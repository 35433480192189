import React from 'react';

import './styles.css';

interface Proptypes {
  type?: 'warning' | 'message' | 'error';
  children: React.ReactNode;
}

export default function ToolTip({ type, children }: Proptypes) {
  return (
    <p className={`tool-tip tool-tip--${type || 'message'}`}>{children}</p>
  );
}
