import { newStoryType, storyType } from '../../types/stories';

export const addStoryToStore = (newStory: newStoryType) => {
  return {
    type: 'ADD_STORY',
    newStory,
  };
};

export const removeStoryFromStore = (story: storyType) => {
  return {
    type: 'REMOVE_STORY',
    stories: [story],
  };
};

export const loadStoryToStore = (stories: [storyType]) => {
  return {
    type: 'LOAD_STORIES',
    stories,
  };
};
export const resetStories = () => {
  return {
    type: 'RESET_STORIES',
  };
};
export const moveStories = (idList: string[] | number[]) => {
  return {
    type: 'MOVE_STORIES',
    payload: idList
  }
}