import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import EmptyClipboard from './EmptyClipboard';
import {
  OnboardingPopStyles,
  OnboardingPopBaseStepsStyles,
} from '../OnboardingHelpers';

interface OnboardingProps {
  run?: boolean;
}

const OnboardingWelcome = ({ run }: OnboardingProps) => {
  const [runState, setRunState] = useState(run || false);
  const [emptyClipboard, setEmptyClipboard] = useState(false);

  useEffect(() => {
    // rerun the component
  }, [run]);

  const stepsValue = {
    run: runState,
    steps: [
      {
        target: '.onboard-info',
        stepCount: 1,
        totalSteps: 7,
        title: 'Welcome to Scrappi',
        content:
          'Create your first Scrap. Copy anything and paste it into your dashboard.',
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        styles: {
          ...OnboardingPopBaseStepsStyles,
          tooltipContainer: {
            paddingTop: 0,
            textAlign: 'left',
          },
          tooltipContent: {
            padding: 0,
            paddingTop: 16,
          },
        },
      },
    ],
  };

  /**
   * Check if hideOnboarding is set in local storage
   * don't show onboarding component
   */
  const hideOnboarding = localStorage.getItem('hideOnboarding');
  if (hideOnboarding) {
    localStorage.removeItem('hideOnboarding');
  }

  return (
    <div>
      {/* there is some issue in the libraries types with certain properties,
          so we need to ignore the typescript error */}
      <Joyride
        // @ts-ignore
        steps={stepsValue.steps}
        run={runState}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        hideCloseButton
        hideBackButton
        locale={{
          close: 'Close',
          last: 'End tour',
        }}
        styles={OnboardingPopStyles}
      />

      <EmptyClipboard emptyClipboard={emptyClipboard} />
    </div>
  );
};

export default OnboardingWelcome;
