import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int'],
  ['document_id', 'Int']
  
]);

export default function DELETE_SCRAP_DOCUMENT() {
  return gql`
      mutation DeleteScrapDocument(${parametersTypeString}) {
        deleteScrapDocument(${parametersAssignString}) {
          ${defaultResponse}
        }
      }
    `;
}
