interface propTypes {
  value: string;
  oldValue?: string;
  min?: number;
  max?: number;
  compareLength?: boolean;
}
const checkMinMax = (
  type: 'min' | 'max',
  intValue: number,
  compareValue: number,
  checkLength?: boolean
) => {
  // CHECK FOR MIN
  if (type === 'min') {
    if (checkLength) {
      return ((''+intValue).length <= compareValue);  
    }
    return (intValue < compareValue);
  }
  // CHECK FOR MAX
  if (checkLength)
    return ((''+intValue).length >= compareValue);
  return (intValue > compareValue);
}
export const onlyNumbers = ({ value, oldValue, min, max, compareLength }: propTypes) => {
  var finalValue = value.replace(/\D/g, '');
  if(finalValue.length > 0) {
    var intValue = +finalValue;
    if(min && checkMinMax('min', intValue, min, compareLength)) {
      return oldValue ? oldValue : '';
    }
    if(max && checkMinMax('max', intValue, max, compareLength)) {
      return oldValue ? oldValue : '';
    }
  }
  return finalValue;
}