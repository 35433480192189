import React from 'react';
import './styles.css';
import { ExclamationSquare } from '../Global/icons';

interface Proptypes {
  renderInvitationControls: () => React.ReactElement;
  returnFormFields: () => React.ReactElement;
  renderSuggestion: () => React.ReactElement;
  invitationHandler: () => void;
  hideEmailSuggestion: (val: boolean) => void;
  isInviteDisabled: () => boolean;
  sendingInvitation: boolean;
  message: string;
}

const InvitationPopup = (prop: Proptypes) => {
  const {
    renderInvitationControls,
    returnFormFields,
    renderSuggestion,
    invitationHandler,
    hideEmailSuggestion,
    sendingInvitation,
    isInviteDisabled,
    message,
  } = prop;

  return (
    <div className={`invitation-popup${sendingInvitation ? ' invitation-popup--processing' : ''}`}>
      {renderInvitationControls()}
      <div className="invitation-popup__input-wrapper">
        {returnFormFields()}
        {renderSuggestion()}
        <button
          disabled={sendingInvitation || isInviteDisabled()}
          onClick={() => {
            invitationHandler();
            hideEmailSuggestion(true);
          }}
          className={`invitation-popup__invite ${
            sendingInvitation || isInviteDisabled()
              ? 'button__secondary'
              : 'button__primary'
          }`}
        >
          Invite
        </button>
      </div>
      {message && (
        <span
          className={`invitation-popup--message ${
            message.includes('successfully')
              ? 'invitation-popup--message__success'
              : ''
          }`}
        >
          {!message.includes('successfully') && <ExclamationSquare />} {message}{' '}
        </span>
      )}
    </div>
  );
};

export default InvitationPopup;
