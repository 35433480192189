// React imports
import React, { useCallback, useEffect, useState } from 'react';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import {
  setGroupSharePopup,
  setInvitationPopup,
  setReloadSideNavShares,
  setWarningPopup,
} from '../../redux/action/utils';
import { ReduxStateType } from '../../redux/store';

// Api imports
import { useMutation, useQuery } from '@apollo/client';
import GET_RELEVANT_CONTACT_AND_GROUP_LIST from '../data/getRelevantContactAndGroupList';
import SEPARATE_SHARE from '../data/separateShare';
import GROUP_SHARE from '../data/groupShare';

// Component imports
import GroupShare from '../../components/GroupShare';
import GroupShareMobile from '../../components/GroupShare/GroupShareMobile';

// Type imports
import { GroupType } from './types';

// Helper imports
import { isMobileDevice } from '../../helpers';
import { oneGroupContainsAllMembers } from '../../helpers/groupHelpers';




const GroupShareContainer = () => {
  const dispatch = useDispatch();
  const isMobile = isMobileDevice();
  const groupShare = useSelector(
    (state: ReduxStateType) => state?.utils?.groupSharePopup
  );
  const [separateShare] = useMutation(SEPARATE_SHARE());
  const [groupShareMutation] = useMutation(GROUP_SHARE());
  const [page, setPage] = useState(1);
  const [groupSearchTerm, setGroupSearchTerm] = useState('');
  const { data, refetch, loading } = useQuery(
    GET_RELEVANT_CONTACT_AND_GROUP_LIST(),
    {
      variables: {
        first: 20,
        page,
        text: groupSearchTerm,
      },
      skip: !groupShare,
      fetchPolicy: 'cache-and-network',
    }
  );
  const isInitialFetching = page === 1 && loading;
  const groupContactData = !isInitialFetching
    ? data?.getRelevantContactAndGroupList?.data || []
    : [];
  const totalGroupContact = isInitialFetching
    ? null
    : data?.getRelevantContactAndGroupList?.paginatorInfo?.total;


  const groupCreate = useCallback(
    (scrap_id: string | number, selected: Array<Partial<GroupType>>) => {
      // Filter and modify selected items based on their type
      // Filter items of type 'group'
      const groups = selected
        ?.filter((item) => item.type === 'group')
        .map((item) => {
          return {
            group_id: item.id,
          };
        });

      // Filter items of type 'collection'
      const collections = selected
        ?.filter((item) => item.type === 'collection')
        .map((item) => {
          return {
            collection_id: item.id,
          };
        });

      // Filter items of type 'contact'
      const members = selected
        ?.filter((item) => item.type === 'contact')
        .map((item) => {
          return {
            user_id: item.id,
          };
        });

      return {
        scrap_id: scrap_id,
        members,
        groups,
        collections,
      };
    },
    []
  );

  const loadMore = () => {
    if (totalGroupContact > groupContactData?.length && !loading) {
      setPage(page + 1);
    }
  };

  const separateShareHandler = useCallback(
    async (
      action: 'reload-after-mutation' | 'close-when-mutation-complete',
      data: Array<Partial<GroupType>> | Partial<GroupType>
    ) => {
      await separateShare({
        variables: groupCreate(
          groupShare.scrap_id,
          Array.isArray(data) ? data : [data]
        ),
      });
      dispatch(setReloadSideNavShares(true));
      switch (action) {
        case 'close-when-mutation-complete':
          dispatch(setGroupSharePopup(null));
          break;
        case 'reload-after-mutation':
          setPage(1);
          refetch({
            first: 10,
            page: 1,
          });
          break;
        default:
          break;
      }
    },
    [setPage, refetch, groupShare]
  );

  const saveHandler = useCallback(async (selected: Partial<GroupType>[]) => {
    if (
      selected.length === 1 ||
      selected.some((data) => data.type === 'collection') ||
      oneGroupContainsAllMembers(selected)
    ) {
      await separateShareHandler('close-when-mutation-complete', selected);
    } else {
      dispatch(
        setWarningPopup({
          className: 'group-share-warning-popup',
          warningMessage:
            'Would you like to share this Scrap with these contacts separately or create a new Group Share?',
          header: 'Share to',
          leftLoader: false,
          cancel: {
            label: 'Share Separately',
            cb: separateShareHandler.bind(
              null,
              'close-when-mutation-complete',
              selected
            ),
          },
          submit: {
            label: 'Create a Group',
            cb: groupShareHandler.bind(
              null,
              'close-when-mutation-complete',
              selected
            ),
          },
        })
      );
    }
  }, [groupShare])

  const groupShareHandler = useCallback(
    async (
      action: 'reload-after-mutation' | 'close-when-mutation-complete' | null,
      groups: Array<Partial<GroupType>>
    ) => {
      await groupShareMutation({
        variables: groupCreate(groupShare.scrap_id, groups),
      });
      dispatch(setReloadSideNavShares(true));

      switch (action) {
        case 'close-when-mutation-complete':
          dispatch(setGroupSharePopup(null));
          break;
        case 'reload-after-mutation':
          setPage(1);
          refetch({
            first: 10,
            page: 1,
          });
          break;
        default:
          break;
      }
    },
    [setPage, refetch, groupShare]
  );
  
  const searchChangeHandler = useCallback((text: string) => {
    setGroupSearchTerm(text);
    setPage(1);
  }, []);

  // Effect to cleanup states
  useEffect(() => {
    setGroupSearchTerm('');
    setPage(1);
  }, [groupShare]);

  // Render mobile view component in group share if accessed from browser
  if (isMobile) {
    return (
      <GroupShareMobile
        isLoadingList={loading}
        onSearch={searchChangeHandler}
        groups={groupContactData}
        show={!!groupShare}
        onClose={() => dispatch(setGroupSharePopup(null))}
        shareUrl={groupShare?.shareUrl}
        onLoadMore={loadMore}
        onIndividualSend={separateShareHandler.bind(null, null)}
        onMultipleSend={(selected) => {
          dispatch(
            setWarningPopup({
              className: 'group-share-warning-popup',
              warningMessage:
                'Would you like to share this Scrap with these contacts separately or create a new Group Share?',
              header: 'Share to',
              leftLoader: false,
              cancel: {
                label: 'Share Separately',
                cb: separateShareHandler.bind(
                  null,
                  'close-when-mutation-complete',
                  selected
                ),
              },
              submit: {
                label: 'Create a Group',
                cb: groupShareHandler.bind(
                  null,
                  'close-when-mutation-complete',
                  selected
                ),
              },
            })
          );
        }}
        onInviteNew={(prefilledData) => {
          dispatch(
            setInvitationPopup({
              onComplete: () => {},
              prefilledData
            })
          );
        }}
      />
    );
  }

  return (
    <GroupShare
      groups={groupContactData}
      loading={loading}
      onClose={() => dispatch(setGroupSharePopup(null))}
      groupSearchTerm={groupSearchTerm}
      setGroupSearchTerm={searchChangeHandler}
      onInviteNew={(prefilledData) => {
        dispatch(
          setInvitationPopup({
            onComplete: () => {},
            prefilledData,
          })
        );
      }}
      onLoadMore={loadMore}
      shareUrl={groupShare?.shareUrl}
      show={!!groupShare}
      onSubmit={saveHandler}
    />
  );
};

export default GroupShareContainer;
