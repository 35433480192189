import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const Pencil = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0082 1.15715C12.6624 0.502957 13.723 0.502957 14.3772 1.15715L16.8427 3.62258C17.4968 4.27677 17.4968 5.33742 16.8427 5.99161L6.61806 16.2162C6.3852 16.4491 6.08888 16.6081 5.76613 16.6735L2.6744 17.2998C2.12401 17.4113 1.55438 17.2396 1.1573 16.8425C0.760212 16.4454 0.58851 15.8758 0.700001 15.3254L1.3263 12.2337C1.39168 11.9109 1.55074 11.6146 1.7836 11.3817L12.0082 1.15715ZM2.70286 13.8757L2.81058 13.3439L4.65587 15.1892L4.12413 15.2969L2.34181 15.658L2.70286 13.8757ZM6.15045 14.3148L3.68502 11.8493L10.8763 4.65807L13.3417 7.12351L6.15045 14.3148ZM14.5262 5.93899L15.6581 4.80709L14.4736 3.62258L14.3772 3.52618L13.1927 2.34166L12.0608 3.47356L14.5262 5.93899Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default Pencil;
