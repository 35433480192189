const DEFAULT = '';

const appliedQuery = (state =  DEFAULT, action) => {

  switch(action.type) {
    case 'LOAD_APPLIED_QUERY' :
      
      return action.appliedQuery;

    default :
      return state;
  }
};

export default appliedQuery;

  
      
  