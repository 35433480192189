import React, { useEffect, useMemo, useRef, useState } from 'react';

import './styles.css';
import Comments, { CommentType, ReactionType } from '..';
import { CloseIcon } from '../../Global/icons';
import CommentCard from '../CommentCard';
import CommentInput from '../CommentInput';
import { useQuery } from '@apollo/client';
import GET_COMMENTS from '../../../containers/data/getComments';
import UPSERT_COMMENT from '../../../containers/data/upsertComment';
import { getResponseMessages, waitBeforeExecute } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import { setOneScrapAction } from '../../../redux/action/onScrapActions';
import GET_REACTIONS from '../../../containers/data/getReactions';

interface Proptypes {
  commentsFor?: {
    type: 'scrap' | 'collection';
    id: CommentType['id'];
  };
  defaultComments?: CommentType[];
  mentionedUserName?: string;
  show: boolean;
  onClose: () => void;
  variables: {
    scrap_id?: number;
    collection_id?: number;
    commentable_type: number;
  };
}

let isUpdatingQuery = false;
export default function ScrapCardCommentsOverlay({
  defaultComments,
  commentsFor,
  onClose,
  show,
  mentionedUserName,
  variables,
}: Proptypes) {
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateType) => state.user);
  const ref = useRef<HTMLDivElement>();
  const [commentsReactions, setCommentsReactions] = useState<
    Array<ReactionType>
  >([]);
  // state for mentioned user name
  const [mention, setMention] = useState<{
    userName: string;
    key: string;
  }>(null);
  const [isCommenting, setIsCommenting] = useState(false);

  // Fetch comments hook
  const { data, loading, error, fetchMore, updateQuery, client } = useQuery(
    GET_COMMENTS(),
    {
      variables: {
        ...variables,
        first: 10,
        sort_by: 'desc',
        sort_param: 'created_at',
        // search_after: lastComment ? lastComment.created_at : null,
      },
      skip: !show,
      fetchPolicy: 'network-only',
    }
  );
  const commentingUsers: CommentType['user'][] =
    data?.getComments?.data?.commenting_users ?? [];
  const commentsList: CommentType[] = useMemo(() => {
    const comments: CommentType[] = data?.getComments?.data?.comments ?? [];
    return comments.map((comment) => ({
      ...comment,
      user:
        commentingUsers.find((user) => user.user_id === comment.user_id) ??
        comment.user,
    }));
  }, [data]);
  const totalComments: number = data?.getComments?.data?.paginatorInfo?.total;

  const loadReactionsHandler = async (
    comment_ids: Array<CommentType['id']>
  ) => {
    if (!comment_ids.length) return [];
    const response = await client.query({
      query: GET_REACTIONS(),
      variables: {
        comment_ids,
        reactable_type: 3,
      },
    });
    const reactions: Array<ReactionType> =
      response?.data?.getReactions?.data || [];
    const orderedReactions: Array<ReactionType> = new Array(comment_ids.length);
    comment_ids.forEach((id, i) => {
      orderedReactions[i] = reactions.find((data) => data.id == id) ?? null;
    });
    return orderedReactions;
  };

  const closeHandler = async () => {
    onClose();
    await waitBeforeExecute(() => {}, 300);
    dispatch(
      setOneScrapAction({
        type: 'comments-and-likes',
        comments: {
          scrap_id: variables.scrap_id,
          commenting_users: commentingUsers,
          comments: commentsList
            .map((comment, i) => {
              const commentReaction = commentsReactions[i];
              return {
                ...comment,
                reaction: commentReaction,
              };
            })
            .reverse(),
          comment_count: totalComments,
        },
      })
    );
  };

  const loadMoreHandler = async (scrollToBottom = false) => {
    if (!commentsList?.length) return;
    if (!loading && !isUpdatingQuery) {
      isUpdatingQuery = true;
      const lastComment = commentsList[commentsList.length - 1];
      await fetchMore({
        variables: {
          search_after: lastComment.created_at,
          tie_breaker_id: lastComment.id,
          tie_breaker_param: 'id',
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          isUpdatingQuery = false;
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            getComments: {
              ...prev.getComments,
              data: {
                ...prev.getComments.data,
                comments: [
                  ...prev.getComments.data.comments,
                  ...fetchMoreResult.getComments.data.comments,
                ],
                commenting_users: [
                  ...prev.getComments.data.commenting_users,
                  ...fetchMoreResult.getComments.data.commenting_users,
                ],
              },
            },
          };
        },
      });
      if (scrollToBottom) {
        setTimeout(() => {
          ref?.current?.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }, 0);
      }
    }
  };

  const scrapCommentHandler = async (text: string) => {
    setIsCommenting(true);
    try {
      setMention(null);
      updateQuery((prevData) => {
        return {
          ...(prevData || {}),
          getComments: {
            ...(prevData?.getComments || {}),
            data: {
              ...(prevData?.getComments?.data || {}),
              paginatorInfo: {
                ...(prevData?.getComments?.data?.paginatorInfo || {}),
                total: totalComments + 1,
              },
            },
          },
        };
      });

      const res = await client.mutate({
        mutation: UPSERT_COMMENT(),
        variables: {
          text,
          ...variables,
        },
      });
      const { isSuccess, error } = getResponseMessages(res.data.upsertComment);

      if (!isSuccess) throw new Error(error[0]);
      const comment = res.data.upsertComment.data;
      setCommentsReactions((old) => [null, ...old]);
      updateQuery((prevData) => {
        return {
          ...(prevData || {}),
          getComments: {
            ...(prevData?.getComments || {}),
            data: {
              ...(prevData?.getComments?.data || {}),
              comments: [
                comment,
                ...(prevData?.getComments?.data?.comments || []),
              ],
              commenting_users: (function () {
                const prevUsers: CommentType['user'][] =
                  prevData?.getComments?.data?.commenting_users || [];
                const commentingCurrentUser = {
                  avatar: user.avatar,
                  display_name: user.display_name,
                  user_id: user.user_id,
                  user_name: user.userName,
                };
                if (
                  prevUsers.findIndex(
                    (data) => data.user_id == commentingCurrentUser.user_id
                  ) >= 0
                ) {
                  return prevUsers;
                } else return [...prevUsers, commentingCurrentUser];
              })(),
            },
          },
        };
      });
      setTimeout(() => {
        ref?.current?.scroll({
          top: 0,
          behavior: 'smooth',
        });
      }, 0);
    } catch (error) {
      console.error(error);
    }
    setIsCommenting(false);
  };

  const reactionToggledHandler = (
    commentIndex: number,
    is_reacted: boolean
  ) => {
    if (loading) return;
    const comment = commentsList[commentIndex];
    setCommentsReactions((old) =>
      old.map((reaction, i) => {
        if (i === commentIndex) {
          if (reaction) {
            return {
              ...reaction,
              is_reacted,
            };
          }
          return {
            id: comment.id,
            is_reacted,
            reactable_type: 3,
          };
        }
        return reaction;
      })
    );
  };

  useEffect(() => {
    if (mentionedUserName) {
      setMention({
        userName: mentionedUserName,
        key: (Math.random() * 1000).toString(),
      });
    } else setMention(null);
  }, [mentionedUserName]);
  useEffect(() => {
    isUpdatingQuery = false;
  }, []);

  useEffect(() => {
    if (loading || !commentsList?.length || !show) return;
    const comment_ids = commentsReactions.length
      ? commentsList
        .slice(commentsReactions.length)
        .map((comment) => comment.id)
      : commentsList.map((scrap) => scrap.id);
    // const scrap_ids = newScraps.map((scrap) => scrap.id);
    loadReactionsHandler(comment_ids).then((data) => {
      setCommentsReactions(
        commentsReactions?.length ? [...commentsReactions, ...data] : data
      );
    });
  }, [commentsList, loading]);
  useEffect(() => {
    setCommentsReactions([]);
  }, [show]);
  return (
    <div
      className={`scrap-card-comments-overlay${
        show ? ' scrap-card-comments-overlay--visible' : ''
      }`}
    >
      {show && (
        <>
          <div className={`scrap-card-comments-overlay__body${user.user_id ? '' : ' scrap-card-comments-overlay__body--read-only'}`}>
            <Comments
              containerRef={ref}
              comments={commentsList}
              onLoadMore={loadMoreHandler}
            >
              {(comment, commentIndex) => {
                const commentReaction = commentsReactions[commentIndex];
                return (
                  <CommentCard
                    onReactionToggled={reactionToggledHandler.bind(
                      null,
                      commentIndex
                    )}
                    onReplyClick={() => {
                      setMention({
                        userName: comment.user.user_name,
                        key: (Math.random() * 1000).toString(),
                      });
                    }}
                    comment={{
                      ...comment,
                      reaction: commentReaction,
                    }}
                  />
                );
              }}
            </Comments>
            {user.user_id && (
              <CommentInput
                disabled={isCommenting}
                mention={mention}
                key={totalComments}
                submitLabel="Reply"
                onCancel={closeHandler}
                onSubmit={scrapCommentHandler}
              />
            )}
          </div>
        </>
      )}

      <div className="scrap-card-comments-overlay__footer">
        <button
          onClick={closeHandler}
          className="scrap-card-comments-overlay__close-button"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}
