import { useMutation } from '@apollo/client';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import AddCollectionsHelper from '../components/Global/AddCollections';
import AddTagsHelper from '../components/Global/AddTags';
import NewPopup from '../components/Global/NewPopup';
import ScrapNotePopup from '../components/ScrapNotePopup';
import ReloadContext from '../helpers/contexts/reloadContext';
import { entryTypes } from '../types/feed';
import { CREATE_NOTE } from './data/createNote';
import { RequestStatusObjectType } from '../types/requestStatusType';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import { ADD_COLLECTIONS_TO_PANEL } from './data/collection';
import { isEmpty, parseTagFromSlug } from '../helpers';
import ScrapAddNotesGroup from '../components/Global/InputsGroup/ScrapAddNotesGroup';
import { noteSummary } from '../helpers';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../components/Global/InvisibleRecaptcha';
import ValidateHuman from '../helpers/validators/isHuman';
import { setReloadSideNavFilters } from '../redux/action/utils';
import { useDispatch } from 'react-redux';

interface Proptypes {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}
export interface ScrapNote {
  title: string;
  description: string;
  tags: [];
  collections: [];
}

const ScrapNotePopupContainer = ({ show, setShow }: Proptypes) => {
  const { setReload } = useContext(ReloadContext);
  const dispatch = useDispatch();
  const [scrapNote, setScrapNote] = useState<ScrapNote>({
    title: '',
    description: '',
    tags: [],
    collections: [],
  });
  const isDescriptionEmpty = !noteSummary(scrapNote.description)
  let variables = {
    title: scrapNote?.title,
    notes: isEmpty(scrapNote.description, true)? '<div></div>' : scrapNote?.description,
  };

  const [showCloseWarning, setShowCloseWarning] = useState(false);

  // if (scrapNote) variables['id'] = scrapNote.id;
  const [createNote] = useMutation(CREATE_NOTE);
  const [addCollections] = useMutation(ADD_COLLECTIONS_TO_PANEL);
  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });

  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  
  function setShowHandler(val: boolean) {
    if (status?.status === 'processing') return;
    else if (
      !scrapNote.collections.length &&
      !scrapNote.tags.length &&
      !scrapNote.description &&
      !scrapNote.title
    ) {
      setShow(val);
    } else {
      setShowCloseWarning(!showCloseWarning);
    }
  }

  function cancelClickHandler(close: () => void) {
    if (showCloseWarning) {
      setShowCloseWarning(false);
      setShow(false)
    }
    else
    {
      setShow(false)
    }
    close();
  }

  
  const saveNoteHandler = async (closeFunc) => {
    const { tags, collections } = scrapNote;
    if (showCloseWarning && !scrapNote.description && !scrapNote.title) {
      setShowCloseWarning(false)
    }
    setStatus({ status: 'processing' });
    
    try {
      if (isDescriptionEmpty && !scrapNote.title) {
        throw new Error('Enter a title or notes to save this Scrap.')
      }
      if (Array.isArray(scrapNote.tags) && scrapNote.tags.length) {
        variables['tags'] = tags?.map((el: { title; id }) => el.id).join(',');
      }
      const isHuman= await ValidateHuman(reCaptchaRef.current);
      if(!isHuman)
        throw new Error('Your\'re not a human!')
      
      const res = await createNote({ variables });
      if (!res.data.createNote.pid) throw new Error('Failed to save scrap');
      if (
        Array.isArray(scrapNote.collections) &&
        scrapNote.collections.length
      ) {
        variables['collection'] = collections
          ?.map((el: { title; id }) => el.id)
          .join(',');
        await addCollections({
          variables: {
            pid: res.data.createNote.pid,
            collections: variables['collection'],
          },
        });
      }
      dispatch(setReloadSideNavFilters(true));
      setStatus({ status: 'success' });
      closeFunc();
      setShow(false);
      setReload(true);
  
    } catch (error) {
      setStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  };

  const onSave = (data?: Partial<entryTypes>) => {
    if (data?.tags) {
      const { tags } = data;
      const tagsId = tags.map((el) => parseTagFromSlug(el.slug).id);

      const filtered = tags.filter(
        ({ slug }, index) => !tagsId.includes(parseTagFromSlug(slug).id, index + 1)
      );

      setScrapNote((prevState) => {
        return {
          ...prevState,
          tags: [...filtered],
        } as ScrapNote;
      });
    }

    if (data.collections) {
      const { collections } = data;
      const collectionsId = collections.map((el) => el.id);
      const filtered = collections.filter(
        ({ id }, index) => !collectionsId.includes(id, index + 1)
      );

      setScrapNote((prevState) => {
        return {
          ...prevState,
          collections: [...filtered],
        } as ScrapNote;
      });
    }
  };

  let disableSubmit =
    isEmpty(scrapNote.description, true) && scrapNote.title.length <= 0 || status?.status === 'processing';
  if(showCloseWarning) disableSubmit = false;
  function render(close: () => void) {
    if (showCloseWarning) {
      return (
        <p className="scrap-images-popup__close-warning">
          Do you want to save your updates first?
        </p>
      );
    }
    return (
      <ScrapNotePopup
        errorMessage={status?.status === 'error' ? status.message : null}
        renderCollectionsField={() => (
          <AddCollectionsHelper
            selectedCollections={scrapNote?.collections}
            onSave={onSave}
          />
        )}
        renderTagsField={() => (
          <AddTagsHelper
            onSave={onSave}
            selectedTags={scrapNote.tags}
          />
        )}
      >
        <>
          <InvisibleRecaptcha inputRef={reCaptchaRef}/>
          <ScrapAddNotesGroup
            isDefaultOpened
            onDescriptionChange={(desc) => {
              setScrapNote((old) => ({
                ...old,
                description: desc,
              }));
            }}
            description={scrapNote?.description || ''}
            title={scrapNote?.title}
            onTitleChange={(title) => {
              setScrapNote((old) => ({ ...old, title }));
            }}
          />
        </>
      </ScrapNotePopup>
    );
  }
  useEffect(() => {
    setScrapNote({ title: '', description: '', tags: [], collections: [] });
    setShowCloseWarning(false);
    setStatus({ status: 'not-started' });
  }, [show]);

  let popupClass = 'scrap-note-popup__modal';

  return (
    <NewPopup
      className={popupClass}
      header={{
        heading: 'Scrap a Note',
      }}
      controlled={{ show, setShow: setShowHandler }}
      footer={{
        // disableSubmit: (status?.status === 'processing' || !disableSubmit)&&!showCloseWarning,
        disableSubmit: disableSubmit,
        disableCancel: status?.status === 'processing',
        cancelLabel: showCloseWarning ? 'Discard & close' : 'Cancel',
        onSubmit: saveNoteHandler,
        
        onCancelClick: cancelClickHandler,
        submitLabel: status?.status === 'processing' ? 'Saving' :'Save',
      }}
    >
      {render}
    </NewPopup>
  );
};

export default ScrapNotePopupContainer;
