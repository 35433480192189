const heic2any = typeof window === 'object' ? require('heic2any') : null;
// This helper function converts file of type heic to jpeg/png
export default async function convertHEICImage(file: File) {
  try {
    if(!heic2any) return null;

    const conversionResult = await heic2any({ blob: file });
    const conversionBlob = Array.isArray(conversionResult)
      ? conversionResult[0]
      : conversionResult;

    if (!conversionBlob) throw new Error('Could not convert');

    const newFile = new File([conversionBlob], file.name, {
      type: conversionBlob.type,
    });

    console.log('file size is', newFile.size / 1000000)

    return newFile;
  } catch (error) {
    return null;
  }
}
