import React, { useState, useEffect, useRef } from 'react';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import { validateEmail } from '../../helpers/validators';
import NewPopup from '../Global/NewPopup';
import { charLimits } from '../../helpers/config';

import './styles.css';
import { getHtmlLength } from '../../helpers/getHtmlLength';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../Global/InvisibleRecaptcha';
import ValidateHuman from '../../helpers/validators/isHuman';
import LabelTextInput from '../Global/LabelTextInput';
import ScrapDescription from '../ScrapDetails/components/ScrapDescription';

interface Proptypes {
  onInvite: (variables: { email: string; message?: string }) => Promise<string>;
  close: () => void;
  controlled: {
    show: boolean;
    setShow: (value: boolean) => void;
  };
}

const InviteUser = ({ onInvite, controlled }: Proptypes) => {
  const [desccharLength, setDescCharLength] = useState<number>(
    charLimits.default
  );
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [componentKey, setComponentKey] = useState(
    (Math.random() * 1000).toString()
  );
  const [response, setResponse] = useState<{
    type: 'error' | 'success';
    message: string;
  }>(null);
  const [email, setEmail] = useState('');
  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const handleSubmit = async () => {
    
    setProcessing(true);
    const isHuman = await ValidateHuman(reCaptchaRef.current);
    if (!isHuman) return setProcessing(false);

    if (email) {
      if (!validateEmail(email))
        return setResponse({ type: 'error', message: 'Invalid email' });
      
      const variables = {
        email,
      };
      if (message && message.trim() !== '') variables['message'] = message;
      try {
        const successMessage = await onInvite(variables);
        setResponse({ type: 'success', message: successMessage });
        setEmail('');
        setComponentKey((Math.random() * 1000).toString());
      } catch (error) {
        setResponse({ type: 'error', message: parseTryCatchError(error) });
      } finally {
        setProcessing(false);
      }
    }
  };

  const setEmailFun = (value: string) => {
    setEmail(value);
    if (value?.length === 1) {
      setResponse(null);
    }
  };

  const render = (close) => {
    return (
      <div className="invite-user">
        <div className="invite-user__form">
          <InvisibleRecaptcha inputRef={reCaptchaRef} />
          <div className="invite-user__form-control invite-user__form-email">
            <LabelTextInput
              value={email}
              onChange={(e) => setEmailFun(e)}
              label="Email"
              placeholder="Email"
            />
            
          </div>
          <div className="invite-user__form-control invite-user__form-message">
            <div className="invite-user__form-message-input">
              
              <ScrapDescription
                hideAllTools
                label='Message'
                placeholder="Type your message here"
                description=''
                
                onChange={setMessage}
                limitConfig={{
                  charLimit: 500,
                  setDescCharLimitExceeds: (...args) => {
                    // Intentionallhy left blank because it is not required to store character count of notes
                  },
                }}
                key={componentKey}
                setCharLength={setDescCharLength}
              />

              <span className="invite-user__form-char-limit">
                {desccharLength || 0}/500
              </span>
              {desccharLength < 0 && (
                <span className="invite-user__form-char-limit-message">
                  {charLimits.characterLimitReachedMessage}
                </span>
              )}
            </div>
          </div>
          {response && (
            <span
              className={`invite-user__form-response invite-user__form-response--${
                response.type === 'success' ? 'success' : 'error'
              }`}
            >
              {response.message}
            </span>
          )}
        </div>
      </div>
    );
  };

  const validation = () => {
    if (validateEmail(email) && getHtmlLength(message) <= 500) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (controlled.show) {
      setResponse(null);
      setEmail('');
    }
  }, [controlled.show]);

  return (
    <NewPopup
      
      size="Small"
      className="invite-user-popup"
      controlled={controlled}
      header={{
        heading: 'Invite Scrapper',
      }}
      footer={{
        onSubmit: () => {
          handleSubmit();
        },
        submitLabel: 'Invite',
        disableSubmit: validation() || processing,
      }}
    >
      {(close) => render(close)}
    </NewPopup>
  );
};

export default InviteUser;
