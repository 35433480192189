import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const MinusIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={()=>onClick()}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM5.75736 11C5.20507 11 4.75736 10.5523 4.75736 10C4.75736 9.44772 5.20507 9 5.75736 9H14.2426C14.7949 9 15.2426 9.44772 15.2426 10C15.2426 10.5523 14.7949 11 14.2426 11H5.75736Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default MinusIcon;
