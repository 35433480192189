import React, { useState, useRef } from 'react';
import './styles.css';
import { useSize, useScroller } from 'mini-virtual-list';
import { useMasonry, useResizeObserver, usePositioner } from 'masonic';
import Masonry from 'react-masonry-css';
import { ExistingImagesItemsType } from '../../../../types/imagesTypes';
// import { ReactComponent as TrashSVG } from '../../../svgs/trash.svg';
import Icon from '../../../../../Global/Icon';
import ResponsiveImage from '../../../../../Global/ResponsiveImage/';

interface Proptypes {
  selectedImages: ExistingImagesItemsType[];
  setSelectedImages: (imgs: ExistingImagesItemsType[]) => void;
}

const breakpointColumnsObj = {
  default: 5,
  1100: 4,
  700: 2,
  500: 1,
};
const FakeCard = ({ index, data, imagesToRemove, setImagesToRemove }) => {
  const { order, name, type } = data;
  return (
    <div key={order} className="images-masonry-item">
      <input
        type="checkbox"
        name="image-select"
        checked={imagesToRemove.findIndex((item) => item.name === name) !== -1}
        onChange={(e) => {
          if (e.target.checked) setImagesToRemove([...imagesToRemove, data]);
          else
            setImagesToRemove(
              imagesToRemove.filter((img) => img.name !== name)
            );
        }}
        className="images-masonry-item__checkbox"
        id={`${order}${name}`}
      />
      <span
        className={
          `images-masonry-item__label-check ` +
          (imagesToRemove.length ? 'show_border' : '')
        }
      >
        <Icon iconClass="check" size="big" />
      </span>
      <label htmlFor={`${order}${name}`} className="images-masonry-item__label">
        <ResponsiveImage
          sizes={['800']}
          image={name}
          alt={`pic ${index + 1}`}
          imageClass="images-masonry-item__img"
        />
      </label>
    </div>
  );
};
const SelectedImagesMasonry = (props: Proptypes) => {
  const { selectedImages, setSelectedImages } = props;
  const [imagesToRemove, setImagesToRemove] = useState<
    ExistingImagesItemsType[]
  >([]);

  const containerRef = useRef(null);

  const { height, width } = useSize(containerRef);
  const { scrollTop, isScrolling } = useScroller(containerRef);
  const positioner = usePositioner(
    {
      width,
      columnWidth: 200,
      columnGutter: 10,
    },
    [selectedImages]
  );
  const resizeObserver = useResizeObserver(positioner);

  const removeSelectedItems = () => {
    let itemArr = [...selectedImages];
    imagesToRemove.map((s) => {
      let itemIndex = itemArr.findIndex((i) => i.image === s.image);
      itemArr.splice(itemIndex, 1);
      return true;
    });

    setSelectedImages(itemArr);
    setImagesToRemove([]);
  };

  return (
    <>
      <div
        className="images-masonry-container"
        style={{ height: '100%', overflow: 'auto', width: '100%' }}
        ref={containerRef}
      >
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {selectedImages.map((image, i) => (
            <FakeCard
              key={image.image}
              index={i}
              data={image}
              imagesToRemove={imagesToRemove}
              setImagesToRemove={setImagesToRemove}
            />
          ))}
        </Masonry>
        {imagesToRemove.length > 0 && (
          <div className="addedImages-selected-item__delete text-left">
            <span>{imagesToRemove.length} Images Selected</span>
            <button
              className="button button__outline"
              onClick={() => {
                removeSelectedItems();
              }}
            >
              <Icon iconClass="trash" size="big" color="#6a6e73" />{' '}
              <span>Delete</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default SelectedImagesMasonry;
