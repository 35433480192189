import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['text', 'String'],
  ['scrap_id', 'Int'],
  ['collection_id', 'Int'],
  ['commentable_type', 'Int'],
  ['parent_comment_id', 'Int'],
]);
export default function UPSERT_COMMENT() {
  return gql`
    mutation UpsertComment(${parametersTypeString}) {
      upsertComment(${parametersAssignString}) {
        ${defaultResponse}
        data {
          created_at
          id
          text
          updated_at
          user_id
        }
      }
    }
  `;
}
