/* eslint-disable @typescript-eslint/no-explicit-any */
export const moveDataToIndex = (
  position: number,
  dataList: any[],
  subDataList: any[],
  findCondition: Function
) => {
  const tempAllList = [...dataList];
  const gotList = [];

  subDataList.forEach((dta) => {
    gotList.push(
      ...tempAllList.splice(
        tempAllList.findIndex((data) => findCondition(data, dta)),
        1
      )
    );
  });

  tempAllList.splice(position, 0, ...gotList);
  return tempAllList;
};
// This function divides the array in to small arrays of a particular batch size
// And returns those small arrays
export const returnArrayInBatch = <T>(
  list: Array<T>,
  batchSize: number
): Array<Array<T>> => {
  const array = [];
  for (let i = 0; i < Math.ceil(list.length / batchSize); i++) {
    array.push([]);
  }
  list.forEach((item, i) => {
    array[Math.floor(i / batchSize)].push(item);
  });
  return array;
};
