import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['group_id', 'Int!'],
  ['search', 'String'],
  ['first', 'Int'],
  ['page', 'Int'],
]);
export default function GET_RELEVANT_CONTACT_FOR_UPDATE_GROUP(
  responseString = ''
) {
  let dataString = `
    paginatorInfo {
      total    
    } 
    data {
      id
      user_id
      user_name
      display_name
      avatar
      is_group_owner
      is_group_member
      updated_at
    }
  `;
  if (responseString) dataString = responseString;
  return gql`
    query GetRelevantContactForUpdateGroup(${parametersTypeString}) {
        getRelevantContactForUpdateGroup(${parametersAssignString}) {
          ${defaultResponse}
          ${dataString}
      }
    }
  `;
}
