import { useMutation, useQuery } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useHistory, useParams } from 'react-router-dom';
import GET_GROUP from '../data/getGroup';
import GROUP_SCRAPS from '../data/groupScraps';
import { GroupType } from '../GroupShareContainer/types';
import { scrapType } from '../../types/scrapType';
import ScrapCard from '../../components/ScrapCard';
import CollectionPage from '../../components/CollectionPage';
import { collectionCardFooterOptionsType } from '../../components/NewCollectionCard/UiComponents/NewCollectionFooter';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupDetailsPopup } from '../../redux/action/popupsAction';
import { ReduxStateType } from '../../redux/store';
import REMOVE_GROUP_SCRAP from '../data/removeGroupScrap';
import { setCollectionSettingsPopup, setReloadSideNavShares, setScrapPopup, setWarningPopup } from '../../redux/action/utils';
import LEAVE_GROUP from '../data/leaveGroup';
import DELETE_GROUP from '../data/deleteGroup';
import { setOneScrapAction } from '../../redux/action/onScrapActions';
import updateEntryCache from '../../helpers/cacheHelpers/updateEntry';
import RenderConvertMessage from '../../components/GroupDetailsPopup/RenderConvertMessage/';
import { setGroupFilters } from '../../redux/action/filterAction';
import SEOMeta from '../../components/Meta/SEOMeta';
import { getResponseMessages, returnAllGroupMembersExceptMe } from '../../helpers';
import GET_SCRAPS_WITH_IN_COLLECTION_COMMENTS from '../data/getScrapsWithInCollectionComments';
import { CommentType, ReactionType, ScrapCommentsReactionsType, ScrapCommentsType, ScrapReactionCommentCountType } from '../../components/Comments';
import UPSERT_COMMENT from '../data/upsertComment';
import GET_REACTIONS from '../data/getReactions';
import ADD_REACTION from '../data/addReaction';
import REMOVE_REACTION from '../data/removeReaction';
import { setGroupMessenger } from '../../redux/action/messengersAction';
import MATCHED_BOOKMARKS from '../data/matchedBookmarks';
import { updateScrapsLikesAndComments } from '../../redux/action/scrapsLikesAndCommentsActions';
import { updateGroupsLikesAndComments } from '../../redux/action/groupsLikesAndCommentsActions';

const numberOfRecords = 10;
export default function GroupPageContainer() {
  const oneScrapAction = useSelector(
    (state: ReduxStateType) => state.oneScrapAction
  );
  const scrapsLikesRef = useRef<ReactionType[]>([]); 
  // const [scrapsReactions, setScrapsReactions] = useState<ReactionType[]>([]);
  const [scrapsComments, setScrapsComments] = useState<ScrapCommentsType[]>([])
  const [bookmarkedScrapIds, setBookmarkedScrapIds] = useState<Array<scrapType['id']>>([])

  const user = useSelector((state: ReduxStateType) => state.user);
  const groupMessenger = useSelector(
    (state: ReduxStateType) => state.messengers.groupMessenger
  );
  const groupFilters = useSelector((state: ReduxStateType) => state.filters?.groupFilters)
  const dispatch = useDispatch();
  
  const ref = useRef<HTMLDivElement>();
  const { gid: group_id } = useParams<{ gid: string }>();

  const [page, setPage] = useState(1);

  const [removeScrapMutation] = useMutation(REMOVE_GROUP_SCRAP());
  const [leaveGroupMutation] = useMutation(LEAVE_GROUP());
  const [deleteGroupMutation] = useMutation(DELETE_GROUP());
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());

  const groupQueryVariables = {
    group_id,
  };
  const {
    data: groupData,
    loading: groupLoading,
    refetch: refetchGroup,
    updateQuery: updateGroupQuery
  } = useQuery(GET_GROUP(), {
    variables: groupQueryVariables,
    fetchPolicy: 'cache-and-network',
  });

  const { data: reactionData, updateQuery: updateReactionQuery } = useQuery(GET_REACTIONS(), {
    variables: {
      collection_ids: [group_id],
      reactable_type: 2,
    },
    fetchPolicy: 'network-only'
  })
  const reaction: ReactionType = (reactionData?.getReactions?.data || [])[0]
  const isLiked = reaction?.is_reacted
  const { replace } = useHistory()
  const scrapsQueryVariables = {
    page,
    group_id,
    first: numberOfRecords,
  };

  if (groupFilters) {
    for (let key in groupFilters) {
      if (
        key === 'untagged' &&
        Array.isArray(groupFilters[key]) &&
        groupFilters[key].length
      )
        scrapsQueryVariables[key] = true;
      else if (
        key === 'tags' &&
        Array.isArray(groupFilters[key]) &&
        groupFilters[key].length
      ) {
        scrapsQueryVariables[key] = groupFilters[key].map((slug) => ({
          slug,
        }));
      } else scrapsQueryVariables[key] = groupFilters[key].toString() || '';
    }
  }
  const { data: scrapsData, loading: scrapsLoading, client, refetch: refetchGroupScraps } = useQuery(
    GROUP_SCRAPS(),
    {
      variables: scrapsQueryVariables,
      fetchPolicy: 'cache-and-network',
    }
  );
  const group: GroupType = useMemo(() => {
    return groupData?.getGroup?.data;
  }, [groupData]);
  const groupisMine = group?.owner_user_name === user.userName;
  const scraps: scrapType[] = useMemo(() => {
    return scrapsData?.groupScraps?.data;
  }, [scrapsData]);
  const totalScraps = scrapsData?.groupScraps?.paginatorInfo.total;
  const masonryKey = useMemo(() => (Math.random() * 10000).toString(), [
    totalScraps,
    group_id,
    groupFilters
  ]);

  

  async function loadMoreHandler() {
    if (!Array.isArray(scraps)) return;
    if (scraps.length % numberOfRecords !== 0) return;
    setPage(scraps.length / numberOfRecords + 1);
  }

  async function leaveGroupHandler() {
    const res = await leaveGroupMutation({
      variables: {
        group_id: group.id,
      },
    });
    const successMessages = res?.data?.leaveGroup?.messages?.success;
    const errorMessages = res?.data?.leaveGroup?.messages?.error || [];
    if(Array.isArray(successMessages) && successMessages.length) {
      dispatch(setReloadSideNavShares(true));
      replace('/');
    }
    else throw new Error(errorMessages[0]);
  }
  
  async function deleteGroupHandler() {
    const res = await deleteGroupMutation({
      variables: {
        group_id: group.id,
      },
    });
    const successMessages = res?.data?.deleteGroup?.messages?.success;
    const errorMessages = res?.data?.deleteGroup?.messages?.error || [];
    if(Array.isArray(successMessages) && successMessages.length) {
      dispatch(setReloadSideNavShares(true))
      replace('/');
    }
    else throw new Error(errorMessages[0]);
  }

  const dropdownOptionClickHandler = useCallback(
    (option: collectionCardFooterOptionsType['list'][number]) => {
      switch (option) {
        case 'editShareGroup':
          dispatch(
            setGroupDetailsPopup({
              type: 'groupData',
              data: group,
            })
          );
          break;
          
        case 'leave':
          dispatch(
            setWarningPopup({
              warningMessage:"Are you sure you want to leave this Share?",
              header:"Warning",
              leftLoader:true,
              cancel:{
                label:"Cancel",
              },
              submit:{
                label:"Yes",
                cb: leaveGroupHandler
              },
              processingLabel: 'Leaving'
            })
          ) 
          break;
        case 'delete':
          dispatch(
            setWarningPopup({
              warningMessage:"Are you sure you want to delete this Share?",
              header:"Warning",
              leftLoader:true,
              cancel:{
                label:"Cancel",
              },
              submit:{
                label:"Yes",
                cb: deleteGroupHandler
              },
              processingLabel: 'Deleting'
            })
          ) 
          break;
        case 'convert':
          dispatch(
            setWarningPopup({
              renderCustomMessage: () => <RenderConvertMessage />,
              className: 'convert-message',
              // warningMessage:
              //   'Are you sure? Click OK to delete your account. This cannot be reversed. All content will be deleted.',
              header: 'Convert',
              leftLoader:true,
              cancel: {
                label: 'Cancel',
              },
              submit: {
                label: 'Convert',
                cb: () => {
                  // deleteUser();
                  dispatch(
                    setCollectionSettingsPopup({
                      collection: group,
                      type: 'group-convert',
                    })
                  );
                },
              },
            })
          );  
          break;  
        default:
          break;
      }
    },
    [group]
  );

  const removeScrapHandler = async (scrap: scrapType) => {
    await removeScrapMutation({
      variables: {
        scrap_id: scrap.id,
        group_id: group.id,
      },
    });
    removeScrapFromGroupCacheHandler(scrap)
     
  };

  const removeScrapClickHandler = (scrap: scrapType) => {
    dispatch(
      setWarningPopup({
        warningMessage:
          'Are you sure you want to remove this Scrap from this Share?',
        header: 'Warning',
        leftLoader: true,
        cancel: {
          label: 'Cancel',
        },
        submit: {
          label: 'OK',
          cb: removeScrapHandler.bind(null, scrap),
        },
      })
    );
  };

  const footerDropdownOptions: collectionCardFooterOptionsType = useMemo(
    () => ({
      list:
        groupisMine
          ? ['convert', 'editShareGroup', 'delete']
          : ['leave'],
      onOptionClick: dropdownOptionClickHandler,
    }),
    [group, groupisMine]
  );

  const removeScrapFromGroupCacheHandler = (scrap: Partial<scrapType>) => {
    const scrapIndex = scraps.findIndex(scrapData => scrapData.id === scrap.id);
    scrapsLikesRef.current.filter((_,i) => i !== scrapIndex);
    setScrapsComments(old =>old.filter((_,i) => i !== scrapIndex))
    setBookmarkedScrapIds(old =>old.filter((_,i) => i !== scrapIndex))
    client.cache.updateQuery(
      { query: GROUP_SCRAPS(), overwrite: true, variables: scrapsQueryVariables },
      (prevCache) => {
        return {
          ...prevCache,
          groupScraps: {
            ...prevCache.groupScraps,
            data: prevCache.groupScraps.data.filter(data => {
              return scrap.id !== data.id
            }),
            paginatorInfo: {
              ...prevCache.groupScraps.paginatorInfo,
              total: prevCache.groupScraps.paginatorInfo.total - 1
            }
            
          }

        }
      }
    );
    

  }

  const renderScrap = useCallback(
    (scrap: scrapType, i: number) => {
      const scrapIsMine = scrap.user_id === user.user_id;
      let scrapData = scrap;
      if(group) {
        const scrapUser = group?.members?.find(
          (member) => member.user_id === scrap.user_id
        );
    
        if (scrapUser)
          scrapData = {
            ...scrap,
            user_name: scrapUser.user_name,
            display_name: scrapUser.display_name,
            avatar: scrapUser.avatar,
            headline: scrapUser.headline,
          };
        else if (scrap.user_id === group.user_id)
          scrapData = {
            ...scrap,
            user_name: group.owner_user_name,
            display_name: group.owner_display_name,
            avatar: group.owner_avatar,
            headline: group.headline,
          };
      }

      let scrapComments: ScrapCommentsType = scrapsComments[i] ? { ...scrapsComments[i] } : null;
      const commentingUsers = scrapComments?.commenting_users || []
      let comments = scrapComments?.comments || [];
      if(comments.length) {
        comments = comments.map(comment => ({
          ...comment,
          user: commentingUsers.find(user => user.user_id === comment.user_id) ?? comment.user
        }))
      }

      
      
      return (
        <ScrapCard
          onBookmarkToggled={scrapBookmarkToggledHandler.bind(null, i)}
          isBookmarked={!!bookmarkedScrapIds[i]}
          view='group-page'
          onCommentReactionToggled={commentReactionToggledHandler.bind(null, scrap.id, i)}
          commentsCount={scrapComments?.comment_count || scrapComments?.comments?.length}
          scrapAllCommentsExtraVariables={{
            collection_id: +group_id,
            commentable_type: 2,
          }}
          onLikeToggled={scrapReactionToggledHandler.bind(null, scrap.id, i)}
          comments={comments}
          onScrapComment={scrapCommentHandler.bind(null, scrap.id)}
          scrap={scrapData}
          onRemoveClick={
            (scrapIsMine || groupisMine) &&
            removeScrapClickHandler.bind(null, scrap)
          }
          onEditClick={
            scrapIsMine
              ? (scrap, scrollToBottom?: boolean) => {
                dispatch(
                  setScrapPopup({
                    type: 'id',
                    defaultEdit: true,
                    data: scrap.id,
                    scrollToBottom,
                  })
                );
              }
              : null
          }
        />
      );
    },
    [user, group, scrapsComments, bookmarkedScrapIds]
  );

  const scrapCommentHandler = useCallback(async (scrapId: scrapType['id'], text: string, dontUpdateScrapState?: boolean) => {
    const res = await client.mutate({
      mutation: UPSERT_COMMENT(),
      variables: {
        text,
        scrap_id: scrapId,
        collection_id: group.id,
        commentable_type: 2,
      }
    })
    const { isSuccess, error } = getResponseMessages(res.data.upsertComment);

    if(!isSuccess) throw new Error(error[0]);
    const comment = res.data.upsertComment.data;
    const scrapCommentsIndex = scraps.findIndex(scrap => scrap.id == scrapId)
    // const data: ScrapCommentsType[] = await loadCommentsHandler([scrapId]);
    // const scrapComments = data[0];
    let scrapComments = scrapsComments[scrapCommentsIndex]
    if(scrapComments) {
      const currentCommentsCount = scrapComments.comment_count || scrapComments?.comments?.length || 0;
      scrapComments = {
        ...scrapComments,
        comment_count: currentCommentsCount + 1,
        comments: [...(scrapComments.comments || []), comment],
        commenting_users: (function() {
          const currentCommentingUsers = scrapComments.commenting_users || [];
          const existingUserIndex = currentCommentingUsers.findIndex(commentingUser => commentingUser.user_id === user.user_id)
          if(existingUserIndex > -1) return currentCommentingUsers;
          return [
            ...currentCommentingUsers,
            {
              user_id: user.user_id,
              user_name: user.userName,
              avatar: user.avatar,
              display_name: user.display_name,
            }
          ]
        })()
      }
    } else {
      scrapComments = {
        comment_count: 1,
        commenting_users: [{
          user_id: user.user_id,
          user_name: user.userName,
          avatar: user.avatar,
          display_name: user.display_name,
        }],
        comments: [comment],
        scrap_id: scrapId,
      }
    }
    if(dontUpdateScrapState) return scrapComments
    
    setScrapsComments(old => old.map((data,i) => {
      if(i === scrapCommentsIndex) {
        return scrapComments;
      }
      return data;
    }))
    return scrapComments    
  }, [group, scraps, scrapsComments])

  const loadBookmarksHandler = async (ids: Array<number | string>, idKey: 'scrap_ids' | 'child_collection_ids') => {
    if(!ids.length) return []
    try {
      const response = await client.query({
        query: MATCHED_BOOKMARKS(),
        variables: { [idKey]: ids }
      })
      const { error,isSuccess } = getResponseMessages(response?.data?.matchedBookmarks)
      if(!isSuccess) throw new Error(error[0])
      const scrapIdsFromResponse: Array<scrapType['id']> = (response?.data?.matchedBookmarks?.data || []).map(item => item?.scrap_id);
      const orderedScrapIds: Array<scrapType['id']> = new Array(ids.length)
      ids.forEach((id, i) =>  {
        orderedScrapIds[i] = scrapIdsFromResponse.find(data => data == id) ?? null;
      })
      return orderedScrapIds;
    } catch (error) {
      
      return []
    }
  }

  const scrapBookmarkToggledHandler = (scrapIndex: number, action: 'save' | 'unsave') => {
    const scrap = scraps[scrapIndex]
    setBookmarkedScrapIds(old => old.map((id, i) => {
      if(i === scrapIndex) return action === 'save' ? scrap.id : null;
      return id
    }))
  }

  const loadReactionsHandler = async (ids: Array<scrapType['id']>, idKey: 'scrap_ids' | 'comment_ids') => {
    const response = await client.query({ query: GET_REACTIONS() , variables: {
      [idKey]: ids,
      reactable_type: idKey === 'scrap_ids' ? 1 : 3      
    } })
    const scrapsReactions: Array<ReactionType> = response?.data?.getReactions?.data || []
    const orderedScrapsReactions: Array<ReactionType> = new Array(ids.length)
    ids.forEach((id, i) =>  {
      orderedScrapsReactions[i] = scrapsReactions.find(data => data.id == id) ?? null
    })
    return orderedScrapsReactions;
  }

  const loadScrapsCommentsReactionsHandler = async (idsTuples: Array<[scrapType['id'], Array<CommentType['id']>]>) => {
    const allCommentIds: Array<CommentType['id']> = [];
    const allScrapsId: Array<scrapType['id']> = [];
    
    (idsTuples || []).forEach(([scrapId, commentIds]) => {
      allScrapsId.push(scrapId);
      allCommentIds.push(...(commentIds || []).slice(0,2))
    })
    const reactions = await loadReactionsHandler(allCommentIds, 'comment_ids');
    const scrapsCommentsReactions: ScrapCommentsReactionsType[] = []
    allScrapsId.forEach((scrap_id,i) => {
      const commentsReactions: ReactionType[] = [];
      const scrapCommentsId = idsTuples[i][1] || []
      if(scrapCommentsId) {
        scrapCommentsId.forEach(commentId => {
          const commentReaction = reactions.find(reaction => reaction?.id == commentId)
          if(commentReaction) commentsReactions.push(commentReaction)
        })
      }

      scrapsCommentsReactions.push({
        scrap_id,
        reactions: commentsReactions
      })
    
    })
    return scrapsCommentsReactions;
  }

  

  async function scrapReactionToggledHandler (scrap_id: number, scrapIndex: number,isReacted: boolean) {
    const scrapReaction = scrapsLikesRef.current[scrapIndex];
    if(scrapReaction) {
      scrapReaction.is_reacted = isReacted;
    } else {
      scrapsLikesRef.current[scrapIndex] = {
        is_reacted: isReacted,
        id: scrap_id,
        reactable_type: 1
      }
    }

  }


  async function commentReactionToggledHandler(scrapId: scrapType['id'], scrapIndex: number, commentId: CommentType['id'], isReacted: boolean) {
    setScrapsComments(old => old.map((scrapComments,i) => {
      if(i === scrapIndex) {
        return {
          ...scrapComments,
          comments: (function(){
            return scrapComments.comments.map(comment => {
              if(comment.id == commentId) {
                let newReactionData = comment.reaction ? { ...comment.reaction } : null;
                if(newReactionData) newReactionData.is_reacted = isReacted;
                else newReactionData = {
                  id: commentId,
                  is_reacted: isReacted,
                  reactable_type: 3
                }
                return {
                  ...comment,
                  reaction_count: isReacted ? (comment.reaction_count || 0) + 1 : (comment.reaction_count || 0) - 1,
                  reaction: newReactionData,
                }
              }
              return comment
            })
          })()
        }
      }
      return scrapComments
    }))
  }  

  useEffect(() => {
    const reaction: ReactionType = (reactionData?.getReactions?.data || [])[0]
    if(!reaction || !group) return;
    dispatch(updateGroupsLikesAndComments({
      groupId: reaction.id,
      data: {
        isLiked: reaction.is_reacted,
        likesCount: group.reaction_count,
        commentsData: {
          count: group.comment_count
        }
      }
    }));
  },[reactionData])

  useEffect(() => {
    if (scrapsLoading || !scraps?.length) return;
    
    const scrapsCommentsIds = scrapsComments.length
      ? scraps.slice(scrapsComments.length).map((scrap) => scrap.id)
      : scraps.map((scrap) => scrap.id);
    
    const reactionCommentCounts: ScrapReactionCommentCountType[] = scrapsData?.groupScraps?.reaction_comment_counts || []  
    const fetchedScrapsComments: ScrapCommentsType[] = scrapsData?.groupScraps?.share_scraps_comments || []
    const orderedScrapsComments = new Array(scrapsCommentsIds.length)
    scrapsCommentsIds.forEach((id, i) =>  {
      const scrapComments = fetchedScrapsComments.find(data => data.scrap_id == id) ?? null;
      const countsData = reactionCommentCounts.find(data => data.scrap_id == id) ?? null;
      if(!countsData && !scrapComments) orderedScrapsComments[i] = null;
      orderedScrapsComments[i] = {
        ...(scrapComments || {}),
        ...(countsData || {})
      }
      if(orderedScrapsComments[i]?.comments?.length && !orderedScrapsComments[i].comment_count) {
        orderedScrapsComments[i].comment_count = orderedScrapsComments[i].comments.length;
      }
    })
    setScrapsComments(
      scrapsComments?.length
        ? [...scrapsComments, ...orderedScrapsComments]
        : orderedScrapsComments
    );

    const reactionsScrapIds = scrapsLikesRef.current.length
      ? scraps.slice(scrapsLikesRef.current.length).map((scrap) => scrap.id)
      : scraps.map((scrap) => scrap.id);

    const bookmarkScrapIdsToFetch = bookmarkedScrapIds.length 
      ? scraps.slice(bookmarkedScrapIds.length).map((scrap) => scrap.id)
      : scraps.map((scrap) => scrap.id);

    Promise.all([
      loadReactionsHandler(reactionsScrapIds, 'scrap_ids'),
      loadScrapsCommentsReactionsHandler(scrapsCommentsIds.map((scrapId,i) => {
        const commentIds: Array<CommentType['id']> = []
        if(orderedScrapsComments[i]?.comments) commentIds.push(...orderedScrapsComments[i].comments.map(comment => comment.id))
        return [scrapId, commentIds]
      })),
      loadBookmarksHandler(bookmarkScrapIdsToFetch, 'scrap_ids')
      
    ]).then((responses) => {
      const [scrapsReactionsResponse, scrapsCommentsReactionsResponse, bookmarkedIds] = responses;
      if(scrapsReactionsResponse.length) {
        scrapsLikesRef.current.push(...scrapsReactionsResponse);
        dispatch(updateScrapsLikesAndComments(scrapsReactionsResponse.filter(data => !!data).map(item => {
          const scrap = scraps.find(scrapData => item.id == scrapData.id);
          return {
            scrapId: item.id,
            data: {
              isLiked: item.is_reacted,
              likesCount: scrap?.reaction_count,
              commentsData: {
                type: 'group',
                id: group?.id,
                count: orderedScrapsComments.find(data => data.scrap_id ==item.id)?.comment_count,
              }
            }
          }
        })))
      }
      bookmarkedIds.length && setBookmarkedScrapIds(
        bookmarkedScrapIds.length
          ? [...bookmarkedScrapIds, ...bookmarkedIds]
          : bookmarkedIds
      )

      const newData = orderedScrapsComments.map((scrapComments, i) => {
        const fetchedReactions = scrapsCommentsReactionsResponse[i]
        if(!fetchedReactions?.reactions?.length) return scrapComments
        return {
          ...scrapComments,
          comments: scrapComments.comments.map((comment, commentIndex) => {
            return {
              ...comment,
              reaction: fetchedReactions.reactions[commentIndex] || null,
            }
          })
        }
      })
      setScrapsComments(scrapsComments?.length ?
        [...scrapsComments, ...newData] : newData)
    });
  }, [scrapsLoading, scraps]);
  useEffect(() => {
    if (oneScrapAction.type) {

      const { scrap: actionScrap, type: actionType, comments: actionComments } = oneScrapAction;
      
      if (actionType === 'edit') {
        updateEntryCache(client.cache, actionScrap, true);
        let shouldRemoveScrap = false

        if(actionScrap.tags &&
          groupFilters?.tags?.length &&
          !actionScrap.tags.some(
            (tag) =>
              groupFilters.tags.findIndex(
                (slug) => slug.toLowerCase() === tag.slug.toLowerCase()
              ) > -1
          )) shouldRemoveScrap = true
        else if(actionScrap.tags?.length && scrapsQueryVariables['untagged']) shouldRemoveScrap = true;    
        if(shouldRemoveScrap) {
          if(scraps.length === 1) {
            dispatch(setGroupFilters(null));
          } else {
            removeScrapFromGroupCacheHandler(actionScrap);
          }
          
        } 
      } else if(actionType === 'comments-and-likes') {
        const scrapIndex = scraps.findIndex(scrap => scrap.id === actionComments.scrap_id);
        setScrapsComments(old => {
          return old.map((scrapComment, i) => {
            if(i === scrapIndex) {
              return actionComments
            }
            return scrapComment
          })
        });
      } else removeScrapFromGroupCacheHandler(actionScrap);
      dispatch(setOneScrapAction(null));
    }
  }, [oneScrapAction]);

  useEffect(() => {
    if (!groupMessenger) return;
    const { type, commentsCount: comment_count } = groupMessenger;
    if (type === 'edit' || type === 'member-added') {
      refetchGroup();
    } else if (type === 'delete') {
      //
    } else if(type === 'member-removed') {
      refetchGroup();
      refetchGroupScraps();
      setPage(1);
    } else if(type === 'likes-and-comments') {
      updateGroupQuery(prevData => {
        return {
          ...prevData,
          getGroup: {
            ...prevData.getGroup,
            data: {
              ...prevData.getGroup.data,
              comment_count
            }
          }
        }
      })
    }
    dispatch(setGroupMessenger(null));
  }, [groupMessenger]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (page !== 1) setPage(1);
    setScrapsComments([]);
    scrapsLikesRef.current = [];
  
  }, [group_id, groupFilters]);
  let pageTitle = ''
  if(group) {
    if(group.nickname && group.members.length > 1) pageTitle = group.nickname;
    else {
      const members = returnAllGroupMembersExceptMe(group);
      pageTitle = members.map(({ display_name, user_name }) => display_name ?? user_name).join(', ')
    }
  }
  
  return (
    <>
      <SEOMeta title='Scrappi | Share' />
      <CollectionPage
        ref={ref}
        collection={group}
        masonryKey={masonryKey}
        scraps={scraps}
        collectionLoading={groupLoading}
        scrapsLoading={scrapsLoading}
        onLoadMore={loadMoreHandler}
        scrapCount={totalScraps}
        type="group"
        footerDropdownOptions={footerDropdownOptions}
      >
        {renderScrap}
      </CollectionPage>
    </>
  );
}
