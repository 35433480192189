import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {  isEmpty,parseNote } from '../../../helpers';
import { ReduxStateType } from '../../../redux/store';
import { returnImageURL } from '../../Gallery/helpers/returnImageURL';
import { ImagesItemsType } from '../../Gallery/types/imagesTypes';
import {
  CloseIcon,
  ImagesIcon,
  LeftArrowIcon,
  Replace,
} from '../../Global/icons';
import NewPopup from '../../Global/NewPopup';
import Tiptap from '../../Global/Tiptap';
import './styles.css';

interface Proptypes {
  imageList: ImagesItemsType[];
  onImageEdit?: () => void;
  onMoreImagesClick?: () => void;
  onImageClick?: (index: number) => void;
  onReplaceClick?: () => void;
  children?: React.ReactNode;
}

const ScrapSavedGallery = ({
  imageList,
  onImageEdit,
  onMoreImagesClick,
  onImageClick,
  onReplaceClick,
  children
}: Proptypes) => {
  const [imageLightBox, setImageLightBox] = useState<string>(null);
  const[imageDesc,setImageDesc]=useState<string>();
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const {backUrl,scrollToBottom,scrapPopup} = useSelector((state: ReduxStateType) => state.utils);
  let images = imageList.sort((a,b) => a.order - b.order);
    
  let elem = document.getElementsByClassName('scrap-share__actions-toggle-description')[0];
 


  // images = [images[0]]
  function renderImage(imageUrl: string, className = '', count = null, index = -1) {
    let imageClass = 'scrap-saved-gallery__image-container';
    if (className) imageClass += ' ' + className;
    if (count) imageClass += ' scrap-saved-gallery__image-container--clickable';
    let imageControlElement = null;
    if (count) { 
      imageControlElement = (
        <span className="scrap-saved-gallery__image-count">+{count}</span>
      );
    }  
    // else
    //   imageControlElement = (
    //     <button
    //       className="scrap-saved-gallery__expand-image-icon"
    //       onClick={() => {
    //         setImageLightBox(imageUrl);
    //         setImageDesc(imageDesc)
    //       }}
    //     >
    //       <FullScreenIcon />
    //     </button>
    //   );

    const scrollToToggle=()=>{
      (scrollToBottom || scrapPopup?.scrollToBottom) &&
      elem?.scrollIntoView({ behavior: 'smooth' });
    }
    return (
      <div
        className={imageClass}
        onClick={() => {
          if (count) {
            if (onMoreImagesClick) onMoreImagesClick();
            else setShowGalleryModal(true);
          }
        }}
      >
        <picture>
          <source srcSet={imageUrl} type="image/heic" />
          <img
            alt=""
            onLoad={scrollToToggle}
            src={imageUrl}
            className="scrap-saved-gallery__image"
            onClick={(e) => {
              if (onImageClick && index > -1) onImageClick(index);
            }}
          />
        </picture>

        {imageControlElement}
      </div>
    );
  }
  let trimmedImages = [...images];
  if (images.length > 3) trimmedImages = images.slice(0, 2);
  let imagesClass = 'scrap-saved-gallery__images';
  if (images.length === 1)
    imagesClass += ' scrap-saved-gallery__images--single';
  else if (images.length === 2)
    imagesClass += ' scrap-saved-gallery__images--double';
  else imagesClass += ' scrap-saved-gallery__images--triple';
  return (
    <div className="scrap-saved-gallery">
      <div className="scrap-saved-gallery__images-container">
        <div className={imagesClass}>
          {trimmedImages.map((imageData, i) =>
            renderImage(returnImageURL(imageData.image), '', '', i)
          )}
          {images.length > 3 &&
            renderImage(
              returnImageURL(images[2].image),
              'scrap-saved-gallery__image-container--with-count',
              images.length - trimmedImages.length - 1
            )}
          {(onReplaceClick && images.length === 1) && (
            <button
              onClick={onReplaceClick}
              className={`scrap-saved-gallery__replace-button`}
            >
              <Replace />
            </button>
          )}
        </div>
        {children && children}
      </div>
      {(onImageEdit && onReplaceClick) && (
        <button
          onClick={onImageEdit}
          className="scrap-saved-gallery__images-edit-button"
        >
          <ImagesIcon /> Add/Edit Images
        </button>
      )}

      {imageLightBox && (
        <NewPopup
          size="Extra large"
          fullMobile={true}
          header={{
            heading: 'Image Details',
            customRender: (close) => (
              <header className="new-popup__header-inner-container">
                <h3 className="new-popup__header-heading">
                  <button
                    onClick={() => {
                      setImageLightBox(null);
                      close();
                    }}
                    className="new-popup__header-button"
                  >
                    <LeftArrowIcon className="new-popup__header-button__icon" />
                  </button>
                  Image Details
                </h3>
                <button
                  onClick={() => {
                    setImageLightBox(null);
                    close();
                  }}
                  className="new-popup__header-button"
                >
                  <CloseIcon className="new-popup__header-button__icon" />
                </button>
              </header>
            ),
          }}
          body={{
            className: 'add-view-full-popup',
          }}
          controlled={{
            show: !!imageLightBox,
            setShow: (val) => setImageLightBox(null),
          }}
        >
          {(close) => (
            <>
              <img
                src={imageLightBox}
                alt="add-image__view-full"
                className="add-image__view-full__image"
              />
              {!isEmpty(parseNote(imageDesc), true) && imageDesc && (
                <div className="scrap-saved-gallery__popup-image-description">
                  <Tiptap text={parseNote(imageDesc)} edit={false} />
                </div>
              )}
            </>
          )}
        </NewPopup>
      )}
      {showGalleryModal && (
        <NewPopup
          className="scrap-saved-popup"
          header={{
            heading: 'Image Details',
            customRender: (close) => (
              <header className="new-popup__header-inner-container">
                <h3 className="new-popup__header-heading">
                  <button
                    onClick={() => {
                      close();
                    }}
                    className="new-popup__header-button"
                  >
                    <LeftArrowIcon className="new-popup__header-button__icon" />
                  </button>
                  Image Details
                </h3>
                <button
                  onClick={() => {
                    close();
                  }}
                  className="new-popup__header-button"
                >
                  <CloseIcon className="new-popup__header-button__icon" />
                </button>
              </header>
            ),
          }}
          controlled={{
            show: showGalleryModal,
            setShow: setShowGalleryModal,
          }}
        >
          {(close) => (
            <div className="scrap-saved-gallery__popup-images">
              {images.map((image, i) => (
                <div key={i} className="scrap-saved-gallery__popup-image-item">
                  {renderImage(
                    returnImageURL(image.image),
                    'scrap-saved-gallery__image-container--full-width',
                    ''
                  )}
                  {!isEmpty(parseNote(image.desc), true) && image.desc && (
                    <div className="scrap-saved-gallery__popup-image-description">
                      <Tiptap text={parseNote(image.desc)} edit={false} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </NewPopup>
      )}
    </div>
  );
};

export default ScrapSavedGallery;
