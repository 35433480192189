import React from 'react';
import Icon from '../../Global/Icon';
import {
  BookmarkIcon,
  HeartIcon,
  MinusIcon,
  PencilEditIcon,
  ReplyIcon,
  ShareIcon,
  TrashOutlineIcon,
  TripleDotIcon,
} from '../../Global/icons';
import { scrapType } from '../../../types/scrapType';
import { formatTimestampDate, showFullDate } from '../../../helpers';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import MyDropDown from '../../Global/MyDropDown';
import StateLabel from '../../Global/StateLabel';
import { scrapCardViewType } from '..';
import ScrapCardFooterLike from "./ScrapCardFooterLike";
type dropdownType = {
  valueField: 'delete' | 'edit' | 'remove' | 'share' | 'bookmark';
  textField: string;
  className?: string;
  icon: React.ReactElement;
  isIconStroked?: boolean;
};

interface Proptypes {
  onBookmarkClick?: () => void;
  onDeleteClick?: () => void;
  onEditClick?: (scrap: scrapType, val?: boolean) => void;
  state?: number;
  userName?: string;
  copied?: boolean;
  handleCopy?: () => void;
  scrap?: scrapType;
  onRemoveClick?: () => void;
  date?: string;
  onCommentClick?: () => void;
  onLikeToggled?: (isLiked: boolean) => void;
  onDropdownOpened?: () => void;
  onDropdownClosed?: () => void;
  isCommentActive?: boolean;
  commentsCount?: number;
  reactionsCount?: number;
  view?: scrapCardViewType;
  isBookmarked?: boolean;
}

const ScrapCardFooter = ({
  onBookmarkClick,
  onDeleteClick,
  onEditClick,
  state,
  userName,
  copied,
  handleCopy,
  scrap,
  onRemoveClick,
  date,
  onCommentClick,
  onLikeToggled,
  onDropdownClosed,
  onDropdownOpened,
  commentsCount,
  isCommentActive,
  view,
  isBookmarked
}: Proptypes) => {
  const user = useSelector((state: ReduxStateType) => state.user);

  function dropdownOptionClickHandler(option: Partial<dropdownType>) {
    switch (option.valueField) {
      case 'delete':
        onDeleteClick();
        break;
      case 'edit':
        onEditClick(scrap, false);
        break;
      case 'remove':
        onRemoveClick();
        break;
      case 'share':
        handleCopy();
        break;
      case 'bookmark':
        onBookmarkClick();
        break;

      default:
        break;
    }
  }

  return (
    <div className="scrap-card__footer">
      <div className="scrap-card__footer-left">
        <StateLabel state={state} onClick={state > 1 ? dropdownOptionClickHandler.bind(null, { valueField: "share" }) : null} />

        {copied && (
          <div className="scrap-card__copied">
            <Icon iconClass="check-square" size="big" />
            Link copied to clipboard
          </div>
        )}

        {date && (
          <p className="scrap-card__footer-date" title={showFullDate(date, user.timezone)}>
            Edited {formatTimestampDate(date, user.timezone)}
          </p>
        )}
      </div>
      <div className="scrap-card__footer-right">
        <ScrapCardFooterLike scrapId={scrap.id} userId={user.user_id} onLikeToggled={onLikeToggled} />

        {(onCommentClick && user.user_id) && (
          <button
            onClick={onCommentClick}
            className={`scrap-card__footer-button scrap-card__comment-button${
              isCommentActive ? ' scrap-card__footer-button--active' : ''
            }`}
          >
            <ReplyIcon />
            {commentsCount ? commentsCount : ''}
          </button>
        )}

        <button
          onClick={onBookmarkClick}
          className={'scrap-card__footer-button scrap-card__bookmark-button'}
        >
          <BookmarkIcon isFilled={isBookmarked} />
        </button>
      </div>
    </div>
  );
};

export default ScrapCardFooter;
