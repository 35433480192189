import React from 'react';
import { NewScrapSelectOptionType } from '../CreateScrapIconToggler';

interface Proptypes {
  option: NewScrapSelectOptionType;
  onClick: () => void;
}

const SelectScrapItem = ({ option, onClick }: Proptypes) => {
  const { ScrapIcon, title } = option;
  return (
    <li key={title} onClick={onClick} className="select-scrap__item">
      <span className="select-scrap__item__icon">
        <ScrapIcon />
      </span>
      <span className="select-scrap__item__title">{title}</span>
    </li>
  );
};

export default SelectScrapItem;
