import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../../helpers/apolloHelpers';
import { defaultResponse } from '../../../containers/data/defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['login_via', 'Int = 1'],
  ['social_id', 'String'],
  ['token', 'String'],
  ['user_name', 'String'],
  ['email', 'String'],
  ['password', 'String'],
  ['password_confirmation', 'String'],
  ['type', 'String = "web"'],
  ['timezone', 'String = "America/New_York"'],
  ['country', 'String = "United States"'],
  ['email_verified', 'Boolean = false'],
  ['register_from', 'Int'],
  ['phone', 'String'],
  ['otp', 'String'],
  ['country_code', 'String'],
  ['country_iso', 'String']
]);

export function REGISTER(responseString = '') {
  let dataString = `
    access_token
    user {
      id
      name
      email
      phone
      profile {
        onboard_status
      }
      creator_initials
      created_at
      updated_at
      can_send_invite
      login_via
      register_from
    }
    new_user
  `
  if(responseString) dataString = responseString
  return gql`
    mutation Register(${parametersTypeString}) {
      register(${parametersAssignString}) {
        ${defaultResponse}
        data {
          ${dataString}
        }
      }
    }
  `
}

export const GET_EMAIL = gql`
  query RegisterDetail($token: String!) {
    registerDetail(token: $token) {
      data {
        email
        phone
        country_code
        country_iso
      }
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;
