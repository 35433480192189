import React, { Fragment } from 'react';
import Icon from '../../../Global/Icon';
import './styles.css';

interface Proptypes {
  isSearchBarActive: boolean;
  toggleSearch: () => void;
  submitHandler: (e: React.FormEvent<HTMLFormElement>) => void;
  renderInputElement: Function;
  // Removed renderSearchFilter : Not needed as Global search is going to be unified
  // renderSearchFilter: Function;
}

const GlobalSearchBar = ({
  isSearchBarActive,
  toggleSearch,
  submitHandler,
  renderInputElement,
}: // renderSearchFilter,
Proptypes) => {
  return (
    <>
      <form onSubmit={submitHandler} className={`global-search-bar`} >
        {renderInputElement()}
      </form>
    </>
  );
};

export default GlobalSearchBar;
