import React from 'react';
import { imageSize } from '../../../../helpers/imageSize';
import './styles.css';
interface Proptypes {
  image?: string;
  className?: string;
}


const NewCollectionCardMedia = ({ image ,className}: Proptypes) => {
  return <img className={`new-collection-media ${className?className:""}`} alt="" src={imageSize(image,800)}></img>;
};

export default NewCollectionCardMedia;
