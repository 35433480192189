import React from 'react';
import { useHistory } from 'react-router-dom';
import { returnImageURL } from '../../../Gallery/helpers/returnImageURL';
import { AddMore, InviteIcon } from '../../../Global/icons';
import { fromString } from '@tiptap/core';

interface Proptypes {
  fromString?: string;
  image?: string;
  text?: string;
  className?: string;
  userName?:string;
  isInvite?: boolean;
  onClick?: () => void;
  setCollaboratorsDetail?:(val:boolean)=>void;
  isEditPopup?:boolean;
}

const CollectionHeaderAvatar = ({
  fromString,
  userName,
  image,
  text,
  className,
  setCollaboratorsDetail,
  isInvite,
  onClick,
  isEditPopup
}: Proptypes) => {
  const history = useHistory();
  if (isInvite)
    return (
      <li
        onClick={e => {
          e.stopPropagation()
          onClick()
        } }
        className={
          className ||
          'collection-header__footer-collaborator collection-header__footer-collaborator--invite'
        }
      >
        {isEditPopup ? <AddMore /> : <InviteIcon className="collection-header__footer-collaborator-invite-icon" />}
      </li>
    );

  return (
    <li
      onClick={(e)=>{
        e.stopPropagation();
        if(onClick) onClick();
        else if(userName) history.push(`/${userName}${fromString ?? ''}`);
      }}
      className={className || 'collection-header__footer-collaborator'}
    >
      {image || !text ? (
        <img
          src={image ? returnImageURL(image) : '/dashboard/img/default-avatar.jpg'}
          alt="avatar"
          className="collection-header__footer-collaborator-avatar"
        />
      ) : (
        <span className="collection-header__footer-collaborator-text">
          {text}
        </span>
      )}
    </li>
  );
};

export default CollectionHeaderAvatar;
