import React from 'react';
import './styles.css';
import LabelTextInput from '../LabelTextInput';

interface Proptypes {
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  placeholder: string;
  onChange?: (val: string) => void;
  value?: string;
  className?: string;
  label: string;
}

const SearchModal = ({
  disabled,
  placeholder,
  onChange,
  value,
  className,
  label,
  onBlur,
  onFocus
}: Proptypes) => {
  return (
    <div className={`search-modal${disabled ? ' search-modal--disabled' : ''}`}>
      <LabelTextInput
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        inputType="text"
        value={value}
        label={label}
        onChange={onChange}
      />  
    </div>
    
  );
};

export default SearchModal;
