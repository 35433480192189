import React, { createContext, useState } from 'react';

interface ReloadContextType {
  reload: boolean;
  setReload: (value: boolean) => void;
}
const ReloadContext = createContext<ReloadContextType>({
  reload: false,
  setReload: function (val) {},
});

export function ReloadContextProvider(props) {
  const [reload, setReload] = useState(false);
  const context = {
    reload,
    setReload,
  };
  return (
    <ReloadContext.Provider value={context}>
      {props.children}
    </ReloadContext.Provider>
  );
}

export default ReloadContext;
