import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const WebPageIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 0H3C1.3 0 0 1.3 0 3V15C0 16.7 1.3 18 3 18H17C18.7 18 20 16.7 20 15V3C20 1.3 18.7 0 17 0ZM3.4 4.5C2.7 4.5 2.2 3.9 2.2 3.3C2.2 2.7 2.7 2 3.4 2C4.1 2 4.6 2.6 4.6 3.2C4.6 3.8 4.1 4.5 3.4 4.5ZM6.2 3.2C6.2 2.6 6.7 2 7.4 2C8.1 2 8.6 2.6 8.6 3.2C8.6 3.8 8 4.4 7.4 4.4C6.8 4.4 6.2 3.9 6.2 3.2ZM12.8 8.2L10.2 12.2C10 12.5 9.7 12.6 9.4 12.7C9.1 12.8 8.8 12.5 8.6 12.3L7.2 10.3C6.9 9.8 7 9.2 7.4 8.9C7.9 8.6 8.5 8.7 8.8 9.1L9.4 9.9L11.2 7.1C11.5 6.6 12.1 6.5 12.6 6.8C13 7.1 13.1 7.7 12.8 8.2Z"
        fill="#969696"
      />
    </svg>
  );
};

export default WebPageIcon;
