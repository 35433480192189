import React from 'react';
import { CloseIcon, TrashIcon } from '../Global/icons';
import ScrapShare from '../ScrapDetails/components/ScrapShare';

interface Proptypes {
  close: () => void;
  scrapState: number;
  shareUrl: string;
  isChangingVisibility: boolean;
  onDeleteIconClick: () => void;
  onShare: () => Promise<string>;
  showDelete?: boolean;
}

const ScrapSavedHeader = ({
  isChangingVisibility,
  close,
  scrapState,
  shareUrl,
  onDeleteIconClick,
  onShare,
  showDelete
}: Proptypes) => {
  return (
    <header className="new-popup__header-inner-container">
      <h3 className="new-popup__header-heading">Scrap Saved</h3>
      <div className="create-scrap__header__actions">
        <span
          onClick={onDeleteIconClick}
          className="scrap-saved__header-delete new-popup__header-button"
        >
          <TrashIcon className="new-popup__header-button__icon" />
        </span>
        <div className="create-scrap__visibility">
          {!showDelete && (
            <ScrapShare
              handleEdit={async (data) => {
                return onShare();
                
              }}
              shareUrl={shareUrl}
              status={scrapState}
              type="Scrap"
              noUrlMessage="Share link will be generated after saving the Scrap."
              loading={isChangingVisibility}
            />
          )}
        </div>

        <button onClick={close} className="new-popup__header-button">
          <CloseIcon className="new-popup__header-button__icon" />
        </button>
      </div>
    </header>
  );
};

export default ScrapSavedHeader;
