import React from 'react';

interface Proptypes {
  className?: string;
}

const Tag = ({ className }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M6.79181 1.63455C9.58499 0.263003 14.905 -1.59478 17.2523 2.49332C17.2523 2.49332 17.4331 2.75519 17.4402 3.04077C17.4445 3.22747 17.3748 3.4241 17.1308 3.56574L2.06213 12.3139C1.44604 12.6716 1.07954 11.8824 1.07954 11.8824C-1.2589 7.79744 2.98232 4.10149 5.56189 2.34976L5.19353 1.70819C5.1388 1.61288 5.04574 1.54454 4.93677 1.51779C4.82779 1.49104 4.71122 1.50812 4.61118 1.5662L3.5124 2.2041C3.34916 2.29887 3.15722 2.32734 2.97779 2.28385C2.7981 2.23991 2.64702 2.12759 2.55682 1.97051C2.49729 1.86681 2.46911 1.74845 2.47085 1.62919C2.47133 1.5689 2.48061 1.50762 2.4977 1.44769C2.54995 1.26969 2.67044 1.11678 2.83368 1.02201L4.08516 0.295464C4.27649 0.184393 4.48507 0.111541 4.70238 0.0783377C4.91818 0.0465947 5.1387 0.0556556 5.34808 0.106476C5.47392 0.138143 5.5963 0.184171 5.71019 0.24395C5.78377 0.282424 5.85561 0.326024 5.92169 0.375906C6.09244 0.502103 6.23343 0.662055 6.33832 0.844729L6.79181 1.63455Z"
        fill="#EB6F19"
      />
      <path
        d="M3.83422 12.49C4.7258 13.9694 5.72349 15.4258 6.65102 16.279C8.95954 18.4043 12.1316 18.141 13.3795 17.6813C13.5631 17.6138 13.8388 17.6946 14.1429 17.784C14.528 17.8964 14.9567 18.0234 15.3048 17.8796C15.6044 17.6474 15.7096 17.211 15.8035 16.8202C15.8785 16.5108 15.9467 16.23 16.0983 16.1028C17.1164 15.247 18.9275 12.6174 18.2531 9.54341C18.1411 9.03041 17.9444 8.45541 17.6944 7.84893C17.3426 6.99567 16.8833 6.08157 16.3982 5.19596L3.83422 12.49Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default Tag;
