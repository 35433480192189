import React, { useState } from 'react';
import { ScrapImageData } from '../../../containers/ScrapImagesPopupContainer';
import { DragHandle } from '../../Gallery/components/AddedImages/helpers/draggables';
import { DragIcon, PencilEditIcon } from '../../Global/icons';
import Tiptap from '../../Global/Tiptap';
import './styles.css';
import LoaderImage from '../../Global/icons/LoaderImage';
interface Proptypes {
  imageData: ScrapImageData;
  onCropClick: () => void;
  onDescriptionChange: (text: string) => void;
}
const ScrapImagesListItem = ({
  imageData,
  onCropClick,
  onDescriptionChange,
}: Proptypes) => {
  const [load, setLoad] = useState(true);
  return (
    <li className="scrap-images-list__item">
      <DragHandle>
        <button className="scrap-images-list__item-handle-button" tabIndex={-1}>
          <DragIcon className="scrap-images-list__item-handle-icon" />
        </button>
      </DragHandle>
      <div
        className={`scrap-images-list__item-image-container  ${
          load && 'scrap-images-list__loader-background'
        }`}
      >
        {load && (
          <div className={`${load ? 'scrap-images-list__loader' : 'scrap-images-list__loader-hide' }`}>
            <LoaderImage />
          </div>
        )}
        <img
          alt="loader"
          className={`scrap-images-list__item-image ${load && 'scrap-images-list__loader-hide' }`}
          src={imageData.imageViewUrl}
          onLoad={() => {
            setLoad(false);
          }}
        />
        <button
          onClick={onCropClick}
          className="scrap-images-list__item-edit-icon"
        >
          <PencilEditIcon />
        </button>
      </div>
      <div className="scrap-images-list__item-description">
        <Tiptap
          onChange={onDescriptionChange}
          placeholder="Write a caption"
          hideAllTools
          edit
          labelledEditor
          label="Caption"
          text={imageData.description}
        />
      </div>
    </li>
  );
};

export default ScrapImagesListItem;
