import React from 'react';

interface Proptypes {
  className?: string;
}

const MediaNotFound = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75575 14.4L7.31131 10.8444L17.0891 20.6222L23.3113 14.4L28.6446 19.7333V3.55556H3.75575V14.4ZM3.75575 19.4293V28.4444H9.26686L14.5753 23.1378L7.31131 15.8738L3.75575 19.4293ZM14.2962 28.4444H28.6446V24.7627L23.3113 19.4293L14.2962 28.4444ZM1.97797 0H30.4224C30.8939 0 31.3461 0.187301 31.6795 0.520699C32.0129 0.854097 32.2002 1.30628 32.2002 1.77778V30.2222C32.2002 30.6937 32.0129 31.1459 31.6795 31.4793C31.3461 31.8127 30.8939 32 30.4224 32H1.97797C1.50648 32 1.05429 31.8127 0.720894 31.4793C0.387496 31.1459 0.200195 30.6937 0.200195 30.2222V1.77778C0.200195 1.30628 0.387496 0.854097 0.720894 0.520699C1.05429 0.187301 1.50648 0 1.97797 0ZM22.4224 12.4444C21.7152 12.4444 21.0369 12.1635 20.5368 11.6634C20.0367 11.1633 19.7558 10.485 19.7558 9.77778C19.7558 9.07053 20.0367 8.39226 20.5368 7.89216C21.0369 7.39206 21.7152 7.11111 22.4224 7.11111C23.1297 7.11111 23.8079 7.39206 24.308 7.89216C24.8081 8.39226 25.0891 9.07053 25.0891 9.77778C25.0891 10.485 24.8081 11.1633 24.308 11.6634C23.8079 12.1635 23.1297 12.4444 22.4224 12.4444Z"
        fill="#616161"
      />
    </svg>
  );
};

export default MediaNotFound;
