import React from 'react';
import Icon from '../Icon';
import './styles.css';

interface Proptypes {
  isChecked: boolean;
  onChange: () => void;
  label: string;
  id: string;
  children: React.ReactChild;
}
const CheckBox = (props: Proptypes) => {
  const { isChecked, onChange, label, id, children } = props;
  return (
    <div
      key={`batch-edit-i=${id}-${label}`}
      className={`custom-checkbox${isChecked ? ' custom-checkbox--checked':''}`}
      onClick={onChange}
    >
      {children}
      <input
        type="checkbox"
        className="custom__checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <span className="custom-label__check">
        <Icon iconClass="check" size="big" />
      </span>
    </div>
  );
};

export default CheckBox;
