import { OneScrapActionType } from '../store/oneScrap';

export const setOneScrapAction = (payload: OneScrapActionType) => {
  return {
    type: 'SET_ONE_SCRAP_ACTION',
    payload: payload
      ? payload
      : {
        type: null,
        scrap: null,
      },
  };
};
