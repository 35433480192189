import { gql } from '@apollo/client';

export const SEARCH_COLLABORATORS = gql`
query globalSearchUsersForInvitation($first: Int, $page: Int, $text: String) {

    globalSearchUsersForInvitation(
    first :$first
     page: $page
     text: $text
   ) {
      data{
     knot{
         userName
       last_name
       email
       first_name
       display_name
       avatar
       bio
       creator_initials
     }
     }
     http_code
     status
     messages  {
       error
       success
     }
    }
}
 
`;
