import React, { useContext, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { parseFromTimeZone, formatToTimeZone } from 'date-fns-timezone';
import TagsList from '../Global/TagsList';
import ItemMeta from '../Meta/ItemMeta';
import RenderTitleAndNotes from './CardComponents/RenderTitleAndNotes';
import CheckBox from '../Global/CheckBox';
import EntryActionContext from '../../helpers/contexts/entryActionContext';
import { scrapTitle } from './cardHelpers';
import { isViewTrue } from '../../helpers/parseViewSettings';

import { scrapsSettingsMapper } from '../../variableMappers/viewSettings';

import { entryTypes } from '../../types/feed';
import { userTypes } from '../../types/user';
import { CardViewType } from './types/cardView';
import { BatchEditType } from '../../types/batchEdit';

import {
  AllScrapsViewSettingsType,
  CollectionsViewSettingsType,
} from '../../types/viewSettings';
import { updateBatchEditScraps } from '../../redux/action/batchEditAction';
import { cardSettingsConfigType } from './CardComponents/CardSettings';
import { getBrowserTimezone, noteSummary, pendingImage } from '../../helpers';
import TagsView from '../Global/TagsView';
import { CollectionIcon, CreatedAtIcon, NewPencil } from '../Global/icons';

import { useApolloClient } from '@apollo/client';
import useInterval from '../../helpers/customHooks/useInterval';
import { returnFullName } from '../../helpers/returnFullName';
import CardMore from './CardComponents/CardMore';
import showDate, { compareDates, showTime } from '../../helpers/showDate';
import { ReduxStateType } from '../../redux/store';

export const animationDurationWait = 320; // Duration of animation for card more to appear in milli seconds


interface Proptypes {
  onEditClick?: (val?:boolean) => void;
  onDeleteClick?: () => void;
  settingsConfig?: cardSettingsConfigType;
  children?: (onMoreClick: () => void) => React.ReactElement;
  containerClass: string;
  dontRenderItemMeta?: boolean;
  view: CardViewType;
  entry: entryTypes;
  activeStoryView?;
  user?: userTypes;
  source?: string;
  search?: string;
  addPanelDirectly?: (pid: string) => Promise<void>;
  handleStoryConfig?: (type: 'title' | 'notes', val: boolean) => void;
  editing?: boolean;
  collectionScrapsViewSettings: CollectionsViewSettingsType;
  allScrapsViewSettings: AllScrapsViewSettingsType;
  batchEdit: BatchEditType;
  cta?: string; // url link to ope
  ctaLabel?: string;
  onCardClick?: (entry: entryTypes) => boolean;
  showProfile?: boolean;
  collectionId?:string;
  reloadCollection?: () => void;
  iAmCollaborator?:boolean;
  collectionOwner?:string;
}

const CardWrapper = (props: Proptypes) => {
  const {
    onEditClick,
    onDeleteClick,
    settingsConfig,
    children,
    containerClass,
    view,
    entry,
    handleStoryConfig,
    addPanelDirectly,
    user,
    source,
    editing,
    collectionScrapsViewSettings,
    allScrapsViewSettings,
    batchEdit,
    onCardClick: overridenCardClick,
    collectionId,
    reloadCollection,
    iAmCollaborator,
    collectionOwner
  } = props;
  const dispatch = useDispatch();
  let settings: CollectionsViewSettingsType | AllScrapsViewSettingsType;
  const isDetailView =
    view &&
    ['detailFullscreen', 'detailSlideOutFull', 'detailSlideOut'].includes(view);
  switch (view) {
    case 'default':
    case null:
    case undefined:
      settings = allScrapsViewSettings;

      break;
    case 'collectionEdit':
    case 'collectionPreview':
      settings = collectionScrapsViewSettings;
      break;
    default:
      break;
  }
  const { width } = useSelector((state: ReduxStateType) => state.utils.dimensions);
  const userData = useSelector(
    (state: { user: userTypes }) => state.user as userTypes
  );
  const { setEntryAction, entryAction } = useContext(EntryActionContext);
  let initialShowMore = null;
  if(entry?.isMetaExpanded) initialShowMore = 'meta';
  else if(entry?.isUgcExpanded) initialShowMore = 'ugc-description';
  if(width < 640) initialShowMore = null;
  const [showMore, setShowMore] = useState<'meta' | 'ugc-tags' | 'ugc-collections' | 'ugc-description' |'anotations'>(initialShowMore);
  const history = useHistory();
  
  let notesProperty = 'notes';
  if (entry.type === 'annotation') notesProperty = 'comment';
  else if (['gallery', 'document', 'product'].includes(entry.type))
    notesProperty = 'desc';
  const isDescriptionEmpty = !(noteSummary(entry[notesProperty]) || '').trim();
  const onCheckBoxClick = () => {
    const searchedIndex = batchEdit.selectedScraps.findIndex(
      (scrap) => +scrap.pid === +entry.pid
    );
    if (searchedIndex < 0) {
      dispatch(updateBatchEditScraps([entry, ...batchEdit.selectedScraps]));
    } else {
      if (batchEdit.preventDeselect && batchEdit.selectedScraps.length <= 1)
        return alert(
          'At least one Scrap must be selected to perform bulk action'
        );
      const tempScraps = [...batchEdit.selectedScraps];
      tempScraps.splice(searchedIndex, 1);
      dispatch(updateBatchEditScraps(tempScraps));
    }
  };
  function showDateHandler(date: string, fullDate?: boolean) {
    const zonedDate = parseFromTimeZone(date, {
      timeZone: 'UTC',
    });
    const dateObject = new Date(zonedDate);
    const todayDate = new Date();

    const formattedTime = formatToTimeZone(
      dateObject,
      'MM.DD.YYYY HH:mm:ss.SSS',
      {
        // timeZone: 'UTC',
        timeZone: user.timezone || getBrowserTimezone(),
      }
    );
    // If full date is true, then show both time and date
    if (fullDate) return showDate(formattedTime, true, false);

    if (dateObject.toDateString() === todayDate.toDateString()) {
      // Meaning the it is recently updated show, only show time
      return showTime(formattedTime);
    }
    // Else show Date only
    return showDate(formattedTime, false, false, true);
  }
  const onCardClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (batchEdit.mode) return;
    if (overridenCardClick) {
      const continueForward = overridenCardClick(entry);
      if (!continueForward) return;
    }
  };
  const moreClickHandler = (type: 'tags' | 'collections' | 'notes',currentlyExpanded?: boolean) => {
    const scrapData: Partial<entryTypes> = {
      uid: entry.uid,
      pid: entry.pid,
      id: entry.id,
      type: entry.type
    }
    if(width < 640) { 
      if(type === 'collections') {
        scrapData.isCollectionsExpanded = !currentlyExpanded;
  
      } else if(type === 'tags') scrapData.isTagsExpanded = !currentlyExpanded;
      else scrapData.isUgcExpanded = true;
      setEntryAction({
        type: 'edit',
        entry: scrapData,
      })
      return true;
    }
    let showMoreValue : "meta" | "ugc-tags" | "ugc-collections" | "ugc-description" | "anotations";
    if(type === 'collections') showMoreValue = 'ugc-collections'
    else if(type === 'tags') showMoreValue = 'ugc-tags';
    else if(type === 'notes') showMoreValue = 'ugc-description'
    showMoreValue && setShowMore(showMoreValue)
    setTimeout(() => {
      scrapData.isUgcExpanded = true;
      setEntryAction({
        type: 'edit',
        entry: scrapData,
      })
    }, animationDurationWait)
    
    
    return false;
  }
  const parsedConfig = entry.config ? JSON.parse(entry.config)?.img_status : 3;

  const client = useApolloClient();

  let cancel = !['page', 'screenshot', 'social', 'product'].includes(
    entry.type
  );

  if (!cancel && (parsedConfig === 2 || entry?.image || entry?.thumbnail)) {
    cancel = true;
  }
  const hideTitle = ['product', 'page', 'social'].includes(entry.type);
  const fetch = () =>
    pendingImage({
      parsedConfig: parsedConfig,
      uid: entry?.uid,
      image: entry?.image,
      thumbnail: entry?.thumbnail,
      client: client,
      setEntryAction: setEntryAction,
    });

  useInterval(fetch, 30000, cancel);
  const showUser = ['collectionEdit', 'collectionPreview'].includes(view);
  const className = `${containerClass}${
    showUser ? ' card__with-user' : ''
  } card--${entry.type}`;
  const showTitle =
    !isDetailView &&
    !hideTitle &&
    scrapTitle(entry) &&
    (!settings ||
      isViewTrue(scrapsSettingsMapper.title, settings) ||
      entry.type === 'note');
  let entryUrl = entry.updated_url || entry.url || entry.domain;
  if (entry.type === 'document') entryUrl = entry.file;
  let avatar, userName;
  if (!view) {
    avatar = user.avatar;
    userName = user.userName;
  } else {
    avatar = entry.avatar ?? entry.owner?.avatar;
    userName = entry.userName ?? entry.owner?.username;
  }
  const fullName = returnFullName(
    entry?.owner?.first_name,
    entry?.owner?.last_name
  );
  const wrapperElement = (
    <div
      className={`${className} ${
        entry.url && entry.image_type !== 2 ? 'with-url' : ' '
      }`}
      onClick={onCardClick}
    >
      <div
        className="card__user"
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/${entry.userName}`);
        }}
      >
        {avatar ? (
          <img className="card__user-avatar" src={avatar} alt={userName} />
        ) : (
          <span
            data-initials={entry.creator_initials}
            className="card__user-initials"
          ></span>
        )}
        <div className="card__user-meta">
          <div className="card__user-names-wrapper">
            {entry.owner.display_name && (
              <span className="card__user-full-name">{entry.owner.display_name}</span>
            )}
            <span className="card__user-name">{userName}</span>
          </div>
          <div className="card__user-meta__date-time">
            <span className="card__user-updated-at card__created-at">
              <CreatedAtIcon /> {showDateHandler(entry.created_at)}
            </span>
            {!compareDates(entry.updated_at, entry.created_at) && (
              <span className="card__user-updated-at card__updated-at">
                <NewPencil /> {showDateHandler(entry.updated_at)}
              </span>
            )}
          </div>
        </div>
      </div>
      {children &&
        children(
          width < 640 || view === 'detailShare'
            ? null
            : () => {
              setShowMore('meta');
              setTimeout(() => {
                setEntryAction({
                  type: 'edit',
                  entry: {
                    uid: entry.uid,
                    pid: entry.pid,
                    id: entry.id,
                    type: entry.type,
                    isMetaExpanded: true,
                  },
                });
              }, animationDurationWait);
            }
        )}
      {showTitle && (
        <div className="card__title-and-settings-wrapper">
          {showTitle && (
            <RenderTitleAndNotes
              entry={entry}
              type="title"
              preview={view !== 'storyEdit'}
            />
          )}
        </div>
      )}

      {!isDetailView && (
        <>
          {!isDescriptionEmpty && noteSummary(entry[notesProperty])!=='null' && (
            <>
              <div className="card__notes-display">
                {(!settings ||
                  isViewTrue(scrapsSettingsMapper.description, settings) ||
                  entry.type === 'note') && (
                  <RenderTitleAndNotes
                    hideLess={width < 640}
                    onMoreClick={moreClickHandler.bind(null, 'notes', false)}
                    entry={entry}
                    type="notes"
                    notesProperty={notesProperty}
                    preview={view !== 'storyEdit'}
                  />
                )}
              </div>
              {view === 'storyEdit' && (
                <p>
                  <input
                    type="checkbox"
                    defaultChecked={entry.story_config?.hide_notes}
                    onChange={(e) =>
                      handleStoryConfig('notes', e.target.checked)
                    }
                  />{' '}
                  Hide Description
                </p>
              )}
            </>
          )}

          <div className="tag-collection-wrapper">
            {entry?.collections?.length > 0 &&
              view !== 'detailShare' &&
              view !== 'collectionPreview' &&
              entry.userName === userData.userName && (
              <div className="card__collection">
                <TagsView
                  defaultExpanded={width < 640 && entry.isCollectionsExpanded}
                  list={entry.collections}
                  valueField="id"
                  labelField="title"
                  dataType="collectionType"
                  showMore
                  onMoreClick={moreClickHandler.bind(null, 'collections')}
                  hideLess={width < 640}
                >
                  <CollectionIcon />
                </TagsView>
              </div>
            )}
            {(!settings || isViewTrue(scrapsSettingsMapper.tags, settings)) &&
              view !== 'detailShare' &&
              view !== 'collectionPreview' &&
              entry.userName === userData.userName && (
              <TagsList
                hideLess={width < 640}
                onMoreClick={moreClickHandler.bind(null, 'tags')}
                showMore={true}
                edit={false}
                tags={entry.tags || []}
              />
            )}
          </div>
        </>
      )}
      <ItemMeta
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        settingsConfig={settingsConfig}
        entry={entry}
        source={source}
        addPanelDirectly={addPanelDirectly}
        view={view}
        hideModifiedDate={
          settings && !isViewTrue(scrapsSettingsMapper.updatedAt, settings)
        }
        collectionId={collectionId}
        reloadCollection={reloadCollection}
        iAmCollaborator={iAmCollaborator}
        collectionOwner={collectionOwner}
      />
      {editing && <span>Saving, Please wait</span>}

      {/* {renderShowMoreContent()} */}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`card__more-content-overlay${
          showMore ? ' card__more-content-overlay--visible' : ''
        }`}
      >
        <div
          className={`card__more-content${
            showMore
              ? ` card__more-content--visible card__more-content--${showMore}`
              : ''
          }`}
        >
          {showMore && (
            <CardMore
              show={showMore}
              // @ts-ignore
              entry={entry}
              onCloseClick={() => {
                setShowMore(null);
                setTimeout(() => {
                  setEntryAction({
                    type: 'edit',
                    entry: {
                      uid: entry.uid,
                      pid: entry.pid,
                      id: entry.id,
                      type: entry.type,
                      isAnnotationExpanded: false,
                      isMetaExpanded: false,
                      isUgcExpanded: false,
                    },
                  });
                }, animationDurationWait);
              }}
              view={view}
            />
          )}
        </div>
      </div>
    </div>
  );

  if (!batchEdit.mode) return wrapperElement;
  return (
    <CheckBox
      id={entry.pid}
      label="batchEdit"
      isChecked={
        batchEdit.selectedScraps.findIndex(
          (scrap) => +scrap.pid === +entry.pid
        ) >= 0
      }
      onChange={() => {
        onCheckBoxClick();
      }}
    >
      {wrapperElement}
    </CheckBox>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    collectionScrapsViewSettings:
      state.viewSettings.collectionScrapsViewSettings,
    allScrapsViewSettings: state.viewSettings.allScrapsViewSettings,
    batchEdit: state.batchEdit,
  };
};

export default connect(mapStateToProps)(CardWrapper);
