import { gql } from '@apollo/client';
import { addCollectionsToPanelRes } from './addCollectionToPanelRes';
import { defaultResponse } from './defaultResponse';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';

export default function COLLECTION(dataQueryString = '', showScrapsCount = false) {
  const [
    parametersTypeString,
    parametersAssignString,
  ] = createApolloQueriesString([
    ['collection_id', 'Int!'],
    ['username', 'String'],
    ['private_key', 'String'],
  ]);
  return gql`
    query Collection(${parametersTypeString}) {
      collection(${parametersAssignString}) {
        ${defaultResponse}
        ${showScrapsCount ? `
          scrapsCount {
            id
            scraps_count
          }
        ` : ''}
        ${dataQueryString ? `
          data {
            ${dataQueryString}
          }
        ` : ''}
      }  
    }
  `
}

const dataQueryString = `
  id
  user_id
  slug
  title
  desc
  status
  state
  private_key
  sort_by
  sort_param
  view_type
  cover_image
  user_name
  display_name
  avatar
  nickname
  show_author
  used_at
  created_at
  updated_at
  members {
    user_id
    display_name
    avatar
    user_name
    is_trusted
  } 
  reaction_count
  comment_count 
  is_trusted
`

export const COLLECTION_FOR_COLLECTION_PAGE = COLLECTION(dataQueryString, true);

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['id', 'ID'],
  ['title', 'String'],
  ['cover_image', 'String'],
  ['desc', 'String'],
  ['status', 'Int = 1'],
  ['state', 'Int'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['view_type', 'Int'],
  ['nickname', 'String'],
  ['show_author', 'Int'],
]);

export const UPSERT_COLLECTION = (responseString?: string) => {
 
  const allResponse = `
  data{
      id
      title
      cover_image
      desc
      status
      state
      sort_param
      sort_by
      view_type
      slug
      status
      show_author
      nickname
  }
  `;
  return gql`
  mutation UpsertCollection(${parametersTypeString}) {
      upsertCollection(${parametersAssignString}) {
          ${responseString || allResponse}
      }
  } 
`;
};

export const DELETE_COLLECTION = gql`
  mutation DeleteCollectionPanel($id: ID!) {
    deleteCollection(id: $id) {
      ${defaultResponse}
    }
  }
`;

export const COLLECTION_DELETE=gql`
mutation deleteCollection($id: ID!) {
  deleteCollection(id: $id) {
    ${defaultResponse}
  }
}
`;

export const ADD_PANEL_TO_COLLECTION = gql`
  mutation AddPanelToCollection($cid: [Int!]!, $pid: Int!) {
    upsertCollectionPanel(cid: $cid, pid: $pid) {
      ${defaultResponse}
    }
  }
`;

export const ADD_COLLECTIONS_TO_PANEL = gql`
  mutation AddCollectionsToPanel($collections: String, $pid: Int!) {
    addCollectionsToPanel(collections: $collections, pid: $pid) {
      ${addCollectionsToPanelRes}
    }
  }
`;

export const ORGANIZE_COLLECTION_PANEL = gql`
  mutation OrganizeCollectionPanel(
    $collection_id: Int!
    $pid: Int!
    $order: String!
  ) {
    organizeCollectionPanel(
      collection_id: $collection_id
      pid: $pid
      order: $order
    ) {
      ${defaultResponse}
    }
  }
`;

export const REMOVE_COLLECTION_TO_PANEL = gql`
mutation RemoveCollectionToPanel($collection_id: Int!, $pid: Int!) {
  removeCollectionToPanel(collection_id: $collection_id, pid: $pid) {
    ${defaultResponse}
  }
}
`;


export const GET_DATA_WITH_USER_COLLABORATED_FILTER = gql`
  query collectionsWithUserCollaboratedFilter(
    $page: Int
    $sort_param: String
    $sort_by: String
    $id: ID
    $first: Int
  ) {
    collectionsWithUserCollaboratedFilter(
      page: $page
      sort_param: $sort_param
      sort_by: $sort_by
      id: $id
      first: $first
    ) {
      data {
        total
        collections {
          cover_image
          desc
          id
          title
          view_type
          panel_count
          images
          updated_at
          created_at
          slug
          status
          state
          private_key
          owner {
            avatar
            username
            creator_initials
            first_name
            last_name
            display_name
          }
          collaborators {
            cc_id
            avatar
            username
            creator_initials
            role {
              role_id
              title
            }
          }
        }
      }
    }
  }
`;
