import React from 'react';
import { MediaNotFoundIcon } from '../../../Global/icons';
import './styles.css';
import { scrapType } from '../../../../types/scrapType';

interface PropTypes {
  parsedConfig?: number;
  image?: string;
  config?: string;
  className?: string;
}

function imageStatus(config: string) {
  try {
    const parsedConfig = JSON.parse(config);
    return parsedConfig.img_status;
  } catch {
    return 3;
  }
}
export const GlobalImageStatusOneScrap = ({
  imageData,
}: {
  imageData: scrapType['images'][number];
}) => {
  const { file_status, file_path } = imageData || {};
  if (!imageData || (+file_status === 3 && !file_path)) {
    return null;
  }
  return (
    <div className={`global-image-status`}>
      <MediaNotFoundIcon />
      <p className="global-image-status__text">Image Pending</p>
    </div>
  );
};

const GlobalImageStatus = (props: PropTypes) => {
  const { image, config, className } = props;
  if (imageStatus(config) === 3 && (image || image === null)) return null;
  return (
    <div className={`global-image-status ${className ? className : ''}`}>
      <MediaNotFoundIcon />
      <p className="global-image-status__text">Image Pending</p>
    </div>
  );
};

export default GlobalImageStatus;
