import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', ' ID!'],
  ['first', 'Int'],
  ['page', 'Int'],
  ['skip_first', 'Int'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['username', 'String'],
  ['private_key', 'String']
]);

export default function GetScrapDocuments(responseString = '') {
  return gql`
  query getScrapDocuments(${parametersTypeString}){
    getScrapDocuments(${parametersAssignString}){
      ${defaultResponse}
      paginatorInfo {
        total
      }
      data{
        id
        scrap_id
        file_name
        file_path
        file_type 
        ${responseString}
      }
    }
  } 
  `;
}
