import React from 'react';
import Icon from '../../Icon';
import './styles.css';
import { PropHelpers, convertTagsIntoCollection } from '../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import SideNavDropdownListItem from '../../../SideNav/SideNavDropdown/SideNavDropdownlistItem';
import { Collaborator, GlobeIcon,NewGlobeIcon } from '../../icons';
import { collectionType } from '../../../../types/collections';
import { parseTagFromSlug } from '../../../../helpers';
import { useDispatch } from "react-redux";
import { canPaste } from "../../../../redux/action/pasteDataAction";
const faPlusSquareIcon = faSquarePlus as IconProp;
/*
 * NOTE - CSS IS GETTING USED THAT OF SIDE NAV CONTAINER ONLY
 * PATH - src/containers/SideNavContainer.tsx
 */

interface propTypes {
  allData: PropHelpers['dataType'][];
  searchTerm: string;
  setSearchTerm: (val: string) => void;
  searchHandler: (text: string, itemsList: PropHelpers['dataType'][]) => void;
  placeholder?: string;
  filterList: PropHelpers['dataType'][];
  allowCreation?: boolean;
  onCreate: (name: string) => void;
  selectedItems: PropHelpers['dataType'][];
  onChange: (val: PropHelpers['dataType']) => void;
  itemDataType?:string;
}

export const CheckList = ({
  allData,
  searchTerm,
  setSearchTerm,
  searchHandler,
  placeholder,
  filterList,
  allowCreation,
  onCreate,
  selectedItems,
  onChange,
  itemDataType
}: propTypes) => {
  const dispatch = useDispatch();

  // RENDER LIST ITEM ALONG WITH MARKING IT AS ACTIVE OR INACTIVE
  const renderItem = (item: PropHelpers['dataType']) => {
    const collectionItem = { ...item } as collectionType;
    let active = false
    let renderConfig;
    if(collectionItem.id) {
      const { id, label } = parseTagFromSlug(collectionItem.slug);
      collectionItem.id = id;
      collectionItem.title = label;
    }
    active = selectedItems.findIndex((p) => (p as collectionType).id?.toString() === collectionItem.id?.toString()) >= 0;
  
    // @ts-ignore
    if (+item.state === 3) {
      renderConfig = {
        type: 'icon',
        render: (elementProps) => <NewGlobeIcon {...elementProps} />,
      };
    }

    if (collectionItem?.members&&collectionItem.members.length>0 && +collectionItem.state !== 3)
    {
      renderConfig = {
        type: 'icon',
        render: (elementProps) => <Collaborator {...elementProps} />,
      };
    }
    
  

    return (
      <SideNavDropdownListItem
        key={convertTagsIntoCollection(item).id}
        label={convertTagsIntoCollection(item).title}
        onClick={() => onChange(item)}
        isMultiselect
        active={active}
        renderConfig={renderConfig}
        type={itemDataType}
      />
    );
  };

  // RETURN OPTION FOR NEW ITEM CREATION
  const createOption = () => {
    if (!allowCreation) return null;
    if (
      allData.filter(
        (at) =>
          convertTagsIntoCollection(at).title.toLowerCase().trim() === searchTerm.toLowerCase().trim()
      ).length ||
      !searchTerm.length
    ) {
      return null;
    }

    // RETURN AN ITEM TO BE ABLE TO CREATE A NEW TAG
    return (
      <li
        onClick={() => {
          if (searchTerm.trim().length < 100) {
            onCreate(searchTerm);
          }
        }}
        className={`side-nav-dropdown__list-item checkbox-list__new-item`}
      >
        <div className="side-nav-dropdown__list-item-label-container">
          <FontAwesomeIcon icon={faPlusSquareIcon} />
          <p className="side-nav-dropdown__list-item-label checkbox-list__new-item--label">
            Create <i className="checkbox-list__create-quotation">"</i>
            <span className="checkbox-list__new-item--label_tag">
              {searchTerm.trim()}
            </span>
            <i className="checkbox-list__create checkbox-list__create-quotation">
              "{` `}
              {searchTerm.trim().length > 100 && (
                <div style={{ color: 'red' }}>Text Limit Exceeded</div>
              )}
            </i>
          </p>
        </div>
      </li>
    );
  };
  return (
    <>

      {allData.length >= 0 && (
        <div className="side-nav-dropdown__search">
          <input
            value={searchTerm}
            onChange={(e) => searchHandler(e.target.value.replace(/[^a-zA-Z0-9_\s]/g, ''), allData)}
            onBlur={() => {
              dispatch(canPaste(true));
              setSearchTerm(searchTerm.trim());
            }}
            onFocusCapture={() => dispatch(canPaste(false))}
            className="side-nav-dropdown__search-input"
            placeholder={placeholder || 'Type to search or create'}
          />
          <span className="side-nav-dropdown__search-icon">
            <Icon iconClass="search" size="normal" />
          </span>
        </div>
      )}
      <ul className="side-nav-dropdown__list side-nav-dropdown__list-action no_bg">
        {createOption()}
        {filterList.length > 0 && filterList.map((item) => renderItem(item))}
        {filterList.length === 0 && !allowCreation && (
          <li className="side-nav-dropdown__no-data-message">
            No Results found!
          </li>
        )}
      </ul>
    </>
  );
};

export default CheckList;
