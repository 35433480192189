// This function returns number of columns and widths of each card, that can fit in container of a certain width
// the width of card can never be less than passed value in parameter minimumCardWidth
// Gutter is the gap between two columns
export default function calculateCardWidth(
  minimumCardWidth: number,
  containerWidth: number,
  gutter: number
): { width: number; columns: number } {
  let columns = 0;
  let guttersCount = 0;
  let width = 0;
  for (let i = 1; i <= 20; i++) {
    let x = 0;
    const numberOfGutters = i === 1 ? 0 : i - 1;
    if (i === 1) x = (i + 1) * minimumCardWidth + gutter;
    else x = (i + 1) * minimumCardWidth + (numberOfGutters + 1) * gutter;
    if (x > containerWidth) {
      columns = i;
      guttersCount = numberOfGutters;
      break;
    }
  }
  if (columns > 1) guttersCount = columns - 1;
  width = (containerWidth - guttersCount * gutter) / columns;
  return { width, columns };
}