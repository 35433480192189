import React, { Fragment, useEffect, useRef } from 'react';

import './styles.css';
import { GroupType } from '../../containers/GroupShareContainer/types';
import { ContactWithGroupFlag } from '../../containers/GroupDetailsPopupContainer/types';
import Button from '../uiComponents/Button';
import { CircleTickIcon, TickIcon } from '../Global/icons';

export { GroupDetailsWarning } from './GroupDetailsWarning';

interface PropTypes {
  masonryKey?: string;
  isLoadingGroup?: boolean;
  isLoadingList: boolean;
  nickNameInputRenderer?: React.ReactElement;
  searchInputRenderer: React.ReactElement;
  warningRenderer?: React.ReactElement;
  list: GroupType['members'] | ContactWithGroupFlag[];
  children: (
    item: GroupType['members'][number] | ContactWithGroupFlag
  ) => React.ReactElement;
  onLoadMore: () => void;
  errorMessage?: string;
  showAdd: boolean;
  onAddClick: () => void;
  onNickNameSave?: () => void;
}

export default function GroupDetailsPopup({
  masonryKey,
  nickNameInputRenderer,
  searchInputRenderer,
  warningRenderer,
  children,
  list,
  isLoadingList,
  onLoadMore,
  errorMessage,
  showAdd,
  onAddClick,
  onNickNameSave,
}: PropTypes) {
  const ref = useRef<HTMLDivElement>();

  /**
   * Renders list of members in group details popup
   */
  const renderList = () => {
    if (list?.length) {
      return list.map(children)
    }
    if (isLoadingList) return null;

    return <h4 className='group-details__no-data-message'>No matching contact found.</h4>;
  };

  /**
   * Function to handle scroll and load more when it reaches the bottom
   */
  const scrollHandler = () => {
    if(isLoadingList) return;
    const elm = ref.current
    if(elm.scrollTop + elm.clientHeight > elm.scrollHeight - 10) {
      onLoadMore()
      // User has scrolled to the bottom of the element
    }
  }

  const render = () => {
    if (warningRenderer) return warningRenderer;
    else
      return (
        <>
          <div className="group-details__inputs">
            {nickNameInputRenderer && (
              <form onSubmit={e => {
                e.preventDefault();
                onNickNameSave && onNickNameSave();
              }} className={`group-details__nickname-input-wrapper${onNickNameSave ? ' group-details__nickname-input-wrapper--with-save' : ''}`}>
                {nickNameInputRenderer}
                {onNickNameSave && (
                  <button
                    type='submit'
                    className="group-details__nickname-save-button"
                    // onClick={onNickNameSave}
                  >
                    <CircleTickIcon />
                  </button>
                )}
              </form>
            )}
            {showAdd ? (
              <div className="group-details__add">
                <span className="group-details__add-label">Members</span>
                <Button
                  icon={<TickIcon />}
                  buttonType="orange-filled"
                  onClick={onAddClick}
                  className="group-details__add-button"
                >
                  Add
                </Button>
              </div>
            ) : (
              <div className="group-details__search-input-wrapper">
                {searchInputRenderer}
              </div>
            )}
          </div>

          <div key={masonryKey} className="group-details__contacts-list">
            {renderList()}
          </div>
        </>
      );
  };

  // THIS USEEFFECT TAKES CARE OF LOADING MORE IF THE PAGE IS NOT SCROLLABLE YET FOR ONSCROLL EVENT TO GET FIRED
  useEffect(() => {
    if(ref?.current && !isLoadingList ) {
      const element = ref.current;
      const { scrollTop, clientHeight, scrollHeight } = element;
      if(scrollTop === 0 && scrollHeight === clientHeight) {
        onLoadMore()
      }
    }    
  }, [ref, list, isLoadingList]);

  return (
    <div
      className={`group-details ${
        warningRenderer ? ' group-details--with-warning' : ''
      }`}
      ref={ref}
      onScroll={scrollHandler}
    >
      {render()}
      {errorMessage && <p className='group-details__error-message'>{errorMessage}</p>}
    </div>
  );
}
