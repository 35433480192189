import entry from '../containers/entry';
import { entryTypes } from '../types/feed';
import { scrapType } from '../types/scrapType';
import { scrapTypeUniqueID } from './scrapTypeConstants';

var slugify = require('slugify');

export const slugifyString = (str: string, slice = true) => {
  if (str) {
    var slug = str;
    if (slice) {
      //GET THE FIRST 15 CHARACTER OF STRING FOR SLUG
      slug = str.substring(0, 15);
    }
    return slugify(slug, { lower: true });
  }
  return '';
};

export const returnScrapSlug = (scrap: entryTypes | scrapType) => {
  let title = scrap?.title;
  if (!title?.trim().length) {
    title = '0';
  }
  let scrapSlug = slugifyString(title);
  if(scrap?.type){
    if (scrapTypeUniqueID[scrap?.type]) {
      let typeAndId = scrapTypeUniqueID[scrap.type]['key'] + '-' + scrap.id; // For scrap which have type (Non-one scrap data)
      
  
      return `${scrapSlug}/${typeAndId}`;
    }
    return "";
  } else {
    return `${scrapSlug}/${scrap?.id}`;
  }
  
};

export const returnScrapKey = (scrapType: string | number) => {
  if (scrapTypeUniqueID[scrapType]) {
    return scrapTypeUniqueID[scrapType]['key'];
  } else {
    let obj = Object.entries(scrapTypeUniqueID).filter(
      (a) => a[1].name === scrapType
    );
    /*
      * SAMPLE OBJ STRUCTURE:
        [
            [
                "page",
                {
                    "key": 5,
                    "name": "pages"
                }
            ]
        ]
     */
    if (obj.length && obj[0].length) {
      return obj[0][1]['key'];
    }
  }
  return scrapType;
};

export const returnScrapSlugInd = (
  name: string,
  type: string | number,
  id: string | number
) => {
  if (!name?.trim().length) {
    name = '0';
  }
  let scrapSlug = slugifyString(name);
  let scrapKey = returnScrapKey(type);
  let typeAndId = scrapKey + '-' + id;

  return `${scrapSlug}/${typeAndId}`;
};
