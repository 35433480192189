import axios from 'axios';
import { getToken } from "../../../apollo/getToken";
import * as config from "../../../settings.json";
import { scrapType } from "../../../types/scrapType";

export const getScrapTags = async (
  scrap: scrapType
) => {
  const token = getToken();
  return await axios.post(
    "https://content-extractor-dev.scrap-crawler.workers.dev",
    scrap,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
};

export const getScrapSummary = async (
  content: String
) => {
  const token = getToken();
  return await axios.post(
    "https://summary-dev.scrap-crawler.workers.dev",
    {
      content: content,
    },
  );
}
