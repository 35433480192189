import gql from 'graphql-tag';
import { UPSERT_COLLECTION } from '../../../../containers/data/collection';

const ADD_TAG = gql`
  mutation AddTag($title: String!) {
    upsertTag(title: $title) {
      id
      title
    }
  }
`;

export { ADD_TAG, UPSERT_COLLECTION as ADD_COLLECTION };