import React, { useState, useEffect } from 'react';

interface Proptypes {
  durationInSeconds: number;
  children?: (timeLeftInSeconds: number) => React.ReactElement;
}

const CountdownTimer = ({ durationInSeconds, children }: Proptypes) => {
  const [timer, setTimer] = useState(durationInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [durationInSeconds]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (children) return children(timer);
  return (
    <div>
      <h1>Countdown Timer</h1>
      <p>Time remaining: {formatTime(timer)}</p>
    </div>
  );
};

export default CountdownTimer;
