import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['text', 'String'],
  ['tags', '[TagSlugInput]'],
  ['untagged', 'Boolean'],
  ['first', 'Int'],
  ['search_after', 'String'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['tie_breaker_id', 'String'],
  ['uncategorized', 'Boolean']
]);

export default function Scraps(responseString = '') {
  return gql`
  query scraps(${parametersTypeString}){
    scraps(${parametersAssignString}){
      ${defaultResponse}
      paginatorInfo {
        total
      }
      data{
        id
        user_id
        user_name
        display_name
        avatar
        title
        desc
        status
        state
        private_key
        service
        meta
        meta_title
        meta_desc
        price
        created_at
        updated_at
        url
        domain
        favicon
        original_favicon
        annotations {
				  highlighted_text
        }
        annotations_count
        documents_count
        documents {
          file_name
          file_type
          file_path
        }
        audios_count
        audios{
          file_name
          file_path
        }
        videos_count
        videos{
          file_name
          file_path
        }
        images_count
        images {
          file_name
          file_path
          order
          file_status
          file_config
          desc
        }
        tags {
          slug
        }
        collections{
          id
        }
        reaction_count
        areFilesExpanded @client
        isUgcExpanded @client
        isMetaExpanded @client
        areAnnotationsExpanded @client
        isAnnotationExpanded @client
        isTagsExpanded @client
        isCollectionsExpanded @client
        ${responseString}
      }
    }
  } 
  `;
}
