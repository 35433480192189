import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const NewDownArrow = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3765 13.1956C12.7705 13.5826 12.7762 14.2158 12.3892 14.6098L7.38923 19.7007C7.2012 19.8922 6.94412 20 6.67578 20C6.40744 20 6.15036 19.8922 5.96233 19.7007L0.962332 14.6098C0.575342 14.2158 0.581046 13.5826 0.975072 13.1956C1.3691 12.8087 2.00224 12.8144 2.38923 13.2084L5.67578 16.5547L5.67578 0.999999C5.67578 0.447715 6.1235 -2.86409e-07 6.67578 -2.62268e-07C7.22807 -2.38127e-07 7.67578 0.447715 7.67578 1L7.67578 16.5547L10.9623 13.2084C11.3493 12.8144 11.9825 12.8087 12.3765 13.1956Z"
        fill="#FDF1E8"
      />
    </svg>
  );
};

export default NewDownArrow;
