import { entryTypes } from '../../types/feed';
import { BatchEditType } from '../../types/batchEdit';
const DEFAULT: BatchEditType = {
  mode: false,
  selectedScraps: [],
  preventDeselect: true,
};

interface actionType {
  type: string;
  payload: { value?: boolean; scraps: entryTypes[]; preventDeselect?: boolean };
}
const batchEditMode = (state = DEFAULT, action: actionType) => {
  switch (action.type) {
    case 'CLEAR_BATCH_EDIT_SCRAPS':
      return { ...state, selectedScraps: [] };
    case 'SET_BATCH_EDIT':
      return { ...state, mode: action.payload.value };
    case 'RESET_BATCH_EDIT':
      return DEFAULT;
    case 'UPDATE_SCRAPS_IN_BATCH_MODE':
      return {
        ...state,
        selectedScraps: action.payload.scraps,
      };
    case 'SET_PREVENT_DESELECT':
      return {
        ...state,
        preventDeselect: action.payload.preventDeselect,
      };
    default:
      return state;
  }
};

export default batchEditMode;
