import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CollectionPageContainer from '../../containers/CollectionPageContainer';
import OneScrapPopupContainer from '../../containers/OneScrapPopupContainer';
import CollaboratedCollectionsContainer from '../../containers/CollaboratedCollectionsContainer';

interface Proptypes {
  containerClass: string;
}
const CollectionPaths = (props: Proptypes) => {

  return (
    <Switch>
      <Route
        path="/collections"
        render={(props) => <CollaboratedCollectionsContainer allCollections />}
      />
      <Route
        path="/collections/share/:id"
        render={(props) => (
          <CollectionPageContainer {...props} view="collectionPreview" />
        )}
      />
      <Route
        path="/c/:id/:cID"
        render={(pProps) => {
          
          return (
            <>
              <CollectionPageContainer {...pProps} view="collectionEdit" />
              <Switch>
                <Route
                  path={`/c/${pProps.match.params.id}/:cID/scrap/:slug/:id`}
                  render={(props) => {
                    return (
                      <OneScrapPopupContainer />
                    );
                  }}
                />
              </Switch>
            </>
          );
        }}
      />
    </Switch>
  );
};

export default CollectionPaths;
