import React from 'react';
import PublicPageWrapper from '../components/Global/PublicPageWrapper';
import SearchResultsContainer from './SearchResultsContainer';

const GlobalSearchPublicWrapper = (props) => {
  return (
    <PublicPageWrapper>
      {/* <GlobalSearchWrapper {...props} className="sl-container" isPublic={true} /> */}
      <SearchResultsContainer isPublic />
    </PublicPageWrapper>
  );
};

export default GlobalSearchPublicWrapper;
