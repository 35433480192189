import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as config from '../../../settings.json';

interface Proptypes {
  inputRef?: React.MutableRefObject<ReCAPTCHA>,
}

const InvisibleRecaptcha = ({ inputRef }: Proptypes) => {
  return (
    <ReCAPTCHA
      className="invisible-recaptcha"
      sitekey={config.recaptchaSiteKey}
      size="invisible"
      ref={inputRef}
    />
  );
};

export default InvisibleRecaptcha;
