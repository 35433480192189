import { CommentType } from '../../components/Comments';
import { GroupType } from '../../containers/GroupShareContainer/types';
import { collectionType } from '../../types/collections';
import { scrapType } from '../../types/scrapType';

type scrapDataType = {
  likesCount: number;
  commentsData?: {
    count: number;
    id: collectionType['id'] | GroupType['id'];
    type: 'collection' | 'group';
    comments?: CommentType[]
  };
  isLiked: boolean;
};
export type ReduxScrapsLikesAndCommentsType = Map<
  scrapType['id'],
  scrapDataType
>;

export const UPDATE_SCRAPS_LIKES_AND_COMMENTS =
  'UPDATE_SCRAP_LIKES_AND_COMMENTS';
export const LIKE_SCRAP = 'LIKE_SCRAP';
export const DISLIKE_SCRAP = 'DISLIKE_SCRAP';
export const INCREASE_COMMENT_COUNT = 'INCREASE_COMMENT_COUNT';
export const RESET_SCRAPS_LIKES_AND_COMMENTS = 'RESE_SCRAPS_LIKES_AND_COMMENTS';

const defaultState: ReduxScrapsLikesAndCommentsType = new Map();
type actionType =
  | typeof UPDATE_SCRAPS_LIKES_AND_COMMENTS
  | typeof LIKE_SCRAP
  | typeof DISLIKE_SCRAP
  | typeof INCREASE_COMMENT_COUNT
  | typeof RESET_SCRAPS_LIKES_AND_COMMENTS;
  
export type scrapsLikesAndCommentsPayloadType = {
  scrapId: scrapType['id'];
  data?: Partial<scrapDataType>;
};


/**
 * scrapsLikesandComments reducer
 * It keeps the list of all the likes that are made by the user on scraps.
 * The list gets updated as more scraps are loaded across the app.
 * It will also save the comments count which will be related to collection
 */
export default function scrapsLikesAndComments(
  state = defaultState,
  action: {
    type: actionType;
    payload:
      | Array<scrapsLikesAndCommentsPayloadType>
      | scrapsLikesAndCommentsPayloadType;
  }
) {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_SCRAPS_LIKES_AND_COMMENTS:
      if (Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => [+item.scrapId, item.data]) as Iterable<
            readonly [scrapType['id'], scrapDataType]
          >),
        ]);
      } else {
        return new Map([...state, [+payload.scrapId, payload.data]]);
      }
    case DISLIKE_SCRAP:
    case LIKE_SCRAP:
      if (Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => {
            let data = state.get(+item.scrapId);
            if (!data) {
              data = {
                isLiked: type === LIKE_SCRAP ? true : false,
                likesCount: type === LIKE_SCRAP ? 1 : 0,
              };
            } else {
              data = {
                ...data,
                isLiked: type === LIKE_SCRAP ? true : false,
                likesCount:
                  type === LIKE_SCRAP
                    ? (data.likesCount || 0) + 1
                    : (data.likesCount || 1) - 1,
              };
            }
            return [+item.scrapId, data];
          }) as Iterable<readonly [scrapType['id'], scrapDataType]>),
        ]);
      } else {
        return new Map([
          ...state,
          (function () {
            let data = state.get(+payload.scrapId);
            if (!data) {
              data = {
                isLiked: true,
                likesCount: LIKE_SCRAP ? 1 : 0,
              };
            } else {
              data = {
                ...data,
                isLiked: type === LIKE_SCRAP ? true : false,
                likesCount: type === LIKE_SCRAP
                  ? (data.likesCount || 0) + 1
                  : (data.likesCount || 1) - 1,
              };
            }
            return [+payload.scrapId, data];
          })(),
        ]);
      }
    case INCREASE_COMMENT_COUNT:
      //
      return state;
    case RESET_SCRAPS_LIKES_AND_COMMENTS:
      return defaultState;
    default:
      return state;
  }
}
