import React from 'react';

interface Proptypes {
  className?: string;
}

const CrossSquare = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16699 1.66663H15.8337C17.2144 1.66663 18.3337 2.78591 18.3337 4.16663V15.8333C18.3337 17.214 17.2144 18.3333 15.8337 18.3333H4.16699C2.78628 18.3333 1.66699 17.214 1.66699 15.8333V4.16663C1.66699 2.78591 2.78628 1.66663 4.16699 1.66663ZM4.16699 3.33329C3.70675 3.33329 3.33366 3.70639 3.33366 4.16663V15.8333C3.33366 16.2935 3.70675 16.6666 4.16699 16.6666H15.8337C16.2939 16.6666 16.667 16.2935 16.667 15.8333V4.16663C16.667 3.70639 16.2939 3.33329 15.8337 3.33329H4.16699ZM13.0896 6.9107C13.415 7.23614 13.415 7.76378 13.0896 8.08922L11.1788 9.99996L13.0896 11.9107C13.415 12.2361 13.415 12.7638 13.0896 13.0892C12.7641 13.4147 12.2365 13.4147 11.9111 13.0892L10.0003 11.1785L8.08958 13.0892C7.76414 13.4147 7.23651 13.4147 6.91107 13.0892C6.58563 12.7638 6.58563 12.2361 6.91107 11.9107L8.82182 9.99996L6.91107 8.08922C6.58563 7.76378 6.58563 7.23614 6.91107 6.9107C7.23651 6.58527 7.76414 6.58527 8.08958 6.9107L10.0003 8.82145L11.9111 6.9107C12.2365 6.58527 12.7641 6.58527 13.0896 6.9107Z"
        fill="#969696"
      />
    </svg>
  );
};

export default CrossSquare;
