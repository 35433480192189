
export type ReduxFiltersType = {
  groupFilters?: {
    tags?: string[];
    untagged?: string[];
  };
};

export const SET_GROUP_FILTERS = 'SET_GROUP_FILTERS';

type ActionType = typeof SET_GROUP_FILTERS;

const defaultState: ReduxFiltersType = {
  groupFilters: null,
};

export default function filters(
  state = defaultState,
  action: {
    type: ActionType;
    payload: ReduxFiltersType;
  }
) {
  const { type, payload } = action;
  switch (type) {
    case SET_GROUP_FILTERS:
      return { ...state, groupFilters: payload.groupFilters };

    default:
      return state;
  }
}
