import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const ImagesIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        d="M20 18.0016V14.7016L16.3 10.6016L9.5 17.2016C9.1 17.6016 8.4 17.7016 7.9 17.3016L3.1 13.8016L0 16.8016V18.0016C0 19.1016 0.9 20.0016 2 20.0016H18C19.1 20.0016 20 19.1016 20 18.0016Z"
        fill="#969696"
      />
      <path
        d="M3.7 11.1L8.5 14.6L15.5 7.8C15.7 7.6 16.1 7.4 16.4 7.4C16.7 7.4 17.1 7.6 17.3 7.8L20 10.8V2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V13.3L2.1 11.3C2.5 10.8 3.2 10.8 3.7 11.1ZM8.1 3.8C9.1 3.8 10 4.6 10 5.7C10 6.8 9.2 7.6 8.1 7.6C7 7.6 6.2 6.7 6.2 5.6C6.2 4.5 7.1 3.8 8.1 3.8Z"
        fill="#969696"
      />
    </svg>
  );
};

export default ImagesIcon;
