import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CrossIcon } from '../icons';
import { charLimits } from '../../../helpers/config';
import './styles.css';

const faEyeIcon = faEye as IconProp;
const faEyeSlashIcon = faEyeSlash as IconProp;

/*
  NOTE -
    * It has been observed that the auto focus is not working on the input field when the form has TipTap editor.
    * The reason for this is that the TipTap editor is getting autoFocused the first time that interferes with the auto focus of the input field.
 */
interface PropHelper {
  children: React.ReactChild | React.ReactChildren;
  acceptedType: 'textarea' | 'input';
  keyboard: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>;
  changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
}

interface Proptypes {
  onFocus?: () => void;
  onBlur?: () => void;
  value?: string | number;
  onChange?: (val: string) => void;
  onKeyDown?: (e: PropHelper['keyboard']) => void;
  onChangeHandler?: (e: PropHelper['changeEvent']) => void;
  placeholder?: string;
  inputType?: string;
  inputName?: string;
  disabled?: boolean;
  required?: boolean;
  type?: PropHelper['acceptedType'];
  children?: PropHelper['children'];
  isFocused?: boolean;
  label?: string;
  showLabelOnFocus?: boolean;
  className?: string;
  limitConfigTitle?: {
    charLimit: number;
    titleCharLimitExceeds: boolean;
    setTitleCharLimitExceeds: Dispatch<SetStateAction<boolean>>;
  };
  togglePass?: boolean;
  autoFocus?: boolean;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  alwaysShowLabel?: boolean;
  additionalClassName?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>;
  hideClearIcon?: boolean;
  showSuggestion?: boolean;
  showSuggestionHandler?: (val: boolean) => void;
}
const LabelTextInput = ({
  onFocus,
  onBlur,
  value = '',
  onChange,
  onChangeHandler,
  onKeyDown,
  placeholder,
  inputType,
  inputName,
  disabled: readOnly,
  required,
  type,
  children,
  isFocused,
  label,
  showLabelOnFocus,
  className,
  limitConfigTitle,
  togglePass,
  autoFocus,
  icon,
  alwaysShowLabel,
  additionalClassName,
  inputRef: parentRef,
  hideClearIcon,
  showSuggestion,
  showSuggestionHandler,
}: Proptypes) => {
  const { charLimit, setTitleCharLimitExceeds } = limitConfigTitle || {};
  const [charLength, setCharLength] = useState(null);
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {}, [parentRef]);
  useEffect(() => {
    if (parentRef?.current) {
      autoFocus && parentRef.current.focus();
    } else {
      autoFocus && inputRef && inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    if (limitConfigTitle) {
      setTitleCharLimitExceeds(charLength > charLimit);
    }
  }, [limitConfigTitle, charLength]);

  const showLabel =
   label?.trim().length && (focused || isFocused || value);
  const returnPlaceholder = () => {
    if (!(isFocused || focused)) {
      return placeholder || 'Type here!';
    }
  };
  // Check if clear icon is enabled
  const showClearIcon = useMemo(() => {
    return (
      !hideClearIcon &&
      !readOnly &&
      type !== 'textarea' &&
      !(icon && label === 'Country') &&
      ((value ?? '') + '').length > 0
    );
  }, [value, icon, label]);
  // Clear text input
  const clearInput = () => {
    setCharLength(0);
    onChange('');
    inputRef?.current?.focus();
  };

  let elProps = {
    onChange: (e) => {
      onChangeHandler && onChangeHandler(e);
      setCharLength(e?.target?.value?.length);
      onChange(e?.target?.value);
    },
    onKeyDown: (
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onKeyDown && onKeyDown(e);
    },
    placeholder: returnPlaceholder(),
    type: showPassword ? '' : inputType || '',
    id: inputName || '',
    name: inputName || '',
    value: value ?? '',
    className: `label-text-input__input${
      readOnly ? ' label-text-input__input-disabled' : ''
    }${!hideClearIcon && type !== 'textarea' ? ' label-text-input__icon' : ''}${
      togglePass ? ' password-field' : ''
    } ${additionalClassName ?? ''}`,
    readOnly,
    required,
    onFocus: () => {      
      setFocused(true);
      showSuggestion && showSuggestionHandler(true);
      onFocus && onFocus()
    },
    onBlur: () => {
      setFocused(false)
      onBlur && onBlur();
    },
    autoFocus: false,
    tabIndex: readOnly ? -1 : 0,
  };
  if (autoFocus) {
    elProps.autoFocus = true;
  }
  let el = <input ref={parentRef ?? inputRef} {...elProps} />;
  if (type === 'textarea') {
    el = <textarea ref={parentRef ?? inputRef} {...elProps} />;
  }
  if (readOnly && !value) return null;

  return (
    <div className={`label-text-input ${className ? className : ''}`}>
      {children ? children : el}

      {icon && (
        <span
          className={`label-text-input__social-icon ${
            label === 'Country' ? 'label-text-input__country-dropdown' : ''
          }`}
        >
          {icon}
        </span>
      )}

      {(showClearIcon && focused) && (
        <span className="label-text-input__clear-icon" onMouseDown={clearInput}>
          <CrossIcon />
        </span>
      )}

      {togglePass && (
        <span
          className={`label-text-input__pass-icon${
            focused || isFocused ? ' label-text-input__pass-icon-active' : ''
          }`}
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <FontAwesomeIcon icon={faEyeSlashIcon} color="#6a6e73" />
          ) : (
            <FontAwesomeIcon icon={faEyeIcon} color="#6a6e73" />
          )}
        </span>
      )}

      {(showLabel) && (
        <span
          className={`label-text-input__label${
            focused || isFocused ? ' label-text-input__label-active' : ''
          } label-text-input__label-show${
            alwaysShowLabel ? ' label-text-input__label-always' : ''
          }`}
        >
          {label}
        </span>
      )}
      {!readOnly && limitConfigTitle && focused && (
        <div className="edit-description__limit">
          <span
            className={`edit-description__limit-tracker${
              (charLength || 0) > charLimit
                ? ' edit-description__limit-tracker-danger'
                : ''
            }`}
          >
            {charLimit - charLength}
          </span>
          {charLength > charLimit && (
            <span className="edit-description__limit-exceeds-message">
              {charLimits['characterLimitReachedMessage']}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default LabelTextInput;
