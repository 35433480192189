export function returnFullName(firstName: string, lastName: string) {
  let name = firstName || '';
  if(firstName ==='' || lastName==='' ){
    return null
  }
  if (lastName?.trim() || '') {
    if (name.trim()) name += ' ' + lastName.trim();
    else name = lastName;
  }
  return name;
}
