import { viewSettingType } from '../types/viewSettings';

export const findSetting = (
  key: string,
  settings: viewSettingType<string>[]
) => {
  if (!settings) return null;
  return settings.find((setting) => setting.key === key);
};
export const isViewTrue = (
  key: string,
  settings: viewSettingType<string>[]
) => {
  const settingFound = findSetting(key, settings);
  if (!settingFound || !settingFound.value) return false;
  return settingFound.value;
};

export const parseSettingsFromString = (str: string) => {
  let data = str;
  while (typeof data !== 'object') {
    try {
      data = JSON.parse(data);
    } catch (error) {
      break;
    }
  }
  return typeof data === 'object' ? data : null;
};
