import React from 'react';
import { useQuery } from '@apollo/client';
import ScrapCard from '../../components/ScrapCard';
import Scrap from '../data/scrap';
import { scrapType } from '../../types/scrapType';
import BrokenLinkComponent from '../../components/Global/Error/Components/BrokenLinkComponent';
import SEOMeta from '../../components/Meta/SEOMeta';

const ShareScrapContainer = (props) => {
  const { match } = props;
  const scrapId = match.params.id;
  const username = match.params.username || null;
  const private_key = match.params.private_key;

  const { data, loading } = useQuery(Scrap, {
    variables: {
      private_key: private_key,
      scrap_id: scrapId,
      username,
    },
  });
  const scrapData: scrapType = data?.scrap?.data;
  const showShareData = () => {
    if (!loading && !scrapData?.id) return <BrokenLinkComponent />;
    else if (scrapData?.id) {
      return (
        <>
          <SEOMeta
            title={scrapData?.meta_title || scrapData?.title}
            description={
              scrapData?.meta_desc ||
              scrapData?.desc
            }
            image={scrapData?.images[0]?.file_path}
          />
          <ScrapCard scrap={scrapData} shareDetails />
        </>
      );
    }
    return null;
  };

  return <section className="share-scrap">{showShareData()}</section>;
};

export default ShareScrapContainer;
