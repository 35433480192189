export const addCollectionsToPanelRes = `
  data{
    state
  }
  messages {
    error
    success
  }
  status
  http_code
`;
