import React, { useRef } from 'react';
import { GroupType } from '../../../containers/GroupShareContainer/types';

import './styles.css';
import EmptyPageMessage from '../EmptyPageMessage';
import ItemsMasonry from '../../ItemsMasonry';
interface Proptypes {
  children: (shareData: GroupType, i: number) => React.ReactElement;
  onLoadMore?: () => void;
  shareData: GroupType[];
  loading: boolean;
  masonryKey?: string;
}
const AllSharesCard = ({
  children,
  shareData,
  loading,
  onLoadMore,
  masonryKey,
}: Proptypes) => {

  const ref = useRef<HTMLDivElement>();
  
  let className = 'all-shares';  
  let el;
  if (shareData?.length) {
    el = (
      <ItemsMasonry<GroupType>
        key={masonryKey}
        className="all-shares__masonry"
        containerRef={ref}
        items={shareData}
        onLoadMore={onLoadMore}
        loading={loading}
        overscanBy={2}
        rowGutter={18}
        columnCount={1}
        columnWidth={540}
      >{children}</ItemsMasonry>
    );
  } else if (loading) {
    el = null;
  } else {
    className += ' all-shares--no-data'
    el = (
      <EmptyPageMessage>
        <span>
          Looks like you have no shares.
        </span>
      </EmptyPageMessage>
    );
  }
  
  return <section className={className} ref={ref}>{el}</section>;
};

export default AllSharesCard;
