export default function calculateElementDimensions(
  el: HTMLElement,
  exludePadding?: boolean
): {
  width: number;
  height: number;
} {
  try {
    let computedStyle = {
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: '0',
    };

    if (typeof window === 'object') {
      computedStyle = window?.getComputedStyle(el);
      let width = el.clientWidth; // width with padding
      let height = el.clientHeight; // height with padding
      if (!exludePadding)
        return {
          height,
          width,
        };
      height -=
        parseFloat(computedStyle.paddingTop) +
        parseFloat(computedStyle.paddingBottom);
      width -=
        parseFloat(computedStyle.paddingLeft) +
        parseFloat(computedStyle.paddingRight);

      return { height, width };
    }

    return {
      width: 1000,
      height: 400,
    };
  } catch (error) {
    console.warn(error);
    return {
      width: 0,
      height: 0,
    };
  }
}
