import React, { useState } from "react";
import parseJustification from "../helpers/parseJustification";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import Popup from "reactjs-popup";
import Icon from "../../Global/Icon";
import EditCollection from "../../CollectionCreationWrapper/components/EditCollection";
import NewPopup from "../../Global/NewPopup";
import MyScraps from "../../MyScraps";
import ScrapCard from "../../ScrapCard";
import './CitationStyles.css';
import IconAcorn from "./IconAcorn";
import {useSelector} from "react-redux";
import {userTypes} from "../../../types/user";

// takes scrap ids as array in props and return the citation component
interface CitationProps {
  message: string;
  scrapIds?: number[];
  justification?: String; // justification text
  collectionId?: String;
  isGroup?: boolean;
  userList?: object[];
}

const query = gql`
  query getScraps($scrap_ids: [Int]!, $collection_id: ID, $group_id: ID, $private_key: String) {
    getScraps(scrap_ids: $scrap_ids, collection_id: $collection_id, group_id: $group_id, private_key: $private_key) {
      data {
        id
        user_id
        user_name
        display_name
        avatar
        title
        desc
        status
        state
        private_key
        service
        meta
        meta_title
        meta_desc
        price
        created_at
        updated_at
        url
        domain
        favicon
        original_favicon
        annotations {
            highlighted_text
        }
        annotations_count
        documents_count
        documents {
            file_name
            file_type
            file_path
        }
        audios_count
        audios{
            file_name
            file_path
        }
        videos_count
        videos{
            file_name
            file_path
        }
        images_count
        images {
            file_name
            file_path
            order
            file_status
            file_config
            desc
        }
        tags {
            slug
        }
        collections{
            id
        }
      }
    }
  }
`;

const Citation = (props: CitationProps) => {
  const { message, scrapIds, justification, collectionId, isGroup, userList } = props;
  // get user id from the global state, to make check I own the scrap
  const userId = useSelector(
    (state: { user: userTypes }) => state.user?.user_id || null
  );

  const [ showScraps, setShowScraps ] = useState(false);
  const [getScrapsData, { data, loading }] = useLazyQuery(query);
  const [refScraps, setRefScraps] = useState(null);

  // convert scrapIds to array of integers
  let existingScraps = scrapIds?.map((scrapId) => parseInt(scrapId.toString()));

  // For cases where the scraps are not passed as props, but are present in the message
  // this takes care of hte old data as well
  if((!existingScraps || existingScraps.length === 0) && message) {
    const justificationText = parseJustification(message);
    existingScraps = justificationText?.scrapIds;
  }

  async function toggleScrapsDisplay() {
    setShowScraps(!showScraps);
    setRefScraps(null);

    const scrapValues = await getScrapsData({
      variables: {
        scrap_ids: existingScraps,
        collection_id: !isGroup && collectionId ? collectionId : null,
        group_id: isGroup ? collectionId : null
      },
    });

    const listOfMatchingScraps = scrapValues.data?.getScraps.data;

    // add the user details to scraps is not present
    // use the user_id to get the user details from userList
    if(listOfMatchingScraps && userList) {
      listOfMatchingScraps.forEach((scrap) => {
        // @ts-ignore
        const user = userList.find((user) => user.user_id === scrap.user_id);
        // @ts-ignore
        scrap.user_name = user?.user_name;
        // @ts-ignore
        scrap.display_name = user?.display_name;
        // @ts-ignore
        scrap.avatar = user?.avatar;
      });
    }


    if(listOfMatchingScraps) {
      setRefScraps(listOfMatchingScraps);
    }
  }

  return (
    <>
      <span className="chat__citation">
        {(!existingScraps || existingScraps.length === 0) && (
          <span className="chat__citation-none">No Scraps referenced</span>
        )}

        {existingScraps?.length > 0 && (
          <span
            onClick={async () => await toggleScrapsDisplay()}
            className="chat__citation-reference"
          >
            <span className="chat__citation-icon">
              <IconAcorn />
            </span>
            {existingScraps.length} Scrap{existingScraps.length > 1 ? 's' : ''}{' '}
            referenced
          </span>
        )}
      </span>

      {showScraps && (
        <NewPopup
          size="Small"
          header={{
            heading: 'Referenced Scraps',
          }}
          controlled={{
            show: showScraps,
            setShow: (status) => {
              setShowScraps(status);
            },
          }}
          className="matching-scraps-popup"
          footer={{
            cancelLabel: 'Close',
          }}
        >
          <div className="matching-scraps">
            {
              refScraps && refScraps.map((scrap) => (
                <div
                  key={scrap.id}
                  className={!scrap.user_name && +userId !== +scrap.user_id ? 'user-na' : ''}
                >
                  <ScrapCard
                    scrap={scrap}
                  />
                </div>
              ))
            }
          </div>
        </NewPopup>
      )}
    </>
  );
}


export default Citation;