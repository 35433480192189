import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';
export const BULK_REMOVE = gql`
  mutation BulkRemove(
    $scraps: [Int]
    $tags: [Int]
    $collections: [Int]
    $stories: [Int]
  ) {
    bulkRemoveOperations(
      scraps: $scraps
      tags: $tags
      collectios: $collections
      stories: $stories
    ) {
      ${defaultResponse}
    }
  }
`;
export const BATCH_EDIT_OPERATIONS = (
  type: 'bulkRemoveOperations' | 'bulkAddOperations'
) => gql`
  mutation ${type === 'bulkRemoveOperations' ? 'BulkRemove' : 'BulkAdd'} (
    $scraps: [Int]${type === 'bulkRemoveOperations' ? '' : '!'}
    $tags: [Int]
    $collections: [Int]
    $stories: [Int]
  ) {
    ${type}(
      scraps: $scraps
      tags: $tags
      collections: $collections
      stories: $stories
    ) {
      ${defaultResponse}
    }
  }
`;
