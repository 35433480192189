// @ts-nocheck
export const socialLogout = () => {
  if (typeof window !== 'undefined') {
    // For Facebook
    if (window.FB) {
      if (window.FB.getLoginStatus) {
        window.FB.getLoginStatus(function (response) {
          window.FB.logout(function (response) {
            //
          });
        });
      }
    }
    // For all session storage sessions
    if (window.sessionStorage) {
      window.sessionStorage.clear();
    }
  }
};
