import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const FileIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        d="M6.1 4.5H11.9C12.2 4.5 12.5 4.2 12.5 3.9V1.1C12.5 0.8 12.2 0.5 11.9 0.5H6.1C5.8 0.5 5.5 0.8 5.5 1.1V3.9C5.5 4.3 5.8 4.5 6.1 4.5Z"
        fill="#969696"
      />
      <path
        d="M13.1979 13.0951L4.79785 13.1049L4.80179 16.5049L13.2018 16.4951L13.1979 13.0951Z"
        fill="#969696"
      />
      <path
        d="M17.3 5.4L15 2.5C14.7 2.1 14.3 1.9 13.9 1.7V4C13.9 5.1 13 6 11.9 6H6C4.9 6 4 5.1 4 4V1.5H3C1.3 1.5 0 2.8 0 4.5V18.5C0 20.2 1.3 21.5 3 21.5H15C16.7 21.5 18 20.2 18 18.5V7.2C18 6.6 17.8 5.9 17.3 5.4ZM15.2 16.5C15.2 17.6 14.3 18.5 13.2 18.5H4.8C3.7 18.5 2.8 17.6 2.8 16.5V13.1C2.8 12 3.7 11.1 4.8 11.1H13.2C14.3 11.1 15.2 12 15.2 13.1V16.5Z"
        fill="#969696"
      />
    </svg>
  );
};

export default FileIcon;
