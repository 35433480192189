export default function parseTagFromSlug(slug: string, count?: string) {
  const data = {
    id: '',
    label: '',
    title: '',
    count: 0,
    value: slug,
  };
  if(typeof count === 'string') data.count = +count
  else delete data['count'];
  if (slug) {
    const splitedSlug = slug.split('-');
    if (splitedSlug.length) {
      data.id = splitedSlug.splice(splitedSlug.length - 1, 1)[0];
      data.label = splitedSlug.join('_');
      data.title = data.label;
    } 
  }
  if(slug === 'Untagged' || (data.label === 'Untagged' && data.id === 'null')) {
    data.value = 'true'
    data.id = 'null'
    data.label = 'Untagged'
    data.title = data.label;
  }
  return data;
}
