/**
 * This component is used to log general messages to rollbar
 */
import * as config from '../../settings.json';

export default async function logging (message: string) {
  console.log(config.access_token, message);

  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'X-Rollbar-Access-Token': config.access_token,
      'content-type': 'application/json',
    },

    body: JSON.stringify({
      data: {
        environment: config.target_env,
        body: {
          message: {
            body: `${config.target_env}: ${message}`,
          },
        },
      },
    }),
  };

  return await fetch("https://api.rollbar.com/api/1/item/", options)
    .then((response) => {
      return response.text();
    })
    .catch((err) => {
      return err;
    });
}