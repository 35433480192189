import React from 'react';

export default function IconAcorn() {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0083 2.81506C15.8188 0.452949 24.9811 -2.74657 29.0236 4.29406C29.0236 4.29406 29.3349 4.74505 29.3472 5.23689C29.3545 5.55842 29.2346 5.89706 28.8144 6.141L2.86273 21.2073C1.80169 21.8232 1.17049 20.4642 1.17049 20.4642C-2.85683 13.4289 4.44749 7.06367 8.89009 4.0468L8.25569 2.94188C8.16144 2.77774 8.00116 2.66004 7.81349 2.61397C7.62581 2.5679 7.42505 2.59732 7.25275 2.69735L5.36041 3.79595C5.07928 3.95916 4.74872 4.0082 4.43969 3.93329C4.13023 3.85762 3.87003 3.66418 3.7147 3.39365C3.61216 3.21507 3.56364 3.01122 3.56662 2.80582C3.56746 2.70199 3.58345 2.59645 3.61287 2.49325C3.70286 2.18668 3.91038 1.92335 4.19151 1.76013L6.34684 0.508855C6.67634 0.317565 7.03557 0.192098 7.40982 0.134915C7.78148 0.0802464 8.16125 0.0958513 8.52186 0.183376C8.73858 0.237913 8.94934 0.317183 9.1455 0.420137C9.27221 0.486396 9.39594 0.561485 9.50975 0.647394C9.80382 0.864734 10.0466 1.14021 10.2273 1.45481L11.0083 2.81506Z"
        fill="#EB6F19" />
      <path
        d="M5.91467 21.5105C7.45016 24.0585 9.16841 26.5667 10.7658 28.0361C14.7416 31.6963 20.2045 31.2428 22.3537 30.451C22.6699 30.3349 23.1448 30.4741 23.6685 30.628C24.3318 30.8215 25.0701 31.0403 25.6695 30.7927C26.1855 30.3928 26.3666 29.6412 26.5284 28.9682C26.6576 28.4352 26.775 27.9516 27.0362 27.7326C28.7896 26.2587 31.9087 21.73 30.7472 16.4359C30.5543 15.5524 30.2155 14.5621 29.785 13.5176C29.1791 12.0481 28.3881 10.4738 27.5526 8.94859L5.91467 21.5105Z"
        fill="#EB6F19" />
    </svg>
  )
}