import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';

const GET_TIMEZONES = gql`
  query Timezones($country: String!) {
    timezones(country: $country) {
      data {
        timezones
      }
      ${defaultResponse}
    }
  }
`;

export default GET_TIMEZONES;
