import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReduxStateType } from '../../../../redux/store';
import EmptyPageMessage from '../../EmptyPageMessage';
import { NavigationOnlyLogo } from '../../PublicNavigation';

interface Proptypes {
  message?: string;
  setHideSideNavFilters?: (boolean) => void;
  hideGoHome?: boolean;
}

const BrokenLinkComponent = ({
  message,
  setHideSideNavFilters,
  hideGoHome,
}: Proptypes) => {
  const user = useSelector((state: ReduxStateType) => state.user);
  const errorMessage =
    message || 'We are not able to find what you are looking for.';
  return (
    <>
      <div className="error-wrapper">
        <EmptyPageMessage header={{}} register_from={user.register_from}>
          <span className="error-wrapper__error-message">
            Hmm, nothing's buried here - maybe you're digging in the wrong spot?
            {!hideGoHome && (
              <Link
                className="error-wrapper__error-message__link"
                to={'#'}
                onClick={() => window.open(window.location.origin, '_blank')}
              >
                Go Home
              </Link>
            )}
          </span>
        </EmptyPageMessage>
      </div>
    </>
  );
};

export default BrokenLinkComponent;
