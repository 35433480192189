import { ReduxMessengersType, SET_GROUP_MESSENGER, SET_COLLECTION_MESSENGER } from '../store/messengers';

export function setGroupMessenger(
  groupMessenger: ReduxMessengersType['groupMessenger']
) {
  return {
    type: SET_GROUP_MESSENGER,
    payload: { groupMessenger },
  };
}
export function setCollectionMessenger(
  collectionMessenger: ReduxMessengersType['collectionMessenger']
) {
  return {
    type: SET_COLLECTION_MESSENGER,
    payload: { collectionMessenger },
  };
}
