import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';
export const EDIT_PROFILE = gql`
  mutation EditProfile(
    $address: String
    $email: String
    $phone: String
    $avatar: String
    $country: String
    $timezone: String
    $bio: String
    $headline: String
    $cover_image: String
    $social_media_accounts: String
    $url: String
    $location: String
    $display_name: String
    $onboard_status: Int
  ) {
    upsertProfile(
      address: $address
      email: $email
      phone: $phone
      avatar: $avatar
      country: $country
      timezone: $timezone
      bio: $bio
      headline: $headline
      cover_image: $cover_image
      social_media_accounts: $social_media_accounts
      url: $url
      location: $location
      display_name: $display_name
      onboard_status: $onboard_status
    ) {
      data {
        avatar
        cover_image
        social_media_accounts
        url
        location
        display_name
        onboard_status
      }
      ${defaultResponse}
    }
  }
`;
