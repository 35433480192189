import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { Auth } from '../components/Account';
import ErrorAuthPage from '../components/Account/ErrorAuthPage';

interface LocationStateType {
  forceRedirectUrl: string;
  forceRedirectTo: 'login' | 'register';
}
const AuthWrapper = () => {
  const { state } = useLocation<LocationStateType>();
  const { push } = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);

  const { forceRedirectTo, forceRedirectUrl } = state || {};

  function handleClick(type: 'login' | 'register') {
    if (forceRedirectTo && forceRedirectUrl) {
      const [urlPath, urlSearch] = forceRedirectUrl.split('?');
      if (forceRedirectTo !== type) {
        setErrorMessage(
          forceRedirectTo === 'login'
            ? 'This invite link is for existing users only!'
            : 'This invite link is for new user only!'
        );
      } else {
        push({
          pathname: urlPath,
          state: { dontRedirectToAuth: true },
          search: '?' + urlSearch,
        });
      }
    } else {
      if (type === 'login') {
        push('/login');
      } else {
        push('/register');
      }
    }
  }
  if (errorMessage) {
    let buttonLink, buttonLabel;
    if (forceRedirectUrl && forceRedirectTo) {
      const [urlPath, urlSearch] = forceRedirectUrl.split('?');
      buttonLink = {
        pathname: urlPath,
        state: { dontRedirectToAuth: true },
        search: '?' + urlSearch,
      };
      buttonLabel = forceRedirectTo === 'login' ? 'Login Now' : 'Sign up Now';
    }
    return (
      <ErrorAuthPage
        title="Invalid Action"
        message={errorMessage}
        buttonLink={buttonLink}
        buttonLabel={buttonLabel}
      />
    );
  }

  let title, show;
  if (forceRedirectUrl) {
    if (
      forceRedirectUrl.startsWith('/login?collectionCollaboratorToken=') ||
      forceRedirectUrl.includes('&type=collaborator')
    ) {
      show = forceRedirectUrl.startsWith('/login?collectionCollaboratorToken=')
        ? 'login'
        : 'sign up';
      title = "You've been invited to a Collection!";
    }
  }

  return <Auth title={title} handleClick={handleClick} show={show} />;
};

export default AuthWrapper;
