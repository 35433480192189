import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['group_id', 'Int!'],
  ['members', '[InputMemberId]']
]);
export default function ADD_GROUP_MEMBERS() {
  return gql`
    mutation AddGroupMembers(${parametersTypeString}) {
      addGroupMembers(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}
