import React from 'react';
import { useSelector } from 'react-redux';
import { parseNote } from '../../../../helpers';
import { ReduxStateType } from '../../../../redux/store';
import ReadMoreText from '../../../Cards/ReadMoreText';
import { onClickType } from '../../../ScrapCard';
interface Proptypes {
  showFull?: boolean
  description: string;
  onMoreClick:(onClickType:onClickType, currentlyOpened?:boolean)=>boolean;
  moreShow?:onClickType;
  editClick?:() => void;
}
export default function WebContentDescription({ description,onMoreClick, showFull, moreShow, editClick }: Proptypes) {
  const { width } = useSelector(
    (state: ReduxStateType) => state.utils.dimensions
  );
  if (!description) return null;
  return (
    <span className="web-content__description">
      {/* {description} */}
      <ReadMoreText
        showFull={showFull}
        hideLess
        // onLoad={onCardResize}
        content={parseNote(description)}
        // maxHeight={150}
        onMoreClick={()=>onMoreClick(moreShow)}
        editClick={editClick}
      />
      
    </span>
  );
}
