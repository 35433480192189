import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const CircleTickIcon = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className||""}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM14.7372 7.67572C15.1103 7.26861 15.0828 6.63604 14.6757 6.26285C14.2686 5.88965 13.636 5.91716 13.2628 6.32428L8.53586 11.481L6.76822 9.35982C6.41466 8.93554 5.78409 8.87821 5.35982 9.23178C4.93554 9.58534 4.87821 10.2159 5.23178 10.6402L7.73178 13.6402C7.91649 13.8618 8.18795 13.9929 8.4764 13.9997C8.76485 14.0065 9.04219 13.8884 9.23715 13.6757L14.7372 7.67572Z"
        fill="white"
      />
    </svg>
  );
};

export default CircleTickIcon;
