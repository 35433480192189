import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GlobalSearchType } from '../../../../redux/store/globalSearch';
import './styles.css';
import {
  GlobalSearchTabsType,
  KnotFetch,
} from '../../../../types/globalSearchDataType';
interface PropTypes {
  tabs: GlobalSearchTabsType;
  disabled?: boolean;
  onFilterChange: (
    filterType: string,
    filterMode?: string,
    forceRender?: boolean
  ) => void;
  selectedType?: string;

  selectedMode?: string;
  selectedFilter?: string; // TO BE REMOVED AND REPLACE WITH SELECTED-TYPE ONLY
  isPublicSearch?: boolean;
  loading?: boolean;
}

const GlobalFiltersSearch = ({
  onFilterChange,
  disabled,
  selectedMode,
  selectedType,
  isPublicSearch,
  loading,
  tabs,
}: PropTypes) => {
  const globalSearch: GlobalSearchType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.globalSearch
  );
  const { isSearchBarActive } = globalSearch;

  
  const returnModeAndType = (value: KnotFetch) => {
    let type: string, mode: string;
    switch (value) {
      case KnotFetch.Scraps:
        type = 'scraps';
        mode = 'personal';
        break;
      case KnotFetch.MyCollections:
        type = 'collections';
        mode = 'personal';
        break;
      case KnotFetch.PublicCollections:
        type = 'collections';
        mode = 'global';
        break;
      case KnotFetch.Shares:
        type = 'shares';
        mode = 'personal';
        break;
      case KnotFetch.Scrappers:
        type = 'scrappers';
        mode = 'global';
        break;

      default:
        break;
    }
    return { mode, type };
  };

  const tabClickHandler = (tab: GlobalSearchTabsType[number]) => {
    const { value } = tab;
    const { mode, type } = returnModeAndType(value);
    if (type && mode) onFilterChange(type, mode);
  };
  const activeTab = useMemo(() => {
    if (selectedMode === 'personal') {
      switch (selectedType) {
        case 'scraps':
          return KnotFetch.Scraps;
        case 'collections':
          return KnotFetch.MyCollections;
        case 'shares':
          return KnotFetch.Shares;
        default:
          return null;
      }
    } else {
      switch (selectedType) {
        case 'collections':
          return KnotFetch.PublicCollections;
        case 'scrappers':
          return KnotFetch.Scrappers;
        default:
          return null;
      }
    }
  }, [selectedMode, selectedType]);
  useEffect(() => {
    if (!loading && tabs.length) {

      const tabIndex = tabs.findIndex(tab => tab.value === activeTab);
      if(tabIndex < 0) {
        tabClickHandler(tabs[0])
      }
    }
  }, [tabs, loading, selectedMode, selectedType]);
  if (!tabs?.length) return null;
  return (
    <div
      className={`global-feeds__filter ${
        disabled ? ' global-feeds__filter--disabled' : ''
      }`}
    >
      <div
        className={`global-search-links${isSearchBarActive ? ' global-search-active' : ''}`}
      >
        <div className="global-search-links__list">
          {tabs.map((item) => (
            <span
              className={`global-search-links__list-item ${
                item.value === activeTab && !loading ? 'active_item' : ''
              }`}
              onClick={() => tabClickHandler(item)}
              key={item.value}
            >
              {item.label}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GlobalFiltersSearch;
