import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const LoaderImage = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="42"
      height="41"
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 11.6166V4.9541"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.6789 26.8208L10.0664 31.4333"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21 29.3833V36.0458"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M27.3213 26.8208L31.9338 31.4333"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.8838 20.5H36.5463"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M27.3213 14.1794L31.9338 9.56689"
        stroke="white"
        strokeWidth="2"
        stroke-miterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default LoaderImage;
