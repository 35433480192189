import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const Pinterest = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M0 9C0 12.8423 2.40825 16.1228 5.7975 17.4135C5.715 16.7108 5.62725 15.552 5.81625 14.739C5.979 14.04 6.867 10.2855 6.867 10.2855C6.867 10.2855 6.59925 9.74925 6.59925 8.955C6.59925 7.71 7.32075 6.78 8.22 6.78C8.985 6.78 9.354 7.35375 9.354 8.0415C9.354 8.81025 8.86425 9.95925 8.6115 11.025C8.40075 11.9167 9.05925 12.6442 9.93825 12.6442C11.5305 12.6442 12.7552 10.965 12.7552 8.541C12.7552 6.39525 11.2132 4.896 9.012 4.896C6.4635 4.896 4.96725 6.80775 4.96725 8.784C4.96725 9.55425 5.2635 10.3792 5.634 10.8285C5.66559 10.8624 5.68791 10.9039 5.69881 10.949C5.70971 10.994 5.70883 11.0411 5.69625 11.0858C5.628 11.3693 5.4765 11.9775 5.44725 12.102C5.4075 12.2655 5.3175 12.3008 5.14725 12.2213C4.02825 11.7008 3.32925 10.065 3.32925 8.751C3.32925 5.92425 5.382 3.32925 9.24825 3.32925C12.3563 3.32925 14.772 5.544 14.772 8.5035C14.772 11.5913 12.8257 14.0767 10.1227 14.0767C9.2145 14.0767 8.36175 13.6042 8.06925 13.047C8.06925 13.047 7.62 14.7585 7.51125 15.177C7.29975 15.99 6.71325 17.019 6.3495 17.6033C7.188 17.8613 8.0775 18 9 18C13.9702 18 18 13.9702 18 9C18 4.02975 13.9702 0 9 0C4.02975 0 0 4.02975 0 9Z"
        fill="#838383"
      />
    </svg>
  );
};

export default Pinterest;
