import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Paragraph from '@tiptap/extension-paragraph';
import { Extension, mergeAttributes } from '@tiptap/core';
import TiptapMenuBar from './MenuBar';
import Highlight from '@tiptap/extension-highlight'
import './styles.css';
import LabelTextInput from '../LabelTextInput';

export type tiptapToolsType =
  | 'heading'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'list'
  | 'clear-format';
interface propTypes {
  onLoad?: () => void;
  text?: string;
  placeholder?: string;
  editorFor?: string;
  edit?: boolean;
  imageDescriptionIndex?: number;
  setCharLength?: (updatedValue: number) => void;
  onChange?: (content: string) => void;
  onBlur?: (content: string) => void;
  setIsChanging?: (val: boolean) => void;
  toolsToHide?: Array<tiptapToolsType>;
  hideAllTools?: boolean;
  animated?: boolean;
  setFocus?: Dispatch<SetStateAction<boolean>>;
  focusedEditor?: boolean;
  desccharLength?: number;
  labelledEditor?: boolean;
  label?: string;
}

const Tiptap = (props: propTypes) => {
  const {
    onLoad,
    text,
    placeholder,
    editorFor,
    edit,
    imageDescriptionIndex,
    setCharLength,
    onChange,
    onBlur,
    setIsChanging,
    toolsToHide,
    hideAllTools,
    animated,
    focusedEditor,
    setFocus,
    desccharLength,
    labelledEditor,
    label,
  } = props;

  const [editorFocused, setEditorFocused] = useState(false);
  const [showTools, setShowTools] = useState(
    animated || hideAllTools ? false : true
  );
  const tiptap = useRef();
  const editor = useEditor({
    editable: edit || false,
    extensions: [
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (StarterKit as unknown) as Extension<StarterKitOptions, any>,
      Underline,
      Link,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Highlight.configure({ multicolor: true }),
      Paragraph.extend({
        parseHTML() {
          return [{ tag: 'div' }];
        },
        renderHTML({ HTMLAttributes }) {
          return [
            'div',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            0,
          ];
        },
      }),
    ],
    editorProps: {
      transformPastedHTML: html => {
        return html.replace(/<h[1-6]/g, "<strong").replace(/<\/h[1-6]/g, "</strong");
      },
    },
    content: text,
    onUpdate({ editor }) {
      // The content has changed.
      const json = editor.getHTML();
      setCharLength && setCharLength(editor.getCharacterCount());
      onChange && onChange(json);
    },
    onFocus({ editor, event }) {
      setEditorFocused(true);
      if (animated && edit) {
        setFocus && setFocus(true);
        setShowTools(true);
        editor.setOptions({
          editorProps: {
            attributes: {
              class: 'tiptap--highlighted',
            },
          },
        });
      }
    },
    onCreate({ editor }) {
      onLoad && onLoad();
      if (animated) editor.commands.blur();
    },
    onBlur({ editor, event }) {
      setEditorFocused(false);
      if (onBlur) {
        const json = editor.getHTML();
        setCharLength && setCharLength(editor.getCharacterCount());
        onBlur(json);
      }
    },
  });

  useEffect(() => {
    if (editor != null) {
      editor.setEditable(edit || false);
      if (editor.isEditable) {
        editor.chain().run();
      } else {
        editor.commands.setContent(text);
      }
      setCharLength && setCharLength(editor.getCharacterCount());
    }
  }, [editor, edit]);

  useEffect(() => {
    if (editor != null && imageDescriptionIndex != null && setIsChanging) {
      if (editor.isEditable) {
        setIsChanging(true);
        editor.commands.setContent(text);
        setIsChanging(false);
        setCharLength && setCharLength(editor.getCharacterCount());
      }
    }
  }, [imageDescriptionIndex]);
  useEffect(() => {
    if (focusedEditor && editor) {
      setShowTools(false);
      editor.setOptions({
        editorProps: {
          attributes: {
            class: '',
          },
        },
      });
      editor.chain().blur();
    }
  }, [focusedEditor, editor]);

  const isLabelledEditor = () => {
    return labelledEditor && label;
  };
  return (
    <div className={`tiptap${editorFocused ? ' tiptap--focused ': ''}${edit ? ' tiptap--editable' : ''}${isLabelledEditor() ? ' tiptap--labelled' : ''}${edit && showTools ? ' tiptap--tools-visible' : ''}`}>
      <span className={`tiptap--label-text-input__label ${editorFocused ? 'label-text-input__label-active' :''}`}>{label ? label: 'Notes'}</span>
      {!hideAllTools && !isLabelledEditor() && <TiptapMenuBar editor={editor} toolsToHide={toolsToHide} visible={edit && showTools} />}
      {isLabelledEditor() ? (
        <LabelTextInput
          placeholder="Add a title"
          label={label}
          isFocused={showTools}
          className="labelled-tiptap"
        >
          <EditorContent  ref={tiptap} editor={editor} />
        </LabelTextInput>
      ) : (
        <EditorContent ref={tiptap} editor={editor} />
      )}
    </div>
  );
};

export default Tiptap;
