import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const Recycle = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      onClick={onClick}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5116 13.7464L12.9223 13.8828C12.36 14.0129 11.8126 13.6561 11.6997 13.0858C11.5868 12.5156 11.9511 11.9479 12.5134 11.8178L15.4108 11.1474C15.9296 11.0274 16.4438 11.3222 16.6046 11.8318L17.3981 14.3464C17.5724 14.8988 17.2707 15.5025 16.7243 15.6947C16.1779 15.887 15.5937 15.5951 15.4194 15.0427L15.3324 14.767C14.118 16.4175 12.4888 17.4296 11.1662 17.895C9.52203 18.4736 7.1012 18.6486 4.76616 17.4818C2.42395 16.3115 1.09487 14.202 0.573255 12.549C0.398954 11.9966 0.70059 11.3929 1.24698 11.2007C1.79337 11.0084 2.3776 11.3003 2.5519 11.8527C2.94126 13.0867 3.96018 14.6962 5.71895 15.575C7.48488 16.4575 9.32352 16.321 10.535 15.8947C11.4633 15.568 12.6142 14.8642 13.5116 13.7464ZM5.42312 7.22329C5.98542 7.0932 6.34972 6.52547 6.23683 5.95524C6.12393 5.385 5.57658 5.02819 5.01429 5.15828L4.42492 5.29464C5.32235 4.17688 6.47327 3.47305 7.40154 3.14641C8.61303 2.7201 10.4517 2.58363 12.2176 3.46603C13.9764 4.34485 14.9953 5.95443 15.3847 7.18837C15.559 7.74075 16.1432 8.03268 16.6896 7.84041C17.236 7.64815 17.5376 7.04449 17.3633 6.49211C16.8417 4.83904 15.5126 2.72959 13.1704 1.55924C10.8354 0.392462 8.41453 0.567484 6.77034 1.14606C5.44781 1.61144 3.81859 2.62354 2.60413 4.27406L2.51715 3.9984C2.34285 3.44601 1.75861 3.15408 1.21223 3.34635C0.665839 3.53862 0.364202 4.14228 0.538504 4.69466L1.33197 7.20926C1.49278 7.7189 2.00693 8.01365 2.52571 7.89363L5.42312 7.22329Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default Recycle;
