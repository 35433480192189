import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['annotation_id', 'Int'],
  ['scrap_id', 'Int'],
]);
export default function DELETE_SCRAP_ANNOTATION(responseString = '') {
  return gql`
    mutation DeleteScrapAnnotation(${parametersTypeString}) {
      deleteScrapAnnotation(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}
