import React from 'react';
import EmptyPageMessage from '../EmptyPageMessage';
import './styles.css';
import { target_env } from '../../../settings.json';
import { access_token } from '../../../settings.json';

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  
 
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'X-Rollbar-Access-Token': access_token,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          environment: target_env,
          body: {
            message: {
              body: error.message,
            },
          },
        },
      }),
    };


    fetch('https://api.rollbar.com/api/1/item/',options)
      .then((response) => {
        return response.text();
      })
      .catch((err) => {
        return err;
      });
    
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <EmptyPageMessage header={{}}>
            <div className="error-boundary__container">
              <span className="error-boundary__container__message">
                Whoa, we're having some problems loading content. Give us some
                time and check back later.
              </span>
              <div className="error-boundary__container__controls">
                <a
                  className="error-boundary__container__controls__home"
                  href={'/'}
                >
                  Go Home
                </a>
              </div>
            </div>
          </EmptyPageMessage>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;