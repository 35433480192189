/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  DefaultOptions,
  InMemoryCache,
  ApolloLink,
  gql
} from '@apollo/client';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { getToken } from './getToken';
import * as config from '../settings.json';
import { entryTypes } from '../types/feed';
import { queryMerge, queryRead, scrapLocalFields } from './apolloHelpers';
import { scrapType } from '../types/scrapType';
import { collectionType } from '../types/collections';
import { GroupType } from '../containers/GroupShareContainer/types';
import { userTypes } from '../types/user';

// todo: write gql schemas
// const typeDefs = gql`
// `

const returnApolloState = () => {
  try {
    // @ts-ignore: ignore the unknown type
    return window.__APOLLO_STATE__
  } catch (error) {
    return null;
  }
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const graphQlWrapper = (token: string, withoutToken = false) => {
  const tokenValue = withoutToken ? '' : `Bearer ${getToken(token)}`;

  return new ApolloClient({
    ssrMode: false,
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          // handle graphql error
          // reload page and let the server handle the request
          if (graphQLErrors[0].message === 'Token error') {
            window.location.reload();
          }
        }
        if (networkError) {
          // handle newtowrk error
        }
      }),
      new CreateUploadLink({
        uri: `${config.baseUrl}/`,
        headers: {
          authorization: tokenValue,
        },
      }),
    ]),
    // link: new HttpLink({
    //   uri: `${config.baseUrl}/`,
    //   headers: {
    //     authorization: tokenValue,
    //   },
    // }),
    ssrForceFetchDelay: 100,
    cache: new InMemoryCache({
      typePolicies: {
        Collection: {
          keyFields: false,
        },
        Entry: {
          keyFields: ['uid'],

          fields: scrapLocalFields,
        },
        Knot: {
          keyFields: false, // Disable cache normalization for knot
          fields: scrapLocalFields,

        },
        Scrap: {
          keyFields: ['id'],
          fields: scrapLocalFields
        },
        CollectionScrap: {
          keyFields: ['id'],
          fields: scrapLocalFields
        },
        GroupShare: {
          keyFields: false
        },
        ContactAndGroup: {
          keyFields: false
        },
        /**
         * WRITING POLICIES FOR FILTER QUERY FOR PAGINATION
         */
        Query: {
          merge: true,
          fields: {
            globalSearch: {
              keyArgs: ['text', 'type', 'sort_by', 'sort_param'],
              merge(existing, incoming, { args }) {
                if (!existing) return incoming;
                const existingKnots: any[] = existing?.data?.knot;
                const incomingKnots: any[] = incoming?.data?.knot;
                const merged = queryMerge(existingKnots, incomingKnots, args, incoming?.data?.knot_count?.collections);
                const obj = {
                  ...incoming,
                  data: { ...incoming.data, knot: merged },
                };
                return obj
              },
              read(existing, { args }) {
                if (!existing) return undefined;
                return {
                  ...existing,
                  data: {
                    ...existing.data, knot: queryRead(existing?.data?.knot, args),
                  }
                };
              }
            },
            getRelevantContactAndGroupList: {
              keyArgs: ['text'],
              merge(existing, incoming, { args }) {
                const existingList: [] = existing?.data;
                const incomingList: [] = incoming?.data;
                if (!existing) 
                  return incoming;
                else {
                  const merged = queryMerge(existingList, incomingList, args, incoming?.paginatorInfo.total);
                  const obj = {
                    ...incoming,
                    data: merged,
                  };
                  return obj;
                }
              },
              read(existing, { args }) {
                if (!existing) return undefined;
                const obj = {
                  ...existing,
                  data: queryRead(existing?.data || [], args),          
                };
                return obj;
              }
            },
            getRelevantContactForUpdateGroup: {
              keyArgs: ['search', 'group_id'],
              merge(existing, incoming, { args }) {
                
                const existingList: [] = existing?.data;
                const incomingList: [] = incoming?.data;
                if (!existing) 
                  return incoming;
                else {
                  const merged = queryMerge(existingList, incomingList, args, incoming?.paginatorInfo.total);
                  const obj = {
                    ...incoming,
                    data: merged,
                  };
                  return obj;
                }
              },
              read(existing, { args }) {
                if (!existing) return undefined;
                const obj = {
                  ...existing,
                  data: queryRead(existing?.data || [], args),          
                };
                return obj;
              }
            },
            getRelevantContactForUpdateCollection: {
              keyArgs: ['search', 'collection_id'],
              merge(existing, incoming, { args }) {
                
                const existingList: [] = existing?.data;
                const incomingList: [] = incoming?.data;
                if (!existing) 
                  return incoming;
                else {
                  const merged = queryMerge(existingList, incomingList, args, incoming?.paginatorInfo.total);
                  const obj = {
                    ...incoming,
                    data: merged,
                  };
                  return obj;
                }
              },
              read(existing, { args }) {
                if (!existing) return undefined;
                const obj = {
                  ...existing,
                  data: queryRead(existing?.data || [], args),          
                };
                return obj;
              }
            },
            filterKnot: {
              keyArgs: [
                'text',
                'sort_by',
                'sort_param',
                'tags',
                'types',
                'untagged',
              ],

              merge(existing, incoming, { args }) {
                if (!existing) return incoming;
                const existingKnots: any[] = existing?.data?.knot;
                const incomingKnots: any[] = incoming?.data?.knot;
                let knotCount: number;
                if(args.types && incoming?.data?.knot_count) knotCount = incoming.data.knot_count[args.types];
                const merged = queryMerge(existingKnots, incomingKnots, args, knotCount)
                const obj = {
                  ...incoming,
                  data: { ...incoming.data, knot: merged },
                };
                return obj
              },
              read(existing, { args }) {
                if (!existing) return undefined;
                return {
                  ...existing,
                  data: {
                    ...existing.data, knot: queryRead(existing?.data?.knot, args),
                  }
                };
              }
            },
            // Temporarily commented below code to prevent compile errors due to change in collection structure
            collectionScraps: {
              keyArgs: [
                'collection_id',
                'sort_param',
                'sort_by',
                'tags',
                'untagged',
              ],

              merge(existing, incoming, { args, cache }) {
                
                const existingScraps: scrapType[] = existing?.data;
                let incomingScraps: scrapType[] = incoming?.data;
                let merged = queryMerge(existingScraps, incomingScraps, args, incoming?.paginatorInfo.total);
                const existingScrapUserData: userTypes[] = existing?.scrap_user_data || [];
                const incomingScrapUserData: userTypes[] = incoming?.scrap_user_data || [];
                const mergedScrapUserData = [
                  ...existingScrapUserData,
                  ...incomingScrapUserData.filter(
                    (scrapUser) =>
                      existingScrapUserData.findIndex(
                        (existingScrapUser) =>
                          existingScrapUser.user_id == scrapUser.user_id
                      ) < 0
                  ),
                ];
                const obj = {
                  ...incoming,
                  data: merged,
                  scrap_user_data: mergedScrapUserData,
                };
                return obj;
              },
              read(existing, { args }) { 
                if (!existing) return undefined;
                const obj = {
                  ...existing,
                  data: queryRead(existing?.data || [], args),          
                };
                return obj;
              },
            },
            groupScraps: {
              keyArgs: ['group_id', 'tags', 'untagged'],
              merge(existing, incoming, { args }) {
                const existingScraps: scrapType[] = existing?.data;
                const incomingScraps: scrapType[] = incoming?.data;
                if (!existing) 
                  return incoming;
                else {
                  const merged = queryMerge(existingScraps, incomingScraps, args, incoming?.paginatorInfo.total);
                  const obj = {
                    ...incoming,
                    data: merged,
                  };
                  return obj;
                }
              },
              read(existing, { args }) {
                if (!existing) return undefined;
                const obj = {
                  ...existing,
                  data: queryRead(existing?.data || [], args),          
                };
                return obj;
              }
            },
            filter: {
              // DEFINE KEYARGS TO STORE UNIQUES CACHE DATA OF SCRAPS FOR DIFFERENT TYPES OF FILTERS APPLIED BY USER
              keyArgs: [
                'sort_by',
                'sort_param',
                'tags',
                'types',
                'uncategorized',
                'untagged',
              ],
              /**
               * METHOD TO MERGE DATA IN PAGINATION FOR FILTER QUERY,
               * IT MERGES THE INCOMING DATA COMING FROM NETWORK REQUEST TO ALREADY CACHE DATA
               * */

              merge(existing, incoming, { args }) {
                const existingData: entryTypes[] = existing?.data;
                const incomingData: entryTypes[] = incoming?.data;
                const merged = queryMerge(existingData, incomingData, args, incoming?.total)
                return {
                  ...incoming,
                  data: merged,
                };
              },
              /**
               * METHOD TO RETURN DATA IN USEQUERY HOOK OF FILTER API IN PAGINATED WAY,
               * REASON TO WRITE THIS METHOD IS BECAUSE, WITHOUT THIS, ALL DATA FROM CACHE IS
               * DIRECTLY SHOWN IN DASHBOARD, WHICH IS UNEXPECTED AND WAS CAUSING ISSUES WITH
               * NEWLY UPDATED SCRAPS
               */
              read(existing, { args }) {
                if (!existing) return undefined;
                return {
                  ...existing,
                  data: queryRead(existing?.data, args),
                };
              },
            },

            getMyContactsOrInvitations: {
              keyArgs: ['action','text'],
              merge(existing,incoming,{ args }){
                
                let existingData: entryTypes[] = existing?.data.knot;
         
                let incomingData: entryTypes[] = incoming?.data.knot;
                if (!existingData)
                {
                  return incoming;
                } 


                else
                { 
                  const merged = queryMerge(existingData, incomingData, args);
                  const obj = {
                    ...incoming,
                    data:{
                      ...incoming.data,
                      knot:merged,
                      non_scrappi_users:incoming.data.non_scrappi_users
                    }
                  };
                  return obj
                }
              
              },
              read(existing, { args }) {    
                if (!existing) return undefined;
                const obj = {
                  ...existing,
                  data: {
                    ...existing.data,
                    knot: queryRead(existing?.data?.knot || [], args),
                  },
                };       
                return obj;
              },
            },


            getAllShare: {
              keyArgs: false,
              merge(existing,incoming,{ args }){
                
                let existingData: entryTypes[] = existing?.data;
         
                let incomingData: entryTypes[] = incoming?.data;
                if (!existingData)
                {
                  return incoming;
                } 

                else
                { 
                  const merged = queryMerge(existingData, incomingData, args);
                
                  const obj = {
                    ...incoming,
                    data: merged   
                  };
                  return obj
                }
              
              },
              read(existing, { args }) {    
                if (!existing) return undefined;
                const obj = {
                  ...existing,
                  data: queryRead(existing?.data || [], args),
                    
                };     
                return obj;
              },
            },


            getGroupShareList: {
              keyArgs: [],
              merge(existing,incoming,{ args }){
            
                let existingData: entryTypes[] = existing?.data;
         
                let incomingData: entryTypes[] = incoming?.data;
           
                if (!existingData)
                {
                  return incoming;
                } 


                else
                { 
                  const merged = queryMerge(existingData, incomingData, args);
            
                  const obj = {
                    ...incoming,
                    data:merged,
                      
                  };
               
                  return obj
                }
              
              },



              
              read(existing, { args }) {   
             
                if (!existing) return undefined;
             
                const obj = {
                  ...existing,
                  data:  queryRead(existing?.data || [], args),
                };   
    
                return obj;
              },
            },
            scraps:{
              // DEFINE KEYARGS TO STORE UNIQUES CACHE DATA OF SCRAPS FOR DIFFERENT TYPES OF FILTERS APPLIED BY USER

              keyArgs: [
                'sort_by',
                'sort_param',
                'tags',
                'untagged',
                'text',
                'uncategorized'
              ],

              merge(existing, incoming, { args, cache }) {
                const existingData = existing?.data || [];
                const incomingData = incoming?.data || [];
                const limit = incoming?.paginatorInfo?.total;
                const merged: Array<any> = existingData.slice(0);
                let offset = 0;
                if(args && incomingData.length) {
                  const { tie_breaker_id } = args;
                  if(tie_breaker_id) {
                    offset = existingData.findIndex(item => {
                      const parsedScrap: Partial<scrapType> = cache.readFragment({
                        id: item.__ref,
                        fragment: gql`
                          fragment ScrapFragment on Scrap {
                            id
                          }
                        `
                      })
                      if(!parsedScrap) return false;
                      return parsedScrap.id == tie_breaker_id
                    })
                    offset += 1
                  }
                  for (let i = 0; i < incomingData.length; ++i) {
                    merged[offset + i] = incomingData[i];
                    if (Number.isInteger(limit) && merged.length > limit) {
                      merged.splice(limit - 1, merged.length - limit);
                    }
                  }
                }
                return {
                  ...incoming,
                  data: merged,
                };
              },
              read(existing, { args, cache }) { 
                if (!existing) return undefined;
                const existingList: Array<any> = existing.data || []
                const { first, tie_breaker_id } = args || {}
                let sliceUptoIndex = first;
                if(tie_breaker_id) {
                  let lastSearchIndex = existingList.findIndex((item, i) => {
                    const parsedScrap: Partial<scrapType> = cache.readFragment({
                      id: item.__ref,
                      fragment: gql`
                        fragment ScrapFragment on Scrap {
                          id
                        }
                      `
                    })
                    if(!parsedScrap) return false;
                    return parsedScrap.id == tie_breaker_id;
                  }) + 1;
                  // lastSearchIndex += 1;
                  if(lastSearchIndex >= 0) sliceUptoIndex = lastSearchIndex + args.first;
                }
                return {
                  ...existing,
                  // data: queryRead(existing?.data, args),
                  data: existingList.slice(0, sliceUptoIndex)
                };
              },

            },
            globalSearchCollection: {
              keyArgs: [
                'text',
                'sort_by',
                'sort_param'
              ],
              merge(existing, incoming, {args, cache}) {
                const existingData = existing?.data || []
                const incomingData = incoming?.data || []
                const existingCollectionsMetaData = existing?.collection_meta_data || [];
                const incomingCollectionsMetaData = incomingData.map((item) => {
                  const parsedCollection: Partial<collectionType> = cache.readFragment({
                    id: item.__ref,
                    fragment: gql`
                      fragment CollectionFragment on SearchCollection {
                        id
                      }
                    `
                  })
                  if(parsedCollection?.id) {
                    const metaData: Partial<collectionType> = incoming?.collection_meta_data?.find(metaItem => {
                      const parsedMeta: Partial<collectionType> = cache.readFragment({
                        id: metaItem.__ref,
                        fragment: gql`
                          fragment MetaFragment on CollectionFull {
                            id
                          }
                        `
                      })
                      if(parsedMeta?.id) {
                        return parsedMeta.id == parsedCollection.id
                      } else {
                        console.error('Failed to parse collection meta')
                        return false;
                      }
                    })
                    return metaData ?? null;
                    
                  } else {
                    console.error('failed to parse collection');
                    return null;
                  }
                  
                });

                const existingScrapsCounts = existing?.scrapsCount || [];
                const incomingScrapsCounts = incomingData.map((item) => {
                  const parsedCollection: Partial<collectionType> = cache.readFragment({
                    id: item.__ref,
                    fragment: gql`
                      fragment CollectionFragment on SearchCollection {
                        id
                      }
                    `
                  })
                  if(parsedCollection?.id) {
                    const scrapsCount: Partial<collectionType> = incoming?.scrapsCount?.find(metaItem => {
                      const parsedScrapsCount: Partial<collectionType> = cache.readFragment({
                        id: metaItem.__ref,
                        fragment: gql`
                          fragment ScrapsCountFragment on CollectionWithScrapCount {
                            id
                          }
                        `
                      })
                      if(parsedScrapsCount?.id) {
                        return parsedScrapsCount.id == parsedCollection.id
                      } else {
                        console.error('Failed to parse collection meta')
                        return false;
                      }
                    })
                    return scrapsCount;
                    
                  } else {
                    console.error('failed to parse collection');
                    return null;
                  }
                  
                });

                
                const limit = incoming?.paginatorInfo?.total;
                const merged: Array<any> = existingData.slice(0);
                const mergedMeta: Array<any> = existingCollectionsMetaData.slice(0);
                const mergedScrapsCounts: Array<any> = existingScrapsCounts.slice(0)
                let offset = 0;
                if(args && incomingData?.length) {
                  const {tie_breaker_id} =  args
                  if(tie_breaker_id) {
                    offset = existingData.findIndex(item => {
                      const parsedCollection: Partial<collectionType> = cache.readFragment({
                        id: item.__ref,
                        fragment: gql`
                          fragment CollectionFragment on SearchCollection {
                            id
                          }
                        `
                      })
                      if(!parsedCollection) return false;
                      return parsedCollection.id == tie_breaker_id;
                    })
                    offset += 1;
                  }
                  for (let i = 0; i < incomingData.length; ++i) {
                    merged[offset + i] = incomingData[i];
                    mergedMeta[offset + i] = incomingCollectionsMetaData[i];
                    mergedScrapsCounts[offset + i] = incomingScrapsCounts[i];

                    if (Number.isInteger(limit) && merged.length > limit) {
                      merged.splice(limit - 1, merged.length - limit);
                      mergedMeta.splice(limit - 1, mergedMeta.length - limit);
                      mergedScrapsCounts.splice(limit - 1, mergedScrapsCounts.length - limit);
                    }
                  }

                }
                return {
                  ...(incoming ?? existing),
                  data: merged,
                  collection_meta_data: mergedMeta,
                  scrapsCount: mergedScrapsCounts
                }
              },
              read(existing, {args, cache}) {
                if(!existing) return undefined;
                const existingList: Array<any> = existing.data || []
                const { first, tie_breaker_id } = args || {}
                let sliceUptoIndex = first;
                if(tie_breaker_id) {
                  let lastSearchIndex = existingList.findIndex((item, i) => {
                    const parsedCollection: Partial<collectionType> = cache.readFragment({
                      id: item.__ref,
                      fragment: gql`
                        fragment CollectionFragment on SearchCollection {
                          id
                        }
                      `
                    })
                    if(!parsedCollection) return false;
                    return parsedCollection.id == tie_breaker_id;
                  }) + 1;
                  // lastSearchIndex += 1;
                  if(lastSearchIndex >= 0) sliceUptoIndex = lastSearchIndex + args.first;
                }
                return {
                  ...existing,
                  // data: queryRead(existing?.data, args),
                  data: existingList.slice(0, sliceUptoIndex)
                };

              }
            },
            globalSearchUsers: {
              keyArgs: ['text'],
              merge(existing, incoming, {args, cache}) {
                const existingData = existing?.data?.knot || []
                const incomingData = incoming?.data?.knot || []
                const limit = incoming?.paginatorInfo?.total
                const merged: Array<any> = existingData.slice(0);


                let offset = 0;
                if(args && incomingData?.length) {
                  const {tie_breaker_id} =  args
                  if(tie_breaker_id) {
                    offset = existingData.findIndex(item => {
                      const parsedUser: Partial<collectionType> = cache.readFragment({
                        id: item.__ref,
                        fragment: gql`
                          fragment UsersFragment on Users {
                            id
                          }
                        `
                      })
                      if(!parsedUser) return false;
                      return parsedUser.id == tie_breaker_id;
                    })
                    offset += 1;
                  }
                  for (let i = 0; i < incomingData.length; ++i) {
                    merged[offset + i] = incomingData[i];
                    
                    if (Number.isInteger(limit) && merged.length > limit) {
                      merged.splice(limit - 1, merged.length - limit);
                    }
                  }

                }
                const prevData = incoming ?? existing;
                return {
                  ...(prevData),
                  data: {
                    ...prevData.data,
                    knot: merged
                  },
                }
              },
              read(existing, {args, cache}) {
                if(!existing) return undefined;
                const existingList: Array<any> = existing.data?.knot || []
                const { first, tie_breaker_id } = args || {}
                let sliceUptoIndex = first;
                if(tie_breaker_id) {
                  let lastSearchIndex = existingList.findIndex((item, i) => {
                    const parsedUser: Partial<collectionType> = cache.readFragment({
                      id: item.__ref,
                      fragment: gql`
                        fragment UsersFragment on Users {
                          id
                        }
                      `
                    })
                    if(!parsedUser) return false;
                    return parsedUser.id == tie_breaker_id;
                  }) + 1;
                  // lastSearchIndex += 1;
                  if(lastSearchIndex >= 0) sliceUptoIndex = lastSearchIndex + args.first;
                }
                return {
                  ...existing,
                  // data: queryRead(existing?.data, args),
                  data: {
                    ...existing.data,
                    knot: existingList.slice(0, sliceUptoIndex)
                  }
                };
              }

            },
            globalSearchShare: {
              keyArgs: [
                'text',
                'sort_by',
                'sort_param'
              ],
              merge(existing, incoming, {args, cache}) {
                const existingData = existing?.data || []
                const incomingData = incoming?.data || []
                const existingSharesMetaData = existing?.collection_meta_data || [];
                const incomingSharesMetaData = incomingData.map((item) => {
                  const parsedGroup: Partial<GroupType> = cache.readFragment({
                    id: item.__ref,
                    fragment: gql`
                      fragment ShareFragment on SearchShare {
                        id
                      }
                    `
                  })
                  if(parsedGroup?.id) {
                    const metaData: Partial<GroupType> = incoming?.shareMetaData?.find(metaItem => {
                      const parsedMeta: Partial<GroupType> = cache.readFragment({
                        id: metaItem.__ref,
                        fragment: gql`
                          fragment MetaFragment on CollectionFull {
                            id
                          }
                        `
                      })
                      if(parsedMeta?.id) {
                        return parsedMeta.id == parsedGroup.id
                      } else {
                        console.error('Failed to parse collection meta')
                        return false;
                      }
                    })
                    return metaData;
                    
                  } else {
                    console.error('failed to parse collection');
                    return null;
                  }
                  
                });

                const existingScrapsCounts = existing?.scrapsCount || [];
                const incomingScrapsCounts = incomingData.map((item) => {
                  const parsedGroup: Partial<GroupType> = cache.readFragment({
                    id: item.__ref,
                    fragment: gql`
                      fragment ShareFragment on SearchShare {
                        id
                      }
                    `
                  })
                  if(parsedGroup?.id) {
                    const scrapsCount: Partial<GroupType> = incoming?.scrapsCount?.find(metaItem => {
                      const parsedScrapsCount: Partial<GroupType> = cache.readFragment({
                        id: metaItem.__ref,
                        fragment: gql`
                          fragment ScrapsCountFragment on CollectionWithScrapCount {
                            id
                          }
                        `
                      })
                      if(parsedScrapsCount?.id) {
                        return parsedScrapsCount.id == parsedGroup.id
                      } else {
                        console.error('Failed to parse collection meta')
                        return false;
                      }
                    })
                    return scrapsCount;
                    
                  } else {
                    console.error('failed to parse collection');
                    return null;
                  }
                  
                });


                const limit = incoming?.paginatorInfo?.total;
                const merged: Array<any> = existingData.slice(0);
                const mergedMeta: Array<number> = existingSharesMetaData.slice(0);
                const mergedScrapsCounts: Array<number> = existingScrapsCounts.slice(0);
                let offset = 0;
                if(args && incomingData?.length) {
                  const {tie_breaker_id} =  args
                  if(tie_breaker_id) {
                    offset = existingData.findIndex(item => {
                      const parsedShare: Partial<GroupType> = cache.readFragment({
                        id: item.__ref,
                        fragment: gql`
                          fragment ShareFragment on SearchShare {
                            id
                          }
                        `
                      })
                      if(!parsedShare) return false;
                      return parsedShare.id == tie_breaker_id;
                    })
                    offset += 1;
                  }
                  for (let i = 0; i < incomingData.length; ++i) {
                    merged[offset + i] = incomingData[i];
                    mergedMeta[offset + i] = incomingSharesMetaData[i];
                    mergedScrapsCounts[offset + i] = incomingScrapsCounts[i];

                    if (Number.isInteger(limit) && merged.length > limit) {
                      merged.splice(limit - 1, merged.length - limit);
                      mergedMeta.splice(limit - 1, mergedMeta.length - limit);
                      mergedScrapsCounts.splice(limit - 1, mergedScrapsCounts.length - limit);
                    }
                  }

                }
                return {
                  ...(incoming ?? existing),
                  data: merged,
                  shareMetaData: mergedMeta,
                  scrapsCount: mergedScrapsCounts,
                }
              },
              read(existing, {args, cache}) {
                if(!existing) return undefined;
                const existingList: Array<any> = existing.data || []
                const { first, tie_breaker_id } = args || {}
                let sliceUptoIndex = first;
                if(tie_breaker_id) {
                  let lastSearchIndex = existingList.findIndex((item, i) => {
                    const parsedShare: Partial<GroupType> = cache.readFragment({
                      id: item.__ref,
                      fragment: gql`
                        fragment ShareFragment on SearchShare {
                          id
                        }
                      `
                    })
                    if(!parsedShare) return false;
                    return parsedShare.id == tie_breaker_id;
                  }) + 1;
                  // lastSearchIndex += 1;
                  if(lastSearchIndex >= 0) sliceUptoIndex = lastSearchIndex + args.first;
                }
                return {
                  ...existing,
                  // data: queryRead(existing?.data, args),
                  data: existingList.slice(0, sliceUptoIndex)
                };

              }
            },
            filterScraps: {
              keyArgs: [
                'sort_by',
                'sort_param',
                'tags',
                'untagged',
                'text',
                'uncategorized'
              ],
              merge(existing, incoming, { args, cache }) {
                const existingData = existing?.data || [];
                const incomingData = incoming?.data || [];
                const limit = incoming?.paginatorInfo?.total;
                const merged: Array<any> = existingData.slice(0);
                let offset = 0;
                if(args && incomingData.length) {
                  const { tie_breaker_id } = args;
                  if(tie_breaker_id) {
                    offset = existingData.findIndex(item => {
                      const parsedScrap: Partial<scrapType> = cache.readFragment({
                        id: item.__ref,
                        fragment: gql`
                          fragment ScrapFragment on Scrap {
                            id
                          }
                        `
                      })
                      if(!parsedScrap) return false;
                      return parsedScrap.id == tie_breaker_id
                    })
                    offset += 1
                  }
                  for (let i = 0; i < incomingData.length; ++i) {
                    merged[offset + i] = incomingData[i];
                    if (Number.isInteger(limit) && merged.length > limit) {
                      merged.splice(limit - 1, merged.length - limit);
                    }
                  }
                }
                return {
                  ...(incoming ?? existing),
                  data: merged,
                };
              },
              read(existing, { args, cache }) { 
                if (!existing) return undefined;
                const existingList: Array<any> = existing.data || []
                const { first, tie_breaker_id } = args || {}
                let sliceUptoIndex = first;
                if(tie_breaker_id) {
                  let lastSearchIndex = existingList.findIndex((item, i) => {
                    const parsedScrap: Partial<scrapType> = cache.readFragment({
                      id: item.__ref,
                      fragment: gql`
                        fragment ScrapFragment on Scrap {
                          id
                        }
                      `
                    })
                    if(!parsedScrap) return false;
                    return parsedScrap.id == tie_breaker_id;
                  }) + 1;
                  // lastSearchIndex += 1;
                  if(lastSearchIndex >= 0) sliceUptoIndex = lastSearchIndex + args.first;
                }
                return {
                  ...existing,
                  // data: queryRead(existing?.data, args),
                  data: existingList.slice(0, sliceUptoIndex)
                };
              },
            }
          },
          
        },
        FilterType: {
          keyFields: ['label', 'value'],
        },
      },
      // @ts-ignore: ignore the unknown type
    }).restore(returnApolloState()),
    defaultOptions,
  });
};
export default graphQlWrapper;