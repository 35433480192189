import React, { useState, useEffect } from 'react';
import qs from 'qs';

import { useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';

import { ACCEPT_COLLECTION_COLLABORATOR } from './data/collaboration';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import ErrorAuthPage from '../components/Account/ErrorAuthPage';
import { getToken } from '../apollo/getToken';
import { slugifyString } from '../helpers';

const AcceptCollaboratorWrapper = (props) => {
  const { search } = useLocation();
  const history = useHistory();
  const urlParamValues = qs.parse(search.substring(1));
  const { token } = urlParamValues || {};
  const [acceptCollaborator] = useMutation(ACCEPT_COLLECTION_COLLABORATOR);

  const [fetchErrorMessage, setFetchErrorMessage] = useState(null);
  async function acceptCollaboratorHandler() {
    try {
      const response = await acceptCollaborator({
        variables: { token, type: 'collaborator' },
      });

      const { data, messages } = response?.data?.acceptCollectionCollaborator || {};
      const { error } = messages || { error: [], success: [] };
      const { email: fetchedEmail, collection } = data || {};
      const { title, id } = collection || {};
      if (fetchedEmail && id) {
        return history.replace(
          `/c/${slugifyString(title, false)}/${id}?notification=accepted-invite`
        );
      } else {
        if (Array.isArray(error) && error.length === 2) throw error;
        else
          throw new Error(
            Array.isArray(error) && error.length > 0
              ? error[0]
              : 'Something went wrong'
          );
      }
    } catch (e) {
      console.log('error ', e)
      if (Array.isArray(e) && e.length === 2) setFetchErrorMessage(e);
      else setFetchErrorMessage(parseTryCatchError(e));
    }
  }
  useEffect(() => {
    const jwtToken = getToken();

    if (jwtToken) {
      if (token) {
        acceptCollaboratorHandler();
      } else history.replace('/');
    } else {
      if (token) {
        history.replace(`/login?collectionCollaboratorToken=${token}`);
      } else {
        history.replace('/login');
      }
    }
  }, [search]);
  if (fetchErrorMessage) {
    let title = 'Collaboration Invite';
    let message = fetchErrorMessage;
    // If fetchErrorMessage is an array, then first item will be heading and second one will be message
    if (Array.isArray(fetchErrorMessage)) {
      title = fetchErrorMessage[0];
      message = fetchErrorMessage[1];
    }
    return (
      <ErrorAuthPage
        title={title}
        message={message}
        hideActions
        view="accept-collaborator"
      />
    );
  }

  return null;
};

export default AcceptCollaboratorWrapper;
