import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const FullScreenIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0016 5.97826C20.0016 6.53061 19.5539 6.97838 19.0017 6.97838C18.4495 6.97838 18.0018 6.53061 18.0018 5.97826L18.0018 4.38665L13.6464 8.68151C13.2532 9.06929 12.6201 9.06479 12.2324 8.67145C11.8447 8.27811 11.8492 7.64489 12.2425 7.25711L16.5633 2.99634L15.0206 2.99633C14.4684 2.99632 14.0207 2.54855 14.0207 1.9962C14.0207 1.44385 14.4684 0.99609 15.0206 0.996094L19.0017 0.996119C19.5539 0.996122 20.0016 1.44389 20.0016 1.99623L20.0016 5.97826ZM0.000869751 5.97913C0.00086925 6.53135 0.44863 6.97902 1.00097 6.97902C1.55331 6.97902 2.00108 6.53135 2.00108 5.97913L2.00108 4.41193L6.26681 8.67678C6.65738 9.06726 7.29061 9.06726 7.68117 8.67678C8.07174 8.28629 8.07174 7.65319 7.68117 7.26271L3.41543 2.99786L4.98299 2.99785C5.53533 2.99784 5.98309 2.55017 5.98308 1.99794C5.98308 1.44572 5.53531 0.998049 4.98297 0.998053L1.00097 0.998078C0.448631 0.998082 0.000873542 1.44575 0.000873083 1.99797L0.000869751 5.97913ZM19.0014 15.0187C19.5536 15.0187 20.0013 15.4665 20.0013 16.0189L20.0013 20.0009C20.0013 20.5532 19.5536 21.001 19.0014 21.001L15.0203 21.001C14.468 21.001 14.0204 20.5533 14.0204 20.0009C14.0204 19.4486 14.468 19.0008 15.0202 19.0008L16.5875 19.0008L12.2373 14.6496C11.8468 14.259 11.8468 13.6258 12.2373 13.2352C12.6277 12.8446 13.2608 12.8446 13.6513 13.2352L18.0015 17.5864L18.0015 16.0189C18.0015 15.4665 18.4492 15.0187 19.0014 15.0187ZM0.00119865 16.018C0.00119819 15.4657 0.448959 15.0181 1.0013 15.0181C1.55364 15.0181 2.0014 15.4657 2.0014 16.018L2.00141 17.5607L6.262 13.24C6.64978 12.8467 7.28299 12.8422 7.67632 13.2299C8.06965 13.6176 8.07415 14.2507 7.68637 14.644L3.39169 18.9992L4.98331 18.9993C5.53566 18.9993 5.98342 19.4469 5.98341 19.9992C5.98341 20.5514 5.53564 20.9991 4.9833 20.999L1.0013 20.999C0.44896 20.999 0.00120282 20.5513 0.00120236 19.9991L0.00119865 16.018Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default FullScreenIcon;
