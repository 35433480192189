import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FACEBOOK_LOGIN_MUTATION } from '../../../../containers/Login';
import { useMutation } from '@apollo/client';
import AppleLogin from 'react-apple-login';
import jwt_decode from 'jwt-decode';
import { accountNotExistErrorCode } from '../Messages';
import {
  performNativeSocialLogin,
  setToken,
  storageListener,
} from '../../../../helpers';
import { LOGIN_VIA, STORAGE_KEYS } from '../../../../helpers/config';
import { ProfileType } from '../../types/ProfileType';
import './styles.css';

export interface AppleProfile {
  email: string;
  login_via: number;
  userID: string;
  name?: string;
  givenName?: string;
  familyName?: string;
}

interface Proptypes {
  processing?: boolean;
  label?: string;
  setProcessing?: (val: boolean) => void;
  registerRedirect?: (profileObj, emailExist?: boolean) => void;
  text?: string;
  handleRedirection?: (onboard_status?: boolean) => void;
  setMainLoading?: Dispatch<SetStateAction<boolean>>;
  overrideSubmit?: boolean;
  onSuccess?: (data?: ProfileType) => void;
}

const AppleButton = (props: Proptypes) => {
  const {
    registerRedirect,
    text,
    handleRedirection,
    setMainLoading,
    overrideSubmit,
    onSuccess,
  } = props;
  const [loginMutation] = useMutation(FACEBOOK_LOGIN_MUTATION);
  // For managing FB button state
  const [isDisabled, setIsDisabled] = useState(false);
  const [redirectURI, setRedirectURI] = useState<string | undefined>();

  const responseApple = async (
    accessToken: string,
    profileObj: AppleProfile
  ) => {
    setMainLoading && setMainLoading(true);
    if (accessToken) {
      if (overrideSubmit) {
        onSuccess({
          social_auth_code: accessToken,
          social_id: profileObj.userID,
          email: profileObj.email,
          login_via: LOGIN_VIA.apple,
        });
      } else {
        loginMutation({
          variables: {
            social_auth_code: accessToken,
            social_id: profileObj.userID,
            email: profileObj.email,
            login_via: LOGIN_VIA.apple,
          },
        }).then(async (val) => {
          var response = val.data.login;
          if (response.data.access_token) {
            await setToken(response.data.access_token, response.data.user?.id);
            if (handleRedirection) {
              handleRedirection(response.data.user?.profile?.onboard_status);
            } else {
              window.location.replace('/');
            }
          } else {
            let emailExist = response.http_code === accountNotExistErrorCode;
            setMainLoading && setMainLoading(false);
            emailExist &&
              registerRedirect &&
              registerRedirect(profileObj, emailExist);
          }
        }); 
      }
    }
  };

  const checkPlatform = (platform?: string, cb?: () => void) => {
    if (platform?.length && platform !== 'ios') {
      setRedirectURI(undefined); // Hiding the apple button in case it is a webview and is not ios
    } else {
      cb && cb();
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const platform = window.localStorage.getItem(
        `${STORAGE_KEYS.WEBVIEW_PLATFORM}`
      );
      checkPlatform(platform, () => {
        setRedirectURI(window.location.origin);
      });
      // For native error listener
      storageListener(STORAGE_KEYS.WEBVIEW_PLATFORM, (platform: string) => {
        checkPlatform(platform);
      });
    }
  }, []);

  if (!redirectURI) return null;

  return (
    <>
      <div className={`App`}>
        <AppleLogin
          clientId="com.scrappi.web"
          redirectURI={redirectURI}
          usePopup={true}
          scope="name email"
          responseType="code id_token"
          responseMode="form_post"
          callback={(response) => {
            if ('authorization' in response) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              var decoded: any = jwt_decode(response.authorization?.id_token);
              if (decoded) {
                setIsDisabled(false);
                const firstName = response.user?.name?.firstName ?? '';
                const lastName = response.user?.name?.firstName ?? '';
                const fullName = (firstName + ' ' + lastName).trim();
                responseApple(response.authorization?.code, {
                  email: decoded.email ?? '',
                  login_via: LOGIN_VIA.apple,
                  userID: decoded.sub,
                  givenName: firstName,
                  familyName: lastName,
                  name: fullName,
                });
              }
            } else if (response.error) {
              setIsDisabled(false);
              setMainLoading && setMainLoading(false);
            }
          }}
          render={(
            renderProps //Custom Apple Sign in Button
          ) => (
            <button
              onClick={() => {
                performNativeSocialLogin(
                  'apple',
                  () => renderProps.onClick() // Web Callback if not native
                );
              }}
              disabled={isDisabled || !redirectURI}
              className="apple-button"
            >
              <img src="/dashboard/img/apple.png" alt="Google" />
              <span>{text}</span>
            </button>
          )}
        />
      </div>
    </>
  );
};

export default AppleButton;
