import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['username', 'String!'],]);

export default function GLOBAL_USER(responseString = '') {
  return gql`
  query GlobalUser(${parametersTypeString}){
    globalUser(${parametersAssignString}){
      ${defaultResponse}
      data {
        user_id
        userName
        first_name
        last_name
        avatar
        bio
        scraps_count
        collections_count
        cover_image
        social_media_accounts
        location
        display_name
        url
        is_contact
        is_trusted
      }
    }
  } 
  `;
}
