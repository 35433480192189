/* eslint-disable no-undef */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Popup from 'reactjs-popup';
import { ReduxStateType } from '../../../redux/store';
import { CloseIcon, NewClose, TickIcon } from '../icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import Button from '../../uiComponents/Button';
import { canPaste } from "../../../redux/action/pasteDataAction";
const faSpinnerIcon = faSpinner as IconProp;
/**
 * DESCRIPTION
 * Reusable component which will be used to show the popup to user
 * Control the size of popup with size prop.
 * It can either be controlled from outside or from inside
   - To control it from outside, pass controlled object in props to this component, which will then conditionally show popup
   to the user
   - To control it from inside, pass any jsx element in triggerer prop which will be then used to toggle the popup
 * It has header prop which is used to render show content in popup header
   - By default, it will show heading and close button in the header 
   but you can also customize it with customRender method which is optional in header prop.
 * You can also render the footer in the popup by passing footer prop
 */
interface Proptypes {
  onOpen?: () => void;
  invisible?: boolean;
  className?: string;
  header?: {
    heading?: string;
    className?: string; // Optional className which will be added to header
    customRender?: (close: () => void) => JSX.Element;
    renderIcon?: (close: () => void) => JSX.Element;
  };
  onClosed?: () => void; // Callback function to render
  secondaryPopup?:boolean;
  body?: {
    className?: string; //Optional className which will be added to body
  };
  size?: 'Default' | 'Small' | 'Large' | 'Extra large';
  footer?: {
    className?: string; // Optional className which will be added to footer
    customRender?: (close: () => void) => JSX.Element;
    hide?: boolean;
    onSubmit?: (close: () => void) => void;
    submitLabel?: string;
    hideSubmit?: boolean;
    disableSubmit?: boolean;
    cancelLabel?: string;
    hideCancel?: boolean;
    disableCancel?: boolean;
    leftRender?: (close: () => void) => JSX.Element;
    onCancelClick?: (close: () => void) => void;
    spinnerConfig?: {
      show: boolean;
      label: string;
    }
  };
  children: ((close: () => void) => JSX.Element) | React.ReactElement;
  triggerer?: JSX.Element; // Triggerer button which will used to toggle popup
  unclosable?: boolean; // Optional boolean prop which if true makes the popup unclosable
  controlled?: {
    show: boolean;
    setShow: (val: boolean) => void;
  };
  nestedPopup?: boolean;
  fullMobile?: boolean;
  action?: () => void; // a callback function for additional actions
  defaultOpen?: boolean
}
const sizeConfig = {
  Default: 'new-popup--default',
  Small: 'new-popup--small',
  Large: 'new-popup--large',
  'Extra large': 'new-popup--extra-large',
};
const NewPopup = ({
  onOpen,
  className,
  header,
  children,
  triggerer,
  body,
  footer,
  onClosed,
  secondaryPopup=false,
  unclosable,
  controlled,
  nestedPopup,
  size,
  fullMobile,
  invisible,
  action,
  defaultOpen = false
}: Proptypes) => {
  const { show: controlledShow, setShow: setControlledShow } = controlled || {};
  const [show, setShow] = useState(defaultOpen);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [initialHeight, setInitialHeight] = useState(null);
  const { height, width } = useSelector((state: ReduxStateType) => state.utils.dimensions);
  const dispatch = useDispatch()

  useEffect(() => {
    // disable paste when the popup is shown
    if(controlledShow) {
      dispatch(canPaste(false));
    }

    const body = document?.body
    if((controlledShow || show) && body) {
      if (body.style.overflow === 'auto') body.style.overflow = 'hidden';
      // Needed to add this mutation observer since masonry component is automatically changing the overflow of body to auto
      // But we wanted the over flow of body to be hidden
      const observer = new MutationObserver((mutations) => {
        if (body.style.overflow === 'auto') body.style.overflow = 'hidden';
      });
      var target = document.body;
      observer.observe(target, {
        attributes: true,
        attributeFilter: ['style'],
      });
      return () => {
        observer.disconnect();
        body.style.overflow = 'auto';
      };
    }

    return () => {
      dispatch(canPaste(true));
    }
  }, [controlledShow, show])

  useEffect(() => {
    if (!initialHeight) {
      setInitialHeight(height);
    }
    if (
      typeof window !== 'undefined' &&
      width <= 768 &&
      height < initialHeight / 1.4
    ) {
      setKeyboardOpen(true);
    } else if (keyboardOpen) {
      setKeyboardOpen(false);
    }

    if(width > height){
      setKeyboardOpen(false);
    }
  }, [height, width]);

  const sizeClass = sizeConfig[size || 'Default'];

  /**
   * when new scrap creation is triggered
   * clicking on the close button will delete the scrap
   */
  function triggerScrapDelete () {
    if (action) action();
  }

  // Handler for closing the popup
  function closeHandler() {
    if (unclosable) return;
    if(!secondaryPopup){
      if (controlled) setControlledShow(false);
      else setShow(false);
    }
    onClosed && onClosed();
  }

  function renderHeader() {
    const { className, customRender, heading, renderIcon } = header;
    if (customRender) return customRender(closeHandler);
    let containerClass = 'new-popup__header-inner-container';
    if (className) containerClass += ' ' + className;
    return (
      <div className={containerClass}>
        <h3 className="new-popup__header-heading">
          {renderIcon && renderIcon(closeHandler) && renderIcon(closeHandler)}
          {heading || 'Heading'}
        </h3>
        <button
          onClick={() => {
            closeHandler();
            triggerScrapDelete();
          }}
          className="new-popup__header-button hoverState__with-white-text"
        >
          <NewClose className="new-popup__header-button__icon" />
        </button>
      </div>
    );
  }

  function submitHandler(cb: Function) {
    try {
      cb(closeHandler)
    } catch (error) {
      // todo: error handling
    }
    
  }

  function renderBody() {
    const { className } = body || {};
    let containerClass = 'new-popup__body';
    if (className) containerClass += ' ' + className;
    return <div className={containerClass}>{typeof children === 'function' ? children(closeHandler): children}</div>;
  }
  function renderFooter() {
    const {
      customRender,
      className,
      cancelLabel,
      hide,
      hideCancel,
      hideSubmit,
      disableSubmit,
      disableCancel,
      onSubmit,
      submitLabel,
      leftRender,
      onCancelClick,
      spinnerConfig,
    } = footer;

    if (hide) return null;
    let containerClass = 'new-popup__footer';
    if (className) containerClass += ' ' + className;
    if (leftRender) containerClass += ' new-popup__footer--double';
    if(spinnerConfig?.show) containerClass += ' new-popup__footer--with-spinner';
    if (customRender)
      return <div className={containerClass}>{customRender(closeHandler)}</div>;
    else if (onSubmit) {
      const cancelButtonLabel = cancelLabel || 'Cancel';
      const submitButtonLabel = submitLabel || 'Save';
      return (
        <div className={containerClass}>
          {(leftRender || spinnerConfig?.show) && (
            <div className="new-popup__footer-left">
              {leftRender ? (
                leftRender(closeHandler)
              ) : (
                <>
                  <span className="new-popup__footer-spinner-label">
                    {spinnerConfig.label}
                  </span>
                  <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
                </>
              )}
            </div>
          )}
          <div className="new-popup__footer-actions">
            {!hideCancel && (
              <Button
                buttonType='outlined-with-background'
                className="new-popup__footer-cancel"
                onClick={
                  onCancelClick
                    ? onCancelClick.bind(null, closeHandler)
                    : closeHandler
                }
                disabled={disableCancel || spinnerConfig?.show}
              >
                {cancelButtonLabel}
              </Button>
            )}
            {!hideSubmit && (
              <Button
                icon={<TickIcon />}
                buttonType="orange-filled"
                disabled={disableSubmit || spinnerConfig?.show}
                onClick={submitHandler.bind(null, onSubmit)}
                className="new-popup__footer-submit"
              >
                {submitButtonLabel}
              </Button>
            )}
          </div>
        </div>
      );
    }
  }
  let popupClass = 'new-popup ' + sizeClass;
  if (invisible) popupClass += ' new-popup--invisible';
  if (className) popupClass += ' ' + className;
  if (controlled) {
    return (
      <Popup
        onOpen={onOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        nested={nestedPopup}
        lockScroll
        className={`${popupClass} ${fullMobile ? ' new-popup__full' : ''}`}
        open={controlledShow}
        onClose={closeHandler}
      >
        {header && (
          <div
            className={`new-popup__header ${
              header?.className ? header.className : ''
            }`}
          >
            {renderHeader()}
          </div>
        )}
        <div className="new-popup__body-wrapper">
          {renderBody()}
          {footer && !keyboardOpen && renderFooter()}
        </div>
      </Popup>
    );
  }
  return (
    <Fragment>
      <button className="new-popup__triggerer" onClick={() => setShow(!show)}>
        {triggerer}
      </button>
      <Popup
        closeOnDocumentClick={false}
        closeOnEscape={false}
        nested={nestedPopup}
        lockScroll
        className={`${popupClass} ${fullMobile ? 'new-popup__full' : ''}`}
        open={show}
        onClose={closeHandler}
      >
        {/* <div className={'new-popup__content ' + sizeClass}> */}
        {header && (
          <div
            className={`new-popup__header ${
              header?.className ? header.className : ''
            }`}
          >
            {renderHeader()}
          </div>
        )}
        <div className="new-popup__body-wrapper">
          {renderBody()}
          {footer && renderFooter()}
        </div>
        {/* </div> */}
      </Popup>
    </Fragment>
  );
};
export default NewPopup;
