import React from 'react';
import { HeartIcon } from '../../Global/icons';
import {useDispatch, useSelector} from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import { getResponseMessages } from '../../../helpers';
import { useMutation } from '@apollo/client';
import ADD_REACTION from '../../../containers/data/addReaction';
import REMOVE_REACTION from '../../../containers/data/removeReaction';
import { sendToast } from "../../../helpers/notification";
import { dislikeScrap, likeScrap } from '../../../redux/action/scrapsLikesAndCommentsActions';

interface Proptypes {
  scrapId: number;
  userId: number;
  onLikeToggled?: (isLiked: boolean) => void;
}

const ScrapCardFooterLike = (props: Proptypes) => {
  const { scrapId, userId, onLikeToggled } = props;
  const scrapCommentsAndLikes = useSelector((state: ReduxStateType) => state.scrapsLikesAndComments.get(+scrapId));
  const isReacted = scrapCommentsAndLikes?.isLiked
  const [addReaction] = useMutation(ADD_REACTION());
  const [removeReaction] = useMutation(REMOVE_REACTION());
  const dispatch = useDispatch();

  async function toggleLikeHandler() {
    const currentlyLiked = isReacted;
    try {
      const mutationAction = currentlyLiked ? removeReaction : addReaction;
      const dispatchAction = currentlyLiked ? dislikeScrap : likeScrap;
      onLikeToggled && onLikeToggled(!currentlyLiked)
      dispatch(dispatchAction(scrapId));
      const response = await mutationAction({
        variables: {
          scrap_id: scrapId,
          reactable_type: 1,
        },
      });

      const action = isReacted ? 'removeReaction' : 'addReaction';
      const { isSuccess, error } = getResponseMessages(response.data[action]);

      // there can be a case of error from the API, and it doesn't go in catch
      if (!isSuccess) {
        throw new Error('Failed to react!');
      }
    } catch (error) {
      sendToast('Failed to react!', 'error');

      // reset the like state
      const dispatchAction = currentlyLiked ? likeScrap : dislikeScrap;
      dispatch(dispatchAction(scrapId));
      onLikeToggled && onLikeToggled(!currentlyLiked)
    }
  }

  return (
    <button
      onClick={toggleLikeHandler}
      className={`scrap-card__footer-button scrap-card__like-button${
        isReacted ? ' scrap-card__footer-button--active' : ''
      }${userId ? '' : ' scrap-card__footer-button--disabled'}`}
    >
      <HeartIcon isFilled={isReacted} />
      {scrapCommentsAndLikes?.likesCount > 0 ? scrapCommentsAndLikes?.likesCount : isReacted ? 1 : ''}
    </button>
  );
};

export default ScrapCardFooterLike;
