import React, { useEffect } from 'react';
import MainNavigation from './MainNavigation';
import { topicTypes } from '../../types/topic';
import { userTypes } from '../../types/user';
import { utilsTypes } from '../../types/utils';
import { setTheme, typesValue } from './ThemeToggle';
import { useDispatch } from 'react-redux';
import { toggleTheme } from '../../redux/action/themeAction';
import { STORAGE_KEYS } from '../../helpers/config';

interface propTypes {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topics: Array<any>;
  load: boolean;
  activeTopic: topicTypes;
  user: userTypes;
  addNewTopic(topic: topicTypes);
  utils?: utilsTypes;
  currentHighlight: string;
}

const Navigation = ({
  user,
  addNewTopic,
  currentHighlight,
  load,
}: propTypes) => {
  const DISPATCH = useDispatch();

  useEffect(() => {
    if (typeof document === 'object') {
      const theme = window.localStorage.getItem(`${STORAGE_KEYS.THEME}`);
      setTheme(theme);
      DISPATCH(toggleTheme({ type: theme, payload: typesValue[theme] }));
    }
  }, []);

  return (
    <aside className="navigation">
      <MainNavigation
        load={load}
        currentHighlight={currentHighlight}
        user={user}
        addNewTopic={addNewTopic}
      />
    </aside>
  );
};

export default Navigation;
