import { gql } from '@apollo/client';

export const GET_CONTACTS_INVITATIONS = gql`
  query getMyContactsOrInvitations(
    $action: String
    $page: Int
    $first: Int
    $text: String
  ) {
    getMyContactsOrInvitations(
      action: $action
      page: $page
      first: $first
      text: $text
    ) {
      data {
        non_scrappi_users {
          email
          phone
          created_at
        }
        knot {
          user_name
          display_name
          creator_initials
          avatar
          collection_count
          is_sent
          email
          phone
          is_trusted
        }
        knot_count {
          scrappers
          received_notification
        }
      }
      messages {
        error
        success
      }
    }

    getUsersITrust {
      data {
        knot {
          user_id
          userName
          avatar
          display_name
        }
      }
    }

    getUsersTrustMe {
      data {
        knot {
          user_id
          userName
          avatar
          display_name
          is_trusted
        }
      }
    }
  }
`;
