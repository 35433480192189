import React from 'react';

interface Proptypes {
  className?: string;
}

const Tick = ({ className }: Proptypes) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M8 16.9375C3.5816 16.9375 0 13.3559 0 8.9375C0 4.5191 3.5816 0.9375 8 0.9375C12.4184 0.9375 16 4.5191 16 8.9375C16 13.3559 12.4184 16.9375 8 16.9375ZM7.2024 12.1375L12.8584 6.4807L11.7272 5.3495L7.2024 9.8751L4.9392 7.6119L3.808 8.7431L7.2024 12.1375Z"
        fill="white"
      />
    </svg>
  );
};

export default Tick;
