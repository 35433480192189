import React, { useState, useContext } from 'react';
import ReloadContext from '../helpers/contexts/reloadContext';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { changeBatchEditMode, clearBatchEditScraps } from '../redux/action/batchEditAction';
import { moveStories } from '../redux/action/storiesAction';
import { moveCollections } from '../redux/action/collectionAction';
import { moveTags } from '../redux/action/tagAction';
// Graphql-apollo
import { useMutation } from '@apollo/client';

// Queries
import { BATCH_EDIT_OPERATIONS } from './data/batchEdit';

// Types
import { BatchEditType, BatchScrapVariablesType } from '../types/batchEdit';

// Components
import BatchEdit from '../components/BatchEdit';
import { waitBeforeExecute } from '../helpers';

const BatchEditWrapper = (props) => {
  const { setReload } = useContext(ReloadContext);
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const batchEdit: BatchEditType = useSelector((state: any) => state.batchEdit);
  const [isProcessing, setIsProcessing] = useState(false);
  const [batchRemove] = useMutation(
    BATCH_EDIT_OPERATIONS('bulkRemoveOperations')
  );
  const [batchAdd] = useMutation(BATCH_EDIT_OPERATIONS('bulkAddOperations'));
  async function handleBatchOperation(
    type: 'remove' | 'add',
    variables: BatchScrapVariablesType,
    cb?: Function
  ) {
    const mutation = type === 'remove' ? batchRemove : batchAdd;
    try {
      setIsProcessing(true);
      await mutation({ variables });
      cb && cb();
      await waitBeforeExecute(() => {
        cb && cb();
        setReload && setReload(true);
        // Locally move selected collections, tags and stories to top on list in redux store
        if (variables.stories?.length > 0)
          dispatch(moveStories(variables.stories));
        if (variables.tags?.length > 0) dispatch(moveTags(variables.tags));
        if (variables.collections?.length > 0)
          dispatch(moveCollections(variables.collections));
        // Clear all selected scraps
        dispatch(clearBatchEditScraps());
        dispatch(changeBatchEditMode(false));
      }, 2000);
    } catch (error) {
      //
    }
    setIsProcessing(false);
  }

  return (
    <BatchEdit
      isProcessing={isProcessing}
      scraps={batchEdit.selectedScraps}
      handleBatchOperation={handleBatchOperation}
    />
  );
};

export default BatchEditWrapper;
