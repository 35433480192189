import React, { useEffect } from 'react';

import useWindowOnClick from '../../../../helpers/customHooks/useWindowOnclick';

import './styles.css';

interface Proptypes {
  children: React.ReactChild | React.ReactChildren;
  onOutsideClick: () => void;
  hideOverflow?: boolean;
  removeOverlayListener?: boolean;
}

const CreateScrapPopup = ({
  children,
  onOutsideClick,
  hideOverflow,
  removeOverlayListener,
}: Proptypes) => {
  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement =
      el.closest('.create-scrap-popup__content') ||
      el.closest('.create-scrap__delete-popup-overlay') ||
      el.closest('.create-scrap-add-image__cropper-overlay');
    if (!closestElement) onOutsideClick();
  };
  useWindowOnClick({
    cb: handleOutSideClick,
    removeListener: removeOverlayListener || false,
  });
  useEffect(() => {
    if (hideOverflow) {
      const el = document.body;
      el.style.overflow = 'hidden';
      return () => {
        el.style.overflow = 'auto';
      };
    }
  }, [hideOverflow]);
  useEffect(() => {
    // Needed to add this mutation observer since masonry component is automatically changing the overflow of body to auto
    // But we wanted the over flow of body to be hidden
    const observer = new MutationObserver((mutations) => {
      const body = document.body;
      if (body.style.overflow === 'auto' && hideOverflow)
        body.style.overflow = 'hidden';
    });
    var target = document.body;
    observer.observe(target, { attributes: true, attributeFilter: ['style'] });
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className={`create-scrap-popup`}>
      <div className="create-scrap-popup__content">{children}</div>
    </div>
  );
};

export default CreateScrapPopup;
