import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import editor from "../../../PublicNotes/components/editor";
interface Proptypes {
  title: string;
  url: string;
  secondary?: boolean;
  fullTitle?: boolean;
  editClick?: () => void;
}
export default function WebContentTitle({
  title,
  url,
  secondary,
  fullTitle,
  editClick
}: Proptypes) {
  if (!title) return null;
  return (
    <h3
      className="web-content__title"
      onClick={(e) => {
        // if it is a double click
        if (e.detail === 2 && editClick) {
          editClick()
        }
      }}
    >
      {!secondary ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {fullTitle ? (
            title
          ) : (
            <LinesEllipsis
              text={title}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          )}
        </a>
      ) : (
        title
      )}
    </h3>
  );
}
