import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const Youtube= ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M17.6231 2.67714C17.5212 2.29562 17.3227 1.95021 17.0487 1.67763C16.7669 1.39667 16.4216 1.19569 16.0453 1.09367C14.637 0.704098 8.99475 0.704098 8.99475 0.704098C6.64257 0.676012 4.29096 0.799542 1.9532 1.07399C1.57689 1.18356 1.23222 1.38902 0.949835 1.67212C0.672372 1.9523 0.471399 2.2978 0.366414 2.67635C0.114217 4.10221 -0.00831698 5.55 0.000462825 7.00019C-0.00853596 8.44908 0.113698 9.89639 0.366414 11.324C0.46915 11.701 0.669373 12.0449 0.947585 12.3228C1.2258 12.6006 1.57225 12.8013 1.9532 12.9075C3.38026 13.2963 8.99475 13.2963 8.99475 13.2963C11.3499 13.3244 13.7045 13.2009 16.0453 12.9264C16.4216 12.8244 16.7669 12.6234 17.0487 12.3424C17.3226 12.0699 17.5209 11.7244 17.6223 11.3429C17.8811 9.91761 18.007 8.46922 17.998 7.01829C18.0175 5.56122 17.8918 4.10601 17.6231 2.67635V2.67714ZM7.20099 9.69492V4.30625L11.8954 7.00098L7.20099 9.69492Z"
        fill="#838383"
      />
    </svg>
  );
};

export default Youtube;
