import React, { createContext, useState } from 'react';
import { collectionFilterParamTypes } from '../../components/CollectionCreationWrapper/types';
/**
 * This context is used to locally store the applied filters from side nav
 * which will be used in collection detail page.
 * since filters in side nav will not affect the url
 */

interface CollectionFiltersContextType {
  filters: collectionFilterParamTypes;
  setFilters: (value: collectionFilterParamTypes) => void;
  collectionId: string | number;
  setCollectionId: (val: string | number) => void;
  
}
const CollectionFiltersContext = createContext<CollectionFiltersContextType>({
  filters: null,
  setFilters: function (val) {},
  collectionId: null,
  setCollectionId: function (val) {},
  
});

export function CollectionFiltersProvider(props) {
  const [filters, setFilters] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const context = {
    filters,
    setFilters,
    collectionId,
    setCollectionId,
  };
  return (
    <CollectionFiltersContext.Provider value={context}>
      {props.children}
    </CollectionFiltersContext.Provider>
  );
}

export default CollectionFiltersContext;
