import React from 'react';

interface Proptypes {
  className?: string;
}

const CaptionIcon = ({ className }: Proptypes) => {
  return (
    <svg
      className={className}
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666626 10.6719V3.13122C0.666626 1.74303 1.78591 0.617676 3.16663 0.617676H14.8333C16.214 0.617676 17.3333 1.74303 17.3333 3.13122V10.6719C17.3333 12.0601 16.214 13.1854 14.8333 13.1854H11.2929L9.12184 15.0953C7.7748 16.2804 5.66663 15.3186 5.66663 13.519V13.1854H3.16663C1.78591 13.1854 0.666626 12.0601 0.666626 10.6719ZM7.13428 12.3907C6.85269 11.8661 6.30106 11.5097 5.66663 11.5097H3.16663C2.70639 11.5097 2.33329 11.1346 2.33329 10.6719V3.13122C2.33329 2.66849 2.70639 2.29337 3.16663 2.29337H14.8333C15.2935 2.29337 15.6666 2.66849 15.6666 3.13122V10.6719C15.6666 11.1346 15.2935 11.5097 14.8333 11.5097H11.2929C10.8891 11.5097 10.4992 11.657 10.1953 11.9243L8.02434 13.8342C7.75493 14.0713 7.33329 13.8789 7.33329 13.519V13.1854C7.33329 13.1338 7.33097 13.0827 7.32643 13.0323C7.30656 12.8118 7.24418 12.6035 7.14759 12.416C7.14322 12.4075 7.13879 12.3991 7.13428 12.3907Z"
        fill="#AEAEAE"
      />
    </svg>
  );
};

export default CaptionIcon;
