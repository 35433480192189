import React, { useEffect } from 'react';
import './StatusPageStyles.css';
import { getServerStatus } from './getServerStatus';

const StatusPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [elasticStatus, setElasticStatus] = React.useState(false);
  const [mysqlStatus, setMysqlStatus] = React.useState(false);
  const [workerStatus, setWorkerStatus] = React.useState(false);
  const [imageQueueStatus, setImageQueueStatus] = React.useState(false);
  const [databaseSyncStatus, setDatabaseSyncStatus] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    getServerStatus()
      .then((res) => {
        const status = res.data;

        if (status.mainDatabase?.status === 'online') {
          setMysqlStatus(true);
        }

        if (status.searchDatabase?.status === 'online') {
          setElasticStatus(true);
        }

        if (status.worker?.status === 'online') {
          setWorkerStatus(true);
        }

        if (status.imageQueue?.status === 'online') {
          setImageQueueStatus(true);
        }

        if (status.dataSync?.status === 'online') {
          setDatabaseSyncStatus(true);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const onlineElement = (
    <span className="status-page__status status-page__status--online">
      Online
    </span>
  );

  const offlineElement = (
    <span className="status-page__status status-page__status--offline">
      Offline
    </span>
  );

  return (
    <div className="status-page">
      <h2>Current status</h2>

      {loading ? (
        <div className="status-page__loading">Processing...</div>
      ) : (
        <div className="status-page__wrapper">
          <div className="status-page__item">
            <div className="status-page__item-title">Dashboard</div>
            <div className="status-page__item-status">
              {onlineElement}
            </div>
          </div>

          <div className="status-page__item">
            <div className="status-page__item-title">API</div>
            <div className="status-page__item-status">
              {mysqlStatus || elasticStatus ? onlineElement : offlineElement}
            </div>
          </div>

          <div className="status-page__item">
            <div className="status-page__item-title">Main Database</div>
            <div className="status-page__item-status">
              {mysqlStatus ? onlineElement : offlineElement}
            </div>
          </div>

          <div className="status-page__item">
            <div className="status-page__item-title">Search Database</div>
            <div className="status-page__item-status">
              {elasticStatus ? onlineElement : offlineElement}
            </div>
          </div>

          <div className="status-page__item">
            <div className="status-page__item-title">Worker</div>
            <div className="status-page__item-status">
              {workerStatus ? onlineElement : offlineElement}
            </div>
          </div>

          <div className="status-page__item">
            <div className="status-page__item-title">Image Queue</div>
            <div className="status-page__item-status">
              {imageQueueStatus ? onlineElement : offlineElement}
            </div>
          </div>

          <div className="status-page__item">
            <div className="status-page__item-title">Database Sync</div>
            <div className="status-page__item-status">
              {databaseSyncStatus ? onlineElement : offlineElement}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusPage;
