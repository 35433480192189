import { gql } from '@apollo/client';

export const SEND_WITHDRAW_CONTACT_REQUEST = gql`
  mutation sendOrWithdrawContactRequest(
    $email: String
    $phone: String 
    $isSending: Boolean
    $isWithdrawing: Boolean
    $country_code: String
    $login_via: Int
    $country_iso: String
    $name: String
  ) {
    sendOrWithdrawContactRequest(
      email: $email
      phone: $phone
      isSending: $isSending
      isWithdrawing: $isWithdrawing
      login_via: $login_via
      country_code: $country_code
      country_iso: $country_iso
      name: $name
    ) {
      status
      messages {
        error
        success
      }
    }
  }
`;
