import React from 'react';
import './styles.css';

interface Proptypes {
  avatar?: string;
  creator_initials?: string;
  userName?: string;
  className?: string;
}

const Avatar = ({
  avatar,
  creator_initials,
  userName,
  className,
}: Proptypes) => {
  if (avatar)
    return (
      <img
        className={`avatar__user-avatar ${className ? className : ''}`}
        src={avatar}
        alt={userName || 'profile-image'}
      />
    );
  else if (creator_initials) {
    return (
      <span
        data-initials={creator_initials}
        className={`avatar__user-initials ${className ? className : ''}`}
      ></span>
    );
  }
  return (
    <img
      className={`avatar__user-avatar ${className ? className : ''}`}
      src="/dashboard/img/default-avatar.jpg"
      alt={userName || 'profile-image'}
    />
  );
};

export default Avatar;
