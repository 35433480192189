import React from 'react';
import './styles.css';

interface Proptypes {
  count: number;
}

const NotificationCount = (prop: Proptypes) => {
  const { count } = prop;
  return <span className="notification-count">{count}</span>;
};

export default NotificationCount