import React from 'react';
import * as config from '../../../settings.json';
import '../OnboardingStyles.css';
import { NewClose } from "../../Global/icons";

interface Proptypes {
  hasScraps?: boolean;
  reloadScraps?: () => void;
}

const OnboardingInstruction = ({ hasScraps, reloadScraps }: Proptypes) => {
  const [hideOnboarding, setHideOnboarding] = React.useState(false);
  let keySymbol = '';
  let keyName = '';

  // platform is deprecated, userAgentData is the new way to get the platform
  // @ts-ignore
  const platform = navigator.platform || navigator.userAgentData.platform || '';
  if (platform.indexOf('Win') !== -1 || platform.indexOf('Linux') !== -1) {
    keySymbol = 'Ctrl';
    keyName = 'Control';
  } else if (platform.indexOf('Mac') !== -1) {
    keySymbol = '⌘';
    keyName = 'Command';
  }

  let extensionUrl = "#";
  // use try and catch to avoid errors when the settings.json file doesn't have the key
  try {
    extensionUrl = config.chromeExtensionStoreUrl;
  } catch (e) {
    // nothing ot do here
  }

  // on load check if the localstorage has the key to show the onboarding
  React.useEffect(() => {
    if (localStorage.getItem('hideOnboarding')) {
      setHideOnboarding(true);
    }
  }, []);

  function closeOnboarding() {
    setHideOnboarding(false);
    // set the localstorage to not show the onboarding again
    localStorage.setItem('hideOnboarding', 'true');
    reloadScraps && reloadScraps();
  }

  if (hideOnboarding) {
    return null;
  }

  return (
    <div>
      <button
        className="new-popup__header-button hoverState__with-white-text onboard__close-button"
        onClick={() => {
          closeOnboarding();
        }}
      >
        <NewClose className="new-popup__header-button__icon " />
      </button>
      <div
        className={`scrap-card onboard-info ${hasScraps ? 'has-scraps' : ''}`}
      >
        {/*<div className="onboard-info__message">Use</div>*/}
        <div className="onboard-info__trigger">
          <span className="onboard-key__modifier">
            <span className="onboard-key__modifier--symbol">{keySymbol}</span>
            <span className="onboard-key__modifier--name">{keyName}</span>
          </span>
          <span className="onboard-key__add">+</span>
          <span className="onboard-key__character">V</span>
        </div>
        <div className="onboard-info__action">Paste to create a Scrap</div>

        {/*  show action to download extension */}
        {hasScraps && (
          <div className="onboard-info__download">
            <div className="onboard-info__message">Use</div>
            <div className="onboard-info__trigger">
              <a
                target="_blank"
                href={extensionUrl}
                className="ui-botton ui-button--primary onboard-info__download-button" rel="noreferrer"
              >
                Chrome Extension
              </a>
            </div>
            <div className="onboarding-info__action">
              To add Scraps while browsing the web
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingInstruction;