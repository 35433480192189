const DEFAULT = '';

const appliedFilter = (state =  DEFAULT, action) => {

  switch(action.type) {
    case 'LOAD_APPLIED_FILTERS' :
      
      return [...action.appliedfilterData];

    default :
      return state;
  }
};

export default appliedFilter;

  
      
  