import React from 'react';

interface Proptypes {
  onClick: () => void;
  icon?: string | { (): React.ReactElement };
  children: React.ReactChild | React.ReactChildren;
}
const IndependentOption = ({ onClick, icon, children }: Proptypes) => {
  const renderIcon = () => {
    if (icon) {
      if (typeof icon === 'string')
        return (
          <span className="card-settings__list-item-icon">
            <img src={icon} />
          </span>
        );
      else return icon();  
    }
  };
  return (
    <div className="card-settings__list-item" onClick={onClick}>
      {renderIcon()}
      <span className="card-settings__list-item-label">{children}</span>
    </div>
  );
};

export default IndependentOption;
