import React from 'react';
import { Collaborator, CollectionIcon, NewGlobeIcon } from '../icons';
import './styles.css';
import NewGlobe from '../icons/NewGlobe';
import { GroupType } from '../../../containers/GroupShareContainer/types';

interface Proptypes {
  state?: number;
  members: GroupType['members'];
  className?: string;
  showAllMembers?: (val: GroupType['members']) => void;
  groupName?: string;
  type?:string
}

const GroupShow = ({ members, className, showAllMembers, groupName,type, state }: Proptypes) => {
  const renderCollectionIcon = () => {
    if(state == 3) return <NewGlobeIcon className="group-show__member-icon" />;
    else if(members?.length) return <Collaborator className="group-show__member-icon" />
    else return <CollectionIcon className="group-show__member-icon" />
  }
  return (
    <div className="group-show">
      {type ==="collection" ? renderCollectionIcon() : <Collaborator className="group-show__member-icon" /> } 
      <span className="group-show__member-name">
        {groupName ? groupName : members?.map((obj) =>  obj['display_name'] ?? obj['user_name']).join(', ')}
      </span>

      {members?.length > 0 && <span
        className="group-show__member-count"
        onClick={(e) => {
          e.stopPropagation();
          showAllMembers && showAllMembers(members);
        }}
      >
        {members?.length + 1}
      </span>}
    </div>
  );
};

export default GroupShow;
