import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const NewGlobe = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M13.8675 13.4467C13.653 12.7867 13.0343 12.3 12.3 12.3H11.475V9.825C11.475 9.6062 11.3881 9.39635 11.2334 9.24164C11.0786 9.08692 10.8688 9 10.65 9H5.7V7.35H7.35C7.5688 7.35 7.77865 7.26308 7.93336 7.10836C8.08808 6.95365 8.175 6.7438 8.175 6.525V4.875H9.825C10.2626 4.875 10.6823 4.70116 10.9917 4.39173C11.3012 4.08229 11.475 3.66261 11.475 3.225V2.88675C13.8923 3.86025 15.6 6.228 15.6 9C15.6 10.716 14.94 12.2752 13.8675 13.4467ZM8.175 15.5422C4.91625 15.138 2.4 12.366 2.4 9C2.4 8.4885 2.466 7.9935 2.57325 7.52325L6.525 11.475V12.3C6.525 12.7376 6.69884 13.1573 7.00827 13.4667C7.31771 13.7762 7.73739 13.95 8.175 13.95M9 0.75C7.91659 0.75 6.8438 0.963393 5.84286 1.37799C4.84193 1.7926 3.93245 2.40029 3.16637 3.16637C1.61919 4.71354 0.75 6.81196 0.75 9C0.75 11.188 1.61919 13.2865 3.16637 14.8336C3.93245 15.5997 4.84193 16.2074 5.84286 16.622C6.8438 17.0366 7.91659 17.25 9 17.25C11.188 17.25 13.2865 16.3808 14.8336 14.8336C16.3808 13.2865 17.25 11.188 17.25 9C17.25 7.91659 17.0366 6.8438 16.622 5.84286C16.2074 4.84193 15.5997 3.93245 14.8336 3.16637C14.0675 2.40029 13.1581 1.7926 12.1571 1.37799C11.1562 0.963393 10.0834 0.75 9 0.75Z"
        fill="#969696"
      />
    </svg>
  );
};

export default NewGlobe;
