import { supabaseStoreType } from '../store/supabase';

export function setSupabaseClient(client: supabaseStoreType['client']) {
  return {
    type: 'SET_SUPABASE_CLIENT',
    payload: {
      client,
    },
  };
}
export function setSupabaseMessage(message) {
  return {
    type: 'SET_SUPABASE_MESSAGE',
    payload: {
      message,
    },
  };
}
export function setSupabaseInvitationsCountSetter(
  invitationsCountSetter: supabaseStoreType['invitationsCountSetter']
) {
  return {
    type: 'SET_SUPABASE_INVITATIONS_COUNT_SETTER',
    payload: { invitationsCountSetter },
  };
}
