import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const ZoomOut = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 9.23164 14.4131 10.8262 13.4275 12.0957C13.5286 12.1435 13.6234 12.2092 13.7071 12.2929L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L12.2929 13.7071C12.2092 13.6234 12.1435 13.5286 12.0957 13.4275C10.8262 14.4131 9.23164 15 7.5 15ZM7.5 2C4.46243 2 2 4.46243 2 7.5C2 10.5376 4.46243 13 7.5 13C10.5376 13 13 10.5376 13 7.5C13 4.46243 10.5376 2 7.5 2ZM11 7.5C11 8.05228 10.5523 8.5 10 8.5H5C4.44772 8.5 4 8.05228 4 7.5C4 6.94772 4.44772 6.5 5 6.5H10C10.5523 6.5 11 6.94772 11 7.5Z"
        fill="black"
      />
    </svg>
  );
};

export default ZoomOut;
