// This function returns the file type by looking at type of file object
export default function returnFileType(type: string) {
  let fileTypeValue = 1;
  if (
    type ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
    type === 'application/vnd.ms-powerpoint'
  ) {
    fileTypeValue = 2;
  } else if (
    type === 'application/vnd.ms-excel' ||
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    fileTypeValue = 3;
  } else if (
    type === 'application/msword' ||
    type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    fileTypeValue = 4;
  } else if(
    type === 'text/plain'
  ) fileTypeValue = 5;
  return fileTypeValue;
}
