import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '../../../redux/action/themeAction';
import { ReduxStateType } from '../../../redux/store';
import './ThemeToggleStyles.css';
import {
  ThemeDarkIcon,
  ThemeLightIcon,
  ThemeSystemIcon,
} from '../../Global/icons';
import { handleThemeChange } from '../../../helpers';
import { STORAGE_KEYS } from '../../../helpers/config';

export const typesValue = {
  auto: null,
  light: 1,
  dark: 0,
};

export const setTheme = (theme: string) => {
  if (!theme) return;
  // use the value to set the value of theme in html tag with data-theme
  if (typeof window === 'object') {
    document.querySelector('html')?.setAttribute('data-theme', theme);
  }
};

const ThemeToggle = () => {
  const DISPATCH = useDispatch();
  const { theme } = useSelector((state: ReduxStateType) => state?.theme);

  const themeHandler = (type: string) => {
    if (!type) return;

    window.localStorage.setItem(`${STORAGE_KEYS.THEME}`, type);
    handleThemeChange(type);
    setTheme(type);
    DISPATCH(toggleTheme({ type, payload: typesValue[type] }));
  };

  return (
    <div className="theme-toggle">
      {/* <span
        onClick={() => themeHandler('auto')}
        className={`${theme === null && 'highlight'}`}
      >
        Auto
      </span> */}
      <span className="theme-toggle__title">Theme</span>
      <ul className="theme-toggle__list">
        <li
          className={`theme-toggle__list-item${
            theme !== 0 && theme !== 1 ? ' theme-toggle__list-item--active' : ''
          }`}
          onClick={() => themeHandler('auto')}
        >
          <ThemeSystemIcon className={`theme-toggle__icon`} />
          <span className="theme-toggle__item-text">System</span>
        </li>
        <li
          className={`theme-toggle__list-item${
            theme === 1 ? ' theme-toggle__list-item--active' : ''
          }`}
          onClick={() => themeHandler('light')}
        >
          <ThemeLightIcon className={`theme-toggle__icon`} />
          <span className="theme-toggle__item-text">Light</span>
        </li>
        <li
          className={`theme-toggle__list-item${
            theme === 0 ? ' theme-toggle__list-item--active' : ''
          }`}
          onClick={() => themeHandler('dark')}
        >
          <ThemeDarkIcon className={`theme-toggle__icon`} />
          <span className="theme-toggle__item-text">Dark</span>
        </li>
      </ul>
    </div>
  );
};

export default ThemeToggle;
