import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface propTypes {
  numberOfItems?: number;
}

const LoadingFilter = ({ numberOfItems }: propTypes) => {
  const placeholderArray = new Array(numberOfItems || 3).fill('placeholder');
  return (
    <>
      {placeholderArray.map((v) => {
        return (
          <>
            <div className="topic-navigation__header">
              <h4 className="topic-navigation__heading">
                <Skeleton style={{ width: 120 }} />
              </h4>
            </div>

            <ul className={`topic-navigation__topics custom-scroll__body`}>
              <li className="topic-navigation__item topic-navigation__filter">
                <label>
                  <Skeleton />
                </label>
                <span className="topic-navigation__count">
                  <Skeleton />
                </span>
              </li>
              <li className="topic-navigation__item topic-navigation__filter">
                <label>
                  <Skeleton />
                </label>
                <span className="topic-navigation__count">
                  <Skeleton />
                </span>
              </li>
            </ul>
          </>
        );
      })}
    </>
  );
};

export default LoadingFilter;
