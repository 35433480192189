import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['id', 'ID'],
  ['scrap_id', 'Int!'],
  ['position', 'String'],
  ['highlighted_text', 'String'],
  ['comment', 'String'],
  ['hash', 'String'],
]);
export default function UPSERT_SCRAP_ANNOTATION(responseString = '') {
  return gql`
    mutation UpsertScrapAnnotation(${parametersTypeString}) {
      upsertScrapAnnotation(${parametersAssignString}) {
        ${defaultResponse}
        ${responseString ? `data { ${responseString} }` : ''}
      }
    }
  `;
}
