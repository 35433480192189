import React, { useState, useCallback, useRef } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Icon from '../Global/Icon';
import ImageCropper from '../Global/ImageCropper';
import './styles.css';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from "../Global/InvisibleRecaptcha";
import ValidateHuman from '../../helpers/validators/isHuman';
import { convertHEICImage, isMobileDevice } from '../../helpers';
import config from '../../helpers/config';

const MUTATION = gql`
  mutation($file: Upload!, $is_profile_picture: Boolean = false) {
    upload(file: $file, is_profile_picture: $is_profile_picture)
  }
`;

interface Proptypes {
  callback: (url: string) => void;
  aspect?: number;
  onUploadStart?: Function;
  // eslint-disable-next-line no-undef
  renderDropBox?: (rootProps, inputProps) => JSX.Element;
  isCircular?: boolean;
  isProfileImage?: boolean
}
const UploadFileToCdn = (props: Proptypes) => {
  const { callback, renderDropBox, aspect, onUploadStart, isCircular, isProfileImage = false } = props;
  const [open, setOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const cropperRef = useRef(null);
  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  const [mutate] = useMutation(MUTATION);
  const onCrop = async (blob: Blob) => {

    const isHuman= await ValidateHuman(reCaptchaRef.current);
    if(!isHuman) return;
    setOpen(false);
    mutate({ variables: { file: blob, is_profile_picture: isProfileImage } }).then(({ data }) => {
      callback(data.upload);
    });
  };
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    let file = acceptedFiles[0];
    if(file.type === 'image/heic') {
      file = await convertHEICImage(file)
    }
    // in blob format
    const reader = new FileReader();

    reader.onload = () => {
      const binaryStr = reader.result;
      setImgUrl(binaryStr);
      setOpen(true);
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: config.supported_images_accept_list });

  return (
    <>
      {renderDropBox ? (
        renderDropBox(getRootProps, getInputProps)
      ) : (
        <div {...getRootProps()} className="drop-image">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <span className="upload-label">
              <Icon iconClass="upload" size="big" /> Upload Header Image
            </span>
          )}
        </div>
      )}
      <InvisibleRecaptcha inputRef={reCaptchaRef}/>
      <Popup
        closeOnEscape={false}
        modal
        open={open}
        onClose={() => setOpen(false)}
      >
        {' '}
        <div className="modal-window__header">
          <h2 className="modal-window__heading">Crop Image</h2>

          <span
            className="modal-window__close"
            onClick={(e) => {
              setOpen(false);
            }}
          >
            <Icon iconClass="x-circle" size="big" />
          </span>
        </div>
        <div className="upload-file__cropper">
          <ImageCropper
            imageUrl={imgUrl}
            onCrop={onCrop}
            ref={cropperRef}
            aspectRatio={aspect}
            circularCrop={isCircular}
            movable={isMobileDevice()?false:true}
            zoomOnTouch={isMobileDevice()?false:true} 
          />

          <div className="upload__action-wrapper">
            <button className="button button__link" {...getRootProps()}>
              Upload Another
            </button>

            <button
              className="button button__primary"
              onClick={(e) => {
                const el = e.target as HTMLButtonElement;
                el.disabled = true;
                onUploadStart && onUploadStart();
                cropperRef?.current?.onCropClick();
              }}
            >
              Save crop
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default UploadFileToCdn;
