import React from 'react';

interface Proptypes extends React.DOMAttributes<SVGSVGElement> {
  className?: string;
}

const TrashOutline = (props: Proptypes) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_10652_215371)">
        <path
          d="M3.33325 6.08301H16.6666"
          stroke="#71A7C5"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.33325 9.41699V14.417"
          stroke="#71A7C5"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.6667 9.41699V14.417"
          stroke="#71A7C5"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16675 6.08301L5.00008 16.083C5.00008 16.525 5.17568 16.949 5.48824 17.2615C5.8008 17.5741 6.22472 17.7497 6.66675 17.7497H13.3334C13.7754 17.7497 14.1994 17.5741 14.5119 17.2615C14.8245 16.949 15.0001 16.525 15.0001 16.083L15.8334 6.08301"
          stroke="#71A7C5"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 6.08333V3.58333C7.5 3.36232 7.5878 3.15036 7.74408 2.99408C7.90036 2.8378 8.11232 2.75 8.33333 2.75H11.6667C11.8877 2.75 12.0996 2.8378 12.2559 2.99408C12.4122 3.15036 12.5 3.36232 12.5 3.58333V6.08333"
          stroke="#71A7C5"
          strokeWidth="1.8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10652_215371">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashOutline;
