export default function returnPrice(price: number) {
  switch (typeof price) {
    case 'number':
    case 'bigint':
    case 'string':
      return +price;

    default:
      return NaN;
  }
}
