import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  type?: 'twisted-arrow' | 'connected-dots';
}

const Share = ({ className, onClick, type }: Proptypes) => {
  if (type === 'connected-dots') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        className={className || ''}
        onClick={onClick}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18 10.5C17.2 10.5 16.4 10.3 15.8 9.8L10.2 12.5L15.6 15.3C16.3 14.8 17.1 14.5 18 14.5C20.2 14.5 22 16.3 22 18.5C22 20.7 20.2 22.5 18 22.5C15.8 22.5 14 20.7 14 18.5C14 17.9 14.1 17.4 14.3 16.9L9.5 14.4C8.8 15.6 7.5 16.5 6 16.5C3.8 16.5 2 14.7 2 12.5C2 10.3 3.8 8.5 6 8.5C7.5 8.5 8.9 9.4 9.5 10.6L14.4 8.2C14.1 7.7 14 7.1 14 6.5C14 4.3 15.8 2.5 18 2.5C20.2 2.5 22 4.3 22 6.5C22 8.7 20.2 10.5 18 10.5Z"
          fill="#274A5E"
        />
      </svg>
    );
  }
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        d="M12.2311 16.4004C12.0311 16.4004 11.8311 16.4004 11.6311 16.3004C11.0311 16.1004 10.7311 15.5004 10.7311 14.9004V12.2004C9.33114 12.1004 7.93114 12.3004 6.73114 12.9004C5.43114 13.4004 4.23114 14.3004 2.93114 15.5004C2.43114 16.0004 1.63114 16.1004 1.03114 15.7004C0.43114 15.3004 0.23114 14.5004 0.53114 13.8004C1.33114 11.8004 2.43114 10.2004 3.73114 8.80039C4.83114 7.60039 6.23114 6.60039 7.73114 5.80039C8.63114 5.30039 9.63114 4.90039 10.7311 4.60039V1.90039C10.7311 1.30039 11.1311 0.700391 11.6311 0.500391C12.2311 0.300391 12.8311 0.400391 13.2311 0.800391L20.0311 7.60039V7.70039C20.3311 8.30039 20.2311 9.00039 19.7311 9.50039L13.3311 16.0004C13.0311 16.3004 12.6311 16.4004 12.2311 16.4004ZM10.0311 10.2004C10.4311 10.2004 10.8311 10.2004 11.2311 10.3004C11.9311 10.4004 12.5311 11.0004 12.5311 11.8004V14.0004L18.0311 8.30039L12.4311 2.70039V4.80039C12.4311 5.50039 12.0311 6.10039 11.3311 6.30039C10.3311 6.60039 9.33114 7.00039 8.43114 7.40039C7.23114 8.00039 6.03114 8.90039 5.03114 10.0004C4.23114 10.8004 3.53114 11.8004 2.93114 12.8004C3.93114 12.1004 4.83114 11.5004 5.83114 11.1004C7.23114 10.5004 8.63114 10.2004 10.0311 10.2004Z"
        fill="#696969"
      />
    </svg>
  );
};

export default Share;
