import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const CopyLink = ({ className }: Proptypes) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.833 3.66668C12.7284 2.56211 10.9376 2.56211 9.833 3.66668L8.49966 5.00002C8.13147 5.36821 7.53452 5.36821 7.16633 5.00002C6.79813 4.63183 6.79813 4.03487 7.16633 3.66668L8.49966 2.33334C10.3406 0.492389 13.3254 0.492389 15.1664 2.33334C17.0073 4.1743 17.0073 7.15908 15.1664 9.00003L13.833 10.3334C13.4648 10.7016 12.8679 10.7016 12.4997 10.3334C12.1315 9.96518 12.1315 9.36823 12.4997 9.00003L13.833 7.6667C14.9376 6.56212 14.9376 4.77126 13.833 3.66668ZM3.16631 14.3334C2.06174 13.2288 2.06174 11.4379 3.16631 10.3334L4.49965 9.00003C4.86784 8.63184 4.86784 8.03489 4.49965 7.6667C4.13146 7.29851 3.5345 7.29851 3.16631 7.6667L1.83297 9.00003C-0.00798116 10.841 -0.00798133 13.8258 1.83297 15.6667C3.67393 17.5077 6.65871 17.5077 8.49966 15.6667L9.833 14.3334C10.2012 13.9652 10.2012 13.3682 9.833 13C9.46481 12.6319 8.86785 12.6319 8.49966 13L7.16633 14.3334C6.06175 15.438 4.27088 15.438 3.16631 14.3334ZM11.833 7.00003C12.2012 6.63184 12.2012 6.03488 11.833 5.66669C11.4648 5.2985 10.8679 5.2985 10.4997 5.66669L5.16632 11C4.79813 11.3682 4.79813 11.9652 5.16632 12.3334C5.53451 12.7016 6.13147 12.7016 6.49966 12.3334L11.833 7.00003Z"
        fill="#1A404C"
      />
    </svg>
  );
};

export default CopyLink;
