import React from 'react';
import { BookmarkIcon, CollectionIcon, MyScrapsIcon } from '../Global/icons';
import './MobileNavigationStyles.css';
import MobileNavigationButton from './Components/MobileNavigationButton';
import Share from '../Global/icons/Share';
import CreateScrapIconToggler from '../CreateScrap/components/CreateScrapIconToggler';

/**
 * TODO: things to consider
 * Show the list of navigation items.
 * Hide with CSS and only show in mobile.
 * Highlight the icon when on the page.
 * Ability to add at the bottom will be good. Manage the z-index in this case.
 */
const MobileNavigation = () => {
  let webView = null;
  if (typeof window !== 'undefined') {
    // @ts-ignore
    webView = !!window?.ReactNativeWebView;
    if (webView) {
      // skip the onboarding on mobile
      try {
        localStorage.setItem('tourEnd', 'true');
      } catch (e) {
        // do nothing
      }
    }
  }

  return (
    <div
      className="mobile-nav__wrapper"
      style={{
        paddingBottom: webView ? '1.6rem' : '1.6rem',
        height: webView ? '84px' : '84px',
      }}
    >
      <MobileNavigationButton
        icon={<MyScrapsIcon />}
        label="My Scraps"
        link="/"
      />

      <MobileNavigationButton
        icon={<CollectionIcon />}
        label="Collections"
        customClass="mobile-collection-icon"
        link="/collections"
      />

      <div className="mobile-nav__button">
        <CreateScrapIconToggler />
      </div>

      <MobileNavigationButton
        icon={<BookmarkIcon isFilled />}
        label="Saved"
        link="/saved"
      />

      <MobileNavigationButton
        icon={<Share type="connected-dots" />}
        label="Shares"
        link="/shares"
      />
    </div>
  );
};

export default MobileNavigation;
