import { ParsedQs } from 'qs';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { SearchFilterType } from '../../containers/GlobalSearchBarWrapper';
import { SearchHistoryItemType } from '../../types/globalSearchDataType';
import './pills.css';
type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pillsList?: any;
  activePill?: string | string[] | ParsedQs | ParsedQs[];
  activeLink?:string | string[] | ParsedQs | ParsedQs[];
  hoveredPill?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickFun?: (val: any) => void;
  //used in nav or not, if used then true else false
  nav: boolean;
  filterTypes?: {};
  loadingHistories?:boolean
};

const Pills = (props: Props) => {
  
  const { pillsList, hoveredPill, activePill, onClickFun, nav,filterTypes,activeLink,loadingHistories } = props;
  const renderPillsCondition=(item)=>{
    if(!nav)return true
    if(nav){ 
      if(activeLink==="personal"&&item.value==="scrappers")return false
      if(filterTypes[item.value]>0)return true
      return false
    }
  }
  
  return (
    <div className={`pill-div ${nav ? 'pill-div__nav' : ''}`}>
      {!loadingHistories?pillsList.map((item, index) => (

        renderPillsCondition(item)?<div
          className={`pill-text ${
            hoveredPill === index? ' hovered active-pill': ''
          }`}
          key={item?.id || item.value}
          onMouseDown={() => onClickFun(item)}
        >
          <label>
            {item?.search_term || item?.label}{' '}
          </label>
        </div>:null
      )): null /*<Skeleton style={{borderRadius:'50px',width:'80px'}} className='pill-text' count={3}/>*/}
    </div>)
}
export default Pills;
