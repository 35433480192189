import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const Download = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.23896 8.67516C1.91646 8.3468 1.92122 7.81918 2.24957 7.49669C2.57793 7.1742 3.10554 7.17895 3.42804 7.50731L6.16683 10.2959L6.16683 1.50033C6.16683 1.04009 6.53993 0.666992 7.00016 0.666992C7.4604 0.666992 7.8335 1.04009 7.8335 1.50033V10.2959L10.5723 7.50731C10.8948 7.17895 11.4224 7.1742 11.7508 7.49669C12.0791 7.81918 12.0839 8.3468 11.7614 8.67516L7.5947 12.9176C7.43801 13.0771 7.22378 13.167 7.00016 13.167C6.77655 13.167 6.56231 13.0771 6.40562 12.9176L2.23896 8.67516ZM1.16683 15.667C0.706592 15.667 0.333496 16.0401 0.333496 16.5003C0.333496 16.9606 0.706592 17.3337 1.16683 17.3337H12.8335C13.2937 17.3337 13.6668 16.9606 13.6668 16.5003C13.6668 16.0401 13.2937 15.667 12.8335 15.667H1.16683Z"
        fill="#838383"
      />
    </svg>
  );
};

export default Download;
