import React, { useState } from 'react';
import NewPopup from '../components/Global/NewPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from '@apollo/client';
import { DELETE_PANEL } from '../components/data/deletePanel';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../redux/store';
import { RequestStatusObjectType } from '../types/requestStatusType';
import { setDeleteScrapPopup, setReloadSideNavFilters } from '../redux/action/utils';
import { waitBeforeExecute } from '../helpers';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import { DeleteScrap } from './data/deleteScrap';
import removeFromRAG from "../components/ScrapCard/uiComponents/helpers/removeFromRAG";


const faSpinnerIcon = faSpinner as IconProp;
const DeleteScrapPopupContainer = () => {
  const dispatch = useDispatch();
  const deleteScrapData = useSelector(
    (state: ReduxStateType) => state.utils.deleteScrapPopup
  );
  const { scrap, onComplete } = deleteScrapData || {};
  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [deletePanel] = useMutation(DELETE_PANEL(scrap?.type || 'page'));
  const [deleteScrap]=useMutation(DeleteScrap)
  async function deleteHandler(close: () => void) {
    setStatus({ status: 'processing' });
    try {
      await deleteScrap({
        variables: {
          scrap_id: scrap.id,
        },
      });
      waitBeforeExecute(() => {
        setStatus({ status: 'success' });
        onComplete && onComplete(scrap);
      }, 2000);

      // delete data from rag
      await removeFromRAG(scrap.id);
    } catch (error) {
      alert('Some went wrong!');
      setStatus({ status: 'error', message: parseTryCatchError(error) });
    }
    dispatch(setReloadSideNavFilters(true));
  }
  function render() {
    return (
      <p className="delete-scrap__message">
        Are you sure that you want to delete this Scrap?
      </p>
    );
  }
  return (
    <NewPopup
      size='Small'
      unclosable={status.status === 'processing'}
      className="delete-scrap"
      header={{

        heading: 'Delete Scrap',
      }}
      controlled={{
        show: !!deleteScrapData,
        setShow: (val) => {
          dispatch(setDeleteScrapPopup(null));
        },
      }}
      footer={{
        onSubmit: (close) => deleteHandler(close),
        submitLabel: 'Delete',
        hideSubmit: status.status === 'processing',
        hideCancel: status.status === 'processing',
        spinnerConfig: {
          show: status.status === 'processing',
          label: 'Deleting'
        }
      }}
    >
      {render}
    </NewPopup>
  );
};

export default DeleteScrapPopupContainer;