import React from 'react';

interface Proptypes {
  className?: string;
  onClick: (e) => void;
}

const Hamburger = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        d="M1.25 0C0.559616 0 0 0.447754 0 1C0 1.55225 0.559616 2 1.25 2H18.75C19.4404 2 20 1.55225 20 1C20 0.447754 19.4404 0 18.75 0H1.25Z"
        fill="#9F9F9F"
      />
      <path
        d="M1.25 6C0.559616 6 0 6.44775 0 7C0 7.55225 0.559616 8 1.25 8H18.75C19.4404 8 20 7.55225 20 7C20 6.44775 19.4404 6 18.75 6H1.25Z"
        fill="#9F9F9F"
      />
      <path
        d="M1.25 12C0.559616 12 0 12.4478 0 13C0 13.5522 0.559616 14 1.25 14H18.75C19.4404 14 20 13.5522 20 13C20 12.4478 19.4404 12 18.75 12H1.25Z"
        fill="#9F9F9F"
      />
      <path
        d="M0 19C0 18.4478 0.559616 18 1.25 18H18.75C19.4404 18 20 18.4478 20 19C20 19.5522 19.4404 20 18.75 20H1.25C0.559616 20 0 19.5522 0 19Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export default Hamburger;
