import React, { useEffect, useMemo, useState } from 'react';

// REDUX IMPORTS
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../redux/store';
import { setGroupDetailsPopup } from '../../redux/action/popupsAction';
import { setCollectionMessenger, setGroupMessenger } from '../../redux/action/messengersAction';
import { setReloadSideNavCollections, setReloadSideNavShares } from '../../redux/action/utils';

// APOLLO IMPORTS
import { useMutation, useQuery } from '@apollo/client';
import UPDATE_GROUP from '../data/updateGroup';
import REMOVE_GROUP_MEMBER from '../data/removeGroupMember';
import ADD_GROUP_MEMBERS from '../data/addGroupMembers';
import CHECK_IS_GROUP_WITH_SAME_MEMBERS_EXIST from '../data/CheckIsGroupWithSameMembersExist';
import GET_GROUP from '../data/getGroup';
import GET_RELEVANT_CONTACT_FOR_UPDATE_GROUP from '../data/getRelevantContactForUpdateGroup';
import { ADD_COLLECTION_MEMERS, REMOVE_COLLECTION_MEMBER } from '../data/collaboration';

// COMPONENTS IMPORTS
import GroupDetailsPopupRenderer from './GroupDetailsPopupRenderer';

// TYPESCRIPT TYPES IMPORTS
import { InputFieldType } from '../../types/inputFieldType';
import { ContactWithGroupFlag } from './types';
import { GroupType } from '../GroupShareContainer/types';

// HELPERS IMPORTS
import { getResponseMessages } from '../../helpers';
import { collectionType } from '../../types/collections';
import GET_RELEVANT_CONTACT_FOR_UPDATE_COLLECTION from '../data/getRelevantContactForUpdateCollection';


const numberOfRecords = 10;

export default function GroupDetailsPopupContainer() {
  // REDUX HOOKS FOR GETTING STATE AND DISPATCHING ACTIONS
  const dispatch = useDispatch();
  const groupDetailsPopup = useSelector(
    (state: ReduxStateType) => state.popups.groupDetailsPopup
  );
  const { data: popupData, type, isReadOnly } = groupDetailsPopup ?? {};

  const dataType = ['groupId', 'groupData'].includes(type) ? 'group' : 'collection';

  // STATES FOR KEEPING PARAMETERS IN USEQUERY
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const [searchGotFocused, setSearchGotFocused] = useState(false);
  const [currentMode, setCurrentMode] = useState<'all-groups-and-contacts' | 'current-members'>('current-members');  

  // STATE FOR STORING GROUP DATA WHICH IS EITHER COMING FROM REDUX OR FETCHED FROM APOLLO USEQUERY HOOK
  const [groupData, setGroupData] = useState<GroupType | collectionType>();

  // // VARIABLE TO DETERMINE IF WE HAVE TO SHOW MEMBERS OF GROUP ONLY OR ALL CONTACTS
  // const currentlyDisplayedList = search || searchGotFocused
  //   ? 'all-groups-and-contacts'
  //   : 'current-members';

  // GET GROUP DATA API
  const { data, loading, client } = useQuery(GET_GROUP(), {
    variables: {
      group_id: typeof popupData === 'string' ? popupData : popupData?.id,
    },
    skip: ['groupData', 'collectionData'].includes(type) || !groupDetailsPopup,
    // fetchPolicy: 'cache-and-network'
  });
  // GET ALL CONTACTS API WHICH CAN FILTER THE LIST BASED ON WHAT IS SEARCHED BY USER
  const { data: groupContactsData, loading: groupContactsLoading, refetch: refetchGroupContacts } = useQuery(
    GET_RELEVANT_CONTACT_FOR_UPDATE_GROUP(),
    {
      variables: {
        group_id: typeof popupData === 'string' ? popupData : popupData?.id,
        search,
        page,
        first: numberOfRecords,
      },
      skip: !groupDetailsPopup  || dataType === 'collection',
      fetchPolicy: 'cache-and-network'
    }
  );
  const { data: collectionContactsData, loading: collectionContactsLoading, refetch: refetchCollectionContacts } = useQuery(
    GET_RELEVANT_CONTACT_FOR_UPDATE_COLLECTION(),
    {
      variables: {
        collection_id: typeof popupData === 'string' ? popupData : popupData?.id,
        search,
        page,
        first: numberOfRecords,
      },
      skip: !groupDetailsPopup || dataType === 'group',
      fetchPolicy: 'cache-and-network'
    }
  );
  const refetch = dataType === 'collection' ? refetchCollectionContacts : refetchGroupContacts;
  const listData = dataType === 'group' ? groupContactsData?.getRelevantContactForUpdateGroup : collectionContactsData?.getRelevantContactForUpdateCollection;
  const loadingList = dataType === 'group' ? groupContactsLoading : collectionContactsLoading

  // APOLLO USEMUTATION HOOKS
  const [updateGroupMutation] = useMutation(UPDATE_GROUP());
  const [removeGroupMemberMutation] = useMutation(REMOVE_GROUP_MEMBER());
  const [addGroupMembersMutation] = useMutation(ADD_GROUP_MEMBERS());
  const [removeCollectionMemberMutation] = useMutation(REMOVE_COLLECTION_MEMBER());
  const [addCollectionMembersMutation] = useMutation(ADD_COLLECTION_MEMERS());

  const collectionOrGroupData: GroupType =
    ['groupData', 'collectionData'].includes(type) ? popupData : data?.getGroup?.data;
  const totalFilteredContacts: number =
    listData?.paginatorInfo?.total;
  const contactsList: ContactWithGroupFlag[] =
    listData?.data;
  const masonryKey = useMemo(() => (Math.random() * 10000).toString(), [
    totalFilteredContacts,
    groupData,
  ]);
  

  /**
   * FUNCTION WHICH IS USED TO CHECK IF THE GROUP ALREADY EXISTS OR NOT
   * RETURNS FALSE IF EXISTING GROUP DOES NOT EXIST
   * IF GROUP EXISTS THEN IT RETURNS OBJECT CONTAINING DATA OF EXISTING GROUP AND CURRENT
   * GROUP WITH MESSAGE WHICH IS DISPLAYED IN WARNING POPUP
   */
  const existingGroupCheckHandler = async (
    members: ContactWithGroupFlag[], // MEMBERS WHICH ARE GOING TO BE ADDED OR REMOVED FROM GROUP
    needFor: boolean // TRUE IF USER IS TRYING TO ADD MEMBERS AND FALSE IF MEMBERS ARE BEING REMOVED
  ) => {
    const response = await client.query({
      query: CHECK_IS_GROUP_WITH_SAME_MEMBERS_EXIST(),
      variables: {
        members: members.map((member) => ({
          user_id: member.user_id,
        })),
        original_group_id: groupData.id,
        need_status_for: needFor,
      },
    });
    const { error, isSuccess, success } = getResponseMessages(
      response?.data?.checkIsGroupWithSameMembersExist
    );
    if (!isSuccess) throw new Error(error[0]);

    const data = response?.data?.checkIsGroupWithSameMembersExist?.data;
    const isGroupExists = !!data?.existing?.user_id;
    if (isGroupExists)
      return {
        data,
        message: success[0],
      };
    return false;
  };
  
  /**
   * FUNCTION WHICH WILL BE CALLED WHEN USER CLICKS ON SAVE BUTTON
   * @param nickname OBJECT WHICH CONTAINS THE VALUE OF NICKNAME ENTERED BY USER AND GOTCHANGED WHICH IS TRUE IS NICKNAME IS CHANGED ELSE IT WILL BE FALSE
   * @param newMembers LIST OF NEW MEMBERS WHICH ARE GOING TO BE ADDED
   * @param isMerge BOOLEAN WHICH IS FALSE IF USER HAS JUST CLICKED ON SAVE BUTTON. TRUE IF USER CLICKS ON MERGE BUTTON FROM THE WARNING POPUP
   * @returns IF GROUP ALREADY EXISTS WITH THE NEW MEMBERS, THEN RETURNS OBJECT COMING FROM existingGroupCheckHandler FUNCTION
   */
  const submitHandler = async (
    newMembers: ContactWithGroupFlag[],
    isMerge = false
  ) => {
    if(dataType === 'group') {
      if (!newMembers?.length) return;
  
      if (!isMerge) {
        const isGroupExists = await existingGroupCheckHandler(newMembers, true);
        if (isGroupExists) return isGroupExists;
      }
  
      const addMembersResponse = await addGroupMembersMutation({
        variables: {
          group_id: groupData.id,
          members: newMembers.map((member) => ({
            user_id: member.user_id,
          })),
        },
      });
      const { error, isSuccess } = getResponseMessages(
        addMembersResponse.data.addGroupMembers
      );
      if (isSuccess) {
        updateGroupDataHandler(!isMerge ? 'members-added' : 'groups-merge', newMembers);
        dispatch(setReloadSideNavShares(true));
        setPage(1)
        refetch({ page: 1 })
      } else throw new Error(error[0]);
    } else {
      if(!newMembers.length) return;
      const response = await addCollectionMembersMutation({
        variables: {
          collection_id: groupData.id,
          members: newMembers.map((member) => ({
            user_id: member.user_id,
          })),
        }
      })
      const { isSuccess, error } = getResponseMessages(
        response.data.addCollectionMembers
      );

      if (!isSuccess) throw new Error(error[0]);
      setPage(1)
      refetch({ page: 1 })
      updateGroupDataHandler('members-added', newMembers);
    }
    setCurrentMode('current-members');
  };

  /**
   * FUNCTION RESPONSIBLE FOR REMOVING MEMBERS FROM THE GROUP
   * @param member MEMBER WHICH IS GOING TO BE REMOVED
   * @param isMerge TRUE IF TWO GROUPS ARE GOING TO BE MERGED, ELSE FALSE
   * @returns IF GROUP ALREADY EXISTS WITH THE NEW MEMBERS, THEN RETURNS OBJECT COMING FROM existingGroupCheckHandler FUNCTION
   */
  const removeMemberHandler = async (
    member: ContactWithGroupFlag,
    isMerge = false
  ) => {
    if(dataType === 'group') {
      if (!isMerge) {
        const isGroupExists = await existingGroupCheckHandler([member], false);
        if (isGroupExists) return isGroupExists;
      }
      const removeResponse = await removeGroupMemberMutation({
        variables: {
          group_id: groupData.id,
          member: {
            user_id: member.user_id,
          },
        },
      });

      const { isSuccess, error } = getResponseMessages(
        removeResponse.data.removeGroupMember
      );
  
      if (isSuccess) {
        updateGroupDataHandler(!isMerge ? 'member-removed' : 'groups-merge', [member]);
        dispatch(setReloadSideNavShares(true));
        setPage(1);
        refetch({ page: 1 })
      } else {
        throw new Error(error[0]);
      }
    } else {
      const removeResponse = await removeCollectionMemberMutation({
        variables: {
          collection_id: groupData.id,
          member_id: member.user_id
        },
      });

      const { isSuccess, error } = getResponseMessages(
        removeResponse.data.removeCollectionMember
      );
  
      if (isSuccess) {
        updateGroupDataHandler('member-removed', [member]);
        dispatch(setReloadSideNavCollections(true));
        setPage(1);
        refetch({ page: 1 })
      } else {
        throw new Error(error[0]);
      }
    }
    
  };

  /**
   * FUNCTION RESPONSIBLE FOR CHANGING THE OWNER OF GROUP
   * @param member MEMBER WHICH IS GOING TO BECOME OWNER
   */
  const setOwnerHandler = async (member: ContactWithGroupFlag) => {
    const res = await updateGroupMutation({
      variables: {
        group_id: groupData.id,
        change_owner: true,
        owner_id: member.user_id,
      },
    });
    const { error, isSuccess } = getResponseMessages(res?.data?.updateGroup);
    if (!isSuccess) throw new Error(error[0]);
    updateGroupDataHandler('owner-change', [member]);
    dispatch(setReloadSideNavShares(true));
  };

  /**
   * FUNCTION RESPONSIBLE TO CLOSE THE GROUP DETAILS POPUP AND ALSO FOR STATE CLEANUPS
   */
  const closePopupHandler = () => {
    dispatch(setGroupDetailsPopup(null));
    setSearch('');
    setPage(1);
    setSearchGotFocused(false);
  };

  /**
   * FUNCTION RESPONSIBLE TO LOAD MORE MEMBERS ON SCROLL
   */
  const loadMoreHandler = () => {
    if (currentMode === 'current-members') return;
    if (contactsList?.length < totalFilteredContacts) {
      setPage((old) => old + 1);
    }
  };

  /**
   * FUNCTION RESPONSIBLE FOR SEARCHING MEMBERS OR CONTACTS
   * @param val STRING, WHICH IS ENTERED BY USER ON SEARCH BAR
   */
  const searchHandler = (val: string) => {
    setSearch(val);
    setPage(1);
  };

  /**
   * FUNCTION WHICH UPDATES THE GROUPDATA STATE
   * AND ALSO TO DISPATCH ACTION FOR GROUP MESSENGER SO THAT BACKGROUND UI CAN UPDATE ACCORDINGLY
   * @param type STRING VALUE WHICH IS USED TO FIND THE TYPE OF ACTION PERFORMED BY USER
   * @param members MEMBERS LIST WHICH ARE ADDED. IF GROUP OWNER IS CHANGED THEN THIS LIST CONTAINS THAT SINGLE MEMBER WHICH BECAME OWNER.
   * IF MEMBER IS REMOVED THEN THIS LIST CONTAINS THAT SINGLE MEMBER WHICH IS REMOVED
   * @param nickname STRING VALUE WHICH GIVES THE NEW NICKNAME SET BY USER(OWNER)
   */
  const updateGroupDataHandler = (
    type:
      | 'owner-change'
      | 'member-removed'
      | 'members-added'
      | 'nickname-updated'
      | 'groups-merge',
    members: ContactWithGroupFlag[],
    nickname = ''
  ) => {
    const dispatchFunction = dataType === 'group' ? setGroupMessenger : setCollectionMessenger
    switch (type) {
      case 'owner-change':
        if(dataType === 'group') {
          const data = groupData as GroupType
          dispatch(
            dispatchFunction({
              data: {
                owner_avatar: members[0].avatar,
                owner_display_name: members[0].display_name,
                owner_user_name: members[0].user_name,
                user_id: members[0].user_id,
                id: data.id,
                members: [
                  {
                    id: data.user_id.toString(),
                    user_id: data.user_id,
                    avatar: data.owner_avatar,
                    display_name: data.owner_display_name,
                    user_name: data.owner_user_name,
                  },
                  ...data.members.filter(
                    (member) => member.user_id !== members[0].user_id
                  ),
                ],
              },
              type: 'edit',
            })
          );
        }
        
        closePopupHandler();
        break;
      case 'member-removed':
        setGroupData((old) => {
          return {
            ...old,
            ...(dataType === 'group'
              ? { members_count: (old as GroupType).members_count - 1 }
              : {}),
            members: old.members.filter(
              (member) => +member.user_id !== +members[0].user_id
            ),
          }
        });
        dispatch(
          dispatchFunction({
            type: 'member-removed',
            data: {
              id: groupData.id,
              ...(dataType === 'group'
                ? { members_count: (groupData as GroupType).members_count - 1 }
                : {}),
              members: groupData.members.filter(
                (member) => +member.user_id !== +members[0].user_id
              )
            }
          })
        );
        break;
      case 'members-added':
        setGroupData((old) => ({
          ...old,
          members: [...old.members, ...members],
          ...(dataType === 'group'
            ? { members_count: (old as GroupType).members_count + 1 }
            : {}),
        }));
        dispatch(
          dispatchFunction({
            type: 'member-added',
            data: {
              id: groupData.id,
              members: [...groupData.members, ...members],
              ...(dataType === 'group'
                ? { members_count: (groupData as GroupType).members_count + 1 }
                : {}),
            }
          })
        );
        break;
      case 'nickname-updated':
        setGroupData(old => ({
          ...old,
          nickname: nickname
        }))
        dispatch(
          dispatchFunction({
            data: {
              nickname,
              id: groupData.id,
            },
            type: 'edit',
          })
        );
        break;
      case 'groups-merge':
        dispatch(
          setGroupMessenger({
            type,
          })
        );
        break;

      default:
        break;
    }
  };

  const nickNameSaveHandler = async (nickname: string) => {
    const variables = {};
    variables['nickname'] = nickname;

    variables['group_id'] = groupData.id;

    const res = await updateGroupMutation({ variables });
    const { error, isSuccess } = getResponseMessages(res?.data?.updateGroup);
    if (!isSuccess) throw new Error(error[0]);
    updateGroupDataHandler('nickname-updated', [], nickname);
    dispatch(setReloadSideNavShares(true));
  }

  // To set group data in state with this effect 
  useEffect(() => {
    setGroupData(collectionOrGroupData);
  }, [collectionOrGroupData]);

  useEffect(() => {
    setCurrentMode('current-members');
  }, [groupDetailsPopup])

  return (
    <GroupDetailsPopupRenderer
      onCurrentModeChange={setCurrentMode}
      onFocus={setSearchGotFocused.bind(null, true)}
      dataType={dataType}
      isReadOnly={isReadOnly}
      currentlyDisplayedList={currentMode}
      onLoadMore={loadMoreHandler}
      onSearch={searchHandler}
      masonryKey={masonryKey}
      show={!!groupDetailsPopup}
      onClose={closePopupHandler}
      group={groupData}
      list={contactsList}
      isloadingGroup={loading}
      isLoadingList={loadingList}
      onMemberRemove={removeMemberHandler}
      onSubmit={submitHandler}
      onSetOwner={setOwnerHandler}
      dontRedirectOnMerge={groupDetailsPopup?.dontRedirectOnMerge}
      onNickNameSave={nickNameSaveHandler}
    />
  );
}
