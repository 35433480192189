import React, { useEffect, useRef, useState } from 'react';
import { entryTypes } from '../../types/feed';
import ScrapDetailsHeader from './ScrapDetailsHeader';
import ScrapDetailsFooter from './ScrapDetailsFooter';
import ScrapDetailsDistinctContent from './ScrapDetailsDistinctContent';
import '../ScrapDetails/styles.css';
import './styles.css';
import ScrapEditSourceUrl from '../ScrapDetails/components/ScrapEditSourceUrl';
import Icon from '../Global/Icon';
import ScrapAddNotesGroup from '../Global/InputsGroup/ScrapAddNotesGroup';
import ScrapDescription from '../ScrapDetails/components/ScrapDescription';
import TagsList from '../Global/TagsList';
import TagsView from '../Global/TagsView';
import {
  CloseIcon,
  CollectionIcon,
  FullScreenIcon,
  LeftArrowIcon,
} from '../Global/icons';
import { RequestStatusObjectType } from '../../types/requestStatusType';
import { tagType } from '../../types/tags';
import { collectionType } from '../../types/collections';
import { isEmpty, parseNote, returnScrapSlug } from '../../helpers';
import ScrapShare from '../ScrapDetails/components/ScrapShare';
import { returnShareLink } from '../../helpers/returnShareLinks';
import { renderSocialEmbed } from '../Cards/CardSocial';
import NewPopup from '../Global/NewPopup';
import { ScrapDetailsFieldNameType } from '../../containers/ScrapDetailsPopupContainer';
import { InputFieldType } from '../../types/inputFieldType';



export const parseSearchQueryFromUrl = (url: string) => {
  try {
    if (url) {
      const parsedUrl = new URL(url);
      const search = parsedUrl.search;
      return search.slice(1, search.length) || '';
    }
  } catch (error) {
    return '';
  }
};
interface Proptypes {
  setInput: (type: ScrapDetailsFieldNameType) => React.Dispatch<React.SetStateAction<InputFieldType<string>>>;
  getInput: (type: ScrapDetailsFieldNameType) => InputFieldType<string>;
  onCollectionsChange?: (collections: collectionType[]) => void;
  onTagsChange?: (tags: tagType[]) => void;
  scrap?: entryTypes;
  editMode?: boolean;
  children?: React.ReactElement;
  isReadOnly?: boolean;
  saveStatus?: RequestStatusObjectType;
  onVisibilityChange?: (visibilityState: number) => Promise<string>;
  onGenerateNewLink?: () => Promise<string>;
  isSharedView?: boolean;
  alwaysShowPublic?: boolean;
  setDisableSave?:(val:boolean)=>void;
  disableSave?: boolean;
  showFullMessage?:boolean;
}


const ScrapDetails = ({
  onCollectionsChange,
  showFullMessage,
  onTagsChange,
  scrap,
  editMode,
  children,
  isReadOnly,
  saveStatus,
  onVisibilityChange,
  onGenerateNewLink,
  isSharedView,
  alwaysShowPublic,
  setInput,
  getInput
}: Proptypes) => {
 
  const { type } = scrap;
  const hideTitle = ['product', 'page', 'social'].includes(type);
  let descriptionMapper = ['document', 'product', 'gallery'].includes(
    type
  )
    ? 'desc'
    : 'notes';
  if (type === 'annotation') descriptionMapper = 'comment';
  const initialNotes = parseNote(scrap[descriptionMapper] || '');
  const isNotesEmptyInitially = isEmpty(initialNotes, true);
  if (type === 'annotation') descriptionMapper = 'comment';
  const [lightBoxScrap, setLightBoxScrap] = useState<entryTypes>(null);
  const lightBoxRef = useRef<HTMLDivElement>();
  const [, setSourceUrl] = [getInput('source-url'), setInput('source-url')];
  const [, setShowEditSourceUrl] = useState(false);
  const [, setIsProductPriceFocused] = useState(false);
  const [price, setPrice] = [getInput('price'), setInput('price')];
  const [showEditPrice, setShowEditPrice] = useState(false);
  const [title, setTitle] = [getInput('title'), setInput('title')];
  const [notes, setNotes] = [getInput('notes'), setInput('notes')];

  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleTagsChange = (data: Partial<entryTypes>) => {
    if (data?.tags || data?.collections) {
      if (data.tags && onTagsChange) onTagsChange(data.tags);
      if (data.collections && onCollectionsChange)
        onCollectionsChange(data.collections);
    }
  };
  const returnTrimmedData = (desc: string, maxLen: number) => {
    var metaDescription = desc;
    if (metaDescription) {
      if (desc.length > maxLen) {
        metaDescription = desc.substring(0, maxLen) + '...';
      } else {
        metaDescription = desc;
      }
    }
    return metaDescription;
  };

  function sourceUrlChangeHandler(val: string) {
    setSourceUrl({ value: val, gotChanged: true });
  }

  function resetStates() {
    setSourceUrl({
      value: parseSearchQueryFromUrl(scrap.updated_url || scrap.url),
      gotChanged: false,
    });
    setShowEditSourceUrl(false);
    setIsProductPriceFocused(false);
    setPrice({
      value: scrap?.price?.toFixed(2) || '',
      gotChanged: false,
    });
    setShowEditPrice(false);
    setTitle({
      value: scrap.title,
      gotChanged: false,
    });
    setNotes({
      value: isNotesEmptyInitially ? '' : initialNotes,
      gotChanged: false,
    });
  }
  const headerTitle = () => {
    if (scrap?.type === 'gallery') {
      return 'Image Details';
    } else if (scrap?.page_title) {
      return scrap?.page_title;
    } else {
      return '';
    }
  };

  useEffect(() => {
    resetStates();
  }, [editMode]);

  // Use effect for setting the height of youtube embed and also maintain its aspect ratio
  useEffect(() => {
    const element = lightBoxRef?.current;
    if (lightBoxScrap && element) {
      if (lightBoxScrap.service === 'youtube') {
        element.style.height = (element.clientWidth * 360) / 640 + 'px';
      }
    }
  }, [lightBoxRef, lightBoxScrap]);
  const favicon = scrap.favicon || scrap.original_favicon;
  let typeOfScrap = type;

  let shareUrl = null;
  if (scrap)
    shareUrl = returnShareLink(
      'scrap',
      returnScrapSlug(scrap),
      scrap.userName,
      false,
      scrap.state,
      scrap.private_key
    );
  let isNotesDefaultOpened = false;
  if (!isNotesEmptyInitially || (!hideTitle && scrap.title?.trim()))
    isNotesDefaultOpened = true;
  return (
    <main
      className={`scrap-details scrap-details--${type}${
        saveStatus?.status === 'processing' ? ' scrap-details--saving' : ''
      }`}
    >
      {lightBoxScrap && (
        <NewPopup
          size="Extra large"
          fullMobile
          header={{
            heading: 'Image Details',
            customRender: (close) => (
              <header className="new-popup__header-inner-container">
                <h3 className="new-popup__header-heading">
                  <button
                    onClick={() => {
                      close();
                    }}
                    className="new-popup__header-button"
                  >
                    <LeftArrowIcon className="new-popup__header-button__icon" />
                  </button>
                  <span className="new-popup__header-title">
                    {headerTitle()}
                  </span>
                </h3>
                <button
                  onClick={() => {
                    close();
                  }}
                  className="new-popup__header-button"
                >
                  <CloseIcon className="new-popup__header-button__icon" />
                </button>
              </header>
            ),
          }}
          controlled={{
            show: !!lightBoxScrap,
            setShow: (val) => setLightBoxScrap(null),
          }}
        >
          {(close) => (
            <div
              ref={lightBoxRef}
              className={`scrap-details__light-box scrap-details__light-box--${lightBoxScrap.service}`}
            >
              {renderSocialEmbed(lightBoxScrap)}
            </div>
          )}
        </NewPopup>
      )}
      {type !== 'note' && (
        <div className="scrap-details__outer-container">
          <section
            className={`scrap-details__section scrap-details__section--${typeOfScrap} scrap-details__section--${scrap.service}`}
          >
            {children && (
              <div className="scrap-details__distinctive-content-container">
                {children}
                {type === 'social' &&
                  !['facebook', 'tiktok', 'youtube', 'pinterest', 'twitter'].includes(
                    scrap.service
                  ) && (
                  <button
                    onClick={setLightBoxScrap.bind(null, scrap)}
                    className="scrap-details__light-box-toggler"
                  >
                    <FullScreenIcon />
                  </button>
                )}
              </div>
            )}
            {!['gallery', 'video', 'document', 'screenshot'].includes(
              type
            ) && (
              <div className="scrap-details__general-content">
                {scrap.url?.length > 0 && (
                  <div className="scrap-details__meta">
                    <div className="scrap-details__meta-link">
                      {favicon && (
                        <span className="card-link-preview__link-favicon">
                          <img src={favicon} alt="FavIcon"></img>
                        </span>
                      )}
                      <div className="scrap-details__meta-url-text-container">
                        <ScrapEditSourceUrl
                          disabled
                          url={scrap.updated_url || scrap.url}
                          onChange={() => {}}
                          // displayFullUrl
                          domain={scrap.domain || scrap.url}
                        />
                      </div>
                    </div>
                    {/* HIDING EDIT URL AS PER CLIENT REQUEST */}
                    {/* {editMode && (
                      <>
                        {!showEditSourceUrl ? (
                          <span
                            className="scrap-details__meta-edit-url"
                            onClick={() => setShowEditSourceUrl(true)}
                          >
                            Edit URL
                          </span>
                        ) : (
                          <div className="scrap-details__meta-url-input-container">
                            <input
                              value={sourceUrl.value}
                              onChange={(e) =>
                                sourceUrlChangeHandler(e.target.value)
                              }
                              className="scrap-details__meta-url-input"
                            />
                          </div>
                        )}
                      </>
                    )} */}
                    <h2 className="scrap-details__meta-title">
                      {scrap.meta_title}
                    </h2>
                    {scrap.meta_desc?.length > 0 && (
                      <p className="scrap-details__meta-description">
                        {scrap.meta_desc}
                      </p>
                    )}
                  </div>
                )}
                {
                  type==='product' && <div className={'scrap-details__divider'}></div>
                }
                {type === 'product' && (
                  <div className="scrap-details__edit-price">
                    {(+price.value !== 0 || showEditPrice) &&
                      !showEditPrice &&
                      (
                        <span className="scrap-details__edit-price-dollar-icon">
                          <Icon iconClass="currency-dollar" size="big" />
                        </span>
                      )}
                    <div className={`scrap-details__edit-price-input-wrapper ${showEditPrice?'scrap-details__edit-price-input-wrapper--edit':' '}`}>
                      {editMode && showEditPrice ? (
                        <>
                           
                          <span className={`scrap-details__edit-price-dollar-icon }`}>
                            <Icon iconClass={`currency-dollar ${showEditPrice?"icon-inside":""}`} size="big" />
                          </span>
                        
                          <input
                            onFocus={setIsProductPriceFocused.bind(null, true)}
                            onChange={(e) => {
                              let price = e.target.value;
                              if (price.trim() === '') price = null;
                              setPrice({ value: price, gotChanged: +scrap?.price !== +price });
                            }}
                            disabled={!editMode}
                            onBlur={() => {
                              setIsProductPriceFocused(false);
                              setPrice((old) => ({
                                ...old,
                                value: (+old.value || 0).toFixed(2),
                              }));
                            }}
                            defaultValue={null}
                            type="text"
                            placeholder="Add a Price"
                            value={price.value}
                            className={`scrap-details__edit-price-input ${
                              price.value ? ' price-defined' : ''
                            }`}
                            onKeyPress={(event) => {
                              if (!/^\d+(?:\.\d+)?$/.test(event.key)) {
                                const keyCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  keyCode === 190 ||
                                  keyCode === 110 ||
                                  keyCode === 46
                                ) {
                                  //FOR . (DOT)
                                  if (price.value.toString().includes('.')) {
                                    event.preventDefault();
                                  }
                                } else {
                                  event.preventDefault();
                                }
                              }
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {+price.value !== 0 && (
                            <span className="scrap-details__edit-price-input">
                              {price.value}
                            </span>
                          )}
                          {(editMode && !showEditPrice) && (
                            <span
                              className="scrap-details__edit-price-button"
                              onClick={() => setShowEditPrice(true)}
                            >
                              {+price.value !== 0 ? 'Edit' : 'Add'} Price
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}

                {!scrap.url?.length && type !== 'product' && (
                  <p className="scrap-details__edit--meta-preview-description">
                    No Meta Data Available.
                  </p>
                )}
              </div>
            )}
          </section>
        </div>
      )}
      {editMode ? (
        <ScrapAddNotesGroup
          hideTitle={hideTitle}
          isDefaultOpened={isNotesDefaultOpened}
          className="scrap-details__add-notes"
          title={title.value}
          onTitleChange={(title) => {
            setTitle({
              value: title,
              gotChanged: scrap?.title?.trim() !== title?.trim(),
            });
          }}
          description={notes.value}
          onDescriptionChange={(desc) => {
            let gotChanged = true;
            if(isEmpty(desc, true) && isNotesEmptyInitially) gotChanged = false;
            else if(desc === initialNotes) gotChanged = false;        
            setNotes({
              value: desc,
              gotChanged,
            });
          }}
        />
      ) : (
        <>
          {!hideTitle && (
            <div className="scrap-details__title">
              <h2 className="scrap-details__title-heading">{title.value}</h2>
            </div>
          )}
          {!isEmpty(notes.value, true) && (
            <div
              className={`scrap-details__description ${
                isSharedView ? 'maxcontent' : ''
              }`}
            >
              <ScrapDescription
                key={editMode ? 'edit-mode' : 'view-mode'}
                description={notes.value || ''}
                onChange={(desc) => {}}
                disabled
              />
            </div>
          )}
        </>
      )}

      {!isSharedView && (
        <div className="scrap-details__tags-wrapper">
          {isReadOnly && scrap.collections?.length ? null : (
            <div className="card__collection">
              <TagsView
                readOnly={isReadOnly}
                list={scrap.collections || []}
                valueField="id"
                labelField="title"
                showEdit={!isReadOnly}
                showEditLabel={isReadOnly ? null : 'Collections'}
                dataType="collectionType"
                onSave={handleTagsChange}
              >
                <CollectionIcon />
              </TagsView>
            </div>
          )}

          <TagsList
            tags={scrap.tags || []}
            view={isReadOnly ? null : 'scrap-details'}
            onSave={handleTagsChange}
          />
        </div>
      )}
      {editMode && (
        <ScrapShare
          alwaysShowPublic={alwaysShowPublic}
          showFullMessage={showFullMessage}
          uneditable={isReadOnly}
          handleEdit={(data) => onVisibilityChange(data.state)}
          shareUrl={shareUrl}
          status={scrap.state}
          type="Scrap"
          noUrlMessage="Share link will be generated after saving the Scrap."
          loading={saveStatus.status === 'processing'}
          settings={true}
          onGenerateLink={onGenerateNewLink}
        />
      )}
    </main>
  );
};

export {
  ScrapDetails as default,
  ScrapDetailsFooter,
  ScrapDetailsHeader,
  ScrapDetailsDistinctContent,
};
