const DEFAULT = {
  collectionView: null
};

const page = (state = DEFAULT, action) => {
  switch (action.type) {
    case 'LOAD_PAGE':
      return action.page;
    case 'COLLECTION_VIEW':
      return { ...state, collectionView: action.payload }
    default:
      return state;
  }
};

export default page;
