import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';

export const DELETE_ACCOUNT = gql`
  mutation deleteUser(
    $type: Int 
    $verification_key: String
  ) {
    deleteUser(
      type: $type 
      verification_key: $verification_key
    ) {
      ${defaultResponse}
    }
  }
`;
