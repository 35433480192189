import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['group_id', 'Int!'],
  ['scrap_id', 'Int!'],
]);
export default function REMOVE_GROUP_SCRAP() {
  return gql`
    mutation RemoveGroupScrap(${parametersTypeString}) {
      removeGroupScrap(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}
