import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Helmet } from 'react-helmet';
import { BatchEditType } from '../../types/batchEdit';
import MyScrapsContainer from '../../containers/MyScrapsContainer';
import { routerLocationTypes } from '../../types/router';
import OneScrapPopupContainer from '../../containers/OneScrapPopupContainer';

interface propTypes {
  containerClass: string;
  location?: routerLocationTypes;
}

const ScrapPaths = (props: propTypes) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const batchEdit: BatchEditType = useSelector((state: any) => state.batchEdit);
  const fromOnboard = props.location?.state?.onboard || false;
  return (
    <>
      <Helmet>
        <title>Scrappi | All Scraps</title>
      </Helmet>
      <MyScrapsContainer hideSkeletion={fromOnboard} />

      {!batchEdit.mode && (
        <Switch>
          

          <Route
            exact
            path={['/scrap/:slug/:id', '/scrap/:slug/:id/edit']}
            render={(props) => {
              return (
                <OneScrapPopupContainer />
              );
            }}
          />
          
        </Switch>
      )}
    </>
  );
};

export default ScrapPaths;
