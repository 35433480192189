import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const Notes = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4 1.8V0.9C11.4 0.4 11 0 10.5 0C10 0 9.6 0.4 9.6 0.9V1.8H7.8V0.9C7.9 0.4 7.5 0 7 0C6.5 0 6.1 0.4 6.1 0.9V1.8H4.4V0.9C4.4 0.4 4 0 3.5 0C3 0 2.6 0.4 2.6 0.9V1.8C1.2 1.8 0 3 0 4.5V15.3C0 16.8 1.2 18 2.6 18H11.4C12.8 18 14 16.8 14 15.3V4.5C14 3 12.8 1.8 11.4 1.8ZM10.6 14.3H3.4C2.9 14.3 2.5 13.9 2.5 13.4C2.5 12.9 2.9 12.5 3.4 12.5H10.6C11.1 12.5 11.5 12.9 11.5 13.4C11.5 13.9 11.1 14.3 10.6 14.3ZM10.6 10.8H3.4C2.9 10.8 2.5 10.4 2.5 9.9C2.5 9.4 2.9 9 3.4 9H10.6C11.1 9 11.5 9.4 11.5 9.9C11.5 10.4 11.1 10.8 10.6 10.8ZM10.6 7.3H3.4C2.9 7.3 2.5 6.9 2.5 6.4C2.5 5.9 2.9 5.5 3.4 5.5H10.6C11.1 5.5 11.5 5.9 11.5 6.4C11.5 6.9 11.1 7.3 10.6 7.3Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default Notes;
