const DEFAULT = 0;

interface actionTypes {
  type: string;
  count: number;
  pidList?: string[];
}

const filterCount = (state = DEFAULT, action: actionTypes) => {
  switch (action.type) {
    case 'UPDATE_COUNT':
      return action.count;

    case 'CHANGE_TOTAL_COUNT_BY':
      return state + action.count;
    case 'BULK_DELETE_SCRAPS':
      return state - action.pidList.length;
    default:
      return state;
  }
};

export default filterCount;
