import React, { useState } from 'react';

import './styles.css';

interface Proptypes {
  isDefaultOpened?: boolean;
  renderTogglerButton?: (onClick: () => void) => React.ReactElement;
  togglerLabel?: string;
  children: React.ReactElement | React.ReactElement[];
}
const InputsGroup = ({
  isDefaultOpened = false,
  renderTogglerButton,
  togglerLabel = 'Show Fields',
  children,
}: Proptypes) => {
  const [show, setShow] = useState(isDefaultOpened);
  //   useEffect(() => {
  //     setShow(isDefaultOpened);
  //   }, [isDefaultOpened]);
  function renderButton() {
    if (renderTogglerButton)
      return renderTogglerButton(setShow.bind(null, true));
    return (
      <button
        onClick={setShow.bind(null, true)}
        className="inputs-group__toggler-button"
      >
        {togglerLabel}
      </button>
    );
  }
  return (
    <div className="inputs-group">
      {!show ? (
        renderButton()
      ) : (
        <div className="inputs-group__children">{children}</div>
      )}
    </div>
  );
};

export default InputsGroup;
