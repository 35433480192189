export const toggleGlobalSearch = (isSearchBarActive: boolean) => {
  return {
    type: 'TOGGLE_GLOBAL_SEARCH_BAR',
    payload: { isSearchBarActive },
  };
};

export const changeGlobalSearchText = (search: string) => {
  return {
    type: 'SET_GLOBAL_SEARCH_TEXT',
    payload: { search },
  };
};
export const changeGlobalSearchFilter = (filter: string) => {
  return {
    type: 'SET_GLOBAL_SEARCH_FILTER',
    payload: { filter },
  };
};
