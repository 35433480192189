import React, { useEffect, useState } from 'react';
import './styles.css';
import Filter from '../../../../containers/filter';
import { filterParamTypes } from '../../../../types/filter';
import ImagesMasonry from './components/ImagesMasonry';
import LoadingContent from '../../../Loaders/LoadingContent';
import {
  ExistingImagesItemsType,
  FetchedExistingImageType,
} from '../../types/imagesTypes';
import { IsExistingViewModeType } from '../../types/isExistingViewModeTypes';
import SelectedImagesMasonry from './components/SelectedImagesMasonry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Icon from '../../../Global/Icon';
import EmptyPageMessage from '../../../Global/EmptyPageMessage';
import { ReduxStateType } from '../../../../redux/store';
import { useSelector } from 'react-redux';

const faSpinnerIcon = faSpinner as IconProp;
interface Proptypes {
  filters: filterParamTypes;
  setFilters: (val: filterParamTypes) => void;
  images: FetchedExistingImageType[];
  loadMore: () => void;
  loading: boolean;
  selectedImages: ExistingImagesItemsType[];
  setSelectedImages: (imgs: ExistingImagesItemsType[]) => void;
  reloadFilters: boolean;
  setReloadFilters: (dt: boolean) => void;
  isAddExistingImages: IsExistingViewModeType;
  singleSelect?: (image: string) => void;
  processingImage?: boolean;
  showFilteredCollection?: string;
  collectionId?: string;
}

const AddExistingImages = (props: Proptypes) => {
  const {
    filters,
    setFilters,
    images,
    loadMore,
    loading,
    selectedImages,
    setSelectedImages,
    reloadFilters,
    setReloadFilters,
    isAddExistingImages,
    singleSelect,
    processingImage,
    showFilteredCollection,
    collectionId
  } = props;
  const [showTags, setShowTags] = useState(false);
  const user = useSelector((state: ReduxStateType) => state.user);
  useEffect(() => {
    setShowTags(false);
  }, [showFilteredCollection]);

  const renderImages = (
    isLoading: boolean,
    items: FetchedExistingImageType[]
  ) => {
    if (items.length > 0)
      return (
        <ImagesMasonry
          images={items}
          loadMore={loadMore}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          singleSelect={singleSelect}
        />
      );
    // else return <LoadingContent</LoadingContent>  
    else
      return isLoading ? (
        <LoadingContent />
      ) : (
        <div className="no-collection-data">
          <EmptyPageMessage header={{}} register_from={user.register_from}>
            <span>No Image Found</span>
          </EmptyPageMessage>
        </div>
      );
  };
  return (
    <div
      className={`add-existing-images${
        processingImage ? ' add-existing-images__processing' : ''
      }`}
    >
      <div className="add-existing-images__filters-outer-container">
        <div className="add-existing-images__filters custom-scroll__body">
          <div
            className="add-existing-images__filters__tag-icon-holder"
            onClick={() => setShowTags(!showTags)}
          >
            <div className="add-existing-images__filters__tag-icon-holder__icon-container">
              <img
                className={
                  'add-existing-images__filters__tag-icon-holder--icon-container__icon'
                }
                src={'/dashboard/svg-icons/NewTag.svg'}
                alt={'Tags'}
              />
              <span className="add-existing-images__filters__tag-icon-holder__icon-container__label">
                Tags
              </span>
            </div>
            <span
              className={`add-existing-images__filters__tag-icon-holder__toggle-icon${
                showTags ? ' side-nav-item__toggle-icon--open' : ''
              }`}
            >
              <Icon iconClass="chevron-down" size="normal" />
            </span>
          </div>

          {showTags && (
            <Filter
              pFilters={filters}
              setPFilters={setFilters}
              isAddExistingImages={isAddExistingImages}
              reloadFilters={reloadFilters}
              setReloadFilters={setReloadFilters}
              selectedImages={selectedImages.length || 0}
              noFilterCount
              hideSelectedImages={!!singleSelect}
              collectionId={collectionId}
              showFilteredCollection={showFilteredCollection}
            />
          )}
        </div>
      </div>
      <div className={`add-existing-images__main-wrapper`}>
        {isAddExistingImages.viewMode === 'All Images' ? (
          renderImages(loading, images)
        ) : (
          <SelectedImagesMasonry
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
          />
        )}
      </div>
      {processingImage && (
        <span className="add-existing-images__processing-icon">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="5x" />
        </span>
      )}
    </div>
  );
};
export default AddExistingImages;
