/**
 * This component handles the display of trust status of a user.
 * It takes care of:
 *  - Displaying the trust status of a user
 *  - Different controls to mark a user as trusted or not
 */

import React, { useEffect, useState } from 'react';
import './TrustStyles.css';
import { useMutation } from '@apollo/client';

import { DELETE_USER_TRUST, UPSERT_USER_TRUST } from './trustQueries';
import TrustIcon from './TrustIcon';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../redux/store';

interface TrustViews {
  view: 'button' | 'text' | 'icon';
}

interface TrustProps {
  isTrusted: boolean;
  userId?: number;
  view?: TrustViews['view'];
  cb?: (val: boolean) => void;
}

const Trust = ({ isTrusted, userId, view, cb }: TrustProps) => {
  const [trust, setIsTrust] = useState(isTrusted);
  const user = useSelector((state: ReduxStateType) => state.user);

  const [upsertUserTrust] = useMutation(UPSERT_USER_TRUST);
  const [deleteUserTrust] = useMutation(DELETE_USER_TRUST);
  const isLoggedInUser = user.user_id === userId;

  useEffect(() => {
    setIsTrust(isTrusted);
  }, [isTrusted]);

  async function toggleTrust() {
    const newState = !trust;
    setIsTrust(newState);

    if (trust) {
      await deleteUserTrust({
        variables: {
          user_id_trusted: userId,
        },
      });
    } else {
      await upsertUserTrust({
        variables: {
          user_id_trusted: userId,
        },
      });
    }

    if(cb) {
      cb(newState);
    }

    const isServer = typeof window === 'undefined';
    if (!isServer && view === 'button') {
      // reload the page to reflect the changes
    }
  }

  if (view === 'button') {
    return (
      <div className="trust-wrapper">
        <button
          onClick={toggleTrust}
          disabled={isLoggedInUser}
          className={`trust-btn ${trust || isLoggedInUser ? 'trust-btn--trusted' : ''}`}
        >
          <span className="trust-btn__icon">
            <TrustIcon type="outline" />
          </span>
          <span>
            {trust || isLoggedInUser ? 'Trusted' : 'Trust'}
          </span>
        </button>
      </div>
    );
  }

  if (view === 'icon') {
    if (!trust && !isLoggedInUser) return null;

    return (
      <strong className="trust__icon">
        <TrustIcon />
      </strong>
    );
  }

  return (
    <div onClick={toggleTrust} className="trust__text">
      <span className={`trust-text ${trust ? 'trust-text--trusted' : ''}`}>
        {trust ? 'Trusted' : 'Trust'}
      </span>
    </div>
  );
}

export default Trust;