import React from 'react';

interface Proptypes {
  className?: string;
}

const LinkIcon = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''} 

      width="11"
      height="11"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24266 0.37707C7.87487 0.380479 7.5794 0.689918 7.58271 1.06822C7.58602 1.44653 7.88686 1.75044 8.25466 1.74703L11.3782 1.71809L6.87873 6.34614C6.61865 6.61365 6.61865 7.04738 6.87873 7.31489C7.1388 7.5824 7.56048 7.5824 7.82055 7.31489L12.32 2.68684L12.2919 5.89963C12.2885 6.27793 12.584 6.58737 12.9518 6.59078C13.3196 6.59419 13.6204 6.29027 13.6237 5.91197L13.6666 1.02419C13.6682 0.84038 13.5979 0.663619 13.4715 0.533642C13.3452 0.403665 13.1733 0.331379 12.9946 0.333036L8.24266 0.37707ZM0.999226 0.651165C0.631418 0.651165 0.333252 0.957854 0.333252 1.33617V12.9813C0.333252 13.3597 0.631418 13.6663 0.999226 13.6663H12.9868C13.3546 13.6663 13.6527 13.3597 13.6527 12.9813V9.55629C13.6527 9.17797 13.3546 8.87128 12.9868 8.87128C12.6189 8.87128 12.3208 9.17797 12.3208 9.55629V12.2963H1.6652V2.02118H4.66208C5.02989 2.02118 5.32805 1.71449 5.32805 1.33617C5.32805 0.957854 5.02989 0.651165 4.66208 0.651165H0.999226Z"
        fill="#838383"
      />
    </svg>
  );
};

export default LinkIcon;
