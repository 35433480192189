import { VisibilityModesType } from '../../types/visibilityModesType';
const DEFAULT = [];

const visibilityStates = (
  state: VisibilityModesType[] = DEFAULT,
  {
    type,
    payload,
  }: {
    type: string;
    payload?: {
      statesList: VisibilityModesType[];
    };
  }
) => {
  const { statesList } = payload || {};
  switch (type) {
    case 'LOAD_VISIBILITY_STATES':
      return statesList;

    default:
      return state;
  }
};

export default visibilityStates;
