import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { slugifyString } from '../../../helpers';
import { setBackUrl } from '../../../redux/action/utils';
import { entryTypes } from '../../../types/feed';
import ScrapEditTags from '../../ScrapDetails/components/ScrapEditTags';
import { PropHelpers, convertTagsIntoCollection } from '../CheckboxList';
import Plus from '../icons/Plus';
import './styles.css';

interface Proptypes {
  hideLess?: boolean;
  defaultExpanded?: boolean;
  readOnly?: boolean;
  list: PropHelpers['dataType'][];
  valueField: keyof PropHelpers['mergedDataType'];
  labelField: keyof PropHelpers['mergedDataType'];
  children: React.ReactChild;
  showEdit?: boolean;
  showEditLabel?: string;
  isNew?: boolean;
  dataType: 'tagType' | 'collectionType';
  data?: PropHelpers['dataType'][];
  setData?: (tags: PropHelpers['dataType'][]) => void;
  onSave?: (data?: Partial<entryTypes>) => void;
  savingStatus?: string | null;
  label?: string;
  addIcon?: boolean;
  disableItemClick?: boolean;
  setOnSaveCollectionList?: (val: boolean) => void;
  onSaveCollectionList?: boolean;
  showMore?: boolean;
  onMoreClick?: (currentlyExpanded: boolean) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TagsView(props: Proptypes) {
  const {
    hideLess,
    defaultExpanded,
    readOnly,
    list,
    valueField,
    labelField,
    children,
    showEdit,
    showEditLabel,
    isNew,
    dataType,
    onSave,
    savingStatus,
    label,
    addIcon,
    disableItemClick,
    setOnSaveCollectionList,
    onSaveCollectionList,
    showMore,
    onMoreClick,
  } = props;
  const { push } = useHistory();

  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState<PropHelpers['dataType'][]>(
    list as PropHelpers['dataType'][]
  );

  // use this function to make add collection and add tag label black when there is no collection or tag are saved
  // const className = () =>{
  //   if(onSaveCollectionList !== undefined){
  //     if(onSaveCollectionList){
  //       return 'tags-view__single-line-item__edit-label'
  //     }else{
  //       return 'tags-view__single-line-item__add'
  //     }
  //   }else if(list?.length === 0){
  //     return 'tags-view__single-line-item__add'

  //   }else{
  //     return 'tags-view__single-line-item__edit-label'
  //   }
  // }
  const [more, setMore] = useState(defaultExpanded);
  const descriptionClickHandler = (
    canCollapse: boolean,
    e: React.MouseEvent
  ) => {
    e.stopPropagation();
    if(onMoreClick) {
      const canContinue = onMoreClick(more);
      if(!canContinue) return;
    }
    setMore((old) => !old);
  };

  const returnItemLabel = (item, index) => {
    let trimmed = item[labelField];
    if (!item?.checked && index == 2 && !more && list.length > 3) {
    

      return (
        <>
          {/* {item[labelField]}{' '} */}
          {item[labelField].length > 25
            ? trimmed?.substring(0, 20)
            : item[labelField]}
          {' '} <span
            className={`tags-view__single-line-item__more-button`}
            onClick={descriptionClickHandler.bind(null, true)}
          >
            ...more
          </span>{' '}
        </>
      );
    } else if (index === list.length - 1 && list.length > 3) {
      return (
        <>
          {item[labelField].length > 25
            ? trimmed?.substring(0, 20)
            : item[labelField]}
          {!hideLess && (
            <span
              className={`tags-view__single-line-item__more-button`}
              onClick={descriptionClickHandler.bind(null, true)}
            >
              ...less
            </span>
          )}
        </>
      );
    } else {
      return item[labelField];
    }
  };
  const returnWithMoreButton = () => {
    return (list || []).slice(0, !more ? 3 : list.length).map((item, i) => {
      return (
        <span
          onClick={(e) => {
            if (disableItemClick) return;
            e.stopPropagation();
            itemClickHandler(item);
          }}
          key={i}
          className={`${
            !onSaveCollectionList && onSaveCollectionList !== undefined
              ? ''
              : `tags-view__single-line-item tags-view__single-line-item--${dataType} ${
                showMore
                  ? `tags-view__single-line-item--${dataType}--trim`
                  : ''
              }`
          }${disableItemClick ? ' tags-view__single-line-item--disabled' : ''}`}
        >
          {returnItemLabel(item, i)}
        </span>
      );
    });
  };

  const returnWithoutMoreButton = () => {
    return (list || []).map((item, i) => {
      const parsedItem = convertTagsIntoCollection(item)
      return (
        <span
          onClick={(e) => {
            if (disableItemClick) return;
            e.stopPropagation();
            itemClickHandler(item);
          }}
          key={convertTagsIntoCollection(item).id}
          className={`${
            !onSaveCollectionList && onSaveCollectionList !== undefined
              ? ''
              : `tags-view__single-line-item tags-view__single-line-item--${dataType}`
          }${disableItemClick ? ' tags-view__single-line-item--disabled' : ''}`}
        >
          {!parsedItem?.checked && parsedItem[labelField]}
        </span>
      );
    });
  };

  // was used for showing plus icon conditionlly if the there were collection plus icon was not being showed
  // const plusIcon = () => {
  //   if (onSaveCollectionList !== undefined) {
  //     if (!onSaveCollectionList) {
  //       return <Plus className="tags-view__header-icon" />;
  //     } else {
  //       return null;
  //     }
  //   } else if (list?.length === 0) {
  //     return <Plus className="tags-view__header-icon" />;
  //   }
  // };

  useEffect(() => {
    setData(list);
  }, [list]);
  function itemClickHandler(item: PropHelpers['dataType']) {
    if (dataType === 'collectionType') {
      push(`/c/${slugifyString(convertTagsIntoCollection(item).title, false)}/${convertTagsIntoCollection(item).id}`);
      dispatch({ type: 'CLOSE_EDIT' });
    } else if (dataType === 'tagType') {
      push(`/?tags=${convertTagsIntoCollection(item).slug}`);
      dispatch(setBackUrl(null));
      
    }
  }
  return (
    <div className="tags-view tags-view--with-icon">
      <div className='tags-view__label-wrapper'>
        <span className="tags-view__icon">{children}</span>
        {showEdit && (
          <span
            className={`tags-view__single-line-item tags-view__single-line-item__edit`}
            onClick={() => {
              setEdit(true);
            }}
          >
            <span
              onClick={() => {
                list.length > 0 && setEdit(true);
              }}
              //use className function to make add collection and add tag label black when there is no collection or tag are saved
              className={'tags-view__single-line-item__edit-label'}
            >
              {/* {label ? label : list?.length === 0 ? 'Add' : 'Edit'}{' '} */}
              {showEditLabel}
            </span>
            <div className='tags-view__header-icon-wrapper'>
              <Plus className="tags-view__header-icon" />
            </div>
          </span>
        )}
      </div>
      
      <span
        className={`tags-view__single-line-list ${
          showEditLabel === 'Collections'
            ? 'tags-view__single-line-collection'
            : ''
        } ${
          !onSaveCollectionList && onSaveCollectionList !== undefined
            ? 'tags-view__single-line-list-flex'
            : ''
        }`}
      >
       
        {(showMore && dataType!=="tagType") ? returnWithMoreButton() : returnWithoutMoreButton()}

     
      </span>
      {!readOnly && edit && dataType && data && setData && (
        <ScrapEditTags
          controlled={{ show: edit, setShow: setEdit }}
          data={data}
          setData={setData}
          dataType={dataType}
          onSave={onSave}
          showEdit={list?.length === 0 ? !showEdit : showEdit}
          savingStatus={savingStatus}
          isNew={isNew}
          setOnSaveCollectionList={setOnSaveCollectionList}
        />
      )}
    </div>
  );
}

export default TagsView;
