import React, { ReactNode } from 'react';
import ReactDom from 'react-dom';

interface propTypes {
  show: boolean;
  children: ReactNode;
}

const PortalComponent = (props: propTypes) => {
  const { show, children } = props;
  if (!show) return null;

  return ReactDom.createPortal(
    <div onClick={(e)=>{e.stopPropagation()}}>{children}</div>,
    document.getElementById('portal')
  );
};

export default PortalComponent;
