import React from 'react';

interface Proptypes {
  className?: string;
}

const OpenSquare = ({ className }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8641 0.0660937C11.3124 0.0712061 10.8692 0.535365 10.8742 1.10282C10.8792 1.67028 11.3304 2.12615 11.8821 2.12104L16.5674 2.07762L9.81821 9.0197C9.42809 9.42097 9.42809 10.0716 9.81821 10.4728C10.2083 10.8741 10.8408 10.8741 11.231 10.4728L17.9801 3.53075L17.9379 8.34993C17.9329 8.91739 18.3761 9.38155 18.9278 9.38666C19.4795 9.39177 19.9308 8.9359 19.9357 8.36844L20 1.03677C20.0024 0.761058 19.897 0.495917 19.7074 0.300951C19.5179 0.105986 19.2601 -0.00244297 18.992 4.17829e-05L11.8641 0.0660937ZM0.99896 0.477235C0.44725 0.477235 0 0.937269 0 1.50475V18.9725C0 19.54 0.44725 20 0.99896 20H18.9802C19.532 20 19.9792 19.54 19.9792 18.9725V13.8349C19.9792 13.2674 19.532 12.8074 18.9802 12.8074C18.4285 12.8074 17.9813 13.2674 17.9813 13.8349V17.945H1.99792V2.53226H6.49324C7.04495 2.53226 7.4922 2.07223 7.4922 1.50475C7.4922 0.937269 7.04495 0.477235 6.49324 0.477235H0.99896Z"
        fill="#838383"
      />
    </svg>
  );
};

export default OpenSquare;
