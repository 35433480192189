import { topicTypes } from '../../types/topic';

const DEFAULT: Array<topicTypes> = [];

const topics = (state = DEFAULT, action) => {
  switch (action.type) {
    case 'LOAD_TOPICS':
      return [...action.topics];

    case 'ADD_TOPIC':
      return [...state, action.topic];

    case 'UPDATE_TOPIC_NOTE':
      return state.map((en) => {
        if (en.id === action.id) {
          /* eslint no-param-reassign: "error" */
          en.notes = action.note;
        }

        return en;
      });

    default:
      return state;
  }
};

export default topics;
