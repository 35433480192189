import { ApolloClient } from '@apollo/client';
import { tagType } from '../../../types/tags';
import REMOVE_SCRAP_TAGS from '../../data/removeScrapTags';
import UPSERT_SCRAP_TAGS from '../../data/upsertScrapTags';
import { parseTagFromSlug } from '../../../helpers';

// Function to remove tags from scrap
export async function removeScrapTagsHandler(
  scrap_id: number,
  list: tagType[],
  client: ApolloClient<object>
) {
  try {
    // Start process of removinb tags
    const response = await client.mutate({
      mutation: REMOVE_SCRAP_TAGS(),
      variables: {
        scrap_id,
        tags: list.map(({ slug }) => ({ id: parseTagFromSlug(slug).id })),
      },
    });
    // Check if tags are saved successfully by looking for success message in response
    if (response.data.removeScrapTags.messages.success.length === 0) {
      throw new Error();
    }
  } catch (error) {
    throw new Error('Failed to remove tags!');
  }
}

// Handler function to save tags in scrap
export async function saveTagsInScrapHandler(
  scrap_id: number,
  list: tagType[],
  client: ApolloClient<object>
) {
  try {
    // Start process of saving tags in scrap with mutation
    const response = await client.mutate({
      mutation: UPSERT_SCRAP_TAGS(),
      variables: {
        scrap_id,
        tags: list.map(({ slug}) => ({ title: parseTagFromSlug(slug).title, id: parseTagFromSlug(slug).id })),
      },
    });

    // Check if tags are saved successfully by looking for success message in response
    if (response.data.upsertScrapTags.messages.success.length === 0) {
      throw new Error();
    }
  } catch (error) {
    throw new Error('Failed to save tags!');
  }
}
