import React, { useEffect, useRef, useState } from 'react';
import { TickIcon } from '../../Global/icons';
import ImageCropper, { CropControlType } from '../../Global/ImageCropper';
import Tiptap from '../../Global/Tiptap';

import './styles.css';
import Button from '../../uiComponents/Button';

interface Proptypes {
  imageUrl: string;
  onCrop: (file: Blob, isCropped: boolean) => void;
  onCancel: () => void;
  imageType?: string;
  cancelButtonLabel?: string;
  submitButtonLabel?: string;
  aspectRatio?: number;
  onDescriptionChange?: (text: string) => void;
  imageDescription?: string;
  showOverflow?:boolean;
  movable?:boolean;
  zoomOnTouch ?:boolean;
}

const ScrapImagesCropper = ({
  submitButtonLabel,
  cancelButtonLabel,
  imageUrl,
  onCrop,
  onCancel,
  imageType,
  aspectRatio,
  imageDescription,
  onDescriptionChange,
  showOverflow,
  movable,
  zoomOnTouch
}: Proptypes) => {
  const [isCropping, setIsCropping] = useState(false);
  const cropperRef = useRef(null);
  const showTiptap = !!onDescriptionChange;

  useEffect(()=>{
    if(showOverflow) {
      if(typeof(window)!=="undefined"){
        document.body.style.overflow="auto";
      }
    }
  },[])
  

  function renderControls(controlsList: CropControlType[]) {
    return (
      <div className={`scrap-images-cropper__controls${isCropping ? ' scrap-images-cropper__controls--disabled' : ''}`}>
        <ul className="scrap-images-cropper__controls-list">
          {controlsList.map(({ icon, onClick, title, type }) => (
            <li
              key={type}
              title={title}
              onClick={onClick}
              className="scrap-images-cropper__controls-list-item"
            >
              {icon}
            </li>
          ))}
        </ul>
        {onDescriptionChange && (
          <div className="scrap-images-cropper__caption">
            <Tiptap
              onChange={onDescriptionChange}
              placeholder="Write a caption"
              hideAllTools
              edit={true}
              labelledEditor={true}
              label="Caption"
              text={imageDescription}
            />
          </div>
        )}

        <div className="scrap-images-cropper__action-buttons">
          <button
            className="scrap-images-cropper__cancel-button button button__outline"
            onClick={onCancel}
          >
            {cancelButtonLabel || 'Cancel'}
          </button>
          <Button
            icon={<TickIcon />}
            buttonType="orange-filled"
            onClick={() => {
              setIsCropping(true);
              cropperRef?.current?.onCropClick();
            }}
            className="new-popup__footer-submit"
          >
            {submitButtonLabel || 'Crop'}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div className={`scrap-images-cropper${showTiptap ? ' scrap-images-cropper--with-description' : ''}`}>
      <ImageCropper
        imageType={imageType}
        imageUrl={imageUrl}
        onCrop={onCrop}
        ref={cropperRef}
        customRenderControls={renderControls}
        aspectRatio={aspectRatio}
        movable={movable}
        zoomOnTouch={zoomOnTouch}
      />
    </div>
  );
};

export default ScrapImagesCropper;
