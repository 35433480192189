import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import ImagesGroup from '../';
import { returnImageURL } from '../../../Gallery/helpers/returnImageURL';

interface Proptypes {
  images: string[];
  fetchMoreImages?:(number)=>void;
  renderImage?: (index: number, defaultRender: React.ReactElement) => React.ReactElement;
}

export default function ImagesGroupCarousel({ images,fetchMoreImages, renderImage }: Proptypes) {
  if (images?.length === 0 || !images) return null;
  if (images?.length === 1)
    return <ImagesGroup images={images} mode="carousel" />;
  return (
    <div
      className="images-group__carousel"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Carousel
        dynamicHeight={true}
        onChange={(index, item) => {
          if(!fetchMoreImages) return;
          if (index >= images?.length - 2) {
            fetchMoreImages(index)
          }
        }}
      >
        {images.map((image, i) => {
          const imageElement = (
            <div key={image}>
              <img src={returnImageURL(image)} alt={image} />
            </div>
          )
          if(renderImage) return renderImage(i, imageElement);
          return imageElement;
        })}
      </Carousel>
    </div>
  );
  // return <div className="images-group__carousel">

  // </div>;
}
