import React, { useState } from 'react';

interface Proptypes {
  favicon: string;
}
export default function WebContentFavicon({ favicon }: Proptypes) {
  const [image, setImage] = useState(true);
  function handleImageError() {
    setImage(false);
  }
  if (!favicon) return null;
  return image ?  <img src={favicon} className="web-content__favicon" alt="favicon" onError={handleImageError} /> : null
}
