import React from 'react';
import InputsGroup from '.';
import ScrapDescription from '../../ScrapDetails/components/ScrapDescription';
import { NoteIcon } from '../icons';
import Plus from '../icons/Plus';
import LabelTextInput from '../LabelTextInput';

import './ScrapAddNotesGroup.css';

interface Proptypes {
  charLimit?: number
  hideTitle?: boolean;
  isDefaultOpened?: boolean;  
  className?: string;  
  title: string;
  onTitleChange: (val: string) => void;
  description: string;
  onDescriptionChange?: (val: string) => void;
  setDisableSave?:(val:boolean)=>void;
  onDescriptionClose?: () => void;
}

const ScrapAddNotesGroup = ({
  charLimit,
  hideTitle,
  isDefaultOpened,  
  className = '',  
  title,
  onTitleChange,
  description,
  onDescriptionChange,
  setDisableSave,
  onDescriptionClose
}: Proptypes) => {
  const baseClass = 'scrap-add-notes-group';
  return (
    <div className={baseClass + ' ' + className}>
      <InputsGroup
        isDefaultOpened={isDefaultOpened}
        renderTogglerButton={(onClick) => (
          <button onClick={onClick} className={baseClass + '__toggler-button'}>
            <NoteIcon />
            <span className={baseClass + '__toggler-button-label'}>
              Add Notes
            </span>
            {<Plus className="tags-view__header-icon" />}
          </button>
        )}
      >
        {!hideTitle && (
          <div className={baseClass + '__title-input'}>
            <LabelTextInput
              placeholder="Title"
              showLabelOnFocus
              value={title}
              label="Title"
              onChange={onTitleChange}
            />
          </div>
        )}
        {onDescriptionChange && (
          <div className={baseClass + '__description-input'}>
            <ScrapDescription
              description={description || ''}
              placeholder="Add notes"
              onChange={onDescriptionChange}
              onCloseClick={onDescriptionClose}
              autoFocus
              limitConfig={{
                charLimit,
                setDescCharLimitExceeds: (...args) => {
                  // Intentionallhy left blank because it is not required to store character count of notes 
                }
              }}
            />
          </div>
        )}
      </InputsGroup>
    </div>
  );
};

export default ScrapAddNotesGroup;
