import React from 'react';

import './styles.css';
import { useHistory } from 'react-router-dom';

import AuthHeaderLayout from './components/AuthHeaderLayout';
import ErrorAuthPage from './ErrorAuthPage';

interface Proptypes {
  status: 'pending' | 'error' | 'success';
  message: string;
}

const AccountRecovery = (props: Proptypes) => {
  const { status, message } = props;
  const history = useHistory();

  if (status === 'error')
    return (
      <ErrorAuthPage
        title="Verify Email"
        message={message}
        buttonLabel="Back to scrappi"
        buttonLink="/login"
      />
    );
  if (status === 'success')
    return (
      <AuthHeaderLayout title="Verify Email" helmetTitle="Scrappi">
        <div>
          <h4>Success! Your email has been verified</h4>
          <div className="form-group form-group__action">
            <a
              className="button button__primary"
              href='/login'
            >
              Back to Scrappi
            </a>
          </div>
        </div>
      </AuthHeaderLayout>
    );
  return null;

  //
};

export default AccountRecovery;
