import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_ids', '[Int]'],
  ['collection_ids', '[Int]'],
  ['comment_ids', '[Int]'],
  ['reactable_type', 'Int'],
  ['reaction_type', 'Int = 1'],
]);
export default function GET_REACTIONS() {
  return gql`
    query GetReactions(${parametersTypeString}) {
      getReactions(${parametersAssignString}) {
        ${defaultResponse}
        data {
            is_reacted
            id
            reactable_type
            reactions {
                id
                user_id
                scrap_id
                collection_id
                comment_id
                reaction_type
            }
            reacted_users {
                user_id
                user_name
                display_name
                avatar
            }
        }
      }
    }
  `;
}
