import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReduxStateType } from '../../redux/store';
import EmptyPageMessage from '../Global/EmptyPageMessage';
import './styles.css';
const Page404 = () => {
  const user = useSelector((state: ReduxStateType) => state.user);
  return (
    <div className="not-found">
      <Helmet>
        <title>Scrappi | Page Not Found</title>
      </Helmet>{' '}
      <EmptyPageMessage header={{}} register_from={user.register_from}>
        <span className="error-wrapper__error-message">
          Hmm, nothing's buried here - maybe you're digging in the wrong spot?
          <Link
            className="error-wrapper__error-message__link"
            to={'#'}
            onClick={() => {
              window.location.href = window.location.origin;
            }}
          >
            Go Home
          </Link>
        </span>
      </EmptyPageMessage>
    </div>
  );
};

export default Page404;
