import React, { useEffect, useState } from 'react';
import './styles.css';
import NewPopup from '../Global/NewPopup';
import { useLazyQuery } from '@apollo/client';

import GET_STRIPE_CHECKOUT from '../../containers/data/getStripeCheckoutForSubscriptionItem';
import { NewClose } from '../Global/icons';
import CANCEL_STRIPE_SUBSCRIPTION from '../../containers/data/cancelStripeSubscription';

interface PropTypes {
    name?: string;
    price?: number;
    className?: string;
    buttonDetail?: string;
    isActive: boolean;
    included?: Array<string>;
    id?: string;
    isAnnual?: boolean;
    customerItemUnitPrice?: number;
    modifiedPriceId?: string;
    modifiedCustomerActivePlanPriceId?: string;
    modifiedCustomerActivePlanPriceFrequency?: string;
}

const PriceCard = ({
    name,
    price,
    buttonDetail,
    isActive,
    included,
    id,
    isAnnual,
    customerItemUnitPrice,
    modifiedPriceId,
    modifiedCustomerActivePlanPriceId,
    modifiedCustomerActivePlanPriceFrequency
}: PropTypes) => {


    const [openRedirectToStripe, setRedirectToStripe] = useState<boolean>(false);
    const [openCancelStripeSubscriptionPopup, setOpenCancelStripeSubscriptionPopup] = useState<boolean>(false);
    const [itemPriceId, setItemPriceId] = useState<string | null>(null);
    const [unitPrice, setUnitPrice] = useState<string | null>(null);

    const isActivePlan =
        modifiedPriceId === modifiedCustomerActivePlanPriceId &&
        modifiedCustomerActivePlanPriceFrequency === (isAnnual ? 'year' : 'month');

    const isLowerPrice = price !== undefined && price < customerItemUnitPrice;

    const disablePackage = ((isActivePlan || isLowerPrice) && modifiedCustomerActivePlanPriceFrequency == (isAnnual ? 'year' : 'month') && price !== 0)


    const [
        fetchSubscriptionItemUrl,
        { data: subscriptionItemUrl, loading: subscriptionItemLoading },
    ] = useLazyQuery(GET_STRIPE_CHECKOUT(), {
        variables: {
            itemPriceId,
            unitPrice
        },
    }
    
    );

    const [
        cancelStripeSubscriptionQuery,
        { data: cancelStripeSubscriptionData, loading: cancelStripeSubscriptionLoading },
    ] = useLazyQuery(CANCEL_STRIPE_SUBSCRIPTION);


    const onChangeStripeSubscription = (itemPriceId: string, unitPrice: string) => {
        if (unitPrice == "0") {
            setOpenCancelStripeSubscriptionPopup(true);
        }
        else {
            setRedirectToStripe(true);
            setItemPriceId(itemPriceId);
            setUnitPrice(unitPrice);
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            if (setRedirectToStripe && itemPriceId !== null && unitPrice !== null) {
                await fetchSubscriptionItemUrl({ variables: { itemPriceId, unitPrice } });
                
            }
        };
        fetchData();
    }, [setRedirectToStripe, itemPriceId, unitPrice]);


    useEffect(() => {
        if (cancelStripeSubscriptionData?.cancelStripeSubscription?.data?.is_cancelled) {
            setOpenCancelStripeSubscriptionPopup(false);
            window.location.reload(); 
        }


    }, [cancelStripeSubscriptionData])


    function render(close: () => void) {
        return (
            <>

                <div className="delete-scrap">

                </div>

                <p className='delete-scrap__message'>Are you sure that you want to unsubscribe?</p>
            </>
        );
    }

    function cancelStripeSubscriptionHandler() {
        cancelStripeSubscriptionQuery();
    }


    return (
        <> 
            {(openRedirectToStripe && subscriptionItemUrl?.getStripeCheckoutForSubscriptionItem?.data?.url) && (

                window.location.replace(subscriptionItemUrl?.getStripeCheckoutForSubscriptionItem?.data?.url)
            )}

            {openCancelStripeSubscriptionPopup && (
                <NewPopup
                    className='delete-scrap'
                    header={{
                        heading: 'Unsubscribe the plan',
                    }}
                    onClosed={() => setOpenCancelStripeSubscriptionPopup(false)}
                    defaultOpen={true}
                    footer={{
                        onSubmit: () => cancelStripeSubscriptionHandler(),
                        submitLabel: 'Delete',
                    }}
                >
                    {render}
                </NewPopup>
            )}
            <div className={`package${isActive ? ' package--active' : ''} ${disablePackage ? 'package--disable' : ''}`} onClick={() => onChangeStripeSubscription(id || '', String(price) || '')}>
                <div className='package__name'>{name}</div>
                <div className={`package__price__${isAnnual ? 'annually' : 'monthly'}`}>${price}</div>
                <div className='package__trial'>{buttonDetail}</div>
                <ul className='package__list'>
                    {included && included.map((items, index) => (
                        <li key={index} className='package__list-item'>
                            <strong>{items}</strong>
                        </li>
                    ))}
                </ul>
            </div>
        </>

    );
};

export default PriceCard;