import React from 'react';
import { Link } from 'react-router-dom';
import ExtensionInstructions from '../Messages/ExtensionInstructions';

interface propTypes {
  instructions?: boolean;
}

const Message = ({ instructions }: propTypes) => {
  const filterApplied = window.location.search.length || false;

  return (
    <div>
      {(!instructions || filterApplied) && <h4>No Data Found</h4>}

      {instructions && !filterApplied && (
        <>
          <h4>You don’t have any Scraps :(</h4>
          <p>
            Use the Scrappi browser extension to start collecting Scraps!{' '}
            <Link to="/help">
              Visit our Help section to set up the browser extension.
            </Link>
          </p>
        </>
      )}
    </div>
  );
};

export default Message;
