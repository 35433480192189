const Account_Messages = {
  login: 'Log in',
  signIn: 'Sign up',
  googleButtonLabel: 'Continue With Google',
  googleLoginLabel: 'Log In With Google',
  facebookButtonLabel: 'Continue With Facebook',
  facebookLoginLabel: 'Log In With Facebook',
  appleButtonLabel: 'Continue With Apple',
  appleLoginLabel: 'Log In With Apple',
  accountNotExists: 'Account not exists. Please register first and then login.',
  codeLabel: 'Get a Code',
  backToLogin: 'Back to Log In',
  accountNotFound: 'The account was not found',
  createAccount: 'Create Account',
  createAccountDescription:
    'Would you like to create a new account or return back to the login screen?',
  createAccountError:
    'Unable to register your account. Please try again by going back.',
};

const accountNotExistErrorCode = 422;

export default Account_Messages;
export { accountNotExistErrorCode };
