import axios from 'axios';
import { getToken } from "../../../apollo/getToken";
import * as config from "../../../settings.json";
import { scrapType } from "../../../types/scrapType";

export const getScrapImageTags = async (
  image: string
) => {
  const token = getToken();
  return await axios.post(
    "https://image-dev.scrap-crawler.workers.dev/",
    { image },
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
  );
};
