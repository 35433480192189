import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int'],
]);

const DeleteScrap = gql`
mutation deleteScrap(${parametersTypeString}){
       deleteScrap(${parametersAssignString}){
         ${defaultResponse}   
       }
     } 
`

export {
  DeleteScrap
};
