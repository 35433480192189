import React from 'react';
import { scrapType } from '../../../../types/scrapType';
import { onClickType } from '../../../ScrapCard';

export interface Type {
  highlighted_text: String;
}
interface PropTypes {
  highlightsCount?: number;
  text?: Array<Type>;
  scrap?: scrapType;
  label: String;
  showMore?: onClickType;
  onMoreClick?: (
    onClickType: onClickType,
    currentlyOpened?: boolean
  ) => boolean;
  setShowViewAll?:(boolean)=>void;
}

const ViewAllHighlights = ({
  highlightsCount,
  onMoreClick,
  label,
  showMore,
  setShowViewAll
}: PropTypes) => {
  return (
    <>
      <div
        className="highlights__view-all-highlights hoverState__with-white-text "
        onClick={(e) => {
          e.stopPropagation();
          onMoreClick && onMoreClick(showMore);
          setShowViewAll && setShowViewAll(false);
        }}
      >
        {`${label} (${highlightsCount && highlightsCount})`}
      </div>
    </>
  );
};

export default ViewAllHighlights;
