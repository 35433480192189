import React from 'react';
import { entryTypes } from '../../../types/feed';
import ResponsiveImage from '../../Global/ResponsiveImage/';
import RenderEmbed from './RenderEmbed';

interface PropType {
  meta: string;
  preview?: boolean;
  entry?: entryTypes;
}

interface TweetProps {
  tweetId: number;
}

export const Twitter = ({ meta, preview, entry }: PropType) => {
  // parse the meta data for twitter
  const parsedMeta: TweetProps = JSON.parse(meta);
  let tweetId = '';
  if(typeof parsedMeta === 'string'){
    let parseMeta = JSON.parse(parsedMeta);
    tweetId = parseMeta.tweetId;
  }
  else{
    tweetId = parsedMeta.tweetId.toString();
  }
  
  if (entry && preview) {
    return (
      <div>
        <ResponsiveImage sizes={['800']} image={entry.thumbnail} alt={entry.title} imageClass="" />
      </div>
    );
  }

  return (
    <RenderEmbed
      href={`https://twitter.com/username/status/${tweetId}`}
    />
  );
};
