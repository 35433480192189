/* eslint-disable no-case-declarations */
// REACT IMPORTS
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

// COMPONENTS IMPORTS
import GroupDetailsPopup, {
  GroupDetailsWarning,
} from '../../components/GroupDetailsPopup';
import NewPopup from '../../components/Global/NewPopup';
import SearchModal from '../../components/Global/SearchModal';
import ShareList from '../../components/Global/ShareList';

// HELPERS IMPORTS
import { useLodashDebounce } from '../../helpers/customHooks/useDebounce';
import userInitials from '../../helpers/userInitial';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';

// TYPESCRIPT TYPES IMPORTS
import { RequestStatusObjectType } from '../../types/requestStatusType';
import {
  ContactWithCollectionFlag,
  ContactWithGroupFlag,
  GroupPopupDetailsRendererPropTypes,
  groupDetailsWarningType,
} from './types';
import { GroupMemberType, GroupType } from '../GroupShareContainer/types';
import { InputFieldType } from '../../types/inputFieldType';

const MemoizedShareList = React.memo(ShareList);

export default function GroupDetailsPopupRenderer({
  dataType,
  isReadOnly,
  group,
  isloadingGroup,
  isLoadingList,
  list,
  masonryKey,
  onClose,
  show,
  onLoadMore,
  onSearch,
  currentlyDisplayedList,
  onMemberRemove,
  onSetOwner,
  onSubmit,
  dontRedirectOnMerge,
  onFocus,
  onCurrentModeChange,
  onNickNameSave,
}: GroupPopupDetailsRendererPropTypes) {
  // STATES
  const [status, setStatus] = useState<RequestStatusObjectType>({
    // STATE FOR SHOWING PROCESSING UI AND ERROR MESSAGE
    status: 'not-started',
  });
  const [warning, setWarning] = useState<groupDetailsWarningType>(null); // STATE FOR SHOWING WARNING POPUP IF DEFINED
  const [searchTerm, setSearchTerm] = useState(''); // STORES SEARCH VALUE WRITTEN BY USER ON SEARCH BAR
  const [nickName, setNickName] = useState<InputFieldType<string>>({
    // STATE FOR STORE NICKNAME OF GROUP ENTERED BY USER
    value: group?.nickname,
    gotChanged: false,
  });
  const [selected, setSelected] = useState<
    // STATE WHICH STORES THE SELECTED MEMBERS
    GroupType['members'] | ContactWithGroupFlag[] | ContactWithCollectionFlag[]
  >([]);

  // ROUTER HOOKS
  const history = useHistory();
  const { replace } = history || {};

  // DEBOUNCE THE SEARCHING FUNCTIONALITY
  const debouncedSearch = useLodashDebounce(function () {
    onSearch(searchTerm);
  }, 200);
  /**
   * FUNCTION RESPONSIBLE TO UPDATE THE SEARCHTERM STATE AND SHOW FILTERED RESULTS
   * @param value
   */
  const searchChangeHandler = (value: string) => {
    setSearchTerm(value);
    debouncedSearch();
  };
  const nicknameSaveHandler = async () => {
    try {
      setStatus({ status: 'processing' });
      await onNickNameSave(nickName.value);
      setStatus({ status: 'success' });
      setNickName(old => ({
        ...old, gotChanged: false,
      }))
    } catch (error) {
      setStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  }

  /**
   * FUNCTION WHICH IS CALLED WHEN USER CLICKS ON SAVE BUTTON OF GROUP DETAILS POPUP
   */
  const submitHandler = async () => {
    try {
      if(status.status === 'processing') return;
      if (warning) return warningProceedHandler();
      setStatus({ status: 'processing' });
      const data = await onSubmit(selected);
      if (data)
        setWarning({
          type: 'merge-groups',
          existingGroup: data.data.existing,
          responseMessage: data.message,
          cb: async () => {
            await onSubmit(selected, true);
            !dontRedirectOnMerge && replace(`/shares/${data.data.existing.id}`);
            onClose();
          },
        });
      setStatus({ status: 'success' });
    } catch (error) {
      setStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  };

  /**
   * FUNCTION USED FOR UPDATING NICKNAME AND STORING IT IN NICKNAME STATE
   * @param value VALUE ENTERED BY USER ON NICKNAME FIELD
   */
  const nickNameChangeHandler = (value: string) => {
    if(status.status === 'processing') return;
    setNickName({
      value,
      gotChanged: (group?.nickname || '') !== value,
    });
  };

  /**
   * FUNCTION WHICH GETS CALLED WHEN USER PROCEEDS FROM WARNING POPUP
   */
  const warningProceedHandler = useCallback(async () => {
    if (!warning) return;
    setStatus({ status: 'processing' });
    try {
      switch (warning.type) {
        case 'set-owner':
          await onSetOwner(warning.newOwner);

          break;
        case 'remove-member':
          const data = await onMemberRemove(warning.member);
          if (data) {
            setWarning({
              type: 'merge-groups',
              existingGroup: data.data.existing,
              responseMessage: data.message,
              async cb() {
                try {
                  await onMemberRemove(warning.member, true);
                  !dontRedirectOnMerge && replace(`/shares/${data.data.existing.id}`);
                  onClose();
                } catch (error) {
                  setStatus({
                    status: 'error',
                    message: parseTryCatchError(error),
                  });
                }
              },
            });
            setStatus({ status: 'success' });
            return;
          } else if (dataType === 'group' && (group as GroupType).members_count === 1) {
            onClose();
            !dontRedirectOnMerge && replace('/');
          }
          break;
        case 'merge-groups':
          await warning.cb();
          break;

        default:
          break;
      }
      setWarning(null);
      setStatus({ status: 'success' });
    } catch (error) {
      setStatus({ message: parseTryCatchError(error), status: 'error' });
    }
  }, [warning, dontRedirectOnMerge]);

  /**
   * FUNCTION WHICH GETS CALLED WHEN USER CLICKS ON MEMBERS/CONTACTS
   * IT ONLY SELECTS/UNSELECTS THE CONTACTS WHICH ARE NOT PART OF GROUP
   * @param item CONTACT DATA ON WHICH USER CLICKS
   * @param type STRING, IF ITS VALUE IS "current-members", THEN FUNCTION IS RETURNED FROM FIRST LINE SINCE IT IS ALREADY A MEMBER OF GROUP
   */
  const itemClickHandler = (
    item: ContactWithGroupFlag | ContactWithCollectionFlag,
    type: GroupPopupDetailsRendererPropTypes['currentlyDisplayedList']
  ) => {
    if(dataType === 'group') {
      const dataItem = item as ContactWithGroupFlag
      if (
        dataItem.is_group_member ||
        dataItem.is_group_owner ||
        type === 'current-members'
      )
        return;
      setSelected((old: GroupMemberType[]) => {
        if (!old?.find((data) => data.id === dataItem.id)) {
          return [...old, dataItem];
        } else
          return old.filter(function (data) {
            return data.id !== dataItem.id;
          });
      });
    } else {
      const dataItem = item as ContactWithCollectionFlag
      if (
        dataItem.is_collection_member ||
        dataItem.is_collection_owner ||
        type === 'current-members'
      )
        return;
      setSelected((old: GroupMemberType[]) => {
        if (!old?.find((data) => data.user_id === dataItem.user_id)) {
          return [...old, dataItem];
        } else
          return old.filter(function (data) {
            return data.user_id !== dataItem.user_id;
          });
      });
    }
    
  };

  /**
   * FUNCTION FOR RENDERING CONTACT/MEMBER IN LIST
   */
  const renderListItem = useCallback(
    (listItem: ContactWithGroupFlag | ContactWithCollectionFlag) => {
      let renderRight;
      
      if (listItem[dataType === 'group' ? 'is_group_owner' : 'is_collection_owner']) renderRight = 'Owner';
      else if (
        !isReadOnly &&
        (listItem[dataType === 'group' ? 'is_group_member' : 'is_collection_member'] ||
          currentlyDisplayedList === 'current-members')
      ) {
        renderRight = {
          type: 'dropdown',
          text: dataType === 'group' ? 'Member' : 'Collaborator',
          dropDownOptions: dataType === 'group' ? ['setOwner', 'remove'] : ['remove'],
          onDropDownOptionClick: (val) => {
            if (val === 'remove')
              setWarning({
                type: 'remove-member',
                member: listItem,
              });
            else if (val === 'setOwner')
              setWarning({
                type: 'set-owner',
                newOwner: listItem,
              });
          },
        };
      }
      let onClick = itemClickHandler.bind(
        null,
        listItem,
        currentlyDisplayedList
      );
      if (
        isReadOnly ||
        listItem[dataType === 'group' ? 'is_group_member' : 'is_collection_member'] ||
        listItem[dataType === 'group' ? 'is_group_owner' : 'is_collection_owner'] ||
        currentlyDisplayedList === 'current-members'
      )
        onClick = null;
      return (
        <MemoizedShareList
          renderRight={renderRight}
          name={listItem?.display_name}
          id={listItem?.user_id?.toString()}
          type={'contact'}
          avatar={listItem?.avatar}
          user_name={listItem?.user_name}
          creator_initials={userInitials(listItem?.display_name)}
          key={listItem.user_id + listItem.display_name}
          className="group-share__member-list"
          onClick={onClick}
          isActive={selected?.some((data) => listItem.user_id == data.user_id)}
        />
      );
    },
    [currentlyDisplayedList, group, list, selected, dataType]
  );

  // CLEANS UP THE COMPONENTS STATES, AND UPDATES THE VALUE OF THEM ACCORDINGLY
  useEffect(() => {
    setStatus({
      status: 'not-started',
    });
    setWarning(null);
    setNickName({
      value: group?.nickname,
      gotChanged: false,
    });
    setSelected([]);
    searchChangeHandler('');
  }, [group]);

  // Effect clears the error message after 3 seconds
  useEffect(() => {
    if(status?.status === 'error') {
      const timeoutKey = setTimeout(() => {
        setStatus({status: 'not-started'})
      }, 3000)
      return () => {
        window.clearTimeout(timeoutKey);
      }
    }
  }, [status])

  const groupMembers: ContactWithGroupFlag[] | ContactWithCollectionFlag[] = useMemo(
    () =>
      group?.members
        ? [
          {
            user_id: group.user_id,
            display_name: group[dataType === 'group' ? 'owner_display_name' : 'display_name'],
            avatar: group[dataType === 'group' ? 'owner_avatar' : 'avatar'],
            user_name: group[dataType === 'group' ? 'owner_user_name': 'user_name'],
            [dataType === 'group' ? 'is_group_owner' : 'is_collection_owner']: true,
          },
          ...group.members,
        ]
        : [],
    [group, dataType]
  );

  let submitLabel = 'Save';
  if (warning) {
    const { type } = warning;
    if (type === 'set-owner') submitLabel = 'Yes';
    else if (type === 'remove-member') submitLabel = 'Yes';
    else submitLabel = 'Merge';
  } else if(currentlyDisplayedList === 'all-groups-and-contacts') submitLabel = 'Add';
  let popupHeading = dataType === 'group' ? 'Share Group' : 'Collection Collaborators';

  if(warning?.type === 'merge-groups') popupHeading = 'Share';
  if(!warning && currentlyDisplayedList === 'all-groups-and-contacts') popupHeading = 'Add members';
  let disableSave = true;
  if(selected.length) disableSave = false;
  // else if(nickName.gotChanged) disableSave = false;
  else if(warning) disableSave = false;

  return (
    <NewPopup
      className="group-details__popup"
      header={{
        heading: popupHeading,
      }}
      controlled={{
        show,
        setShow: (val) => {
          onClose();
        },
      }}
      footer={{
        disableSubmit: disableSave,
        onSubmit: submitHandler,
        hideSubmit: currentlyDisplayedList === 'current-members' && !warning,
        submitLabel,
        cancelLabel: (currentlyDisplayedList === 'current-members' && !warning) ? 'Close' : 'Cancel',
        onCancelClick(close) {
          if(currentlyDisplayedList === 'all-groups-and-contacts' && !warning) {
            onCurrentModeChange('current-members');
            searchChangeHandler('');
            setSelected([]);
            return;
          }
          if (warning) return setWarning(null);
          close();
        },
        spinnerConfig: {
          show: status?.status === 'processing',
          label: 'Processing',
        },
      }}
      size='Small'
    >
      {() => (
        <GroupDetailsPopup
          showAdd={currentlyDisplayedList === 'current-members'}
          onAddClick={() => onCurrentModeChange('all-groups-and-contacts')}
          masonryKey={masonryKey}
          isLoadingGroup={isloadingGroup}
          isLoadingList={
            currentlyDisplayedList === 'all-groups-and-contacts'
              ? isLoadingList
              : isloadingGroup
          }
          errorMessage={status?.status === 'error' ? status.message : null}
          warningRenderer={
            warning && <GroupDetailsWarning dataType={dataType} group={group} warning={warning} />
          }
          searchInputRenderer={
            !isReadOnly && (
              <SearchModal
                label='Search'
                placeholder="Search and Add Contacts"
                onChange={searchChangeHandler}
                value={searchTerm}
                onFocus={onFocus}
              />
            )
          }
          list={
            currentlyDisplayedList === 'all-groups-and-contacts'
              ? list
              : groupMembers
          }
          onLoadMore={onLoadMore}
          onNickNameSave={(nickName.gotChanged && status.status !== 'processing') ? nicknameSaveHandler : null}
          nickNameInputRenderer={
            (dataType === 'group' && (group as GroupType)?.members_count > 1) && (
              <SearchModal
                label='Nickname'
                disabled={isReadOnly || status.status === 'processing'}
                placeholder="Add a Nickname for this Share Group"
                onChange={nickNameChangeHandler}
                value={nickName.value}
              />
            )
          }
        >
          {renderListItem}
        </GroupDetailsPopup>
      )}
    </NewPopup>
  );
}
