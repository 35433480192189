import React from 'react';
import Avatar from '../Avatar';
import GroupShow from '../GroupShow';
import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import { userTypes } from '../../../types/user';
import { GroupType } from '../../../containers/GroupShareContainer/types';
import MyDropDown from '../MyDropDown';
import TripleDot from '../icons/TripleDot';
import { setShowMember } from '../../../redux/action/utils';
import { returnAllGroupMembersExceptMe } from '../../../helpers';

type ShareListDropdownOptionTextType = 'setOwner' | 'remove';

type dropdownType = {
  valueField: ShareListDropdownOptionTextType;
  textField: string;
  className?: string;
};

const dropdownOptionsMapper: {
  [key in ShareListDropdownOptionTextType]: dropdownType;
} = {
  remove: {
    valueField: 'remove',
    textField: 'Remove',
  },
  setOwner: {
    valueField: 'setOwner',
    textField: 'Set Owner',
  },
};
interface Proptypes {
  state?: number;
  name: string;
  id: string;
  user_name?: string;
  avatar?: string;
  type?: string;
  creator_initials: string;
  className?: string;
  onClick?: (val: string, type: string) => void;
  // selected?: Array<Partial<GroupType>>;
  isActive?: boolean;
  members?: GroupType['members'];
  owner_display_name?: string;
  owner_name?: string;
  nickname?: string;
  renderRight?:
    | string
    | {
        type: 'dropdown';
        text: string;
        dropDownOptions: ShareListDropdownOptionTextType[];
        onDropDownOptionClick: (val: ShareListDropdownOptionTextType) => void;
      };
}

const ShareList = ({
  state,
  name,
  id,
  avatar,
  creator_initials,
  user_name,
  type,
  className,
  onClick,
  isActive,
  members,
  owner_display_name,
  owner_name,
  nickname,
  renderRight,
}: Proptypes) => {
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateType) => state.user as userTypes);
  const showMemberName = (
    memberName: string,
    ownerName: string,
    typeCheck: string
  ) => {
    if (user[typeCheck] === memberName) {
      return ownerName;
    } else {
      return memberName;
    }
  };
  const rightRenderer = () => {
    if (typeof renderRight === 'string')
      return <span className="share-list__right-text">{renderRight}</span>;
    if (renderRight.type === 'dropdown') {
      const { dropDownOptions, onDropDownOptionClick } = renderRight;
      const dropdownList: dropdownType[] = [];
      if (dropDownOptions?.length) {
        dropDownOptions.forEach((item) => {
          dropdownList.push(dropdownOptionsMapper[item]);
        });
      }
      return (
        <>
          <span className='share-list__right-dropdown-text'>{renderRight.text}</span>
          <MyDropDown<dropdownType>
            onOptionClick={(val) => onDropDownOptionClick(val.valueField)}
            dropDownList={dropdownList}
            customButton={{
              customRender: (showDropDownListHandler) => {
                return (
                  <TripleDot
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      showDropDownListHandler();
                    }}
                  />
                );
              },
            }}
          />
        </>
        
      );
    }
  };
  return (
    <div
      className={`share-list${onClick ? ' share-list--clickable' : ''} ${className || ''} ${
        isActive ? 'share-list__selected' : ''
      }${renderRight ? '' : ' share-list--single-child'}`}
      onClick={() => onClick && onClick(id, type)}
    >
      {((type === 'contact' || members?.length === 1) && type !== 'collection') ? (
        <>
          <div className={`share-list__left`}>
            <Avatar
              avatar={
                members?.length === 1
                  ? showMemberName(members[0].avatar, avatar, 'avatar')
                  : avatar
              }
              className="share-list__avatar"
            />

            <span className="share-list__name">
              {members?.length === 1
                ? showMemberName(
                  members[0].display_name,
                  owner_display_name,
                  'display_name'
                )
                : name}
              {(user_name || members?.length === 1) && (
                <span className="share-list__user-name">
                  @
                  {members?.length === 1
                    ? showMemberName(
                      members[0]?.user_name,
                      owner_name,
                      'userName'
                    )
                    : user_name}
                </span>
              )}
            </span>
          </div>

          {renderRight && (
            <div className="share-list__right">{rightRenderer()}</div>
          )}
        </>
      ) : (
        <GroupShow
          state={state}
          members={returnAllGroupMembersExceptMe(members, user.userName, 'user_name')}
          groupName={nickname}
          type={type}
          showAllMembers={() => {
            dispatch(
              setShowMember({
                member: members,
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default ShareList;
