import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const LeftArrow = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        d="M3.828 6.9782H16V8.9782H3.828L9.192 14.3422L7.778 15.7562L0 7.9782L7.778 0.200195L9.192 1.6142L3.828 6.9782Z"
        fill="white"
      />
    </svg>
  );
};

export default LeftArrow;
