import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const AppleIcon = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M35.3865 28.8903C35.3544 25.353 38.3108 23.632 38.446 23.5519C36.7717 21.134 34.1765 20.8037 33.2645 20.7773C31.0849 20.5501 28.9706 22.0691 27.8603 22.0691C26.7278 22.0691 25.018 20.7993 23.1752 20.8366C20.8038 20.8728 18.5854 22.2327 17.3687 24.3443C14.8577 28.65 16.7304 34.9773 19.1361 38.4576C20.3395 40.1621 21.7457 42.0652 23.5863 41.9983C25.387 41.9247 26.0596 40.8612 28.2326 40.8612C30.3856 40.8612 31.0173 41.9983 32.8944 41.9555C34.8269 41.9247 36.0436 40.2433 37.2049 38.5235C38.5956 36.5698 39.1541 34.6459 39.1763 34.5471C39.1308 34.5317 35.4231 33.1301 35.3865 28.8903Z"
        fill="#3C3C3C"
      />
      <path
        d="M31.8406 18.4878C32.8091 17.2882 33.4717 15.6562 33.2878 14C31.886 14.0615 30.133 14.9603 29.1235 16.1336C28.2304 17.1675 27.4325 18.8621 27.6386 20.4557C29.2133 20.5721 30.83 19.6688 31.8406 18.4878Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default AppleIcon;
