import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const Nut = ({ className }: Proptypes) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M6.8919 1.63455C9.68509 0.263003 15.0051 -1.59478 17.3524 2.49332C17.3524 2.49332 17.5332 2.75519 17.5403 3.04077C17.5446 3.22747 17.4749 3.4241 17.2309 3.56574L2.16223 12.3139C1.54614 12.6716 1.17964 11.8824 1.17964 11.8824C-1.15881 7.79744 3.08241 4.10149 5.66199 2.34976L5.29362 1.70819C5.2389 1.61288 5.14583 1.54454 5.03686 1.51779C4.92789 1.49104 4.81132 1.50812 4.71127 1.5662L3.61249 2.2041C3.44926 2.29887 3.25732 2.32734 3.07788 2.28385C2.8982 2.23991 2.74711 2.12759 2.65692 1.97051C2.59739 1.86681 2.56921 1.74845 2.57094 1.62919C2.57143 1.5689 2.58071 1.50762 2.59779 1.44769C2.65005 1.26969 2.77054 1.11678 2.93378 1.02201L4.18526 0.295464C4.37658 0.184393 4.58517 0.111541 4.80248 0.0783377C5.01828 0.0465947 5.23879 0.0556556 5.44818 0.106476C5.57401 0.138143 5.69639 0.184171 5.81029 0.24395C5.88386 0.282424 5.95571 0.326024 6.02179 0.375906C6.19254 0.502103 6.33353 0.662055 6.43841 0.844729L6.8919 1.63455Z"
        fill="#1A404C"
      />
      <path
        d="M3.93432 12.49C4.8259 13.9694 5.82359 15.4258 6.75111 16.279C9.05964 18.4043 12.2317 18.141 13.4796 17.6813C13.6632 17.6138 13.9389 17.6946 14.243 17.784C14.6281 17.8964 15.0568 18.0234 15.4049 17.8796C15.7045 17.6474 15.8096 17.211 15.9036 16.8202C15.9786 16.5108 16.0468 16.23 16.1984 16.1028C17.2165 15.247 19.0276 12.6174 18.3532 9.54341C18.2412 9.03041 18.0445 8.45541 17.7945 7.84893C17.4427 6.99567 16.9834 6.08157 16.4983 5.19596L3.93432 12.49Z"
        fill="#1A404C"
      />
    </svg>
  );
};

export default Nut;
