function getBase64FromUrl (url: string) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.responseType = 'blob';
    // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    // via Cache-Control header:
    xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
    
    // fallbacks for IE and older browsers:
    xhr.setRequestHeader("Expires", "Tue, 01 Jan 1980 1:00:00 GMT");
    xhr.setRequestHeader("Pragma", "no-cache"); //Edit: I noticed this is required for Chrome some time ago... forgot to mention here
    
    xhr.onload = function () {
      if (this.status === 200) {
        var blob = this.response;
        var fileReader = new FileReader();  
        fileReader.onloadend = function (e) {
          var result = e.target.result;
          resolve(result);
        };
        fileReader.onerror = function (e) {
          reject()
        }
        fileReader.readAsDataURL(blob);
      } else reject();
    };
    xhr.onerror = function (e) {
      reject();
    };
    xhr.send();
  });
}

export default getBase64FromUrl;