import React, { useEffect, useState } from "react";
import NewPopup from "../../Global/NewPopup";
import { getScrapSummaryContent } from "./helpers/getScrapSummary";
import { useDispatch } from "react-redux";
import { canPaste } from "../../../redux/action/pasteDataAction";
import { Link } from "react-router-dom";
import MarkdownReader from "../../Chat/MarkdownReader";

interface Proptypes {
  showAISummary: boolean;
  setShowAISummary: (value: boolean) => void;
  url: string;
  userId: number;
}

const ScrapCardAISummary = ({
  showAISummary,
  setShowAISummary,
  url,
  userId
}: Proptypes) => {
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState(null);
  const [tagList, setTagList] = useState(null);
  const [topicList, setTopicList] = useState(null);
  const [readHTML, setReadHTML] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [html, setHtml] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if(!url) return;

    const statusMessages = {
      'not-started': 'The AI Summary is not available for this Scrap.',
      'queued-for-content': 'AI Summarization in progress. Please check back in a few minutes.',
      'content-failed': 'The AI Summary is not available for the content in this Scrap.',
      'summarizing': 'AI Summarization in progress, please check back in a few minutes.',
      'final-summary': 'AI Summarization in progress, please check back in a few minutes.',
      'failed': 'Unable to create an AI Summary for this Scrap.',
    }

    setLoading(true);
    getScrapSummaryContent(url, userId).then((response) => {
      // @ts-ignore
      const summary = response.data?.summary;
      if(summary) {
        // @ts-ignore
        setSummary(summary)

        // @ts-ignore
        const tags = response.data?.tagging?.tags || null;
        setTagList(tags);

        // @ts-ignore
        const topics = response.data?.tagging?.topics || null;
        setTopicList(topics);
      } else {
        // @ts-ignore
        const status = response.data?.status;
        if(statusMessages[status]) {
          setSummary(statusMessages[status]);
        } else {
          setSummary('AI Summarization in progress. Please check back in a few minutes.');
        }
      }

      // @ts-ignore
      const htmlContent = response.data?.html;
      if(htmlContent) {
        setHtml(htmlContent);
      }

      // still have content when the summary is not available
      //@ts-ignore
      if(response.data?.content) {
        //@ts-ignore
        setContent(response.data?.content);
      }
    }).catch(
      (error) => {
        console.log(error);
      }
    ).finally(() => {
      setLoading(false);
    });
  }, [url]);

  const isServer = typeof window === 'undefined';

  return (
    <NewPopup
      size="Default"
      header={{
        heading: 'AI Summary',
      }}
      controlled={{
        show: showAISummary,
        setShow: (status) => {
          if (!status) {
            dispatch(canPaste(true));
          }
          setShowAISummary(status);
        },
      }}
      footer={{
        leftRender: () => (
          <button
            onClick={() => setReadHTML(!readHTML)}
            className="ui-button ui-button--outlined-with-background new-popup__footer-cancel"
          >
            HTML
          </button>
        ),
        submitLabel: readMore ? 'Summarize' : content ? 'Read Article' : '',
        onSubmit: async () => {
          setReadMore(!readMore);
          setReadHTML(false);
        },
        hideSubmit: !content,
        cancelLabel: 'Close',
      }}
    >
      <div className="scrap-card__ai-summary">
        {!readMore &&
          !readHTML &&
          (loading ? (
            'Loading...'
          ) : (
            <>
              <MarkdownReader markdown={summary} shouldLoadMarkdown={!isServer} />
            </>
          ))}

        {readHTML && (
          <iframe
            className="ai__iframe"
            title="Page HTML"
            srcDoc={html}
          ></iframe>
        )}

        {readMore && !readHTML && (
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        )}

        {topicList && (
          <div className="scrap-card__ai-tags">
            {topicList.map((topic: string) => (
              <Link className="scrap-card__ai-tag" key={topic} to={`/search?mode=personal&type=scraps&text=${topic}`}>
                {topic}
              </Link>
            ))}
          </div>
        )}
      </div>
    </NewPopup>
  );
}

export default ScrapCardAISummary;