import React from 'react';
import { render, hydrate } from 'react-dom';
import 'reactjs-popup/dist/index.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Fix: Expected server HTML to contain a matching <a> in
const renderMethod = module.hot ? render : hydrate;
renderMethod(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
