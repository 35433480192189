import React from 'react';

import { parseSocialMeta } from '../../../helpers/parseSocialMeta';

interface PropType {
  meta: string;
}

interface TikTokProps {
  ttUrl?: string;
}

export const TikTok = ({ meta }: PropType) => {
  // parse the meta data
  let parsedMeta: TikTokProps = parseSocialMeta(meta);
  const tiktokId = parsedMeta.ttUrl;

  return (
    <>
      <iframe
        src={"https://www.tiktok.com/embed/v2/" + tiktokId + "?lang=en-GB"}
        scrolling="no" height="700" frameBorder="0" style={{ height: '750px', width: '350px' }} //WIDTH IS WRITTEN BUT NO APPLIED BECAUSE IFRAME HAS CLASS THAT MAKES WIDTH 100%
      ></iframe>
    </>
  );
};
