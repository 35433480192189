import React, { useRef, useState } from "react";
import AIScrappTagging from "./Components/AIScrappTagging";
import './aiStyles.css';

const AI = () => {
  const [scrapId, setScrapId] = useState(null);
  const [username, setUsername] = useState(null);
  const [privateKey, setPrivateKey] = useState(null);

  // define scrapUrl ref
  const scrapUrlInput = useRef(null);

  // given a URL like this: https://dev.scrappi.com/larrylarry/scrap/0/61736/7pqF8SKXxzB0N0vijkBT
  // the data after scrap/0/ is the scrapId
  // take the value of the scrapUrl input and set the scrapId
  // after app.scrappi.com/ there is username
  // and the last part is the private key
  const handleSubmit = (e) => {
    e.preventDefault();
    const scrapUrl = scrapUrlInput.current.value;
    const scrapUrlParts = scrapUrl.split("/");
    console.log(scrapUrlParts);
    const scrapId = scrapUrlParts[6];
    const username = scrapUrlParts[3];
    const privateKey = scrapUrlParts[7];
    setScrapId(scrapId);
    setUsername(username);
    setPrivateKey(privateKey);
  };


  return (
    <div className="ai__wrapper">
      <form onSubmit={handleSubmit}>
        <input placeholder="Paste Scrap share URL & hit enter." className="ai__search" type="text" ref={scrapUrlInput} />
      </form>

      {scrapId && (
        <div className="ai__result">
          <p>Scrap ID: {scrapId}</p>
          <AIScrappTagging scrapId={scrapId}  username={username} privateKey={privateKey} />
        </div>
      )}
    </div>
  );
};

export default AI;