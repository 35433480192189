// This function adjust each item in the grid accordlying
// with their height.
// Each item has to have an inner container, used to calculate
// its overflow. Check GridItem component for an example.
const getRowSpan = (grid, item) => {
  let rowHeight = parseInt(
    window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')
  );
  let rowGap = parseInt(
    window.getComputedStyle(grid).getPropertyValue('grid-row-gap')
  );
  let itemHeight = Math.ceil(item.getBoundingClientRect().height);
  let rowSpan = Math.ceil((itemHeight + rowGap) / (rowHeight + rowGap));
  return rowSpan;
};

export const adjustGridItemsHeight = (grid) => {
  const items = grid.children;
  for (let i = 0; i < items.length; i++) {
    items[i].style.gridRowEnd = 'span ' + getRowSpan(grid, items[i]);
  }
};

export const adjustGridItem = (grid, index, oldIndex, newIndex) => {
  const items = grid.children;
  const holdItemHeight = getRowSpan(grid, items[index]);

  if (index === newIndex) {
    items[index].style.gridRowEnd = 'span ' + holdItemHeight;
    //RESET OLD INDEX ELEMENT HEIGHT
    const oldIndexHeight = getRowSpan(grid, items[oldIndex]);
    items[oldIndex].style.gridRowEnd = 'span ' + oldIndexHeight;
  } else {
    items[newIndex].style.gridRowEnd = 'span ' + holdItemHeight;
  }

  //GRID HEIGHT SETUP
  if(index === 0 && newIndex > 0 || index < newIndex){
    for (let i = 0; i < items.length -1 ; i++) {
      if(i !== newIndex)
        items[i].style.gridRowEnd = 'span ' + getRowSpan(grid, items[i+1]);
    }
  }
  else if(index > newIndex){
    for (let i = newIndex + 1; i < items.length; i++) {
      items[i].style.gridRowEnd = 'span ' + getRowSpan(grid, items[i-1]);
    }
  }
};
