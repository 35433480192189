import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['itemPriceId', 'String!'],
  ['unitPrice', 'String!']
]);
export default function GET_STRIPE_CHECKOUT() {

  return gql`
    query getStripeCheckoutForSubscriptionItem(${parametersTypeString}) {
        getStripeCheckoutForSubscriptionItem(${parametersAssignString}) {
            data{
                id
                url
                state
                type
                object
                }
        ${defaultResponse}
      }
    }
  `;
}
