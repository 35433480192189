import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { parseNote } from '../../../../helpers';
import { charLimits } from '../../../../helpers/config';
import TiptapEditor from '../../../Global/Tiptap/TiptapEditor';

import './styles.css';
import { PlusIcon } from '../../../Global/icons';

interface Proptypes {
  hideAllTools?: boolean;
  onCloseClick?: () => void;
  label?: string;
  description: string;
  onChange: (val: string) => void;
  placeholder?: string;
  limitConfig?: {
    charLimit: number;
    setDescCharLimitExceeds: Dispatch<SetStateAction<boolean>>;
  };
  disabled?: boolean;
  setCharLength?: (updatedValue: number) => void;
  autoFocus?: boolean;
}
const ScrapDescription = (props: Proptypes) => {
  const {
    hideAllTools,
    description,
    onChange,
    placeholder,
    limitConfig,
    disabled,
    setCharLength: setLimitNote,
    label,
    onCloseClick,
    autoFocus,
  } = props;
  const { charLimit, setDescCharLimitExceeds } = limitConfig || {};
  const [charLength, setCharlength] = useState(null);
  const setCharLength = setLimitNote ? setLimitNote : setCharlength;
  const [focus, setFocus] = useState(false);
  useEffect(() => {
    if (limitConfig) {
      setDescCharLimitExceeds(charLength > charLimit);
    }
  }, [limitConfig, charLength]);
  useEffect(() => {
    onChange(parseNote(description));
  }, []);

  if (disabled && !description) return null;

  return (
    <div className="edit-description">
      <TiptapEditor
        hideAllTools={hideAllTools}
        autoFocus={autoFocus}
        label={label}
        text={parseNote(description)}
        edit={!disabled}
        onChange={(content) => onChange(content)}
        placeholder={placeholder}
        animated
        setCharLength={setCharLength}
        onBlur={() => setFocus(false)}
        charLimit={limitConfig?.charLimit}

      />
      {onCloseClick && (
        <button onClick={onCloseClick} className="edit-description__close">
          <PlusIcon />
        </button>
      )}
      {!disabled && limitConfig && focus && (
        <div className="edit-description__limit">
          <span
            className={`edit-description__limit-tracker${
              (charLength || 0) > charLimit
                ? ' edit-description__limit-tracker-danger'
                : ''
            }`}
          >
            {charLimit - charLength}
          </span>
          {charLength > charLimit && (
            <span className="edit-description__limit-exceeds-message">
              {charLimits['characterLimitReachedMessage']}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ScrapDescription;
