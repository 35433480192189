import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const LinkedIn = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6861 9.2721H13.0287V10.9371C13.5102 9.9795 14.745 9.1191 16.5999 9.1191C20.1558 9.1191 21 11.0253 21 14.5227V21H17.4V15.3192C17.4 13.3275 16.9185 12.2043 15.6927 12.2043C13.9926 12.2043 13.2861 13.4148 13.2861 15.3183V21H9.6861V9.2721V9.2721ZM3.513 20.847H7.113V9.1191H3.513V20.847V20.847ZM7.6287 5.295C7.62883 5.59675 7.56899 5.89551 7.45265 6.17393C7.33631 6.45235 7.16579 6.70486 6.951 6.9168C6.51576 7.34936 5.92663 7.59149 5.313 7.59C4.70045 7.58959 4.11268 7.34808 3.6768 6.9177C3.46279 6.70504 3.29285 6.45224 3.17671 6.17379C3.06057 5.89534 3.00052 5.5967 3 5.295C3 4.6857 3.243 4.1025 3.6777 3.6723C4.1132 3.24134 4.70122 2.99973 5.3139 3C5.9277 3 6.5163 3.2421 6.951 3.6723C7.3848 4.1025 7.6287 4.6857 7.6287 5.295Z"
        fill="#838383"
      />
    </svg>
  );
};

export default LinkedIn;
