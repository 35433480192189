import React from 'react';

import ImagesGroupCarousel from './uiComponents/ImagesGroupCarousel';
import ImagesGroupThumbnail from './uiComponents/ImagesGroupThumbnail';

import './styles.css';
import { scrapType } from "../../../types/scrapType";

interface Proptypes {
  images?: string[];
  mode?: 'thumbnail' | 'carousel';
  imageCount?: number;
  onClick?: () => void;
  children?: React.ReactElement;
  renderImage?: (index: number, defaultRender: React.ReactElement) => React.ReactElement;
  width?: number;
  imageConfig?: scrapType['images'];
}

function ImagesGroup({ images, mode = 'thumbnail',imageCount, onClick,children, renderImage, width, imageConfig }: Proptypes) {
  if (images?.length === 0) return null;
  if (images?.length === 1){
    if(images[0]){
      const fileConfig = imageConfig ? imageConfig[0]?.file_config : null;
      let imageWidth = width - 32;
      let imageHeight : string | number = 'auto';

      try {
        const parsedConfig = JSON.parse(JSON.parse(fileConfig));

        const parsedHeight = parsedConfig?.file_meta?.original?.height;
        const parsedWidth = parsedConfig?.file_meta?.original?.width;

        // considering the image's 100% width is the 'width' prop
        // calculate the ratio based on the original image dimensions
        // and find the height that would make the image 100% width
        if(parsedWidth && parsedHeight) imageHeight = `${(parsedHeight / parsedWidth) * imageWidth}px`;
        else if(parsedHeight) imageHeight = parsedHeight;
      } catch (error) {
        // nothing to do here
      }

      const imageElement = (
        <div
          className={`images-group images-group--single${onClick ? ' images-group--clickable' : ''}`}
          onClick={onClick}
        >
          {children}
          <img
            src={ images[0]}
            alt={images[0]}
            className="images-group__image"
            style={{
              width: width ? `${width - 32}px` : '100%',
              height: imageHeight,
            }}
          />
        </div>
      )
      if(renderImage) return renderImage(0, imageElement)
      return imageElement
    }
    return null

  }

  return (
    <div className={`images-group images-group--${mode}${onClick ? ' images-group--clickable' : ''}`} onClick={onClick}>
      {mode === 'carousel' ? (
        <ImagesGroupCarousel images={images} renderImage={renderImage} />
      ) : (
        <ImagesGroupThumbnail images={images} imageCount={imageCount}/>
      )}
    </div>
  );
}

export {
  ImagesGroup as default,
  ImagesGroupCarousel as ImagesCarousel,
  ImagesGroupThumbnail as ImagesThumbnail,
};
