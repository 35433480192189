import React from 'react';
import Icon from '../../Icon';
import { returnPrice } from '../../../../helpers';

interface Proptypes {
  price: number;
  withDivider?: boolean;
  setEditPrice?: (val) => void;
  editMode?: boolean;
  setEditMode?: (val) => void;
}

export default function WebContentPrice({
  price,
  withDivider,
  editMode,
  setEditMode,
  setEditPrice,
}: Proptypes) {
  if (isNaN(returnPrice(price))) return null;
  return (
    <>
      {!editMode && setEditPrice ? (
        <div className="web-content__edit-price-container">
          <span className={`web-content__dollar-icon }`}>
            <Icon
              iconClass={`currency-dollar ${price ? 'icon-inside' : ''}`}
              size="big"
            />
          </span>
          <input
            onChange={(e) => {
              let price = e.target.value;
              // if (String(editPrice.value).trim() === '') price = null;
              setEditPrice({
                value: price,
                gotChanged: true,
              });
            }}
            value={price}
            defaultValue={null}
            type="text"
            placeholder="Add a Price"
            className={`web-content__edit-price-input ${
              price ? ' price-defined' : ''
            }`}
            onKeyPress={(event) => {
              if (!/^\d+(?:\.\d+)?$/.test(event.key)) {
                const keyCode = event.which ? event.which : event.keyCode;
                if (keyCode === 190 || keyCode === 110 || keyCode === 46) {
                  //FOR . (DOT)
                  if (price.toString().includes('.')) {
                    event.preventDefault();
                  }
                } else {
                  event.preventDefault();
                }
              }
            }}
          />
        </div>
      ) : (
        <span
          className={`web-content__price${
            withDivider ? ' web-content__price--with-divider' : ''
          }`}
        >
          &#x24;{(+price)?.toFixed(2)}{' '}
          {editMode && (
            <span
              onClick={() => {
                setEditMode(false);
                setEditPrice({ value: price });
              }}
              className="web-content__edit-price"
            >
              Edit Price
            </span>
          )}
        </span>
      )}
    </>
  );
}
