import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const AntiClockwise = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C13.8162 0 16.1109 1.34664 17.4071 2.64289C18.7098 3.94562 20 6.19161 20 9C20 11.8162 18.6534 14.1109 17.3571 15.4071C16.0544 16.7098 13.8084 18 11 18C10.4477 18 10 17.5523 10 17C10 16.4477 10.4477 16 11 16C13.1916 16 14.9494 14.9864 15.9429 13.9929C16.9428 12.9929 18 11.1838 18 9C18 6.80838 16.9864 5.05057 15.9929 4.05711C14.9929 3.05716 13.1838 2 11 2C8.80838 2 7.05057 3.01364 6.05711 4.00711C5.24044 4.82377 4.38561 6.18017 4.09968 7.83963L4.87531 7.21913C5.30657 6.87412 5.93586 6.94404 6.28087 7.37531C6.62588 7.80657 6.55596 8.43586 6.12469 8.78087L3.6247 10.7809C3.2268 11.0992 2.6532 11.0674 2.29289 10.7071L0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289C0.683417 6.90237 1.31658 6.90237 1.70711 7.29289L2.09858 7.68437C2.43162 5.47193 3.555 3.68078 4.64289 2.59289C5.94562 1.29016 8.19161 0 11 0Z"
        fill="black"
      />
    </svg>
  );
};

export default AntiClockwise;
