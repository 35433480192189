const userInitials = (display_name: string) => {
  let initials = '';
  if (display_name) {
    let [firstName, LastName] = display_name.split(' ');
    if (firstName) {
      initials += firstName[0]?.toUpperCase();
    }
    if (LastName) {
      initials += LastName[0]?.toUpperCase();
    }else{
      initials += firstName[1]?.toUpperCase();
    }
  }
  return initials;
};

export default userInitials;
