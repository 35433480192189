import { entryTypes } from '../../types/feed';

export const changeBatchEditMode = (value: boolean) => {
  return {
    type: 'SET_BATCH_EDIT',
    payload: { value },
  };
};

export const clearBatchEditScraps = () => ({
  type: 'CLEAR_BATCH_EDIT_SCRAPS',
});

export const updateBatchEditScraps = (scraps: entryTypes[]) => {
  return {
    type: 'UPDATE_SCRAPS_IN_BATCH_MODE',
    payload: { scraps },
  };
};

export const setPreventDeselect = (preventDeselect: boolean) => {
  return {
    type: 'SET_PREVENT_DESELECT',
    payload: { preventDeselect },
  };
};
