import React from 'react';

interface Proptypes {
  className?: string;
}

const PenEditIcon = ({ className }: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.9 0.400024C0.4 0.400024 0 0.800024 0 1.40002V17.1C0 17.6 0.4 18 0.9 18H17.1C17.6 18 18 17.6 18 17.1V12.5C18 12 17.6 11.6 17.1 11.6C16.6 11.6 16.2 12 16.2 12.5V16.2H1.8V2.30002H5.8C6.3 2.30002 6.7 1.90002 6.7 1.40002C6.7 0.900024 6.3 0.400024 5.8 0.400024H0.9Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 2.40005L15.6 0.500049C15 -0.0999512 13.9 -0.0999512 13.3 0.500049L5.30004 8.50005C5.10004 8.70005 5.00004 9.00005 4.90004 9.30005L4.40004 11.7C4.30004 12.2 4.50004 12.8 4.80004 13.2C5.20004 13.6 5.70004 13.7 6.30004 13.6L8.70004 13.1C9.00004 13 9.30004 12.9 9.60004 12.7L17.6 4.70005C18.2 4.10005 18.2 3.00005 17.5 2.40005ZM7.70004 11.7L6.00004 12L6.30004 10.3L6.40004 9.90005L8.10004 11.6L7.70004 11.7ZM15.2 4.70005L9.40004 10.5L7.50004 8.60005L13.3 2.80005L14.4 1.70005L15.2 2.50005L15.6 2.90005L16.4 3.70005L15.2 4.70005Z"
        fill="white"
      />
    </svg>
  );
};

export default PenEditIcon;
