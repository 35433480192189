export const updateAllScrapsViewSettings = (data) => {
  return {
    type: 'UPDATE_ALL_SCRAPS_VIEW_SETTINGS',
    payload: data,
  };
};
export const updateCollectionScrapsViewSettings = (data) => {
  return {
    type: 'UPDATE_COLLECTION_SCRAPS_VIEW_SETTINGS',
    payload: data,
  };
};
export const updateCollectionsViewSettings = (data) => {
  return {
    type: 'UPDATE_COLLECTIONS_VIEW_SETTINGS',
    payload: data,
  };
};
export const updateStoriesViewSettings = (data) => {
  return {
    type: 'UPDATE_STORIES_VIEW_SETTINGS',
    payload: data,
  };
};
export const updateMyScrapsPageViewLayoutSettings = (
  data: 'Gallery' | 'Story' | 'Grid'
) => {
  return {
    type: 'UPDATE_MY_SCRAPS_PAGE_VIEW_LAYOUT_SETTINGS',
    payload: data,
  };
};
export const updateMyScrapsPageSortSettings = (
  data: 'Last Edited' | 'Last Created'
) => {
  return {
    type: 'UPDATE_MY_SCRAPS_PAGE_SORT_SETTINGS',
    payload: data,
  };
};

export const updateScrapLayoutSizeSettings = (data: 'Large' | 'Default') => {
  return {
    type: 'UPDATE_SCRAP_LAYOUT_SIZE_SETTINGS',
    payload: data,
  }
}
export const setAllViewSettings = (data) => {
  return {
    type: 'SET_ALL_SETTINGS',
    payload: data,
  };
};

export const setLoadingSettings = (load) => {
  return {
    type: 'SET_LOADING_SETTINGS',
    payload: load,
  };
};
export const setSavingSettings = (load) => {
  return {
    type: 'SET_SAVING_SETTINGS',
    payload: load,
  };
};
