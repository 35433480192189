import React from 'react';

interface Proptypes {
  className?: string;
}

const Trash = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6858 3.425L14.4001 3.425C14.4001 1.60246 12.9226 0.125 11.1001 0.125H8.9001C7.07756 0.125 5.6001 1.60246 5.6001 3.425L4.87771 3.425L4.5001 3.425L4.49879 3.425L1.2001 3.425C0.592584 3.425 0.100098 3.91749 0.100098 4.525C0.100098 5.13251 0.592584 5.625 1.2001 5.625L2.3001 5.625L2.3001 18.825C2.3001 20.6475 3.77756 22.125 5.6001 22.125H14.4001C16.2226 22.125 17.7001 20.6475 17.7001 18.825L17.7001 5.625L18.8001 5.625C19.4076 5.625 19.9001 5.13251 19.9001 4.525C19.9001 3.91749 19.4076 3.425 18.8001 3.425L15.5021 3.425L15.5001 3.425L14.6858 3.425ZM15.5001 5.625H13.3001L12.2001 5.625L7.8001 5.625L6.7001 5.625L4.5001 5.625L4.5001 7.825L4.5001 18.825C4.5001 19.4325 4.99259 19.925 5.6001 19.925H14.4001C15.0076 19.925 15.5001 19.4325 15.5001 18.825L15.5001 7.825V5.625ZM7.8001 3.425H8.9001H10.0001L11.1001 3.425H12.2001C12.2001 2.81749 11.7076 2.325 11.1001 2.325L8.9001 2.325C8.29258 2.325 7.8001 2.81749 7.8001 3.425Z"
        fill="#696969"
      />
    </svg>
  );
};

export default Trash;
