import React from 'react';

interface Proptypes {
  className?: string;
}

const UnOrderList = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0ZM7 1C6.44771 1 6 1.44772 6 2C6 2.55228 6.44771 3 7 3H19C19.5523 3 20 2.55228 20 2C20 1.44772 19.5523 1 19 1H7ZM6 8C6 7.44771 6.44771 7 7 7H19C19.5523 7 20 7.44771 20 8C20 8.55229 19.5523 9 19 9H7C6.44771 9 6 8.55229 6 8ZM2 6C0.895431 6 0 6.89543 0 8C0 9.10457 0.895431 10 2 10C3.10457 10 4 9.10457 4 8C4 6.89543 3.10457 6 2 6ZM6 14C6 13.4477 6.44771 13 7 13H19C19.5523 13 20 13.4477 20 14C20 14.5523 19.5523 15 19 15H7C6.44771 15 6 14.5523 6 14ZM2 12C0.895431 12 0 12.8954 0 14C0 15.1046 0.895431 16 2 16C3.10457 16 4 15.1046 4 14C4 12.8954 3.10457 12 2 12Z"
        fill="black"
      />
    </svg>
  );
};

export default UnOrderList;
