import { parseFromTimeZone, formatToTimeZone } from 'date-fns-timezone';
import { getBrowserTimezone } from '.';
import { userTypes } from '../types/user';
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const showDate = (
  date: string,
  inAmPm = false,
  card?: boolean,
  newDateFormat?: boolean
) => {
  const temp = date.split(' ');
  const dateString = temp[0].split('.');
  const timeString = temp[1].split(':');
  let day = +dateString[1];
  let month = months[+dateString[0] - 1];

  let monthNumber = +dateString[0];
  const year = +dateString[2];
  let hours = timeString[0];
  let minutes = timeString[1];

  if (inAmPm) {
    let amPm = 'AM';
    if (+hours === 0) {
      hours = '12';
      amPm = 'AM';
    } else if (parseInt(hours) > 11) {
      amPm = 'PM';
      if (+hours !== 12) {
        hours = (+hours - 12).toString();
      }
    }

    if (card) {
      return `${monthNumber}/${day}/${year}`;
    }
    return `${hours.replace(
      /^0(?:0:0?)?/,
      ''
    )}:${minutes} ${amPm} - ${monthNumber}/${day}/${year}`;
  }
  if (newDateFormat) {
    if (year === new Date().getFullYear()) {
      return `${month} ${day}`;
    }
    return `${month} ${day}, ${year}`;
  }
  return `${hours}:${minutes} at ${day} ${month}, ${year}`;
};
const showTime = (date: string) => {
  const temp = date.split(' ');
  const timeString = temp[1].split(':');

  let hours = timeString[0];
  let minutes = timeString[1];

  let amPm = 'AM';
  if (+hours === 0) {
    hours = '12';
    amPm = 'AM';
  } else if (parseInt(hours) > 11) {
    amPm = 'PM';
    if (+hours !== 12) {
      hours = (+hours - 12).toString();
    }
  }
  return `${hours.replace(/^0(?:0:0?)?/, '')}:${minutes} ${amPm}`;
};
const showFeedDate = (date, timeZone) => {
  const zonedDate = parseFromTimeZone(date, {
    timeZone: 'UTC',
  });
  return formatToTimeZone(new Date(zonedDate), 'MMMM Do, YYYY', {
    timeZone,
  });
};

function showDateHandler(
  date: string,
  user: userTypes,
  timezone?: string,
  fullDate?: boolean
) {
  const zonedDate = parseFromTimeZone(date, {
    timeZone: 'UTC',
  });
  const dateObject = new Date(zonedDate);
  const todayDate = new Date();

  const formattedTime = formatToTimeZone(
    dateObject,
    'MM.DD.YYYY HH:mm:ss.SSS',
    {
      // timeZone: 'UTC',
      timeZone: timezone || user.timezone || getBrowserTimezone(),
    }
  );
  // If full date is true, then show both time and date
  if (fullDate) return showDate(formattedTime, true, false);

  if (dateObject.toDateString() === todayDate.toDateString()) {
    // Meaning the it is recently updated show, only show time
    return showTime(formattedTime);
  }
  // Else show Date only
  return showDate(formattedTime, true, true);
}

function compareDates(
  date1: string,
  date2: string,
  checkTime?: boolean
): boolean {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  ) {
    if (checkTime) {
      if (
        d1.getHours() === d2.getHours() &&
        d1.getMinutes() === d2.getMinutes()
      ) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
}

export {
  showDate as default,
  showFeedDate,
  showTime,
  showDateHandler,
  compareDates,
};
