import { useMutation } from '@apollo/client';

import axios from 'axios';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { setSavedScrap, setScrapPopup } from '../redux/action/utils';
import { EDIT_PANEL } from '../components/data/updateDetails';
import NewPopup from '../components/Global/NewPopup';
import { ScrapImagesUploader } from '../components/ScrapImagesPopup';
import { getGraphqlDateTime } from '../helpers/dateHelpers';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import { UPLOAD_FILE } from './data/uploadFile';
import config from '../helpers/config';
import { entryTypes } from '../types/feed';
import ScrapFilePopup from '../components/ScrapFilePopup';
import { RequestStatusObjectType } from '../types/requestStatusType';
import ReloadContext from '../helpers/contexts/reloadContext';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../components/Global/InvisibleRecaptcha';
import ValidateHuman from '../helpers/validators/isHuman';


interface Proptypes {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export interface ScrapFileData {
  file: File;
  key: string;
}

const error = 'Failed to create a new scrap';
const ScrapFilePopupContainer = ({ show, setShow }: Proptypes) => {
  const { setReload } = useContext(ReloadContext);
  const dispatch = useDispatch();
  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [selectedVideoFile, setSelectedVideoFile] = useState<{
    file: File;
    url: string;
  }>(null);
  const [uploadPDF] = useMutation(UPLOAD_FILE);
  const [saveVideoScrap] = useMutation(EDIT_PANEL({ type: 'video' }));
  const [saveDocumentScrap] = useMutation(EDIT_PANEL({ type: 'document' }));
  const [duration, setDuration] = useState<number>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  //use this for video scrap 
  // useEffect(() => {
  //   if (selectedVideoFile && duration) {
  //     if (duration > config.max_video_duration) {
  //       setErrorMessage(null);
  //       const message = `Unable to upload. Video exceeds the maximum length of ${
  //         config.max_video_duration / 60
  //       } minutes. Please try another Video.`;
  //       setErrorMessage(message);
  //       setSelectedVideoFile(null);
  //       setStatus({
  //         status: 'error',
  //         message,
  //       });
  //       setDuration(null)
  //     } else handleVideoUpload(selectedVideoFile.file);
  //   }
  // }, [duration, selectedVideoFile]);

  const uploadFile = async (file: File) => {
    let variables = {};
    // todo: Add upload handler
    const uploadResponse = await uploadPDF({
      variables: {
        file,
      },
    });
    const { data } = uploadResponse;
    let fileTypeValue =1; 
    if(file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" || file.type==="application/vnd.ms-powerpoint"){
      fileTypeValue=2;
    }else if(file.type ==="application/vnd.ms-excel" || file.type==="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      fileTypeValue=3;
    }else if(file.type==="application/msword" || file.type==="application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
      fileTypeValue=4;
    }

    if (data && data.upload) {
      const { upload } = data;
      variables = { file: upload, file_type: fileTypeValue, name: file.name };
    }
    const res = await saveDocumentScrap({ variables });
    const entry: entryTypes = res.data.upsertDocument;
    if (entry.uid) {
      setShow(false);
      setReload(true);
      if(!entry.type) entry.type = 'document';
      dispatch(setScrapPopup({
        type: 'scrapData',
        defaultEdit: true,
        data: entry,
        headerTitle:"Scrap Saved",
      }))
    } else {
      setErrorMessage(error);
      setStatus({ status: 'error', message: errorMessage });
    }
  };
  //use this for video scrap 
  // const handleVideoUpload = async (file: File) => {
  //   try {
  //     setErrorMessage(null);
  //     let saveVariables = {};

  //     const variables = {
  //       project_id: 1,
  //       playback_time: Math.ceil(duration) || 5,
  //       started_at: getGraphqlDateTime(new Date()),
  //     };

  //     const response = await saveVideoScrap({ variables });
  //     if (!response.data.upsertVideo.video_meta) {
  //       throw new Error('Failed to fetch upload url!');
  //     }

  //     const entry: entryTypes = response.data.upsertVideo;

  //     const parsedObject = JSON.parse(entry.video_meta);

  //     const { uploadURL, uid } = parsedObject || {};
  //     if (!uploadURL || !uid)
  //       throw new Error('Something went wrong while parsing the video url!');
  //     const fd = new FormData();
  //     fd.append('file', file);

  //     await axios.post(uploadURL, fd);

  //     saveVariables = {
  //       video: uid,
  //       upload_status: true,
  //       id: entry.id,
  //       name: file.name,
  //     };

  //     await saveVideoScrap({ variables: saveVariables });
  //     setShow(false);
  //     setReload(true);
  //     entry.type = 'video';
  //     // dispatch(setSavedScrap(entry.uid));
  //     dispatch(setScrapPopup({
  //       type: 'scrapData',
  //       defaultEdit: true,
  //       data: entry,
  //       headerTitle:"Scrap Saved",
  //       // reloadBackgroundOnClose: true,
  //     }))
  //   } catch (error) {
  //     setErrorMessage(parseTryCatchError(error))
  //     setStatus({ message: parseTryCatchError(error), status: 'error' });
  //     setSelectedVideoFile(null);
  //     setDuration(null);
  //   }
  // };

  async function uploadFileChangeHandler(files: FileList) {
   
    try {
      setErrorMessage(null);
      if (!files.length) throw new Error('Files not selected');
      const file = files[0];
      var fileType=["application/pdf","application/vnd.openxmlformats-officedocument.presentationml.presentation","application/vnd.ms-powerpoint","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      var fileTypeStatus=fileType.includes(file.type);
      setStatus({ status: 'processing' });
      const isHuman= await ValidateHuman(reCaptchaRef.current);
      if(!isHuman)
        throw new Error("");
  
      if(!fileTypeStatus){
        videoLoadErrorHandler();
      }
      if (fileTypeStatus) {
        if (file.size > config.pdf_size_limit) {
          throw new Error(
            `Unable to upload. PDF exceeds the maximum storage size of (${
              config.pdf_size_limit / 1000000
            } mb). Please try another PDF.`
          );
        }
        uploadFile(file);
      } else {
        if (file.size > config.video_size_limit) {
          throw new Error(
            `Unable to upload. Video exceeds the maximum storage size of (${
              config.video_size_limit / 1000000
            } mb). Please try another Video.`
          );
        }
        setSelectedVideoFile({ file, url: URL.createObjectURL(file) });
      }
    } catch (error) {
      setStatus({ status: 'error', message: parseTryCatchError(error) });
      throw new Error(parseTryCatchError(error));
    }
  }
  function videoLoadErrorHandler() {
    setErrorMessage('Sorry, this file type is not currently supported');
    setStatus({
      status: 'error',
      message: 'Sorry, this file type is not currently supported',
    });
    setSelectedVideoFile(null);
    setDuration(null);
  }

  function render(close: () => void) {
    return (
      <ScrapFilePopup
        isProcessing={status?.status === 'processing'}
        videoUrl={selectedVideoFile?.url}
        onVideoLoaded={setDuration}
        onVideoError={videoLoadErrorHandler}
      >
        <>
          <InvisibleRecaptcha inputRef={reCaptchaRef}/>
          <ScrapImagesUploader
            onChange={uploadFileChangeHandler}
            fileType=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx"
            text="Up to 20 MB"
            upLoadType="File"
            status={status?.status}
            errorMessage={errorMessage}
          />
        </>
      </ScrapFilePopup>
    );
  }

  useEffect(() => {
    setErrorMessage(null);
    setDuration(null);
    setSelectedVideoFile(null);
    setStatus({ status: 'not-started' });
  }, [show]);

  return (
    <NewPopup
      className='scrap-file-popup__modal'

      unclosable={status?.status === 'processing'}
      header={{
        heading: 'Scrap a File',
        className: 'scrap-file-popup__header',
      }}
      controlled={{ show, setShow }}
      footer={{
        hide: true,
        onSubmit: () => {},
        submitLabel: 'Save',
      }}
      body={{
        className: 'scrap-file-popup__body',
      }}
    >
      {render}
    </NewPopup>
  );
};

export default ScrapFilePopupContainer;
