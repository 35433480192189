/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import './styles.css';
import { generalSearchResultsDataType } from '../../containers/SearchResultsContainer';
import EmptyPageMessage from '../Global/EmptyPageMessage';
import NewBackToTop from '../Global/NewBackToTopButton';
import { ReduxStateType } from '../../redux/store';
import { useSelector } from 'react-redux';
import ItemsMasonry from '../ItemsMasonry';

interface Proptypes {
  masonryKey: string;
  renderScrapControls: () => React.ReactElement;
  loading: boolean;
  renderFilters: () => React.ReactElement;
  knots: generalSearchResultsDataType;
  children: (
    knot: generalSearchResultsDataType['data'][number],
    type: generalSearchResultsDataType['type'],
    index: number
  ) => React.ReactElement;
  loadMore?: () => void;
  isPublic?: boolean;
}

const SearchResults = ({
  masonryKey,
  loading,
  renderFilters,
  children,
  knots,
  loadMore,
  renderScrapControls,
  isPublic
}: Proptypes) => {
  const ref = useRef<HTMLDivElement>()
  let columnWidth = 260;
  if (knots?.type !== 'global-scrappers') columnWidth = 350;

  
  const user = useSelector((state: ReduxStateType) => state.user);
  
  const renderMasonry = () => {
    if (!loading && !knots?.data?.length)
      return (
        <div className="search-results__no-content">
          <EmptyPageMessage header={{}} register_from={user.register_from}>
            <span>Looks like we have no current results for this search.</span>
          </EmptyPageMessage>
        </div>
      );
    else if (knots?.data?.length) {
      return (
        <ItemsMasonry<generalSearchResultsDataType['data'][number]>
          containerRef={ref}
          className='search-results__masonry'
          masonryKey={masonryKey}
          items={knots.data}
          onLoadMore={loadMore}
          loading={loading}
          overscanBy={3}
          columnGutter={15}
          columnWidth={columnWidth}
          columnCount={knots?.type === 'global-scrappers' ? 1 : null}
        >
          {(item,index) => children(item, knots.type, index)}
        </ItemsMasonry>
      );
    } else
      return null
  };
  return (
    <div className={`search-results search-results--${knots?.type}${isPublic ? ' search-results--public' : ''}`} ref={ref}>
      <section className="search-results__filters">{renderFilters()}</section>
      <section
        className={`search-results__controls ${
          knots?.type === 'global-scrappers'
            ? 'search-results__controls-scrappers'
            : ''
        }`}
      >
        {renderScrapControls()}
      </section>
      {renderMasonry()}
      {knots?.type === 'personal-scraps' && <NewBackToTop />}
    </div>
  );
};

export default SearchResults;
