import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { ToastPosition } from 'react-hot-toast/src/core/types';
import './toast.css';
/*
  USAGE INSTRUCTIONS:
  1. IMPORT TOAST ELEMENT AND SENDTOAST FUNCTION
  2. APPEND TOAST AS THE VERY FIRST ELEMENT IN THE DIV WHERE TOAST NOTIFICATION IS REQUIRED
  3. ATTACH THE SENDTOAST FUNCTION WITH NECESSARY ARGUMENTS TO YOUR EVENT CALL 
*/
export const sendToast = (
  message: string,
  type: 'success' | 'error' | 'warning',
  duration?: number
) => {
  /*
    message - The message to display in the notification
    type - It needs to be one of success, error or warning only (can be further expanded by adding more cases with different styling)
    duration - This is the duration of notication to be displayed in miliseconds
    
    PS - 
    1. Currently the styles of all types have been put same as at the time only mockup present is of success only
    2. The notification will be removed on click of the toast itself (functionality present in Toast component)
  */
  const returnStyle = () => {
    let styles: React.CSSProperties;
    switch (type) {
      case 'success':
        styles = {
          border: 'solid 1px #c3e6cb',
          padding: '10px 14px',
          borderRadius: '4px',
          backgroundColor: '#d4edda',
          height: 'fit-content',
          fontSize: '16px',
          minWidth: '300px',
          maxWidth: '75vw',
          textAlign: 'center',
        };
        break;
      case 'error':
        styles = {
          border: 'solid 1px rgb(221, 115, 115)',
          padding: '10px 14px',
          borderRadius: '4px',
          backgroundColor: 'rgb(221, 115, 115)',
          height: 'fit-content',
          fontSize: '16px',
          minWidth: '300px',
          maxWidth: '75vw',
          color: 'white',
          textAlign: 'center',
        };
        break;
      case 'warning':
        styles = {
          border: 'solid 1px #c3e6cb',
          padding: '10px 14px',
          borderRadius: '4px',
          backgroundColor: '#d4edda',
          height: 'fit-content',
          fontSize: '16px',
          minWidth: '300px',
          maxWidth: '75vw',
          textAlign: 'center',
        };
        break;
      default:
        break;
    }
    return styles;
  };

  toast(message, {
    duration: duration ?? 2500,
    style: returnStyle(),
  });
};

/*
  COMPONENT DESCRIPTION:
  1 OPTIONAL PARAMETER IS PRESENT NAMED POSITION WITH DEFAULT VALUE AS TOP-CENTER
  2. POSSIBLE VALUES FOR POSITION ARE - TOP-LEFT, TOP-CENTER, TOP-RIGHT, BOTTOM-LEFT, BOTTOM-CENTER, BOTTOM-RIGHT
*/
interface propTypes {
  position?:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';
}

export const Toast = ({ position }: propTypes) => {
  return (
    <Toaster
      position={(position ?? 'top-center') as ToastPosition}
      containerClassName="toast-notification"
    >
      {(t) => (
        <div onClick={() => toast.remove()}>
          <ToastBar
            toast={t}
            style={{
              ...t.style,
              animation: t.visible
                ? 'custom-enter 1s ease'
                : 'custom-exit 1s ease',
            }}
          />
        </div>
      )}
    </Toaster>
  );
};
