import React, { useRef } from 'react';
import './styles.css';
import Masonry from 'react-masonry-css';
import {
  ExistingImagesItemsType,
  FetchedExistingImageType,
} from '../../../../types/imagesTypes';
import Icon from '../../../../../Global/Icon';
import ResponsiveImage from '../../../../../Global/ResponsiveImage/';

interface Proptypes {
  images: FetchedExistingImageType[];
  loadMore: () => void;
  selectedImages: ExistingImagesItemsType[];
  setSelectedImages: (imgs: ExistingImagesItemsType[]) => void;
  type?: 'selected' | 'add existing';
  singleSelect?: (image: string) => void;
}

const breakpointColumnsObj = {
  default: 4,
  1100: 4,
  700: 2,
  500: 1,
};
const FakeCard = ({
  index,
  data,
  selectedImages,
  setSelectedImages,
  singleSelect,
}) => {
  const { image, order } = data;

  // if the image doesn't exist
  if (!image || image.trim() === '') {
    return null;
  }

  let el;
  if (singleSelect) {
    el = (
      <ResponsiveImage
        sizes={['800']}
        image={image}
        alt={`pic ${index + 1}`}
        imageClass="images-masonry-item__img"
        onClick={singleSelect.bind(null, image)}
      />
    );
  } else {
    el = (
      <>
        <input
          type="checkbox"
          name="image-select"
          checked={
            selectedImages.findIndex((item) => item.image === image) !== -1
          }
          onChange={(e) => {
            if (e.target.checked)
              setSelectedImages([
                ...selectedImages,
                {
                  type: 'existing',
                  ...data,
                },
              ]);
            else
              setSelectedImages(
                selectedImages.filter((img) => img.image !== image)
              );
          }}
          className="images-masonry-item__checkbox"
          id={`${order}${image}`}
        />
        <span
          className={
            `images-masonry-item__label-check ` +
            (selectedImages.length ? 'show_border' : '')
          }
        >
          <Icon iconClass="check" size="big" />
        </span>
        <label
          htmlFor={`${order}${image}`}
          className="images-masonry-item__label"
        >
          <ResponsiveImage
            sizes={['800']}
            image={image}
            alt={`pic ${index + 1}`}
            imageClass="images-masonry-item__img"
          />
        </label>
      </>
    );
  }
  return (
    <div key={order} className="images-masonry-item">
      {el}
    </div>
  );
};

const ImagesMasonry = (props: Proptypes) => {
  const {
    images,
    loadMore,
    selectedImages,
    setSelectedImages,
    type,
    singleSelect,
  } = props;
  const containerRef = useRef(null);
  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    var element = e.target as HTMLElement;
    const { scrollHeight, scrollTop, clientHeight } = element;
    if (scrollHeight - scrollTop - 100 <= clientHeight) {
      loadMore();
    }
  };

  return ( 
    <div
      className="images-masonry-item__container"
      ref={containerRef}
      onScroll={handleScroll}
    >
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {images.map((image, i) => (
          <FakeCard
            key={image.image}
            index={i}
            data={image}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            singleSelect={singleSelect}
          />
        ))}
      </Masonry>
    </div>
  );
};

export default ImagesMasonry;
