import React from 'react';
import './MessagesStyles.css';
import { Helmet } from "react-helmet";

const LoginSuccess = () => {
  return (
    <div className="welcome">
      <Helmet>
        <title>Login Successful</title>
      </Helmet>
      <img src="/img/welcome.svg" alt="" className="welcome__image" />
      <h2>
        Welcome, you are successfully logged into the extension!
      </h2>
    </div>
  );
};

export default LoginSuccess;
