import { ApolloClient } from '@apollo/client';
import UPSERT_SCRAP from '../../data/upsertScrap';
import { scrapType } from '../../../types/scrapType';

export default async function createScrapHandler(
  variables: object,
  client: ApolloClient<object>
) {
  try {
    // Call mutation for creating initial scrap with just notes, title and state
    const response = await client.mutate({
      mutation: UPSERT_SCRAP('desc, state, title'),
      variables,
    });

    // Store the response in scrap variable and define its type
    return response.data.upsertScrap.data as Partial<scrapType>;
  } catch (error) {
    throw new Error('Failed to create scrap!');
  }
}
