import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const NewDownloadIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8822 9.27916C13.2285 8.9761 13.2636 8.44963 12.9606 8.10326C12.6575 7.7569 12.131 7.7218 11.7847 8.02487L9.83341 9.73221V1.56868C9.83341 1.10845 9.46032 0.735352 9.00008 0.735352C8.53984 0.735352 8.16675 1.10845 8.16675 1.56868V9.73221L6.2155 8.02487C5.86914 7.7218 5.34267 7.7569 5.0396 8.10326C4.73653 8.44963 4.77163 8.9761 5.11799 9.27916L8.45133 12.1958C8.76552 12.4707 9.23465 12.4707 9.54883 12.1958L12.8822 9.27916ZM2.33341 11.5687C2.33341 11.1084 1.96032 10.7354 1.50008 10.7354C1.03984 10.7354 0.666748 11.1084 0.666748 11.5687V14.0687C0.666748 14.9892 1.41294 15.7354 2.33341 15.7354H15.6667C16.5872 15.7354 17.3334 14.9892 17.3334 14.0687V11.5687C17.3334 11.1084 16.9603 10.7354 16.5001 10.7354C16.0398 10.7354 15.6667 11.1084 15.6667 11.5687V14.0687H2.33341V11.5687Z"
        fill="#FDF1E8"
      />
    </svg>
  );
};

export default NewDownloadIcon;
