import React, { useState, useEffect } from 'react';

import {
  GET_SEARCH_HISTORIES,
  DELETE_SEARCH_HISTORY,
} from './data/searchHistory';
import { useLazyQuery, useMutation } from '@apollo/client';

import GlobalSearchInput from '../components/GlobalSearch/components/GlobalSearchBar/GlobalSearchInput';

import useDebounce from '../helpers/customHooks/useDebounce';
import { SearchHistoryItemType } from '../types/globalSearchDataType';
import { useHistory } from 'react-router-dom';
interface Proptypes {
  hideHistory: boolean;
  setHideHistory: (val: boolean) => void;
  handleIconStyles: (showSearch: boolean, showClear: boolean) => void; // THIS WILL HANDLE TO SHOW OR HIDE SEARCH AND CLEAR ICONS
  showSearchIcon: boolean;
  showClearIcon: boolean;
  placeholder: string;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  onSearchHistoryItemClick: (val: string) => void;
  clearFiltersIfAny: () => void;
  isPublic?: boolean;
  hideSuggestion?:boolean;
}

const GlobalSearchInputWrapper = ({
  hideHistory, 
  setHideHistory,
  handleIconStyles,
  showSearchIcon,
  placeholder,
  showClearIcon,
  inputRef,
  onSearchHistoryItemClick,
  clearFiltersIfAny,

  isPublic
}: Proptypes) => {
  const debounce = useDebounce(500);
  const { push } = useHistory();
  const [fetchHistories, { data, loading }] = useLazyQuery(
    GET_SEARCH_HISTORIES
  );
  const [searchHistoryList, setSearchHistoryList] = useState<
    SearchHistoryItemType[]
  >([]);
  const [deleteHistory, { loading: deleting }] = useMutation(
    DELETE_SEARCH_HISTORY
  );
  const [loadingHistories, setLoadingHistories] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  /**
   * HANDLER TO FETCH SEARCH HISTORY BASED ON WHAT USER TYPES IN SEARCH BAR
   */
  function inputChangeHandler(value: string) {
    setHoveredIndex(-1);
    const trimmedVal = value.trim();
    handleIconStyles(showSearchIcon, trimmedVal !== '');
    if (!isPublic) {
      setLoadingHistories(true);
      debounce(() =>
        fetchHistories({ variables: { search_term: trimmedVal, first: 5 } })
      );
    }
  }

  async function deleteAllHistoriesHandler() {
    setSearchHistoryList([]);
    if (!isPublic) {
      await deleteHistory({
        variables: {
          all: true,
        },
      });
    }
  }
  async function deleteHistoryHandler(id: string) {
    setSearchHistoryList((old) => old.filter((item) => item.id !== id));
    if (!isPublic) {
      await deleteHistory({
        variables: {
          id,
        },
      });
    }
  }

  function focusHandler(val: string) {
    if (!isPublic) {
      setLoadingHistories(true);
      fetchHistories({
        variables: {
          search_term: val.trim(),
          first: 5,
        },
      });
      setHideHistory(false);
    }
    handleIconStyles(false, val.trim() !== '');
  }

  function blurHandler(val: string) {
    if (val.trim() === '') {
      handleIconStyles(true, false);
      inputRef.current.value = '';
    }
    setSearchHistoryList([]);
    setHideHistory(true);
  }

  function clearIconClickHandler() {
    inputRef.current.value = '';
    handleIconStyles(true, false);
    setSearchHistoryList([]);
    clearFiltersIfAny();
    // pushing user to default global search
    push('/search?mode=global&type=all');
  }

  function filterSelectHandler(item: SearchHistoryItemType) {
    if (!isPublic) {
      onSearchHistoryItemClick(item.search_term);
      setSearchHistoryList([]);
      setHideHistory(true);
    }
  }
  /**
   * USEEFFECT TO CHECK AND LOAD SEARCH HISTORY DATA TO STATE, IF HIDEHISTORY IS TRUE
   * THEN DONT SAVE DATA
   */
  useEffect(() => {
    if (data && !loading && !hideHistory) {
      setSearchHistoryList(data?.searchHistories.data || []);
      setLoadingHistories(false);
    }
  }, [data, loading]);
  return (
    <GlobalSearchInput
      hideHistory={hideHistory}
      placeholder={placeholder}
      showSearchIcon={showSearchIcon}
      showClearIcon={showClearIcon}
      setHoveredIndex={setHoveredIndex}
      hoveredIndex={hoveredIndex}
      inputRef={inputRef}
      focusHandler={focusHandler}
      blurHandler={blurHandler}
      inputChangeHandler={inputChangeHandler}
      clearIconClickHandler={clearIconClickHandler}
      searchHistoryList={searchHistoryList}
      deleteAllHistoriesHandler={deleteAllHistoriesHandler}
      deleteHistoryHandler={deleteHistoryHandler}
      disabled={deleting}
      loadingHistories={loadingHistories}
      filterSelectHandler={filterSelectHandler}
    />
  );
};

export default GlobalSearchInputWrapper;
