import { ViewSettingsType } from '../../types/viewSettings';
import {
  scrapsSettingsMapper,
  collectionsSettingsMapper,
  storiesSettingsMapper,
} from '../../variableMappers/viewSettings';
const {
  description: scrapsDesc,
  updatedAt: scrapsUpdatedAt,
  tags: scrapsTags,
  title: scrapsTitle,
} = scrapsSettingsMapper;
const {
  coverImage: collectionsCoverImage,
  description: collectionsDesc,
  panelCount: collectionsPanelCount,
  state: collectionsVisibility,
  updatedAt: collectionsUpdatedAt,
} = collectionsSettingsMapper;
const {
  coverImage: storiesCoverImage,
  description: storiesDesc,
  panelCount: storiesPanelCount,
  state: storiesVisibility,
  tags: storiesTags,
  updatedAt: storiesUpdatedAt,
} = storiesSettingsMapper;
const DEFAULT: ViewSettingsType = {
  loading: false,
  saving: false,
  error: '',
  allScrapsViewSettings: [
    { key: scrapsDesc, value: true, label: 'Description' },
    { key: scrapsUpdatedAt, value: true, label: 'Last Modified Date' },
    { key: scrapsTags, value: true, label: 'Tags' },
    { key: scrapsTitle, value: true, label: 'Title' },
  ],
  collectionScrapsViewSettings: [
    { key: scrapsDesc, value: true, label: 'Description' },
    { key: scrapsUpdatedAt, value: true, label: 'Last Modified Date' },
    { key: scrapsTags, value: true, label: 'Tags' },
    { key: scrapsTitle, value: true, label: 'Title' },
  ],
  collectionsViewSettings: [
    { key: collectionsCoverImage, value: true, label: 'Cover Image' },
    { key: collectionsDesc, value: true, label: 'Description' },
    { key: collectionsUpdatedAt, value: true, label: 'Last Modified Date' },
    { key: collectionsPanelCount, value: true, label: 'Number Of Scraps' },
    { key: collectionsVisibility, value: true, label: 'Visibility Setting' },
  ],
  storiesViewSettings: [
    { key: storiesCoverImage, value: true, label: 'Cover Image' },
    { key: storiesDesc, value: true, label: 'Description' },
    { key: storiesUpdatedAt, value: true, label: 'Last Modified Date' },
    { key: storiesPanelCount, value: true, label: 'Number Of Scraps' },
    { key: storiesVisibility, value: true, label: 'Visibility Setting' },
    { key: storiesTags, value: true, label: 'Tags' },
  ],
  myScrapsPageViewSettings: {
    viewLayout: 'Gallery',
    sort_param: 'Last Created'
  },
  scrapLayoutSettings: {
    size: 'Default',
  },
  gobalSearchPageViewSettings: {
    viewLayout: 'Gallery',
    sort_param: 'Last Created'
  }
};
const returnSetting = {
  UPDATE_ALL_SCRAPS_VIEW_SETTINGS: 'allScrapsViewSettings',
  UPDATE_COLLECTION_SCRAPS_VIEW_SETTINGS: 'collectionScrapsViewSettings',
  UPDATE_COLLECTIONS_VIEW_SETTINGS: 'collectionsViewSettings',
  UPDATE_STORIES_VIEW_SETTINGS: 'storiesViewSettings',
};

const viewSettings = (state = DEFAULT, action) => {
  switch (action.type) {
    case 'UPDATE_ALL_SCRAPS_VIEW_SETTINGS':
    case 'UPDATE_COLLECTION_SCRAPS_VIEW_SETTINGS':
    case 'UPDATE_COLLECTIONS_VIEW_SETTINGS':
    case 'UPDATE_STORIES_VIEW_SETTINGS':
      return {
        ...state,
        error: '',
        loading: false,
        [returnSetting[action.type]]: state[returnSetting[action.type]].map(
          (setting) => {
            if (setting.key === action.payload.key) return action.payload;

            return setting;
          }
        ),
      };
    case 'SET_ALL_SETTINGS':
      return { ...state, ...action.payload, loading: false, error: '' };
    case 'SET_LOADING_SETTINGS':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_SAVING_SETTINGS':
      return {
        ...state,
        saving: action.payload,
      }  
    case 'UPDATE_MY_SCRAPS_PAGE_VIEW_LAYOUT_SETTINGS':
      return {
        ...state,
        error: '',
        myScrapsPageViewSettings: {
          ...state.myScrapsPageViewSettings,
          viewLayout: action.payload,
        },
      };
    case 'UPDATE_MY_SCRAPS_PAGE_SORT_SETTINGS':
      return {
        ...state,
        error: '',
        myScrapsPageViewSettings: {
          ...state.myScrapsPageViewSettings,
          sort_param: action.payload,
        },
      };
    case 'UPDATE_SCRAP_LAYOUT_SIZE_SETTINGS':
      return {
        ...state,
        error: '',
        loading: false,
        scrapLayoutSettings: {
          ...state.scrapLayoutSettings,
          size: action.payload,
        },
      };
    default:
      return state;
  }
};

export default viewSettings;
