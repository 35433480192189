import React, { useEffect, useRef } from 'react';
import { returnImageURL } from '../../Gallery/helpers/returnImageURL';
import { Doc, DownloadNew, NewDownloadIcon, PdfIcon, Ppt, Xls } from '../icons';
import './styles.css';
import { onClickType } from '../../ScrapCard';
import ViewAllHighlights from '../Highlights/uiComponents/ViewAllHighlights';
import { FileCardType } from '../../../helpers/config';
interface Type {
  file_name: string;
  file_path: string;
  file_type: string;
}
interface Proptypes {
  file: Array<Type>;
  file_count?: number;
  onMoreClick?: (
    onClickType: onClickType,
    currentlyOpened?: boolean
  ) => boolean;
  showAll?: boolean;
  showHighlightsButton?: boolean;
  clickedOn?: string;
}

const FileCard = ({
  file,
  file_count,
  onMoreClick,
  showAll,
  showHighlightsButton,
}: Proptypes) => {
  const fileIcon = (fileType) => {
    switch (fileType) {
      case FileCardType.PDF:
        return <PdfIcon />;
      case FileCardType.PPT:
        return <Ppt />;
      case FileCardType.XLS:
        return <Xls />;
      case FileCardType.DOC:
      case FileCardType.TEXT:
        return <Doc />;
    }
  };

  const filesRef = useRef<HTMLDivElement>();
  function clickHandler(
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    filePath
  ) {
    e.stopPropagation();
    window && window.open(returnImageURL(filePath), '_blank');
  }
  if (!file) return null;
  let files = showAll ? file : file?.slice(0, 5);
  return (
    <div ref={filesRef}>
      {files?.map((item, key) => {
        return (
          <div className="fileCard" key={key}>
            <span className="fileCard__file-icon">
              {fileIcon(item.file_type)}
            </span>
            <span className="fileCard__file-name">
              <span className="fileCard__show-name">
                {item.file_name?.split('.')[0]}
              </span>
              <span className="fileCard__show-type">
                .{item.file_name?.split('.')[1]}
              </span>
            </span>
            <button
              className="fileCard__download-icon hoverState-button"
              onClick={(e) => clickHandler(e, item.file_path)}
            >
              <NewDownloadIcon />
            </button>
          </div>
        );
      })}
      {file_count > 5 && showHighlightsButton && (
        <ViewAllHighlights
          highlightsCount={file_count}
          onMoreClick={onMoreClick}
          label="View all files"
          showMore="all-files"
        />
      )}
    </div>
  );
};

export default FileCard;
