import React from 'react';
import Dropdown from '../../../Global/Dropdown/';
import { CloseIcon } from '../../../Global/icons';

const dropdownList = [
  { id: '1', label: 'This Collection' },
  { id: '2', label: 'All Collections' },
];

interface Proptypes {
  hideCollectionFilter?: boolean;
  selectedType: {
    title: string;
    id: number;
  };
  onTypeChange: (val) => void;
  close: () => void;
}

const AddExistingImagesPopupHeader = ({
  hideCollectionFilter = false,
  close,
  selectedType,
  onTypeChange,
}: Proptypes) => {
  return (
    <div className="add-existing-images__header">
      <div className="add-existing-images__header-left">
        <h3 className="add-existing-images__header-heading new-popup__header-heading">
          Select Image
        </h3>
        {!hideCollectionFilter && (
          <div className="add-existing-images__header-dropdown">
            <Dropdown
              list={dropdownList}
              selectedItem={selectedType?.title}
              placeholder={'This Collection'}
              onSelect={(item) =>
                onTypeChange({ id: +item.id, title: item.label })
              }
              parentClassName=".new-popup__header-inner-container"
              additonalClassName="__dropdown"
              dropdownicon="dropdownicon"
            />
          </div>
        )}
      </div>
      <button
        onClick={() => {
          close();
          if(typeof(window)!=="undefined")
          {
            document.body.style.overflow=""
          }
        
        }}
        className="new-popup__header-button"
      >
        <CloseIcon className="new-popup__header-button__icon" />
      </button>
    </div>
  );
};

export default AddExistingImagesPopupHeader;
