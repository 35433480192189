import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from '../redux/action/userActions';

import { useMutation, useQuery, gql } from '@apollo/client';
import { EDIT_PROFILE } from './data/editProfile';
import { CHANGE_PASSWORD } from './data/changePassword';
import Profile from '../components/Account/Profile';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router'
import { userTypes } from '../types/user';
const GET_PROFILE_DATA = gql`
  {
    profile {
      data {
        cover_image
        social_media_accounts
        url
        location
        display_name
      }
    }
  }
`;
const ProfileWrapper = (props) => {
  const dispatch = useDispatch();
  const { refetch: fetchProfileData } = useQuery(GET_PROFILE_DATA, {
    skip: true, // SETTING SKIP: TRUE WILL ALLOW US TO ONLY FETCH DATA WHEN WE CALL fetchProfileData AND IT WILL ALSO PREVENT FETCHING AUTOMATICALLY AT COMPONENT MOUNT
  });

  const {pathname}=useLocation()
  const [params,setParams] = useState({});
  const history = useHistory();
  const [editProfile] = useMutation(EDIT_PROFILE);
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  useEffect(() => {

    if(pathname!=='/profile')
    {
      history.push('/404')
    }
    
    
  }, [pathname]);
  async function profileSubmitHandler(variables: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    country?: string;
    timezone?: string;
    bio?: string;
    coverImageURL?:string;
    URL?:string;
    SocialId?:{[key:string]:string}
  }) {
    try {
      const response = await editProfile({
        variables,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data: any = {
        ...variables,
      };
      if (data.email) {
        delete data.email;
        data.userEmail = variables.email;
      }
      if (response?.data?.upsertProfile?.messages?.success?.length > 0) {
        let fetchedProfile = {};
        // ONLY FETCH PROFILE DATA WHEN USER UPDATES EMAIL
        if (data.userEmail||data.cover_image||data.social_media_accounts) {
          const {
            data: { profile },
          } = await fetchProfileData();
          fetchedProfile = profile.data;
        }
        dispatch(updateUser({ ...data, ...fetchedProfile }));
        return response.data.upsertProfile.messages.success[0];
      } else
        throw new Error(
          response?.data?.upsertProfile?.messages?.error[0] ||
            'Something went wrong'
        );
    } catch (error) {
      throw new Error(
        (error as { message: string }).message || 'Something went wrong'
      );
    }
  }

  async function submitNewPasswordHandler(variables: {
    old_password: string;
    password: string;
    password_confirmation?: string;
  }) {
    try {
      const response = await changePassword({
        variables,
      });
      if (response?.data?.changePassword?.messages?.success?.length > 0)
        return response.data.changePassword.messages.success[0];
      else
        throw new Error(
          response?.data?.changePassword?.messages?.error[0] ||
            'Something went wrong'
        );
    } catch (error) {
      throw new Error(
        (error as { message: string }).message || 'Something went wrong'
      );
    }
  }
  async function imageUploadHandler(imageUrl: string) {
    const generalErrorMessage = 'Image upload failed. Please try again!';
    try {
      const response = await editProfile({
        variables: { avatar: imageUrl },
      });
      const userData: userTypes = response.data.upsertProfile.data;
      dispatch(updateUser({ avatar: userData.avatar }));
    } catch (error) {
      throw new Error(
        (error as { message: string }).message || generalErrorMessage
      );
    }
  }

  return (
    <Profile
      onProfileSubmit={profileSubmitHandler}
      onNewPasswordSubmit={submitNewPasswordHandler}
      onImageUpload={imageUploadHandler}
    />
  );
};

export default ProfileWrapper;
