import { scrapType } from '../../types/scrapType';
import {
  DISLIKE_SCRAP,
  LIKE_SCRAP,
  UPDATE_SCRAPS_LIKES_AND_COMMENTS,
  scrapsLikesAndCommentsPayloadType,
} from '../store/scrapsLikesAndComments';

export function updateScrapsLikesAndComments(
  payload:
    | scrapsLikesAndCommentsPayloadType
    | Array<scrapsLikesAndCommentsPayloadType>
) {
  return {
    type: UPDATE_SCRAPS_LIKES_AND_COMMENTS,
    payload,
  };
}

export function likeScrap(
  scrapIdOrIds: Array<scrapType['id']> | scrapType['id']
) {
  return {
    type: LIKE_SCRAP,
    payload: Array.isArray(scrapIdOrIds)
      ? scrapIdOrIds.map((scrapId) => ({
        scrapId,
      }))
      : {
        scrapId: scrapIdOrIds,
      },
  };
}
export function dislikeScrap(
  scrapIdOrIds: Array<scrapType['id']> | scrapType['id']
) {
  return {
    type: DISLIKE_SCRAP,
    payload: Array.isArray(scrapIdOrIds)
      ? scrapIdOrIds.map((scrapId) => ({
        scrapId,
      }))
      : {
        scrapId: scrapIdOrIds,
      },
  };
}
