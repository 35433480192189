import React, { useMemo } from 'react';

import Popup from 'reactjs-popup';

import './styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import MatchedScrapsDrawer from './MatchedScrapsDrawer';
import { setDrawer } from '../../../redux/action/drawerAction';
import CommentsDrawer from './CommentsDrawer';

export default function Drawer() {
  const drawer = useSelector((state: ReduxStateType) => state.drawer);
  const dispatch = useDispatch();
  const { data, type } = drawer || {};

  const closeHandler = () => {
    dispatch(setDrawer(null));
  }

  const [content, drawerClass] = useMemo(() => {
    let content: React.ReactElement,
      drawerClass = 'drawer';
    switch (type) {
      case 'matched-scraps':
        content = <MatchedScrapsDrawer data={data} onClose={closeHandler} />;
        drawerClass += ' drawer--matched-scraps';
        break;
      case 'comments':
        content = <CommentsDrawer data={data} onClose={closeHandler} />;
        drawerClass += ' drawer--comments';
        break;

      default:
        break;
    }
    return [content, drawerClass];
  }, [drawer]);
  return (
    <Popup
      open={!!drawer}
      className={drawerClass}
      onClose={closeHandler}
      closeOnDocumentClick={false}
    >
      {content}
    </Popup>
  );
}
