import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const Clockwise = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C6.18385 0 3.88915 1.34664 2.59289 2.64289C1.29016 3.94562 0 6.19161 0 9C0 11.8162 1.34664 14.1109 2.64289 15.4071C3.94562 16.7098 6.19162 18 9 18C9.55229 18 10 17.5523 10 17C10 16.4477 9.55229 16 9 16C6.80838 16 5.05057 14.9864 4.05711 13.9929C3.05716 12.9929 2 11.1838 2 9C2 6.80838 3.01364 5.05057 4.00711 4.05711C5.00705 3.05716 6.81615 2 9 2C11.1916 2 12.9494 3.01364 13.9429 4.00711C14.7596 4.82377 15.6144 6.18017 15.9003 7.83963L15.1247 7.21913C14.6934 6.87412 14.0641 6.94404 13.7191 7.37531C13.3741 7.80657 13.444 8.43586 13.8753 8.78087L16.3753 10.7809C16.7732 11.0992 17.3468 11.0674 17.7071 10.7071L19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289C19.3166 6.90237 18.6834 6.90237 18.2929 7.29289L17.9014 7.68437C17.5684 5.47193 16.445 3.68078 15.3571 2.59289C14.0544 1.29016 11.8084 0 9 0Z"
        fill="black"
      />
    </svg>
  );
};

export default Clockwise;
