import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const NextIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.21967 9.79079C0.512563 10.0697 0.987437 10.0697 1.28033 9.79079L5.78033 5.50508C6.07322 5.22613 6.07322 4.77387 5.78033 4.49492L1.28033 0.20921C0.987437 -0.0697374 0.512564 -0.0697374 0.21967 0.209209C-0.0732228 0.488155 -0.0732228 0.940416 0.21967 1.21936L4.18934 5L0.21967 8.78064C-0.0732233 9.05958 -0.0732233 9.51184 0.21967 9.79079Z"
        fill="white"
      />
    </svg>
  );
};

export default NextIcon;
