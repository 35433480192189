import React from 'react';

interface Proptypes {
  className?: string;
}

const GalleryView = ({ className }: Proptypes) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.895431 0 2V2.66667V5C0 6.10457 0.895432 7 2 7H6C7.10457 7 8 6.10457 8 5V2C8 0.89543 7.10457 0 6 0H2ZM2 2H6V5L2 5V2.66667V2ZM12 0C10.8954 0 10 0.89543 10 2V4V9C10 10.1046 10.8954 11 12 11H16C17.1046 11 18 10.1046 18 9V2C18 0.89543 17.1046 0 16 0H12ZM12 2H16V9H12V4V2ZM10 15C10 13.8954 10.8954 13 12 13H16C17.1046 13 18 13.8954 18 15V18C18 19.1046 17.1046 20 16 20H12C10.8954 20 10 19.1046 10 18V15.6667V15ZM16 15H12V15.6667V18H16V15ZM2 9C0.895431 9 0 9.89543 0 11V13V18C0 19.1046 0.89543 20 2 20H6C7.10457 20 8 19.1046 8 18V11C8 9.89543 7.10457 9 6 9H2ZM2 11H6V18H2V13V11Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default GalleryView;
