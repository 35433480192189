import React from 'react';

interface Proptypes {
  className?: string;
}

const StrikeThrough = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      fill="#000000"
      width="20"
      height="16"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 502 502"
    >
      <g>
        <g>
          <g>
            <path
              d="M430,251c0-22.056-17.944-40-40-40H259.811c-1.947-0.986-3.886-1.965-5.794-2.927l0.002,0.001
               c-48.917-24.672-68.84-37.277-68.84-62.987c0-36.294,29.527-65.821,65.82-65.821c36.294,0,65.821,29.527,65.821,65.821
               c0,21.854,17.779,39.633,39.633,39.633s39.633-17.779,39.633-39.633C396.086,65.086,331,0,251,0S105.915,65.086,105.915,145.087
               c0,24.422,6.726,46.507,20.018,65.913H112c-22.056,0-40,17.944-40,40c0,22.056,17.944,40,40,40h130.771l0.443,0.212
               c0.001,0,0.003,0.001,0.004,0.002c66.036,31.55,73.603,38.305,73.603,65.7c0,36.294-29.527,65.82-65.821,65.82
               c-36.293,0-65.82-29.526-65.82-65.82c0-21.854-17.779-39.633-39.633-39.633s-39.633,17.779-39.633,39.633
               C105.914,436.915,170.999,502,251,502s145.086-65.085,145.086-145.086c0-25.644-5.751-47.39-17.491-65.914H390
               C412.056,291,430,273.056,430,251z M125.914,145.087C125.914,76.113,182.027,20,251,20s125.086,56.113,125.086,125.087
               c0,10.825-8.807,19.633-19.633,19.633s-19.633-8.808-19.633-19.633c0-47.322-38.499-85.821-85.821-85.821
               c-47.321,0-85.82,38.499-85.82,85.821c0,31.355,21.034,49.091,51.174,65.913H151.38
               C134.478,192.336,125.914,170.183,125.914,145.087z M251,482c-68.972,0-125.085-56.113-125.085-125.086
               c0-10.825,8.807-19.633,19.633-19.633c10.826,0,19.633,8.808,19.633,19.633c0,47.321,38.499,85.82,85.82,85.82
               c47.322,0,85.821-38.499,85.821-85.82c0-32.268-12.538-46.448-49.024-65.914h65.936c15.231,17.566,22.352,38.635,22.352,65.914
               C376.086,425.887,319.972,482,251,482z M112,271c-11.028,0-20-8.972-20-20s8.972-20,20-20h278c11.028,0,20,8.972,20,20
               s-8.972,20-20,20H112z"
            />
            <path
              d="M137,238h-24c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h24c5.523,0,10-4.478,10-10
               C147,242.478,142.523,238,137,238z"
            />
            <path
              d="M293,238H169c-5.523,0-10,4.478-10,10c0,5.522,4.477,10,10,10h124c5.523,0,10-4.478,10-10
               C303,242.478,298.523,238,293,238z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default StrikeThrough;
