import React, { ReactElement } from 'react'

interface Proptypes{
  icon:ReactElement;
  label:string;
  onClick?:()=>void;
  className:string
}

const ButtonModal = ({ icon,label,onClick,className }:Proptypes) => {
  
  return (
    <button className={className} onClick={onClick}>{icon}{" "}{label}</button>
  )
}

export default ButtonModal