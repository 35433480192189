import React, { useEffect, useRef } from 'react';
import { matchedScrapsDrawerType } from '../../../../redux/store/drawer';

import './styles.css';
import { returnShareLink } from '../../../../helpers/returnShareLinks';
import ItemsMasonry from '../../../ItemsMasonry';
import Button from '../../../uiComponents/Button';
import { useHistory } from 'react-router-dom';
import { NewClose } from '../../icons';

import MATCHED_SCRAPS from '../../../../containers/data/matchedScraps';
import { useQuery } from '@apollo/client';
import { scrapType } from '../../../../types/scrapType';
import ScrapCard from '../../../ScrapCard';
import EmptyPageMessage from '../../EmptyPageMessage';
import graphQlWrapper from '../../../../apollo/client';
import { collectionType } from '../../../../types/collections';
import { GroupType } from '../../../../containers/GroupShareContainer/types';

interface Proptypes {
  data: matchedScrapsDrawerType['data'];
  onClose: () => void;
}

const publicClient = graphQlWrapper(null, true);
let isUpdatingQuery = false;
export default function MatchedScrapsDrawer({ data, onClose }: Proptypes) {
  const ref = useRef<HTMLDivElement>();
  const history = useHistory();
  const { collection, group, openLink, usePublicClient } = data || {};
  const dataType = collection ? 'collection' : 'group';
  const scrap_ids = data[dataType]?.scrap_ids;
  let shareLink = openLink;
  if (!shareLink) {
    if (dataType === 'group') shareLink = `/shares/${group.id}`;
    else
      shareLink = returnShareLink(
        'collection',
        collection.slug,
        collection.user_name,
        true
      );
  }

  // const [lastScrap, setLastScrap] = useState<scrapType>(null);

  const { data: matchedScrapsData, loading, fetchMore } = useQuery(
    MATCHED_SCRAPS(),
    {
      variables: {
        scrap_ids,
        first: 140,
        // search_after: lastScrap && lastScrap.updated_at,
        // tie_breaker_id: lastScrap && lastScrap.id,
        tie_breaker_param: 'id',
        
      },
      client: usePublicClient && publicClient,
      fetchPolicy: 'network-only',
    }
  );

  
  const scrapsCount = data[dataType]?.matched_count;
  const scraps: scrapType[] = matchedScrapsData?.matchedScraps?.data;

  const loadMoreHandler = () => {
    if(scraps?.length >= scrap_ids.length) return;
    if (scraps.length && !loading && !isUpdatingQuery) {
      isUpdatingQuery = true;
      const lastScrap = scraps[scraps.length - 1];
      fetchMore({
        variables: {
          search_after: lastScrap.updated_at,
          tie_breaker_id: lastScrap.id,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          isUpdatingQuery = false;
          return {
            ...prev,
            matchedScraps: {
              ...prev.matchedScraps,
              data: [
                ...prev.matchedScraps.data,
                ...fetchMoreResult.matchedScraps.data,
              ],
            },
          };
        },
      });
    }
  };

  const renderScraps = () => {
    if (scraps?.length) {
      return (
        <ItemsMasonry<scrapType>
          containerRef={ref}
          items={scraps}
          loading={loading}
          overscanBy={2}
          columnCount={1}
          rowGutter={10}
          onLoadMore={loadMoreHandler}
        >
          {(scrap) => {

            let scrapData = {...scrap};
            const data = dataType === 'group' ? group : collection;
            if(data) {
              const scrapUser = data?.members.find(
                (member) => +member.user_id === +scrap.user_id
              );
          
              if (scrapUser)
                scrapData = {
                  ...scrap,
                  user_name: scrapUser.user_name,
                  display_name: scrapUser.display_name,
                  avatar: scrapUser.avatar,
                };
              else if (scrap.user_id === data.user_id)
                scrapData = {
                  ...scrap,
                  user_name: dataType === 'group' ? (data as GroupType).owner_user_name: (data as collectionType).user_name,
                  display_name: dataType === 'group' ? (data as GroupType).owner_display_name : (data as collectionType).display_name,
                  avatar: dataType === 'group' ? (data as GroupType).owner_avatar : (data as collectionType).avatar,
                };
            }
            scrapData.tags = [];
            return <ScrapCard scrap={scrapData} />
          }}
        </ItemsMasonry>
      );
    } else if (!loading) {
      return (
        <EmptyPageMessage header={{}}>
          <span>
          Unfortunately, no matched Scraps were found at this moment. Please feel free to check back later!
          </span>
        </EmptyPageMessage>
      );
    } else return null;
  };
  useEffect(() => {
    isUpdatingQuery = false;
  }, []);
  return (
    <div className="matched-scraps">
      <header className="matched-scraps__header">
        <p className="matched-scraps__count">{scrapsCount} Matched Scrap{scrapsCount === 1 ? '' : 's'}</p>
        <div className="matched-scraps__header-actions">
          <Button
            buttonType="outlined-with-primary-background"
            onClick={() => {
              onClose();
              history.push(shareLink);
            }}
          >
            {`View ${dataType === 'collection' ? 'Collection' : 'Share'}`}
          </Button>
          <button onClick={onClose} className="matched-scraps__close-button">
            <NewClose />
          </button>
        </div>
      </header>
      <div className="matched-scraps__scraps" ref={ref}>
        {renderScraps()}
      </div>
    </div>
  );
}
