import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as temp from '../../settings.json';
import './styles.css';
import { userTypes } from '../../types/user';
import SelectedSupport from './SelectedSupport';
import FeedBackIcon from '../Global/icons/FeedBack';
import GoogleIcon from '../Global/icons/GoogleIcon';
import FAQIcon from '../Global/icons/FAQIcon';
import AppleIcon from '../Global/icons/AppleIcon';
import ExtensionIcon from '../Global/icons/Extension';
import { RealeaseIcon } from '../Global/icons';

const NewHelpPage = (props) => {
  const [selectedState, setSelectedState] = useState<
    | 'extension'
    | 'faq'
    | 'appStore'
    | 'playStore'
    | 'release-notes'
    | 'ticket'
    | ''
  >('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { default: config }: any = { ...temp };
  const [isIphone, setIsIphone] = useState(false);
  const supportList = [
    {
      title: 'Download Chrome Extension',
      stateName: 'extension',
      label: 'Install the Chrome Extension',
      description:
        'Use Scrappi from your browser to easily Scrap web content. You can scrap web pages, screenshots, embedded content and recordings without leaving your browser tab. Get the',
      subDescription:
        'As you Scrap, you can add notes to your Scraps, organize your Scraps into Collections and further categorize them via Tags. You can also do all of those things from Scrappi.com later!',
      image1: '/dashboard/img/scrapPage.png',
      image2: '/dashboard/img/scrapSaved.png',
    },
    {
      title: 'Frequently Asked Questions',
      stateName: 'faq',
      description: 'Update your login password.',
    },
    {
      title: 'Download the Mobile App',
      stateName: 'appStore',
      label: 'Download on the App Store',
      description:
        'Use Scrappi from your iOS device to save Scraps easily by sharing web content or files from your phone. Simply click the Share icon from any app to open up your share tray. Then choose Scrappi to create and save the Scrap to your Scrappi Dashboard.',
      subDescription:
        'As you Scrap you can organize your Scraps into Collections. From the mobile app or Scrappi.com you’ll be able to further customize, annotate, and organize your Scraps.',
      image1: '/dashboard/img/appStoreScrapCreate.png',
      image2: '/dashboard/img/appStoreScrapSave.png',
      appLogo: '/dashboard/img/appStore.svg',
      // @ts-ignore
      appLink: `${config?.appStore ? config?.appStore : '/help/#'}`,
    },
    {
      title: 'Download the Mobile App',
      stateName: 'playStore',
      label: 'Get it on Google Play',
      description:
        'Use Scrappi from your Android device to save Scraps easily by sharing web content or files from your phone. Simply click the Share icon from any app to open up your share tray. Then choose Scrappi to create and save the Scrap to your Scrappi Dashboard. ',
      subDescription:
        'As you Scrap you can organize your Scraps into Collections. From the mobile app or Scrappi.com you’ll be able to further customize, annotate, and organize your Scraps.',
      image1: '/dashboard/img/playStoreScrapCreate.png',
      image2: '/dashboard/img/playStoreSave.png',
      appLogo: '/dashboard/img/playStore.svg',
      // @ts-ignore
      appLink: `${config?.playStore ? config?.playStore : '/help/#'}`,
    },
    {
      title: 'Scrappi Release Notes',
      stateName: 'release-notes',
      description:
        'See how your profile page is viewed by Scrappers everywhere.',
    },
    {
      title: 'Submit Feedback',
      stateName: 'ticket',
      description: 'Remove your account and data from Scrappi.',
    },
  ];
  
  const scrollTop=()=>{
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      navigator.userAgent.match('Safari') && setIsIphone(true);
    }
  }, []);
  return selectedState === '' ? (
    <div className="new-help-page__wrapper">
      <div className="new-help-page__title">Scrappi Help Desk</div>
      <div className="new-help-page">
        <div className="new-help-page__nav-tabs-wrapper">
          <div className="new-help-page__nav-tabs-wrapper__nav-tabs">
            <ExtensionIcon />
            <p className="new-help-page__nav-tabs-wrapper__title">
              Download Chrome Extension
            </p>
            <p className="new-help-page__nav-tabs-wrapper__description">
              Scrap from any Chromium-based browser like Edge, Brave, and ...
              there's one more I can't quite remember ... oh yeah, Chrome.
            </p>
            <div className="new-help-page__buttons-wrapper">
              <button
                className="new-help-page__learn-more"
                onClick={() => setSelectedState('extension')}
              >
                <span className="new-help-page__buttons-wrapper__text">
                  Learn More
                </span>
              </button>
              <a
                href={
                  config?.chromeExtensionStoreUrl
                    ? config?.chromeExtensionStoreUrl
                    : '/help/#'
                }
                target="_blank"
                rel="noopener noreferrer"
                className="new-help-page__image-wrapper"
              >
                <img
                  src="/dashboard/img/chromeExtension.svg"
                  alt="chrome-Extension"
                />
              </a>
            </div>
          </div>

          <div className="new-help-page__nav-tabs-wrapper__nav-tabs">
            <FAQIcon />
            <p className="new-help-page__nav-tabs-wrapper__title">
              Frequently Asked Questions
            </p>
            <p className="new-help-page__nav-tabs-wrapper__description">
              Find answers to common questions like "What is a Scurry?"
            </p>
            <div className="new-help-page__buttons-wrapper">
              <button
                className="new-help-page__learn-more"
                onClick={() => setSelectedState('faq')}
              >
                <span className="new-help-page__buttons-wrapper__text">
                  Learn More
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="new-help-page__nav-tabs-wrapper">
          <div className="new-help-page__nav-tabs-wrapper__nav-tabs">
            <AppleIcon />
            <p className="new-help-page__nav-tabs-wrapper__title">
              Download on the App Store
            </p>
            <p className="new-help-page__nav-tabs-wrapper__description">
              iPhones rule! Sorry it won't work on the iPod touch but you can
              get the awesome iPhone Scrappi app here.
            </p>
            <div className="new-help-page__buttons-wrapper">
              <button
                className="new-help-page__learn-more"
                onClick={() => setSelectedState('appStore')}
              >
                <span className="new-help-page__buttons-wrapper__text">
                  Learn More
                </span>
              </button>
              {/*// @ts-ignore */}
              <a
                className="new-help-page__image-wrapper"
                href={config?.appStore ? config?.appStore : '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="apple-logo" src={'/dashboard/img/appStore.svg'}></img>
              </a>
            </div>
          </div>
          {isIphone && (
            <div className="new-help-page__nav-tabs-wrapper__nav-tabs">
              <GoogleIcon />
              <p className="new-help-page__nav-tabs-wrapper__title">
                Get it on Google Play
              </p>
              <p className="new-help-page__nav-tabs-wrapper__description">
                Android rules! Be sure to upgrade grandpa's Galaxy S2 so he can
                use Scrappi too.
              </p>
              <div className="new-help-page__buttons-wrapper">
                <button
                  className="new-help-page__learn-more"
                  onClick={() => setSelectedState('playStore')}
                >
                  <span className="new-help-page__buttons-wrapper__text">
                    Learn More
                  </span>
                </button>
                {/*// @ts-ignore */}
                <a
                  className="new-help-page__image-wrapper"
                  href={config?.playStore ? config?.playStore : '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="playstore-Logo"
                    src={'/dashboard/img/playStore.svg'}
                  />
                </a>
              </div>
            </div>
          )}
        </div>

        <div className="new-help-page__nav-tabs-wrapper">
          <div className="new-help-page__nav-tabs-wrapper__nav-tabs">
            <RealeaseIcon />
            <p className="new-help-page__nav-tabs-wrapper__title">
              Release Notes
            </p>
            <p className="new-help-page__nav-tabs-wrapper__description">
              A special place for those of us that enjoy reading release notes
              just before bed.
            </p>
            <div className="new-help-page__buttons-wrapper">
              <button
                className="new-help-page__learn-more"
                onClick={() =>{
                  scrollTop(); 
                  setSelectedState('release-notes') ; 
                }}
              >
                <span className="new-help-page__buttons-wrapper__text">
                  Learn More
                </span>
              </button>
            </div>
          </div>
          <div className="new-help-page__nav-tabs-wrapper__nav-tabs">
            <FeedBackIcon />
            <p className="new-help-page__nav-tabs-wrapper__title">
              Submit Feedback
            </p>
            <p className="new-help-page__nav-tabs-wrapper__description">
              Checkout our subreddit for Scrappers to share tools, tips, praise
              and (gulp) complaints.
            </p>
            <div className="new-help-page__buttons-wrapper">
              <button
                className="new-help-page__learn-more"
                onClick={() => setSelectedState('ticket')}
              >
                <span className="new-help-page__buttons-wrapper__text">
                  Send FeedBack
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SelectedSupport
      selectedState={selectedState}
      setSelectedState={setSelectedState}
      supportList={supportList}
    />
  );
};
const mapStateToProps = (state: { user: userTypes }) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(NewHelpPage);
