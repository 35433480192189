import { gql } from '@apollo/client';

export const LOGIN_METHOD = gql`
  mutation AddLoginMethod(
    $otp: String
    $country_code: String
    $phone: String
    $social_token: String
    $social_auth_code: String
    $social_id: String
    $login_via: Int
    $email: String
    $type: String
  ) {
    addLoginMethod(
      otp: $otp
      social_token: $social_token
      social_auth_code: $social_auth_code
      social_id: $social_id
      login_via: $login_via
      phone: $phone
      email: $email
      country_code: $country_code
      type: $type
    ) {
      messages {
        error
        success
      }
      http_code
    }
  }
`;

export const UPSERT_LOGIN_METHOD = gql`
mutation UpsertLoginMethod(
  $otp: String
  $email: String
  $old_email: String
  $phone: String
  $login_via: Int
  $country_code: String
  $remove: Boolean
  $update: Boolean
  $mark_primary: Boolean
){
  upsertLoginMethod(
    otp: $otp
    email: $email
    old_email: $old_email
    phone: $phone
    login_via: $login_via
    country_code: $country_code
    remove: $remove
    update: $update
    mark_primary: $mark_primary
  ){
    messages {
      error
      success
    }
    http_code
  }
}
`;
