import React from 'react';

interface Proptypes {
  className?: string;
}

const Highlight= ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="20"
      height="16"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#D9D9D9"
        d="M229.6 796.3h160.2l54.3-54.1-80.1-78.9zm220.7-397.1l262.8 258.9 147.3-145-262.8-259zm-77.1 166.1l171.4 168.9 68.6-67.6-171.4-168.9z"
      />
      <path d="M957.6 507.5L603.2 158.3a7.9 7.9 0 0 0-11.2 0L353.3 393.5a8.03 8.03 0 0 0-.1 11.3l.1.1 40 39.4-117.2 115.3a8.03 8.03 0 0 0-.1 11.3l.1.1 39.5 38.9-189.1 187H72.1c-4.4 0-8.1 3.6-8.1 8v55.2c0 4.4 3.6 8 8 8h344.9c2.1 0 4.1-.8 5.6-2.3l76.1-75.6L539 830a7.9 7.9 0 0 0 11.2 0l117.1-115.6 40.1 39.5a7.9 7.9 0 0 0 11.2 0l238.7-235.2c3.4-3 3.4-8 .3-11.2zM389.8 796.3H229.6l134.4-133 80.1 78.9-54.3 54.1zm154.8-62.1L373.2 565.3l68.6-67.6 171.4 168.9-68.6 67.6zm168.5-76.1L450.3 399.2l147.3-145.1 262.8 259-147.3 145z" />
    </svg>
  );
};

export default Highlight;
