import React from 'react';
import { ScrapImageData } from '../../../containers/ScrapImagesPopupContainer';
import { isEmpty, parseNote } from '../../../helpers';
import { DownloadIcon, PencilEditIcon } from '../../Global/icons';
import Tiptap from '../../Global/Tiptap';
import './styles.css';
import { useDispatch } from 'react-redux';
import { setImageDisplayPopup } from '../../../redux/action/utils';
interface Proptypes {
  images: ScrapImageData[];
  defaultImageIndex?: number;
  onImageEdit?: (image: ScrapImageData) => void;
}

export default function ScrapImagesView({
  images,
  defaultImageIndex,
  onImageEdit,
}: Proptypes) {
  const dispatch = useDispatch();
  return (
    <div className="scrap-images-view">
      {images.map((imageData, i) => {
        const { description, imageViewUrl, filename, id } = imageData;
        const isDescriptionEmpty = isEmpty(description, true);
        return (
          <div key={id || i} className="scrap-images-view__item-wrapper">
            <div className="scrap-images-view__item">
              <div className="scrap-images-view__image-wrapper">
                <img
                  onClick={() => {
                    dispatch(
                      setImageDisplayPopup({
                        imageUrl: imageViewUrl,
                      })
                    );
                  }}
                  className="scrap-images-view__image"
                  src={imageViewUrl}
                  alt="no-i"
                />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="scrap-images-view__download-button hoverState__with-white-text"
                  href={imageViewUrl}
                  download={filename}
                >
                  <DownloadIcon />
                </a>
                {onImageEdit && (
                  <button
                    className="scrap-images-view__edit-button hoverState__with-white-text"
                    onClick={() => onImageEdit(imageData)}
                  >
                    <PencilEditIcon />
                  </button>
                )}
              </div>
              {!isDescriptionEmpty && (
                <div className="scrap-images-view__description">
                  <Tiptap text={parseNote(description)} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
