import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $old_password: String!
    $password: String!
    $password_confirmation: String!
  ) {
    changePassword(
      old_password: $old_password
      password: $password
      password_confirmation: $password_confirmation
    ) {
      ${defaultResponse}
    }
  }
`;
