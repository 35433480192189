import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ReloadContext from '../../../../helpers/contexts/reloadContext';
import { setSavedScrap, setScrapPopup } from '../../../../redux/action/utils';
import { entryTypes } from '../../../../types/feed';
import { ExclamationIcon } from '../../../Global/icons';
import NewPopup from '../../../Global/NewPopup';
import { getScrapData } from './getScrapData';
import './styles.css';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../../../Global/InvisibleRecaptcha';
import ValidateHuman from '../../../../helpers/validators/isHuman';
interface Proptypes {
  className?: string;
  controlled?: {
    show: boolean;
    setShow: (val: boolean) => void;
  };
}
const CreateWebPage = (props: Proptypes) => {
  const { className, controlled } = props;

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [pageURL, setPageURL] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  // CREATE SCRAP MUTATION
  const { setReload } = useContext(ReloadContext);
  const [isValid, setIsValid] = useState(true);
  const dispatch = useDispatch();
  const autoFocus = useCallback((el) => {
    if (el) {
      setTimeout(() => {
        el.focus();
      }, 250);
    }
  }, []);

  const createHandler = async () => {
    setLoading(true);

    const isHuman= await ValidateHuman(reCaptchaRef.current);

    //if request is made by a bot then  return without hitting server
    if(!isHuman){
      setReload(true);
      setLoading(false);
      controlled.setShow(false);
      return false;
    }
    try {
      // @ts-ignore
      const scrapData: {
        data: {
          createScrap: {
            data: entryTypes;
            messages: {
              success: Array<string>;
              error: Array<string>;
            };
          };
        };
      } = await getScrapData(pageURL);

   
      if (scrapData.data?.createScrap?.messages?.error?.length) {
        setErrorMessage(scrapData.data.createScrap.messages.error[0]);
        setLoading(false);
      } else {
        let scrapDetails = scrapData.data.createScrap.data;
        if(!scrapDetails.config)
        {
          scrapDetails.config=JSON.stringify("{\"img_status\":0}");
        }
        controlled.setShow(false);
        setReload(true);
        // dispatch(setSavedScrap(scrapDetails));
        setLoading(false);
        dispatch(
          // eslint-disable-next-line no-undef
          setScrapPopup({
            type: 'id',
            defaultEdit: true,
            data: scrapDetails.id,
            headerTitle:"Scrap a Web Page",
            // reloadBackgroundOnClose: true,
          })
        );
      }
    } catch (e) {
      setErrorMessage(
        "Sorry... we can't save this page right now. An error case has been logged for review."
      );
      setLoading(false);
    }
  };

  const isValidUrl = (urlString: string) => {
    // eslint-disable-next-line max-len
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      urlString
    );
  };

  const render = (close) => {
    return (
      <div className="create-web-page__wrapper">
        <InvisibleRecaptcha inputRef={reCaptchaRef}/>
        <input
          autoFocus
          ref={autoFocus}
          placeholder="Enter URL"
          className={`text-input__input ${
            errorMessage?.length > 0 ? 'input-error' : ''
          }`}
          type="url"
          value={pageURL}
          onChange={(e) => {
            setPageURL(e.target.value);
            setErrorMessage('');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (!isValidUrl(pageURL)) {
                setIsValid(false);
              } else if (pageURL.length) {
                createHandler();
              }
            } else if (!isValid) {
              setIsValid(true);
            }
          }}
          required
          disabled={loading}
        />
        {pageURL?.length > 0 && !isValid && (
          <p className="create-web-page__error invalid">Invalid URL</p>
        )}
        {errorMessage && errorMessage.length > 0 && (
          <p className="create-web-page__error">
            <span className="create-web-page__error-icon">
              <ExclamationIcon />
            </span>
            {errorMessage ||
              "Sorry... we can't save this page right now. An error case has been logged for review."}
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!controlled.show) {
      setPageURL('');
      setErrorMessage(null);
    }
  }, [controlled]);

  return (
    <NewPopup
      className="create-web-page__popup"
      header={{
        heading: 'Scrap a Web Page',
      }}
      size="Small"
      body={{
        className: className || '',
      }}
      footer={{
        onSubmit: () => {
          createHandler();
        },
        disableSubmit: !isValidUrl(pageURL) || loading,
        disableCancel: loading,
        submitLabel: loading ? 'Saving' : null,
        className: 'create-web-page__footer',
      }}
      controlled={controlled}
    >
      {(close) => render(close)}
    </NewPopup>
  );
};

export default CreateWebPage;
