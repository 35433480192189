import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReloadContext from '../../helpers/contexts/reloadContext';
import { scrapType } from '../../types/scrapType';
import { RequestStatusObjectType } from '../../types/requestStatusType';
import { useApolloClient} from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../redux/store';
import SCRAP from '../data/scrap';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import { setBackUrl, setScrapPopup } from '../../redux/action/utils';
import NewScrapPopupContainer from '../NewScrapPopupContainer';
import { setOneScrapAction } from '../../redux/action/onScrapActions';
import GetScrapDocuments from '../data/getScrapDocuments';
import GetScrapImages from '../data/getScrapImages';
import GetScrapAnnotations from '../data/getScrapAnnotations';
interface Proptypes {
  readOnly?: boolean;
  isEdit?: boolean;
  isReduxPopup?: boolean;
}
const OneScrapPopupContainer = ({
  readOnly,
  isEdit,
  isReduxPopup,
}: Proptypes) => {
  const { setReload } = useContext(ReloadContext);
  const { pathname } = useLocation();
  const { replace } = useHistory();

  const client = useApolloClient();

  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateType) => state.user);
  const { backUrl, scrollToBottom, scrapPopup } = useSelector(
    (state: ReduxStateType) => state.utils
  );
  const [editMode, setEditMode] = useState(isEdit);
  const [scrap, setScrap] = useState<scrapType>(null);
  const [imagesData, setImagesData] = useState<scrapType['images']>(null);
  const [fetchStatus, setFetchStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });

  const onFetchData = async (scrap: scrapType) => {
    if(!scrap.documents_count) return
    const { data } = await client.query({
      query: GetScrapDocuments(),
      variables: {
        scrap_id: scrap?.id,
        first: scrap?.documents_count,
        page: 1,
        username: scrap?.user_name,
        private_key: scrap?.private_key,
        skip_first: 0,
      },
    });

    if (data?.getScrapDocuments?.data) {
      let files = data?.getScrapDocuments?.data;
      dispatch(
        setOneScrapAction({
          type: 'edit',
          scrap: {
            id: +scrap.id,
            documents: files,
          },
        })
      );
    }
    return data?.getScrapDocuments?.data;
  };

  const onFetchAnnotations = async (scrap: scrapType) => {
    if(!scrap.annotations_count) return;
    const { data } = await client.query({
      query: GetScrapAnnotations(),
      variables: {
        scrap_id: scrap?.id,
        first: scrap?.annotations_count,
        page: 1,
        username: scrap?.user_name,
        private_key: scrap?.private_key,
        skip_first: 0,
        sort_by: 'asc',
        sort_param: 'order',
      },
    });
    return  data?.getScrapAnnotations?.data;
  }

  const onFetchImageData = async (scrap: scrapType) => {
    if(!scrap.images_count) return;
    const { data } = await client.query({
      query: GetScrapImages(),
      variables: {
        scrap_id: scrap?.id,
        first: scrap.images_count,
        page: 1,
        private_key: scrap?.private_key,
        username: scrap?.user_name,
        skip_first: 0,
      },
    });

    let imageData = data?.getScrapImages?.data.map(function (item) {
      delete item.file_origin;
      delete item.title;
      delete item.scrap_id;
      return item;
    });

    if (data?.getScrapImages?.data) {
      dispatch(
        setOneScrapAction({
          type: 'edit',
          scrap: {
            id: +scrap.id,
            images: imageData,
          },
        })
      );
    }

    return data?.getScrapImages?.data.sort((a, b) => a.order - b.order);
  };

  // Function for fetching scrap's data
  async function fetchScrapHandler(scrap_id: string, returnScrapData = false) {
    try {
      setFetchStatus({ status: 'processing' });
      const variables = {
        scrap_id,
      };
      const response = await client.query({ query: SCRAP, variables });
      const scrapData: scrapType = response.data.scrap.data;
      if (!scrapData.id) throw new Error('Failed to fetch scrap data');
      if (!scrapData.state) scrap.state = 1;
      if (returnScrapData) return scrapData;
      let scrapFetchImage=scrapData?.images;
      let scrapFetchDocument=scrapData?.documents;
      let scrapFetchAnnotations= scrapData?.annotations;
      setScrap(scrapData);
      if (scrapData?.images_count > 5) {
        scrapFetchImage = await onFetchImageData(scrapData);
      }
      if (scrapData?.documents_count > 5) {
        scrapFetchDocument = await onFetchData(scrapData);
      }
      if (scrapData?.annotations_count > 5) {
        scrapFetchAnnotations = await onFetchAnnotations(scrapData);  
      }
      (scrapFetchImage.length > 0 || scrapFetchDocument.length >0|| scrapFetchAnnotations.length > 0) && setScrap({
        ...scrapData,
        documents: scrapFetchDocument,
        images:scrapFetchImage,
        annotations:scrapFetchAnnotations
      });
      
      //   initializeInputsHandler(scrap);
      //   setScrapImage(scrap.image || scrap.thumbnail);
      setFetchStatus({ status: 'success' });
    } catch (error) {
      setFetchStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  }

  // Function which will be called to close scrap details popup
  function closeHandler(val?: boolean) {
    if (!scrapPopup) return;
    if (!scrapPopup.fromUrl) {
      // True if scrap details popup is opened by redux, and url is not changed when displaying it
      scrapPopup.reloadBackgroundOnClose && setReload(true);
      dispatch(setScrapPopup(null));
      dispatch(setBackUrl(null));
      setEditMode(false);
    } else {
      // Else scrap details popup is displayed by the help of url
      dispatch(setScrapPopup(null));
      let url;

      if (typeof pathname === 'string') {
        url = pathname.split('/scrap/')[0];
        if (!url) url = '/';
        if (backUrl) url += backUrl;
      }
      replace(url, {
        dontReload: true,
      });
      dispatch(setBackUrl(null));
    }
  }
  async function saveCompletedHandler(scrap: Partial<scrapType>, dontDispatchUpdate?: boolean) {
    !dontDispatchUpdate && dispatch(
      setOneScrapAction({
        type: 'edit',
        scrap,
      })
    );
    setScrap(old => ({ ...old, ...scrap }));
  }
  useEffect(() => {
    setScrap(null);
    setFetchStatus({ status: 'not-started' });
    if (scrapPopup) {
      if (scrapPopup.type === 'id') {
        fetchScrapHandler(scrapPopup.data as string);
      } else {
        setScrap(scrapPopup.data as scrapType);
        // initializeInputsHandler(scrapPopup.data as entryTypes);
        setFetchStatus({ status: 'success' });
      }
      if (scrapPopup.defaultEdit) setEditMode(true);
    }
  }, [scrapPopup]);
  useEffect(() => {
    if (params.id) {
      dispatch(
        setScrapPopup({
          type: 'id',
          data: params.id,
          readOnly,
          fromUrl: true,
        })
      );
      return () => {
        document.body.style['overflow'] = 'auto';
      }
    }
  }, []);

  useEffect(() => {
    if (scrap && scrap.images?.length === 1) {
      const { file_path, file_status } = scrap.images[0];
      if (!file_path && +file_status !== 3) {
        const timer = setInterval(async () => {
          const response = await client.query({
            query: SCRAP,
            variables: {
              scrap_id: scrap.id,
            },
          });
          const scrapData: scrapType = response.data.scrap.data;
          const { file_path, file_status } = scrapData.images[0];
          if (file_path || +file_status === 3) {
            dispatch(
              setOneScrapAction({
                type: 'edit',
                scrap: {
                  id: scrap.id,
                  images: scrapData.images,
                },
              })
            );
            setScrap((old) => ({ ...old, images: scrapData.images }));
            setImagesData(scrapData.images);
          }
        }, 10000);
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [scrap]);

  useEffect(()=>{
    if(fetchStatus?.status === "success" && scrollToBottom)
    {
      if (typeof window === 'object') {
        let elem = document.getElementsByClassName(
          'scrap-share__actions-toggle-description'
        )[0];
        (scrollToBottom ) &&
          elem?.scrollIntoView({ behavior: 'smooth' });
      }
    }
    
  },[fetchStatus?.status])

  const params = useParams<{ id?: string }>();
  if ((params.id || pathname.includes('/scrap/')) && isReduxPopup) {
    return null;
  }
  return (
    <NewScrapPopupContainer
      existingScrap={{
        data: scrap,
        isLoading: fetchStatus.status === 'processing',
        onClose: () => {
          closeHandler();
        },
        show: !!scrapPopup,
        onSaved: saveCompletedHandler,
        heading: scrapPopup?.headerTitle,
        imagesData,
      }}
    />
  );
};

export default OneScrapPopupContainer;
