import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';
const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['text', 'String'],
  ['tags', '[TagSlugInput]'],
  ['untagged', 'Boolean'],
  ['collection_id', 'ID'],
  ['uncategorized', 'Boolean']
]);
const SCRAPS_FILTER_OPTIONS = gql`
  query ScrapsFilterOptions(
    ${parametersTypeString}
  ) {
    scrapsFilterOptions(${parametersAssignString}) {
      ${defaultResponse}
      data {
        type
        label
        values {
            key
            doc_count
        }
      }
    }
  }
`;
export default SCRAPS_FILTER_OPTIONS
