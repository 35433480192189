import React from 'react';
import './styles.css';

interface Proptypes {
  count: number;
  onClick?: () => void;
}

const SearchResultCountContainer = ({ count, onClick }: Proptypes) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className="search-count"
    >
      {count} Matched Scrap{count === 1 ? '' : 's'}
    </div>
  );
};

export default SearchResultCountContainer;
