import React, { useEffect, useRef } from 'react';

import './styles.css';

interface Proptypes {
  text: string;
  onChange?: (text: string) => void;
  minHeight: number;
  placeholder?: string;
}

const AutoGrowingTextarea = ({
  text,
  onChange,
  minHeight,
  placeholder = 'Add Something',
}: Proptypes) => {
  const textareaRef = useRef(null);
  
  useEffect(() => {
    adjustTextareaHeight(text);
  }, [text]);

  const adjustTextareaHeight = (text: string) => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset the height to auto to recalculate the proper height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scroll height
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <textarea
      rows={1}
      disabled={!onChange}
      placeholder={!onChange ? '' : placeholder}
      className="autogrowing-textarea"
      ref={textareaRef}
      value={text}
      onChange={handleChange}
      style={{ minHeight }} // Set a minimum height for the textarea
    />
  );
};

export default AutoGrowingTextarea;
