import React from 'react';
import ResponsiveImage from '../ResponsiveImage';

import './styles.css';

interface Proptypes {
    images: string[];
    // making title as optional prop 
    title?: string;
    //ADDED: additional prop needWrapper 
    //WORKING: will show whether or not component needs to return with wrapper div or not 
    //Default-value: true
    //REASON: reusing this component
    needWrapper?: boolean;
    
}

const RenderThumbnail = (props: Proptypes) => {

  
  const { images, title,needWrapper=true } = props
  let content;
  if (images?.length === 0) {
    // render no image thumbnail
    content = (
      <div className="no-image-thumbnail">
        <h3 className="custom-thumbnail__title">{title}</h3>
      </div>
    );
  } else if (images?.length === 1) {
    // render whole image thumbnail
    content = (
      <div className="one-image-thumbnail">
        <div>
          <ResponsiveImage sizes={['800']} image={images[0]} 
            alt="cover banner"
            imageClass="image-1" />
        </div>
      </div>
    );
  } else if (images?.length === 2) {
    // render 2 images thumbnail
    content = (
      <div className="two-images-thumbnail">
        <div>
          <ResponsiveImage sizes={['800']} image={images[0]} 
            alt="cover banner 1"
            imageClass="image-1" />
        </div>
        <div>
          <ResponsiveImage sizes={['800']} image={images[1]} 
            alt="cover banner 2"
            imageClass="image-2" />
        </div>
      </div>
    );
  } else {
    // render 3 images thumbnail
    content = (
      <div className="three-images-thumbnail">
        <div>
          <ResponsiveImage sizes={['800']} image={images[0]} 
            alt="cover banner 1"
            imageClass="image-1" />
        </div>
        <div>
          <ResponsiveImage sizes={['800']} image={images[1]} 
            alt="cover banner 2"
            imageClass="image-2" />

          <div className="three-images-thumbnail__more">
            <ResponsiveImage sizes={['800']} image={images[2]} 
              alt="cover banner 3"
              imageClass="image-3" />

            {images.length > 3 && (
              <span className="card-gallery__count">
                +{images.length - 2}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
  return (
    !needWrapper?content:<div className="custom-thumbnail">
      {content}
    </div>
  )

}


export default RenderThumbnail
