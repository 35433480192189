import React from 'react';

import { Link, useHistory } from 'react-router-dom';
import AuthHeaderLayout from './components/AuthHeaderLayout';

import './styles.css';

interface Proptypes {
  title?: string;
  message?: string;
  handleClick: (type: 'login' | 'register') => void;
  show?: 'login' | 'sign up';
}

const Auth = ({ title, message, handleClick, show }: Proptypes) => {
  function renderAuthMethods() {  
    switch (show) {
      case 'login':
        return handleClick('login');
      case 'sign up':
        return handleClick('register');

      default:
        return handleClick('register');
    }
  }
  return (
    <AuthHeaderLayout
      className="auth"
      title={title}
      helmetTitle={title}
      subHeading={message}
    >
      {renderAuthMethods()}
    </AuthHeaderLayout>
  );
};

export default Auth;
