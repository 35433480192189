import React from 'react';

import './styles.css';

const ScrapSavedLoader = () => {
  return (
    <div className="scrap-saved-loader">
      <p>Loading scrap</p>
    </div>
  );
};

export default ScrapSavedLoader;
