import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', ' ID!'],
  ['first', 'Int'],
  ['page', 'Int'],
  ['skip_first', 'Int'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['username', 'String'],
  ['private_key', 'String']
]);

export default function GetScrapAnnotations(responseString = '') {
  return gql`
  query getScrapAnnotations(${parametersTypeString}){
    getScrapAnnotations(${parametersAssignString}){
      ${defaultResponse}
      paginatorInfo {
        total
      }
      data{
        comment
        highlighted_text
        id
        scrap_id
        hash
        order
        ${responseString}
      }
    }
  } 
  `;
}
