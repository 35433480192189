import React from 'react';

interface Proptypes extends React.SVGAttributes<SVGSVGElement> {
  isFilled?: boolean;
}

const BookmarkIcon = (props: Proptypes) => {
  const elementAttributes = { ...props };
  delete elementAttributes.isFilled;
  if (props.isFilled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...elementAttributes}
      >
        <path
          d="M4.99976 21V5C4.99976 4.45 5.19576 3.979 5.58776 3.587C5.97909 3.19567 6.44976 3 6.99976 3H16.9998C17.5498 3 18.0208 3.19567 18.4128 3.587C18.8041 3.979 18.9998 4.45 18.9998 5V21L11.9998 18L4.99976 21Z"
          fill="#969696"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...elementAttributes}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.30235 3.91495C5.12514 4.15254 5.08174 4.29577 5.08174 4.43494V15.6411L9.99992 12.7376L14.9181 15.6411V4.43494C14.9181 4.27777 14.9011 4.19807 14.8828 4.14867C14.87 4.11396 14.8461 4.06496 14.782 3.99586C14.5312 3.82452 14.3621 3.76973 14.1817 3.76973H5.8181C5.54831 3.76973 5.4305 3.80533 5.30235 3.91495ZM4.01418 2.65413C4.59396 2.09956 5.20922 1.96973 5.8181 1.96973H14.1817C14.8971 1.96973 15.4437 2.25436 15.8882 2.57322L15.9398 2.61024L15.9857 2.65413C16.2539 2.91074 16.4504 3.19721 16.5715 3.52556C16.6897 3.84573 16.7181 4.15733 16.7181 4.43494V18.794L9.99992 14.8279L3.28174 18.794V4.43494C3.28174 3.71499 3.59522 3.17365 3.92804 2.74918L3.96772 2.69858L4.01418 2.65413Z"
        fill="#969696"
      />
    </svg>
  );
};

export default BookmarkIcon;
