import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import TripleDot from '../../../Global/icons/TripleDot';
import { PromptProps } from 'react-router-dom';
import useOutsideAlerter from '../../../../helpers/customHooks/useOutsideAlerter';

interface Proptypes {
  handleRemoveContact: () => void;
  selectedUserName?: string;
  userName?:string;
  setSelectedUserName?:(val:string)=>void;
}
const ContactCardOptions = (prop: Proptypes) => {
  // const ref = useRef<HTMLInputElement>();
  const ref = useRef(null);
  
  const { handleRemoveContact ,selectedUserName, userName ,setSelectedUserName } = prop;
  const [remove, setRemove] = useState(false);
  useOutsideAlerter(ref, () => setRemove(false), !remove, true);
  return (
    <div className="contact-card__options">
      <span
        className="contact-card__options"
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedUserName(userName)
          setRemove(selectedUserName===userName?!remove:true);
        }}
      >
        <TripleDot />
      </span>
      {remove && (selectedUserName===userName) &&  (
        <button
          className="contact-card__remove"
          onClick={()=>handleRemoveContact()}
          ref={ref}
        >
          Remove Contact
        </button>
      )}
    </div>
  );
};

export default ContactCardOptions;
