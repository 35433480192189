const DEFAULT = {
  compact: true,
};

const user = (state = DEFAULT, action) => {
  switch (action.type) {
    case 'LOAD_USER':
      return { ...action.user, compact: true };

    case 'UPDATE_GRID':
      return { ...state, compact: action.compact };
    case 'UPDATE_USER_DETAILS':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default user;
