import React from 'react';

interface Proptypes {
  className?: string;
}

const VideoPlayButton = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.399902 3.43119V27.1562C0.400044 27.6114 0.518159 28.059 0.742817 28.4554C0.967475 28.8519 1.29107 29.1839 1.68229 29.4193C2.07351 29.6547 2.51911 29.7855 2.97596 29.7991C3.43282 29.8127 3.88547 29.7086 4.29012 29.4969L26.9808 17.6344C27.409 17.4101 27.7675 17.0734 28.0176 16.6609C28.2677 16.2484 28.3999 15.7756 28.3999 15.2937C28.3999 14.8118 28.2677 14.339 28.0176 13.9265C27.7675 13.5139 27.409 13.1773 26.9808 12.9529L4.29012 1.10366C3.88647 0.892477 3.43503 0.788375 2.97927 0.801377C2.52352 0.81438 2.0788 0.944049 1.68795 1.1779C1.2971 1.41174 0.973283 1.74189 0.747689 2.13655C0.522094 2.5312 0.40232 2.97706 0.399902 3.43119Z"
        fill="white"
      />
    </svg>
  );
};

export default VideoPlayButton;
