import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const PrevIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.78033 0.209209C5.48744 -0.0697365 5.01256 -0.0697366 4.71967 0.209209L0.21967 4.49492C-0.0732231 4.77387 -0.0732231 5.22613 0.21967 5.50508L4.71967 9.79079C5.01256 10.0697 5.48744 10.0697 5.78033 9.79079C6.07322 9.51184 6.07322 9.05958 5.78033 8.78064L1.81066 5L5.78033 1.21936C6.07322 0.940416 6.07322 0.488155 5.78033 0.209209Z"
        fill="white"
      />
    </svg>
  );
};

export default PrevIcon;
