import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import ReactCropper, { ReactCropperElement } from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import './styles.css';
import { AntiClockwiseIcon, ClockwiseIcon, RecycleIcon, ZoomInIcon, ZoomOutIcon } from '../icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const faSpinnerIcon = faSpinner as IconProp;
/**
 * Always pass ref to this component
 * since we will keep the crop button outside of
 * this component and manage the click event of crop using
 * useImperativeHandle
 */

interface Proptypes {
  imageUrl: string;
  onCrop: (file: Blob, isCropped: boolean) => void;
  aspectRatio?: number;
  circularCrop?: boolean;
  toggleDragModeOnDblclick?: boolean;
  minCropBoxHeight?: number;
  minCropBoxWidth?: number;
  imageType?: string;
  customRenderControls?: (list: CropControlType[]) => React.ReactElement;
  movable?:boolean;
  zoomOnTouch?:boolean;
}
export interface CropControlType {
  type: string;
  onClick: () => void;
  title: string;
  icon: React.ReactElement;
}

const ImageCropper = forwardRef(
  (
    {
      imageUrl,
      onCrop,
      aspectRatio,
      circularCrop,
      toggleDragModeOnDblclick,
      minCropBoxHeight,
      minCropBoxWidth,
      imageType,
      customRenderControls,
      movable,
      zoomOnTouch
    }: Proptypes,
    ref
  ) => {
    const isCroppedRef = useRef<boolean>(false)
    const cropperRef = useRef<ReactCropperElement>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [cropperInstance, setCropperInstance] = useState<any>();

    const cropHandler = () => {
      const imageElement: ReactCropperElement = cropperRef?.current;
      const cropper = imageElement?.cropper;
      setTimeout(() => {
        cropper.getCroppedCanvas({
          maxHeight: 5000,
          maxWidth: 5000,
        }).toBlob(
          (blob) => {
            onCrop(blob, isCroppedRef.current);
          },
          imageType || 'image/png',
          1
        );
      },0)
      
    };
    useImperativeHandle(ref, () => ({
      onCropClick() {
        cropperInstance.crop(cropHandler());
      },
    }));
    const aspect = circularCrop ? 1 : aspectRatio;
    const cropperControlsList: Array<{
      type: string;
      onClick: () => void;
      title: string;
      icon: React.ReactElement
    }> = [
      {
        type: 'reset',
        onClick: () => cropperInstance.reset(),
        title: 'Revert image to initial State',
        icon: <RecycleIcon />,
      },
      {
        type: 'rotate-clockwise',
        onClick: () => cropperInstance.rotate(90),
        title: 'Rotate clockwise',
        icon: <ClockwiseIcon />,
      },
      {
        type: 'rotate-anti-clockwise',
        onClick: () => cropperInstance.rotate(-90),
        title: 'Rotate anti-clockwise',
        icon: <AntiClockwiseIcon />,
      },
      {
        type: 'zoom-out',
        onClick: () => cropperInstance.zoom(-0.1),
        title: 'Zoom out',
        icon: <ZoomOutIcon />
      },
      {
        type: 'zoom-in',
        onClick: () => cropperInstance.zoom(0.1),
        title: 'Zoom in',
        icon: <ZoomInIcon />,
      },
    ];

    useEffect(() => {
      isCroppedRef.current = false;
    }, [imageUrl])

    // This useEffect disables swipe gestures which is causing issues in mobile app
    // because in mobile app there is a swipe gesture which reloads the web view
    useEffect(() => {
      const handleTouchMove = (e) => {
        e.preventDefault();
      };
      // Add the event listener when the component mounts
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
  
      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener('touchmove', handleTouchMove);
      };
    }, []); // Empty dependency array ensures that the effect runs only once when the component mounts
    return (
      <div
        className={`image-cropper${
          circularCrop ? ' image-cropper__circular' : ''
        }`}
      >
        <div className="image-cropper__image-wrapper">
          <div className="image-cropper__loader">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="3x" />
          </div>
          <ReactCropper
            cropstart={() => {
              isCroppedRef.current = true;
            }}
            toggleDragModeOnDblclick={toggleDragModeOnDblclick}
            autoCropArea={1}
            minCropBoxHeight={minCropBoxHeight}
            minCropBoxWidth={minCropBoxWidth}
            aspectRatio={aspect}
            src={imageUrl}
            ref={cropperRef}
            initialAspectRatio={aspect}
            dragMode="move"
            onInitialized={(crop) => {
              setCropperInstance(crop);
            }}
            style={{
              height: 'calc(var(--screen-height) - 284px)',
              width: '100%',
            }}
            rotatable
            responsive
            className="image-cropper__image"
            viewMode={1}
            movable={movable}
            zoomOnTouch={zoomOnTouch}
            zoom={(e)=>{return !zoomOnTouch && e?.detail?.originalEvent?e.preventDefault():null}}
          />
        </div>
        {customRenderControls ? (
          customRenderControls(cropperControlsList)
        ) : (
          <div className="image-cropper__controls">
            {cropperControlsList.map(({ title, type, icon, onClick }) => (
              <button
                key={type}
                title={title}
                className="button button__outline image-cropper__controls-button image-cropper__controls-reset"
                onClick={onClick}
              >
                {icon}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default ImageCropper;
