import React, { useMemo } from 'react';
import './styles.css';
import CollectionHeaderAvatar from '../../../CollectionCreationWrapper/components/CollectionCreationHeader/CollectionHeaderAvatar';
import { collectionType } from '../../../../types/collections';
import { GroupMemberType } from '../../../../containers/GroupShareContainer/types';

interface Proptypes {
  fromString?: string;
  collaborators: collectionType['members'] | GroupMemberType[];
  collectionId?: number | string;
  onCollaboratorIconClick?: () => void;
  onViewMoreClick?: () => void;
  shareView?: boolean;
}

const NewCollectionCollaborators = ({
  fromString,
  collaborators,
  collectionId,
  onCollaboratorIconClick,
  onViewMoreClick,
  shareView,
}: Proptypes) => {
  const checkCollaboratorsLength = (collaborators) => {
    if (collaborators.length > 10) {
      return [...collaborators].splice(0, 10);
    } else return collaborators;
  };
  const membersToShow = useMemo(() => {
    if (onCollaboratorIconClick) return (collaborators ?? []).slice(0,2);
    return checkCollaboratorsLength(collaborators ?? []) 
  }, [collectionId, collaborators, onCollaboratorIconClick]);
  if (collaborators?.length) {
    return (
      <ul
        className="new-collection-card__footer-collaborators"
        key={collectionId}
      >
        {membersToShow.map(({ user_name, display_name, avatar, user_id }) => (
          <li title={user_name} key={user_id + collectionId}>
            {user_name && (
              <CollectionHeaderAvatar
                fromString={fromString}
                className="new-collection-card__footer-collaborators__icon"
                userName={user_name}
                image={avatar}
                onClick={onViewMoreClick}
              />
            )}
          </li>
        ))}
        {onCollaboratorIconClick && (
          <li>
            <CollectionHeaderAvatar
              fromString={fromString}
              onClick={onCollaboratorIconClick}
              className="new-collection-card__footer-collaborators__icon"
              isInvite
              isEditPopup
            />
          </li>
        )}
        {!onCollaboratorIconClick && collaborators.length > 10 && (
          <li>
            <CollectionHeaderAvatar
              fromString={fromString}
              onClick={onViewMoreClick}
              className="new-collection-card__footer-collaborators__icon new-collection-card__footer-collaborators__icon--count"
              text={`+${
                collaborators.length -
                checkCollaboratorsLength(collaborators).length
              }`}
            />
          </li>
        )}
      </ul>
    );
  }
  return null;
};

export default NewCollectionCollaborators;
