import * as config from '../../../../settings.json';

export default async function removeFromRAG(id: string | number) {
  // make a fetch delete request
  return fetch(config.ragURL, {
    method: 'DELETE',
    body: JSON.stringify({
      ids: [id],
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
    });
}
