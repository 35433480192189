const activeTopic = (state = { id: -1 }, action) => {
  switch (action.type) {
    case 'LOAD_ACTIVE_TOPIC':
      if (!action.activeTopic) {
        return state;
      }

      return action.activeTopic;

    default:
      return state;
  }
};

export default activeTopic;
