import React from 'react';

interface Proptypes {
  className?: string;
}

const Collection = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.03 0H1.97C0.881999 0 0 0.881999 0 1.97V5.03C0 6.118 0.881999 7 1.97 7H18.03C19.118 7 20 6.118 20 5.03V1.97C20 0.881999 19.118 0 18.03 0Z"
        fill="#969696"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8H19V17C19 17.7956 18.6839 18.5587 18.1213 19.1213C17.5587 19.6839 16.7956 20 16 20H4C3.20435 20 2.44129 19.6839 1.87868 19.1213C1.31607 18.5587 1 17.7956 1 17V8ZM5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H14C14.2652 11 14.5196 11.1054 14.7071 11.2929C14.8946 11.4804 15 11.7348 15 12C15 12.2652 14.8946 12.5196 14.7071 12.7071C14.5196 12.8946 14.2652 13 14 13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929Z"
        fill="#969696"
      />
    </svg>
  );
};

export default Collection;
