import { sortType } from '../../types/sortParams';

interface actionTypes {
  type: string;
  sortData: sortType;
}

const stateDefault: sortType = {
  sort_by: '',
  sort_param: '',
  label: '',
};

const sortParams = (state = stateDefault, action: actionTypes) => {
  switch (action.type) {
    case 'SET_SORT_PROPS':
      return { ...action.sortData };

    default:
      return state;
  }
};

export default sortParams;
