import React from 'react';

import './styles.css';
import { CommentType } from '..';
import { HeartIcon, ReplyIcon } from '../../Global/icons';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Avatar from '../../Global/Avatar';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import { formatTimestampDate, getResponseMessages, showFullDate } from '../../../helpers';

import ADD_REACTION from '../../../containers/data/addReaction';
import REMOVE_REACTION from '../../../containers/data/removeReaction';
import { useMutation } from '@apollo/client';
interface Proptypes {
  comment: CommentType;
  onReplyClick: () => void;
  onReactionToggled?: (isReacted: boolean) => void;
}

export default function CommentCard({
  comment,
  onReactionToggled,
  onReplyClick,
}: Proptypes) {
  const {
    user: commentingUser,
    text,
    id,
    parent_comment_id,
    scrap_id,
    user_id,
    reaction_count,
    reaction,
    updated_at,
  } = comment;
  const isReacted = reaction?.is_reacted;
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());
  let { avatar, display_name, user_name } = commentingUser || {};
  const user = useSelector((state: ReduxStateType) => state.user);

  
  const likeClickHandler = async () => {
    onReactionToggled && onReactionToggled(!isReacted);
    try {
      const mutationAction = !isReacted ? addLike : removeLike;
      const response = await mutationAction({
        variables: {
          comment_id: id,
          reactable_type: 3,
        },
      });
      const { isSuccess, error } = getResponseMessages(
        response.data[isReacted ? 'removeReaction' : 'addReaction']
      );
      if (!isSuccess) {
        throw new Error(error[0]);
      }
    } catch (error) {
      alert('something went wrong');
    }
  };
  return (
    <div className="comment-card">
      <Avatar avatar={avatar} userName={user_name} />
      {/* <img src={avatar} className="comment-card__avatar" /> */}
      <div className="comment-card__body">
        <div className="comment-card__user-wrapper">
          <div className="comment-card__display-name-wrapper">
            {display_name?.trim() && (
              <h4 className="comment-card__display-name">
                {display_name.trim()}
              </h4>
            )}
            <span className="comment-card__user-name">@{user_name}</span>
          </div>
          <span className="comment-card__date" title={showFullDate(updated_at, user.timezone)}>
            | {formatTimestampDate(updated_at, user.timezone)}
          </span>
        </div>
        <HTMLEllipsis
          unsafeHTML={text}
          maxLine={Infinity}
          basedOn="words"
          ellipsisHTML={`<button>... more</button>`}
        />
        <div className="comment-card__actions">
          <button
            onClick={user.user_id && likeClickHandler}
            className={`comment-card__action-button${
              isReacted ? ' comment-card__action-button--active' : ''
            } comment-card__action-button--like ${
              user.user_id ? '' : ' comment-card__action-button--disabled'
            }`}
          >
            <HeartIcon isFilled={isReacted} />
            {reaction_count > 0 ? reaction_count : isReacted ? 1 : null}
          </button>
          {user.user_id && (
            <button
              onClick={onReplyClick}
              className="comment-card__action-button comment-card__action-button--comment"
            >
              <ReplyIcon /> Reply
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
