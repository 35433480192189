import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './styles.css';
import Navigation from '../../containers/navigation';
import { Helmet } from 'react-helmet';
import SideNavigation from '../../containers/SideNavContainer';
import { ReduxStateType } from '../../redux/store';
import { BatchEditType } from '../../types/batchEdit';
import BatchEditWrapper from '../../containers/BatchEditWrapper';
import { toggleSidebarAction } from '../../redux/action/utils';
import useWindowOnClick from '../../helpers/customHooks/useWindowOnclick';
import PublicPageWrapper from '../Global/PublicPageWrapper';
import MobileNavigation from "../Navigations/MobileNavigation";
interface Proptypes {
  children: React.ReactChild | React.ReactChildren;
  withEntry?: boolean;
  showPublicWrapper?: boolean;
  shouldRenderChildren?: boolean;
}

export const fullScreenPages = ['/onboard'];

const BaseLayout = ({
  children,
  withEntry,
  shouldRenderChildren,
  showPublicWrapper,
  ...props
}: Proptypes) => {
  const dispatch = useDispatch();
  
  const [showFullScreen, setShowFullScreen] = useState(true);
  const { expanded } = useSelector((state: ReduxStateType) => state.utils);
  const user = useSelector((state: ReduxStateType) => state.user);
  const isUserDefined = Object.keys(user).length > 1;
  const { width } = useSelector(
    (state: ReduxStateType) => state.utils.dimensions
  );
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { mode: batchEditMode }: BatchEditType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.batchEdit
  );
  const sideNavRef = useRef(null);
  useEffect(() => {
    setShowFullScreen(fullScreenPages.includes(pathname));
    if (!fullScreenPages.includes(pathname))
      dispatch(toggleSidebarAction(width >= 768));
  }, [pathname, width]);

  useWindowOnClick({
    cb: (el) => {
      if (width > 450 && width <= 821) {
        const closestElement = el.closest('.navigation__toggle');
        if (
          !closestElement &&
          !el.closest('.main-navigation__link-invite') &&
          !el.closest('.base-layout__side-nav') &&
          !el.closest('.collection-header') &&
          !el.closest('.global-feeds__filter') &&
          !el.closest('.card-base')
        )
          dispatch(toggleSidebarAction(!expanded));
      } else return;
    },
    removeListener: !expanded,
  });

  if (showPublicWrapper) {
    return <PublicPageWrapper hideActionButtons>{children}</PublicPageWrapper>;
  }

  return (
    <section className={`base-layout${withEntry ? ' with-entry' : ''}`}>
      <Helmet>
        <title>Scrappi</title>
      </Helmet>
      <Navigation hideFilter={false} showFullScreen={showFullScreen} />
      {showFullScreen ? (
        children
      ) : (
        <div>
          {isUserDefined && (
            <aside
              ref={sideNavRef}
              className={`base-layout__side-nav${
                expanded ? '' : ' base-layout__side-nav--hidden'
              }`}
            >
              <SideNavigation />
            </aside>
          )}
          {(isUserDefined || shouldRenderChildren) && (
            <main
              className={`base-layout__content ${
                pathname.startsWith('/search')
                  ? 'global-base-layout__content'
                  : ''
              } ${batchEditMode ? 'batch-edit__enabled' : ''}`}
            >
              {children}
            </main>
          )}
          {batchEditMode && <BatchEditWrapper {...props} />}
        </div>
      )}

      <MobileNavigation />
    </section>
  );
};

export default BaseLayout;
