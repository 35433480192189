import { collectionType } from '../../types/collections';

export const addCollectionToStore = (collection: collectionType) => {
  return {
    type: 'ADD_COLLECTION',
    collection,
  };
};

export const removeCollectionFromStore = (collectionID: string) => {
  return {
    type: 'REMOVE_COLLECTION',
    collectionID,
  };
};

export const loadCollections = (collections: Partial<collectionType>[]) => {
  return {
    type: 'LOAD_COLLECTIONS',
    collections,
  };
};
export const resetCollections = () => {
  return {
    type: 'RESET_COLLECTIONS',
  };
};
export const moveCollections = (idList: string[] | number[]) => {
  return {
    type: 'MOVE_COLLECTIONS',
    payload: idList,
  };
};
