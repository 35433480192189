import React, { useEffect, useState } from "react";
import NewPopup from "../../Global/NewPopup";
import { CopyIcon } from "../../Global/icons";

interface Proptypes {
  emptyClipboard?: boolean;
  handlePaste?: (url: string) => void;
}

const EmptyClipboard = ( { emptyClipboard, handlePaste } : Proptypes) => {
  const [show, setShow] = useState(emptyClipboard || false);
  const [link] = useState('https://www.theverge.com/2024/2/29/24086218/eken-aiwit-tuck-video-doorbell-amazon-temu')

  useEffect(() => {
    setShow(emptyClipboard);
  }, [emptyClipboard]);

  const addLinkToClipboard =  () => {
    handlePaste(link);
  }

  return (
    <NewPopup
      className='empty-clipboard-popup'
      controlled={{
        show: show,
        setShow: setShow,
      }}
      header={{
        heading: 'Clipboard empty',
      }}
    >
      <div className="empty-clipboard">
        <p>It seems like your clipboard is empty</p>
        <p>Click here to create your first Scrap</p>
        <p className="empty-clipboard__link">{link.substring(0, 25)}...<span onClick={addLinkToClipboard}><CopyIcon /></span></p>
      </div>
    </NewPopup>
  )
}

export default EmptyClipboard;