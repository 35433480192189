import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int'],
  ['collection_id', 'Int'],
  ['commentable_type', 'Int'],
  ['first', 'Int = 500'],
  ['search_after', 'String'],
  ['tie_breaker_id', 'String = "id"'],
  ['tie_breaker_param', 'String'],
  ['sort_param', 'String = "created_at"'],
  ['sort_by', 'String = "asc"'],
]);
export default function GET_COMMENTS() {
  return gql`
    query GetComments(${parametersTypeString}) {
      getComments(${parametersAssignString}) {
        data {
            paginatorInfo {
              total
            }
            comments {
              id
              user_id
              text
              created_at
              updated_at
              reaction_count
            }
            commenting_users {
              user_id
              user_name
              display_name
              avatar
            }
        }
        ${defaultResponse}
      }
    }
  `;
}
