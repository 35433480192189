export async function mapUserZone(original: string, type = 'timezone') {
  var timezoneMapping = [
    {
      original: 'Asia/Calcutta',
      parsed: 'Asia/Kolkata',
    },
  ];

  var countryMapping = [
    {
      original: 'United States of America',
      parsed: 'United States',
    },
  ];

  let mappingFound: null | string[] = null;
  if (type === 'timezone') {
    mappingFound = timezoneMapping
      .filter((item) => item.original === original)
      .map(function (obj) {
        return obj.parsed;
      });
  } else {
    mappingFound = countryMapping
      .filter((item) => item.original === original)
      .map(function (obj) {
        return obj.parsed;
      });
  }
  return mappingFound?.length ? mappingFound[0] : original;
}
