import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['text', 'String'],
  ['first', 'Int = 50'],
  ['search_after', 'String'],
  ['tie_breaker_id', 'String'],
  ['tie_breaker_param', 'String'],
  ['sort_by', 'String'],
  ['sort_param', 'String']
]);

export default function GLOBAL_SEARCH_SHARE(responseString = '') {
  return gql`
  query GlobalSearchShare(${parametersTypeString}){
    globalSearchShare(${parametersAssignString}){
      ${defaultResponse}
      shareMetaData {
        id
        user_name
        display_name
        avatar
        members {
          user_id
          avatar
          user_name
          display_name
        }
        comment_count
        reaction_count
      }
      scrapsCount {
        id
        scraps_count
      }
      data {
        id
        user_id
        nickname
        is_group
        matched_count
        created_at
        updated_at
        members {
          user_id
        }
        scrap_ids
      }
      paginatorInfo {
        total
      }
    }
  } 
  `;
}
