import React from 'react';
import './styles.css';
import TermPagesWrapper from './TermPagesWrapper';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <TermPagesWrapper>
      <>
        <Helmet>
          <title>Scrappi | Terms and Conditions</title>
        </Helmet>
        <embed
          src="https://docs.google.com/document/d/e/2PACX-1vSMIZ3CmzzcBBjEe6ssJCRPtZZgvjG1flppG0rFDEozisL97cp50cdQVI3VOhit3chnZ_VRiDg2CoRk/pub?embedded=true"
          title="Terms and Conditions"
          className='policy_embed'
        ></embed>
      </>
    </TermPagesWrapper>
  );
};

export default TermsAndConditions;
