/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient } from '@apollo/client';
import { FileDataType } from '../types';
import { fetchBlob } from '../../../helpers';
import { UPLOAD_FILE } from '../../data/uploadFile';
import UPSERT_SCRAP_DOCUMENT from '../../data/upsertScrapDocument';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import { returnArrayInBatch } from '../../../helpers/arrayHelpers';
import { scrapType } from '../../../types/scrapType';

export default async function saveImagesInScrapHandler(
  scrapId: number,
  client: ApolloClient<object>,
  filesList: Array<FileDataType>
) {
  try {
    const list: Array<{ file: File; upload: string }> = [];
    for (let item of filesList) {
      // get the file object from file Url
      const blob = await fetchBlob(item.fileUrl);
      const file = new File([blob], item.filename, {
        type: blob.type,
      });

      // Start uploading file
      const uploadResponse = await client.mutate({
        mutation: UPLOAD_FILE,
        variables: {
          file,
        },
      });
      // Check if file is successfully uploaded successfully by looking
      // for property upload in data object
      const { data } = uploadResponse;
      if (!data?.upload)
        throw new Error('Failed to upload this file! Please try again later.');
      list.push({ file, upload: data.upload });
    }

    const batchListOfVariables = returnArrayInBatch(
      list.map((data, i) => {
        return {
          scrap_id: scrapId,
          file_name: filesList[i].filename,
          file_path: data.upload,
          file_type: filesList[i].fileType,
        };
      }),
      10
    );
    const responseFiles: scrapType['documents'] = []
    for (let batchListItem of batchListOfVariables) {
      // Start the process of saving uploaded file in scrap
      const responses = await Promise.all(
        batchListItem.map((variables) => {
          return client.mutate({
            mutation: UPSERT_SCRAP_DOCUMENT(`
              file_name
              file_type
              file_path
            `),
            variables,
          });
        })
      );
      for(let response of responses) {
        if(!response?.data?.upsertScrapDocument?.messages?.success?.length) {
          throw new Error('Failed to save files!');
        }
        responseFiles.push(response.data.upsertScrapDocument.data)
      }  
    }
    return responseFiles;
  } catch (error) {
    throw new Error(parseTryCatchError(error));
  }
}
