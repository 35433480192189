import { GroupType } from '../../containers/GroupShareContainer/types';
import { collectionType } from '../../types/collections';

export interface matchedScrapsDrawerType {
  type: 'matched-scraps';
  data: {
    group?: GroupType;
    collection?: collectionType;
    openLink?: string;
    usePublicClient?: boolean;
  };
}

export interface commentsDrawerType {
  type: 'comments';
  data: {
    variables: {
      scrap_id?: number;
      collection_id?: number;
      commentable_type: number;
    };
    commentsFor: {
      type: 'scrap' | 'collection' | 'group',
      id: number | string;
    }
  }
}

export type ReduxDrawerType = matchedScrapsDrawerType | commentsDrawerType;

export const SET_DRAWER = 'SET_DRAWER';

type drawerActionsType = typeof SET_DRAWER;

const defaultState: ReduxDrawerType = null;
export default function drawer(
  state = defaultState,
  action: {
    type: drawerActionsType;
    payload: ReduxDrawerType;
  }
) {
  const { type, payload } = action;
  switch (type) {
    case SET_DRAWER:
      if (!payload) return defaultState;
      return { ...payload };

    default:
      return state;
  }
}
