export default function getResponseMessages(data: {
  messages: {
    error: string[];
    success: string[];
  };
}) {
  try {
    const { error, success } = data.messages || {};
    if (success?.length)
      return {
        success,
        error: [],
        isSuccess: true,
      };
    else
      return {
        success: [],
        error: error ?? [],
        isSuccess: false,
      };
  } catch (e) {
    return {
      success: [],
      error: ['Failed to react to the scrap.'],
      isSuccess: false,
    };
  }
}
