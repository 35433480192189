import { useState, useRef, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';

const useDebounce = (duration: number) => {
  const [timeOutKey, setTimeoutKey] = useState(null);
  return async (cb: Function) => {
    if (timeOutKey) window.clearTimeout(timeOutKey);
    const timer = setTimeout(() => {
      cb();
    }, duration);
    setTimeoutKey(timer);
  };
};

const useLodashDebounce = <T extends Function>(
  callback: T,
  duration: number
): T => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      debouncedCallback.cancel()
    }
  }, [])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, duration);
  }, []);

  return debouncedCallback;
};
export { useDebounce as default, useLodashDebounce };
