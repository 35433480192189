import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['id', 'ID'],
  ['title', 'String'],
  ['desc', 'String'],
  ['state', 'Int'],
  ['service', 'String'],
  ['meta', 'String'],
  ['meta_title', 'String'],
  ['meta_desc', 'String'],
  ['price', 'Float'],
  ['url', 'String'],
  ['original_favicon', 'String'],
]);
export default function UPSERT_SCRAP(responseString = '') {
  return gql`
    mutation UpsertScrap(${parametersTypeString}) {
      upsertScrap(${parametersAssignString}) {
        ${defaultResponse}
        data {
          id
          ${responseString}
        }
      }
    }
  `;
}
