import React from 'react';

interface Proptypes {
  className?: string;
}

const Invite = ({ className }: Proptypes) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 10.1663C4.81192 10.1663 4.39386 10.2015 4.03716 10.2538C2.56683 10.469 1.33333 11.965 1.33333 13.4997C1.33333 13.8679 1.03486 14.1663 0.666667 14.1663C0.298477 14.1663 0 13.8679 0 13.4997C0 11.771 1.08329 10.073 2.6394 9.31072C1.4458 8.46545 0.666667 7.07355 0.666667 5.49967C0.666667 2.92235 2.756 0.833008 5.33333 0.833008C7.91066 0.833008 10 2.92235 10 5.49967C10 7.07355 9.22087 8.46545 8.02727 9.31072C9.58338 10.073 10.6667 11.771 10.6667 13.4997C10.6667 13.8679 10.3682 14.1663 10 14.1663C9.63181 14.1663 9.33333 13.8679 9.33333 13.4997C9.33333 11.965 8.09984 10.469 6.62951 10.2538C6.27281 10.2015 5.85475 10.1663 5.33333 10.1663ZM5.33333 8.83301C3.49238 8.83301 2 7.34062 2 5.49967C2 3.65873 3.49238 2.16634 5.33333 2.16634C7.17428 2.16634 8.66667 3.65873 8.66667 5.49967C8.66667 7.34062 7.17428 8.83301 5.33333 8.83301Z"
        fill="white"
      />
      <path
        d="M13.3333 6.16634C12.9648 6.16634 12.6667 6.46484 12.6667 6.83301V8.16634H11.3333C10.9648 8.16634 10.6667 8.46484 10.6667 8.83301C10.6667 9.20117 10.9648 9.49967 11.3333 9.49967H12.6667V10.833C12.6667 11.2012 12.9648 11.4997 13.3333 11.4997C13.7018 11.4997 14 11.2012 14 10.833V9.49967H15.3333C15.7018 9.49967 16 9.20117 16 8.83301C16 8.46484 15.7018 8.16634 15.3333 8.16634H14V6.83301C14 6.46484 13.7018 6.16634 13.3333 6.16634Z"
        fill="white"
      />
    </svg>
  );
};

export default Invite;
