import React from 'react';
import InviteUser from '../components/InviteUser';

import { useMutation } from '@apollo/client';
import { INVITE_USER } from './data/inviteUser';
import { parseTryCatchError } from '../helpers/parseTryCatchError';

interface Proptypes {
  close: () => void;
  controlled : {
    show: boolean;
    setShow: (value: boolean) => void;
  }
}

const InviteUserWrapper = ({ close,controlled }: Proptypes) => {
  const [sendInvite] = useMutation(INVITE_USER);

  const inviteHandler = async (variables: {
    email: string;
    message?: string;
  }) => {
    try {
      const response = await sendInvite({ variables });
      const {
        messages: { error, success },
      } = response?.data?.inviteUser || {
        error: [],
        success: [],
      };
      if (Array.isArray(success) && success.length > 0) return success[0];
      else {
        const errorMessage =
          Array.isArray(error) && error.length > 0
            ? error[0]
            : 'Something went wrong while sending the invite. Please Try again later.';
        throw new Error(errorMessage);
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // CHECK FOR INVALID EMAIL ERROR COMING FROM GRAPHQL
      if(Array.isArray(error?.graphQLErrors) && error.graphQLErrors.length > 0) {
        let err = error.graphQLErrors[0]?.extensions?.validation?.email || [];
        if(err.length > 0) throw new Error(err[0])
      }
      throw new Error(parseTryCatchError(error));
    }
  };

  return <InviteUser onInvite={inviteHandler} close={close} controlled={controlled}/>;
};

export default InviteUserWrapper;
