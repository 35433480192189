import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';

export const REMOVE_COLLECTION_MEMBER = (responseString = '') => {
  const [
    parametersTypeString,
    parametersAssignString,
  ] = createApolloQueriesString([
    ['collection_id', 'Int!'],
    ['member_id', 'Int!'],
  ]);
  return gql`
    mutation RemoveCollectionMember(${parametersTypeString}) {
      removeCollectionMember(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
};

export const UPSERT_COLLECTION_MEMBER = (responseString = '') => {
  const [
    parametersTypeString,
    parametersAssignString,
  ] = createApolloQueriesString([
    ['collection_id', 'Int!'],
    ['member_id', 'Int!'],
  ]);
  return gql`
    mutation UpsertCollectionMember(${parametersTypeString}) {
      upsertCollectionMember(${parametersAssignString}) {
        ${defaultResponse}
        data {
          cm_id
        }
      }
    }
  `;
};
export const ADD_COLLECTION_MEMERS = (responseString = '') => {
  const [
    parametersTypeString,
    parametersAssignString,
  ] = createApolloQueriesString([
    ['collection_id', 'Int!'],
    ['members', '[InputMemberId]'],
  ]);
  return gql`
    mutation AddCollectionMembers(${parametersTypeString}) {
      addCollectionMembers(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
};

export const FETCH_ROLES = gql`
  query CollaboratorRoles {
    collaboratorRoles {
      data {
        role_id
        title
      }
      ${defaultResponse}
    }
  }
`;

export const FETCH_COLLECTION_COLLABORATION_MEMBERS = gql`
  query CollectionCollaborator($collectionId: Int!) {
    collectionCollaborator(collectionId: $collectionId) {
      data {
        title
        owner {
          avatar
          username
          email
          first_name
          last_name
          bio
          creator_initials
          display_name
        }
        active_user {
          avatar
          username
          email
          first_name
          last_name
          display_name
          role {
            role_id
            title
          }
          bio
        }
        collaborators {
          cc_id
          avatar
          username
          first_name
          last_name
          display_name
          email
          registered_at
          accepted_at
          role {
            role_id
            title
          }
          creator_initials
          bio
        }
      }
      ${defaultResponse}
    }
  }
`;

// export const UPSERT_COLLECTION_COLLABORATION = gql`
//   mutation UpsertCollectionCollaborator(
//     $collection_id: Int!
//     $user_email: String!
//     $role_id: Int!
//   ) {
//     upsertCollectionCollaborator(
//       collection_id: $collection_id
//       user_email: $user_email
//       role_id: $role_id
//     ) {
      
//       data {
//         cc_id
//         email
//         registered_at
//         accepted_at
        
//       }
//       ${defaultResponse}
//     }
//   }
// `;

export const ACCEPT_COLLECTION_COLLABORATOR = gql`
  mutation AcceptCollectionCollaborator($token: String!, $type: String!) {
    acceptCollectionCollaborator(token: $token, type: $type) {
      data {
        email
        registered_at
        accepted_at
        cc_id
        role {
          role_id
          title
        }
        collection {
          id
          title
        }
      }
      ${defaultResponse}
    }
  }
`;
