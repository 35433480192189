import React, { useState, useRef } from 'react';
import {
  CollabMemberActionType,
  CollabRoleType,
  CollectionCollaborationFetchedType,
  CollectionCollaboratorOwnerType,
  CollectionCollaboratorType,
} from '../../types/collaboration';
import CollectionCollaborationMemberItem from './components/CollectionCollaborationMemberItem';
import { SEARCH_COLLABORATORS } from '../../containers/data/searchCollaborators';
import Dropdown from '../Global/Dropdown/';
import CollectionHeaderAvatar from '../CollectionCreationWrapper/components/CollectionCreationHeader/CollectionHeaderAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import { RequestStatusType } from '../../types/requestStatusType';
import { returnImageURL } from '../Gallery/helpers/returnImageURL';
import { returnRoleType } from '../../helpers/collectionCollaborationHelpers';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TickIcon } from '../Global/icons';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../Global/InvisibleRecaptcha';
import ValidateHuman from '../../helpers/validators/isHuman';
import useDebounce from '../../helpers/customHooks/useDebounce';

import useOutsideAlerter from '../../helpers/customHooks/useOutsideAlerter';
import { useApolloClient } from '@apollo/client';
const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  setMessageBody?: (val: boolean) => void;
  handleClose: () => void;
  removingMemberDetail: Partial<CollectionCollaboratorType>;
  onRemoveProceed: () => void;
  removeLoader?: boolean;
  messageBody?: boolean;
  lastName?: string;
  collectionTitle: string;
  collabData: CollectionCollaborationFetchedType;
  loading: boolean;
  roles: CollabRoleType[];
  firstName?: string;
  onInvite: (
    user_email: string,
    role_id: number,
    type?: string
  ) => Promise<void>;
  requestStatus: {
    type: RequestStatusType;
    message: string;
    user_email?: string;
  };
  onMemberDropDownClick: (
    member: Partial<CollectionCollaboratorType>,
    action: CollabMemberActionType
  ) => Promise<void>;
  firstTimeInvitation: boolean;
  onMemberClick: (member: CollectionCollaboratorOwnerType | CollectionCollaboratorType) => void;
}

const CollectionCollaboration = ({
  setMessageBody,
  onRemoveProceed,
  removeLoader,
  handleClose,
  removingMemberDetail,
  messageBody,
  firstName,
  lastName,
  collabData,
  loading,
  roles,
  onInvite,
  onMemberDropDownClick,
  collectionTitle,
  requestStatus,
  firstTimeInvitation,
  onMemberClick
}: Proptypes) => {
  const [selectedType, setSelectedType] = useState<{
    title: string;
    id: number;
  }>(null);  
  const [disableInvite, setDisableInvite] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>();
  const dropdownListRef = useRef(null);
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const canSendInvitation =
    collabData && (+collabData.active_user.role.role_id || 0) === 0;

  const [loadingEmailSuggestion, setLoadingEmailSuggestion] = useState(false);
  const [hideEmailSuggestion, setHideEmailSuggestion] = useState(true);
  const debounce = useDebounce(500);
  const emailSuggestion = useRef();
  const client = useApolloClient();
  const [fetchedUsers, setFetchedUsers] = useState<
    {
      avatar: string;
      userName: string;
      email: string;
      creator_initials: string;
      first_name: string;
      last_name: string;
      display_name: string;
    }[]
  >(null);

  function inputChangeHandler(value: string) {
    if (!value) {
      setFetchedUsers(null);
    }
    setLoadingEmailSuggestion(true);
    debounce(() => searchCollaborators(50, 1, value));
  }

  function focusHandler(val: string) {
    if(val)
    {
      searchCollaborators(50,1,val)
    }
  
  }
  async function searchCollaborators(
    first: number,
    page: number,
    text: string
  ) {
  
    try {
      if (!text) {
        throw new Error('Nothing to search at the moment')
      }
      const variables = {
        first,
        page,
        text,
      };
    
      const data = await client.query({
        query: SEARCH_COLLABORATORS,
        variables,
      });
      if (data?.data.globalSearchUsersForInvitation.data.knot.length > 0) {
        setFetchedUsers(data.data.globalSearchUsersForInvitation.data.knot);
      }  
    } catch (error) {
      //
    }
    setLoadingEmailSuggestion(false);
    setHideEmailSuggestion(false);
  }

  useOutsideAlerter(
    emailSuggestion,
    () => setHideEmailSuggestion(true),
    hideEmailSuggestion,
    true
  );
  function renderMembers() {
    if (collabData && collabData.owner) {
      let el;
      const { owner, collaborators, active_user } = collabData;
      if (Array.isArray(collaborators) && collaborators.length > 0)
        el = collaborators.map((member) => (
          <CollectionCollaborationMemberItem
            firstTimeInvitation={firstTimeInvitation}
            key={member.email}
            email={member.email}
            role={returnRoleType(+member.role.role_id)}
            avatar={(function () {
              if (member.avatar) return member.avatar;
              else
                return member.creator_initials
                  ? null
                  : '/dashboard/img/default-avatar.jpg';
            })()}
            firstName={member.first_name}
            lastName={member.last_name}
            displayName={member.display_name}
            creator_initials={member.creator_initials}
            activeUser={active_user}
            userName={member.username}
            hasAcceptedInvite={!!member.accepted_at}
            onMemberDropDownClick={onMemberDropDownClick.bind(null, {
              cc_id: member.cc_id,
              role: member.role,
              email: member.email,
              last_name: member.last_name,
              first_name: member.first_name,
            })}
            ref={dropdownListRef}
            collectionTitle={collectionTitle}
            requestStatus={requestStatus}
            onMemberClick={member.username ? onMemberClick.bind(null, member) : null}
          />
        ));

      return (
        <div
          className="collection-collaboration__members-wrapper__list"
          onScroll={() => {
            dropdownListRef?.current?.hideDropdown();
          }}
        >
          <CollectionCollaborationMemberItem
            creator_initials={owner?.creator_initials}
            key="collaborator-owner"
            email={owner.email}
            role="Owner"
            avatar={(function() {
              if(owner.avatar) return returnImageURL(owner.avatar);
              else
                return owner.creator_initials
                  ? null
                  : '/dashboard/img/default-avatar.jpg';
            })()}
            firstName={owner.first_name}
            lastName={owner.last_name}
            displayName={owner.display_name}
            activeUser={active_user}
            userName={owner.username}
            hasAcceptedInvite
            onMemberDropDownClick={onMemberDropDownClick.bind(null, {})}
            collectionTitle={collectionTitle}
            requestStatus={requestStatus}
            firstTimeInvitation={firstTimeInvitation}
            onMemberClick={onMemberClick.bind(null, owner)}
          />
          {el}
        </div>
      );
    }

    return (
      <div className="collection-collaboration__members-wrapper__loading">
        <FontAwesomeIcon icon={faSpinnerIcon} spin size="4x" />
      </div>
    );
  }

  async function onInviteClick(type: string) {
    setDisableInvite(true);
    const isHuman = await ValidateHuman(reCaptchaRef.current);
    if (!isHuman) throw new Error('Failed to send invite');
    let email
    for(let i=0;i<fetchedUsers?.length;i++)
    {
      if("@"+fetchedUsers[i].userName===emailInputRef.current.value||fetchedUsers[i].email===emailInputRef.current.value)
      {
        email=fetchedUsers[i].email
      }
    }
    if(!email)
    {
      email=emailInputRef.current.value
    }
    try {
      await onInvite(email, selectedType?.id, type);

      emailInputRef.current.value = '';
      setSelectedType(undefined);
    } catch (error) {
      alert(parseTryCatchError(error));
    }

    setDisableInvite(false);
  }

  const returnMemberCount = () => {
    if (collabData?.collaborators?.length) {
      return (
        collabData.collaborators.filter((c) => Boolean(c.accepted_at)).length +
        1
      );
    }
    return '1';
  };

  function renderMessageBody() {
    return (
      <div
        className={`messagecontainer ${
          removeLoader ? 'messagecontainer-opacity' : ' '
        }`}
      >
        <div className="messagecontainer__messagebody">
          <span>Are You Sure?</span>
          <p className="messagecontainer__messagebody__firstnameLastName__container">
            {' '}
            Removing{' '}
            <span className="messagecontainer__messagebody__firstnameLastName__container__firstNamelastName">
              {removingMemberDetail.first_name} {removingMemberDetail.last_name}
            </span>{' '}
            will remove all of their Scraps from this Collection.
          </p>
        </div>

        <div className="messagecontainer__buttons">
          <div className="messagecontainer__buttons__cancelholder">
            <button
              onClick={() => setMessageBody(!messageBody)}
              className="messagecontainer__buttons__cancelholder__cancel"
            >
              {' '}
              <span>Cancel</span>{' '}
            </button>
          </div>
          <div className="messagecontainer__buttons__removeholder">
            <button
              onClick={onRemoveProceed}
              className="messagecontainer__buttons__removeholder__remove"
            >
              <TickIcon className="messagecontainer__buttons__removeholder__remove__tick" />{' '}
              <span className="messagecontainer__buttons__removeholder__remove__text">
                Remove
              </span>{' '}
            </button>
          </div>
        </div>
      </div>
    );
  }

  function renderStatus() {
    const { type, message } = requestStatus;
    switch (type) {
      case 'processing':
        return (
          <div
            className={`collection-collaboration__status-message--processing`}
          >
            <span>{message}</span>
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
          </div>
        );
      case 'success':
        return (
          <div className={`collection-collaboration__status-message--success`}>
            <p>{message}</p>
          </div>
        );
      case 'error':
        return (
          <div className={`collection-collaboration__status-message--error`}>
            <p>{message}</p>
          </div>
        );

      default:
        return null;
    }
  }

  let dropdownList = roles.map((role) => ({
    id: role.role_id.toString(),
    label: role.title,
  }));
  const disableInputs = requestStatus?.type == 'processing';
  if (loading) {
    return (
      <div className="collection-collaboration__members-wrapper__loading">
        <FontAwesomeIcon icon={faSpinnerIcon} spin size="4x" />
      </div>
    );
  } else
    return messageBody ? (
      renderMessageBody()
    ) : (
      <div
        className={`collection-collaboration ${
          disableInvite || disableInputs
            ? 'collection-collaboration-opacity'
            : ''
        }`}
      >
        {canSendInvitation && roles.length > 0 && (
          <form
            onSubmit={(e) => e.preventDefault()}
            className="collection-collaboration__invite-wrapper"
          >
            <div className="collection-collaboration__invite-wrapper__form-control">
              <InvisibleRecaptcha inputRef={reCaptchaRef} />
              <label htmlFor="collection-collaboration-email">Invite</label>
              <input
                autoComplete="off"
                ref={emailInputRef}
                placeholder="Email/Username"
                type="text"
                className="collection-collaboration__invite-wrapper__form-control__input"
                id="collection-collaboration-email"
                onChange={(e) => inputChangeHandler(e.target.value)}
                onFocus={() => {
                  focusHandler(emailInputRef.current.value);
                }}
              />
              {!loadingEmailSuggestion && !hideEmailSuggestion && fetchedUsers && (
                <div
                  ref={emailSuggestion}
                  className="collection-collaboration__invite-wrapper__form-control__email-suggestion"
                >
                  <div className="collection-collaboration__invite-wrapper__form-control__email-suggestion__scrollable">
                    {fetchedUsers?.map((data, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            emailInputRef.current.value = "@"+data?.userName;
                            setHideEmailSuggestion(true);
                          }}
                          className="collection-collaboration__invite-wrapper__form-control__email-suggestion__scrollable__details"
                        >
                          <span className="collection-collaboration__invite-wrapper__form-control__email-suggestion__scrollable__details__user-icon">
                            <CollectionHeaderAvatar
                              image={data?.avatar}
                              text={data?.creator_initials}
                            />
                          </span>
                          <span className="collection-collaboration__invite-wrapper__form-control__email-suggestion__scrollable__details__initials">
                            <span className="collection-collaboration__invite-wrapper__form-control__email-suggestion__scrollable__details__initials__username">
                              {data?.display_name}
                            </span>
                            <span className="collection-collaboration__invite-wrapper__form-control__email-suggestion__scrollable__details__initials__email">
                               @{data?.userName}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div className="collection-collaboration__invite-wrapper__form-control">
              <label>Role</label>
              <Dropdown
                list={dropdownList}
                selectedItem={selectedType?.title}
                placeholder={'Select'}
                extraHeight={true}
                onSelect={(item) =>
                  setSelectedType({ id: +item.id, title: item.label })
                }
              />
            </div>
            <div className="collection-collaboration__buttonwrapper">
              <button
                onClick={handleClose}
                className="collection-collaboration__buttonwrapper__cancel"
              >
                {' '}
                <span>Cancel</span>{' '}
              </button>

              <button
                disabled={disableInvite}
                onClick={() => onInviteClick('firstTime')}
                className="collection-collaboration__buttonwrapper__invite"
              >
                <TickIcon className="collection-collaboration__buttonwrapper__invite__tick" />
                Invite
              </button>
            </div>
          </form>
        )}
        <div className="collection-collaboration__members-wrapper">
          {renderMembers()}
        </div>

        {/* {requestStatus && (
        <div className={`collection-collaboration__status-message`}>
          {renderStatus()}
        </div>
      )} */}
      </div>
    );
};

export default CollectionCollaboration;