import React from 'react';

interface Proptypes {
  className?: string;
}

const Google = ({ className }: Proptypes) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_14980_228849)">
        <path
          d="M37.1122 19.3554C37.1122 17.7986 36.9858 16.6625 36.7124 15.4844H18.9348V22.5112H29.3699C29.1596 24.2574 28.0235 26.8873 25.4988 28.6544L25.4634 28.8897L31.0844 33.2442L31.4738 33.283C35.0503 29.9799 37.1122 25.12 37.1122 19.3554Z"
          fill="#4285F4"
        />
        <path
          d="M18.9347 37.8695C24.047 37.8695 28.3389 36.1864 31.4737 33.2831L25.4987 28.6545C23.8998 29.7696 21.7538 30.548 18.9347 30.548C13.9276 30.548 9.67782 27.2451 8.16289 22.6797L7.94084 22.6985L2.09609 27.2218L2.01965 27.4343C5.13333 33.6196 11.5291 37.8695 18.9347 37.8695Z"
          fill="#34A853"
        />
        <path
          d="M8.16289 22.6794C7.76317 21.5013 7.53183 20.2389 7.53183 18.9345C7.53183 17.6301 7.76317 16.3678 8.14186 15.1897L8.13127 14.9387L2.21328 10.3428L2.01966 10.4349C0.736359 13.0016 0 15.884 0 18.9345C0 21.9851 0.736359 24.8673 2.01966 27.4341L8.16289 22.6794Z"
          fill="#FBBC05"
        />
        <path
          d="M18.9347 7.32138C22.4902 7.32138 24.8885 8.85719 26.2561 10.1406L31.5999 4.92303C28.318 1.87244 24.047 0 18.9347 0C11.5291 0 5.13333 4.24976 2.01965 10.4351L8.14186 15.1898C9.67781 10.6245 13.9276 7.32138 18.9347 7.32138Z"
          fill="#EB4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_14980_228849">
          <rect width="38" height="38" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Google;
