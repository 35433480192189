import React, { useCallback, useEffect, useState } from 'react';
import useWindowOnClick from '../../../helpers/customHooks/useWindowOnclick';
import './styles.css';
/*
 * POSSIBLE STATES:
 * 1 - PRIVATE
 * 2 - PRIVATE WITH LINK
 * 3 - PUBLIC
 */

/*
  TODO:
  * Change the span tooltip title label with custom component for showing the hover title
  * This popup will be visible on item click rather than on hover
 */
interface PropTypes {
  children: React.ReactChild;
  className?: string;
  parentClass: string;
  actionListener: 'click' | 'hover';
}
const CustomTolltip = (props: PropTypes) => {
  const { children, className, parentClass, actionListener } = props;
  const [isValid, setIsValid] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = useCallback((e: Event, val: boolean) => {
    e.stopPropagation();
    setShow(val);
  }, []);

  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement = el.closest(parentClass);
    if (!closestElement) setShow(false);
  };
  useWindowOnClick({ cb: handleOutSideClick, removeListener: !show });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      var element = document.getElementsByClassName(parentClass);
      if (element.length) {
        setIsValid(true);
        const el = element[0] as HTMLElement;

        if (actionListener === 'click') {
          el.onclick = function (e) {
            handleShow(e, !show);
          };
        } else if (actionListener === 'hover') {
          el.onmouseover = function (e) {
            handleShow(e, true);
          };
          el.onmouseout = function (e) {
            handleShow(e, false);
          };
        }
      }
    }
    return () => {
      if (isValid) {
        var element = document.getElementsByClassName(
          parentClass
        )[0] as HTMLElement;
        if (actionListener === 'click') {
          element.onclick = () => {};
        } else if (actionListener === 'hover') {
          element.onmouseover = () => {};
          element.onmouseout = () => {};
        }
      }
    };
  }, []);

  if (show)
    return (
      <span className={`custom-toolip ${className ?? ''}`}>{children}</span>
    );

  return null;
};

export default CustomTolltip;
