import React from 'react';

interface Proptypes {
  className?: string;
}

const Xls = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="54"
      height="60"
      viewBox="0 0 54 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.9 25.7V17.1C42.9 16.8 42.9 16.5 42.7 16.3C42.6 16 42.4 15.8 42.2 15.6L27.2 0.6C27 0.4 26.8 0.2 26.5 0.1C26.3 0 26 0 25.7 0H4.3C3.2 0 2.1 0.5 1.3 1.3C0.5 2.1 0 3.2 0 4.3V55.7C0 56.8 0.5 57.9 1.3 58.7C2.1 59.5 3.2 60 4.3 60H38.6V55.7H4.3V4.3H21.4V17.2C21.4 18.3 21.9 19.4 22.7 20.2C23.5 21 24.6 21.5 25.7 21.5H38.6V25.8L42.9 25.7ZM25.7 17.1V5.1L37.7 17.1H25.7Z"
        fill="#616161"
      />
      <path
        d="M18.2 51.5999C18.1 50.7999 16.3 45.0999 16.2 44.0999H16.1C16.1 44.7999 14.1 50.8999 14 51.5999H8.5L12.7 40.7999L8.7 29.8999H14.5C14.5 30.3999 16.2 36.1999 16.2 36.7999H16.4C16.5 36.1999 18.2 30.3999 18.3 29.8999H23.8L19.7 40.0999L24 51.5999H18.2Z"
        fill="#616161"
      />
      <path
        d="M25.4004 29.8999H30.8004V47.9999H37.6004L37.1004 51.6999H25.4004V29.8999Z"
        fill="#616161"
      />
      <path
        d="M43.9 44.9V46.4C43.9 47.8 44.4 48.5 45.6 48.5C46.8 48.5 47.1 47.5 47.1 46.5C47.1 44.4 46.6 43.9 43.7 42C40.5 39.9 38.9 38.7 38.9 35.5C38.9 32.4 40.2 29.5 45.6 29.5C51.3 29.5 52.1 32.5 52.1 35V36.2H47V35C47 33.7 46.7 33 45.6 33C44.6 33 44.3 33.7 44.3 34.9C44.3 36.2 44.6 36.9 46.9 38.3C51 40.8 52.5 42.2 52.5 45.8C52.5 49.2 51 52 45.4 52C40 52 38.5 49.5 38.5 46.3V45H43.9V44.9Z"
        fill="#616161"
      />
    </svg>
  );
};

export default Xls;
