import React from 'react';
import { returnShowUrl } from '../../../../helpers/returnShowUrl';
import CtaButton from '../../../Global/CtaButton';
import './styles.css';

interface Proptypes {
  url?: string;
  onChange: (url: string) => void;
  disabled?: boolean;
  displayFullUrl?: boolean;
  domain?:string;
}
const ScrapEditSourceUrl = ({ url, onChange, disabled, displayFullUrl,domain }: Proptypes) => {
  function changeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value);
  }

  if (disabled)
    return (
      <CtaButton
        attributes={{
          href: url,
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        displayFullUrl={displayFullUrl}
        url={url}
        domain={domain}

      >
        {displayFullUrl ? url : returnShowUrl(null, domain)}
      </CtaButton>
    );
  return (
    <div className="edit-source-url">
      <span className="edit-source-url__http"></span>
      <input
        disabled={disabled || false}
        placeholder="Add a Source URL"
        className="edit-source-url__input"
        type="url"
        defaultValue={url || ''}
        onChange={changeHandler}
        onFocus={(e) => {
          const inputEl = e.target as HTMLInputElement;
          inputEl.placeholder = 'https://';
        }}
        onBlur={(e) => {
          const inputEl = e.target as HTMLInputElement;
          let val = (inputEl?.value || '').trim();
          inputEl.placeholder = 'Add a Source URL';

          if (
            val.trim() !== '' &&
            (!val.startsWith('https://') || !val.startsWith('https://'))
          ) {
            val = 'https://' + val;
            inputEl.value = val;
          } else if (val === '') {
            inputEl.value = val;
          }
          onChange(val);
        }}
      />
    </div>
  );
};

export default ScrapEditSourceUrl;
