import { ApolloClient } from '@apollo/client';
import { scrapType } from '../../../types/scrapType';
import REMOVE_SCRAP_COLLECTIONS from '../../data/removeScrapCollections';
import UPSERT_SCRAP_COLLECTIONS from '../../data/upsertScrapCollections';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import { getResponseMessages } from '../../../helpers';

// Function to remove collections from scrap
export async function removeScrapCollectionsHandler(
  scrap_id: number,
  list: scrapType['collections'],
  client: ApolloClient<object>
) {
  try {
    // Start process of removing collections
    const response = await client.mutate({
      mutation: REMOVE_SCRAP_COLLECTIONS(),
      variables: {
        scrap_id,
        collections: list.map(({ id }) => ({ id })),
      },
    });
    const { isSuccess, error } = getResponseMessages(response.data.removeScrapCollections);
    if(!isSuccess) throw new Error(error[0])
  } catch (error) {
    throw new Error(parseTryCatchError(error, 'Failed to remove Scrap! Try again later.'));
  }
}

// Handler function to save collections in scrap
export async function saveCollectionsInScrapHandler(
  scrap_id: number,
  list: scrapType['collections'],
  client: ApolloClient<object>
) {
  try {
    // Start process of saving collections in scrap with mutation
    const response = await client.mutate({
      mutation: UPSERT_SCRAP_COLLECTIONS(),
      variables: {
        scrap_id,
        collections: list.map(({ id }) => ({         
          id,
        })),
      },
    });

    // Check if collections are saved successfully by looking for success message in response
    if (response.data.upsertScrapCollections.messages.success.length === 0) {
      throw new Error();
    }
  } catch (error) {
    throw new Error('Failed to save collections!');
  }
}
