import { isEmpty, parseNote } from '../../../helpers';
import { scrapType } from '../../../types/scrapType';
import { tagType } from '../../../types/tags';
import { FileDataType, FileItemStateType } from '../types';

// Return two array of removed and new Tags where:
// removedTags: tags which are removed from scrap
// newTags: tags which are new in scrap
export function returnRemovedAndNewTags(
  scrapTags: tagType[],
  updatedTags: tagType[]
) {
  const removedTags: tagType[] = [];
  const newTags: tagType[] = [];
  const scrapTagsObject = {};
  if (scrapTags.length) {
    scrapTags.forEach((tag) => {
      scrapTagsObject[tag.slug] = tag;
    });
  }
  const currentTagsObject = {};
  if (updatedTags.length) {
    updatedTags.forEach((tag) => {
      currentTagsObject[tag.slug] = tag;
    });
  }

  // Check for removed tags from scrap
  scrapTags.forEach((tag) => {
    if (!currentTagsObject[tag.slug]) removedTags.push(tag);
  });

  // Check for newly added tags in scrap
  updatedTags.forEach((tag) => {
    if (!scrapTagsObject[tag.slug]) newTags.push(tag);
  });
  return { removedTags, newTags };
}

// Return two array of removed and new Collections where:
// removedCollections: collections which are removed from scrap
// newCollections: collections which are new in scrap 
export function returnRemovedAndNewCollections(
  scrapCollectionIds: scrapType['collections'],
  updatedCollections: scrapType['collections']
) {
  const removedCollections: scrapType['collections'] = [];
  const newCollections: scrapType['collections'] = [];
  const scrapCollectionsObject = {};
  if (scrapCollectionIds?.length) {
    scrapCollectionIds.forEach((collection) => {
      scrapCollectionsObject[collection.id] = collection;
    });
  }
  const currentCollectionsObject = {};
  if (updatedCollections.length) {
    updatedCollections.forEach((collection) => {
      currentCollectionsObject[collection.id] = collection;
    });
  }

  // Check for removed collections from scrap
  (scrapCollectionIds || []).forEach((collection) => {
    if (!currentCollectionsObject[collection.id])
      removedCollections.push(collection);
  });

  // Check for newly added collections in scrap
  updatedCollections.forEach((collection) => {
    if (!scrapCollectionsObject[collection.id]) newCollections.push(collection);
  });
  return { removedCollections, newCollections };
}

// Returns array of removed images from scrap
export function returnRemovedAndUpdatedImages(
  scrapImages: scrapType['images'],
  newList: FileItemStateType[]
) {
  const removedFilesList: FileItemStateType[] = [];
  const updatedFilesList: FileItemStateType[] = [];
  // const newFilesList: FileItemStateType[] = [];

  const scrapIagesObject = {};
  scrapImages.forEach((item) => {
    scrapIagesObject[item.id] = item;
  });

  const currentFilesObject = {};
  newList.forEach((item) => {
    if (item.id) {
      currentFilesObject[item.id] = item;
      const foundImage: scrapType['images'][number] = scrapIagesObject[item.id];
      const oldDesc = parseNote(foundImage.desc);
      const newDesc = parseNote(item.description);
      const isOldDescEmpty = isEmpty(oldDesc, true);
      const isNewDescEmpty = isEmpty(newDesc, true);
      if (item.fileUrl !== foundImage.file_path) {
        updatedFilesList.push(item);
      } else if (!(isOldDescEmpty && isNewDescEmpty) && oldDesc !== newDesc) {
        updatedFilesList.push(item);
      } else if (+item.order !== +foundImage.order) {
        updatedFilesList.push(item);
      }
    } else {
      updatedFilesList.push(item);
    }
  });
  if (scrapImages?.length) {
    scrapImages.forEach((item) => {
      // If the scrap document file id is not found in new files list then
      // It means that file is removed by user, so save it in removedFilesList
      if (!currentFilesObject[item.id])
        removedFilesList.push({
          id: item.id,
          fileUrl: null,
          key: null,
          filename: null,
          description: null,
          fileType: null,
          fileSize: null,
          order: null,
          mimeType: item.mimeType,
        });
    });
  }
  return { removedFilesList, updatedFilesList };
}

// Returns array of removed and newly added documents where
// removedFileslist: files which are removed from scrap
// newFilesList: files which are added to scrap
export function returnRemovedAndNewDocumentFiles(
  scrapDocuments: scrapType['documents'],
  updatedList: FileDataType[]
) {
  const removedFilesList: FileDataType[] = [];
  const newFilesList: FileDataType[] = [];
  const currentFilesObject = {};
  updatedList.forEach((item) => {
    if (item.id) currentFilesObject[item.id] = item;
    else newFilesList.push(item); // Else it is a new file, so save it in newFilesList Array
  });
  if (scrapDocuments?.length) {
    scrapDocuments.forEach((item) => {
      // If the scrap document file id is not found in new files list then
      // It means that file is removed by user, so save it in removedFilesList
      if (!currentFilesObject[item.id])
        removedFilesList.push({
          id: item.id,
          key: 'file-' + Math.ceil(Math.random() * 10000),
          fileUrl: item.file_path,
          fileType: +item.file_type,
          filename: item.file_name,
        });
    });
  }
  return { removedFilesList, newFilesList };
}
