import * as config from '../settings.json';

// get the list of set cookies
// fetch the value of the specific key
const getCookie = (name, exitingToken) => {
  const cookieData = exitingToken || `${(typeof window !== 'undefined' && document) ? '; ' + document.cookie : ''}`;
  const keys = cookieData.split(`; ${name}=`);
  if (keys.length === 2) {
    return keys.pop().split(';').shift();
  }

  // or return staic token from settings
  return config.token;
};

export const getToken = (serverCookie = null) => {
  if (typeof window === "undefined") {
    return config.token;
  }

  return getCookie('_token_', serverCookie);
};
