import React from 'react';

interface Proptypes {
  className?: string;
  onClick?:()=>void;
}

const Back = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80436 11.7007C6.41737 12.0947 5.78423 12.1004 5.3902 11.7134L0.299292 6.71345C0.107845 6.52542 0 6.26834 0 6C0 5.73166 0.107845 5.47458 0.299292 5.28655L5.3902 0.286551C5.78423 -0.100439 6.41737 -0.0947351 6.80436 0.299292C7.19135 0.693318 7.18565 1.32646 6.79162 1.71345L3.44531 5L19 5C19.5523 5 20 5.44771 20 6C20 6.55228 19.5523 7 19 7L3.44531 7L6.79162 10.2866C7.18565 10.6735 7.19135 11.3067 6.80436 11.7007Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default Back;
