import React, { useEffect, useState } from 'react';
import { parseNote } from '../../../../helpers';
import { scrapType } from '../../../../types/scrapType';
import ReadMoreText from '../../../Cards/ReadMoreText';
import { onClickType } from '../../../ScrapCard';
import { CaptionIcon, TrashOutlineIcon } from '../../icons';
import { InputFieldType } from '../../../../types/inputFieldType';
import AutoGrowingTextarea from '../../AutoGrowingTextArea';
interface Proptypes {
  annotation: scrapType['annotations'][number];
  onMoreClick?: (
    onClickType: onClickType,
    index,
    currentlyOpened?: boolean
  ) => boolean;
  setSelectedAnnotationIndex?: (val) => void;
  index?: number;
  showAll?: boolean;
  onDelete?: () => void;
  onEdit?: (text: string) => void;
}

const HighlightsItem = ({
  annotation,
  onMoreClick,
  setSelectedAnnotationIndex,
  index,
  showAll,
  onDelete,
  onEdit,
}: Proptypes) => {
  const [deleteStatus, setDeleteStatus] = useState<
    'none' | 'delete-warning' | 'deleted'
  >('none');
  // const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const showDeleteWarning = deleteStatus === 'delete-warning';
  const { highlighted_text: text, comment } = annotation || {};
  const [caption, setCaption] = useState<InputFieldType<string>>({
    gotChanged: false,
    value: comment,
  });

  const captionIsDefined = !!caption.value?.trim();
  const showFooter = captionIsDefined || !!onDelete || !!onEdit;
  useEffect(() => {
    setCaption({
      value: comment,
      gotChanged: false,
    });
  }, [annotation]);

  if(deleteStatus === 'deleted') return null;
  
  return (
    <div
      className={`highlights__item${
        showFooter ? ' highlights__item--with-caption' : ''
      }`}
    >
      <ReadMoreText
        hideLess
        defaultExpanded={false}
        // onLoad={onCardResize}
        content={parseNote(text)}
        showFull={showAll}
        onMoreClick={() => onMoreClick('anotations', index)}
      />
      {showFooter && (
        <div className="highlights__footer">
          {showDeleteWarning ? (
            <div className="highlights__delete-warning">
              <span className="highlights__delete-warning-message">
                Are you sure you want to delete this highlight?
              </span>
              <div className="highlights__delete-warning-actions">
                <button
                  className="highlights__delete-warning-cancel-button button button__outline"
                  onClick={(e) => setDeleteStatus('none')}
                >
                  Cancel
                </button>
                <button
                  className="highlights__delete-warning-submit-button"
                  onClick={() => {
                    onDelete();
                    setDeleteStatus('deleted');
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="highlights__caption">
                <CaptionIcon />{' '}
                <AutoGrowingTextarea
                  placeholder="Add caption..."
                  minHeight={16}
                  text={caption.value}
                  onChange={
                    onEdit
                      ? (value) => {
                        setCaption({ value, gotChanged: true });
                      }
                      : null
                  }
                />
              </div>
              {!caption.gotChanged && onDelete && (
                <button
                  onClick={() => setDeleteStatus('delete-warning')}
                  className="highlights__delete-button"
                >
                  <TrashOutlineIcon />
                </button>
              )}
              {caption.gotChanged && (
                <div className="highlights__edit-actions">
                  <button
                    className="highlights__edit-cancel-button button button__outline"
                    onClick={(e) => {
                      setCaption({
                        gotChanged: false,
                        value: comment || '',
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="highlights__edit-submit-button"
                    onClick={() => {
                      onEdit(caption.value);
                      setCaption((old) => ({
                        ...old,
                        gotChanged: false,
                      }));
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default HighlightsItem;
