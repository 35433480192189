import { topicTypes } from '../../types/topic';

export const loadTopics = (topics: Array<topicTypes>) => {
  return {
    type: 'LOAD_TOPICS',
    topics,
  };
};

export const setActiveTopic = (topic: topicTypes) => {
  return {
    type: 'LOAD_ACTIVE_TOPIC',
    activeTopic: topic,
  };
};

export const addTopic = (topic: topicTypes) => {
  return {
    type: 'ADD_TOPIC',
    topic,
  };
};

export const updateTopicNote = (id: string, note: string) => {
  return {
    type: 'UPDATE_TOPIC_NOTE',
    id,
    note,
  };
};
