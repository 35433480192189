import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const FeedBackIcon = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1572 19.523V32.3421C14.1572 34.7021 16.06 36.6152 18.4072 36.6152H22.6572V37.1822C22.6572 40.2416 26.2411 41.8766 28.5311 39.862L32.2218 36.6152H38.2406C40.5878 36.6152 42.4906 34.7021 42.4906 32.3421V19.523C42.4906 17.1631 40.5878 15.25 38.2406 15.25H18.4072C16.06 15.25 14.1572 17.1631 14.1572 19.523ZM22.6572 33.7665C23.7358 33.7665 24.6735 34.3724 25.1522 35.2641L25.1749 35.3071C25.3391 35.6259 25.4451 35.9799 25.4789 36.3549C25.4866 36.4406 25.4906 36.5274 25.4906 36.6152V37.1822C25.4906 37.7941 26.2073 38.1211 26.6653 37.7182L30.356 34.4713C30.8725 34.0169 31.5355 33.7665 32.2218 33.7665H38.2406C39.023 33.7665 39.6572 33.1288 39.6572 32.3421V19.523C39.6572 18.7364 39.023 18.0987 38.2406 18.0987H18.4072C17.6248 18.0987 16.9906 18.7364 16.9906 19.523V32.3421C16.9906 33.1288 17.6248 33.7665 18.4072 33.7665H22.6572ZM28.3763 19.5C27.41 19.5 26.6376 20.3038 26.6762 21.2694L26.9951 24.9906C27.0247 25.732 27.6344 26.3177 28.3763 26.3177C29.1182 26.3177 29.7278 25.732 29.7575 24.9906L30.0763 21.2694C30.115 20.3039 29.3426 19.5 28.3763 19.5ZM26.34 30.5234C26.34 29.4232 27.2319 28.5313 28.3322 28.5313C29.4325 28.5313 30.3244 29.4232 30.3244 30.5234C30.3244 31.6237 29.4325 32.5156 28.3322 32.5156C27.2319 32.5156 26.34 31.6237 26.34 30.5234Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default FeedBackIcon;
