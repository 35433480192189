import React from 'react';

import './styles.css';

import AuthHeaderLayout from './components/AuthHeaderLayout';
import { Link } from 'react-router-dom';

interface Proptypes {
  message: string;
  title: string;
  buttonLabel?: string;
  buttonLink?: string | { pathname: string; search: string; state };
  hideActions?: boolean;
  view?: 'accept-collaborator' | 'register-user' | 'account-recovery';
}

const ErrorAuthPage = (props: Proptypes) => {
  const { message, title, buttonLabel, buttonLink, hideActions, view } = props;

  return (
    <AuthHeaderLayout
      className={view}
      title={title}
      helmetTitle="Scrappi"
      subHeading={message}
    >
      {!hideActions && (
        <div className="form-group form-group__action">
          <Link to={buttonLink || '/login'} className="button button__primary">
            {buttonLabel || 'Back to login'}
          </Link>
        </div>
      )}
      <button
        type="button"
        className="button sl-auth__register-button button__primary"
        onClick={() => (window.location.href = '/')}
      >
        Back to Home
      </button>
    </AuthHeaderLayout>
  );
};

export default ErrorAuthPage;
