import React from 'react';
import './styles.css';

interface Proptypes {
  errorMessage?: string;
  renderCollectionsField: () => React.ReactElement;
  renderTagsField: () => React.ReactElement;
  children: React.ReactElement;
}

const ScrapNotePopup = ({
  errorMessage,
  children,
  renderCollectionsField,
  renderTagsField,
}: Proptypes) => {
  return (
    <div className="scrap-note-popup">
      {children}
      <div className="scrap-note-popup__double-fields-container">
        <div className="scrap-note-popup__collections">
          {renderCollectionsField()}
        </div>
        <div className="scrap-note-popup__tags">{renderTagsField()}</div>
      </div>
      {errorMessage && <span className='scrap-note-popup__error'>{errorMessage}</span>}
    </div>
  );
};

export default ScrapNotePopup;
