import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import USER_BOOKMARK from '../data/userBookmark';
import { collectionType } from '../../types/collections';
import { scrapType } from '../../types/scrapType';
import Bookmarks from '../../components/Bookmarks';
import ScrapCard from '../../components/ScrapCard';
import NewCollectionCard from '../../components/NewCollectionCard';
import GET_REACTIONS from '../data/getReactions';
import { ReactionType } from '../../components/Comments';
import ADD_REACTION from '../data/addReaction';
import REMOVE_REACTION from '../data/removeReaction';
import {
  generateRandomKey,
  getResponseMessages,
  removeSpecialCharacters,
  returnScrapSlug,
} from '../../helpers';
import { sendToast } from '../../helpers/notification';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import { useHistory, useLocation } from 'react-router-dom';
import { returnShareLink } from '../../helpers/returnShareLinks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCollaborationPopup,
  setCollectionAction,
  setCollectionSettingsPopup,
  setDeleteScrapPopup,
  setIsMainPageLoading,
  setScrapPopup,
} from '../../redux/action/utils';
import { ReduxStateType } from '../../redux/store';
import { setOneScrapAction } from '../../redux/action/onScrapActions';
import { collectionCardFooterOptionsType } from '../../components/NewCollectionCard/UiComponents/NewCollectionFooter';
import { updateCollectionsLikesAndComments } from '../../redux/action/collectionsLikesAndCommentsActions';
import { updateScrapsLikesAndComments } from '../../redux/action/scrapsLikesAndCommentsActions';
import Onboarding from "../../components/Onboarding";

const first = 20;
interface BookmarkCollectionType {
  type: 'collection';
  data: collectionType;
}

interface BookmarkReactionType {
  type: 'scrap' | 'collection';
  reaction: ReactionType;
}

interface BookmarkScrapType {
  type: 'scrap';
  data: scrapType;
}
export type BookmarkItemType = BookmarkCollectionType | BookmarkScrapType;

export default function BookmarksContainer() {
  const { push } = useHistory();
  const { search } = useLocation();
  let showOnly: 'collections' | 'scraps' = null;
  if (search === '?type=scraps') showOnly = 'scraps';
  else if (search === '?type=collections') showOnly = 'collections';
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateType) => state.user);
  const oneScrapAction = useSelector(
    (state: ReduxStateType) => state.oneScrapAction
  );
  const collectionAction = useSelector(
    (state: ReduxStateType) => state.utils.collectionAction
  );
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalBookmarks, setTotalBookmarks] = useState<number>(null);
  const [list, setList] = useState<{
    key: string;
    data: Array<BookmarkItemType>;
  }>({
    key: generateRandomKey(),
    data: [],
  });
  const [runOnboarding, setRunOnboarding] = useState(false);

  const reactionsRef = useRef<Array<BookmarkReactionType>>([])
  const client = useApolloClient();

  // Functions to load all the reactions and then return them in the order
  // in which ids were passed to this function as parameter
  const loadReactionsHandler = async (
    ids: Array<string | number>,
    idKey: 'scrap_ids' | 'collection_ids'
  ) => {
    const response = await client.query({
      query: GET_REACTIONS(),
      variables: {
        [idKey]: ids,
        reactable_type: idKey === 'scrap_ids' ? 1 : 2,
      },
    });
    const scrapsReactions: Array<ReactionType> =
      response?.data?.getReactions?.data || [];
    const orderedScrapsReactions: Array<ReactionType> = new Array(ids.length);
    ids.forEach((id, i) => {
      orderedScrapsReactions[i] =
        scrapsReactions.find((data) => data.id == id) ?? null;
    });
    return orderedScrapsReactions;
  };

  const bookmarkDeletedHandler = async (bookmarkIndex: number) => {
    setList((old) => ({
      key: generateRandomKey(),
      data: old.data.filter((_, i) => i !== bookmarkIndex),
    }));
    reactionsRef.current.splice(bookmarkIndex, 1)
    const { data, reactionsPromise } = await fetchDataHandler({
      page,
    });
    if (data.length === first) {
      setList((old) => ({
        key: generateRandomKey(),
        data: [...old.data, data[first - 1]],
      }));
      const reactions = await reactionsPromise;
      const newReactionData = reactions[first - 1];
      reactionsRef.current.push(newReactionData);



    }
  };

  // Function to load book marks list which will also return
  // promise to load all the reactions of loaded bookmarks
  const fetchDataHandler = async (variables: { page?: number }) => {
    setIsLoading(true);

    // load bookmarks
    const response = await client.query({
      query: USER_BOOKMARK(),
      variables: {
        ...variables,
        first,
        is_collections_needed: showOnly === 'collections',
        is_scraps_needed: showOnly === 'scraps',
      },
    });
    const bookmarksData = response?.data?.userBookmark;
    if (!bookmarksData) {
      setIsLoading(false);
      return { data: [], reactionsPromise: null };
    }
    // save the total bookmarks count
    setTotalBookmarks(bookmarksData.paginatorInfo?.total);

    // parse out loaded scraps from the response
    const fetchedScraps: Array<scrapType> = bookmarksData.data?.scrap || [];

    // parse out loaded users from the response
    const fetchedUsers: Array<{
      avatar: string;
      display_name: string;
      user_id: number;
      user_name: string;
    }> = bookmarksData.data?.user || [];

    // parse out loaded scrapsCounts from the response
    const fetchedCounts: Array<{
      id: number;
      scraps_count: number;
    }> = bookmarksData.data.scrapsCount || [];

    // parse out loaded collections from the response
    const fetchedCollections: Array<collectionType> =
      bookmarksData.data?.collection || [];

    // parse out orders from the response which will be in sequence
    // in which bookmarks will be displayed on screen
    const orderedIds: Array<{
      scrap_id: scrapType['id'];
      child_collection_id: collectionType['id'];
    }> = bookmarksData.data?.bookmark_order || [];

    // Return if their are no bookmarks
    if (!orderedIds.length) {
      setIsLoading(false);
      return { data: [], reactionsPromise: null };
    }

    // Promise to load all the reactions for fetched scraps
    const fetchedScrapsReactionsPromise = loadReactionsHandler(
      fetchedScraps.map((scrap) => scrap.id),
      'scrap_ids'
    );
    // Promise to load all the reactions for fetched collections
    const fetchedCollectionsReactionsPromise = loadReactionsHandler(
      fetchedCollections.map((collection) => collection.id),
      'collection_ids'
    );

    // log warning if there is some data mismatch because
    // length of total ordererd ids i.e. ordererdIds should be equal to
    // sum of length of parsed scraps and collections
    if (
      orderedIds.length !==
      fetchedScraps.length + fetchedCollections.length
    ) {
      console.warn('some data are missing in collection or scrap array');
    }

    // a new variable to store the bookmark items in organized way
    const bookmarkItems: Array<BookmarkItemType> = [];

    // additional variable to handle the data mismatch and
    // remove all those ordered ids for which no scrap or collection is found
    // in parsed collections and scraps list
    const filteredOrderIds: typeof orderedIds = [];

    // loop through all orderedIds and store the bookmark
    // items with their type(collection or scrap) in bookmarkItems array
    // It also filters out the orderedIds list and stores
    // the new filtered orderids in filteredOrderIds array
    orderedIds.forEach((idsData) => {
      const { child_collection_id: collection_id, scrap_id } = idsData;
      if (scrap_id) {
        let scrap = fetchedScraps.find((scrap) => scrap.id == scrap_id);
        if (scrap) {
          const foundUser = fetchedUsers.find(
            (data) => data.user_id === scrap.user_id
          );
          if (foundUser)
            scrap = {
              ...scrap,
              ...foundUser,
            };
          bookmarkItems.push({
            type: 'scrap',
            data: scrap,
          });
          filteredOrderIds.push(idsData);
        } else {
          console.warn(
            `scrap not found for this id ${scrap_id} in fetched scraps`
          );
        }
      } else if (collection_id) {
        let collection = fetchedCollections.find(
          (collection) => collection.id == collection_id
        );
        if (collection) {
          const foundUser = fetchedUsers.find(
            (data) => data.user_id === collection.user_id
          );
          const foundScrapsCount = fetchedCounts.find(
            (data) => +data.id === +collection.id
          );
          if (foundUser)
            collection = {
              ...collection,
              ...foundUser,
            };
          if (foundScrapsCount) {
            collection.scraps_count = foundScrapsCount.scraps_count;
          }
          bookmarkItems.push({
            type: 'collection',
            data: collection,
          });
          filteredOrderIds.push(idsData);
        } else {
          console.warn(
            `collection not found for this id ${collection_id} in fetched collections`
          );
        }
      } else {
        console.warn('data not found');
      }
    });
    setIsLoading(false);
    // Return the organized bookmark items  list and promise which
    // returns all the reactions in sequence
    return {
      data: bookmarkItems,
      reactionsPromise: Promise.all([
        fetchedScrapsReactionsPromise,
        fetchedCollectionsReactionsPromise,
      ]).then(([scrapsReactions, collectionsReactions]) => {
        const reactions: Array<BookmarkReactionType> = [];

        filteredOrderIds.forEach(
          ({ child_collection_id: collection_id, scrap_id }) => {
            if (scrap_id) {
              const reaction = scrapsReactions.find(
                (data) => +data?.id === +scrap_id
              );
              reactions.push({
                type: 'scrap',
                reaction,
              });
            } else if (collection_id) {
              const reaction = collectionsReactions.find(
                (data) => +data?.id === +collection_id
              );
              reactions.push({
                type: 'collection',
                reaction,
              });
            } else {
              console.warn('data not found');
            }
          }
        );

        // Update the the data to redux for reactions and comments
        const filteredScrapsReactions = scrapsReactions.filter(reaction => !!reaction);
        const filteredCollectionsReactions = collectionsReactions.filter(reaction => !!reaction)
        filteredScrapsReactions.length &&
          dispatch(
            updateScrapsLikesAndComments(
              filteredScrapsReactions.map((reaction) => {
                const scrap = bookmarkItems.find(
                  (item) => item.type === 'scrap' && item.data.id == reaction.id
                )?.data as scrapType;
                return {
                  scrapId: reaction.id,
                  data: {
                    likesCount: +scrap?.reaction_count,
                    isLiked: reaction.is_reacted,
                  },
                };
              })
            )
          );
        filteredCollectionsReactions.length &&
          dispatch(
            updateCollectionsLikesAndComments(
              filteredCollectionsReactions.map((reaction) => {
                const collection = bookmarkItems.find(
                  (item) => item.type === 'collection' && +item.data.id === +reaction.id
                )?.data as collectionType;
                return {
                  collectionId: reaction.id,
                  data: {
                    isLiked: reaction.is_reacted,
                    likesCount: collection?.reaction_count,
                    commentsData: {
                      count: collection?.comment_count
                    }
                  }
                };
              })
            )
          );  
        return reactions;
      }),
    };
  };

  // Function to load more bookmarks when user scrolls to bottom of page
  const loadMoreHandler = async () => {
    if (list.data.length === totalBookmarks) return;
    setPage(page + 1);
    const { data, reactionsPromise } = await fetchDataHandler({
      page: page + 1,
    });
    if (data.length) {
      setList((old) => ({
        ...old,
        data: [...old.data, ...data],
      }));
      const reactions = await reactionsPromise;
      if(!reactions.length) return;
      reactionsRef.current.push(...reactions)
    }
  };

  // Function to call when reaction mutation is performed successfully
  // and then update the bookmarks and reactions list state
  const reactionToggledHandler = (
    bookmarkIndex: number,
    isReacted: boolean
  ) => {
    const {data: item, type: itemType} = list.data[bookmarkIndex];
    const reactionData = reactionsRef.current[bookmarkIndex]
    if(reactionData) {
      const reaction = reactionData.reaction;
      if(reaction) {
        reaction.is_reacted = isReacted;
      } else {
        reactionData.reaction = {
          is_reacted: isReacted,
          id: +item.id,
          reactable_type: 1,
        }
      }
    } else {
      reactionsRef.current[bookmarkIndex] = {
        type: itemType,
        reaction: {
          is_reacted: isReacted,
          id: +item.id,
          reactable_type: 1,
        }
      }
    }

  };

  // Function for returning the link for bookmark item where user
  // will be redirected when he clicks on it
  const returnOnClickLink = (bookmark: BookmarkItemType) => {
    const { data, type } = bookmark;
    switch (type) {
      case 'collection':
        return returnShareLink(
          'collection',
          removeSpecialCharacters(data.title) + '-' + data.id,
          data.user_name,
          true
        );
      case 'scrap':
        return returnShareLink(
          'scrap',
          returnScrapSlug(data),
          data.user_name,
          true
        );

      default:
        break;
    }
  };

  // Function to handle the click on collection card footer dropdown
  function collectionFooterDropdownOptionClick(
    type: collectionCardFooterOptionsType['list'][number],
    collection
  ) {
    switch (type) {
      case 'edit':
        dispatch(setCollectionSettingsPopup({ collection }, false));
        break;
      case 'manageCollaborators':
        dispatch(
          setCollaborationPopup({
            collectionId: collection.id,
            onSaved: () => {},
          })
        );
        break;
      default:
        break;
    }
  }

  // Function to render bookmark list item
  const render = useCallback(
    (data: BookmarkItemType, itemIndex: number) => {
      if (data.type === 'scrap') {
        // to check if the bookmark item is scrap and then render scrap card
        const isScrapMine = user.user_id === data.data.user_id;
        return (
          <ScrapCard
            isBookmarked
            onBookmarkToggled={bookmarkDeletedHandler.bind(null, itemIndex)}
            onEditClick={
              isScrapMine
                ? () => {
                  dispatch(
                    setScrapPopup({
                      type: 'id',
                      defaultEdit: true,
                      data: data.data.id,
                    })
                  );
                }
                : null
            }
            onDeleteClick={
              isScrapMine
                ? () => {
                  dispatch(
                    setDeleteScrapPopup({
                      scrap: data.data as scrapType,
                      onComplete: (scrap) => {
                        // setEvent({ type: 'delete', scrap });
                        bookmarkDeletedHandler(itemIndex);
                        dispatch(setDeleteScrapPopup(null));
                      },
                    })
                  );
                }
                : null
            }
            onLikeToggled={reactionToggledHandler.bind(null, itemIndex)}
            scrap={data.data}
          />
        );
      } else {
        // else bookmark item is a collection, then renderder collection card
        const collection = data.data;
        const isCollectionMine = user.user_id === collection.user_id;
        const footerDropdownOptions: collectionCardFooterOptionsType = isCollectionMine
          ? {
            list: ['edit', 'share'],
            onOptionClick: (type) =>
              collectionFooterDropdownOptionClick(type, collection),
          }
          : null;
        return (
          <NewCollectionCard
            scrapCount={collection.scraps_count}
            isBookmarked
            onBookmarkToggled={bookmarkDeletedHandler.bind(null, itemIndex)}
            onCardClick={() => {
              push(returnOnClickLink(data));
            }}
            onLikeClicked={reactionToggledHandler.bind(
              null,
              itemIndex,
            )}
            cardLayout="compact"
            data={collection}
            footerDropdownOptions={footerDropdownOptions}
          />
        );
      }
    },
    [user, list]
  );

  // useEffect for loading the initial bookmarks data when component
  // gets rendered for the first time
  useEffect(() => {
    reactionsRef.current = [];
    setPage(1);
    setList({ key: generateRandomKey(), data: [] });
    setTotalBookmarks(null);
    fetchDataHandler({
      page: 1,
    })
      .then(({ data, reactionsPromise }) => {
        setList({
          key: generateRandomKey(),
          data,
        });
        return reactionsPromise;
      })
      .then((reactions) => {
        reactionsRef.current = reactions;
        dispatch(setIsMainPageLoading(false));
      });
  }, [showOnly]);

  // useEffect to listen for scrap actions like update or delete
  // and then updating the list accordingly
  useEffect(() => {
    if (oneScrapAction.scrap || (oneScrapAction.type && list)) {
      const { scrap, type } = oneScrapAction;
      const scrapIndex = list.data.findIndex(
        ({ data, type }) => type === 'scrap' && scrap.id == data.id
      );
      switch (type) {
        case 'edit':
          // updateKnotHandler(scrap);
          setList((old) => ({
            ...old,
            data: old.data.map((item, i) => {
              const newScrapData: scrapType = {
                ...(item.data as scrapType),
                ...scrap,
              };
              if (scrapIndex === i) {
                return {
                  type: 'scrap',
                  data: newScrapData,
                };
              }
              return item;
            }),
          }));
          break;
        case 'delete':
          // knotDeletedHandler(scrap);
          bookmarkDeletedHandler(scrapIndex);
          break;

        default:
          break;
      }
      dispatch(setOneScrapAction(null));
    }
  }, [oneScrapAction]);

  // useEffect to listen for collection actions like update or delete
  // and then updating the list accordingly
  useEffect(() => {
    if (collectionAction && list.data.length) {
      const {
        collection: actionCollection,
        type: actionType,
      } = collectionAction;
      const collectionIndex = list.data.findIndex(
        ({ data, type }) =>
          type === 'collection' && actionCollection.id == data.id
      );
      switch (actionType) {
        case 'edit':
          // updateKnotHandler(actionCollection);
          setList((old) => ({
            ...old,
            data: old.data.map((item, i) => {
              const newCollectionData: collectionType = {
                ...(item.data as collectionType),
                ...actionCollection,
              };
              if (collectionIndex === i) {
                return {
                  type: 'collection',
                  data: newCollectionData,
                };
              }
              return item;
            }),
          }));
          break;
        case 'delete':
          bookmarkDeletedHandler(collectionIndex);
          break;

        default:
          break;
      }
    }
    dispatch(setCollectionAction(null));
  }, [collectionAction]);

  /**
   * Onboarding visibility
   */
  useEffect(() => {
    // if it has tour param in the url
    const params = document.location.search;
    const tour = params.includes('tour');

    if(tour) {
      // add a delay of 2s to show the onboarding
      setTimeout(() => {
        setRunOnboarding(true);
      }, 2000);
    }
  }, [setRunOnboarding]);

  return (
    <>
      <Bookmarks
        list={list.data}
        masonryKey={list.key}
        onLoadMore={loadMoreHandler}
        isLoading={isLoading}
      >
        {render}
      </Bookmarks>

      { runOnboarding && (
        <Onboarding type="backToScrap" runOnboarding={runOnboarding} />
      )}
    </>
  );
}
