/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import qs from 'qs';
import {
  GENERATE_OTP_MUTATION,
  LOGIN_MUTATION,
} from './mutations';
import Account_Messages, { accountNotExistErrorCode } from '../Messages';
import { ProfileType } from '../../types/ProfileType';
import { ExclamationSquare } from '../../../Global/icons';
import { parseTryCatchError } from '../../../../helpers/parseTryCatchError';
import {
  LOGIN_VIA,
  OTP_LENGTH,
  PROFILE_SETUP_PATH,
} from '../../../../helpers/config';
import { getResponseMessages, parseUrlParams, setToken } from '../../../../helpers';
import { mapUserZone } from '../../../../helpers/getUserTimeZone';
import Close from '../../../Global/icons/Close';
import ConfirmationScreen from '../../../Global/ConfirmationScreen';
import './styles.css';
import { REGISTER } from '../../mutations';
import CountdownTimer from '../../../Global/CountdownTimer';

interface Proptypes {
  isLogin?: boolean;
  sentTo: string;
  verifyFor: 'login' | 'register';
  isMobileNumber: boolean;
  countryCode?: string;
  countryISO?: string;
  isProcesssing: boolean;
  setIsProcessing: (val: boolean) => void;
  onSuccess: (data?: ProfileType) => void;
  overrideSubmit?: boolean;
  setVerifyOTP: (val: boolean) => void;
  error?: string;
  setError: (val: string) => void;
  onClose?: () => void;
  confirmRegister?: boolean;
}

interface LoginVariables {
  otp: string;
  email?: string;
  phone_number?: string;
  country_code?: string;
  country_iso?: string;
  login_via: number;
}

const VerifyOTP = ({
  isLogin = false,
  verifyFor,
  isMobileNumber,
  countryCode = '',
  countryISO = '',
  isProcesssing,
  setIsProcessing,
  sentTo,
  onSuccess,
  overrideSubmit = false,
  setVerifyOTP,
  error,
  setError,
  onClose,
  confirmRegister,
}: Proptypes) => {
  const [generateOTPMutation, { loading: isGenerating }] = useMutation(GENERATE_OTP_MUTATION);
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [registerMutation] = useMutation(REGISTER());
  const { search } = useLocation();
  const { redirect } = parseUrlParams(search);
  const { collectionCollaboratorToken, token, type } = qs.parse(
    search.substring(1)
  );
  const [otp, setOtp] = useState('');
  // For Country And Timezone
  const ct = require('countries-and-timezones');
  const [timezone, setTimeZone] = useState('');
  const [country, setCountry] = useState('');

  // For registration confirmation screen
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [registerData, setRegisterData] = useState<
    LoginVariables | undefined
  >();

  useEffect(() => {
    const getTimezone = async () => {
      let timezoneFromIntl = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let userTimeZone = await mapUserZone(timezoneFromIntl);
      let userCountry = await ct.getCountryForTimezone(userTimeZone);
      setTimeZone(userTimeZone);
      setCountry(await mapUserZone(userCountry?.name, 'country'));
    };
    getTimezone();
  }, []);

  // Handle Error
  const handleError = (err) => {
    // IF CONDITION TO CHECK FOR THE VALIDATION ERROR CAUSED DUE TO USERNAME BEING ALREADY TAKEN
    if (
      err &&
      err.graphQLErrors &&
      err.graphQLErrors[0]?.extensions?.validation
    ) {
      const { name, email: errorEmail } =
        err.graphQLErrors[0].extensions.validation || {};

      let message = 'Some error occurred';
      if (Array.isArray(errorEmail)) {
        message = errorEmail[0];
      } else if (Array.isArray(name)) {
        message = name[0];
      }
      setError(message || parseTryCatchError(err));
    } else {
      setError(parseTryCatchError(err));
    }
    setIsProcessing(false);
  };

  // Send OTP to Email/Phone Number as requested
  const sendOTP = useCallback(async () => {
    if (sentTo) {
      setIsProcessing(true);
      try {
        if (isMobileNumber) {
          await generateOTPMutation({
            variables: {
              phone: returnPhoneNumber(),
              country_code: `+${countryCode}`,
              check_existing: overrideSubmit,
              is_login: isLogin,
            },
          })
            .then((res) => {
              const { isSuccess, error } = getResponseMessages(res.data.GenerateOtp)
              if (!isSuccess) {
                setVerifyOTP(false);
                setError(error[0]);
              }
            })
            .catch((err) => {
              setIsProcessing(false);
              setVerifyOTP(false);
              handleError(err);
            });
        } else {
          await generateOTPMutation({
            variables: {
              email: sentTo,
              check_existing: overrideSubmit,
              is_login: isLogin,
            },
          })
            .then((res) => {
              const { isSuccess, error } = getResponseMessages(res.data.GenerateOtp);
              if (!isSuccess) {
                setVerifyOTP(false);
                setError(error[0]);
              }
            })
            .catch((err) => {
              setIsProcessing(false);
              setVerifyOTP(false);
              handleError(err);
            });
        }
      } catch (err) {
        setVerifyOTP(false);
        handleError(err);
      }
      setIsProcessing(false);
    }
  }, [sentTo]);

  useEffect(() => {
    sendOTP();
  }, [sendOTP, sentTo]);

  // Check if OTP length is matched in input to allow form submission
  const isOTPDisabled = () => {
    return otp.length !== OTP_LENGTH;
  };

  // Removed Formatting from PhoneNumber
  const returnPhoneNumber = (returnFull = false) => {
    if (sentTo.indexOf(' ')) {
      if (returnFull) {
        return sentTo.replace(' ', '').replace('-', '');
      }
      return sentTo.split(' ')[1].replace('-', '');
    }
    return sentTo;
  };

  // Handle redirection on success
  const handleRedirection = (onboard_status?: boolean) => {
    if (collectionCollaboratorToken)
      window.location.href = `/accept-collaborator?token=${collectionCollaboratorToken}`;
    else if (onboard_status) {
      window.location.replace(PROFILE_SETUP_PATH);
    } else if(redirect && typeof redirect === 'string') {
      window.location.replace(redirect)
    } else {
      window.location.replace('/');
    }
  };

  // Handle Register
  const handleRegister = async (loginVariables: LoginVariables) => {
    const additionalVariables = {
      ...(token ? { token } : {}),
      ...(type ? { type } : {}),
      register_from: 1
    };
    const variables: any =
      countryCode.length > 0
        ? { ...loginVariables, ...additionalVariables, timezone, country }
        : {
          ...loginVariables,
          timezone,
          country,
          ...additionalVariables,
          email_verified: true,
        };
    if(variables.phone_number) {
      variables.phone = variables.phone_number;
      delete variables.phone_number
    }    
    setIsProcessing(true);
    try {
      registerMutation({
        variables,
      })
        .then( async (val) => {
          const responseData = val.data?.register?.data;
          if (responseData.access_token) {
            await setToken(responseData.access_token, responseData.user?.id);
            onSuccess();
            handleRedirection(responseData.user?.profile?.onboard_status);
          } else {
            if (val.data?.register?.http_code === accountNotExistErrorCode) {
              setAlreadyExists(true);
            }
            let message = 'Some error occurred!';
            if (val.data?.register?.messages?.error?.length > 0) {
              message = val.data.register.messages.error[0];
            }
            throw new Error(message);
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } catch (err) {
      setIsProcessing(false);
      handleError(err);
    }
  };

  // Handle Login
  const handleLogin = async (variables: LoginVariables) => {
    try {
      setIsProcessing(true);
      loginMutation({
        variables,
      }).then(async (val) => {
        var response = val.data.login;
        if (response.data.access_token) {
          await setToken(response.data.access_token, response.data.user?.id);
          onSuccess();
          handleRedirection(response.data.user?.profile?.onboard_status);
        } else if (response.data?.new_user) {
          if (confirmRegister) {
            setRegisterData(variables);
            setShowConfirmation(true);
            setIsProcessing(false);
          } else {
            handleRegister(variables);
          }
        } else {
          let errorMessage = 'Invalid OTP Entered!';
          if (response?.messages?.error?.length > 0)
            errorMessage = response.messages.error[0];
          setError(errorMessage);
          setIsProcessing(false);
        }
      });
    } catch (err) {
      setIsProcessing(false);
      handleError(err);
    }
  };

  // Handle OTP Submission
  const onSubmit = () => {
    console.log('on submit')
    setError('');
    if (isOTPDisabled() || isProcesssing) {
      return;
    }
    try {
      setIsProcessing(true);
      if (overrideSubmit) {
        var data: ProfileType = {
          otp: otp,
          login_via: !isMobileNumber ? LOGIN_VIA.email : LOGIN_VIA.phone_number,
        };
        if (isMobileNumber) {
          data.phone = returnPhoneNumber();
          data.country_code = `+${countryCode}`;
        } else {
          data.email = sentTo;
        }
        onSuccess(data);
      } else {
        let variables: LoginVariables = {
          otp,
          login_via: LOGIN_VIA.email,
        };
        if (isMobileNumber) {
          variables = {
            ...variables,
            country_code: `+${countryCode}`,
            country_iso: `${countryISO}`,
            phone_number: returnPhoneNumber(),
            login_via: LOGIN_VIA.phone_number,
          };
        } else {
          variables = {
            ...variables,
            email: sentTo,
          };
        }
        handleLogin(variables);
      }
    } catch (err) {
      setIsProcessing(false);
      setError(parseTryCatchError(err));
    }
  };

  if (showConfirmation)
    return (
      <ConfirmationScreen
        onConfirmClick={() => {
          try {
            setShowConfirmation(false);
            handleRegister(registerData);
          } catch (e) {
            handleError(e);
          }
        }}
        errorMessage={Account_Messages.accountNotFound}
        errorDescription={Account_Messages.createAccountDescription}
        backButtonLabel={Account_Messages.backToLogin}
        confirmButtonLabel={Account_Messages.createAccount}
      />
    );

  if (error && alreadyExists) {
    return (
      <div className="otp-action__wrapper otp-action__wrapper-initial">
        <div className="sl-auth__error otp-error">
          <span className="otp-error__icon">
            <ExclamationSquare />
          </span>
          <span>{error}</span>
        </div>
        <p className="otp-error__description">
          {Account_Messages.createAccountError}
        </p>
        <button
          type="button"
          className={`button button__primary sl-auth__register-button button-otp`}
          onClick={() => window.location.reload()}
        >
          Back to {verifyFor === 'login' ? 'Log In' : 'Register'}
        </button>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="otp-message__wrapper">
        <p className="auth__message-otp auth__message-otp__message">
          <strong>Please enter the code we sent to:</strong>
        </p>
        <p className="auth__message-otp auth__message-otp__detail">{sentTo}</p>
      </div>
      {onClose && (
        <button className="login-popup__container__close-icon">
          <Close
            onClick={() => {
              onClose();
            }}
          />
        </button>
      )}
      <OtpInput
        shouldAutoFocus
        value={otp}
        onChange={setOtp}
        numInputs={OTP_LENGTH}
        renderInput={(props) => <input {...props} type="number" />}
        containerStyle="otp-container"
        inputStyle="otp-input"
        inputType={'number'}
      />
      <div className="otp-action__wrapper">
        {error && (
          <div className="sl-auth__error otp-error">
            <span className="otp-error__icon">
              <ExclamationSquare />
            </span>
            <span>{error}</span>
          </div>
        )}
        <button
          type="submit"
          className={`button button__primary sl-auth__register-button${
            isOTPDisabled() ? ' otp-button--disabled' : ''
          } button-otp`}
          disabled={isOTPDisabled()}
          onClick={onSubmit}
        >
          Continue
        </button>
        {!isGenerating && (
          <CountdownTimer durationInSeconds={30}>
            {(timeLeft) => {
              const isDisabled = timeLeft > 0
              return (
                <button
                  type="button"
                  className={`otp-button${
                    isDisabled ? ' otp-button--disabled' : ''
                  } button-otp`}
                  disabled={isDisabled}
                  onClick={sendOTP}
                >
                  Resend {timeLeft > 0 ? `in ${timeLeft}s` : ''}
                </button>
              );
            }}
          </CountdownTimer>
        )}
      </div>
    </form>
  );
};

VerifyOTP.defaultProps = {
  isMobileNumber: false,
  isProcesssing: false,
};

export default VerifyOTP;
