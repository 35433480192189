import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DELETE_ACCOUNT } from '../../../containers/data/deleteAccount';
import { GENERATEVERIFICATIONEYUSER } from '../../../containers/data/generateVerificationKeyUser';
import { socialLogout } from '../../../helpers';
import { setWarningPopup } from '../../../redux/action/utils';
import Tick from '../../Global/icons/Tick';
import LabelTextInput from '../../Global/LabelTextInput';

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState('');
  const [otp, setotp] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [generateKey] = useMutation(GENERATEVERIFICATIONEYUSER);
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  const [warningMessage, setWraningMessage] = useState('');
  const handleLogout = () => {
    socialLogout();
    window.location.href = '/logout';
  };
  const deleteUser = async () => {
    const response = await deleteAccount({
      variables: {
        verification_key: otp,
      },
    });

    response.data?.deleteUser?.http_code === 200
      ? handleLogout()
      : alert(response.data?.deleteUser?.messages?.error);
  };
  const submitChange = async () => {
    setConfirmDelete(true);
    setInputText('');
    if (!confirmDelete) {
      const response = await generateKey({
        variables: {
          type: 1,
        },
      });
      response.data?.generateVerificationKeyUser?.messages?.success[0] &&
        setWraningMessage(
          response.data?.generateVerificationKeyUser?.messages?.success[0]
        );
      response.data?.generateVerificationKeyUser?.http_code !== 200 &&
        alert(response.data?.generateVerificationKeyUser?.messages?.error);
    } else {
      dispatch(
        setWarningPopup({
          warningMessage:
            'Are you sure? Click OK to delete your account. This cannot be reversed. All content will be deleted.',
          header: 'Warning',
          leftLoader:true,
          cancel: {
            label: 'Cancel',
          },
          submit: {
            label: 'OK',
            cb: () => {
              deleteUser();
            },
          },
        })
      );
    }
  };
  return (
    <div className="delete-account">
      <div className="delete-account__text">
        <span>
          {confirmDelete ? (
            warningMessage ? (
              warningMessage
            ) : (
              <span className="delete-account__text-dot">Processing...</span>
            )
          ) : (
            `Please confirm you want to delete the account by typing "delete" below.`
          )}
        </span>
      </div>
      <LabelTextInput
        placeholder={
          confirmDelete ? 'Type your verification key here' : 'Type here'
        }
        onChange={(newName) => {
          confirmDelete ? setotp(newName) : setInputText(newName);
        }}
        value={confirmDelete ? otp : inputText}
        required
      />
      <div className="box-footer">
        <button
          disabled={inputText !== 'delete' && otp === ''}
          type="submit"
          className="button button__primary"
          onClick={() => {
            submitChange();
          }}
        >
          <Tick />
          Confirm
        </button>
      </div>
    </div>
  );
};

export default DeleteAccount;
