import React from 'react';

import { GlobalSearchUserType } from '../../../../../../types/globalSearchDataType';
import { returnImageURL } from '../../../../../Gallery/helpers/returnImageURL';
import { useHistory } from 'react-router-dom';
import './styles.css';
import '../../../../../Cards/Cards.css';
import userInitials from '../../../../../../helpers/userInitial';

interface Proptypes {
  scrapper: GlobalSearchUserType;
  showBio?: boolean;
  hideButton?: boolean;
  shareLink?: string;
}
const GlobalScrapperItem = (props: Proptypes) => {
  const {
    scrapper: {
      avatar,
      user_name,
      display_name,
      bio,
      collection_count
    },
    hideButton,
    shareLink,
  } = props;
  const { push } = useHistory();
  return (
    <div className={`global-scrappers__main-card ${collection_count ? '' : 'global-scrappers__main-card--no-collection'}`}>
      <div
        className={`global-scrappers__card ${
          hideButton ? 'global-scrappers__card-clickable' : ''
        }`}
        key={user_name}
        onClick={() => {
          push(shareLink);
        }}
      >
        {/* THIIS SPAN IS USED TO FIX THE BUMP BORDER BACKGROUND ISSUE */}
        {/* <span className="global-scrappers__card-image__background"></span>
      <img
        src={
          avatar && avatar.trim() !== ''
            ? `${returnImageURL(avatar)}`
            : '/dashboard/img/default-avatar.jpg'
        }
        className="global-scrappers__card-image"
        alt="scrapper-name"
        onError={(e) => {
          (e.target as HTMLImageElement).src =
            '/dashboard/img/default-avatar.jpg';
        }}
      /> */}

        {/* <div className="global-scrappers__card-user">
          <span className="global-scrappers__card-name">
            {scrapperName !== '' ? scrapperName : ' '}
          </span>
          <span className="global-scrappers__card-user-name">@{userName}</span>
          {showBio && <div className="global-scrappers__card-bio">{bio}</div>}
        </div>
        {!hideButton && (
          <button
            className="global-scrappers__card-button button"
            onClick={(e) => {
              e.stopPropagation();
              push(shareLink);
            }}
          >
            View Profile
          </button>
        )} */}
        <div
          className="card__user"
        >
          {avatar ? (
            <img
              src={
                avatar && avatar.trim() !== ''
                  ? `${returnImageURL(avatar)}`
                  : '/dashboard/img/default-avatar.jpg'
              }
              className="global-scrappers__card-image"
              alt="scrapper-name"
              onError={(e) => {
                (e.target as HTMLImageElement).src =
                  '/dashboard/img/default-avatar.jpg';
              }}
            />
          ) : (
            <span
              data-initials={userInitials(display_name)}
              className="card__user-initials"
            ></span>
          )}
          <div className="card__user-meta">
            <div className="card__user-names-wrapper">
              {display_name && (
                <span className="card__user-full-name">{display_name}</span>
              )}
              <span className="card__user-name">@{user_name}</span>
             
            </div>
            {collection_count > 0 &&  <span className='global-scrappers__collection-count'>
              {collection_count}{" "}  
              <span className='global-scrappers__collection-label'>{collection_count ===1 ? "Collection" :"Collections" }</span>
            </span>}
           
            {/* <button className="global-scrappers__card--invite">
              {width > 767 ? "Invite to my Scurry" : "+ My Scurry"}
            </button> */}
          </div>
        </div>
        
        <div className="global-scrappers__card-bio">
          {bio}
        </div>
      
      </div>
    </div>
  );
};

export default GlobalScrapperItem;
