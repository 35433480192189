import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import CollectionCollaboration from '../components/CollectionCollaboration';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import {
  FETCH_ROLES,
  FETCH_COLLECTION_COLLABORATION_MEMBERS,
  UPSERT_COLLECTION_MEMBER,
  REMOVE_COLLECTION_MEMBER,
} from './data/collaboration';

import {
  CollabMemberActionType,
  CollabRoleType,
  CollectionCollaborationFetchedType,
  CollectionCollaboratorOwnerType,
  CollectionCollaboratorType,
} from '../types/collaboration';
import { RequestStatusType } from '../types/requestStatusType';
import { waitBeforeExecute } from '../helpers';
import NewPopup from '../components/Global/NewPopup';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../components/Global/InvisibleRecaptcha';
import ValidateHuman from '../helpers/validators/isHuman';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../redux/store';
import { setCollaborationPopup } from '../redux/action/utils';


const CollectionCollaborationWrapper = () => {
  const dispatch = useDispatch();
  const collaborationPopup = useSelector((state: ReduxStateType) => state.utils.collaborationPopup)
  const { onSaved, collectionId } = collaborationPopup || {};
  const [show, closeHandler] = [!!collaborationPopup, () => dispatch(setCollaborationPopup(null))]
  const { replace, push } = useHistory();
  const [requestStatus, setRequestStatus] = useState<{
    type: RequestStatusType;
    message: string;
    user_email?: string;
  }>(null);
  const [collabData, setCollabData] = useState<
    CollectionCollaborationFetchedType
  >(null);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [firstTimeInvitation, setfirstTimeInvitation] = useState(false);
  const [removingMyself, setRemovingMyself] = useState(false);
  const [removingMember, setRemovingMember] = useState<
    Partial<CollectionCollaboratorType>
  >();

  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  const [showMessageBody, setMessageBody] = useState(false);
  const { loading: loadingRoles, data, refetch: refetchRoles } = useQuery(
    FETCH_ROLES,
    {
      skip:
        !show ||
        !collabData ||
        (+collabData?.active_user?.role?.role_id || 0) > 0,
    }
  );
  const[load,setLoad]=useState(false)
  const [sendInvite] = useMutation(UPSERT_COLLECTION_MEMBER());
  const [removeCollaborator] = useMutation(REMOVE_COLLECTION_MEMBER());
  const {
    data: fetchedMembers,
    error,
    refetch: refetchCollaborators,
    loading: loader,
  } = useQuery(FETCH_COLLECTION_COLLABORATION_MEMBERS, {
    variables: { collectionId },
    skip: !show,
    // fetchPolicy: "no-cache"
  });

  const roles: CollabRoleType[] = data?.collaboratorRoles?.data || [];


  useEffect(() => {

    setRequestStatus(undefined);
    
  }, [show]);

  

 
  async function sendInviteHandler(
    user_email: string,
    role_id: number,
    type?: string,
    dontAppend?: boolean
  ) {
    const isHuman= await ValidateHuman(reCaptchaRef.current);
    if(!isHuman)
      return;
    try {
      if (!user_email || !user_email.includes('@')) {
        throw new Error('Please enter a valid email or username!');
      }
      if (!role_id) {
        throw new Error('Please select role');
      }
      setRequestStatus({ type: 'processing', message: 'Sending Invite' });
      const response = await sendInvite({
        variables: {
          collection_id: collectionId,
          user_email,
          role_id,
        },
      });
      const {
        data,
        messages: { error, success },
      } = response?.data?.upsertCollectionCollaborator || {
        messages: { error: [], success: [] },
      };

      if (success.length > 0 && data) {
        refetchCollaborators();
        if (!dontAppend) {
          const alreadyInvited =
            collabData.collaborators.findIndex(
              (item) =>
                item.email.trim().toLowerCase() ===
                user_email.trim().toLowerCase()
            ) >= 0;
          !alreadyInvited &&
            setCollabData((old) => ({
              ...old,
              collaborators: [
                ...old.collaborators,
                {
                  cc_id: data.cc_id,
                  email: user_email,
                  role: roles.find((role) => +role.role_id === role_id),
                },
              ],
            }));
        }

        if (type == 'resend') {
          setfirstTimeInvitation(false);
        } else {
          setfirstTimeInvitation(true);
        }
        return setRequestStatus({
          type: 'success',
          message: success[0] as string,
          user_email,
        });
      }

      throw new Error(error[0] || 'Something went wrong');
    } catch (error) {
      setRequestStatus({
        type: 'error',
        message: parseTryCatchError(error),
      });
      throw new Error(parseTryCatchError(error));
    }
  }
  async function removeCollaboratorHandler(
    cc_id: string,
    isRemoveMyself?: boolean
  ) {
    const isHuman= await ValidateHuman(reCaptchaRef.current);
    if(!isHuman)
      return;

    try {
      if (!cc_id) throw new Error('cc_id is undefined');
      setRequestStatus({ type: 'processing', message: 'Removing' });
      setRemoveLoader(true);
      const response = await removeCollaborator({ variables: { cc_id } });

      await waitBeforeExecute(() => {}, 2000);

      if (isRemoveMyself) replace('/');
      const {
        messages: { error, success },
      } = response?.data?.removeCollectionCollaborator || {
        messages: { error: [], success: [] },
      };
      if (Array.isArray(success) && success.length > 0) {
        let filteredCollaborators = collabData.collaborators.filter(
          (collaborator) => collaborator.cc_id !== cc_id
        );
        setCollabData((old) => ({
          ...old,
          collaborators: filteredCollaborators,
        }));
        
        // dispatch(
        //   setCollectionAction({
        //     collection: {
        //       ...collection,
        //       id:collectionId,
        //       collaborators: filteredCollaborators.filter((i)=>i.accepted_at ), 
        //     },
        //     type: 'edit',
        //   })
        // );
        setRequestStatus({
          type: 'success',
          message: success[0],
        });
      } else {
        const errorMessage =
          Array.isArray(error) && error.length > 0
            ? error[0]
            : 'Something went wrong';
        throw new Error(errorMessage);
      }
    } catch (error) {
      setRequestStatus({
        type: 'error',
        message: parseTryCatchError(error),
      });
    }
    setRemoveLoader(false);
    setMessageBody(false);
  }
  async function onMemberDropDownClick(
    member: Partial<CollectionCollaboratorType>,
    action: CollabMemberActionType
  ) {
    switch (action) {
      case 'resend-invitation':
        sendInviteHandler(member.email, +member.role.role_id, 'resend', true);
        break;
      case 'remove-member':
      case 'remove-invitation':
        setRemovingMyself(false);
        setMessageBody(!showMessageBody);
        setRemovingMember({
          cc_id: member.cc_id,
          first_name: member.first_name,
          last_name: member.last_name,
        });
        // removeCollaboratorHandler(member.cc_id);
        break;
      case 'remove-myself':
        setMessageBody(!showMessageBody);
        setRemovingMyself(true);
        setRemovingMember({
          cc_id: member.cc_id,
          first_name: member.first_name,
          last_name: member.last_name,
        });
        // removeCollaboratorHandler(member.cc_id, true);
        break;
      default:
        break;
    }
  }

  function memberClickHandler({username}: CollectionCollaboratorType | CollectionCollaboratorOwnerType) {
    closeHandler();
    push(`/${username}`);    
  }
  useEffect(() => {
    const fetchedData = fetchedMembers?.collectionCollaborator?.data;

    if (fetchedData && fetchedData.owner) {
      setCollabData(fetchedData);
      // setLoading(false);
    }
  }, [fetchedMembers]);
  
  return (
    <NewPopup
      className="collection-collaboration-popup"
      controlled={{
        show,
        setShow: closeHandler,
      }}
      header={{
        heading: 'Collaborators',
      }}
      body={{
        className: 'collection-collaboration__popup-body',
      }}
    >
     
      {(close) => (
        <>
          <InvisibleRecaptcha inputRef={reCaptchaRef}/>
          <CollectionCollaboration
            collectionTitle={fetchedMembers?.collectionCollaborator?.data?.title}
            handleClose={closeHandler}
            collabData={collabData}
            setMessageBody={setMessageBody}
            messageBody={showMessageBody}
            removeLoader={removeLoader}
            firstTimeInvitation={firstTimeInvitation}
            loading={loader}
            removingMemberDetail={removingMember}
            onRemoveProceed={removeCollaboratorHandler.bind(
              null,
              removingMember?.cc_id,
              removingMyself
            )}
            roles={roles}
            onInvite={sendInviteHandler}
            requestStatus={requestStatus}
            onMemberDropDownClick={onMemberDropDownClick}
            onMemberClick={memberClickHandler}
          />
        </>

      )}

    </NewPopup>
  );
};

export default CollectionCollaborationWrapper;