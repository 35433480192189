import { collectionType } from '../../types/collections';
import { moveDataToIndex } from '../../helpers/arrayHelpers';

const DEFAULT = [];

const collections = (state: collectionType[] = DEFAULT, action) => {
  let itemArr = [...state];
  switch (action.type) {
    case 'ADD_COLLECTION':
      return [action.collection, ...state];
    case 'REMOVE_COLLECTION':
      itemArr.splice(
        itemArr.findIndex((i) => i.id === action.collectionID),
        1
      );
      return [...itemArr];
    case 'LOAD_APPLIED_COLLECTIONS':
      return [...action.appliedCollectionData];
    case 'LOAD_COLLECTIONS':
      return action?.collections || [];
    case 'RESET_COLLECTIONS':
      return DEFAULT;
    case 'MOVE_COLLECTIONS':
      return moveDataToIndex(
        0,
        state,
        action.payload,
        (d1, d2) => +d1.id === +d2
      );

    default:
      return state;
  }
};

export default collections;
