export const scrapTypeUniqueID = {
  'annotation': {
    'key': 1,
    'name': 'annotations',
  },
  'video': {
    'key': 2,
    'name': 'videos',
  },
  'gallery': {
    'key': 3,
    'name': 'galleries',
  },
  'note': {
    'key': 4,
    'name': 'notes',
  },
  'page': {
    'key': 5,
    'name': 'pages',
  },
  'product': {
    'key': 6,
    'name': 'products',
  },
  'screenshot': {
    'key': 7,
    'name': 'screenshots',
  },
  'social': {
    'key': 8,
    'name': 'socials',
  },
  'url': {
    'key': 9,
    'name': 'urls',
  },
  'audio': {
    'key': 10,
    'name': 'audios',
  },
  'document': {
    'key': 11,
    'name': 'documents',
  },
  /* 
    * SECTIONS TYPE ADDED BUT NO LONGER GETTING USED
    * ADDED FOR HANDLING OLDER TYPE SECTIONS' SCRAPS
    * START
  */
  'section': {
    'key': 12,
    'name': 'sections',
  },
  'sections': {
    'key': 12,
    'name': 'sections',
  },
  /* 
    * END
  */
};

export const returnScrapTypeName = (key: number) => {
  try {
    const scrapType = Object.keys(scrapTypeUniqueID).find(keyName => scrapTypeUniqueID[keyName].key === key);
    return scrapTypeUniqueID[scrapType]?.name;
  } catch (error) {
    return null;
  }
}