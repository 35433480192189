import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['first', 'Int = 500'],
  ['search_after', 'String'],
  ['tie_breaker_id', 'String'],
  ['tie_breaker_param', 'String'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['text', 'String'],
  ['userIds', '[InputMemberId]'],
  ['isUsersForInvitation', 'Boolean = false']
]);

export default function GLOBAL_SEARCH_USERS(responseString = '') {
  return gql`
  query GlobalSearchUsers(${parametersTypeString}){
    globalSearchUsers(${parametersAssignString}){
      ${defaultResponse}
      paginatorInfo {
        total
      }
      data {
        knot {
            id
            user_name
            creator_initials
            avatar
            bio
            email
            collection_count
            cover_image
            social_media_accounts
            url
            location
            display_name
            updated_at
        }
      }
    }
  } 
  `;
}
