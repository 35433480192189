// @ts-nocheck

import React, { useState, useEffect, useContext } from 'react';
import ReloadContext from '../helpers/contexts/reloadContext';
import { connect } from 'react-redux';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import FilterGroup from '../components/Filters/components/FilterGroup';
import LoadingFilter from '../components/Loaders/LoadingFilter';
import { collectionFilterParamTypes } from '../components/CollectionCreationWrapper/types';
import { IsExistingViewModeType } from '../components/Gallery/types/isExistingViewModeTypes';
import { updateFilter } from '../redux/action/filterParamsAction';
import { filterParamTypes } from '../types/filter';
import FilterGroupItem from '../components/Filters/components/FilterGroupItem';
import SideNavDropdown from '../components/SideNav/SideNavDropdown';
import SideNavDropdownListItem from '../components/SideNav/SideNavDropdown/SideNavDropdownlistItem';
import { filterItem } from '../types/filter';
import CollectionFiltersContext from '../helpers/contexts/collectionFiltersContext';
import filterItemType from '../types/filterItem';
import { useDispatch } from 'react-redux';
import { parseTagFromSlug } from '../helpers';

const GET_FILTER_DATA = gql`
  query ImagesFilterOptions($text: String, $collection_id: ID) {
    imagesFilterOptions(text: $text, collection_id: $collection_id) {
      messages {
        error
        success
      }
      status
      http_code
      data {
        type
        label
        values {
          doc_count
          key
        }
      }
    }
  }
`;
const parsepFilters = (pFilters: collectionFilterParamTypes) => {
  const obj = {};
  for (const property in pFilters) {
    if (pFilters[property].length > 0)
      obj[property] =
        pFilters[property].length === 1
          ? pFilters[property][0]
          : pFilters[property];
  }
  return obj;
};

const parseUrlParams = (search, collectionValue, pFilters) => {
  if (search || collectionValue) {
    const tempParams = collectionValue
      ? parsepFilters(pFilters)
      : qs.parse(search.substring(1));
    for (const property in tempParams) {
      if (Array.isArray(tempParams[property])) {
        (tempParams[property] as string) = (tempParams[
          property
        ] as string[]).join(',');
      }
    }
    let typeVal = collectionValue?.value;
    if (Array.isArray(collectionValue?.value)) {
      typeVal = collectionValue.value.join(',');
    }
    return collectionValue
      ? { ...tempParams, [collectionValue.type]: typeVal }
      : tempParams;
  } else {
    return collectionValue
      ? { [collectionValue.type]: collectionValue.value }
      : '';
  }
};

interface queryParamsType {
  types?: string;
  tags?: string;
  collections?: string;
}
interface Proptypes {
  hideSelectedImages?: boolean;
  filters: object;
  collectionValue?: string;
  pFilters?: collectionFilterParamTypes;
  setPFilters?: (data: collectionFilterParamTypes) => void;
  setReloadFilters?: (val: boolean) => void;
  reloadFilters?: boolean;
  isAddExistingImages?: IsExistingViewModeType;
  selectedImages?: number;
  filterParams: filterParamTypes;
  noFilterCount?: boolean;
  showTags?: boolean;
  collectionId?: string;
  showFilteredCollection?: string;
}
interface locationState {
  dontReload?: boolean;
}
const areParamsEqual = (params1, params2) => {
  if (Object.keys(params1).length !== Object.keys(params2).length) return false;
  let areEqual = true;
  for (const prop in params1) {
    if (params1[prop] !== params2[prop]) {
      areEqual = false;
      break;
    }
  }
  return areEqual;
};
const FilterWrapper = ({
  hideSelectedImages,
  filters,
  collectionValue,
  pFilters,
  setPFilters,
  reloadFilters,
  setReloadFilters,
  isAddExistingImages,
  selectedImages,
  filterParams,
  noFilterCount,
  showTags,
  collectionId,
  showFilteredCollection
}: Proptypes) => {
  const { action } = useHistory();
  const { reload, setReload } = useContext(ReloadContext);
  const { search, state } = useLocation<locationState>();
  let temp = {};
  if (collectionValue) temp = { type: 'collections', value: collectionValue };
  else if (isAddExistingImages)
    temp = {
      type: 'types',
      value: ['screenshots', 'products', 'pages', 'socials'],
    };
  const [queryParams, setQueryParams] = useState(
    parseUrlParams(search, temp['type'] ? temp : null, pFilters)
  );
  if (queryParams['untagged']) {
    queryParams['untagged'] = queryParams['untagged'] === 'true' ? true : false;
  }
  const { push, replace } = useHistory();
  const { data, loading, refetch } = useQuery(GET_FILTER_DATA, {
    variables: {
      collection_id: showFilteredCollection!=='All Collections'?collectionId:null,
      for_page: collectionValue ? 'collection' : null,
      for_collection_image: true,
    },
  });

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const placeholderMappers = {
    Collections: 'Search Collections',
    Tags: 'Search Tags',
    'Scrap Types': 'Scrap Type',
  };
  const mappers = {
    Collections: 'collections',
    Tags: 'tags',
    'Scrap Types': 'types',
  };

  function isFilterActive(
    search: string,
    value: string,
    key: string,
    itemLabel?: string
  ) {
    let isActive = false;
    var tempParams = pFilters.tags.filter(
      (item) => item === value || item.value === value
    );
    if (tempParams.length > 0) {
      isActive = true;
    }

    return isActive;
  }

  function filterItemClickHandler(
    label: filterItemType['label'], // Label value of filter category tab
    value: filterItem // Value of item which is clicked by the user
  ) {
    const existingFilterParam = pFilters ? pFilters : filterParams;
    const newFilterParam = pFilters ? pFilters : existingFilterParam;
    if ( value.value) {
      if (
        (!pFilters.tags.includes(
          value.id
        ))) {
        if (value.id==="null") {

          setPFilters({
            ...newFilterParam,
            tags: [...pFilters.tags,value.id],
            untag: true || newFilterParam.untag,
          });
        } else {
          setPFilters({
            ...newFilterParam,
            tags: [...pFilters.tags, value.id],
            untag: !value.id || newFilterParam.untag,
          });
        }
      } else {
        var tempData = pFilters.tags.filter((item) => item !== value.id);
        setPFilters({
          ...newFilterParam,
          tags: tempData,
          untag:
            pFilters.untag && value.value === 'true' ? false : pFilters.untag,
        });
      }
      // add the updated value to the global store
    } else {
      setPFilters({ ...newFilterParam, tags: [] });
    }
  }
  const { filters: collectionContextFilters } = useContext(
    CollectionFiltersContext
  );

  const handleClearAll = () => {
    const newFilterValue = pFilters || filterParams;

    if (!collectionValue) newFilterValue['collections'] = [];
    if (!isAddExistingImages) newFilterValue['types'] = [];
    newFilterValue['story'] = [];
    newFilterValue['tags'] = [];
    setPFilters && setPFilters(newFilterValue);
  };
  const handleDelete = (type, value) => {
    const newFilterValue = pFilters || filterParams;
    newFilterValue[`${type}`] = newFilterValue[`${type}`].filter(
      (item) => item !== value
    );
    setPFilters && setPFilters(newFilterValue);
  };
  useEffect(() => {
    if (reload) {
      refetch();
      setReload(false);
    }
  }, [reload]);

  const renderFilterGroupItem = (filters) => {
    return filters.map((filter, i) => {
      if (collectionValue && filter.type === 'collections') return null;
      if (isAddExistingImages && filter.type === 'types') return null;
      return (
        <div key={filter.type}>
          <div className="topic-navigation__header">
            <h4 className="topic-navigation__heading">{filter.label}</h4>
          </div>
          <FilterGroupItem
            type={filter.type}
            items={filter.values}
            existingFilterParam={pFilters ? pFilters : filterParams}
            setPFilters={setPFilters}
            pFilters={pFilters}
            noFilterCount={noFilterCount}
          />
        </div>
      );
    });
  };
  useEffect(() => {
    if (!state || !state.dontReload) {
      const parsedParams = parseUrlParams(
        search,
        temp['type'] ? temp : null,
        pFilters
      );
      if (areParamsEqual(parsedParams, queryParams)) {
        refetch();
      } else {
        if (!isAddExistingImages)
          setQueryParams(
            parseUrlParams(search, temp['type'] ? temp : null, pFilters)
          );
      }
    }
    if (reloadFilters) {
      refetch();
      setReloadFilters(false);
    }
  }, [
    search,
    pFilters,
    action,
    state,
    collectionValue,
    reloadFilters,
    showTags,
  ]);

  if (loading || !data) {
    return <LoadingFilter />;
  }
  const listToShow = data.imagesFilterOptions.data[0].values.length > 0
    ? data?.imagesFilterOptions.data[0].values
    : []
  return (
    <div>
      {/* <FilterGroup
        hideSelectedImages={hideSelectedImages}
        filters={data.filterType}
        collectionValue={collectionValue}
        setPFilters={setPFilters}
        pFilters={pFilters}
        isAddExistingImages={isAddExistingImages}
        selectedImages={selectedImages || 0}
        filterParams={pFilters ? pFilters : filterParams}
        renderFilterGroupItem={renderFilterGroupItem}
        handleClearAll={handleClearAll}
        handleDelete={handleDelete}
      /> */}

      <SideNavDropdown<filterItem>
        list={listToShow.map((data) =>
          data.key ? parseTagFromSlug(data.key, data.doc_count) : data
        )}
        placeholder="Search tags"
        labelField="label"
        valueField="value"
        // notSearchable={label === 'Filter by Type'}
      >
        {(item) => {
          let active = false;
          const mappersLabel =
            item.label.toLowerCase() ===
              'untagged' &&
            item.value.toLowerCase() ===
              'true'
              ? 'untagged'
              : mappers[data.imagesFilterOptions.data[0].type];

          if (
            pathname.includes('/c/') &&
            collectionContextFilters &&
            Array.isArray(collectionContextFilters[mappersLabel])
          ) {
            active = collectionContextFilters[mappersLabel].includes(
              item.value
            );
          } else {
            active = isFilterActive(
              search,
              item.id,
              mappers[data.imagesFilterOptions.data[0].type],
              item.label
            );
          }
          // let renderConfig;
          // renderConfig = {
          //   type: 'text',
          //   data: item.count,
          // };

          return (
            <SideNavDropdownListItem
              key={item.value}
              label={item.label}
              onClick={filterItemClickHandler.bind(
                null,
                data.imagesFilterOptions.data[0].type,
                item
              )}
              isMultiselect
              active={active}
              untag={pFilters.untag}
              id={item.id}

              // renderConfig={renderConfig}
            />
          );
        }}
      </SideNavDropdown>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    filters: state.filters,
    filterParams: state.filterParams,
  };
};



export default connect(mapStatetoProps)(FilterWrapper);
