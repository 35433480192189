import React, { useRef, useState, useEffect, useContext } from 'react';
import AddExistingImagesWrapper from '../../../containers/AddExistingImagesWrapper';
import getBase64FromUrl from '../../../helpers/getBase64FromUrl';
import { getImageDimensions } from '../../../helpers/getImageDimensions';
import { isFileBig } from '../../../helpers/validators';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import './styles.css';
import Skeleton from 'react-loading-skeleton';
import {
  CloseIcon,
  FullScreenIcon,
  LeftArrowIcon,
  Replace,
  TrashOutlineIcon,
} from '../icons';
import NewPopup from '../NewPopup';
import GlobalImageStatus from '../../GlobalSearch/components/GlobalImageStatus';
import { convertHEICImage, pendingImage } from '../../../helpers';
import EntryActionContext from '../../../helpers/contexts/entryActionContext';
import { useApolloClient } from '@apollo/client';
import useInterval from '../../../helpers/customHooks/useInterval';
import { entryTypes } from '../../../types/feed';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import Dropzone, {
  DropzoneInputProps,
  DropzoneRootProps,
} from 'react-dropzone';
import UndoBox from '../UndoBox';
import { userTypes } from '../../../types/user';
import { imageSize } from '../../../helpers/imageSize';
import config from '../../../helpers/config';
const { supported_images_accept_list } = config;
interface Proptypes {
  hideCoverImageLabel?: boolean;
  image?: string;
  showUseExisting?: boolean;
  collectionSlug?: string;
  placeholder?: string; // For setting custom title to the component
  convertCdnToBase64?: boolean;
  onChange?: (
    image: File | string,
    type: 'file' | 'cdn-url' | 'b64Url' | 'remove',
    mimeType?: string
  ) => void;
  imageSizeRequirements?: {
    minWidth: number;
    minHeight: number;
  };
  unEditable?: boolean;
  config?: string;
  tempImage?: string;
  removeButtonLabel?: string;
  defaultAll?: boolean;
  setEditImage?: (val: boolean) => void;
  scrapType?: string;
  uid?: string;
  thumbnail?: string;
  setScrap?: (val: entryTypes) => void;
  scrap?: entryTypes;
  clickedLock?: boolean;
  showUploader?: boolean;
  setShowUploader?: (val: boolean) => void;
  undoImageHandler?:()=>void;
  collectionId?:string;
}

/** 
 AddImage component which will be used globally for:
 * giving user the ability to upload file
 * giving user the ability to get the image from the image url he pastes
 * giving user the ability to select image from existing images
**/

const AddImage = ({
  hideCoverImageLabel,
  collectionSlug,
  showUseExisting,
  image,
  convertCdnToBase64,
  placeholder,
  onChange,
  imageSizeRequirements,
  unEditable,
  config,
  removeButtonLabel,
  defaultAll,
  setEditImage,
  scrapType,
  uid,
  thumbnail,
  setScrap,
  scrap,
  clickedLock,
  setShowUploader,
  undoImageHandler,
  collectionId
}: Proptypes) => {
  const { setEntryAction } = useContext(EntryActionContext);
  const { minHeight, minWidth } = imageSizeRequirements || {};
  const inputRef = useRef<HTMLInputElement>();
  const urlInputRef = useRef<HTMLInputElement>();
  const [viewImageUrl, setViewImageUrl] = useState(image);
  const [mode, setMode] = useState<'url' | 'use-existing'>(null);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [toggleLightBox, setToggleLightBox] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);
  const { backUrl, scrollToBottom, scrapPopup } = useSelector(
    (state: ReduxStateType) => state.utils
  );
  const client = useApolloClient();
  const userData = useSelector(
    (state: { user: userTypes }) => state.user as userTypes
  );
  useEffect(() => {
    let elem = document.getElementsByClassName(
      'scrap-share__actions-toggle-description'
    )[0];
    (scrollToBottom || scrapPopup?.scrollToBottom || clickedLock) &&
      elem?.scrollIntoView({ behavior: 'smooth' });
  }, [showImage]);
  var parseConfig = (config) => {
    if (config && typeof config !== 'object') {
      let parseValue = JSON.parse(config);

      if (typeof parseValue === 'object') {
        return parseValue.img_status;
      } else {
        parseConfig(parseValue);
      }
    } else {
      return config.img_status;
    }
  };

  const parsedConfig = config ? parseConfig(JSON.parse(config)) : 3;

  let cancel = !['page', 'screenshot', 'social', 'product'].includes(scrapType);

  if (!cancel && (parsedConfig === 2 || image || thumbnail)) {
    cancel = true;
  }
  const fetch = () =>
    pendingImage({
      parsedConfig: parsedConfig,
      uid: uid,
      image: image,
      thumbnail: thumbnail,
      client: client,
      setEntryAction: setEntryAction,
      setScrap: setScrap,
      scrap: scrap,
    });

  useInterval(fetch, 30000, cancel);

  const [selectedType, setSelectedType] = useState<{
    title: string;
    id: number;
  }>({ title: 'This Collection', id: 1 });
  const checkImageSize = async (image: File | string) => {
    const { width, height } = await getImageDimensions(image);
    if (minHeight > height || minWidth > width)
      return `Image must be atleast ${minWidth}px wide and ${minHeight}px high!`;
  };
  const [showMenu, setShowMenu] = useState(false);
  const [show, setShow] = useState(false);
  let dropdownList = [
    { id: '1', label: 'This Collection' },
    { id: '2', label: 'All Collection' },
  ];
  useEffect(() => {
    if (mode === 'use-existing') {
      setShow(true);
    }
  }, [mode]);

  const onFileChange = async (e) => {
    setProcessing(true);
    let allFiles = (e.target as HTMLInputElement).files;
    if (allFiles.length) {
      let file = allFiles[0];
      const isBigFile = isFileBig('image', file);
      if(file.type === 'image/heic') {
        file = await convertHEICImage(file)
      }
      if (isBigFile) {
        e.target.value = '';
        setErrorMessage(isBigFile);
      } else {
        const error = await checkImageSize(file);
        if (error) {
          e.target.value = '';
          setProcessing(false);
          return setErrorMessage(error);
        }
        
        onChange(file, 'file');
      }
      setProcessing(false);
    }
  };

  const removeImageHandler = () => {
    scrapType === 'screenshot' && setEditImage(true);
    onChange(null, 'remove');
    setViewImageUrl(null);
  };

  const urlSubmitHandler = async () => {
    const enteredUrl = (urlInputRef?.current?.value || '').trim();
    if (enteredUrl !== '') {
      setProcessing(true);
      try {
        const b64Url = await getBase64FromUrl(enteredUrl);
        if (!(b64Url as string).startsWith('data:image/'))
          throw new Error('Invalid image URL!');
        const error = await checkImageSize(b64Url as string);

        if (error) {
          setProcessing(false);
          return setErrorMessage(error);
        }
        onChange(b64Url as string, 'b64Url');
        setViewImageUrl(enteredUrl);
        setMode(null);
      } catch (error) {
        alert(
          parseTryCatchError(
            error,
            'Unable to get base64 url from the image due to CORS policy! Please choose another image'
          )
        );
      } finally {
        setProcessing(false);
      }
    } else {
      alert('Please enter image url');
    }
  };

  const selectImageHandler = (image: string, mimeType?: string) => {
    onChange(image, 'cdn-url', mimeType);
    setMode(null);
  };

  const render = (
    getRootProps?: <T extends DropzoneRootProps>(props?: T) => T,
    getInputProps?: <T extends DropzoneInputProps>(props?: T) => T,
    isDragActive?: boolean
  ) => {
    // SHOW IMAGE IF IMAGE URL IS PRESENT
    if (viewImageUrl) {
      return (
        <div
          className={`add-image__view${
            unEditable ? ' add-image__view--uneditable' : ''
          }`}
        >
          {!hideCoverImageLabel && <span className="user-detail__toggle">Cover Image</span>}
          <img
            src={imageSize(viewImageUrl,800)}
            alt="add-view"
            className={`add-image__image ${
              scrap?.type === 'screenshot' ? 'add-image__image-screenshot' : ''
            }`}
            style={{ display: showImage ? 'block' : 'none' }}
            onLoad={() => {
              scrapType === 'screenshot' && setEditImage && setEditImage(false);
              setLoaded(true);
              setTimeout(() => setShowImage(true), 0);
            }}
          />
          {unEditable && (
            <button
              className="add-image__image-icon"
              onClick={() => {
                setToggleLightBox(true);
              }}
            >
              <FullScreenIcon />
            </button>
          )}

          {!loaded && <Skeleton height={'40vh'} className="add-image__image" />}

          {toggleLightBox && unEditable && (
            <NewPopup
              size="Extra large"
              fullMobile={true}
              header={{
                heading: 'Image Details',
                customRender: (close) => (
                  <header className="new-popup__header-inner-container">
                    <h3 className="new-popup__header-heading">
                      <button
                        onClick={() => {
                          setToggleLightBox(false);
                          close();
                        }}
                        className="new-popup__header-button"
                      >
                        <LeftArrowIcon className="new-popup__header-button__icon" />
                      </button>
                      Image Details
                    </h3>
                    <button
                      onClick={() => {
                        setToggleLightBox(false);
                        close();
                      }}
                      className="new-popup__header-button"
                    >
                      <CloseIcon className="new-popup__header-button__icon" />
                    </button>
                  </header>
                ),
              }}
              body={{
                className: 'add-view-full-popup',
              }}
              controlled={{
                show: toggleLightBox,
                setShow: setToggleLightBox,
              }}
            >
              {(close) => (
                <img
                  src={viewImageUrl}
                  alt="add-image__view-full"
                  className="add-image__view-full__image"
                />
              )}
            </NewPopup>
          )}

          {!unEditable && (
            <button
              onClick={removeImageHandler}
              className={`add-image__remove-button ${showUseExisting?"add-image__remove-button--bottom":""}`}
            >
              {/* {removeButtonLabel || 'Remove'} */}
              <Replace />
            </button>
          )}
        </div>
      );
    }

    if (!viewImageUrl && unEditable) {
      return (
        <div>
          <GlobalImageStatus
            image={viewImageUrl}
            config={config}
            className="add-image__pending-image"
          />
        </div>
      );
    }

    if (unEditable) return null;
    // IF MODE === URL, SHOW FORM FOR RECIEVING IMAGE URL FROM USER
    if (mode === 'url')
      return (
        <form
          className="add-image__url-form"
          onSubmit={(e) => {
            e.preventDefault();
            urlSubmitHandler();
          }}
        >
          <label className="add-image__url-label" htmlFor="add-image-url">
            Image URL
          </label>
          <input
            type="url"
            placeholder="https://"
            id="add-image-url"
            ref={urlInputRef}
            className="add-image__url-input"
            disabled={processing}
          />
          <div className="add-image__url-button-group">
            <button
              disabled={processing}
              onClick={(e) => {
                e.preventDefault();
                setMode(null);
              }}
              className="add-image__url-cancel-button button button__underlined"
            >
              Cancel
            </button>
            <button
              disabled={processing}
              className="add-image__url-submit-button button button__primary"
            >
              Add Image
            </button>
          </div>

          {errorMessage && (
            <p className="add-image__error-message">{errorMessage}</p>
          )}
        </form>
      );
  
    // IF MODE === USE-EXISTING, SHOW MODAL OF EXISTING IMAGES FROM WHICH USER CAN SELECT IMAGE FOR THE SCRAP
    if (mode === 'use-existing')
      return (
        <AddExistingImagesWrapper
          show={true}
          setShow={() => setMode(null)}
          // showFilteredCollection={selectedType?.title}
          oldImages={[]}
          selectedImages={[]}
          setSelectedImages={(dat) => {}}
          singleSelect={selectImageHandler}
          convertToBase64={convertCdnToBase64}
          defaultAll={defaultAll}
          collectionId={collectionId}
        />
      );

    // ELSE SHOW GENERAL FORM
    return (
      <>
        {!image && parsedConfig !== 0 ? (
          <div className="add-image__form" {...getRootProps({ isDragActive })}>
            {placeholder && (
              <span className="add-image__placeholder">{placeholder}</span>
            )}
            <div className="add-image__button-group">
              <button
                type="button"
                className={`add-image__button add-image__upload-button ${isDragActive?"add-image__button--active":""}`}
                onClick={() => {
                  inputRef.current.value = null;
                  inputRef.current.click();
                }}
              >
                Upload
              </button>

              {!showUseExisting && (
                <button
                  type="button"
                  onClick={setMode.bind(null, 'use-existing')}
                  className="add-image__button add-image__use-existing-button"
                >
                  Use Existing
                </button>
              )}
              {/* <button
                  onClick={setMode.bind(null, 'url')}
                 className="add-image__button add-image__url-button"
                 >
                 URL
                </button> */}
            </div>
            <span className="add-image__size-limit">
              Up to 30 mb.
            </span>
            <input
              {...getInputProps()}
              ref={inputRef}
              type="file"
              accept={supported_images_accept_list}
              onChange={(e) => {
                onFileChange(e);
              }}
            />
            {imageSizeRequirements && (
              <p className="add-image__image-requirements">
                Minimum {minWidth}px wide, {minHeight}px high
              </p>
            )}

            {errorMessage && (
              <p className="add-image__error-message">{errorMessage}</p>
            )}
            {showUseExisting && (
              <div className="add-image__button-wrapper">
                <button
                  type="button"
                  className="add-image__use-existing-image"
                  onClick={setMode.bind(null, 'use-existing')}
                >
                  Use Saved
                </button>

                <button onClick={()=>setShowUploader(false)} className="add-image__toggle-icon">
                  <TrashOutlineIcon/> <span> No Image </span>
                </button>

                {userData?.cover_image && <UndoBox label={"Revert"} onUndoClick={()=>undoImageHandler()}/>}

              </div>
            )}
          </div>
        ) : (
          <div>
            <GlobalImageStatus
              image={viewImageUrl}
              config={config}
              className="add-image__pending-image"
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    setViewImageUrl(image);
  }, [image]);

  useEffect(() => {
    setErrorMessage(null);
  }, [mode]);

  return (
    <Dropzone
      onDrop={(acceptedFiles) =>
        onFileChange(({
          target: { files: acceptedFiles },
        } as unknown) as React.ChangeEvent<HTMLInputElement>)
      }
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className={`add-image${processing ? ' add-image--processing' : ''}${
            !viewImageUrl ? ' add-image--no-image' : ''
          }${errorMessage ? ' add-image--error' : ''}${isDragActive?" add-image--focused":""}`}
        >
          {render(getRootProps, getInputProps, isDragActive)}
        </div>
      )}
    </Dropzone>
  );
};

export default AddImage;