import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const Uncategorized = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M2.39729 0C1.08106 0 0 1.08839 0 2.40401V4.80019C0 6.1158 1.08106 7.20262 2.39729 7.20262H4.79458C6.11081 7.20262 7.19971 6.11592 7.19971 4.80019V2.40401C7.19971 1.08839 6.11081 0 4.79458 0L2.39729 0ZM11.1973 0C9.88107 0 8.80001 1.08839 8.80001 2.40401V4.80019C8.80001 6.1158 9.88107 7.20262 11.1973 7.20262H13.6024C14.9186 7.20262 15.9997 6.11592 15.9997 4.80019V2.40401C15.9997 1.08839 14.9186 0 13.6024 0L11.1973 0ZM2.39758 8.80364C1.08135 8.80364 0.000285687 9.8842 0.000285687 11.1998V13.5976C0.000285687 14.9132 1.08135 16 2.39758 16H4.79487C6.1111 16 7.19999 14.9132 7.19999 13.5976L7.19988 11.1998C7.19988 9.8842 6.11099 8.80364 4.79476 8.80364H2.39758ZM11.1976 8.80364C9.88136 8.80364 8.80029 9.8842 8.80029 11.1998V13.5976C8.80029 14.9132 9.88136 16 11.1976 16H13.6027C14.9189 16 16 14.9132 16 13.5976L15.9999 11.1998C15.9999 9.8842 14.9188 8.80364 13.6026 8.80364H11.1976Z"
        fill="#969696"
      />
    </svg>
  );
};

export default Uncategorized;
