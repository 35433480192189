import React from 'react';

interface Proptypes {
  className?: string;
}

const GridView = ({ className }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className || ''}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0H2.5C1.11929 0 0 1.11929 0 2.5V6.5C0 7.88071 1.11929 9 2.5 9H6.5C7.88071 9 9 7.88071 9 6.5V2.5C9 1.11929 7.88071 0 6.5 0ZM2 2.5C2 2.22386 2.22386 2 2.5 2H6.5C6.77614 2 7 2.22386 7 2.5V6.5C7 6.77614 6.77614 7 6.5 7H2.5C2.22386 7 2 6.77614 2 6.5V2.5ZM6.5 11H2.5C1.11929 11 0 12.1193 0 13.5V17.5C0 18.8807 1.11929 20 2.5 20H6.5C7.88071 20 9 18.8807 9 17.5V13.5C9 12.1193 7.88071 11 6.5 11ZM2 13.5C2 13.2239 2.22386 13 2.5 13H6.5C6.77614 13 7 13.2239 7 13.5V17.5C7 17.7761 6.77614 18 6.5 18H2.5C2.22386 18 2 17.7761 2 17.5V13.5ZM13.5 11H17.5C18.8807 11 20 12.1193 20 13.5V17.5C20 18.8807 18.8807 20 17.5 20H13.5C12.1193 20 11 18.8807 11 17.5V13.5C11 12.1193 12.1193 11 13.5 11ZM13.5 13C13.2239 13 13 13.2239 13 13.5V17.5C13 17.7761 13.2239 18 13.5 18H17.5C17.7761 18 18 17.7761 18 17.5V13.5C18 13.2239 17.7761 13 17.5 13H13.5ZM17.5 0H13.5C12.1193 0 11 1.11929 11 2.5V6.5C11 7.88071 12.1193 9 13.5 9H17.5C18.8807 9 20 7.88071 20 6.5V2.5C20 1.11929 18.8807 0 17.5 0ZM13 2.5C13 2.22386 13.2239 2 13.5 2H17.5C17.7761 2 18 2.22386 18 2.5V6.5C18 6.77614 17.7761 7 17.5 7H13.5C13.2239 7 13 6.77614 13 6.5V2.5Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default GridView;
