import React from 'react';
import ImagesGroup from '../';
import { imageSize } from '../../../../helpers/imageSize';

interface Proptypes {
  images: string[];
  imageCount?: number;
}

export default function ImagesGroupThumbnail({ images, imageCount }: Proptypes) {
  if (images?.length <= 1)
    return <ImagesGroup images={images} mode="thumbnail" />;
  return (
    <ul
      className={`images-group__thumbnail images-group__thumbnail--${
        images?.length === 2 ? 'double' : 'triple'
      }`}
    >
      {images?.slice(0, 3).map((image) => (
        <li className="images-group__thumbnail-item" key={image}>
          <img className="images-group__image" src={imageSize(image,800)} alt={image} />
        </li>
      ))}
      {images?.length > 3 && imageCount && (
        <li className="images-group__thumbnail-count">+{ imageCount - 3}</li>
      )}
    </ul>
  );
}
