import React from 'react';

import './styles.css';
import { scrapType } from '../../types/scrapType';


export interface ScrapReactionCommentCountType {
  scrap_id: scrapType['id'];
  comment_count: number;
  reaction_count: number;
}
export interface CommentType {
  id?: number;
  scrap_id?: number;
  collection_id?: number;
  user_id?: number;
  parent_comment_id?: number
  text: string;
  likes?: number;
  created_at?: string;
  updated_at?: string;
  reaction_count?: number;
  reaction?: ReactionType;
  user: {
    user_id?: number;
    user_name: string;
    display_name: string;
    avatar: string;
  };
}
export interface ScrapCommentsReactionsType {
  scrap_id: scrapType['id'];
  reactions: ReactionType[];
}

export interface ReactionType {
  is_reacted: boolean;
  id: number;
  reactable_type: number;
  reactions?: Array<{
    id: number;
    user_id: number;
    scrap_id: number;
    collection_id: number;
    comment_id: number;
    reaction_type: number;
  }>
  reacted_users?: Array<CommentType['user']>;
}

export interface ScrapCommentsType {
  commenting_users?: Array<CommentType['user']>;
  comments?: CommentType[];
  scrap_id?: number;
  comment_count?: number;
}



interface Proptypes {
  children: (comment: CommentType, i: number) => React.ReactElement;
  comments: CommentType[];
  moreConfig?: {
    maxCount: number;
    onMoreClick: () => void;
    totalCount: number;
  };
  onLoadMore?: () => void;
  containerRef?: React.MutableRefObject<HTMLDivElement>;
}

export default function Comments({
  children,
  comments,
  moreConfig,
  onLoadMore,
  containerRef,
}: Proptypes) {

  const { maxCount, onMoreClick, totalCount } = moreConfig || {};
  let data = comments || [];
  let showMoreLabel: string;
  if (moreConfig && (maxCount < totalCount || data.length > maxCount)) {
    showMoreLabel = `View all comments (${totalCount})`;
    data = data.slice(0, maxCount);
  }

  const scrollHandler = () => {
    
    var element = containerRef.current;
    const { scrollHeight, scrollTop, clientHeight } = element;
    if (scrollHeight + scrollTop - 100 <= clientHeight) {
      if (onLoadMore) onLoadMore();
      
    }
  }
  return (
    <div className="comments" ref={containerRef} onScroll={scrollHandler}>
      {showMoreLabel && (
        <button className="comments__more-button hoverState__with-white-text" onClick={onMoreClick}>{showMoreLabel}</button>
      )}
      {data.map((comment, i) => children(comment, i))}
      
    </div>
  );
}
