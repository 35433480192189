import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PublicProfileDataType } from '../../../../containers/ProfilePreview';
import Icon from '../../../Global/Icon';
import {
  ChainIcon,
  FacebookSocial,
  InstagramIcon,
  LinkedInIcon,
  PencilIcon,
  PinterestIcon,
  TwitterIcon,
  UserLocation,
  YoutubeIcon,
} from '../../../Global/icons';

import { ReduxStateType } from '../../../../redux/store';
import './styles.css';
import Trust from '../../../Trust';
interface Proptypes {
  userData?: PublicProfileDataType;
  trustCb?: (val: boolean) => void;
  isTrusted?: boolean;
}

const ProfileCard = (props: Proptypes) => {
  const { userData, trustCb, isTrusted } = props;

  const user = useSelector((state: ReduxStateType) => state.user);
  let socialIcons;
  if(userData.social_media_accounts)
  {
    socialIcons = JSON.parse(JSON.parse(userData.social_media_accounts));
  }
  const [copied,setCopied] = useState(false);
  const returnIcon = (icon?: string) => {
    switch (icon) {
      case 'Instagram':
        return <InstagramIcon className={"profile-card__social-icons"}/>;
      case 'Pinterest':
        return <PinterestIcon className={"profile-card__social-icons"}/>;
      case 'Twitter':
        return <TwitterIcon className={"profile-card__social-icons"}/>;
      case 'Facebook':
        return <FacebookSocial className={"profile-card__social-icons"}/>;
      case 'LinkedIn':
        return <LinkedInIcon className={"profile-card__social-icons"}/>;
      case 'Youtube':
        return <YoutubeIcon className={"profile-card__social-icons"}/>;
    }
  };
  // todo: add invite button functionality
  // const inviteHandler = async () => {
  //   const variables = {
  //     isSending: true,
  //     login_via: LOGIN_VIA.email,
  //     // email: user.

  //   }
  // }

  const returnLink=(item)=>{
    if(socialIcons[item].startsWith("http"))
    {
      return socialIcons[item]
    }
    else{
      switch (item){
        case 'Instagram':
          return `https://www.instagram.com/${socialIcons[item][0]==="@"?socialIcons[item]?.substring(1):socialIcons[item]}`;
        case 'Facebook':
          return `https://www.facebook.com/${socialIcons[item][0]==="@"?socialIcons[item]?.substring(1):socialIcons[item]}`;
        case 'Pinterest':
          return `https://www.pinterest.com/${socialIcons[item][0]==="@"?socialIcons[item]?.substring(1):socialIcons[item]}`;
        case 'Twitter':
          return `https://www.twitter.com/${socialIcons[item][0]==="@"?socialIcons[item]?.substring(1):socialIcons[item]}`;
        case 'LinkedIn':
          return `https://www.Linkedin.com/in/${socialIcons[item][0]==="@"?socialIcons[item]?.substring(1):socialIcons[item]}`;
        case 'Youtube':
          return `https://www.youtube.com/${socialIcons[item]}`;
      }
    }
  }

  const handleCopy=(url)=>{
    navigator.clipboard.writeText(encodeURI(url || ''));
    setCopied(true);
    setTimeout(()=>{
      setCopied(false);
    },1500)
  }
  let showFooter = false;
  if(userData.url) showFooter = true;
  if(userData.location) showFooter = true;
  if( Object.keys(socialIcons || {}).length) showFooter = true;
  return (
    <div className="profile-card__main">
      {userData.cover_image ? (
        <div className="profile-card__mainimg">
          {userData?.cover_image ? <img src={userData?.cover_image} alt="" /> : null}
        </div>
      ) : (
        <div className="profile-card__no-image" />
      )}
      <div className="profile-card">
        <div className="profile-card__header">
          <div className="profile-card__img">
            <img
              src={
                userData?.avatar
                  ? userData?.avatar
                  : '/dashboard/img/default-avatar.jpg'
              }
              alt=""
            />
          </div>
          <div className="profile-card__clntname">
            {
              userData.display_name?
                <span>
                  {userData.display_name}
                  {/*<Trust view="icon" isTrusted={isTrusted} userId={userData.user_id} cb={(state) => trustCb(state)} />*/}
                </span> :
                null
            }

            <span className="profile-card__txtcolor">
              {'@' + userData?.userName}
            </span>
          </div>

          <div className="profile-card__trust">
            <Trust view="button" isTrusted={isTrusted} userId={userData.user_id} cb={(state) => trustCb(state) } />
          </div>

          {userData.userName === user.userName ? (
            <div className="profile-card__edit">
              <span className="profile-card__editbody">
                <Link
                  to={{
                    pathname: `/profile`,
                    search: `?edit-profile=true`,
                  }}
                >
                  {' '}
                  <PencilIcon />{' '}
                  <span className="profile-card__txtcolor">Edit</span>
                </Link>
              </span>
            </div>
          ) : null}
        </div>
        <p className="profile-card__para">{userData?.bio}</p>
        {showFooter ? (
          <div className="profile-card__footer">
            <div className="profile-card__location">
              {userData?.url ? (
                <span title={userData?.url} className="profile-card__link">
                  <span
                    className="profile-card__txtcolor profile-card__txtcolor-url "
                    onClick={() => handleCopy(userData?.url)}
                  >
                    {' '}
                    <ChainIcon />
                    {copied ? (
                      <div className="cover-image__copied">
                        <Icon iconClass="check-square" size="big" />
                        Link copied to clipboard
                      </div>
                    ) : null}
                  </span>{' '}
                  <span className="profile-card__linkunq">
                    <a
                      href={userData?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {userData?.url.toLowerCase().replace(/.+\/\/|www.|\+/g, '')}
                    </a>
                  </span>
                </span>
              ) : null}
              {userData?.location ? (
                <span className="profile-card__txtcolor profile-card__place  ">
                  <UserLocation />

                  <span className="profile-card__location">
                    {userData?.location}
                  </span>
                </span>
              ) : null}
            </div>
            {Object.keys(socialIcons || {}).length
              ? Object.keys(socialIcons).map((item, index) => {
                return (
                  <a
                    key={index}
                    className="profile-card__txtcolor "
                    href={returnLink(item)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {returnIcon(item)}
                  </a>
                );
              })
              : null}
          </div>
        ) : null}
        <div className="profile-card__collection">
          <p>
            <span>{userData?.collections_count || 0}</span>{' '}
            <span>{`${
              (userData?.collections_count > 1 || !userData.collections_count) ? 'Collections' : 'Collection'
            }`}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default ProfileCard;
