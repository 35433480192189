import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
  isFilled?: boolean;
}

const HeartIcon = ({ className, onClick, isFilled }: Proptypes) => {
  if (isFilled)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        className={className || ''}
      >
        <path
          d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"
          fill="#1C274C"
        />
      </svg>
    );
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className || ''}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.22913 15.1617L9.00003 13.875L9.79025 15.15C9.31308 15.4457 8.7107 15.4503 8.22913 15.1617ZM7.86916 4.88058C7.28941 4.48682 6.53198 4.125 5.70609 4.125C3.81208 4.125 2.70037 5.51785 3.07094 7.42969C3.70539 10.7029 9.00003 13.875 9.00003 13.875C8.22913 15.1617 8.22942 15.1619 8.22913 15.1617L8.22613 15.1599L8.2212 15.157L8.20564 15.1475C8.19278 15.1397 8.17497 15.1288 8.15254 15.115C8.10768 15.0873 8.04424 15.0477 7.96467 14.997C7.80567 14.8956 7.58158 14.7491 7.31228 14.5638C6.77575 14.1944 6.04956 13.6634 5.29739 13.0188C4.54946 12.378 3.74722 11.6006 3.07818 10.7349C2.41852 9.88144 1.819 8.8535 1.59835 7.71512C1.34659 6.41621 1.57291 5.12018 2.36664 4.12507C3.16547 3.12355 4.38163 2.625 5.70609 2.625C7.07384 2.625 8.21078 3.26923 8.91611 3.78346C8.94452 3.80417 8.97249 3.82487 9.00003 3.84552C9.02757 3.82487 9.05554 3.80417 9.08395 3.78346C9.78929 3.26923 10.9262 2.625 12.294 2.625C15.2528 2.625 16.9597 5.36605 16.3887 7.7756C16.1269 8.88041 15.5203 9.88654 14.8593 10.7312C14.1892 11.5876 13.3972 12.3612 12.6603 13.002C11.9196 13.6462 11.2088 14.1785 10.6843 14.5493C10.4211 14.7354 10.2025 14.8825 10.0475 14.9845C9.96998 15.0355 9.90822 15.0753 9.86459 15.1031C9.85354 15.1101 9.84365 15.1164 9.83495 15.1219C9.82648 15.1273 9.81915 15.1319 9.81298 15.1358L9.7979 15.1452L9.79313 15.1482L9.79025 15.15C9.78997 15.1501 9.79025 15.15 9.00003 13.875C9.00003 13.875 14.1595 10.6772 14.9291 7.42969C15.2997 5.86607 14.188 4.125 12.294 4.125C11.4681 4.125 10.7107 4.48683 10.1309 4.88059C9.4389 5.35058 9.00003 5.86607 9.00003 5.86607C9.00003 5.86607 8.56116 5.35058 7.86916 4.88058Z"
        fill="#84929A"
      />
    </svg>
  );
};

export default HeartIcon;
