import React, { useEffect } from 'react';
import { SearchHistoryItemType } from '../../../../types/globalSearchDataType';
import { Search } from '../../../Global/icons';
import Pills from '../../../Pills/pills';
import { useDispatch } from "react-redux";
import { canPaste } from "../../../../redux/action/pasteDataAction";

interface Proptypes {
  hideHistory: boolean;
  placeholder: string;
  showSearchIcon: boolean;
  showClearIcon: boolean;
  inputRef: React.MutableRefObject<HTMLInputElement>;
  focusHandler: (val: string) => void;
  blurHandler: (val: string) => void;
  inputChangeHandler: (val: string) => void;
  clearIconClickHandler: () => void;
  searchHistoryList: SearchHistoryItemType[];
  deleteAllHistoriesHandler: () => Promise<void>;
  deleteHistoryHandler: (id: string) => Promise<void>;
  disabled: boolean;
  loadingHistories: boolean;

  filterSelectHandler: (val: SearchHistoryItemType) => void;
  hoveredIndex?:number;
  setHoveredIndex?:(val:number)=>void;
}
const GlobalSearchInput = (props: Proptypes) => {
  const {
    hideHistory,
    placeholder,
    inputRef,
    focusHandler,
    blurHandler,
    inputChangeHandler,
    searchHistoryList,
    deleteAllHistoriesHandler,
    disabled,
    loadingHistories,
    filterSelectHandler,
    hoveredIndex,
    setHoveredIndex
  } = props;

  const dispatch = useDispatch();

  function handleKeyPress(event) {
    switch (event.keyCode) {
      case 38:
        // Up arrow key is pressed
        if (searchHistoryList.length > 0) {
          setHoveredIndex(
            hoveredIndex === 0 ? searchHistoryList.length - 1 : hoveredIndex - 1
          );
        } else setHoveredIndex(-1);
        break;
      case 40:
        // Down arrow key pressed
        if (searchHistoryList.length > 0) {
          setHoveredIndex(
            hoveredIndex < searchHistoryList.length - 1 ? hoveredIndex + 1 : 0
          );
        } else setHoveredIndex(-1);
        break;
      case 13:
        // enter key pressed
        if (
          searchHistoryList.length > 0 &&
          hoveredIndex !== -1 &&
          searchHistoryList[hoveredIndex]
        ) {
          event.preventDefault();
          filterSelectHandler(searchHistoryList[hoveredIndex]);
        }

        break;
      default:
        break;
    }
  }
  useEffect(() => {
    setHoveredIndex(-1);
  }, [searchHistoryList]);

  return (
    <div className="global-search-bar__input-wrapper">
      <div style={{ position: 'relative' }}>
        <div className="global-search-bar__input-holder">
          <input
            disabled={disabled}
            onKeyDown={handleKeyPress}
            ref={inputRef}
            className="global-search-bar__search-term"
            placeholder={placeholder}
            type="text"
            onFocus={(e) => {
              focusHandler(e.target.value);
            }}
            onChange={(e) => inputChangeHandler(e.target.value)}
            onBlur={(e) => {
              blurHandler(e.target.value);
              dispatch(canPaste(true));
            }}
            onFocusCapture={(e) => {
              dispatch(canPaste(false));
            }}
          />
          <button type="submit" className="global-search-bar__search-button hoverState__with-white-text">
            <Search />
          </button>
        </div>
      </div>
      {!hideHistory &&
        // new ui for global search input suggestions
        (!searchHistoryList.length || loadingHistories ? null : (
          <div className="global-search-suggestions">
            <span
              className="global-search-suggestions__header"
              onClick={() => deleteAllHistoriesHandler()}
            >
              Recent Searches
            </span>

            <div className="global-search-suggestions-div">
              <Pills
                pillsList={searchHistoryList}
                hoveredPill={hoveredIndex}
                onClickFun={filterSelectHandler}
                nav={false}
                loadingHistories={loadingHistories}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default GlobalSearchInput;
