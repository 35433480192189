import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PriceCard from '../components/Pricing/PriceCardStripe';
import GET_SUBSCRIPTION_LIST_ITEMS from './data/getSubscriptionList';
import GET_STRIPE_PRODUCT_ITEMS from './data/getStripeProducts';

interface GetStripeProducts {
  id: string;
  name: string;
  buttonDetail: string;
  price: number;
  customerActive: boolean;
  isAnnual: boolean;
  included: Array<string>; 
  customerItemUnitPrice: number;
  modifiedPriceId: string
  modifiedCustomerActivePlanPriceId: string
  modifiedCustomerActivePlanPriceFrequency: string
}


const PricingPageContainer = () => {
  // Fetch subscription list items using Apollo useQuery hook
  const { data, loading } = useQuery(GET_STRIPE_PRODUCT_ITEMS);

  // State for toggling between annual and monthly subscriptions
  const [isAnnual, setIsAnnual] = useState(true);

  // State to store the subscription list
  const [stripeProducts, setStripeProducts] = useState<GetStripeProducts[]>();



  useEffect(() => {
    if (!loading && data) {
      // Filter the subscription list based on the selected annual or monthly option
      const updatedArray = data?.getStripeProducts?.data.filter((item) => item.isAnnual === isAnnual);
      setStripeProducts(updatedArray)
    }
  }, [data, loading, isAnnual]);

  return (
    <>
      {
        data && (
          <div className='profile-form-storage-text-group profile-form-storage-price-container'>
            <div className='profile-form-price-toggle'>
              <button onClick={() => setIsAnnual(true)} className={` ${isAnnual ? 'profile-form-price-toggle-active' : 'profile-form-price-toggle-button'}`}> Annual</button>
              <button onClick={() => setIsAnnual(false)} className={` ${!isAnnual ? 'profile-form-price-toggle-active' : 'profile-form-price-toggle-button'}`}> Monthly</button>
            </div>
          </div>
        )
      }
      <div className='profile-form-storage-price-wrapper'>
        {stripeProducts && stripeProducts.map(priceData => {
          return <PriceCard
            name={priceData.name}
            price={priceData.price}
            buttonDetail={priceData.buttonDetail}
            isActive={priceData.customerActive}
            included={priceData.included}
            isAnnual={priceData.isAnnual}
            id={priceData.id}
            customerItemUnitPrice={priceData.customerItemUnitPrice}
            modifiedPriceId={priceData.modifiedPriceId}
            modifiedCustomerActivePlanPriceId={priceData.modifiedCustomerActivePlanPriceId}
            modifiedCustomerActivePlanPriceFrequency={priceData.modifiedCustomerActivePlanPriceFrequency}
          />
        })}
      </div>

    </>
  )


};

export default PricingPageContainer;
