import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import './styles.css';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
interface Proptypes {
  showButton?: boolean;
}

function NewBackToTop({ showButton }: Proptypes) {
  useEffect(() => {
    document.addEventListener('scroll', () => {
      const isTopValue = window.scrollY < 100;
      if (isTopValue !== isTop) {
        setIsTop(isTopValue);
      }
    });
  });
  const [isTop, setIsTop] = useState(true);
  const faArrowUpIcon = faArrowUp as IconProp;
  const scrollToTop = () => {
    try {
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    } catch (error) {
      //
    }
  };
  return (
    <>
      {!isTop && (
        <button className="top-button hoverState-button" onClick={() => scrollToTop()}>
          <FontAwesomeIcon icon={faArrowUpIcon} />
        </button>
      )}
    </>
  );
}

export default NewBackToTop;
