export async function getImageDimensions(
  data: File | string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    if (typeof data === 'string') {
      img.onload = function () {
        resolve({ height: img.height, width: img.width });
      };
      img.src = data;
    } else {
      const fr = new FileReader();
      fr.onload = function () {
        img.onload = function () {
          resolve({ height: img.height, width: img.width });
        };

        img.src = fr.result as string;
      };

      fr.readAsDataURL(data);
    }
  });
}
