import React from 'react';

interface Proptypes {
  className?: string;
}

const Ppt = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="54"
      height="60"
      viewBox="0 0 54 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12277_215360)">
        <path
          d="M42.9 25.7V17.1C42.9 16.8 42.9 16.5 42.7 16.3C42.6 16 42.4 15.8 42.2 15.6L27.2 0.6C27 0.4 26.8 0.2 26.5 0.1C26.3 0 26 0 25.7 0H4.3C3.2 0 2.1 0.5 1.3 1.3C0.5 2.1 0 3.2 0 4.3V55.7C0 56.8 0.5 57.9 1.3 58.7C2.1 59.5 3.2 60 4.3 60H38.6V55.7H4.3V4.3H21.4V17.2C21.4 18.3 21.9 19.4 22.7 20.2C23.5 21 24.6 21.5 25.7 21.5H38.6V25.8L42.9 25.7ZM25.7 17.1V5.1L37.7 17.1H25.7Z"
          fill="#616161"
        />
        <path
          d="M10.2998 29.8999H17.0998C21.4998 29.8999 23.6998 31.7999 23.6998 35.6999V36.9999C23.6998 40.7999 21.8998 43.0999 16.8998 43.0999H15.3998V51.5999H10.2998V29.8999ZM15.4998 39.6999H16.2998C18.1998 39.6999 18.6998 38.7999 18.6998 36.9999V35.4999C18.6998 34.0999 18.2998 33.1999 16.5998 33.1999H15.4998V39.6999Z"
          fill="#616161"
        />
        <path
          d="M26.0996 29.8999H32.9996C37.3996 29.8999 39.5996 31.7999 39.5996 35.6999V36.9999C39.5996 40.7999 37.7996 43.0999 32.7996 43.0999H31.2996V51.5999H26.1996V29.8999H26.0996ZM31.2996 39.6999H32.0996C33.9996 39.6999 34.4996 38.7999 34.4996 36.9999V35.4999C34.4996 34.0999 34.0996 33.1999 32.3996 33.1999H31.2996V39.6999Z"
          fill="#616161"
        />
        <path
          d="M44.4 33.5999H40.5V29.8999H53.4V33.5999H49.5V51.5999H44.4V33.5999Z"
          fill="#616161"
        />
      </g>
      <defs>
        <clipPath id="clip0_12277_215360">
          <rect width="53.9" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ppt;
