import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { charLimits } from '../../../helpers/config';

import './styles.css';
import LabelTextInput from '../LabelTextInput';
interface Proptypes {
  label?: string;
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  disabled?: boolean;
  type?: 'textarea' | 'input';
  limitConfigTitle?: {
    charLimit: number;
    titleCharLimitExceeds: boolean;
    setTitleCharLimitExceeds: Dispatch<SetStateAction<boolean>>;
  };
}
const TextInput = ({
  value,
  onChange,
  placeholder,
  disabled: readOnly,
  type,
  limitConfigTitle,
  label,
}: Proptypes) => {
  const { charLimit, setTitleCharLimitExceeds } = limitConfigTitle || {};
  const [charLength, setCharLength] = useState(null);
  const [focused, setFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>();
  useEffect(() => {
    if (limitConfigTitle) {
      setTitleCharLimitExceeds(charLength > charLimit);
    }
  }, [limitConfigTitle, charLength]);

  let elProps = {
    onChange: (e) => {
      setCharLength(e?.target?.value?.length);
      onChange(e?.target?.value);
    },
    placeholder: placeholder || 'Type here!',
    value,
    className: 'text-input__input',
    readOnly,
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
  };
  useEffect(() => {
    const textAreaElement = textAreaRef.current;
    if (textAreaElement) {
      textAreaElement.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, [textAreaRef]);
  let el = <input {...elProps} />;
  if (type === 'textarea') {
    el = (
      <div className="text-input__text-area">
        <textarea
          {...{ ...elProps, className: null }}
          ref={textAreaRef}
          rows={1}
          placeholder={focused ? '' : placeholder}
          name="text"
          id="text"
          onInput={(e) => {
            ((e.target as HTMLTextAreaElement)
              .parentNode as HTMLDivElement).dataset.replicatedValue = (e.target as HTMLTextAreaElement).value;
          }}
        ></textarea>
      </div>
    );
  }
  if (readOnly && !value) return null;
  return (
    <div className="text-input">
      {(label && (focused || value?.length)) ?<span className={`collection-settings__title${focused ? ' collection-settings__title--focused' : ''}`}>Title</span> : null}
      {el}
      {!readOnly && limitConfigTitle && focused && (
        <div className="edit-description__limit">
          <span
            className={`edit-description__limit-tracker${
              (charLength || 0) > charLimit
                ? ' edit-description__limit-tracker-danger'
                : ''
            }`}
          >
            {charLimit - charLength}
          </span>
          {charLength > charLimit && (
            <span className="edit-description__limit-exceeds-message">
              {charLimits['characterLimitReachedMessage']}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default TextInput;
