import React from 'react';
import RenderEmbed from './RenderEmbed';

interface PropType {
  meta: string;
}

interface PinProps {
  pinUrl: string;
}

export const Pinterest = ({ meta }: PropType) => {
  // parse the meta data for twitter
  let parsedMeta: PinProps | string = meta;
  let keepParsing = true;
  while (keepParsing) {
    try {
      parsedMeta = JSON.parse(parsedMeta as string);
    } catch (error) {
      keepParsing = false;
    }
  }
  let pinUrl = (parsedMeta as PinProps).pinUrl || '';
  if (pinUrl) {
    
    // Added logic to parse id from pin url and remove slug from url
    let slicedUrlList = pinUrl.split('/');
    if(!slicedUrlList[slicedUrlList.length - 1]) slicedUrlList.pop()
    let lastItem = slicedUrlList[slicedUrlList.length - 1];
    if (lastItem.includes('--')) {
      slicedUrlList.pop();
      let lastItemSplittedList = lastItem.split('--');
      let id = lastItemSplittedList[lastItemSplittedList.length - 1];
      pinUrl = [...slicedUrlList, id].join('/');
    }
  }
  
  return <RenderEmbed href={pinUrl} />;
};
