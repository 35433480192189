import { gql } from '@apollo/client';

// interface propType {
//   type: string;

// }

export const DELETE_PANEL = (type: string) => {
  switch (type) {
    case 'screenshot':
      return gql`
        mutation deleteScreenshotPanel($id: ID!) {
          deleteScreenshot(id: $id)
        }
      `;
    case 'product':
      return gql`
        mutation deleteProductPanel($id: ID!) {
          deleteProduct(id: $id)
        }
      `;
    case 'annotation':
      return gql`
        mutation deleteAnnotationPanel($id: ID!) {
          deleteAnnotation(id: $id)
        }
      `;
    case 'video':
      return gql`
        mutation deleteVideoPanel($id: ID!) {
          deleteVideo(id: $id)
        }
      `;
    case 'url':
      return gql`
        mutation deleteUrlPanel($id: ID!) {
          deleteUrl(id: $id)
        }
      `;
    case 'note':
      return gql`
        mutation deleteNotePanel($id: ID!) {
          deleteNote(id: $id)
        }
      `;
    case 'social':
      return gql`
        mutation deleteSocialPanel($id: ID!) {
          deleteSocial(id: $id)
        }
      `;
    case 'page':
      return gql`
        mutation deletePagePanel($id: ID!) {
          deletePage(id: $id)
        }
      `;
    case 'audio':
      return gql `mutation deleteAudioPanel($id:ID!){
        deleteAudio(id:$id)
      }`;
    case 'gallery':
      return gql `mutation deleteGalleryPanel($id:ID!){
        deleteGallery(id:$id)
      }`;
    case 'document':
      return gql`
        mutation deleteDocumentPanel($id: ID!) {
          deleteDocument(id: $id)
      }`;
    default:
      break;
  }
};
