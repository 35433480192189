import React, { Fragment, useEffect } from 'react';
import { CrossIcon } from '../../../Global/icons';
import { NewScrapSelectOptionType } from '../CreateScrapIconToggler';
import * as settingData from '../../../../settings.json';

import './styles.css';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';

interface Proptypes {
  options: Array<NewScrapSelectOptionType>;
  close: () => void;
  children: (option: NewScrapSelectOptionType, i: number) => React.ReactElement;
}

const SelectScrap = ({ options, children, close }: Proptypes) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { default: config }: any = { ...settingData };
  const { width } = useSelector((state: ReduxStateType) => state.utils.dimensions);
  let extensionExist = false;
  if (typeof (window) !== "undefined") {
    if (document.querySelector('x-scrappi-indicator')) {
      extensionExist = true;
    }
  }

  useEffect(()=>{
    if (typeof (window) !== "undefined" && document.body.clientWidth <= 600) {
      document.body.style.overflow = 'hidden'
      return ()=>{
        document.body.style.overflow = 'auto'
      }
    }
  },[])

  return (
    <section className="select-scrap">
      <header className="select-scrap__header">
        <h3 className="select-scrap__header-title">New Scrap or Collection</h3>
        <span onClick={close} className="select-scrap__header-close-icon">
          <CrossIcon />
        </span>
      </header>

      <ul
        className={`select-scrap__list ${
          !extensionExist ? 'select-scrap__list-extension' : ''
        } `}
      >
        {options.map((option, i) => (
          <Fragment key={option.title}>{children(option, i)}</Fragment>
        ))}
      </ul>

      {!extensionExist && (
        <div className="select-scrap__extension-message">
          <p className="select-scrap__item__title">
            Use Scrappi's desktop{' '}
            <a
              href={
                config?.chromeExtensionStoreUrl
                  ? config?.chromeExtensionStoreUrl
                  : '/help'
              }
              target="_blank"
              rel="noreferrer"
            >
              browser extension
            </a>{' '}
            to create a Scrap.
          </p>
        </div>
      )}
    </section>
  );
};

export default SelectScrap;