import * as config from '../../settings.json';
import axios from 'axios';

const ValidateHuman = async (ref = null) => {
  if (config.recaptchaDisabled || !ref) {
    return true;
  }

  try {
    const token = await ref.executeAsync();

    const captchaResponse = await axios({
      url: `${config.baseUrl}`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        query: `mutation isHuman($token: String) {
            isHuman(token : $token) {
              messages {
                error
                success
              }
              status
              http_code
            }
          }
        `,
        variables: {
          token: token,
        },
      },
    });

    // @ts-ignore
    if (!captchaResponse.data?.data?.isHuman?.messages?.error?.length) {
      return true;
    }
  } catch (err) {
    //
  }
  return false;
};

export default ValidateHuman;
