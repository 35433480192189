import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useWindowOnClick from '../../../../helpers/customHooks/useWindowOnclick';
import { shareSettingsOption } from '../../../../redux/action/shareSettingsAction';
import { setBackUrl } from '../../../../redux/action/utils';
import { ReduxStateType } from '../../../../redux/store';
import { entryTypes } from '../../../../types/feed';
import { userTypes } from '../../../../types/user';

import PortalComponent from '../../../Global/Portal';
import Overlay from '../../../Overlay';
import DependentOption from './DependentOption';
import IndependentOption from './IndependentOption';

import './styles.css';

export interface independentOptionConfigType {
  label: string;
  onClick: (entry: entryTypes) => void;
  action?: dependentOptionActionsType;
}
export interface cardSettingsIndependentOptionType {
  type: 'independent';
  icon?: string | { (): React.ReactElement };
  config: independentOptionConfigType;
}

export type dependentOptionActionsType = 'visibility' | 'delete' | 'link-open';
export interface dependentOptionConfigType {
  action: dependentOptionActionsType;
  label?: string;
  onComplete?: (entry: entryTypes) => void;
}

export interface cardSettingsDependentOptionType {
  type: 'dependent';
  config: dependentOptionConfigType;
}

export interface cardSettingsConfigType {
  options: Array<
    cardSettingsIndependentOptionType | cardSettingsDependentOptionType
  >;
  syncScrap?: (scrap: entryTypes) => void;
}

export interface ShareSettingsProptypes {
  scrap: entryTypes;
  isPublic?: boolean;
  settings: cardSettingsConfigType;
  pointer?: {
    y: number;
    x: number;
  };
  show?: boolean;
}
interface propTypes {
  scrapOwner?: ShareSettingsProptypes;
  isPublic?: boolean;
}
const CardSettings = ({ isPublic, scrapOwner }: propTypes) => {
  const { pathname, search } = useLocation();
  const { settings, scrap, pointer, show } = useSelector(
    (state: ReduxStateType) => state.shareSettingsOption
  );
  const dispatch = useDispatch();
  const listRef = useRef<HTMLDivElement>();
  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement =
      el.closest('#card-settings-' + scrap.pid) ||
      el.closest('.card-settings__list');
    if (!closestElement)
      dispatch(
        shareSettingsOption({
          settings: null,
          scrap: null,
          pointer: null,
          show: false,
        })
      );
  };

  const { width } = useSelector((state: ReduxStateType) => state.utils.dimensions);
  const userData = useSelector(
    (state: ReduxStateType) => state.user as userTypes
  );

  // const scrapOwner = useSelector(
  //   (state: ReduxStateType) => state.shareSettingsOption.scrap
  // );
  const maxCardWidth = 230;
  useWindowOnClick({ cb: handleOutSideClick, removeListener: !show });

  // USEEFFECT TO POSITION THE OPTIONS LIST ABOVE THE OPTION ICON
  useEffect(() => {
    if (pointer && listRef.current && width > 450) {
      let clientWidth = listRef.current.clientWidth;
      // BASED ON MAXWIDTH
      let widthGap = maxCardWidth - clientWidth;
      listRef.current.style.top =
        pointer.y - listRef.current.clientHeight - 10 + 'px';
      listRef.current.style.left = pointer.x + widthGap + 6 + 'px';
    }
  }, [pointer, listRef, width]);

  if (!settings || !scrap) return null;
  let el = (
    <div
      className={`card-settings__list-container${
        show ? ' card-settings__list-container--show' : ''
      }`}
      style={
        pointer && width > 450
          ? {
            maxWidth: maxCardWidth + 'px',
            width: 'fit-content',
            minWidth: 'unset',
          }
          : null
      }
      ref={listRef}
    >
      <ul className="card-settings__list">
        <div className="card-settings__list--header-container">
          <h1 className="card-settings__list--heading">Scrap Options</h1>
        </div>
        {width <= 450 && scrap?.title ? (
          <div className="card-settings__list--header-container">
            <h1 className="card-settings__scrap-title">{scrap.title}</h1>
          </div>
        ) : null}
        {settings.options.map((option, i) => { 
          if (option?.config?.label === 'Open Link' &&  (!scrap?.url && !scrap?.updated_url)) {
            return null;
          }
          

          if (
            !(userData.userName === scrap?.userName) &&
            ['Remove From Collection', 'delete', 'Edit Scrap'].includes(
              option.config?.label || option.config?.action
            )
          )
            return null;

          let renderedElement;
          if (
            !(userData.userName === scrap?.userName) &&
            ['Remove From Collection', 'delete', 'Edit Scrap'].includes(
              option.config?.label || option.config?.action
            )
          )
            return null;

          if (option.type === 'independent') {
            const { label, onClick } = option.config;
            renderedElement = (
              <IndependentOption
                key={label}
                onClick={() => {
                  dispatch(setBackUrl(search));
                  dispatch(
                    shareSettingsOption({
                      scrap,
                      settings,
                      pointer,
                      show: false,
                    })
                  );
                  onClick(scrap);
                }}
                icon={option.icon}
              >
                {option.config.label}
              </IndependentOption>
            );
          } else if (option.type === 'dependent') {
            renderedElement = (
              <DependentOption
                key={option.config.action}
                config={option.config}
                scrap={scrap}
                onClick={() =>
                  dispatch(
                    shareSettingsOption({
                      scrap,
                      settings,
                      pointer,
                      show: false,
                      isPublic: isPublic,
                    })
                  )
                }
                syncScrap={settings.syncScrap}
              />
            );
          }

          return (
            <li className="card-settings__list-item-container" key={i}>
              {renderedElement}
            </li>
          );
        })}
        {width <= 450 ? (
          <>
            <div className="card-settings__footer-divider"></div>
            <button
              className="card-settings__close-btn button button__outline"
              onClick={() =>
                dispatch(
                  shareSettingsOption({ scrap, settings, pointer, show: false })
                )
              }
            >
              Close
            </button>
          </>
        ) : null}
      </ul>
    </div>
  );
  if (width <= 450) {
    el = (
      <PortalComponent show>
        <Overlay
          dontChangeOverFlow={!show}
          overLayClassName={show ? 'card-settings__my-overlay' : ''}
        >
          {el}
        </Overlay>
      </PortalComponent>
    );
  }

  return (
    <div
      key={scrap?.pid}
      className="card-settings"
      id={'card-settings-' + scrap.pid}
    >
      {width <= 450 && settings ? (
        el
      ) : (
        <PortalComponent show>{el}</PortalComponent>
      )}
    </div>
  );
};

export default CardSettings;
