const DEFAULT = '';

const filtersArray = (state =  DEFAULT, action) => {

  switch(action.type) {
    case 'LOAD_FILTERS_ARRAY' :
      
      return [...action.filterArrayData];

    default :
      return state;
  }
};

export default filtersArray;

  
      
  