import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['members', '[InputMemberId]!'],
  ['original_group_id', 'Int!'],
  ['need_status_for', 'Boolean!']
]);
export default function CHECK_IS_GROUP_WITH_SAME_MEMBERS_EXIST(responseString = '') {
  return gql`
    query CheckIsGroupWithSameMembersExist(${parametersTypeString}) {
      checkIsGroupWithSameMembersExist(${parametersAssignString}) {
        ${defaultResponse}
        data {
          original {
            id
            user_id
          }
          existing {
            id
            user_id
            nickname
            owner_user_name
            owner_display_name
            owner_avatar
            members {
              id
              user_id
              avatar
              user_name
              display_name 
            }
          }
        }
      }
    }
  `;
}
