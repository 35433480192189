/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient } from '@apollo/client';
import { FetchedExistingImageType } from '../../../components/Gallery/types/imagesTypes';
import { fetchBlob } from '../../../helpers';
import { FileItemStateType } from '../types';
import { BULK_UPLOAD } from '../../data/BulkUpload';
import { returnArrayInBatch } from '../../../helpers/arrayHelpers';
import UPSERT_SCRAP_IMAGE from '../../data/upsertScrapImage';
import { scrapType } from '../../../types/scrapType';

export default async function saveImagesInScrapHandler(
  scrapId: number,
  client: ApolloClient<object>,
  filesList: Array<FileItemStateType>
) {
  const listOfFiles: Array<{ id: string; file: File; is_heic?: boolean}> = []; // Stores the files from file url in array
  const uploadImagesResponse: FetchedExistingImageType[] = []; // Stores the response of images uploaded

  // Loop through selected images and create the file object from the file url stored in selected images state
  for (const item of filesList) {
    if (!item.fileUrl.startsWith('https://')) {
      const blob = await fetchBlob(item.fileUrl);
      const file = new File([blob], item.filename, {
        type: blob.type,
      });
      listOfFiles.push({ file, id: item.id, is_heic: item.is_heic });
    }
  }
  if (listOfFiles.length) {
    // Perform upload images in batch of 10
    for (
      let currentSet = 0;
      currentSet < listOfFiles.length;
      currentSet += 10
    ) {
      const variables = {
        uploads: listOfFiles
          .map((item) => ({
            is_heic: item.is_heic,
            file: item.file
          }))
          .slice(currentSet, currentSet + 10),
      }
      const response = await client.mutate({
        mutation: BULK_UPLOAD,
        variables
      });
      if (response.data?.bulkUpload) {
        uploadImagesResponse.push(
          ...response.data.bulkUpload.map((item, i) => ({
            ...item,
            id: listOfFiles[i].id,
          }))
        );
      }
    }

    // Check if any image is not successfully uploaded, and store the boolean value in hasUploadFailed variable
    const hasUploadFailed = uploadImagesResponse.some(
      (image) => !image.name || !image.order
    );
    if (hasUploadFailed) throw new Error('Uploading images failed!');

    // Error handling check for array in uploadImagesResponse variable before proceeding further
    if (
      !Array.isArray(uploadImagesResponse) ||
      uploadImagesResponse?.length === 0
    ) {
      throw new Error('Something went wrong while uploading images');
    }
  }

  // Use returArrayInBatch divide the filesList in batch of 10 and also use above uploaded images response to create these batched arrays
  // which will contain the cdn link of successfully uploaded image files
  const batchList = returnArrayInBatch(
    [
      ...filesList
        .filter((item) => !item.fileUrl.startsWith('https://'))
        .map((data, i) => {
          return {
            id: data.id,
            image: uploadImagesResponse[i]['name'],
            filename: data.filename,
            desc: data.description
              ? JSON.stringify(JSON.stringify(data.description))
              : '',
            order: data.order,
            is_heic: data.is_heic,
          };
        }),
      ...filesList
        .filter((item) => item.fileUrl.startsWith('https://'))
        .map((data) => {
          return {
            id: data.id,
            desc: data.description
              ? JSON.stringify(JSON.stringify(data.description))
              : '',
            order: data.order,
            filename: null,
            image: data.fileUrl,
            is_heic: data.is_heic,
          };
        }),
    ],
    10
  );
  const uploadedImagesData: scrapType['images'] = [];
  // Start the batch operation of saving these images in scrap
  for (let batchListItem of batchList) {
    const responses = await Promise.all(
      batchListItem.map((item) => {
        let variables: any = {};
        if (item.image.startsWith('https://')) {
          variables = {
            scrap_id: scrapId,
            order: item.order,
            desc: item.desc,
            file_path: item.image,
            is_heic: item.is_heic,
          };
        } else {
          variables = {
            scrap_id: scrapId,
            file_name: item.filename,
            file_path: item.image,
            order: item.order,
            desc: item.desc,
            is_heic: item.is_heic,
          };
        }
        if (item.id) variables.id = item.id;
        return client.mutate({
          mutation: UPSERT_SCRAP_IMAGE(`
            file_name
            file_path
            order
            file_status
            file_config
            desc
          `),
          variables,
        });
      })
    );
    for (let response of responses) {
      if (!response?.data?.upsertScrapImage?.messages?.success?.length) {
        throw new Error('Failed to save files!');
      }
      uploadedImagesData.push(response.data.upsertScrapImage.data);
    }
  }
  return uploadedImagesData;
}
