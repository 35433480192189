
// import * as mainConfig from '../settings.json';
import * as settingsConfig from '../settings.json';
const baseUrl: string =
  typeof window === 'object' ? window.location.hostname : '';
const mainConfig = {
  baseUrl,
};

const config = {
  image_size_limit: 30000000 /* IMAGE SIZE LIMIT 30 MB: 1024 * 1024 * 30 = 31457280 */,
  pdf_size_limit: 20000000 /* PDF SIZE LIMIT 20 MB */,
  video_size_limit: 1000000000 /* VIDEO SIZE LIMIT 1 GB */,
  max_video_duration: 600 /* MAX VIDEO DURATION 60 SECONDS */,
  cdn_dev_url:
    'https://cdn-dev.scrappi.com/' /* MAIN CDN URL FORM ALL IMAGES */,
  cdn_prod_url: 'https://cdn.scrappi.com/' /* MAIN CDN URL FORM ALL IMAGES */,
  fetch_Image_hit: 3,
  gallery_images_count_limit: 100,
  supported_images_accept_list: '.jpg, .jpeg, .png, .gif, .bmp, .webp, .svg, .heic'
};

export const collectionCoverImageRequirements = {
  minWidth: 684,
  minHeight: 415,
};

export const returnEnvironment = () => {
  if(settingsConfig.apiUrl.includes('dev')) return 'dev';
  else if(settingsConfig.apiUrl.includes('qa')) return 'qa'
  else if(settingsConfig.apiUrl.includes('staging')) return 'staging'
  else return 'production';
}

export const returnImagesCDN = () => {
  if (
    mainConfig.baseUrl.includes('dev') ||
    mainConfig.baseUrl.includes('staging') ||
    mainConfig.baseUrl.includes('localhost') ||
    mainConfig.baseUrl.includes('qa')
  ) {
    return config.cdn_dev_url;
  } else {
    return config.cdn_prod_url;
  }
};

export const returnCloudflareId = (video) => {
  let videoArr = video.split('/');
  if (Array.isArray(videoArr) && videoArr.length > 2) {
    let videoId = videoArr[videoArr.length - 1];
    if (videoId === 'watch') videoId = videoArr[videoArr.length - 2];
    return videoId;
  } else return null;
};

export const releaseNotes = [
  {
    date: '05/17/2022',
    extension: {
      header: 'Extension 1.5.0 Beta',
      list: [
        {
          subHeader: 'New features:',
          subList: [
            {
              header: 'Migration to Manifest 3',
              text:
                'The extension is now more secure with an update to the latest Manifest version as per Google’s latest extension guidelines.',
            },
            {
              header: 'Scrappi capture actions are moved to the toolbar',
              text:
                'There is a new place for all controls. Instead of the floating Scrappi icon on the page, all the controls are now in the extension toolbar. You can access the toolbar by clicking on the Scrappi extension icon on your browser’s address bar.',
            },
            {
              header: 'Enhanced cropping',
              text: 'Now you can edit images while uploading.',
            },
          ],
        },
        {
          subHeader: 'Fixes and Improvements:',
          subList: [
            {
              text: 'Improved device selector while recording screen.',
            },
          ],
        },
      ],
    },
    scrappi: {
      header: 'Scrappi.com 0.3.5 Beta',
      list: [
        {
          subHeader: 'New features:',
          subList: [
            {
              header: 'Create more types of Scraps from the dashboard',
              text:
                'Now you can upload a PDF file and a video file to create a Scrap.',
            },
          ],
        },
        {
          subHeader: 'Fixes and Improvements:',
          subList: [
            {
              header: 'Restructured URLs',
              text:
                'The URLs throughout Scrappi have been updated. If you have any URLs that you have shared with someone, they will no longer work, please share a new one.',
            },
            {
              header: 'Enhanced cropping ability throughout Scrappi',
              text: 'Now you can edit images while uploading.',
            },
          ],
        },
      ],
    },
  },
  {
    date: '04/20/2022',
    scrappi: {
      header: 'Scrappi.com 0.3.4 Beta',
      list: [
        {
          subHeader: 'New features:',
          subList: [
            {
              header: 'Organize Scraps',
              text:
                'Now you can change the setting of a Collection to show in Gallery, Story and Grid view. The Gallery view shows the Scraps as masonry. Story view is the vertical list of Scraps, showing one Scrap at a time. Grid view shows the Scraps in a grid layout. You can also organize the order of the Scraps inside a Collection by dragging and dropping or selecting them and moving to a particular position.',
            },
            {
              text:
                'More controls while uploading images. Now you can rotate and zoom images while uploading.',
            },
            {
              header: 'Create more types of Scraps from the dashboard',
              text:
                'Now you can also create web page, product & text Scraps from the dashboard. The creation process is now similar to how you would do it on the extension.',
            },
            {
              header: 'Stories is now removed from Scrappi',
              text: 'Your existing Stories will show up as Collections.',
            },
            {
              header: 'More controls for your Scraps',
              text:
                'Every Scrap now has three horizontal dots menu throughout Scrappi for more options.',
            },
          ],
        },
        {
          subHeader: 'Fixes and Improvements:',
          subList: [
            {
              header: 'Improved global search',
              text:
                'Now searching throughout Scrappi and your own Scraps and Collections is consolidated into one view, making finding what you are looking for even easier.',
            },
            {
              text: 'Interface improvement for editing a collection.',
            },
            {
              text: 'Improved main navigation.',
            },
          ],
        },
      ],
    },
  },
  {
    date: '03/22/2022',
    extension: {
      header: 'Extension 1.4.2 Beta',
      list: [
        {
          subHeader: 'New features:',
          subList: [
            {
              text: 'Ability to capture video from a webcam.',
            },
          ],
        },
        {
          subHeader: 'Bugs and improvements:',
          subList: [
            {
              text: 'Improved screen capture and upload video workflow.',
            },
            {
              text: 'Improved UI of various parts of the extension.',
            },
          ],
        },
      ],
    },
    scrappi: {
      header: 'Scrappi.com 0.3.3 Beta',
      list: [
        {
          subHeader: 'New features:',
          subList: [
            {
              header: 'Collaborate with other users in collections.',
              text:
                'You can now invite other people to collaborate with you on your collections with their email addresses. You can also invite someone who doesn’t already have a Scrappi account.',
            },
            {
              text:
                'Now you can add description and cover image to a collection.',
            },
          ],
        },
        {
          subHeader: 'Fixes and Improvements:',
          subList: [
            {
              text:
                'New URL structure for shared scraps, stories, and collections.',
            },
            {
              text: 'Improved sharing workflow.',
            },
          ],
        },
      ],
    },
  },
];


export enum FileCardType {
  PDF = 1,
  PPT = 2,
  XLS = 3,
  DOC = 4,
  TEXT = 5,
}
export const tagsLimit = 10;


export const charLimits = {
  default: 500,
  gallery: 500,
  note: 5000,
  annotation: 500,
  url: 500,
  screenshot: 500,
  product: 500,
  social: 500,
  video: 500,
  page: 500,
  section: 50,
  audio: 500,
  document: 500,
  storyDesc: 500,
  readOnly: 250,
  readOnlyForDetail: 600,
  characterLimitReachedMessage: 'Character limit reached! Please trim it down.',
  userBio: 250,
};

export const errorMessages = {
  unsupportedFiles: 'File type is invalid',
}

export const OTP_LENGTH = 6;

export const LOGIN_VIA = {
  manual: 1,
  google: 2,
  facebook: 3,
  apple: 4,
  phone_number: 5,
  email: 6,
};

export const PROFILE_SETUP_PATH = '/profile-setup';
export const INVITE_USER_PATH = '/register-user';

export const STORAGE_KEYS = {
  LOGIN_ERROR: 'loginError',
  LOGIN_SUCCESS: 'loginSuccess',
  THEME: 'theme',
  WEBVIEW_PLATFORM: 'webview_platform',
};

export default config;
