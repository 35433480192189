import React, { Fragment, useState } from 'react';
import useWindowOnClick from '../../../../../../helpers/customHooks/useWindowOnclick';
import Icon from '../../../../../Global/Icon';
import {
  GalleryViewIcon,
  GridViewIcon,
  StoryViewIcon,
} from '../../../../../Global/icons';

import './styles.css';

interface Proptypes {
  value: number;
  onChange: (val: number) => void;
  onSortChange?: (val: string) => void;
  dropUp?: boolean;
  sortValue?: string;
  sortBy?: string;
}

const CollectionViewDropdown = ({
  value,
  onChange,
  dropUp,
  sortValue,
  onSortChange,
  sortBy,
}: Proptypes) => {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [showing, setShowing] = useState('');
  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement = el.closest('.collection-view');
    if (!closestElement) setShow(false);
  };
  useWindowOnClick({
    cb: handleOutSideClick,
    removeListener: !show,
  });
  const viewOptions = [
    {
      title: 'Board View',
      label: 'Board View',
      value: 1,
      message: 'This Collection will display in a grid.',
      renderIcon: () => <GalleryViewIcon />,
      type: 'view',
    },
    {
      title: 'Grid View',
      label: 'Grid View',
      value: 2,
      message:
        'This Collection will display Scraps with rows having fixed height.',
      renderIcon: () => <GridViewIcon />,
      type: 'view',
    },
    {
      title: 'Story View',
      label: 'Story View',
      value: 3,
      message: 'This Collection will display in a single column.',
      renderIcon: () => <StoryViewIcon />,
      type: 'view',
    },
  ];

  const sortOptions = [
    {
      title: 'Custom',
      label: 'Custom Sort',
      value: ' ',
      type: 'sort',
    },
    {
      title: 'Oldest',
      label: 'Chronological',
      value: 'asc',
      type: 'sort',
    },
    {
      title: 'Newest',
      label: 'Recently Added',
      value: 'desc',
      type: 'sort',
    },
  ];

  // const currentOption = options.find((option) => option.value === +value);
  const currentSortOption = sortOptions.find(
    (option) => option.title === sortBy
  );
  const currentViewOption = viewOptions.find(
    (option) => option.value === (+value || 1)
  );
  return (
    <div className="collection-view">
      <div className="collection-view__display-options">
        <span className="collection-view__display-options__title">
          Sort Scraps by:
        </span>
        <div
          className={`collection-view__current${
            show && showing === 'sort'
              ? ' collection-view__current--active'
              : ''
          }`}
          // onClick={setShow.bind(null, !show)}
          onClick={() => {
            setShow(!show);
            setOptions(sortOptions);
            setShowing('sort');
          }}
        >
          {/* <span className="collection-view__current-icon">
          {currentOption?.renderIcon()}
        </span> */}
          <p className="collection-view__current-title">
            {currentSortOption?.label}
          </p>
          <span className="collection-view__current-expand-icon">
            <Icon iconClass="chevron-expand" size="normal" />
          </span>
        </div>
      </div>
      <div className="collection-view__display-options">
        <span className="collection-view__display-options__title">
          Display type:
        </span>
        <div
          className={`collection-view__current${
            show && showing === 'view'
              ? ' collection-view__current--active'
              : ''
          }`}
          // onClick={setShow.bind(null, !show)}
          onClick={() => {
            setShow(!show);
            setOptions(viewOptions);
            setShowing('view');
          }}
        >
          <span className="collection-view__current-icon">
            {currentViewOption?.renderIcon()}
          </span>
          <p className="collection-view__current-title">
            {currentViewOption?.title}
          </p>
          <span className="collection-view__current-expand-icon">
            <Icon iconClass="chevron-expand" size="normal" />
          </span>
        </div>
      </div>
      {show && (
        <div
          className={`collection-view__dropdown${
            dropUp
              ? ` collection-view__dropdown--upside ${
                showing === 'sort'
                  ? 'collection-view__dropdown--upside-sort'
                  : 'collection-view__dropdown--upside-view'
              }`
              : ''
          }`}
        >
          {options.map((option, i) => (
            <Fragment key={option.title}>
              <div
                onClick={() => {
                  option?.type === 'sort' && onSortChange(option.title);
                  option?.type === 'view' && onChange(option.value);
                  setShow(false);
                }}
                className={`collection-view__item${
                  option.title === sortBy || option.value === (+value || 1)
                    ? ' collection-view__item--active'
                    : ''
                }`}
              >
                <div className="collection-view__item-content">
                  {showing === 'view' && (
                    <span className="collection-view__item-icon">
                      {option?.renderIcon()}
                    </span>
                  )}
                  <h4 className="collection-view__item-title">
                    {option.label}
                  </h4>
                </div>
              </div>
              {i + 1 < options.length && (
                <div className="collection-view__separator"></div>
              )}
            </Fragment>
          ))}
        </div>
      )}

      {/* <select
        value={value}
        onChange={(e) => {
          onChange(e.target.value as CollectionViewModeType);
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default CollectionViewDropdown;
