import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int!'],
  ['groups', '[InputGroupId]'],
  ['members', '[InputMemberId]'],
  ['collections', '[InputGroupCollectionId]'],
]);

export default function GROUP_SHARE(responseString = '') {
  return gql`
    mutation GroupShare(${parametersTypeString}){
      groupShare(${parametersAssignString}){
          ${defaultResponse}
          ${responseString}
        }
    }
  `;
}
