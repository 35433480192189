import React from 'react';

interface Proptypes {
  className?: string;
}

const Replace = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.42492 13.7464L5.01428 13.8828C5.57658 14.0129 6.12393 13.6561 6.23682 13.0858C6.34972 12.5156 5.98541 11.9479 5.42312 11.8178L2.52571 11.1474C2.00692 11.0274 1.49278 11.3222 1.33197 11.8318L0.538498 14.3464C0.364196 14.8988 0.665832 15.5025 1.21222 15.6947C1.75861 15.887 2.34284 15.5951 2.51714 15.0427L2.60413 14.767C3.81858 16.4175 5.4478 17.4296 6.77034 17.895C8.41453 18.4736 10.8354 18.6486 13.1704 17.4818C15.5126 16.3115 16.8417 14.202 17.3633 12.549C17.5376 11.9966 17.236 11.3929 16.6896 11.2007C16.1432 11.0084 15.559 11.3003 15.3847 11.8527C14.9953 13.0867 13.9764 14.6962 12.2176 15.575C10.4517 16.4575 8.61303 16.321 7.40154 15.8947C6.47326 15.568 5.32234 14.8642 4.42492 13.7464ZM12.5134 7.22329C11.9511 7.0932 11.5868 6.52547 11.6997 5.95524C11.8126 5.385 12.36 5.02819 12.9223 5.15828L13.5116 5.29464C12.6142 4.17688 11.4633 3.47305 10.535 3.14641C9.32352 2.7201 7.48488 2.58363 5.71894 3.46603C3.96018 4.34485 2.94126 5.95443 2.55189 7.18837C2.37759 7.74075 1.79336 8.03268 1.24697 7.84041C0.700584 7.64815 0.398948 7.04449 0.573249 6.49211C1.09487 4.83904 2.42395 2.72959 4.76616 1.55924C7.1012 0.392462 9.52203 0.567484 11.1662 1.14606C12.4887 1.61144 14.118 2.62354 15.3324 4.27406L15.4194 3.9984C15.5937 3.44601 16.1779 3.15408 16.7243 3.34635C17.2707 3.53862 17.5724 4.14228 17.3981 4.69466L16.6046 7.20926C16.4438 7.7189 15.9296 8.01365 15.4108 7.89363L12.5134 7.22329Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default Replace;
