type ActionType = {
  type: string;
  canPaste: boolean;
}

const pasteData = (state = true, action: ActionType) => {
  switch (action.type) {
    case 'CAN_PASTE':
      return action.canPaste;
    default:
      return state;
  }
}

export default pasteData;