import React, { useState, useRef } from 'react';
import './styles.css';
import { Link, useHistory } from 'react-router-dom';
import AuthHeaderLayout from './components/AuthHeaderLayout';
import { PasswordStateType } from './types/PasswordStateType';
import LabelTextInput from '../Global/LabelTextInput';
import { validatePassword } from '../../helpers/validators/passwordValidators';
import { CrossSquareIcon, TickSquareIcon } from '../Global/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from "../Global/InvisibleRecaptcha";
import ValidateHuman from '../../helpers/validators/isHuman';

interface Proptypes {
  onSubmit: (
    password: string,
    onError: (errorMessage: string) => void,
    onSuccess: () => void
  ) => Promise<void>;
}

const AccountRecovery = (props: Proptypes) => {
  const { onSubmit } = props;
  const history = useHistory();
  const smallPasswordError = 'Password must match the specified rules';
  const [password, setPassword] = useState<PasswordStateType>({
    gotFocused: false,
    value: '',
    show: false,
    error: null,
  });
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  async function submitHandler() {
    if (password.error || password.value.trim() === '') return;
    const isHuman= await ValidateHuman(reCaptchaRef.current);
    if(isHuman){
      setLoading(true);
      await onSubmit(password.value, setErrorMessage, () => setSuccess(true));
      setLoading(false);
    }
  }

  //   If the password change request got success then, render Successs message with a button to
  //   let the user to go back to login page. Else render the form page for changing the password
  if (success)
    return (
      <AuthHeaderLayout title="Reset Password" helmetTitle="Scrappi">
        <div>
          <h4>
            Password has been changed successfully. Now you can login with your
            new password.
          </h4>
          <div className="form-group form-group__action">
            <button
              className="button button__primary"
              onClick={() => history.replace('/login')}
            >
              Back to login
            </button>
          </div>
        </div>
      </AuthHeaderLayout>
    );

  const disableSubmit =
    loading || password.value.trim() === '' || !!password.error;

  return (
    <AuthHeaderLayout title="Reset Password" helmetTitle="Scrappi">
      <>
        <h2 className="sl-auth__heading mb">Reset password</h2>
        <div className="form-group mt-10">
          <InvisibleRecaptcha inputRef={reCaptchaRef}/>
          <LabelTextInput
            placeholder="New Password"
            inputType={'password'}
            inputName={'password'}
            value={password.value}
            label="New Password"
            className={`input-field ${errorMessage ? 'is-invalid' : ''}`}
            onChange={(newPass) => {
              setPassword((old) => ({
                ...old,
                value: newPass,
                error: validatePassword(newPass) ? '' : smallPasswordError,
              }));
            }}
            required
            showLabelOnFocus
            togglePass={true}
          />
          <ul className="sl-auth__input-requirements">
            <li>
              <span className="sl-auth__input-error">
                {validatePassword(
                  password.value,
                  'minimum_8_character_length'
                ) ? (
                    <TickSquareIcon />
                  ) : (
                    <CrossSquareIcon />
                  )}
              </span>
              <span className="sl-auth__input-validation_message">
                Contains a minimum 8 characters
              </span>
            </li>
            <li>
              <span className="sl-auth__input-error">
                {validatePassword(password.value, 'atleast_one_mix_letter') ? (
                  <TickSquareIcon />
                ) : (
                  <CrossSquareIcon />
                )}
              </span>
              <span className="sl-auth__input-validation_message">
                Contains at least one mixed case letter
              </span>
            </li>
            <li>
              <span className="sl-auth__input-error">
                {validatePassword(password.value, 'atleast_one_number') ? (
                  <TickSquareIcon />
                ) : (
                  <CrossSquareIcon />
                )}
              </span>
              <span className="sl-auth__input-validation_message">
                Contains at least one number
              </span>
            </li>
          </ul>
        </div>

        <div className="form-group form-group__action">
          <button
            className="button button__primary"
            disabled={disableSubmit}
            onClick={submitHandler}
          >
            Submit
          </button>
        </div>

        <div className="sl-form-check">
          <Link to="/login" className="button-link">
            Go back to Log In
          </Link>
        </div>
      </>
    </AuthHeaderLayout>
  );
};

export default AccountRecovery;
