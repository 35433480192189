import React, { useEffect, useState } from "react";
import OnboardingWelcome from "./components/OnboardingWelcome";
import OnboardingInstruction from "./components/OnboardingInstruction";
import OnboardingNewScrap from "./components/OnboardingNewScrap";
import OnboardingBackToScrap from "./components/OnboardingBackToScrap";

interface Proptypes {
  type: 'welcome' | 'instruction' | 'newScrap' | 'backToScrap';
  runOnboarding?: boolean;
  hasScraps?: boolean;
  reloadScraps?: () => void;
}

const Onboarding = ( { type, runOnboarding, hasScraps, reloadScraps }: Proptypes ) => {
  /**
   * There are browser specific functions that are not available in the node environment.
   * don't render the onboarding on server
   */
  if (typeof window === 'undefined') {
    return null;
  }

  switch (type) {
    case 'welcome': {
      return <OnboardingWelcome run={runOnboarding} />;
    }
    case 'instruction': {
      return <OnboardingInstruction hasScraps={hasScraps} reloadScraps={reloadScraps} />;
    }
    case 'newScrap': {
      return <OnboardingNewScrap run={runOnboarding} />;
    }
    case 'backToScrap': {
      return <OnboardingBackToScrap run={runOnboarding} />;
    }
    default: {
      return null;
    }
  }
}

export default Onboarding;