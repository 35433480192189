import { scroller, animateScroll } from "react-scroll";

// excluded React component syntax...

const scrollToSection = (refClass) => {
  scroller.scrollTo(refClass, {
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  });
};

const scrollDivTopBottom = (containerID, scrollType) => {
  var options = {
    containerId: containerID,
    duration: 800,
    delay: 0,
    smooth: "easeInOutQuart",
  };

  if (scrollType === "top") {
    animateScroll.scrollToTop(options);
  }
  else if (scrollType === "bottom") {
    animateScroll.scrollToBottom(options);
  }
};

export default scrollToSection;
export {
  scrollDivTopBottom
}