import React, { Fragment, useState } from 'react';
import { SortIcon } from '../icons';
// import { useDispatch, useSelector } from 'react-redux';

import useWindowOnClick from '../../../helpers/customHooks/useWindowOnclick';
// import { setWarningPopup } from '../../../redux/action/utils';
interface Proptypes {
  onClick?: () => void;
  options?: string[];
  onOptionClick: (val: string) => void;
  activeOption: string;
  setOnPathNameChange?:(boolean)=>void;
}

// DEFAUL SORT CONFIG OPTIONS
export const defaultSortConfig = {
  'Last edited': {
    sort_param: 'updated_at',
    sort_by: 'desc',
  },
  Newest: {
    sort_param: 'created_at',
    sort_by: 'desc',
  },
  Custom: {
    sort_param:'',  
    sort_by: ''
  }
};
const ScrapControlsSort = ({ onClick, options, onOptionClick, activeOption,setOnPathNameChange }: Proptypes) => {
  if(!options) {
    options = Object.keys(defaultSortConfig);
  }
  // const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement = el.closest('.scrap-controls__view-dropdown');
    if (!closestElement) setShow(false);
  };
  useWindowOnClick({ cb: handleOutSideClick, removeListener: !show });
  return (
    <Fragment>
      <button
        onClick={() => setShow(!show)}
        className={`scrap-controls__control-item-inner-container hoverState-text-svg ${
          show ? ' scrap-controls__control-item-inner-container--active' : ''
        }`}
      >
        <SortIcon className="scrap-controls__control-item-icon" />
        <span className="scrap-controls__control-item-text">Sort</span>
      </button>
      {show && (
        <div
          className="scrap-controls__view-dropdown scrap-controls__view-dropdown--leaner"
          id="scrap-controls-sort-dropdown"
          onBlur={() => {
            setShow(false);
          }}
        >
          <div className="scrap-controls__view-dropdown-list-container">
            {/* <p className="scrap-controls__view-dropdown-label">{label}</p> */}
            <ul className="scrap-controls__view-dropdown-list">
              {options.map((option, i) => (
                <li
                  onClick={() => {
                    if (activeOption === option) return;
                    setShow(false);
                    onOptionClick(option);
                    // Commented below code to remove warning prompt on changing sort 
                    // Uncomment back to enable it
                    // setOnPathNameChange && setOnPathNameChange(false);
                    // if(activeOption ==='Custom' && option !=='Custom'){
                    //   dispatch(
                    //     setWarningPopup({
                    //       warningMessage:"Are you sure? This Collection has a Custom sort applied. Changing the sort order will delete the Custom sort.",
                    //       header:"Warning",
                    //       cancel:{
                    //         label:"Cancel",
                    //       },
                    //       submit:{
                    //         label:"OK",
                    //         cb:()=>{
                    //           onOptionClick(option);
                    //           setOnPathNameChange && setOnPathNameChange(false);
                    //         }
                    //       }
                    //     })
                    //   ) 
                    // }else{
                    //   onOptionClick(option);
                    //   setOnPathNameChange && setOnPathNameChange(false);
                    // }
                    
                  }}
                  className={`scrap-controls__view-dropdown-list-item${
                    activeOption === option
                      ? ' scrap-controls__view-dropdown-list-item--active'
                      : ''
                  }`}
                  key={i}
                >
                  {/* {iconConfig[option].renderIcon(
                    'scrap-controls__view-dropdown-icon'
                  )} */}
                  <span className="scrap-controls__view-dropdown-item-text">
                    {option}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          {/* {size && renderList('Size')} */}
          {/* {layout && renderList('Layout')} */}
        </div>
      )}
    </Fragment>
  );
};

export default ScrapControlsSort;
