import React from 'react';
import SEOMeta from "../../Meta/SEOMeta";

interface NotificationCountProps {
  newNotificationsCount: number;
}
const NotificationCount = (props: NotificationCountProps) => {
  const { newNotificationsCount } = props;

  // check if it is not server and get the title and add the count to it
  const isServer = typeof window === 'undefined';

  if(isServer) {
    return null;
  }

  if (newNotificationsCount > 0) {
    // get the title and add the count to it
    // add only if the title doesn't already have the count
    const title = document.title;
    if (!title.startsWith(`(${newNotificationsCount})`)) {
      // it already has a count, so replace it
      if (title.includes(`(`)) {
        document.title = title.replace(/\(\d+\)/, `(${newNotificationsCount})`);
      } else {
        document.title = `(${newNotificationsCount}) ${title}`;
      }
    }

    return (
      <span className="notification-list__count">
        {newNotificationsCount}
      </span>
    );
  } else {
    // reset the title if there are no new notifications
    const title = document.title;
    if (title.includes(`(`)) {
      document.title = title.replace(/\(\d+\)/, '');
    }
  }

  return null;
}

export default NotificationCount;