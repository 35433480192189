import React, { forwardRef, Fragment, useState, useEffect } from 'react';
import './styles.css';
import {
  CollabMemberActionType,
  CollectionCollaboratorType,
} from '../../../../types/collaboration';
import Dropdown from '../../../Global/Dropdown/';
import { returnRoleType } from '../../../../helpers/collectionCollaborationHelpers';
import { RequestStatusType } from '../../../../types/requestStatusType';
import CollectionHeaderAvatar from '../../../CollectionCreationWrapper/components/CollectionCreationHeader/CollectionHeaderAvatar';
import { useHistory } from 'react-router-dom';

interface Proptypes {
  email: string;
  role: 'Contributor' | 'Owner' | 'Viewer';
  avatar: string;
  firstName: string;
  displayName:string;
  creator_initials?:string;
  lastName: string;
  activeUser: CollectionCollaboratorType;
  userName: string;
  hasAcceptedInvite: boolean;
  itemClassName?: string;
  onMemberDropDownClick: (action: CollabMemberActionType) => Promise<void>;
  collectionTitle: string;
  requestStatus: {
    type: RequestStatusType;
    message: string;
    user_email?: string;
  };
  firstTimeInvitation?: boolean;
  onMemberClick?: () => void
}
const CollectionCollaborationMemberItem = forwardRef(
  (
    {
      role,
      email,
      activeUser,
      avatar,
      creator_initials,
      firstName,
      lastName,
      displayName,
      userName,
      itemClassName,
      onMemberDropDownClick,
      collectionTitle,
      hasAcceptedInvite,
      requestStatus,
      firstTimeInvitation,
      onMemberClick,
    }: Proptypes,
    ref
  ) => {
    const isMySelf = userName === activeUser.username;
    const memberFullName = ((firstName || '') + ' ' + (lastName || '')).trim();
    const activeUserRole = returnRoleType(+activeUser.role.role_id || 0);
    const [isResend, setIsResend] = useState(false);
    const showResend = activeUserRole === 'Owner' && !hasAcceptedInvite;
    useEffect(() => {
      if (
        requestStatus?.type.toLowerCase() === 'success' &&
        requestStatus?.user_email === email &&
        !firstTimeInvitation
      ) {
        setIsResend(true);
      } else if (
        requestStatus?.type.toLowerCase() === 'success' &&
        requestStatus?.user_email === email &&
        firstTimeInvitation
      ) {
        setIsResend(false);
      }
    }, [requestStatus]);

    function renderRights() {
      const unEditableEl = (
        <div className="collaboration-member__rights__uneditable">
          <span>{hasAcceptedInvite ? role : 'Invited'}</span>
        </div>
      );
      let list = [];

      if (activeUserRole === 'Owner') {
        if (role === 'Owner') {
          return unEditableEl;
        } else if (role === 'Contributor') {
          if (!hasAcceptedInvite) {
            list = [
              // { label: 'Invitation Sent', id: 'invite-sent', disabled: true },
              // { label: 'Resend Invitation', id: 'resend-invitation' },
              {
                label: 'Remove',
                id: 'remove-invitation',
              },
            ];
          } else
            list = [
              { label: role, id: 'collab-type', disabled: true },
              {
                label: 'Remove',
                id: 'remove-member',
              },
            ];
        } else {
          if (!hasAcceptedInvite) {
            list = [
              {
                label: 'Remove',
                id: 'remove-invitation',
              },
            ];
          } else
            list = [
              { label: role, id: 'collab-type', disabled: true },
              {
                label: 'Remove',
                id: 'remove-member',
              },
            ];
        }
      } else if (activeUserRole === 'Contributor') {
        if (isMySelf) {
          list = [
            { label: role, id: 'collab-type', disabled: true },
            {
              label: 'Remove Myself',
              id: 'remove-myself',
            },
          ];
        } else return unEditableEl;
      } else if (activeUserRole === 'Viewer') {
        if (isMySelf) {
          list = [
            { label: role, id: 'collab-type', disabled: true },
            {
              label: 'Remove Myself',
              id: 'remove-myself',
            },
          ];
        } else return unEditableEl;
      } else {
        return unEditableEl;
      }
      return (
        <Dropdown
          list={list}
          onSelect={selectHandler}
          placeholder={role ? role : 'Invitation Sent'}
          ref={ref}
        />
      );
    }
    function selectHandler(val: { label: string; id: string }) {
      onMemberDropDownClick(val.id as CollabMemberActionType);
    }

    function resendButtonClickHandler() {
      selectHandler.bind(null, {
        label: 'Resend Invitation',
        id: 'resend-invitation',
      });
      // setResendedEmail(email);
    }
    return (
      <div
        className={`collaboration-member${
          itemClassName ? ' ' + itemClassName : ''
        }`}
      >
        <div className={`collaboration-member__user-wrapper${onMemberClick ? ' collaboration-member__user-wrapper--clickable' : ''}`}>
          {
            <CollectionHeaderAvatar
              className={`collaboration-member__header-avatar ${
                !creator_initials && !avatar ? 'collaboration-member__header-avatar--hide':""
              }`}
              image={avatar}
              text={creator_initials}
              onClick={onMemberClick}
            />
          }

          <div className="collaboration-member__user-wrapper__names"  onClick={onMemberClick}>
            {!hasAcceptedInvite ? (
              <p className="collaboration-member__user-wrapper__names__email">
                {email}
              </p>
            ) : (
              <Fragment>
                {displayName && <p className="collaboration-member__user-wrapper__names__full-name">
                  {displayName}
                </p>}
                <p className="collaboration-member__user-wrapper__names__user-name">
                  @{userName}
                </p>
              </Fragment>
            )}
          </div>
        </div>
        <div className="collaboration-member__actions">
          <div
            id={`collab-rights-${userName}`}
            className="collaboration-member__rights"
          >
            {renderRights()}
          </div>
          {showResend && (
            <div className="collaboration-member__resend">
              <span
                className={` ${
                  isResend
                    ? 'collaboration-member__resend__resended'
                    : 'collaboration-member__resend-message'
                }`}
              >
                Invitation {isResend ? 'resent!' : 'sent'}
              </span>{' '}
              <button
                className="collaboration-member__resend-button"
                disabled={requestStatus?.type == 'processing'}
                onClick={selectHandler.bind(
                  null,
                  (() => {
                    return {
                      label: 'Resend Invitation',
                      id: 'resend-invitation',
                    };
                  })()
                )}
              >
                Resend
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default CollectionCollaborationMemberItem;
