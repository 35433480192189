import React from 'react';
import { Twitter } from './Twitter';
import Youtube from './Youtube';
import './CardSocial.css';
import { Instagram } from './Instagram';
import { TikTok } from './TikTok';
import { Pinterest } from './Pinterest';
import { LinkedInProfile } from './LinkedInProfile';
import { entryTypes } from '../../../types/feed';
import { storyView } from '../../../types/stories';
import { CardViewType } from '../types/cardView';
import CardWrapper from '../CardWrapper';
import { Facebook } from './Facebook';
import { parseSocialMeta } from '../../../helpers/parseSocialMeta';
import { cardSettingsConfigType } from '../CardComponents/CardSettings';
import CardLinkPreview from '../CardComponents/CardLinkPreview';
interface SocialEmbedProptype {
  service: 'twitter' | 'youtube' | string;
  meta: string;
  entry?: entryTypes;
  activeStoryView?: storyView;
  showEmbed?: boolean;
  preview?: boolean;
  source?: string;
  search?: string;
  editing?: boolean;
  handleStoryConfig?: (type: 'title' | 'notes', val: boolean) => void;
  addPanelDirectly?: (pid: string) => Promise<void>;
  view?: CardViewType;
  gridWidth?: number;
  onCardClick?: (entry: entryTypes) => boolean;
  showProfile?: boolean;
  onCardResize?: () => void;
  collectionId?:string;
  reloadCollection?: () => void;
  iAmCollaborator?:boolean;
  collectionOwner?:string;
}
interface PropType extends SocialEmbedProptype {
  cardSettingsConfig?: cardSettingsConfigType;
  onEditClick?: (val?:boolean) => void;
  onDeleteClick?: () => void;
}

export const renderSocialEmbed = (entry: {meta?: string; service?: string; thumbnail?: string}, preview?: boolean) => {
  const { meta, service, thumbnail } = entry;
  try {
    const parsedData = JSON.parse(meta);
    if (!parsedData) throw new Error('parsing data failed');
    switch (service) {
      case 'twitter': {
        return <Twitter meta={meta} />;
      }

      case 'youtube': {
        return <Youtube meta={meta} />;
      }

      case 'instagram': {
        return <Instagram meta={meta} />;
      }

      case 'facebook': {
        return <Facebook meta={meta} />;
      }

      case 'tiktok': {
        return <TikTok meta={meta} />;
      }

      case 'pinterest': {
        return <Pinterest meta={meta} />;
      }

      case 'linkedIn':
        return (
          <LinkedInProfile
            thumbnail={thumbnail}
          />
        );

      default:
        return null;
    }
  } catch (error) {
    return <></>;
  }
};

/**
 * return the right component based on the social service
 */
const CardSocial = ({
  cardSettingsConfig,
  service,
  meta,
  entry,
  activeStoryView,
  showEmbed,
  preview,
  source,
  search,
  addPanelDirectly,
  handleStoryConfig,
  editing,
  view,
  gridWidth,
  onCardClick,
  showProfile,
  onCardResize,
  onEditClick,
  onDeleteClick,
  collectionId,
  reloadCollection,
  iAmCollaborator,
  collectionOwner
}: PropType) => {
  return (
    <CardWrapper
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      settingsConfig={cardSettingsConfig}
      containerClass={`card-base card__social card_${service}`}
      view={view}
      entry={entry}
      activeStoryView={activeStoryView}
      source={source}
      search={search}
      addPanelDirectly={addPanelDirectly}
      handleStoryConfig={handleStoryConfig}
      editing={editing}
      dontRenderItemMeta={view === 'detailFullscreen'}
      onCardClick={onCardClick}
      showProfile={showProfile}
      collectionId={collectionId}
      reloadCollection={reloadCollection}
      iAmCollaborator={iAmCollaborator}
      collectionOwner={collectionOwner}
    >
      {(onMoreClick) => (
        <div className={`card__social--service ${service}`}>
          <CardLinkPreview
            onMoreClick={onMoreClick}
            imageClickConfigData={entry}
            linkData={{
              image: entry.thumbnail,
              link: entry.updated_url || entry.url,
              domain: entry.domain,
              title: entry.meta_title,
              description: entry.meta_desc,
              favicon: entry.favicon || entry.original_favicon,
            }}
            gridWidth={gridWidth}
            config={entry.config}
            entry={entry}
            view={view}
          />
        </div>
      )}
    </CardWrapper>
  );
};

export default CardSocial;
