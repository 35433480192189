import { gql } from '@apollo/client';

export const BULK_UPLOAD = gql`
  mutation BulkUpload($files: [Upload], $uploads: [FileUploadInput]) {
    bulkUpload(files: $files, uploads: $uploads) {
      order
      name
    }
  }
`;
