import React from 'react';
import './styles.css';
import { BatchEditType } from '../../../types/batchEdit';
import { useSelector } from 'react-redux';
interface Proptypes {
  // eslint-disable-next-line no-undef
  children?: JSX.Element | JSX.Element[];
  scrapCount: number ;
  countLabel?: string;
  loading?: boolean;
  hideCount?: boolean; // This will be used to hide the count when no scraps are present
  view?: string;
}

const ScrapControls = ({
  children,
  scrapCount,
  countLabel,
  loading,
  hideCount,
  view,
}: Proptypes) => {
  const label = countLabel || 'Scrap';
  const { mode: batchEditMode }: BatchEditType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.batchEdit
  );
  const returnLabel = () => {
    if (scrapCount > 1) {
      return label + 's';
    }

    return label;
  };

  // if (scrapCount == 0) return null;

  const renderControls = (item) => {
    if (view === 'collectionView') {
      if (item.key === 'scrap-controls-sort') {
        return item;
      } else if(item.key==="scrap-controls-view"){
        return null;
      }
      else{
        return item
      }
      
    } else {
      return item;
    }
  };
  return (
    <div
      className={`scrap-controls ${
        batchEditMode ? 'scrap-controls__hide' : ''
      }`}
    >
      <p className="scrap-controls__scrap-count">
        {((hideCount && scrapCount > 0) || !hideCount) &&
          !loading &&
          `${scrapCount} ${returnLabel()}`}
      </p>
      <ul className="scrap-controls__controls">
        {children &&
          ((hideCount && scrapCount > 0) || !hideCount) &&
          !loading &&
          React.Children.map(children, (item, i) =>
            item ? (
              <li
                className={`scrap-controls__control-item${
                  item.key === 'scrap-controls-view'
                    ? ' scrap-controls__control-item--hide-mobile'
                    : ''
                }`}
                key={i}
              >
                {renderControls(item)}
              </li>
            ) : (
              ''
            )
          )}
      </ul>
    </div>
  );
};

export default ScrapControls;
