import { getDomainWithoutSubdomain } from "./getDomainWithoutSubdomain";

const validSoicalURL = (label?: string, val?: string) => {
  const regexes = {
    facebook: /^https?:\/\/(?:www\.)?facebook\.com\/profile\.php\?id=\d+$/,
    facebookMobile:/^https?:\/\/(?:www\.)?facebook\.com\/[a-zA-Z0-9.]+(?:\?[\w=&]*)?$/,
    instagram: /^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.-]+\/?$/,
    instagramMobile:/(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/,
    twitter: /^https?:\/\/(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/,
    twitterMobile: /^https?:\/\/(?:www\.)?twitter\.com\/[A-Za-z0-9_]{1,15}\?t=[A-Za-z0-9_-]{22}&s=09$/,
    youtube: /^https?:\/\/(www\.)?youtube\.com\/(channel\/|user\/)?[a-zA-Z0-9_-]+\/?$/,
    youtubeMobile: /^https?:\/\/(www\.)?youtube\.com\/@?[a-zA-Z0-9_\-]{1,}$/,
    linkedin: /^https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
    pinterest: /^https?:\/\/(?:[a-z]{2}\.)?pinterest\.com\/[a-zA-Z0-9_]+\/?$/,
    pinterestMobile: /^https?:\/\/pin\.it\/[a-zA-Z0-9]{7}$/
  };
  switch (label) {
    
    case 'Facebook':
      if (regexes.facebook.test(val)||regexes.facebookMobile.test(val)) {
        return true;
      }
      break;
    case 'Pinterest':
      if (regexes.pinterest.test(val)||regexes.pinterestMobile.test(val)||(getDomainWithoutSubdomain(val)===label.toLowerCase()+".com")) {
        return true;
      }
      break;
    case 'Instagram':
      if (regexes.instagram.test(val)||regexes.instagramMobile.test(val)) {
        return true;
      }
      break;
    case 'Youtube':
      if (regexes.youtube.test(val)||regexes.youtubeMobile.test(val)) {
        return true;
      }
      break;
    case 'LinkedIn':
      if (regexes.linkedin.test(val)) {
        return true;
      }
      break;
    case 'Twitter':
      if (regexes.twitter.test(val)||regexes.twitterMobile.test(val)) {
        return true;
      }
      break;
    default:
      return false;
  }
};

export default validSoicalURL;
