import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { FACEBOOK_LOGIN_MUTATION } from '../../../../containers/Login';
import { useMutation } from '@apollo/client';
import Account_Messages from '../Messages';
import * as config from '../../../../settings.json';
import FacebookLogin from 'react-facebook-login';
import { performNativeSocialLogin, setToken } from '../../../../helpers';
import { LOGIN_VIA } from '../../../../helpers/config';
import { ProfileType } from '../../types/ProfileType';
import './styles.css';

export interface FacebookProfile {
  name: string;
  facebookId: string;
  email: string;
  social_token: string;
  login_via: number;
  userID: string;
}

interface Proptypes {
  processing?: boolean;
  label?: string;
  setProcessing?: (val: boolean) => void;
  registerRedirect?: (profileObj, emailExist?: boolean) => void;
  text?: string;
  handleRedirection?: (onboard_status?: boolean) => void;
  setMainLoading?: Dispatch<SetStateAction<boolean>>;
  overrideSubmit?: boolean;
  onSuccess?: (data?: ProfileType) => void;
}

const FacebookButton = (props: Proptypes) => {
  const {
    registerRedirect,
    text,
    handleRedirection,
    setMainLoading,
    overrideSubmit,
    onSuccess,
  } = props;
  const [facebookLoginMutation] = useMutation(FACEBOOK_LOGIN_MUTATION);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [provider, setProvider] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profile, setProfile] = useState<any>();
  // For managing FB button state
  const [isDisabled, setIsDisabled] = useState(false);
  const [doRenderAgain, setDoRenderAgain] = useState(false);

  const fetchEmailFromFacebook = async (
    accessToken: string,
    profileObj: FacebookProfile
  ) => {
    if (accessToken && profileObj?.userID) {
      var api =
        'https://graph.facebook.com/v2.8/' +
        profileObj.userID +
        '?fields=name,email&access_token=' +
        accessToken;
      try {
        const additionalData = await (await fetch(api))?.json();
        if (additionalData?.email) {
          return additionalData?.email;
        }
      } catch (e) {
        setMainLoading && setMainLoading(false);
      }
    }
    return '';
  };

  const responseFacebook = async (
    accessToken: string,
    profileObj: FacebookProfile
  ) => {
    setMainLoading && setMainLoading(true);
    const userProfile = { ...profileObj };

    if (!userProfile.email) {
      userProfile.email = await fetchEmailFromFacebook(
        accessToken,
        userProfile
      );
    }

    if (accessToken) {
      if (overrideSubmit) {
        onSuccess({
          social_token: accessToken,
          email: userProfile.email,
          login_via: LOGIN_VIA.facebook,
          social_id: userProfile.userID,
        });
      } else {
        facebookLoginMutation({
          variables: {
            email: userProfile.email,
            social_token: accessToken,
            login_via: LOGIN_VIA.facebook,
            social_id: userProfile.userID,
          },
        }).then(async (val) => {
          var response = val.data.login;
          if (response.data.access_token) {
            await setToken(response.data.access_token, response.data.user?.id);
            if (handleRedirection) {
              handleRedirection(response.data.user?.profile?.onboard_status);
            } else {
              window.location.replace('/');
            }
          } else {
            let emailExist =
              response.messages.error[0] === Account_Messages.accountNotExists;
            setMainLoading && setMainLoading(false);
            registerRedirect && registerRedirect(userProfile, emailExist);
          }
        });
      }
    }
  };

  const returnFacebookButton = useMemo(() => {
    if (doRenderAgain) {
      setDoRenderAgain(false);
    }
    return (
      <FacebookLogin
        appId={config.AppID}
        key={(Math.random() * 1000).toString()}
        autoLoad={false}
        textButton={text}
        fields="name,email,picture"
        isDisabled={isDisabled}
        authType="reauthenticate"
        reAuthenticate={true}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          performNativeSocialLogin(
            'facebook',
            () => setIsDisabled(true), // Web Callback if not native
            // Prevent web action if native
            () => {
              e.preventDefault();
              setDoRenderAgain(true);
            }
          );
        }}
        icon={<img alt="FB" src={'/dashboard/img/facebook.svg'} />}
        callback={(response) => {
          if ('accessToken' in response) {
            setProfile(response);
            setIsDisabled(false);
            responseFacebook(response.accessToken, {
              facebookId: response.id,
              email: response.email,
              social_token: response.accessToken,
              login_via: LOGIN_VIA.facebook,
              userID: response.userID,
              name: response.name,
            });
          }
        }}
        onFailure={(err) => {
          setIsDisabled(false);
        }}
      />
    );
  }, [doRenderAgain]);
  return (
    <>
      <div className={`App ${provider && profile ? 'hide' : ''}`}>
        {returnFacebookButton}
      </div>
    </>
  );
};

export default FacebookButton;
