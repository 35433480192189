import React from 'react';
import { ExclamationSquare } from '../icons';
import '../../Account/styles.css';
import './styles.css';

interface ConfirmationScreenProps {
  onBackClick?: () => void;
  onConfirmClick: () => void;
  errorMessage: string;
  errorDescription?: string;
  isProcessing?: boolean;
  backButtonLabel: string;
  confirmButtonLabel: string;
}

const ConfirmationScreen = ({
  onBackClick,
  onConfirmClick,
  errorMessage,
  errorDescription,
  isProcessing,
  backButtonLabel,
  confirmButtonLabel,
}: ConfirmationScreenProps) => {
  return (
    <div className="confirmation-screen__wrapper">
      {errorMessage && (
        <div className="sl-auth__error">
          <span className="otp-error__icon">
            <ExclamationSquare />
          </span>
          <span>{errorMessage}</span>
        </div>
      )}
      {errorDescription && (
        <p className="confirmation-screen__error">{errorDescription}</p>
      )}
      <button
        type="button"
        className="button button__primary sl-auth__register-button confirmation-action"
        onClick={onBackClick}
        disabled={isProcessing}
      >
        {backButtonLabel}
      </button>
      <button
        type="button"
        className="profile_setup--skip confirmation-action"
        disabled={isProcessing}
        onClick={onConfirmClick}
      >
        {confirmButtonLabel}
      </button>
    </div>
  );
};

ConfirmationScreen.defaultProps = {
  onBackClick: () => window.location.reload(),
};

export default ConfirmationScreen;
