import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['text', 'String'],
  ['first', 'Int = 50'],
  ['search_after', 'String'],
  ['tie_breaker_id', 'String'],
  ['tie_breaker_param', 'String'],
  ['sort_by', 'String'],
  ['sort_param', 'String']
]);

export default function GLOBAL_SEARCH_COLLECTION(responseString = '') {
  return gql`
  query GlobalSearchCollection(${parametersTypeString}){
    globalSearchCollection(${parametersAssignString}){
      ${defaultResponse}
      collection_meta_data {
        id
        cover_image
        user_name
        display_name
        avatar
        show_author
        members {
          user_id
          avatar
          user_name
          display_name
        }
        comment_count
        reaction_count
      }
      scrapsCount {
        id
        scraps_count
      }
      data {
        id
        user_id
        title
        desc
        state
        matched_count
        members {
          user_id
        }
        created_at
        updated_at
        scrap_ids
      }
      paginatorInfo {
        total
      }
    }
  } 
  `;
}
