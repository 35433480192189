import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
// import Embedo from 'embedo';
const Embedo = typeof window === 'object' ? require('embedo') : null;

// Initialize Embedo class object
const embedo = Embedo ? new Embedo({
  instagram: true,
  twitter: true,
  pinterest: true,
}) : false;

interface propTypes {
  href: string;
}

const RenderEmbed = ({ href }: propTypes) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { backUrl,scrollToBottom,scrapPopup } = useSelector((state: ReduxStateType) => state.utils);
  const faSpinnerIcon = faSpinner as IconProp;
  const ref = useRef();
  let elem = document.getElementsByClassName('scrap-share__actions-toggle-description')[0];
  

  useEffect(() => {
    if(embedo) {
      embedo
        .load(ref.current, href, {
          hidecaption: true,
        })
        .done((data) => {
          setLoading(false);
        })
        .fail((err) => {
          setError('There was an error loading Social post');
          setLoading(false);
        });
    }
  }, []);

  useEffect(()=>{
    if(!loading)
    {
      (scrollToBottom || scrapPopup?.scrollToBottom) &&
      elem?.scrollIntoView({ behavior: 'smooth' });
    }
    
  },[loading])
  return (
    <>
      {loading && (
        <div className="render-embed__loading-overlay">
          {/* <LoadingVideo /> */}
          <div className='render-embed__loader'>
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
          </div>
        </div>
      )}

      {error && <div>{error}</div>}

      <div
        // container needed while loading,
        // so just not give it height to hide it from the view
        style={{ height: loading ? '0px' : 'auto', overflow: 'hidden' }}
        className="embedo-item"
        ref={ref}
      />
    </>
  );
};

export default RenderEmbed;
