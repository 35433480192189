import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewPopup from '../components/Global/NewPopup';
import { setWarningPopup } from '../redux/action/utils';
import { ReduxStateType } from '../redux/store';
import { RequestStatusObjectType } from '../types/requestStatusType';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
const WarningPopupContainer = () => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  
  const warningData = useSelector(
    (state: ReduxStateType) => state.utils.warningPopup
  ); 
  function render(close: () => void) {
    if(warningData?.renderCustomMessage) return warningData.renderCustomMessage(close)
    if(status?.status === 'error') return <div className="warningPopup__message warningPopup__message--error">{status?.message ?? 'Something went wront! Please try again later.'}</div>
    return <div className="warningPopup__message">{warningData?.warningMessage}</div>
  }
  async function submitHandler(cb: Function,close) {
    try {
      if(status?.status === 'error') return close();
      setStatus({ status: 'processing' });
      cb && await cb();
      close();
    } catch (error) {
      setStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  }

  useEffect(() => {
    setStatus({ status: 'not-started' });
  }, [warningData])

  return (
    <NewPopup
      size='Small'
      unclosable={status.status === 'processing'}
      className={`warning-popup ${warningData?.className ?? ''}`}
      header={{
        heading: warningData?.header,
      }}
      controlled={{
        show: !!warningData,
        setShow: (val) => {
          dispatch(setWarningPopup(null));
        },
      }}
      footer={{
        hideCancel: status.status === 'processing' || warningData?.cancel?.hide,
        hideSubmit: status.status !== 'not-started',
        onSubmit: submitHandler.bind(null,warningData?.submit?.cb),
        submitLabel: warningData?.submit?.label,
        spinnerConfig: {
          label: warningData?.processingLabel || 'Processing',
          show: status.status === 'processing'
        },
        onCancelClick: submitHandler.bind(null, warningData?.cancel?.cb),
        cancelLabel:  status.status === 'error' ? 'Ok' : warningData?.cancel?.label
      }}
    >
      {render}
    </NewPopup>
  );
};

export default WarningPopupContainer;
