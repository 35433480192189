import React from 'react';

interface Proptypes {
  className?: string;
}

const ExclamationSquare = ({ className }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4163 0.833374H3.58301C2.06422 0.833374 0.833008 2.06459 0.833008 3.58337V16.4167C0.833008 17.9355 2.06422 19.1667 3.58301 19.1667H16.4163C17.9351 19.1667 19.1663 17.9355 19.1663 16.4167V3.58337C19.1663 2.06459 17.9351 0.833374 16.4163 0.833374ZM2.66634 3.58337C2.66634 3.07711 3.07675 2.66671 3.58301 2.66671H16.4163C16.9226 2.66671 17.333 3.07711 17.333 3.58337V16.4167C17.333 16.923 16.9226 17.3334 16.4163 17.3334H3.58301C3.07675 17.3334 2.66634 16.923 2.66634 16.4167V3.58337ZM9.99956 4.27087C9.37428 4.27087 8.87453 4.79101 8.89953 5.41579L9.10584 10.5736C9.12503 11.0533 9.51949 11.4323 9.99956 11.4323C10.4796 11.4323 10.8741 11.0533 10.8933 10.5736L11.0996 5.41579C11.1246 4.79101 10.6248 4.27087 9.99956 4.27087ZM8.68197 14.1537C8.68197 13.4418 9.2591 12.8646 9.97103 12.8646C10.683 12.8646 11.2601 13.4418 11.2601 14.1537C11.2601 14.8656 10.683 15.4427 9.97103 15.4427C9.2591 15.4427 8.68197 14.8656 8.68197 14.1537Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default ExclamationSquare;
