import React from 'react';
import CustomTolltip from '../CustomTooltip';
import { GlobeIcon, PrivateIcon, ShareIcon, NewGlobeIcon } from '../icons';
import './styles.css';
/*
 * POSSIBLE STATES:
 * 1 - PRIVATE
 * 2 - PRIVATE WITH LINK
 * 3 - PUBLIC
 */

interface PropTypes {
  scrapState: number;
  className?: string;
  iconClass?: string;
  labelClass?: string;
  itemType?: string; // DEFINES THE TYPE OF ITEM - SCRAP | COLLECTION | ANY-OTHER-TYPE (Must be in lowercase)
  enableShareStatus?: boolean;
  type?: 'Scrap' | 'Collection' | 'Story';
}
const ReturnShareIcon = (props: PropTypes) => {
  const {
    scrapState,
    className,
    iconClass,
    labelClass,
    itemType,
    enableShareStatus = false,
    type,
  } = props;
  const itemTypeLabel = itemType ?? 'scrap';
  const visibilityState = scrapState ?? 1

  if (type === 'Collection') {
    if ((visibilityState === 2 || visibilityState === 1) && enableShareStatus) {
      return (
        <div
          className={`return-share-icon ${className ? ' ' + className : ''}`}
        >
          <span className="return-share-icon__tooltip">
            <ShareIcon className={iconClass || ''} />
            <span className={labelClass || ''}>Shareable</span>
            <CustomTolltip
              className={labelClass || ''}
              parentClass="return-share-icon"
              actionListener="hover"
            >
              <span>
                This {itemTypeLabel} is visible by anyone with the shareable
                link.
              </span>
            </CustomTolltip>
          </span>
        </div>
      );
    }

    if ((visibilityState === 1 || visibilityState === 2) && !enableShareStatus)
      return (
        <div
          className={`return-share-icon ${className ? ' ' + className : ''}`}
        >
          <span className="return-share-icon__tooltip">
            <PrivateIcon className={iconClass || ''} />
            <span className={labelClass || ''}>Private</span>
            <CustomTolltip
              className={labelClass || ''}
              parentClass="return-share-icon"
              actionListener="hover"
            >
              <span>
                This {itemTypeLabel} is only visible to you. Enable Shareable
                Link is off.
              </span>
            </CustomTolltip>
          </span>
        </div>
      );
  } else {
    if (visibilityState === 2) {
      return (
        <div
          className={`return-share-icon ${className ? ' ' + className : ''}`}
        >
          <span className="return-share-icon__tooltip">
            <ShareIcon className={iconClass || ''} />
            <span className={labelClass || ''}>Shareable</span>
            <CustomTolltip
              className={labelClass || ''}
              parentClass="return-share-icon"
              actionListener="hover"
            >
              <span>
                This {itemTypeLabel} is visible by anyone with the shareable
                link.
              </span>
            </CustomTolltip>
          </span>
        </div>
      );
    }

    if (visibilityState === 1)
      return (
        <div
          className={`return-share-icon ${className ? ' ' + className : ''}`}
        >
          <span className="return-share-icon__tooltip">
            <PrivateIcon className={iconClass || ''} />
            <span className={labelClass || ''}>Private</span>
            <CustomTolltip
              className={labelClass || ''}
              parentClass="return-share-icon"
              actionListener="hover"
            >
              <span>
                This {itemTypeLabel} is only visible to you. Enable Shareable
                Link is off.
              </span>
            </CustomTolltip>
          </span>
        </div>
      );
  }
  if (visibilityState === 3)
    return (
      <div className={`return-share-icon ${className ? ' ' + className : ''}`}>
        <span className="return-share-icon__tooltip">
          <NewGlobeIcon className={iconClass || ''} />
          <span className={labelClass || ''}>Public</span>
          <CustomTolltip
            className={labelClass || ''}
            parentClass="return-share-icon"
            actionListener="hover"
          >
            <span>This {itemTypeLabel} is visible to all</span>
          </CustomTolltip>
        </span>
      </div>
    );

  return null;
};

export default ReturnShareIcon;
