import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css';
interface propTypes {
  loadingStatus:boolean
}
const Loader = ({ loadingStatus }:propTypes) => {
  const faSpinnerIcon = faSpinner as IconProp;
  return (
    loadingStatus && (
      <div className="loader__loading-overlay">
        <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
      </div>
    )
  )
}

export default Loader