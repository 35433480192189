import { gql } from "@apollo/client";

export const getProfile = gql`
  query profile {
    profile {
      data {
        can_send_invite
        userName
        avatar
        userEmail
        phone
        country
        timezone
        bio
        headline
        address
        login_via
        onboard_status
        register_from
        services
        main_service
      }
    }
  }
`