/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, gql } from '@apollo/client';
import { entryTypes } from '../../types/feed';
import { scrapType } from '../../types/scrapType';

function updateEntryCache(
  cache: ApolloCache<any>,
  scrap: Partial<entryTypes | scrapType>,
  isOneScrap = false,
  typename: 'Scrap' | 'CollectionScrap' = 'Scrap'
) {
  if (isOneScrap) {
    cache.writeFragment({
      id: cache.identify({ ...scrap, __typename: typename }),
      fragment: gql` fragment NewScrap on ${typename} {
            ${Object.keys(scrap).join(' ')}
          }
          `,
      data: scrap,
    });
  } else {
    cache.writeFragment({
      id: cache.identify({ ...scrap, __typename: 'Entry' }),
      fragment: gql` fragment NewEntry on Entry {
            ${Object.keys(scrap).join(' ')}
          }
          `,
      data: scrap,
    });
  }
}

export default updateEntryCache;
