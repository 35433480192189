import { gql } from '@apollo/client';

export const GENERATE_OTP_MUTATION = gql`
  mutation GenerateOtp($email: String, $phone: String,$check_existing:Boolean,$country_code: String, $is_login: Boolean = false) {
    GenerateOtp(email: $email, phone: $phone,check_existing:$check_existing,country_code: $country_code, is_login: $is_login) {
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login(
    $email: String
    $country_code: String
    $phone_number: String
    $password: String
    $login_via: Int
    $otp: String
  ) {
    login(
      email: $email
      country_code: $country_code
      phone: $phone_number
      password: $password
      login_via: $login_via
      otp: $otp
      type: "web"
    ) {
      data {
        access_token
        new_user
        user {
          id
          name
          email
          profile {
            userName
            avatar
            userEmail
            phone
            country
            timezone
            bio
            address
            can_send_invite
            login_via
            onboard_status
          }
        }
      }
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;
