import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  forwardRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../Message/index';
import { CardViewType } from '../Cards/types/cardView';
import {
  DragItem,
  SortableList,
} from '../Gallery/components/AddedImages/helpers/draggables';
import GridWrapper from './GridWrapper';
import { cardSettingsConfigType } from '../Cards/CardComponents/CardSettings';
import { onlyNumbers, scrollDivTopBottom } from '../../helpers';
import Icon from '../Global/Icon';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import './styles.css';
import { toggleOrganizeCollection } from '../../redux/action/utils';
import { ScrapLayoutSettingsType } from '../../types/viewSettings';
import LoadingScrapCard from '../Global/LoadingScrapCard';
import { Masonry } from 'masonic';
import Tick from '../Global/icons/Tick';
import { CloseIcon, NextIcon, PrevIcon } from '../Global/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from "../Global/InvisibleRecaptcha";
import ValidateHuman from '../../helpers/validators/isHuman';
import { scrapType } from '../../types/scrapType';
import ScrapCard from '../ScrapCard';
import { ScrapOrderType } from '../../containers/CollectionPageContainer/types';

const renderCards = (
  entryTopicId: number,
  entry: scrapType,
  source: string,
  search: string,
  view: CardViewType,
  cardSettingsConfig: cardSettingsConfigType = null,
  showEmbed: boolean = false
) => {
  return (
    <ScrapCard
      key={entry.id}
      scrap={{ ...entry }}
      disabled
    />
  );
};

/**
 * filter the entries based on the selected filter
 */
const filterEntries = (
  entries: scrapType[],
  activeFilter: string
): scrapType[] => {
  let filteredEntries: scrapType[] = [...entries];

  if (activeFilter && activeFilter !== 'all') {
    switch (activeFilter) {
      case 'screenshot':
        filteredEntries = entries.filter(
          (entry) => entry.type === activeFilter
        );
        break;

      case 'url':
        filteredEntries = entries.filter(
          (entry) => entry.type === activeFilter
        );
        break;

      case 'video':
        filteredEntries = entries.filter(
          (entry) => entry.type === activeFilter
        );
        break;

      case 'annotation':
        filteredEntries = entries.filter(
          (entry) => entry.type === activeFilter
        );
        break;

      case 'null':
        filteredEntries = entries.filter((entry) => entry.domain === null);
        break;

      default:
        filteredEntries = entries.filter(
          (entry) => entry.domain === activeFilter
        );
        break;
    }
  }

  return filteredEntries;
};

interface propType {
  cardSettingsConfig?: cardSettingsConfigType;
  feed: Array<scrapType>;
  topicId?: number;
  activeFilter?: string;
  isLoading: boolean;
  count?: number;
  url?: string;
  search?: string;
  // if the user doesn't have data show instructions
  instructions?: boolean;
  // handles card's behaviour in the view, like disable clicks and data based on view
  view?: CardViewType;
  view_type?: number;
  organizeIndex: number;
  setOrganizeIndex: Dispatch<SetStateAction<number>>;
  saveNewOrders: (
    scrapOrders: Array<ScrapOrderType>,
    newPanelsList: scrapType[]
  ) => Promise<void>;
  panel_count?: number;
  titleLabel?: string;
  scrapSize: ScrapLayoutSettingsType['size'];
  onCancel: () => void;
}

interface sortProps {
  oldIndex: number;
  newIndex: number;
  undoAction?: boolean;
}

const DraggableTimeLine = forwardRef((props: propType, ref) => {
  const {
    cardSettingsConfig,
    feed,
    activeFilter,
    topicId,
    isLoading,
    count,
    url,
    search,
    instructions,
    view,
    view_type,
    organizeIndex: focusIndex,
    setOrganizeIndex: setFocusIndex,
    onCancel,
    saveNewOrders,
    scrapSize,
  } = props;
  const controlsRef = useRef<HTMLDivElement>();
  const [isPinned, setIsPinned] = useState(false);
  const [newOrders, setNewOrders] = useState<Array<ScrapOrderType>>([]);
  const [panels, setPanels] = useState(feed.map(item => ({...item})));
  const gridRef = useRef(null);
  const [draggedOver, setDraggedOver] = useState(-1);
  const [moveTo, setMoveTo] = useState('');
  const [operationSet, setOperationSet] = useState([]);
  const [saving, setSaving] = useState(false);
  const DISPATCH = useDispatch();
  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const compactState = useSelector((state: any) => state.user?.compact);

  let filteredFeed: scrapType[] = [...feed];

  //CHECK IF IF IS A DESCENDANT OF THE ACTION DIV OR NOT
  const checkActionChild = (node) => {
    let targetParentClass = 'draggable-feed__item-counter__actions';
    let mainDivClass = 'draggable-feed__item';

    while (node != null) {
      if (node.getAttribute('class')?.includes(targetParentClass)) {
        return true;
      } else if (node.classList.contains(mainDivClass)) {
        return false;
      }
      node = node.parentNode;
    }
    return false;
  };

  //IGNORE OPERATIONAL ELEEMENTS FROM DRAG ITEM
  const shouldCancelStart = (e) => {
    if (view_type === 1) return true;
    return checkActionChild(e.target.parentNode);
  };

  //FOCUS DRAGGED ITEM
  const onSortStart = ({ node, index }) => {
    if (view_type === 1 || !checkActionChild(node)) {
      setFocusIndex(index);
    }
  };

  const onSaveOrdersClick = async () => {
    const isHuman= await ValidateHuman(reCaptchaRef.current);
    if(!isHuman)
      return;
    // todo: set processing to true
    try {
      setSaving(true);
      await saveNewOrders(newOrders, panels);
      setFocusIndex(null);
      DISPATCH(toggleOrganizeCollection(false));
    } catch (error) {
      // todo: Handle error
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const errorMessage = parseTryCatchError(error);
    } finally {
      // todo: set processing to false
      setSaving(false);
    }
  };

  //CHECK IF FOCUS INDEX IS ACTIVE OR NOT
  const checkFocusIndex = () => {
    return focusIndex >= 0;
  };

  // Handler function to change the order of droppedScrap
  const orderScrap = (itemSet: scrapType[], newIndex: number) => {
    // itemSet: newList of all scraps after drop
    // newIndex: index position newly dropped scrap

    const orders = [...newOrders];
    // nextScrapOrder: order of the scrap which is next to the recently dropped scrap, i.e newIndex + 1
    // previousScrapOrder: order of the scrap which is before the recently dropped scrap, i.e newIndex - 1
    let nextScrapOrder, previousScrapOrder;

    const nextScrap = itemSet[newIndex + 1];
    const droppedScrap = itemSet[newIndex];
    const previousScrap = itemSet[newIndex - 1];

    if (!nextScrap) {
      // then scrap is dropped on last position,
      // previousScrapOrder will be order of the scrap which was previously the last one in the list
      // and now it is at second last position in the list, i.e at position: itemSet.length - 2
      // and add 1 to that scrap's order which will be nextScrapOrder
      previousScrapOrder = +itemSet[itemSet.length - 2].order;
      nextScrapOrder = previousScrapOrder + 1;
    } else if (!previousScrap) {
      // scrap is dropped on first position
      // nextScrapOrder will be the order of scrap which was previously the first one in the list
      // and now it is at second position in the list, i.e at position: 1
      // and deduct 1 from that scrap's order which will become previousScrapOrder
      nextScrapOrder = +itemSet[1].order;
      previousScrapOrder = nextScrapOrder - 1;
      // set previousScrapOrder to 0 if it is negative
      if (previousScrapOrder < 0) previousScrapOrder = 0;
    } else {
      // Then scrap is dropped in between
      nextScrapOrder = +nextScrap.order;
      previousScrapOrder = +previousScrap.order;
    }
    const finalOrder = (nextScrapOrder + previousScrapOrder) / 2;
    const config = {
      id: droppedScrap.id.toString(),
      order: finalOrder.toString(),
    };
    // find the scrap in newOrders list
    const scrapIndex = orders.findIndex(
      (item) => item.id.toString() === droppedScrap.id.toString()
    );
    if (scrapIndex > -1) {
      orders.splice(scrapIndex, 1, config);
    } else {
      orders.push(config);
    }
    setNewOrders(orders);
    return finalOrder;
  };

  // HANDLE SORTING
  const onSortEnd = ({ oldIndex, newIndex, undoAction = false }: sortProps) => {
    if (panels.length === 0) return setPanels([]);
    let itemSet = [...panels];
    Array.prototype.splice.call(
      itemSet,
      newIndex,
      0,
      Array.prototype.splice.call(itemSet, oldIndex, 1)[0]
    );
    //DO NOT REMOVE THE UNDER GIVEN LINE - FOR THE MOVED ITEM TO BE STAYED FOCUSED
    // setFocusIndex(newIndex);
    if (oldIndex !== newIndex) {
      setFocusIndex(-1);

      //ADD ACTIONS TO OPERATION SET IF NOT UNDO ACTION
      if (!undoAction) {
        let temp = operationSet;
        temp.push({
          scrap_id: itemSet[newIndex].id,
          old_index: oldIndex,
          new_index: newIndex,
        });
        setOperationSet(temp);
      }
    }
    const finalOrder = orderScrap(itemSet, newIndex);
    // Update the order of dropped scrap in panels
    itemSet[newIndex].order = finalOrder.toString();
    setPanels(itemSet);
    setDraggedOver(-1);
  };

  //HANDLE UNDO OPERATION FROM OPERATION SET
  const handleUndo = () => {
    if (operationSet.length > 0) {
      let lastOperation = operationSet[operationSet.length - 1];
      let newOperationSet = [...operationSet];
      newOperationSet.pop();
      setOperationSet(newOperationSet);
      onSortEnd({
        oldIndex: lastOperation.new_index,
        newIndex: lastOperation.old_index,
        undoAction: true,
      });
    }
  };

  //HANDLING SORT OVER
  const onSortOver = ({ newIndex }) => {
    setDraggedOver(newIndex);
  };

  //DIRECT SORT HANDLER
  const sortHandler = (newIndex: number, callback?: () => void) => {
    if (checkFocusIndex()) {
      onSortEnd({ oldIndex: focusIndex, newIndex });
      callback && callback();
    }
  };

  //DIRECT SORT POSITION HANDLER
  const moveToHandler = () => {
    if (checkFocusIndex() && moveTo !== '') {
      sortHandler(parseInt(moveTo) - 1);
    }
    setMoveTo('');
  };

  const renderCounter = (counter) => {
    return view_type === 2 ? ( //story VIEW
      <div className="draggable-feed__item-counter__actions">
        <span className="draggable-feed__item-counter__actions-index">
          {counter}.
        </span>
        {focusIndex === counter - 1 && (
          <div className="draggable-feed__item-counter__actions-operations">
            {counter > 1 && (
              <span
                className="draggable-feed__item-counter__actions-button"
                onClick={() =>
                  onSortEnd({ oldIndex: counter - 1, newIndex: counter - 2 })
                }
              >
                <Icon iconClass="arrow-up" size="big" />
              </span>
            )}
            {counter < panels.length && (
              <span
                className="draggable-feed__item-counter__actions-button"
                onClick={() =>
                  onSortEnd({ oldIndex: counter - 1, newIndex: counter })
                }
              >
                <Icon iconClass="arrow-down" size="big" />
              </span>
            )}
          </div>
        )}
      </div>
    ) : (
      <div className="draggable-feed__item-counter">{counter}</div>
    );
  };

  //HANDLE DRAG CARDS RENDER CONDITIONS
  const renderItems = (data: scrapType, key: number) => {
    let urlString = '';
    if (url) {
      urlString += url;
    }

    if (view_type > 1) {
      return (
        <>
          <DragItem
            key={key}
            index={key}
            className={`draggable-feed__item ${
              draggedOver === key ? 'draggable-feed__focus' : ''
            } ${focusIndex === key ? 'active' : ''} ${
              view_type === 2 ? 'draggable-feed__item--story' : ''
            }`}
            onClick={() => setFocusIndex(key)}
          >
            {renderCounter(key + 1)}
            {renderCards(
              topicId,
              data,
              urlString,
              search,
              view,
              cardSettingsConfig
            )}
          </DragItem>
        </>
      );
    } else {
      return (
        <div
          key={key}
          className={`draggable-feed__item ${
            draggedOver === key ? 'draggable-feed__focus' : ''
          } ${focusIndex === key ? 'active' : ''}`}
          onClick={() => setFocusIndex(key)}
        >
          {renderCounter(key + 1)}
          {renderCards(
            topicId,
            data,
            urlString,
            search,
            view,
            cardSettingsConfig
          )}
        </div>
      );
    }
  };

  const isExpanded = () => {
    return checkFocusIndex() || operationSet.length > 0;
  };

  useEffect(() => {
    // @ts-ignore
    const el = ref?.current as HTMLDivElement;
    if(el) {
      const handlePinning = () => {
        setIsPinned(controlsRef.current.offsetTop > 0)
      }
      el.addEventListener('scroll', handlePinning)
      return () => {
        el.removeEventListener('scroll', handlePinning);
      }
    }
  }, [])

  useEffect(() => {
    setPanels(feed.map(item => ({...item})));
  }, [feed]);

  // THIS USEEFFECT IS USED FOR DETECTING IF ORGANIZE CONTROLS BAR IS PINNED AT TOP OR NOT
  useEffect(() => {
    const cachedRef = controlsRef.current;
    if (cachedRef && window.innerWidth > 765) {
      const handleScroll = () => {
        setIsPinned(window.innerWidth > 765 && cachedRef.offsetTop > 0);
      }
      window.addEventListener('scroll', handleScroll)

      return function () {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [controlsRef?.current]);
  if (activeFilter) {
    filteredFeed = filterEntries(feed, activeFilter);
  }

  // loading state
  if (isLoading) {
    return (
      <div style={{ width: '100%' }} className={compactState ? 'compact' : ''}>
        <Masonry
          key={'draggable-feeds-loading'}
          items={Array.from(Array(10), (_, index) => ({ id: index + 1 }))}
          columnGutter={18}
          columnWidth={scrapSize === 'Default' ? 260 : 320}
          overscanBy={1}
          render={({ data }) => {
            return <LoadingScrapCard />;
          }}
        />
      </div>
    );
  }

  // when there is no data
  if (filteredFeed.length === 0 && !isLoading) {
    return (
      <>
        <Message instructions={instructions} />
      </>
    );
  }

  if (count) {
    return (
      <div>
        {filteredFeed.map((entry) => {
          return renderCards(topicId, entry, '', '', view, cardSettingsConfig);
        })}
      </div>
    );
  }

  const placheholderValues = new Array(5);
  placheholderValues.fill(0);

  return (
    <div className="draggable-feed__main-wrapper">
      <div
        ref={controlsRef}
        className={`draggable-feed__operation${
          isExpanded() ? ' expanded' : ''
        }${isPinned ? ' draggable-feed__operation--pinned' : ''}`}
      >
        <div
          className={`draggable-feed__operation-container${
            isExpanded() ? ' expanded' : ''
          }`}
        >
          <div
            className={`draggable-feed__operation-order ${
              checkFocusIndex() ? '' : 'order-disabled'
            }`}
          >
            {checkFocusIndex() ? (
              <>
                <div className="draggable-feed__operation-order__direct">
                  <h3 className="draggable-feed__operation-title-mobile">
                    Move Scrap:
                  </h3>
                  <div className="draggable-feed__operation-order__direct-action">
                    <span
                      className={`draggable-feed__operation-previous${
                        focusIndex <= 0 ? ' disabled' : ''
                      }`}
                      onClick={() => {
                        if (focusIndex <= 0) {
                          return;
                        }

                        const targetPosition = focusIndex - 1;
                        sortHandler(targetPosition);
                      }}
                    >
                      <PrevIcon />
                    </span>

                    <span
                      className={`draggable-feed__operation-next${
                        focusIndex >= panels.length - 1 ? ' disabled' : ''
                      }`}
                      onClick={() => {
                        if (focusIndex >= panels.length - 1) {
                          return;
                        }

                        const targetPosition = focusIndex + 1;
                        sortHandler(targetPosition);
                      }}
                    >
                      <NextIcon />
                    </span>
                  </div>
                </div>

                <div className="draggable-feed__operation-order__direct">
                  <div className='draggable-feed__operation-move-to'>
                    <h3 className="draggable-feed__operation-title-mobile">
                      Or Move to
                    </h3>
                    <div className="draggable-feed__operation-order__direct-action direct__move">
                      <span
                        className={`${focusIndex <= 0 ? 'disabled' : ''}`}
                        onClick={() =>
                          sortHandler(0, () =>
                            scrollDivTopBottom(
                              'collection-creation-scroll',
                              'top'
                            )
                          )
                        }
                      >
                        First
                      </span>
                      <span
                        className={`${
                          focusIndex >= panels.length - 1 ? 'disabled' : ''
                        }`}
                        onClick={() =>
                          sortHandler(panels.length - 1, () =>
                            scrollDivTopBottom(
                              'collection-creation-scroll',
                              'bottom'
                            )
                          )
                        }
                      >
                        Last
                      </span>
                    </div>
                  </div>
                  <div className='draggable-feed__operation-position'>
                    <p className="draggable-feed__operation-order__position-label">
                      Position:{' '}
                    </p>
                    <input
                      type="text"
                      className="draggable-feed__operation-order__input"
                      min="1"
                      max={panels.length}
                      value={moveTo}
                      onChange={(e) =>
                        setMoveTo(
                          onlyNumbers({
                            value: e.target.value,
                            oldValue: moveTo,
                            min: 1,
                            max: panels.length,
                          })
                        )
                      }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (checkFocusIndex()) {
                            moveToHandler();
                          }
                        }
                      }}
                      placeholder="#"
                      disabled={checkFocusIndex() ? false : true}
                      title={`Min - 1, Max - ${panels.length}`}
                    />
                    <button
                      onClick={(e) => {
                        moveToHandler();
                      }}
                      className="draggable-feed__operation-order__input-submit-button"
                    >
                      Move
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <h3
                className={`draggable-feed__operation-title-mobile scrap-move${
                  operationSet.length > 0 ? ' undo-action' : ''
                }`}
              >
                <span>Select a Scrap to move</span>
                {operationSet.length > 0 && (
                  <span
                    className="draggable-feed__operation-undo"
                    onClick={() => handleUndo()}
                  >
                    Undo Last Move
                  </span>
                )}
              </h3>
            )}
          </div>
          <div
            className={`draggable-feed__operation-save${
              isExpanded() ? ' expanded' : ''
            }`}
          >
            <button
              className={`button button__outline btn-action${
                isExpanded() ? '' : ' btn-close'
              }`}
              onClick={onCancel}
            >
              <CloseIcon />
              <span>Cancel</span>
            </button>
            {isExpanded() && (
              <button
                onClick={onSaveOrdersClick}
                className="button button__primary btn-action"
              >
                <Tick />
                Save Order
              </button>
            )}
          </div>
        </div>
      </div>
      {saving && (
        <div className="draggable-feed__saving">
          <div className="draggable-feed__saving-icon">
            <img
              src="/dashboard/img/logo-transparent.png"
              alt="Scrappi Loader"
            />
          </div>
        </div>
      )}
      <SortableList
        onSortStart={onSortStart}
        onSortEnd={onSortEnd}
        onSortOver={onSortOver}
        shouldCancelStart={shouldCancelStart}
        axis="xy"
        distance={0}
        transitionDuration={300}
      >
        <InvisibleRecaptcha inputRef={reCaptchaRef}/>
        <GridWrapper
          gridRef={gridRef}
          view_type={view_type}
          scrapSize={scrapSize}
        >
          {panels.length > 0 &&
            panels.map((entry, key) => {
              return renderItems(entry, key);
            })}
        </GridWrapper>
      </SortableList>
    </div>
  );
});

export default DraggableTimeLine;
