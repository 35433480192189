export function returnShareLink(
  type: 'scrap' | 'collection' | 'story' | 'user',
  slug: string,
  username: string,
  forPush?: boolean,
  urlVisibility?: number,
  private_key?:string
) {
  try {
    const origin = window.location.origin;
    let temp, collectionID, slugArr;
    switch (type) {
      case 'scrap':
        // SHARE SCRAP LINK SYNTAX: /@<USERNAME>/scrap/<SCRAP_SLUG>/<SCRAP_TYPE_ID>-<SCRAP_ID>
        temp = `/scrap/${slug}`;
        break;
      case 'collection':
        // SHARE COLLECTION LINK SYNTAX: /@<USERNAME>/collection/<COLLECTION_SLUG>
        slugArr = slug?.split('-');
        collectionID = slugArr?.pop();
        temp = `/c/${slugArr?.join('-')}/${collectionID}`;
        break;
      case 'story':
        // SHARE STORY LINK SYNTAX: /@<USERNAME>/story/<STORY_SLUG>
        temp = `/story/${slug}`;
        break;
      case 'user':
        // SHARE STORY LINK SYNTAX: /@<USERNAME>/story/<STORY_SLUG>
        temp = `/${username}`;
        break;
      default:
        break;
    }

    if (temp) {
      let shareLink = '/' + username + temp;
      if(type === 'user') shareLink = temp;
      if(urlVisibility===2||urlVisibility===3 && private_key) shareLink += `/${private_key}`;
      // for push, only return link without origin
      if (forPush) return shareLink;
      return origin + shareLink;
    }
  } catch (error) {
    return '';
  }
  
}
