import { MutableRefObject } from 'react';
import { containsSpecialCharacter } from './containsSpecialCharacter';
import isFileBig from './isFileBig';

function validateEmail(
  value: string,
  inputRef?: MutableRefObject<HTMLInputElement | HTMLTextAreaElement>
) {

  const checkInputValue = () => {
    if (inputRef?.current) {
      return inputRef.current.validity?.valid;
    }
    return true;
  };

  return (
    checkInputValue() &&
    /^\w+([.-]\w+)*\+*[0-9+]*@\w+([.-]\w+)*\.[a-z]{1,99}$/i.test(value)
  );
}
export { containsSpecialCharacter, validateEmail, isFileBig };