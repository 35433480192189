import React, { useEffect } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { Login } from '../components/Account';
import PublicPageWrapper from '../components/Global/PublicPageWrapper';
import { PROFILE_SETUP_PATH } from '../helpers/config';
import { parseUrlParams, setToken } from '../helpers';

const LOGIN_MUTATION = gql`
  mutation login(
    $email: String
    $country_code: String
    $phone_number: String
    $password: String
    $login_via: Int
    $otp: String
  ) {
    login(
      email: $email
      country_code: $country_code
      phone: $phone_number
      password: $password
      login_via: $login_via
      otp: $otp
      type: "web"
    ) {
      data {
        access_token
        user {
          id
          name
          email
          profile {
            onboard_status
          }
        }
      }
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

// login_via - 2 represents google login
export const GOOGLE_LOGIN_MUTATION = gql`
  mutation login($email: String, $social_token: String) {
    login(email: $email, social_token: $social_token, login_via: 2) {
      data {
        access_token
        user {
          id
          name
          email
          profile {
            onboard_status
          }
        }
      }
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

export const FACEBOOK_LOGIN_MUTATION = gql`
  mutation login(
    $email: String
    $social_token: String
    $social_auth_code: String
    $login_via: Int
    $social_id: String
  ) {
    login(
      email: $email
      social_token: $social_token
      social_auth_code: $social_auth_code
      login_via: $login_via
      social_id: $social_id
      type: "web"
    ) {
      data {
        access_token
        user {
          id
          name
          email
          profile {
            onboard_status
          }
        }
      }
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

interface LocationStateType {
  dontRedirectToAuth?: boolean;
}

const LoginWrapper = (props) => {
  const { push } = useHistory();
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const { search, state, pathname } = useLocation<LocationStateType>();
  const { redirect } = parseUrlParams(search);
  const { collectionCollaboratorToken } = qs.parse(search.substring(1));

  const handleRedirection = (onboard_status?: boolean) => {
    if (collectionCollaboratorToken)
      window.location.href = `/accept-collaborator?token=${collectionCollaboratorToken}`;
    else if (onboard_status) {
      window.location.replace(PROFILE_SETUP_PATH);
    } else if(redirect && typeof redirect === 'string') {
      window.location.replace(redirect)
    } else {
      window.location.replace('/');
    }
  };

  const submitHandler = (
    email: string,
    password: string,
    onError: (message: string) => void,
    onSuccess: Function
  ) => {
    loginMutation({
      variables: {
        email: email,
        password: password,
      },
    }).then(async (val) => {
      var response = val.data.login;
      if (response.data.access_token) {
        await setToken(response.data.access_token, response.data.user?.id);
        onSuccess();
        handleRedirection(response.data.user?.profile?.onboard_status);
      } else {
        let errorMessage = 'Something went wrong. Please try again later.';
        if (response?.messages?.error?.length > 0)
          errorMessage = response.messages.error[0];
        onError(errorMessage);
      }
    });
  };

  useEffect(() => {
    if (collectionCollaboratorToken && !state?.dontRedirectToAuth) {
      push({
        pathname: '/auth',
        state: {
          forceRedirectUrl: pathname + search,
          forceRedirectTo: 'login',
        },
      });
    }
  }, []);
  return (
    <PublicPageWrapper>
      <Login onSubmit={submitHandler} handleRedirection={handleRedirection} />
    </PublicPageWrapper>
  );
};

export default LoginWrapper;
