import React from 'react';

interface Proptypes {
  className?: string;
}

const FilePinIcon = ({ className }: Proptypes) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className || ''}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.5148 2.65139C15.8521 1.98864 14.8693 1.66602 13.9 1.66602C12.9306 1.66602 11.9478 1.98864 11.2851 2.65139L3.79722 10.1405C2.92657 11.0113 2.5 12.3063 2.5 13.5879C2.5 14.8694 2.92657 16.1645 3.79722 17.0353C4.66786 17.906 5.96269 18.3327 7.24403 18.3327C8.52538 18.3327 9.8202 17.906 10.6908 17.0353L16.9308 10.7943C17.2262 10.4989 17.2262 10.0199 16.9308 9.72445C16.6354 9.42901 16.1565 9.42901 15.8611 9.72445L9.62115 15.9654C9.03581 16.5508 8.14666 16.8523 7.24403 16.8523C6.34141 16.8523 5.45226 16.5508 4.86692 15.9654C4.28158 15.38 3.98016 14.4907 3.98016 13.5879C3.98016 12.6851 4.28158 11.7958 4.86692 11.2104L12.3548 3.72127C12.7322 3.34387 13.3093 3.14642 13.9 3.14642C14.4906 3.14642 15.0677 3.34387 15.4451 3.72127C15.8224 4.09867 16.0198 4.67592 16.0198 5.26664C16.0198 5.85737 15.8224 6.43462 15.4451 6.81202L8.78916 13.469C8.61982 13.6384 8.35466 13.7318 8.07602 13.7318C7.79739 13.7318 7.53223 13.6384 7.36289 13.469C7.19355 13.2996 7.10012 13.0344 7.10012 12.7558C7.10012 12.4771 7.19355 12.2119 7.36289 12.0425L12.7708 6.6337C13.0662 6.33827 13.0662 5.85927 12.7708 5.56383C12.4754 5.26839 11.9965 5.26839 11.7011 5.56383L6.29319 10.9726C5.83854 11.4274 5.61996 12.0983 5.61996 12.7558C5.61996 13.4132 5.83854 14.0842 6.29319 14.5389C6.74784 14.9936 7.41867 15.2122 8.07602 15.2122C8.73338 15.2122 9.40421 14.9936 9.85886 14.5389L16.5148 7.88189C17.1774 7.21913 17.5 6.23615 17.5 5.26664C17.5 4.29714 17.1774 3.31415 16.5148 2.65139Z"
        fill="#274A5E"
      />
    </svg>
  );
};

export default FilePinIcon;
