import React, { useState, useEffect } from 'react';

interface MarkdownReaderProps {
  markdown: string;
  shouldLoadMarkdown: boolean; // Assuming this is your condition
}

const MarkdownReader: React.FC<MarkdownReaderProps> = ({ markdown, shouldLoadMarkdown }) => {
  const [ReactMarkdown, setReactMarkdown] = useState<typeof import('react-markdown') | null>(null);

  useEffect(() => {
    if (shouldLoadMarkdown) {
      try {
        import('react-markdown')?.then((module) => {
          // @ts-ignore
          setReactMarkdown(() => module?.default);
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [shouldLoadMarkdown]);

  return (
    <>
      {/* @ts-ignore */ }
      {ReactMarkdown ? <ReactMarkdown>{markdown}</ReactMarkdown> : markdown}
    </>
  );
};

export default MarkdownReader;