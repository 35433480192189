import React, { useState, useRef, useEffect } from 'react';
import { parseNote, waitBeforeExecute } from '../../../helpers';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';

import './styles.css';

interface Proptypes {
  hideLess?: boolean;
  defaultExpanded?: boolean;
  content: string;
  maxHeight?: number;
  reCalculateConfig?: {
    onComplete: () => void;
  };
  onLoad?: () => void;
  noScroll?: boolean;
  view?: String;
  onMoreClick?: (isCurrentlyOpened: boolean) => boolean;
  showFull?: boolean;
  editClick?: () => void;
}

// TO USE TIPTAP IN PLACE OF DANGEROUSLY SET INNER HTML, JUST UNCOMMENT THE COMMENTED LINES OF TIPTAP

const ReadMoreText = ({
  hideLess,
  defaultExpanded,
  content,
  maxHeight,
  reCalculateConfig,
  onLoad,
  noScroll,
  view,
  onMoreClick,
  editClick,
  showFull,
}: Proptypes) => {
  const [clickTimeout, setClickTimeout] = useState(null);
  const notesRef = useRef()

  const [readMore, setReadMore] = useState(defaultExpanded);
  const [haveLongContent, setHaveLongContent] = useState(false);
  return (
    <div
      className="read-more"
      style={{ maxHeight: noScroll ? null : maxHeight + 24 + 'px' }}
      onClick={async (e) => {
        e.stopPropagation();

        // double click
        if (clickTimeout !== null) {
          clearTimeout(clickTimeout);
          setClickTimeout(null);
          if (editClick) {
            editClick();
          }
        } else { // single click
          setClickTimeout(
            setTimeout(() => {
              setClickTimeout(null);
              // @ts-ignore
              if (!showFull && notesRef?.current?.clamped) {
                if (onMoreClick) {
                  const canContinue = onMoreClick(readMore);
                  if (!canContinue) return;
                }
                setReadMore(!readMore);
              }
            }, 300)
          );
        }
      }}
    >
      <div
        className={`read-more__content${
          readMore ? ' read-more_content--visible' : ''
        }`}
      >
        <HTMLEllipsis
          ref={notesRef}
          unsafeHTML={parseNote(content)}
          maxLine={showFull || readMore ? Infinity : 9}
          basedOn='words'
          ellipsisHTML={`<button><div></div><div></div><div></div></button>`}
        />
      </div>
    </div>
  );
};

export default ReadMoreText;
