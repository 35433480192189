import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([['user_ids', '[InputMemberId]!']]);

export default function GLOBAL_USERS_COLLECTIONS_COUNT() {
  return gql`
  query GlobalUsersCollectionsCount(${parametersTypeString}){
    globalUsersCollectionsCount(${parametersAssignString}){
      ${defaultResponse}
      data {
        user_id
        collections_count
      }
    }
  } 
  `;
}
