export const getHtmlLength = (data: string) => {

  let ConvertStringToHTML = function (string) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(string, 'text/html');
    return doc.body.textContent;
  };

  const dataStringLength = ConvertStringToHTML(data).length;
  
  return dataStringLength;


};
