import React, { useState } from 'react';
import { tagType } from '../../../types/tags';
import TagsList from '../TagsList';

import './styles.css';
import { entryTypes } from '../../../types/feed';

interface Proptypes {
  selectedTags?: tagType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave?: (data?: Partial<entryTypes>) => void;
  disableItemClick?: boolean;
}

const AddTagsHelper = ({
  selectedTags,
  onSave,
  disableItemClick
}: Proptypes) => {
  const [onSaveCollectionList,setOnSaveCollectionList] =useState(true)
  
  

  return (
    <div className="add-tags-helper">
      <TagsList
        disableItemClick={disableItemClick}
        tags={selectedTags}
        view="scrap-details"
        onSave={onSave}
        label={onSaveCollectionList ? 'Edit' : 'Add'}
        isNew={!selectedTags.length}
        addIcon
        setOnSaveCollectionList={setOnSaveCollectionList}
        onSaveCollectionList={onSaveCollectionList}
      />
    </div>
  );
};

export default AddTagsHelper;
