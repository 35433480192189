// Example usage
// const timestamp = '2022-09-13 09:56:00'; // Example timestamp from the previous year
// const timezone = 'America/New_York'; // Change this to the desired timezone
// const formattedTimestamp = formatTimestampDate(timestamp, timezone);
// console.log(formattedTimestamp);

// Note: This function assumes that the timezone of
// the timestamp, it is receiving as parameter is UTC

import { parseFromTimeZone } from 'date-fns-timezone';

export default function formatTimestampDate(
  timestamp: string,
  timezone: string
) {
  try {
    // If timestamp value already has timezone info, remove it.
    // It is represented with +
    // this is handling the value coming from Supabase
    if (timestamp.includes('+')) {
      timestamp = timestamp.slice(0, -6);
    }

    const date = new Date(
      parseFromTimeZone(timestamp, {
        timeZone: 'UTC',
      })
    );

    // Convert date to the target timezone
    const dateWithTimezone = new Date(
      date.toLocaleString('en-US', { timeZone: timezone })
    );
    const currentDate = new Date();

    // Check if the date is from a previous year
    const isPreviousYear =
      dateWithTimezone.getFullYear() < currentDate.getFullYear();

    // Calculate the time difference in seconds
    let timeDifference = Math.floor(
      // @ts-ignore
      (currentDate - dateWithTimezone) / 1000
    );
    // make it absolute
    timeDifference = Math.abs(timeDifference);

    if (timeDifference < 60) {
      return `${timeDifference}s`;
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes}m`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours}h`;
    } else if (timeDifference < 604800) {
      const days = Math.floor(timeDifference / 86400);
      return `${days}d`;
    } else {
      // Format the date in 'DD MMM' format with or without the year
      const options = {
        day: 'numeric',
        month: 'short',
        year: isPreviousYear ? 'numeric' : undefined,
      };
      // @ts-ignore
      return dateWithTimezone.toLocaleDateString('en-US', options);
    }
  } catch (error) {
    return null;
  }
}
