import { gql } from '@apollo/client';

interface propType {
  type: string;
  notes?: string;
  tags?: string;
}


export const EDIT_PANEL = ({ type, tags }: propType) => {
  switch (type) {
    case 'screenshot':
      return gql`
        mutation upsertScreenshotPanel(
          $id: ID
          $url: String
          $page_title: String
          $image: String
          $tags: String
          $notes: String
          $memos: [MemoInput]
          $collections: String
          $title: String
          $updated_url: String
          $state: Int
          $file_changed: Boolean
          $image_type: Int
        ) {
          upsertScreenshot(
            id: $id
            url: $url
            image: $image
            page_title: $page_title
            tags: $tags
            collections: $collections
            notes: $notes
            memos: $memos
            title: $title
            updated_url: $updated_url
            state: $state
            file_changed: $file_changed
            image_type: $image_type
          ) {
            id
            pid
            uid
            title
            notes
            image
            image_type
            page_title
            title
            created_at
            updated_at
            private_key
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            tags {
              id
              title
            }
            
          }
        }
      `;
    case 'product':
      return gql`
        mutation upsertProductPanel(
          $id: ID
          $title: String
          $price: String
          $desc: String
          $image: String
          $images_meta: String
          $state: Int
          $updated_url: String
          $tags: String
          $collections: String
          $memos: [MemoInput]
          $page_title: String
          $url: String
          $file_changed: Boolean
        ) {
          upsertProduct(
            id: $id
            title: $title
            price: $price
            desc: $desc
            image: $image
            images_meta: $images_meta
            state: $state
            updated_url: $updated_url
            tags: $tags
            collections: $collections
            memos: $memos
            page_title: $page_title
            url: $url
            file_changed: $file_changed
          ) {
            uid
            pid
            id
            title
            image
            price
            created_at
            updated_at
            private_key
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            
            tags {
              id
              title
            }
          }
        }
      `;
    case 'annotation':
      return gql`
        mutation upsertAnnotationPanel(
          $id: ID
          $tags: String
          $collections: String
          $title: String
          $comment: String
          $memos: [MemoInput]
          $updated_url: String
          $state: Int
        ) {
          upsertAnnotation(
            id: $id
            tags: $tags
            collections: $collections
            comment: $comment
            memos: $memos
            title: $title
            updated_url: $updated_url
            state: $state
          ) {
            uid
            id
            pid
            uid
            created_at
            updated_at
            private_key
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            
            tags {
              id
              title
            }
          }
        }
      `;
    case 'audio':
      return gql`
        mutation upsertAudioPanel(
          $id: ID
          $tags: String
          $collections: String
          $title: String
          $notes: String
          $memos: [MemoInput]
          $state: Int
        ) {
          upsertAudio(
            id: $id
            tags: $tags
            collections: $collections
            notes: $notes
            memos: $memos
            title: $title
            state: $state
          ) {
            id
            pid
            uid
            created_at
            updated_at
            private_key
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            
            tags {
              id
              title
            }
          }
        }
      `;
    case 'video':
      return gql`
        mutation upsertVideoPanel(
          $id: ID
          $tags: String
          $collections: String
          $title: String
          $notes: String
          $memos: [MemoInput]
          $state: Int
          $project_id: Int
          $playback_time: Int
          $started_at: DateTime
          $upload_status: Boolean
          $name: String
        ) {
          upsertVideo(
            id: $id
            tags: $tags
            collections: $collections
            notes: $notes
            memos: $memos
            title: $title
            state: $state
            project_id: $project_id
            playback_time: $playback_time
            started_at: $started_at
            upload_status: $upload_status
            name: $name
          ) {
            pid
            id
            video_meta
            video
            pid
            uid
            created_at
            updated_at
            private_key
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            
            tags {
              id
              title
            }
          }
        }
      `;
    case 'url':
      return gql`
        mutation upsertUrlPanel(
          $id: ID
          $tags: String
          $tags: String
          $notes: String
          $memos: [MemoInput]
          $updated_url: String
        ) {
          upsertUrl(
            id: $id
            tags: $tags
            notes: $notes
            memos: $memos
            title: $title
            updated_url: $updated_url
          ) {
            id
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            
            tags {
              id
              title
            }
          }
        }
      `;
    case 'note':
      return gql`
        mutation upsertNotePanel(
          $id: ID!
          $tags: String
          $notes: String
          $collections: String
          $title: String
          $state: Int
        ) {
          upsertNote(
            id: $id
            tags: $tags
            collections: $collections
            notes: $notes
            type: "notes"
            title: $title
            state: $state
          ) {
            uid
            pid
            id
            collections {
              id
              title
            }
            tags {
              id
              title
            }
            private_key
            updated_at
          }
        }
      `;
    case 'social':
      return gql`
        mutation upsertSocialPanel(
          $id: ID
          $project_id: Int
          $url: String
          $service: String
          $meta: String
          $notes: String
          $title: String
          $tags: String
          $collections: String
          $memos: [MemoInput]
          $updated_url: String
          $state: Int
          $thumbnail: String
          $file_changed: Boolean
        ) {
          upsertSocial(
            id: $id
            project_id: $project_id
            url: $url
            service: $service
            meta: $meta
            notes: $notes
            title: $title
            tags: $tags
            collections: $collections
            memos: $memos
            updated_url: $updated_url
            state: $state
            thumbnail: $thumbnail
            file_changed: $file_changed 
          ) {
            id
            pid
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            tags {
              id
              title
            }
            private_key
            updated_at
          }
        }
      `;
    case 'gallery':
      return gql`
        mutation upsertGalleryPanel(
          $id: ID
          $user_id: Int
          $title: String
          $desc: String
          $images_meta: String
          $tags: String
          $memos: [MemoInput]
          $collections: String
          $state: Int
        ) {
          upsertGallery(
            id: $id
            user_id: $user_id
            title: $title
            desc: $desc
            images_meta: $images_meta
            tags: $tags
            collections: $collections
            memos: $memos
            state: $state
          ) {
            id
            pid
            uid
            title
            desc
            images_meta
            memos {
              id
              memo
            }
            tags {
              id
              title
            }
            collections {
              id
              title
            }
            
            created_at
            updated_at
            private_key
          }
        }
      `;
    case 'page':
      return gql`
        mutation upsertPagePanel(
          $page_title: String
          $id: ID
          $url: String
          $title: String
          $image: String
          $notes: String
          $tags: String
          $collections: String
          $memos: [MemoInput]
          $updated_url: String
          $state: Int
          $file_changed: Boolean
        ) {
          upsertPage(
            page_title: $page_title
            id: $id
            url: $url
            title: $title
            image: $image
            notes: $notes
            collections: $collections
            memos: $memos
            updated_url: $updated_url
            state: $state
            tags: $tags
            file_changed: $file_changed
          ) {
            title
            page_title
            uid
            pid
            image
            id
            notes
            created_at
            updated_at
            private_key
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            
            tags {
              id
              title
            }
          }
        }
      `;
    case 'document':
      return gql`
        mutation upsertDocumentPanel(
          $id: ID
          $file: String
          $title: String
          # $image: String
          $desc: String
          $file_type: Int
          $tags: String
          $collections: String
          $memos: [MemoInput]
          $state: Int
          $name: String
          $file_changed: Boolean

        ) {
          upsertDocument(
            id: $id
            name: $name
            file: $file
            title: $title
            desc: $desc
            # image: $image
            file_type: $file_type
            tags: $tags
            collections: $collections
            memos: $memos
            state: $state
            file_changed: $file_changed
          ) {
            state
            desc
            id
            pid
            uid
            created_at
            updated_at
            file
            file_type
            name
            title
            collections {
              id
              title
            }
            memos {
              id
              memo
            }
            
            tags {
              id
              title
            }
            private_key
          }
        }
      `;
    default:
      break;
  }
  return gql`
    mutation editPanelTag(
      $id: ID!
      $tags: String!
      $collections: String
      $memos: [MemoInput]
    ) {
      upsertPanelTag(
        id: $id
        tags: $tags
        collections: $collections
        memos: $memos
      ) {
        id
        memos {
          id
          memo
        }
      }
    }
  `;
};

// generate new private key 
export const GENERATE_NEW_LINK = () => {
  return gql`
  mutation generateLink($type: String!, $id: Int!) {
    generateLink(type: $type, id: $id) {
        data
        {
          private_key
        }
        messages
        {
          error
          success
        }
        status
        http_code
    }
  }
`;
}
