import React, { useState } from 'react';


import './styles.css';
import TiptapEditor from '../../Global/Tiptap/TiptapEditor';
import Button from '../../uiComponents/Button';
import { isEmpty } from '../../../helpers';

interface Proptypes {
  onCancel: () => void;
  onSubmit: (text: string) => void;
  submitLabel?: string;
  hide?: boolean;
  mention?: {
    userName: string;
    key: string;
  };
  disabled?: boolean;
}

export default function CommentInput({
  onCancel,
  onSubmit,
  submitLabel,
  hide,
  mention,
  disabled,
}: Proptypes) {
  
  const [text, setText] = useState('');
  const isTextEmpty = isEmpty(text, true);
  return (
    <div className={`comment-input${disabled ? ' comment-input--disabled' : ''}`}>
      {!hide && (
        <>
          <TiptapEditor
            mention={mention}
            hideAllTools
            text={text}
            animated
            placeholder="Start typing your comment ..."
            label="Comment"
            edit
            onChange={setText}
          />
          <div className="comment-input-actions">
            <Button buttonType="outlined-with-background" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              disabled={isTextEmpty}
              buttonType="orange-filled"
              onClick={onSubmit.bind(null, text)}
            >
              {submitLabel || 'Save'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
