import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImagesGroupCarousel from '../../components/Global/ImagesGroup/uiComponents/ImagesGroupCarousel';
import NewPopup from '../../components/Global/NewPopup';
import { setImageCarouselPopup } from '../../redux/action/utils';
import { ReduxStateType } from '../../redux/store';
import GetScrapImages from '../data/getScrapImages';

interface imageDataType {
  id: number;
  file_path: string;
  file_name: string;
}

const ImageCarouselPopupContainer = () => {
  const dispatch = useDispatch();
  const imageCarousel = useSelector(
    (state: ReduxStateType) => state.utils?.imageCarouselPopup
  );
  const [skip, setSkip] = useState(true);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const { data } = useQuery(GetScrapImages(), {
    variables: {
      scrap_id: imageCarousel?.scrap_id,
      first: 5,
      page: page,
      private_key:imageCarousel?.private_key,
      username:imageCarousel?.username,
      skip_first:0
    },
    skip: skip || imageCarousel?.images_count === images?.length,
  });
  const imageData: Array<imageDataType> = data?.getScrapImages?.data;
  
  const returnPath = () => {
    if (imageData?.length > 0) {
      return Array.from(Object.values(imageData), (file) => file?.file_path);
    }
    return [];
  };

  function render(close: () => void) {
    return (
      <ImagesGroupCarousel images={images} fetchMoreImages={fetchMoreImages} />
    );
  }

  const fetchMoreImages = (index) => {
    setPage((prev) => prev + 1);
    imageCarousel?.images_count > 5 && setSkip(false);
  };

  useEffect(() => {
    if (imageData) {
      setImages([...images, ...returnPath()]);
    }
  }, [data]);

  useEffect(() => {
    if (imageCarousel) {
      setImages([...images, ...imageCarousel.image]);
    }
  }, [imageCarousel]);

  
  return (
    <NewPopup
      className="image-carousel-popup"
      header={{
        heading: 'Image Gallery',
      }}
      controlled={{
        show: !!imageCarousel,
        setShow: (val) => {
          dispatch(setImageCarouselPopup(null));
          setImages([]);
          setPage(1);
          setSkip(true);
        },
      }}
    >
      {render}
    </NewPopup>
  );
};

export default ImageCarouselPopupContainer;
