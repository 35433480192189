import React, { useState , useRef } from 'react';
import './styles.css';
import { useLazyQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import AuthHeaderLayout from './components/AuthHeaderLayout';
import PublicPageWrapper from '../Global/PublicPageWrapper';
import LabelTextInput from '../Global/LabelTextInput';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../Global/InvisibleRecaptcha';
import ValidateHuman from '../../helpers/validators/isHuman';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  const RESET_QUERY = () => {
    return gql`
      query forgetPassword($email: String!) {
        forgetPassword(email: $email) {
          messages {
            error
            success
          }
          status
          http_code
        }
      }
    `;
  };

  const isInvalid = () => {
    return response?.forgetPassword && response?.forgetPassword.status !== 1;
  };

  const validateEmail = () => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      ? false
      : true;
  };

  const [onReset, { loading, data: response, error }] = useLazyQuery(
    RESET_QUERY()
  );

  const handleSubmit = async () => {
    const isHuman= await ValidateHuman(reCaptchaRef.current);

    if (email.trim().length > 0 && isHuman) {
      onReset({ variables: { email: email } });
      if (response?.forgetPassword) {
        setEmail('');
      }
    }
  };

  return (
    <PublicPageWrapper>
      <AuthHeaderLayout
        title="Reset Password"
        helmetTitle="Scrappi | Account Recovery"
      >
        <>
          {response?.forgetPassword.status === 1 ? (
            <div role="alert" className="sl-auth__success">
              <h2 className="sl-auth__heading mb">Check your email</h2>
              <p className="sl-auth__message">
                If an account exists for {email}, we have sent a password reset
                link to your email address. Please check your spam folder if you
                don't see it within a minute or two.
              </p>
            </div>
          ) : (
            <>
              <h2 className="sl-auth__heading mb">Reset password</h2>
              <div className="form-group">

                <InvisibleRecaptcha inputRef={reCaptchaRef}/>
                <LabelTextInput
                  placeholder="Email"
                  inputType={'email'}
                  inputName={'email'}
                  value={email}
                  label="Email"
                  className={`input-field ${isInvalid() ? 'is-invalid' : ''}`}
                  onChange={(newEmail) => {
                    setEmail(newEmail);
                  }}
                  disabled={loading}
                  required
                  showLabelOnFocus
                />

                {isInvalid() && (
                  <span role="alert" className="sl-auth__error">
                    {response?.forgetPassword?.messages?.error[0]}
                  </span>
                )}
                {error && (
                  <span role="alert" className="sl-auth__error">
                    Unable to process your request. Please try again later.
                  </span>
                )}
              </div>

              <div className="form-group form-group__action">
                <button
                  className="button button__primary"
                  disabled={
                    loading || (email.length > 0 ? validateEmail() : true)
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Send Password Reset Link
                </button>
              </div>
            </>
          )}

          <div className="sl-form-check">
            <Link to="/login" className="button-link">
              Go back to Log In
            </Link>
          </div>
        </>
      </AuthHeaderLayout>
    </PublicPageWrapper>
  );
};

export default ForgotPassword;
