import { scrapType } from "../../types/scrapType";

export const formatScrapDataForAi = (scrapData: scrapType[], startIndex: number, numberOfResults: number) => {
  if(!scrapData) return [];

  let result = [];
  for(let i = startIndex; i < numberOfResults; i++) {
    let item = scrapData[i];
    result.push({
      "i": item.id,
      "t": item.meta_title,
      "d": item.meta_desc,
      "do": item.domain,
      "u": item.url
    });
  }

  return result;
}