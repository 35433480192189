import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import NewPopup from '../Global/NewPopup';
import { CopyLink, TripleDotIcon, TickIcon, AddIcon } from '../Global/icons';
import { handleNativeShare, handleShare, isMobileDevice, returnAllGroupMembersExceptMe } from '../../helpers';
import SearchModal from '../Global/SearchModal';

import { GroupType } from '../../containers/GroupShareContainer/types';
import ShareList from '../Global/ShareList';
import userInitials from '../../helpers/userInitial';
import { useLodashDebounce } from '../../helpers/customHooks/useDebounce';
import Button from '../uiComponents/Button';

import './styles.css';
import LeftArrow from '../Global/icons/LeftArrow';

const MemoizedShareList = React.memo(ShareList);
interface Proptypes {
  isLoadingList?: boolean;
  onSearch: (text: string) => void;
  show: boolean;
  onClose: () => void;
  shareUrl: string;
  onLoadMore: () => void;
  groups: GroupType[];
  onMultipleSend: (selected: Array<Partial<GroupType>>) => void;
  onIndividualSend: (group: Partial<GroupType>) => void;
  onInviteNew: (prefilledData?: string | number) => void;
}

interface sentGroupType extends Partial<GroupType> {
  status: 'sending' | 'sent' | 'failed';
}

export default function GroupShareMobile({
  isLoadingList,
  onSearch,
  show,
  onClose,
  shareUrl,
  onLoadMore,
  groups,
  onMultipleSend,
  onIndividualSend,
  onInviteNew
}: Proptypes) {
  const ref = useRef<HTMLDivElement>();

  const [isTyping, setIsTyping] = useState(false);
  const [selected, setSelected] = useState<Array<Partial<GroupType>>>([]);
  const [sentGroups, setSentGroups] = useState<Array<sentGroupType>>([]);
  const [copied, setCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  // @ts-ignore
  const isFromMobileApp = window.ReactNativeWebView || isMobileDevice();

  const performShare = (url: string, generate: boolean = false) => {
    handleShare(url, 'show', () => {
      navigator.clipboard.writeText(encodeURI(url || ''));
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };
  const loadMoreHandler = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      (event.target as HTMLInputElement).scrollHeight -
        (event.target as HTMLInputElement).scrollTop ===
      (event.target as HTMLInputElement).clientHeight
    ) {
      onLoadMore();
    }
  };
  const onMoreClick = () => {
    if (typeof window !== 'undefined') {
      const isShared = handleNativeShare(shareUrl, () =>
        performShare(shareUrl)
      );
      if (isShared) return;
    }
    performShare(shareUrl);
  };
  const handleCopy = () => {
    // if (typeof window !== 'undefined') {
    //   const isShared = handleNativeShare(shareUrl, () =>
    //     performShare(shareUrl)
    //   );
    //   if (isShared) return;
    // }
    // performShare(shareUrl);
    navigator.clipboard.writeText(encodeURI(shareUrl || ''));
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };
  const debouncedSearch = useLodashDebounce(function () {
    onSearch(searchTerm);
    setIsTyping(false);
  }, 500);

  const searchChangeHandler = (value: string) => {
    setSearchTerm(value);
    setIsTyping(true)
    debouncedSearch();
  };
  const onGroupSelect = useCallback((id: string, type: string) => {
    setSelected((old) => {
      if (!old?.find((item) => item.id === id)) {
        return [...old, { id: id, type: type }];
      } else
        return old.filter(function (item) {
          return item.id !== id;
        });
    });
  }, []);

  const individualSendClickHandler = useCallback(
    async (group: Partial<GroupType>) => {
      try {
        setSentGroups((old) => [...old, { ...group, status: 'sending' }]);
        await onIndividualSend(group);
        setSelected((old) => old.filter((item) => +item.id !== +group.id));
        setSentGroups((old) =>
          old.map((item) => {
            if (item.id == group.id) return { ...item, status: 'sent' };
            return item;
          })
        );
      } catch (error) {
        setSentGroups((old) =>
          old.map((item) => {
            if (item.id == group.id) return { ...item, status: 'failed' };
            return item;
          })
        );
      }
    },
    [show]
  );

  const renderHeader = useCallback((close: () => void) => {
    return (
      <>
        <button className="group-share-mobile__drag-button"></button>
        <h3 className="group-share-mobile__heading">Share to</h3>
      </>
    );
  }, []);
  const render = useCallback(() => {
    if (groups.length) {
      let showInvite = !groups.some((group) => group.type === 'contact');
      if(isLoadingList || !searchTerm || isTyping) showInvite = false;
      return (
        <>
          {showInvite && (
            <div className="group-share__invite" onClick={onInviteNew.bind(null, searchTerm)}>
              <AddIcon />
              <span>Invite new contact</span>
            </div>
          )}
          {groups.map((group) => {
            const isActive = selected?.some((item) => item.id == group.id);
            const sentListItem = sentGroups.find((item) => item.id == group.id);
            const sendStatus = sentListItem?.status;
            const showSendButton =
              (isActive && selected.length === 1) || sentListItem;
            let buttonLabel = 'Send';
            switch (sendStatus) {
              case 'sending':
                buttonLabel = 'Sending';
                break;
              case 'sent':
                buttonLabel = 'Sent';
                break;
              case 'failed':
                buttonLabel = 'Resend';
                break;

              default:
                break;
            }
            return (
              <div
                className={`group-share-mobile__list-item${
                  showSendButton ? ' group-share-mobile__list-item--with-send' : ''
                }`}
                key={group.id}
              >
                <MemoizedShareList
                  state={group.state}
                  name={group?.owner_display_name}
                  id={group.id}
                  type={group.type}
                  avatar={group?.owner_avatar}
                  user_name={group?.owner_user_name}
                  creator_initials={userInitials(group?.owner_display_name)}
                  // showAllMembers={onShowGroupMembers}
                  className="group-share__member-list"
                  onClick={onGroupSelect}
                  isActive={isActive}
                  members={group?.members?.length === 1 ? group.members : returnAllGroupMembersExceptMe(group, null, 'user_name')}
                  nickname={group.title ?? group.nickname}
                  owner_display_name={group.owner_display_name}
                  owner_name={group.owner_user_name}
                />
                {showSendButton && (
                  <Button
                    icon={
                      !sendStatus || sendStatus === 'sent' ? <TickIcon /> : null
                    }
                    className="group-share-mobile__item-send-button"
                    buttonType="orange-filled"
                    disabled={sendStatus === 'sending' || sendStatus === 'sent'}
                    onClick={individualSendClickHandler.bind(null, selected[0])}
                  >
                    {buttonLabel}
                  </Button>
                )}
              </div>
            );
          })}
        </>
      )
    }
    else {
      return (
        <div className="group-share__invite" onClick={onInviteNew.bind(null, searchTerm)}>
          <AddIcon />
          <span>Invite new contact</span>
        </div>
      );
    }
  }, [groups, selected, sentGroups, isLoadingList, isTyping]);
  useEffect(() => {
    setSelected([]);
    setSentGroups([]);
    setSearchTerm('');
  }, [show])

  useEffect(() => {
    if(ref?.current && !isLoadingList ) {
      const element = ref.current;
      const { clientHeight, scrollHeight, scrollTop } = element;
      if(scrollTop === 0 && scrollHeight === clientHeight) {
        onLoadMore()
      }
    }    
  }, [ref, groups, isLoadingList]);
  return (
    <NewPopup
      className="group-share-mobile__popup"
      header={{
        customRender: renderHeader,
      }}
      controlled={{
        show,
        setShow: (val) => {
          onClose();
        },
      }}
      footer={{
        hide: true,
      }}
    >
      {() => (
        <>
          <SearchModal
            label="Search"
            placeholder="Search Contacts and Share Groups"
            onChange={searchChangeHandler}
            value={searchTerm}
          />
          <div
            className="group-share-mobile__list"
            ref={ref}
            onScroll={loadMoreHandler}
          >
            {render()}
          </div>
          <div className="group-share-mobile__actions">
            <div className="group-share-mobile__share-actions">
              {shareUrl && (
                <button
                  onClick={handleCopy}
                  className="group-share-mobile__share-button"
                >
                  <div
                    className={`group-share-mobile__share-icon${
                      copied ? ' group-share-mobile__share-icon--copied' : ''
                    }`}
                  >
                    {copied ? <TickIcon /> : <CopyLink />}
                  </div>
                  <span className="group-share-mobile__action-label">
                    {copied ? 'Link Copied' : 'Copy Link'}
                  </span>
                </button>
              )}
              {isFromMobileApp && (
                <button
                  onClick={onMoreClick}
                  className="group-share-mobile__share-button"
                >
                  <div className="group-share-mobile__more-icon">
                    <TripleDotIcon />
                  </div>
                  <span className="group-share-mobile__action-label">More</span>
                </button>
              )}
              {selected.length > 1 && (
                <button
                  className="group-share-mobile__share-button"
                  onClick={() => {
                    onMultipleSend(selected);
                  }}
                >
                  <div className="group-share-mobile__send-icon">
                    <LeftArrow />
                  </div>
                  <span className="group-share-mobile__action-label">Send</span>
                </button>
              )}
            </div>
            <button
              onClick={onClose}
              className="group-share-mobile__footer-close-button button button__outline"
            >
              Close
            </button>
          </div>
        </>
      )}
    </NewPopup>
  );
}
