import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const FAQIcon = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5732 28C14.5732 35.5939 20.7293 41.75 28.3232 41.75C35.9172 41.75 42.0732 35.5939 42.0732 28C42.0732 20.4061 35.9172 14.25 28.3232 14.25C20.7293 14.25 14.5732 20.4061 14.5732 28ZM17.3232 28C17.3232 21.9249 22.2481 17 28.3232 17C34.3984 17 39.3232 21.9249 39.3232 28C39.3232 34.0751 34.3984 39 28.3232 39C22.2481 39 17.3232 34.0751 17.3232 28ZM29.0762 29.4657C28.9992 29.9663 28.5949 30.409 28.0367 30.409H27.8827C27.3244 30.409 26.9202 29.9855 26.8432 29.4657L26.6507 28.1568C26.5544 27.5215 26.9202 27.0787 27.5362 27.0017C29.2109 26.79 29.9809 26.2702 29.9809 25.327V25.2885C29.9809 24.4222 29.3264 23.9218 28.2099 23.9218C27.3822 23.9218 26.6699 24.172 25.9769 24.6917C25.7459 24.865 25.4379 24.9998 25.1107 24.9998C24.3792 24.9998 23.7632 24.3837 23.7632 23.6522C23.7632 23.2865 23.9172 22.9015 24.2637 22.6128C25.2839 21.7657 26.5737 21.2267 28.2677 21.2267C31.0397 21.2267 32.9262 22.6512 32.9262 25.2115V25.25C32.9262 27.6947 31.2322 28.6957 29.1339 29.1192L29.0762 29.4657ZM26.2079 33.3542V33.3158C26.2079 32.3918 26.9202 31.6795 27.8827 31.6795C28.8452 31.6795 29.5574 32.3918 29.5574 33.3158V33.3542C29.5574 34.2782 28.8452 34.9905 27.8827 34.9905C26.9202 34.9905 26.2079 34.2782 26.2079 33.3542Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default FAQIcon;
