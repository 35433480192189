import React from 'react';
import NewPopup from '../../Global/NewPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface Proptypes {
  className?: string;
  scrapTitle: string;
  deleting: boolean;
  handleDelete: () => void;
  controlled?: { show: boolean; setShow: (val: boolean) => void };
}

const DeleteScrap = (props: Proptypes) => {
  const { deleting, handleDelete, controlled } = props;
  const faSpinnerIcon = faSpinner as IconProp;
  
  function render(close: () => void) {
    return (
      <>
        {deleting && (
          <div className="delete-scrap__loading-overlay">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
          </div>
        )}
        <p className='delete-scrap__message'>Are you sure that you want to delete this Scrap?</p>
      </>
    );
  }
  return (
    <NewPopup
      className='delete-scrap'
      header={{
        heading: 'Delete Scrap',
      }}
      controlled={controlled}
      footer={{
        onSubmit: () => handleDelete(),
        submitLabel: 'Delete',
      }}
    >
      {render}
    </NewPopup>
  );
};

export default DeleteScrap;
