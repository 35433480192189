import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

interface propTypes {
  rootClass?: string;
  children: React.ReactChild | React.ReactChildren;
}

const ScrollDrag = ({ rootClass, children }: propTypes) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [clientX, setClientX] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const ref = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const onMouseDown = (e) => {
    setIsScrolling(true);
    setClientX(e.clientX);
  };

  const onMouseUp = () => {
    setIsScrolling(false);
  };

  const onMouseMove = (e) => {
    if (isScrolling) {
      ref.current.scrollLeft = scrollX - e.clientX + clientX;
      let tempX = scrollX - e.clientX + clientX
      setScrollX(tempX);
      setClientX(e.clientX);
    }
  };

  return (
    <div
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      className={rootClass} //MUST ALWAYS HAVE OVERFLOW-X AS AUTO OR SCROLL IN CSS
    >
      {React.Children.map(children, (child) =>
        React.Children.only(child)
      )}
    </div>
  );
}

ScrollDrag.defaultProps = {
  ref: { current: {} },
  rootClass: '',
};

ScrollDrag.propTypes = {
  ref: PropTypes.object,
  rootClass: PropTypes.string,
  children: PropTypes.string,
};

export default ScrollDrag;
