export const OnboardingPopStyles = {
  options: {
    arrowColor: '#474747',
    backgroundColor: '#474747',
    textColor: '#fff',
    zIndex: 1000,
    width: 250,
  },
  buttonNext: {
    backgroundColor: 'transparent',
    color: '#EB6F19',
  },
}

export const OnboardingPopBaseStepsStyles = {
  tooltipContainer: {
    textAlign: 'left',
    paddingTop: 24,
  },
  tooltipTitle: {
    padding: '12px 0 0',
  },
  tooltipContent: {
    padding: 0,
  },
}