import { entryTypes } from "../../../types/feed";
import { scrapType } from "../../../types/scrapType";

export const getCardTitle = (entry: entryTypes | scrapType, full = false) => {
  /**
   * PREVIOUS CODE:
   * const panelTitle = entry.title||entry.page_title;
   * 
   * ADDED - ENTRY.TYPE === SCREENSHOT 
   * FOR - IRRESPECTIVE OF TITLE FOUND OR NOT, RETURN TITLE RATHER THAN PAGE_TITLE
   * 
   * REASON - 
   *    THE TITLE WAS PREVIOUSLY WORKING IN A MANNER THAT WILL RETURN PAGE TITLE IF TITLE WAS NOT FOUND
   *    AS REQUESTED IF THE MAIN TITLE IS NOT THERE WE SHOULD NOT RETURN THE PAGE TITLE (FOR SCREENSGHOT, AT THE MOMENT OF THIS CODE UPDATE.
   */
  const panelTitle = entry.title;

  if (!panelTitle) {
    return null;
  }

  return panelTitle;
  // use this to limit the length of the title 
  // return panelTitle?.length <= 100 ?
  //   panelTitle :
  //   `${panelTitle.substr(0, 97)}...`;
};
