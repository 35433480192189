import React from 'react';

interface Proptypes extends React.SVGAttributes<SVGSVGElement> {}

const ContactsIcon = (props: Proptypes) => {
  const elementAttributes = { ...props };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...elementAttributes}
    >
      <path
        d="M4.45701 11.6679C5.74441 11.6679 6.78805 10.6243 6.78805 9.3369C6.78805 8.0495 5.74441 7.00586 4.45701 7.00586C3.16962 7.00586 2.12598 8.0495 2.12598 9.3369C2.12598 10.6243 3.16962 11.6679 4.45701 11.6679Z"
        fill="#969696"
      />
      <path
        d="M19.5534 11.6679C20.8408 11.6679 21.8845 10.6243 21.8845 9.3369C21.8845 8.0495 20.8408 7.00586 19.5534 7.00586C18.2661 7.00586 17.2224 8.0495 17.2224 9.3369C17.2224 10.6243 18.2661 11.6679 19.5534 11.6679Z"
        fill="#969696"
      />
      <path
        d="M6.67712 12.888C6.12211 12.333 5.2341 12 4.45709 12C2.45905 12 0.905029 13.554 0.905029 15.5521V18.3271C0.905029 18.7711 1.23803 19.1041 1.68204 19.1041H6.01111V15.6631C6.01111 14.553 6.34412 13.665 6.67712 12.888Z"
        fill="#969696"
      />
      <path
        d="M19.5534 12C18.6654 12 17.8884 12.333 17.2224 12.888C17.6664 13.665 17.8884 14.553 17.8884 15.5521V18.9931H22.2175C22.6615 18.9931 22.9945 18.6601 22.9945 18.2161V15.4411C23.1055 13.554 21.5515 12 19.5534 12Z"
        fill="#969696"
      />
      <path
        d="M16.6672 13.6647C15.8902 11.8887 14.1141 10.5566 12.0051 10.5566C9.89606 10.5566 8.12003 11.8887 7.34302 13.6647C7.12101 14.2197 7.01001 14.8857 7.01001 15.5517V18.9928V19.7698C7.01001 20.2138 7.34302 20.5468 7.78702 20.5468H16.2232C16.6672 20.5468 17.0002 20.2138 17.0002 19.7698V18.9928V15.5517C17.0002 14.8857 16.8892 14.3307 16.6672 13.6647Z"
        fill="#969696"
      />
      <path
        d="M12.0051 10.1132C13.8442 10.1132 15.3352 8.62232 15.3352 6.78318C15.3352 4.94404 13.8442 3.45312 12.0051 3.45312C10.166 3.45312 8.67505 4.94404 8.67505 6.78318C8.67505 8.62232 10.166 10.1132 12.0051 10.1132Z"
        fill="#969696"
      />
    </svg>
  );
};

export default ContactsIcon;
