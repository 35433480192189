import React from 'react';
import {
  Doc,
  PdfIcon,
  PlusIcon,
  Ppt,
  VideoIcon,
  Xls,
} from '../../Global/icons';
import './styles.css';

interface Proptypes {
  type: 'video' | 'document';
  link: string;
  fileName: string;
  fileType?: Number;
  onReplace?: () => void;
}

const ScrapSavedFiles = ({
  type,
  link,
  fileName,
  fileType,
  onReplace,
}: Proptypes) => {
  const fileIcon = (fileType) => {
    switch (String(fileType)) {
      case '1':
        return <PdfIcon />;
      case '2':
        return <Ppt />;
      case '3':
        return <Xls />;
      case '4':
      case '5':
        return <Doc />;
    }
  };
  function linkClickHandler() {
    if (link && window) window.open(link, '_blank');
  }
  return (
    <div className="scrap-saved-files">
      <span className="scrap-saved-files__icon">
        {type === 'video' ? <VideoIcon /> : fileIcon(fileType)}
      </span>
      <span
        onClick={linkClickHandler}
        className={`scrap-saved-files__file-name${
          link ? ' scrap-saved-files__file-name--clickable' : ''
        }`}
      >
        {fileName}
      </span>
      {onReplace && (
        <button
          onClick={onReplace}
          className="scrap-saved-files__replace"
        >
          <PlusIcon />
        </button>
      )}
    </div>
  );
};

export default ScrapSavedFiles;
