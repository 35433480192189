import React from 'react';

interface Proptypes {
  className?: string;
  onClick?:()=>void;
}

const Private = ({ className,onClick }: Proptypes) => {
  return (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66602 6.50033V5.66699C2.66602 3.82604 4.1584 2.33366 5.99935 2.33366C7.8403 2.33366 9.33268 3.82604 9.33268 5.66699V6.50033L2.66602 6.50033ZM0.999349 6.78787V5.66699C0.999349 2.90557 3.23793 0.666992 5.99935 0.666992C8.76077 0.666992 10.9993 2.90557 10.9993 5.66699V6.78787C11.5007 7.11057 11.8327 7.67354 11.8327 8.31405V11.5003C11.8327 14.722 9.22101 17.3337 5.99935 17.3337C2.77769 17.3337 0.166016 14.722 0.166016 11.5003V8.31405C0.166016 7.67354 0.498032 7.11057 0.999349 6.78787ZM1.97974 8.16699C1.89852 8.16699 1.83268 8.23283 1.83268 8.31405L1.83268 11.5003C1.83268 13.8015 3.69816 15.667 5.99935 15.667C8.30054 15.667 10.166 13.8015 10.166 11.5003V8.31405C10.166 8.23283 10.1002 8.16699 10.019 8.16699H9.33268H2.66602H1.97974ZM5.99935 9.83366C6.91982 9.83366 7.66602 10.5799 7.66602 11.5003C7.66602 12.4208 6.91982 13.167 5.99935 13.167C5.07887 13.167 4.33268 12.4208 4.33268 11.5003C4.33268 10.5799 5.07887 9.83366 5.99935 9.83366Z"
        fill="#969696"
      />
    </svg>
  );
};

export default Private;
