const storageListener = (itemKey: string, cb: (error: string) => void) => {
  try {
    window.addEventListener('storage', () => {
      const storageValue = window.localStorage.getItem(itemKey);
      cb(storageValue ?? '');
    });
  } catch {
    cb('');
  }
};

export default storageListener;
