import { moveDataToIndex } from '../../../helpers/arrayHelpers';
import { newStoryType, storyType } from '../../../types/stories';

interface actionTypes {
  newStory: newStoryType;
  stories: [storyType];
  type: string;
  payload?;
}

const stories = (state: [storyType] | [] = [], action: actionTypes) => {
  let itemArr = [...state];
  switch (action.type) {
    case 'LOAD_STORIES':
      return [...state, ...action.stories];
    case 'RESET_STORIES':
      return [];

    case 'ADD_STORY':
      return [action.newStory, ...state];

    case 'REMOVE_STORY':
      itemArr.splice(
        itemArr.findIndex((i) => i.id === action.stories[0].id),
        1
      );
      return [...itemArr];
    case 'MOVE_STORIES':
      return moveDataToIndex(
        0,
        state,
        action.payload,
        (d1, d2) => +d1.id === +d2
      );
    default:
      return state;
  }
};

export default stories;
