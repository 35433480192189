import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewPopup from '../../components/Global/NewPopup';
import ShareList from '../../components/Global/ShareList';
import { setShowMember } from '../../redux/action/utils';
import { ReduxStateType } from '../../redux/store';
import './styles.css';
import userInitials from '../../helpers/userInitial';
import { useHistory } from 'react-router-dom';

export const ShowMemberContainer = () => {
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const showMember = useSelector(
    (state: ReduxStateType) => state?.utils?.showMemberPopup
  );
  const { clickable, heading } = showMember || {};
  function render(close) {
    return (
      <>
        {showMember?.member?.map((user, key) => {
          return (
            <ShareList
              onClick={clickable ? () => {
                close()
                replace(`/${user.user_name}`)
              } : null}
              name={user.display_name}
              id={user.user_id?.toString()}
              avatar={user?.avatar}
              user_name={user?.user_name}
              creator_initials={userInitials(user.display_name)}
              type="contact"
              key={key}
              className="show-member-popup__avatar"
              members={showMember.member}
            />
          );
        })}
      </>
    );
  }
  return (
    <NewPopup
      className="show-member-popup"
      header={{
        heading: heading ?? 'Share Group',
      }}
      controlled={{
        show: !!showMember,
        setShow: (val) => {
          dispatch(setShowMember(null));
        },
      }}
    >
      {render}
    </NewPopup>
  );
};
