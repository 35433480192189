import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const AllDirectionsDrag = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0348 13.5359C6.70007 13.2012 6.15735 13.2012 5.82262 13.5359C5.48788 13.8707 5.48788 14.4134 5.82262 14.7481L8.39405 17.3195C8.72878 17.6543 9.2715 17.6543 9.60623 17.3195L12.1777 14.7481C12.5124 14.4134 12.5124 13.8707 12.1777 13.5359C11.8429 13.2012 11.3002 13.2012 10.9655 13.5359L9.85728 14.6441V9.85631H14.6451L13.5369 10.9645C13.2022 11.2992 13.2022 11.8419 13.5369 12.1767C13.8716 12.5114 14.4144 12.5114 14.7491 12.1767L17.3205 9.60525C17.6553 9.27052 17.6553 8.72781 17.3205 8.39307L14.7491 5.82164C14.4144 5.48691 13.8716 5.48691 13.5369 5.82164C13.2022 6.15638 13.2022 6.69909 13.5369 7.03383L14.6451 8.14202H9.85728V3.3542L10.9655 4.4624C11.3002 4.79713 11.8429 4.79713 12.1777 4.4624C12.5124 4.12766 12.5124 3.58495 12.1777 3.25021L9.60623 0.678785C9.44549 0.51804 9.22747 0.427734 9.00014 0.427734C8.77281 0.427734 8.55479 0.51804 8.39405 0.678785L5.82262 3.25021C5.48788 3.58495 5.48788 4.12766 5.82262 4.4624C6.15735 4.79713 6.70007 4.79713 7.0348 4.4624L8.143 3.3542V8.14202H3.35518L4.46337 7.03383C4.79811 6.69909 4.79811 6.15638 4.46337 5.82164C4.12864 5.48691 3.58592 5.48691 3.25119 5.82164L0.679761 8.39307C0.519016 8.55382 0.428711 8.77183 0.428711 8.99916C0.428711 9.22649 0.519016 9.44451 0.679761 9.60525L3.25119 12.1767C3.58593 12.5114 4.12864 12.5114 4.46337 12.1767C4.79811 11.8419 4.79811 11.2992 4.46337 10.9645L3.35518 9.85631H8.143V14.6441L7.0348 13.5359Z"
        fill="white"
      />
    </svg>
  );
};

export default AllDirectionsDrag;
