export const isEmpty = (data: string, html: boolean) => {
  if (typeof window === 'undefined') {
    return false;
  } 
  if(data==null)
  {
    return true
  }
  if (html) {
    let ConvertStringToHTML = function (string) {
      let parser = new DOMParser();
      let doc = parser.parseFromString(string, 'text/html');
      return doc.body.textContent;
    };
    const dataString = ConvertStringToHTML(data);
    const dataStringLength = dataString.length;
    if(!dataString?.trim()) return true;
    if (dataStringLength === 0) {
      return true;
    }
    return false;
  } else {
    if (data?.trim().length === 0) {
      return true;
    }
    return false;
  }
};
