import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { connect, useDispatch } from 'react-redux';
import Multiselect from 'react-widgets/lib/Multiselect';
import { containsSpecialCharacter } from '../../helpers/validators';
import { addNewTag } from '../../redux/action/tagAction';
import useWindowOnClick from '../../helpers/customHooks/useWindowOnclick';
import { tagType } from '../../types/tags';
import './styles/TagsListStyles.css';
import TagsView from './TagsView';
import { TagIcon } from './icons';
import { PropHelpers } from './CheckboxList';
import { entryTypes } from '../../types/feed';
import { NewTagIcon } from './icons';
const ADD_TAG = gql`
  mutation AddTag($title: String!) {
    upsertTag(title: $title) {
      id
      title
    }
  }
`;
const GET_TAGS = gql`
  query GetTags {
    tags {
      data {
        id
        title
      }
    }
  }
`;

interface propTypes {
  hideLess?: boolean;
  dropUp?: boolean;
  limitTags?: boolean;
  tags: tagType[];
  edit?: boolean;
  getUpdatedTags?: (newTagList: tagType[]) => void;
  showAll?: boolean;
  loading?: boolean;
  disableItemClick?: boolean;
  view?: string;
  onSave?: (data?: Partial<entryTypes>) => void;
  savingStatus?: string | null;
  label?: string;
  addIcon?: boolean;
  isNew?: boolean;
  setOnSaveCollectionList?:(val:boolean)=>void;
  onSaveCollectionList?: boolean;
  showMore?:boolean;
  onMoreClick?: (currentlyOpened) => boolean;
  defaultExpanded?: boolean;
}

/**
 * TODO: Send the value of the udpated tags outside
 */
export const TagsList = (props: propTypes) => {
  const {
    hideLess,
    tags,
    edit,
    getUpdatedTags,
    loading: disable,
    dropUp,
    view,
    onSave,
    savingStatus,
    label,
    isNew,
    addIcon,
    disableItemClick,
    setOnSaveCollectionList,
    onSaveCollectionList,
    showMore,
    onMoreClick,
    defaultExpanded,
  } = props;
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [allTagsState, setAllTagsState] = useState<tagType[]>([]);
  const [scrapTags, setScrapTags] = useState<tagType[]>([]);
  const [message, setMessage] = useState<string | null>(null);
  const [tagData, setTagData] = useState<PropHelpers['dataType'][]>([]);

  // If edit mode is false then skip the query of fetching all tags
  const { loading: tagsLoading, data } = useQuery(GET_TAGS, { skip: !edit });
  const [upsertTag, { loading }] = useMutation(ADD_TAG);
  const DISPATCH = useDispatch();
  const handleSearch = (val: string) => {
    if (containsSpecialCharacter(val)) return;
    if (val.trim().length <= 60) setSearch(val);
  };
  useEffect(() => {
    // set the list of all the tags &
    // tags in the scraps
    // in component state for easy updates
    if (data) setAllTagsState((data?.tags?.data || []).map((tag) => ({
      slug: `${((tag.title || '') as string).toLowerCase()}-${tag.id}`
    })));
  }, [data]);
  useEffect(() => {
    setScrapTags(tags);
  }, [tags]);

  useEffect(() => {
    setTagData(tags);
  }, []);

  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement = el.closest('.tags-list__wrapper');
    if (!closestElement) setOpen(false);
  };

  useWindowOnClick({
    cb: handleOutSideClick,
    removeListener: !open,
  });
  // make a request to create a new tag
  // keep the id as it will be needed to add tag to the scrap
  // * add the new tag to the global tag list
  const onCreate = (name: string) => {
    if (!name || name.trim() === '') return;
    upsertTag({
      variables: {
        title: name,
      },
    })
      .then((res) => {
        const data = res.data.upsertTag
        const newTag = {
          slug: `${data.title}-${data.id}`
        };
        // add to the local state
        setAllTagsState((tagsState) => [newTag, ...tagsState]);
        setScrapTags((scrapTagsState) => [...scrapTagsState, newTag]);
        getUpdatedTags([...scrapTags, newTag]);
      })
      .catch((err) => {
        setMessage('Error saving new Tag! please try again');
      });
  };

  const onchange = (newTags: tagType[]) => {
    setScrapTags(newTags);
    getUpdatedTags(newTags);
  };

  if (!tags) {
    return null;
  }

  if (tags?.length < 1 && !edit && view !== 'scrap-details') {
    return null;
  }

  if (edit) {
    return (
      <div
        onClick={(e) => {
          const el = e.target as HTMLElement;
          if (el.tagName !== 'li') setOpen(true);
        }}
        className="tags-list__wrapper"
      >
        <h3 className="title__small">Tags</h3>
        <Multiselect
          dropUp={dropUp}
          open={open}
          data={allTagsState}
          disabled={loading || disable}
          allowCreate
          onCreate={(name) => onCreate(name)}
          onChange={(name) => onchange(name)}
          valueField="id"
          textField="title"
          value={scrapTags}
          busy={loading || tagsLoading}
          searchTerm={search}
          onSearch={handleSearch}
        />
        {tagsLoading && <span className="tags-list__loading">Loading...</span>}

        {message && <span className="message__error">{message}</span>}
      </div>
    );
  }

  // FOR CUSTOM PROPS WHEN A CERTAIN VIEW IS LOADED
  var customProps = [];
  if (view === 'scrap-details') {
    customProps['showEdit'] = true;
    customProps['showEditLabel'] = 'Tags';
  }
  return (
    <TagsView
      hideLess={hideLess}
      defaultExpanded={defaultExpanded}
      disableItemClick={disableItemClick}
      list={tags}
      showMore={showMore}
      valueField="id"
      labelField="title"
      {...customProps}
      dataType="tagType"
      data={tagData}
      setData={setTagData}
      onSave={onSave}
      savingStatus={savingStatus}
      label={label}
      addIcon={addIcon}
      isNew={isNew}
      setOnSaveCollectionList={setOnSaveCollectionList}
      onSaveCollectionList={onSaveCollectionList}
      onMoreClick={onMoreClick}
    >
      <NewTagIcon/>
    </TagsView>
  );
};



export default TagsList;
