import { gql } from '@apollo/client';

export const ACCEPT_CANCEL_INVITATION= gql`
  mutation acceptOrCancelContactRequest(
    $userName: String!
    $isCancelled: Boolean
    $isAccepted: Boolean
  ) {
    acceptOrCancelContactRequest(
      userName: $userName
      isCancelled: $isCancelled
      isAccepted: $isAccepted
    ) 
    {
        status
        messages{
        error
        success
      }
     }
  }
`;
