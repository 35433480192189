import { CollectionSortConfigType } from "../containers/CollectionPageContainer/types";

export default function returnActiveCollectionSort({
  sort_by,
  sort_param,
}: CollectionSortConfigType){
  if (sort_by === 'desc' && sort_param === 'created_at') return 'Newest';
  else if (sort_by === 'asc' && sort_param === 'scrap_created_at')
    return 'Oldest';
  else return 'Custom';
}
