import React, { AnchorHTMLAttributes } from 'react';
import LinkIcon from '../icons/LinkIcon';

import './styles.css';

interface Proptypes {
  attributes: AnchorHTMLAttributes<HTMLAnchorElement>;
  children: React.ReactChild | React.ReactChildren;
  displayFullUrl?: boolean;
  url?:string;
  domain?:string;
}

const CtaButton = ({ attributes, children, displayFullUrl,url }: Proptypes) => {
  return (
    <a title={!displayFullUrl ? url : typeof children === 'string' ? children : null}  className={`cta${displayFullUrl ? ' cta--full-url' : ''}`} {...attributes}>
      <span className="cta__display-text">{children}</span>
      {!displayFullUrl && <span className='card-link-preview__link-icon'>
        <LinkIcon />
      </span>}
    </a>
  );
};

export default CtaButton;
