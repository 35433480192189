import { gql } from '@apollo/client';

const CANCEL_STRIPE_SUBSCRIPTION = gql`query CancelStripeSubscription {
    cancelStripeSubscription {
      data {
        is_cancelled
      }
      messages {
        error
        success
        __typename
      }
      status
      http_code
      __typename
    }
  }`;

export default CANCEL_STRIPE_SUBSCRIPTION;