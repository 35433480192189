import React, { ForwardedRef, MutableRefObject, forwardRef } from 'react';

import './styles.css';
import { collectionType } from '../../types/collections';
import { scrapType } from '../../types/scrapType';
import ItemsMasonry, { ItemsMasonryGrid } from '../ItemsMasonry';
import NewCollectionCard from '../NewCollectionCard';
import { collectionCardFooterOptionsType } from '../NewCollectionCard/UiComponents/NewCollectionFooter';
import EmptyPageMessage from '../Global/EmptyPageMessage';
import { GroupType } from '../../containers/GroupShareContainer/types';
import { useHistory, useLocation } from 'react-router-dom';
import { parseUrlParams } from '../../helpers';
import { LeftArrowIcon } from '../Global/icons';
import BrokenLinkComponent from '../Global/Error/Components/BrokenLinkComponent';
import SEOMeta from "../Meta/SEOMeta";
import view from "../Global/icons/View";
import Chat from "../Chat";

interface Proptypes {
  isBookmarked?: boolean;
  onBookmarkToggled?: (action: 'save' | 'unsave') => void;
  draggableTimeLineRenderer?: React.ReactElement;
  masonryKey: string;
  collection: collectionType | GroupType;
  scraps: scrapType[];
  collectionLoading: boolean;
  scrapsLoading: boolean;
  children: (scrap: scrapType, index: number) => React.ReactElement;
  onLoadMore: () => void;
  isReadonly?: boolean;
  footerDropdownOptions?: collectionCardFooterOptionsType;
  scrapCount?: number;
  type?: 'group' | 'collection';
  isTrusted?: boolean;
  trustCb?: (val: boolean) => void;
}

const CollectionPage = forwardRef(
  ({
    isBookmarked,
    onBookmarkToggled,
    draggableTimeLineRenderer,
    masonryKey,
    collection,
    scraps,
    scrapsLoading,
    collectionLoading,
    onLoadMore,
    children,
    footerDropdownOptions,
    scrapCount,
    type,
    isTrusted,
    trustCb,
  }: Proptypes, ref: ForwardedRef<HTMLDivElement> ) => {
    
    const { search } = useLocation();
    const history = useHistory()
    const parsedSearch = parseUrlParams(search);
    const comingFrom = parsedSearch?.from as string;
    const isServer = typeof window === 'undefined';
    
    let { view_type } = (collection as collectionType) || {};
    if (type === 'group') view_type = 3;
    function renderCollection() {
      if (!collection) return null;
      // render collection card
      return (
        <NewCollectionCard
          isBookmarked={isBookmarked}
          onBookmarkToggled={onBookmarkToggled}
          footerDropdownOptions={footerDropdownOptions}
          data={collection}
          scrapCount={scrapCount}
          type={type}
          isTrusted={isTrusted}
          trustCb={(status => trustCb(status))}
        />
      );
    }
    function renderScraps() {
      if (scraps?.length) {
        if (draggableTimeLineRenderer) return draggableTimeLineRenderer;
        if (view_type !== 2) {
          return (
            <ItemsMasonry<scrapType>
              key={masonryKey}
              containerRef={ref as MutableRefObject<HTMLElement>}
              items={scraps}
              loading={scrapsLoading}
              onLoadMore={onLoadMore}
              columnCount={view_type === 3 ? 1 : null}
              overscanBy={2}
              columnGutter={18}
              columnWidth={350}
              className={`collection-page__masonry${
                (view_type === 3 || type === 'group') ? ' collection-page__masonry--story' : ''
              }`}
            >
              {children}
            </ItemsMasonry>
          );
        } else
          return (
            <ItemsMasonryGrid<scrapType>
              items={scraps}
              loading={scrapsLoading}
              keyField="id"
              onLoadMore={onLoadMore}
            >
              {children}
            </ItemsMasonryGrid>
          );
      } else if (scrapsLoading || collectionLoading) return null;
      return (
        <EmptyPageMessage header={{}}>
          {type === 'group' ? (
            <>
              No Scraps found in this Share.
            </>
          ) : (
            <>
              Add Scraps to this Collection as you save or edit them. Scraps that are not assigned to any Collections will appear on your "Uncategorized" page.
            </>
          )}
        </EmptyPageMessage>
      );
    }

    if(!collection) {
      return null;
    }

    // create a list of all the user, including the owner of the collection
    const listOfMembers = collection?.members?.map((member) => member) || [];
    const allUsers = [
      ...listOfMembers,
      {
        user_id: collection?.user_id,
        avatar: 'avatar' in collection && collection?.avatar,
        display_name: 'display_name' in collection && collection?.display_name,
        username: 'user_name' in collection && collection?.user_name,
      }
    ]

    console.log('allUsers', allUsers);

    return (
      <>
        <SEOMeta
          title={collection?.title || collection?.nickname}
          description={collection?.desc}
          image={collection?.cover_image}
        />

        <div className={`collection-page collection-page--${type || 'collection'}${view_type === 3 ? ' story' : ''}`} ref={ref}>
          {comingFrom?.length > 0 && (
            <button
              className="button button-back-to-results hoverState__with-white-text"
              onClick={() => {
                history.replace(comingFrom);
              }}
            >
              <LeftArrowIcon />
              {comingFrom.startsWith('/search')
                ? 'Back to Search Results'
                : 'Back To Profile'}
            </button>
          )}
          {!collection?.id && !collectionLoading ? (
            <BrokenLinkComponent hideGoHome />
          ) : (
            <>
              {renderCollection()}
              {renderScraps()}
            </>
          )}

          {
            (!isServer && collection && scraps) && (
              <Chat
                title={collection.title}
                scrapIds={scraps.map(scrap => scrap.id)}
                userList={allUsers}
                collectionId={collection.id}
                isGroup={type === 'group'}
                noUserFilter
              />
            )
          }
        </div>
      </>
    );
  }
);

export default CollectionPage
