import React, { useCallback, useEffect, useState } from 'react';
import ItemsMasonryGrid from './ItemsMasonryGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './styles.css';
// import { Masonry, MasonryProps, useInfiniteLoader } from 'masonic';
import {
  usePositioner,
  useResizeObserver,
  useMasonry,
  useInfiniteLoader,
} from 'masonic';
import { useScroller } from 'mini-virtual-list';
import { calculateElementDimensions } from '../../helpers';

const faArrowUpIcon = faArrowUp as IconProp;

interface Proptypes<T> {
  items: T[];
  onLoadMore: () => void;
  children: (item: T, index: number, width: number) => React.ReactElement;
  loading: boolean;
  containerRef: React.MutableRefObject<HTMLElement>;
  columnGutter?: number;
  columnWidth?: number;
  overscanBy: number;
  columnCount?: number;
  rowGutter?: number;
  className?: string;
  masonryKey?: string;
}

let loadedGalleryItemsIndexes = [];

function ItemsMasonry<itemType>({
  children,
  items,
  onLoadMore,
  loading,
  containerRef,
  columnCount,
  columnGutter,
  rowGutter,
  columnWidth,
  overscanBy,
  className,
  masonryKey,
}: Proptypes<itemType>) {
  // const { width, height } = useSize(containerRef);
  const [dimensions, setDimensions] = useState(
    typeof window === "object" ?
      calculateElementDimensions(containerRef?.current, true) :
      {width: 0, height: 0}
  );
  const { width, height } = dimensions;
  // @ts-ignore
  const { scrollTop, isScrolling } = useScroller(containerRef);
  const positioner = usePositioner(
    {
      width,
      columnWidth,
      columnGutter,
      columnCount,
      rowGutter,
    },
    [masonryKey]
  );

  const resizeObserver = useResizeObserver(positioner);
  const maybeLoadMore = useInfiniteLoader(
    async (startIndex, endIndex, currentItems) => {
      if (!loading) {
        if (onLoadMore) onLoadMore();
        const newIndex = { endIndex, startIndex };
        const existing = loadedGalleryItemsIndexes.find((el) => {
          return (
            el.startIndex === newIndex.startIndex &&
            el.endIndex === newIndex.endIndex
          );
        });
        if (existing) return;
        loadedGalleryItemsIndexes.push(newIndex);
        // get the data
      }
    },
    {
      isItemLoaded: (index, items) => !!items[index] && !loading,
      minimumBatchSize: 15,
      threshold: 15,
    }
  );

  const render = useCallback(
    ({ data, index, width }: { data: itemType; index: number, width: number }) => {
      return children(data, index, width);
    },
    [children]
  );
  useEffect(() => {
    loadedGalleryItemsIndexes = [];
    return () => {
      loadedGalleryItemsIndexes = [];
    };
  }, [masonryKey]);
  useEffect(() => {
    const el = containerRef?.current;
    if(el) {
      el.style.scrollBehavior = 'smooth';
    }
  }, [containerRef])
  useEffect(() => {
    const el = containerRef?.current;
    if (el && typeof window !== 'undefined') {
      const handleResize = () =>
        setDimensions(calculateElementDimensions(el, true));
      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
      };
    }
  }, [containerRef]);

  const scrollToTopHandler = () => {
    const el = containerRef.current
    el.scroll(0,0);
  }
  
  return (
    <>
      {scrollTop > height && (
        <button
          className="top-button hoverState-button"
          onClick={() => scrollToTopHandler()}
        >
          <FontAwesomeIcon icon={faArrowUpIcon} />
        </button>
      )}
      {useMasonry({
        onRender: maybeLoadMore,
        positioner,
        resizeObserver,
        items,
        height,
        scrollTop,
        isScrolling,
        overscanBy,
        render,
        className,
      })}
    </>
  );
  // return useMasonry({
  //   onRender: maybeLoadMore,
  //   positioner,
  //   resizeObserver,
  //   items,
  //   height,
  //   scrollTop,
  //   isScrolling,
  //   overscanBy,
  //   render,
  //   className,
  // });
}

export { ItemsMasonry as default, ItemsMasonryGrid };
