import React from 'react';

interface Proptypes {
  className?: string;
}

const ReplyIcon = ({ className }: Proptypes) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className || ''}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.5 11.2988V4.51219C1.5 3.26282 2.50736 2.25 3.75 2.25H14.25C15.4926 2.25 16.5 3.26282 16.5 4.51219V11.2988C16.5 12.5481 15.4926 13.561 14.25 13.561H11.0636L9.1097 15.2799C7.89735 16.3464 6 15.4808 6 13.8612V13.561H3.75C2.50736 13.561 1.5 12.5481 1.5 11.2988ZM7.32089 12.8457C7.06746 12.3736 6.57099 12.0528 6 12.0528H3.75C3.33579 12.0528 3 11.7152 3 11.2988V4.51219C3 4.09573 3.33579 3.75813 3.75 3.75813H14.25C14.6642 3.75813 15 4.09573 15 4.51219V11.2988C15 11.7152 14.6642 12.0528 14.25 12.0528H11.0636C10.7003 12.0528 10.3493 12.1854 10.0758 12.426L8.12194 14.1449C7.87947 14.3582 7.5 14.1851 7.5 13.8612V13.561C7.5 13.5145 7.49791 13.4686 7.49382 13.4232C7.47594 13.2247 7.4198 13.0373 7.33287 12.8685L7.32089 12.8457ZM9.00051 5.25C8.58629 5.25 8.25051 5.58579 8.25051 6V7.37132H6.87868C6.46447 7.37132 6.12868 7.70711 6.12868 8.12132C6.12868 8.53553 6.46447 8.87132 6.87868 8.87132H8.25051V10.2426C8.25051 10.6569 8.58629 10.9926 9.00051 10.9926C9.41472 10.9926 9.75051 10.6569 9.75051 10.2426V8.87132H11.1213C11.5355 8.87132 11.8713 8.53553 11.8713 8.12132C11.8713 7.70711 11.5355 7.37132 11.1213 7.37132H9.75051V6C9.75051 5.58579 9.41472 5.25 9.00051 5.25Z"
        fill="#84929A"
      />
    </svg>
  );
};

export default ReplyIcon;
