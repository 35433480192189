import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';

const GET_COUNTRIES = gql`
  query Countries {
    countries {
      data {
        name
        official
        capital
        calling_codes
      }
      ${defaultResponse}
    }
  }
`;

export default GET_COUNTRIES;
