import React from 'react';
import PublicNavigation from '../Navigations/PublicNavigation';

const PublicPageWrapper = (props) => {
  return (
    <>
      <PublicNavigation hideActionButtons={props.hideActionButtons ?? false} />
      {props.children}
    </>
  );
};

export default PublicPageWrapper;
