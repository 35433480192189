import React from 'react';
import { PlusIcon } from '../../../Global/icons';
import './styles.css';

interface Proptypes {
  onClick: () => void;
  active?: boolean;
}

const CreateScrapNavIcon = ({ onClick, active }: Proptypes) => {
  const baseClass = 'create-scrap-nav-icon';
  return (
    <span
      className={`${baseClass}${active ? ` ${baseClass}--active` : ''}`}
    >
      <div className="create-scrap-nav-icon__btn-add">
        <button
          onClick={onClick}
          className="create-scrap-nav-icon__add hoverState__with-white-text"
        >
          <PlusIcon className="create-scrap-nav-icon__plus" />
          <span className="create-scrap-nav-icon__new">New</span>
        </button>
      </div>
    </span>
  );
};

export default CreateScrapNavIcon;
