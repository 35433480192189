import React, { useState, useEffect } from 'react';
import { GET_USER_STORAGE } from '../../../containers/data/getUserStorage';
import { useQuery } from '@apollo/client';
import PricingWrapper from '../../../containers/PricingContainer';
import PricingPageContainer from '../../../containers/PricingPageContainer';
import GET_CUSTOMER_CARD_STATUS from '../../../containers/data/getCustomerCardStatus';

interface ExceedStorage {
  file: boolean;
  video: boolean;
  fileAboutToExceed: boolean;
  videoAboutToExceed: boolean;
}

interface StorageData {
  type: string;
  total: {
    value: number;
    type: string;
  };
  used: {
    value: number | string;
    type: string;
  };
}

const StorageComponent = () => {

  // Temporarily commented below code bcz of the client's request
  // const {
  //   data: customerCardStatus,
  //   loading: customerCardStatusLoading,
  // } = useQuery(GET_CUSTOMER_CARD_STATUS);

  const userStorageDummyData = [
    {
      type: 'file',
      total: {
        value: 50,
        type: 'GB',
      },
      used: {
        value: '...',
        type: 'GB',
      },
    },
    {
      type: 'video',
      total: {
        value: 1000,
        type: 'Minutes',
      },
      used: {
        value: '...',
        type: 'Minutes',
      },
    },
  ];

  const { data, loading } = useQuery(GET_USER_STORAGE);
  const [userStorage, setUserStorage] = useState<StorageData[]>(
    userStorageDummyData
  );
  const [storageExceeded, setStorageExceeded] = useState<ExceedStorage | null>(
    null
  );

  // Temporarily commented below code bcz of the client's request
  // const [userCardStatus, setUserCardStatus] = useState(null)


  const checkFileType = (type: string) => {
    const typeData = userStorage.filter((f) => f.type === type);
    if (typeData?.length) {
      return `${typeData[0].used.value} ${typeData[0].used.type} / ${typeData[0].total.value} ${typeData[0].total.type}`;
    }
    return null;
  };

  useEffect(() => {
    const checkStorageExceeded = (storageData) => {
      const fileData = storageData.filter((a) => a.type === 'file');
      const videoData = storageData.filter((a) => a.type === 'video');
      const tempData = {
        file: false,
        fileAboutToExceed:false,
        videoAboutToExceed:false,
        video: false,
      };
      let touched = false;

      if (fileData?.length) {
        if(fileData[0].used.type === fileData[0].total.type){
          if(parseInt(fileData[0].used.value) > 45){
            tempData.fileAboutToExceed = true;
            touched = true;
          }
          if (parseInt(fileData[0].used.value) > parseInt(fileData[0].total.value)) {
            tempData.file = true;  
            touched = true;  
          }
        }
      }

      if (videoData?.length) {
        if(parseInt(videoData[0].used.value) > 990){
          tempData.videoAboutToExceed = true;
          touched = true;
        }
        if (parseInt(videoData[0].used.value) > parseInt(videoData[0].total.value)) {
          tempData.video = true;
          touched = true;
        }
      }

      if (touched) {
        setStorageExceeded(tempData);
      }
    };
    if (!loading && data?.userStorage?.data) {
      setUserStorage(data.userStorage.data);
      checkStorageExceeded(data.userStorage.data);
    }
  }, [loading, data]);

  // Temporarily commented below code bcz of the client's request
  // useEffect(() => {
  //   if (!customerCardStatusLoading && customerCardStatus) {
  //     setUserCardStatus(customerCardStatus?.customerCardStatus?.data?.card_status)
  //   }
  // }, [customerCardStatus, customerCardStatusLoading]);

  return (
    <div className="profile-form-storage">
      {(storageExceeded?.file || storageExceeded?.video)
        ?
        <div className="profile-form-storage-text-single">
          <p className="">
              You're exceeding the limits for a free account! To add new Scraps,
              upgrade your account or delete Scraps you don't need anymore in
              order to keep Scrapping.
        
          </p>
        </div>
        :
        (storageExceeded?.fileAboutToExceed || storageExceeded?.videoAboutToExceed) && (
          <div className="profile-form-storage-text-single">
            <p className="">
            You're about to exceed the limits for a free account! To add new Scraps,
            upgrade your account or delete unneeded Scraps
            </p>
          </div>
        )
      }


      <div className="profile-form-storage-text-group">
        <p className="">
          File Storage:{' '}
          <span className={`${storageExceeded?.fileAboutToExceed && 'storage-exceed'}`}>
            {checkFileType('file')}
          </span>
        </p>
        <p className="">
          Video Storage:{' '}
          <span className={` ${storageExceeded?.videoAboutToExceed && 'storage-exceed'} `}>
            {checkFileType('video')}
          </span>
        </p>
      </div>


      <div className="profile-form-storage-text-group ">

            {/* Temporarily commented below code bcz of the client's request */}
           {/* <PricingWrapper
            // isPaymentMethodAdded ={userCardStatus}
            /> */}
      </div>


      <PricingPageContainer/>
    </div>
  );
};

export default StorageComponent;
