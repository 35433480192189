import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';

export const GENERATEVERIFICATIONEYUSER = gql`
  mutation generateVerificationKeyUser(
    $type: Int 
  ) {
    generateVerificationKeyUser(
      type: $type 
    ) {
      ${defaultResponse}
    }
  }
`;
