import React from 'react';

interface Proptypes {
  className?: string;
}

const Doc = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="54"
      height="60"
      viewBox="0 0 54 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12277_215374)">
        <path
          d="M42.9 25.7V17.1C42.9 16.8 42.9 16.5 42.7 16.3C42.6 16 42.4 15.8 42.2 15.6L27.2 0.6C27 0.4 26.8 0.2 26.5 0.1C26.3 0 26 0 25.7 0H4.3C3.2 0 2.1 0.5 1.3 1.3C0.5 2.1 0 3.2 0 4.3V55.7C0 56.8 0.5 57.9 1.3 58.7C2.1 59.5 3.2 60 4.3 60H38.6V55.7H4.3V4.3H21.4V17.2C21.4 18.3 21.9 19.4 22.7 20.2C23.5 21 24.6 21.5 25.7 21.5H38.6V25.8L42.9 25.7ZM25.7 17.1V5.1L37.7 17.1H25.7Z"
          fill="#616161"
        />
        <path
          d="M10.2998 29.8999H16.2998C21.4998 29.8999 23.2998 31.8999 23.2998 36.3999V44.5999C23.2998 48.2999 22.3998 51.5999 16.5998 51.5999H10.2998V29.8999ZM15.1998 48.2999H16.1998C18.0998 48.2999 18.3998 47.1999 18.3998 45.4999V35.7999C18.3998 34.1999 17.9998 33.2999 16.0998 33.2999H15.1998V48.2999Z"
          fill="#616161"
        />
        <path
          d="M38.7 35.7001V45.8001C38.7 50.1001 36.3 52.0001 31.9 52.0001C27.7 52.0001 25.5 50.1001 25.5 45.9001V35.6001C25.5 31.4001 28.2 29.6001 32.1 29.6001C36.1 29.6001 38.7 31.4001 38.7 35.7001ZM30.5 35.2001V46.3001C30.5 47.6001 31 48.3001 32.1 48.3001C33.2 48.3001 33.8 47.7001 33.8 46.2001V35.2001C33.8 33.8001 33.4 33.1001 32.1 33.1001C31.1 33.2001 30.5 33.8001 30.5 35.2001Z"
          fill="#616161"
        />
        <path
          d="M53.4994 44.5V45.7C53.4994 48.4 52.8994 51.9 47.0994 51.9C42.7994 51.9 40.8994 49.9 40.8994 45.9V35.3C40.8994 31.5 43.0994 29.5 47.1994 29.5C52.5994 29.5 53.3994 32.5 53.3994 35.4V36.8H48.4994V34.9C48.4994 33.7 48.1994 33.1 47.1994 33.1C46.1994 33.1 45.8994 33.7 45.8994 34.9V46.4C45.8994 47.6 46.0994 48.4 47.1994 48.4C48.2994 48.4 48.5994 47.7 48.5994 46.4V44.6H53.4994V44.5Z"
          fill="#616161"
        />
      </g>
      <defs>
        <clipPath id="clip0_12277_215374">
          <rect width="53.9" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Doc;
