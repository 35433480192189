import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { DownArrowIcon } from '../icons';
import useOutsideAlerter from '../../../helpers/customHooks/useOutsideAlerter';

interface propsType<T> {
  onOptionClick?: (value: T) => void;
  className?: string;
  dropDownList: T[];
  currentValue?: string;
  children?: (item: T, onClicked?: () => void) => React.ReactElement;
  customButton?: {
    text?: string;
    className?: string; // Optional className which will be added to header
    customRender?: (showDropDownListHandler: () => void) => React.ReactElement;
    renderIcon?: (showDropDownListHandler: () => void) => React.ReactElement;
  };
  onDropDownClosed?: () => void;
  onDropDownOpened?: () => void;
  closeOnScroll?: boolean;
}

function MyDropDown<
  itemType extends {
    valueField: string;
    textField: string;
    icon?: React.ReactElement;
  }
>({
  onOptionClick,
  className,
  dropDownList,
  currentValue,
  children,
  customButton,
  closeOnScroll,
  onDropDownClosed,
  onDropDownOpened
}: propsType<itemType>) {
  const [showDropDownValue, setShowDropDownValue] = useState(false);

  const [buttonValue, setButtonValue] = useState(
    getButtonText(currentValue, dropDownList)
  );

  const [icon, setIcon] = useState(getButtonIcon(currentValue, dropDownList));
  const wrapperRef = useRef(null);
  useOutsideAlerter(
    wrapperRef,
    () => {
      setShowDropDownValue(false)
      onDropDownClosed && onDropDownClosed();
    },
    !showDropDownValue,
    closeOnScroll
  );

  function showDropDownListHandler() {
    setShowDropDownValue(!showDropDownValue);
    if(!showDropDownValue && onDropDownOpened) onDropDownOpened();
    else if(showDropDownValue && onDropDownClosed) onDropDownClosed();
  }

  function renderButton() {
    const { customRender } = customButton || {};
    if (customRender) {
      return customRender(showDropDownListHandler);
    }
    return (
      <span
        onClick={showDropDownListHandler}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={`dropdown__button-wrapper`}
      >
        {icon}
        <span className={`dropdown__text`}>{buttonValue}</span>
        <span className="dropdown__arrow-icon">
          {' '}
          <DownArrowIcon />
        </span>
      </span>
    );
  }
  function getButtonText(currentVal: string, valArr: itemType[]) {
    const selectedVal = valArr?.filter(
      (item) => item?.valueField === currentVal
    );

    return selectedVal[0]?.textField;
  }

  function getButtonIcon(currentVal: string, valArr: itemType[]) {
    const selectedVal = valArr?.filter(
      (item) => item?.valueField === currentVal
    );

    return selectedVal[0]?.icon;
  }

  function optionClickHandler(item: itemType, e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    if(!onOptionClick) console.error('onOptionClick prop is required if no children is passed!')
    onOptionClick(item);
    setShowDropDownValue(false);
    onDropDownClosed && onDropDownClosed();
  }

  useEffect(() => {
    setShowDropDownValue(false);
    onDropDownClosed && onDropDownClosed();
    setButtonValue(getButtonText(currentValue, dropDownList));
    setIcon(getButtonIcon(currentValue, dropDownList));
  }, [currentValue]);

  return (
    <div className={'dropdown'}>
      {renderButton()}
      {(showDropDownValue && dropDownList.length) ? (
        <div
          className={`${className ? className : 'dropdown__items-wrapper'}`}
          ref={wrapperRef}
        >
          {dropDownList?.map((item) => {
            if(!children) 
              return <div onClick={optionClickHandler.bind(null, item)} className='dropdown__item'>{item.textField}</div>

            return children(
              item,
              () => {
                setShowDropDownValue(false);
                onDropDownClosed && onDropDownClosed();

              }
            );
          })}
        </div>
      ): null}
    </div>
  );
}

export default MyDropDown;
