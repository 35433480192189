import React, { useEffect, useRef, useState } from 'react';
// import Embedo from 'embedo';
import { parseNote } from '../../../helpers/notesHelper';
import { decodeYoutubeUrl } from '../../../helpers/decodeUrl';

interface PropTypes {
  meta: string;
}

interface YoutubeProps {
  videoId: string;
  start?: number;
}

const Youtube = ({ meta }: PropTypes) => {
  const [href, setHref] = useState(null);

  // parse meta data for youtube
  const parsedMeta: YoutubeProps = JSON.parse(parseNote(meta));
  const startTime = parsedMeta?.start || null;

  useEffect(() => {
    setHref(decodeYoutubeUrl(parsedMeta.videoId));
  }, []);

  return (
    <iframe
      className="embedo-item--youtube"
      // width="853"
      // height="480"
      src={`${href}${startTime ? `?start=${startTime}` : ''}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};

export default Youtube;
