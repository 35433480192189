import React, { memo, useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import './styles.css';
import { entryTypes } from '../../types/feed';
import {
  myScrapsPageViewSettingsType,
  ScrapLayoutSettingsType,
} from '../../types/viewSettings';
import FilterSection from '../Filters/FilterSection';
import { useDispatch, useSelector } from "react-redux";
import { BatchEditType } from '../../types/batchEdit';
import EmptyPageMessage from '../Global/EmptyPageMessage';
import { useLocation } from 'react-router-dom';
import NewBackToTop from '../Global/NewBackToTopButton';
import { ReduxStateType } from '../../redux/store';
import { scrapType } from '../../types/scrapType';
import ItemsMasonry from '../ItemsMasonry';
import Onboarding from '../Onboarding';
import { getScrapData } from '../CreateScrap/components/CreateWebPage/getScrapData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import client from "../../apollo/client";
import { useApolloClient } from "@apollo/client";
import createScrapHandler from "../../containers/NewScrapPopupContainer/helpers/createScrapHandler";
import EmptyClipboard from "../Onboarding/components/EmptyClipboard";
import { Simulate } from "react-dom/test-utils";
import paste = Simulate.paste;
import { setNewScrapPopup } from "../../redux/action/utils";

const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  backUrl?: string;
  scraps: Array<entryTypes | scrapType>;
  children: (scrap: entryTypes | scrapType, i?: number, width?: number) => React.ReactElement;
  onLoadMore: () => void;
  processing: boolean;
  masonryKey?: string;
  uncategorized?: boolean;
  isCompact?: boolean;
  renderMyScrapControls?: () => React.ReactElement;
  viewSettings: myScrapsPageViewSettingsType;
  scrapSize: ScrapLayoutSettingsType['size'];
  viewSettingsLoading?: boolean;
  hideSkeletion?: boolean;
  reloadScraps?: () => void;
  disablePaste?: boolean;
  header?: React.ReactElement;
}

const MyScraps = ({
  backUrl,
  scraps,
  uncategorized,
  children,
  onLoadMore,
  processing,
  masonryKey,
  isCompact,
  renderMyScrapControls,
  viewSettings,
  scrapSize,
  viewSettingsLoading,
  hideSkeletion,
  reloadScraps,
  disablePaste,
  header
}: Proptypes) => {
  const ref = useRef<HTMLDivElement>();
  const [ output, setOutput ] = useState('');
  const [ runOnboarding, setRunOnboarding ] = useState(true);
  const [savingScrap, setSavingScrap] = useState(false);
  const [emptyClipboard, setEmptyClipboard] = useState(false);
  // get value from redux store pasteData
  const pasteData = useSelector((state: ReduxStateType) => state.pasteData);

  const dispatch = useDispatch();

  const client = useApolloClient();

  const user = useSelector((state: ReduxStateType) => state.user);
  const { mode: batchEditMode }: BatchEditType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.batchEdit
  );
  const { search } = useLocation();

  const handleCreate = async (pastedData: string) => {
    // if the empty clipboard popup is open, close it
    setEmptyClipboard(false);
    setSavingScrap(true);

    // check if the pasted data is a URL
    //  & not a URL in between some text
    if (pastedData.match(/(http(s)?:\/\/.)|(www.)/)) {
      getScrapData(pastedData)
        .then((data) => {
          reloadScraps();
        })
        .catch((err) => {
        })
        .finally(() => {
          reloadScraps();
          setSavingScrap(false);
        })
    }

    else {
      const data = await createScrapHandler({ desc: pastedData }, client);
      if (data) {
        reloadScraps();
      }

      setSavingScrap(false);
      // handle error state
    }
  }

  const handlePaste = async (e: ClipboardEvent) => {
    if (!pasteData) return;

    const hasFiles = e.clipboardData.files.length > 0;
    // check if there is a file in the clipboard, list them
    if(hasFiles) {
      dispatch(setNewScrapPopup({ type: 'Paste', files: e.clipboardData.files }));
      return;
    }

    const items = e.clipboardData;
    // only allow pasting when the content is different from the previous one
    if (e.clipboardData.getData('text') === output) {
      setEmptyClipboard(true);
      return
    }
    const pastedData = items.getData('text');
    await handleCreate(pastedData);
  };

  /**
   *
   * Set an event listener to read the paste event from
   */
  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [output, runOnboarding, scraps, pasteData]);

  let el;
  if (processing && scraps.length === 0) {
    if (hideSkeletion) el = null;
    else el = null;
  } else if (!processing && scraps.length === 0 && !search)
    el = (
      <>
        <div className="onboarding-dektop">
          <Onboarding type={'instruction'} reloadScraps={reloadScraps} />
          <Onboarding type={'welcome'} runOnboarding={runOnboarding} />
        </div>

        <EmptyPageMessage
          header={{}}
          titleHeading="How to Start Scrapping"
          type={uncategorized ? 'empty' : 'new'}
          register_from={user.register_from}
          isHome
        />
      </>
    );
  else if (!processing && scraps.length === 0 && search.length > 0) {
    el = (
      <EmptyPageMessage header={{}} register_from={user.register_from}>
        <span>
          Looks like there are no results that match the applied filters.
        </span>
      </EmptyPageMessage>
    );
  } else if (scraps.length)
    el = (
      <ItemsMasonry<entryTypes | scrapType>
        containerRef={ref}
        key={masonryKey}
        items={scraps}
        onLoadMore={onLoadMore}
        loading={processing}
        overscanBy={2}
        columnWidth={350}
        columnGutter={18}
      >
        {children}
      </ItemsMasonry>
    );
  if (viewSettingsLoading) el = null;

  return (
    <div
      ref={ref}
      className={`my-scraps${
        viewSettings.viewLayout === 'Story' ? ' my-scraps--story-view' : ''
      } ${batchEditMode ? 'batch-edit--my-scraps' : ''}`}
    >
      <div className={`filters-section__sticky-wrapper`}>
        {renderMyScrapControls && (
          <div className="my-scraps__controls">{renderMyScrapControls()}</div>
        )}
        <FilterSection
          processing={processing && scraps.length === 0}
          overriddenSearch={backUrl}
          isOneScrap
        />
      </div>

      {header}

      {savingScrap && (
        <span className="saving-scrap">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="1x" /> Saving
        </span>
      )}

      <EmptyClipboard
        handlePaste={(url) => handleCreate(url)}
        emptyClipboard={emptyClipboard}
      />

      <div
        className={`my-scraps__masonry${
          viewSettings.viewLayout === 'Story'
            ? ' my-scraps__masonry--story-view'
            : ''
        }${
          viewSettings.viewLayout === 'Story' && scrapSize === 'Large'
            ? ' my-scraps__masonry--story-large-scraps'
            : ''
        }`}
      >
        {el}
      </div>
      <NewBackToTop />
    </div>
  );
};

function propsAreEqual(
  prevProps: Readonly<Proptypes>,
  nextProps: Readonly<Proptypes>
) {
  // Check for scraps
  if ((prevProps.scraps || []).length !== (nextProps.scraps || []).length)
    return false;
  if (!isEqual(prevProps.scraps, nextProps.scraps)) return false;

  // Check for back url
  if (prevProps.backUrl !== nextProps.backUrl) return false;

  // Check hide skeleton
  if (prevProps.hideSkeletion !== nextProps.hideSkeletion) return false;

  // Check hide skeleton
  if (prevProps.isCompact !== nextProps.isCompact) return false;

  // Check masonryKey
  if (prevProps.masonryKey !== nextProps.masonryKey) return false;

  // Check processing
  if (prevProps.processing !== nextProps.processing) return false;

  // Check scrapSize
  if (prevProps.scrapSize !== nextProps.scrapSize) return false;

  // Check uncategorized
  if (prevProps.uncategorized !== nextProps.uncategorized) return false;

  if (prevProps.children !== nextProps.children) return false;

  return true;
}

export default memo(MyScraps, propsAreEqual);
