import React, { useMemo } from 'react';

import './styles.css';
interface Proptypes {
  buttonType: 'outlined' | 'orange-filled' | 'outlined-with-background' | 'outlined-with-primary-background';
  icon?: React.ReactElement;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  showLoader?: boolean;
  className?: string;
  withHover?: boolean;
}
export default function Button({
  buttonType,
  children,
  icon,
  onClick,
  disabled,
  showLoader,
  className,
  withHover,
}: Proptypes) {
  const buttonClass = useMemo(() => {
    let temp = 'ui-button';
    switch (buttonType) {
      case 'orange-filled':
        temp += ' ui-button--primary';
        break;
      case 'outlined':
        temp += ' ui-button--outlined';
        break;
      case 'outlined-with-background':
        temp += ' ui-button--outlined-with-background';
        break;
      case 'outlined-with-primary-background':
        temp += ' ui-button--outlined-with-primary-background';
        break;

      default:
        break;
    }
    if (showLoader) temp += ' ui-button--loading';
    if (disabled) temp += ' ui-button--disabled';
    if (withHover) temp += ' hoverState__with-white-text';
    if (className) temp += ' ' + className;
    return temp;
  }, [buttonType, showLoader, disabled, className, withHover]);

  const iconRenderer = () => {
    return icon
  }
  
  return (
    <button className={buttonClass} onClick={onClick}>
      {icon && iconRenderer()}  
      {children}
    </button>
  );
}
