import React from 'react';
import { Link } from 'react-router-dom';

interface Proptypes {
  image1?: string;
  image2?: string;
  deviceName?: string;
  viewType?: string;
  link?:string;
}

const EmptyMessageForMobile = ({ image1, image2, viewType,deviceName,link }: Proptypes) => {
  return viewType === 'app' ? (
    <div className="empty-page__detailholder">
      <div className="empty-page__detailholder__detail empty-page__detailholder--app-content">
        <p className="empty-page__detailholder__detail__li">
          Simply click the Share icon from any app to open up your share tray.
          Then choose Scrappi to create and save the Scrap to your Scrappi
          Dashboard.
          <div className="selected-support__image-wrapper empty-page__detailholder__detail--image">
            <div>
              <img
                className="selected-support__image-wrapper--image"
                src={image1}
                alt="App-create-scrap"
              ></img>
            </div>
            <div>
              <img
                className="selected-support__image-wrapper--image"
                src={image2}
                alt="App-save-scrap"
              ></img>
            </div>
          </div>
          <span className="empty-page__detailholder__detail--highlighted-text">
            or
          </span>{' '}
          Collect more Scraps from the top of this dashboard. Here you can Scrap
          web pages from URLs, or upload files (pdf, xls, doc, and ppt) to
          create Scraps. Or, quickly Scrap a standalone Note.
        </p>
      </div>
    </div>
  ) : (
    <div className="empty-page__detailholder">
      <div className="empty-page__detailholder__detail">
        <div className="empty-page__detailholder__detail__li">
          Download Scrappi for{' '}
          <a href={link} className="empty-page__detailholder__link" target="_blank"
            rel="noopener noreferrer">
            {deviceName}
          </a>{' '}
          to quickly Scrap on your phone. Share anything to Scrappi to save it.
          <a href={link} className="empty-page__detailholder__detail--app-logo" target="_blank"
            rel="noopener noreferrer">
            <img src={image1} alt="app-logo" />
          </a>
          <span className="empty-page__detailholder__detail--highlighted-text">
            or
          </span>{' '}
          Collect more Scraps from the top of this dashboard. Here you can Scrap
          web pages from URLs, or upload files (pdf, xls, doc, and ppt) to
          create Scraps. Or, quickly Scrap a standalone Note.
        </div>
      </div>
    </div>
  );
};

export default EmptyMessageForMobile;
