// React
import React, { useState, useEffect, useCallback, useRef } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { setPreventDeselect } from '../../redux/action/batchEditAction';
import { addOrRemoveTagsFromScrap } from '../../redux/action/feedAction';
import {
  changeBatchEditMode,
  clearBatchEditScraps,
} from '../../redux/action/batchEditAction';

// Types
import {
  BatchScrapVariablesType,
  BatchEditOperationType,
} from '../../types/batchEdit';
import { entryTypes } from '../../types/feed';
import { tagType } from '../../types/tags';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// Components
import BatchEditActionsRenderer from './components/BatchEditActionsRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Icon from '../Global/Icon';
// Mappers
import { operations } from '../../variableMappers/batchScrapOperations';
import { CrossIcon } from '../Global/icons';
// Styles
import './styles.css';
import { setReloadSideNavFilters } from '../../redux/action/utils';
import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from "../Global/InvisibleRecaptcha";
import ValidateHuman from '../../helpers/validators/isHuman';

const faSpinnerIcon = faSpinner as IconProp;
const faCheckCircleIcon = faCheckCircle as IconProp;

interface Proptypes {
  scraps: entryTypes[];
  isProcessing: boolean;
  handleBatchOperation: (
    type: 'remove' | 'add',
    variables: BatchScrapVariablesType,
    cb?: Function
  ) => void;
  // handleBatchAdd: (variables: BatchRemoveVariablesType, cb?: Function) => void;
}

const BatchEdit = (props: Proptypes) => {
  const { scraps, handleBatchOperation, isProcessing } = props;

  const dispatch = useDispatch();

  const [tags, setTags] = useState<tagType[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);

  const reCaptchaRef =  useRef<ReCAPTCHA>(null);

  //LIST OF OPERATIONS THAT CAN BE PERFORMED IN BATCH

  const [activeOperation, setActiveOperation] = useState<
    BatchEditOperationType
  >(null);
  // HANDLER TO SET MENUOPEN(USED FOR MOBILE VIEW) FALSE IF SCREEN WIDTH IS GREATER THATN 760PX
  const windowResizeHandler = useCallback(() => {
    var w = window.innerWidth;
    if (w > 760) setMenuOpen(false);
  }, []);

  const handleOperationChange = (op) => {
    // IF ZERO SCRAPS SELECTED, THEN SHOW ALERT
    if (scraps.length === 0) return alert('Please select at least one Scrap.');
    setActiveOperation(op);
  };
  const closeBatchEditModeHandler = () => {
    // IF USER IS NOT INSIDE ANY ONE BATCH ACTION OPERATION THEN CLOSE THE BATCH EDIT SIDEBAR
    if (!activeOperation) {
      dispatch(changeBatchEditMode(false));
      dispatch(clearBatchEditScraps());
    } else {
      // ELSE GO BACK TO BATCH ACTIONS LIST MENU
      setActiveOperation(null);
    }
  };
  const handleSubmit = async () => {  
    const isHuman= await ValidateHuman(reCaptchaRef.current);
    if(!isHuman)
      return;
    const returnBatchType = () => {
      if (activeOperation.action === 'delete') return 'remove';
      return activeOperation.action.includes('_add_') ? 'add' : 'remove';
    };
    if (scraps.length === 0) return alert('Please select at least one Scrap');
    const type: 'remove' | 'add' | 'remove' = returnBatchType();
    const pidList = scraps.map((scrap) => +scrap.pid);
    const variables: BatchScrapVariablesType = {
      scraps: pidList,
    };
    variables[activeOperation.show[0]] = activeOperation.selected;
    let callback = () => setActiveOperation(null);
    const tagsActionList = ['bulk_add_tags', 'bulk_remove_tags'];
    let currentTagsActionIndex = tagsActionList.findIndex(
      (action) => action === activeOperation.action
    );
    if (currentTagsActionIndex > -1) {
      callback = () => {
        dispatch(
          addOrRemoveTagsFromScrap(
            currentTagsActionIndex === 0 ? 'add' : 'remove',
            tags,
            pidList
          )
        );
        setActiveOperation(null);
        dispatch(setReloadSideNavFilters(true));
        setTags([]);
      };
    }
    handleBatchOperation(type, variables, callback);
  };
  // FUNCTION TO RENDER THE BUTTONS AT THE BOTTOM OF BATCH EDIT SIDEBAR
  function renderFooterButtons() {
    // IF IN ACTIONS MENU PAGE AND IN MOBILE VIEW, THEN DON'T SHOW BUTTONS
    if (!activeOperation && menuOpen) return null;
    let saveButton;
    // If inside batch operation, then also show save button
    if (activeOperation) {
      let isDisabled =
        activeOperation.selected.length === 0 &&
        activeOperation.action !== 'delete';
      let saveLabel = 'Save Changes';
      if (activeOperation.label?.split(' ')) {
        saveLabel = activeOperation.label?.split(' ')[0];
      }
      saveButton = (
        <button
          onClick={handleSubmit}
          disabled={isDisabled}
          className="button button__primary"
        >
          <FontAwesomeIcon icon={faCheckCircleIcon} /> {saveLabel}
        </button>
      );
    }
    return (
      <div
        className={`batch-button-group batch-mobile-div ${
          menuOpen ? 'dblock' : ''
        }${saveButton ? ' save-present' : ''}`}
      >
        {activeOperation ? (
          <button
            onClick={closeBatchEditModeHandler}
            className="button button__outline"
          >
            Cancel
          </button>
        ) : (
          ''
        )}
        {saveButton}
      </div>
    );
  }
  // RETURN THE MAIN LABEL BASED ON ACTIVE OPERATION
  const returnMainLabel = () => {
    var mainLabel = 'Select Scraps to Edit';
    if (scraps.length > 0) {
      mainLabel = `${scraps.length} Scrap${
        scraps.length > 1 ? 's' : ''
      } Selected`;
      if (activeOperation) {
        mainLabel = activeOperation.label;
      }
    }
    return <h2 className={`batch-count`}>{mainLabel}</h2>;
  };

  useEffect(() => {
    dispatch(setPreventDeselect(!!activeOperation));
  }, [activeOperation]);
  // USEEFFECT TO LOOK FOR THE SCREEN RESIZE EVENT
  useEffect(() => {
    if (menuOpen) {
      window.addEventListener('resize', windowResizeHandler);
      return () => {
        window.removeEventListener('resize', windowResizeHandler);
      };
    }
  }, [menuOpen]);

  return (
    <>
      {isProcessing && (
        <div className="batch-edit__loading-overlay">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
        </div>
      )}
      <div className={`batch-edit ${menuOpen ? 'full-height' : ''}`}>
        <div
          className={`batch-container ${
            activeOperation ? 'batch-container-active' : ''
          }`}
        >
          {returnMainLabel()}
          <div className="batch-icons">
            {scraps.length > 0 && !activeOperation && (
              <span
                className={`batch-mobile-menu batch-close ${
                  menuOpen ? 'icon-active' : ''
                }`}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <Icon iconClass="pencil" size="normal" />
              </span>
            )}
            <span className="batch-close" onClick={closeBatchEditModeHandler}>
              <CrossIcon />
            </span>
          </div>
        </div>
        <InvisibleRecaptcha inputRef={reCaptchaRef}/>
        {activeOperation ? (
          <div className="batch-active__action">
            {activeOperation.message?.length > 0 && (
              <p className="batch-active__action-message">
                {activeOperation.message}
              </p>
            )}
            <BatchEditActionsRenderer
              activeOperation={activeOperation}
              setActiveOperation={setActiveOperation}
              menuOpen={menuOpen}
              tags={tags}
              setTags={setTags}
            />
            {renderFooterButtons()}
          </div>
        ) : (
          <>
            <ul
              className={`batch-operations batch-mobile-div${
                menuOpen ? ' dblock' : ''
              }${scraps.length === 0 ? ' disabled' : ''}`}
            >
              {operations.map((o, key) => {
                const listItem = (
                  <li
                    key={key}
                    onClick={(e) => {
                      handleOperationChange(o);
                    }}
                  >
                    <span>{o.label}</span>
                  </li>
                );
                return listItem;
              })}
            </ul>
            {renderFooterButtons()}
          </>
        )}
      </div>
    </>
  );
};

export default BatchEdit;
