import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['collection_id', 'Int!'],
  ['orders_and_scraps', '[ScrapOrderInput]'],
]);
export default function ORGANIZE_COLLECTION_SCRAP() {
  return gql`
    mutation OrganizeCollectionScrap(${parametersTypeString}) {
      organizeCollectionScrap(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}
