import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const NewClose = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      onClick={onClick}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99999 5.58672L11.95 0.636719L13.364 2.05072L8.41399 7.00072L13.364 11.9507L11.95 13.3647L6.99999 8.41472L2.04999 13.3647L0.635986 11.9507L5.58599 7.00072L0.635986 2.05072L2.04999 0.636719L6.99999 5.58672Z"
        fill="#1A404C"
      />
    </svg>
  );
};

export default NewClose;
