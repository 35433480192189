/**
 * parse the value of the notes and make it backward compatible
 * make sure that the parsing doesn't break because of old note format
 * @param note value of the stringified note
 * @returns string
 */
export const parseNote = (note: string) => {
  let parsedNote: string;
  if(!note) return '';
  try {
    parsedNote = JSON.parse(note);
    try {
      parsedNote = JSON.parse(parsedNote);
    } catch (e) {
      parsedNote = JSON.parse(note);
    }
  } catch (error) {
    parsedNote = note;
  }

  // for cases when we were using object based editor
  if (typeof parsedNote === 'object') {
    return JSON.stringify(parsedNote);
  }
  
  return parsedNote;
};

/**
 * Remove the HTML tags and just
 * @param note HTML/string value of the note
 * @returns
 */
export const noteSummary = (note: string, dontSummarize?: boolean) => {
  try {
    const parsedNoteValue = parseNote(note);
    let summary = parsedNoteValue?.replace(/(<([^>]+)>)/gi, ' '); // remove tags
    if (dontSummarize) return summary?.replace(/\n/g, '').trim();
    summary = summary

      ?.replace(/\n/g, ' ') // add space for enter
      ?.replace(/\./g, '. '); // add space after "." HTML tags mess it up

    // only send 150 characters
    return summary.length <= 150
      ? summary.trim()
      : `${summary.substr(0, 150).trim()}...`;  
  } catch (error) {
    return null;
  }
  
};


/**
 * Remove the HTML tags and return length of the textContent
 * @param note HTML/string value of the note
 * @returns number
 */

export const getNoteLen = (note: string) => {
  note = parseNote(note);
  return note?.replace(/<\/?[^>]+(>|$)/g, '')?.trim()?.length;
};