import { useEffect, useCallback } from 'react';

interface PropTypes {
  cb: (el: HTMLElement) => void;
  removeListener: boolean;
  passedException?: () => boolean;
}
const useWindowOnClick = ({
  cb,
  removeListener,
  passedException,
}: PropTypes) => {
  const handleClick = useCallback((e: MouseEvent) => {
    let flag = true;
    if (passedException) flag = passedException();
    if (!flag) return;
    cb(e.target as HTMLElement);
  }, [cb]);
  useEffect(() => {
    if (!removeListener) {
      document.body.addEventListener('click', handleClick);
      return () => document.body.removeEventListener('click', handleClick);
    } else {
      document.body.removeEventListener('click', handleClick);
    }
  }, [removeListener,handleClick]);
};

export default useWindowOnClick;
