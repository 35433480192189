import React from 'react';

interface Proptypes {
  className?: string;
}

const DragIcon = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.10039 4.9C4.31542 4.9 5.30039 3.91503 5.30039 2.7C5.30039 1.48497 4.31542 0.5 3.10039 0.5C1.88536 0.5 0.900391 1.48497 0.900391 2.7C0.900391 3.91503 1.88536 4.9 3.10039 4.9Z"
        fill="#969696"
      />
      <path
        d="M3.10039 12.6998C4.31542 12.6998 5.30039 11.7148 5.30039 10.4998C5.30039 9.28478 4.31542 8.2998 3.10039 8.2998C1.88536 8.2998 0.900391 9.28478 0.900391 10.4998C0.900391 11.7148 1.88536 12.6998 3.10039 12.6998Z"
        fill="#969696"
      />
      <path
        d="M3.10039 20.5001C4.31542 20.5001 5.30039 19.5151 5.30039 18.3001C5.30039 17.0851 4.31542 16.1001 3.10039 16.1001C1.88536 16.1001 0.900391 17.0851 0.900391 18.3001C0.900391 19.5151 1.88536 20.5001 3.10039 20.5001Z"
        fill="#969696"
      />
      <path
        d="M9.2 4.9C10.415 4.9 11.4 3.91503 11.4 2.7C11.4 1.48497 10.415 0.5 9.2 0.5C7.98497 0.5 7 1.48497 7 2.7C7 3.91503 7.98497 4.9 9.2 4.9Z"
        fill="#969696"
      />
      <path
        d="M9.2 12.6998C10.415 12.6998 11.4 11.7148 11.4 10.4998C11.4 9.28478 10.415 8.2998 9.2 8.2998C7.98497 8.2998 7 9.28478 7 10.4998C7 11.7148 7.98497 12.6998 9.2 12.6998Z"
        fill="#969696"
      />
      <path
        d="M9.2 20.5001C10.415 20.5001 11.4 19.5151 11.4 18.3001C11.4 17.0851 10.415 16.1001 9.2 16.1001C7.98497 16.1001 7 17.0851 7 18.3001C7 19.5151 7.98497 20.5001 9.2 20.5001Z"
        fill="#969696"
      />
    </svg>
  );
};

export default DragIcon;
