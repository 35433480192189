import React, { useEffect } from 'react';
import { adjustGridItemsHeight } from './helper/adjustGridItems';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../redux/store';
import { calculateCardWidth } from '../../helpers';
const faSpinnerIcon = faSpinner as IconProp;

export default function GridWrapper(props) {
  const { children, gridRef, view_type, scrapSize } = props;
  const { width } = useSelector((state: ReduxStateType) => state.utils.dimensions);
  const [loading, setLoading] = React.useState(true);
  const { expanded } = useSelector((state: ReduxStateType) => state.utils);
  useEffect(() => {
    const grid = gridRef.current;
    if (view_type !== 2) {
      setTimeout(() => {
        adjustGridItemsHeight(grid);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }, 1000);
    }
    else{
      setLoading(false);
    }
  },[children]);
  useEffect(() => {
    const gridContainer = gridRef?.current as HTMLDivElement;
    if(view_type === 2 && gridContainer) {
      const { columns } = calculateCardWidth(320, gridContainer.offsetWidth, 10);
      let className = gridContainer.className;
      let list = className.split(' ');
      list = list.filter(val => !val.includes('draggable-feed__wrapper--col'))
      list.push(`draggable-feed__wrapper--col-${columns}`)
      gridContainer.className = list.join(' ');
    }
  },[view_type, width, expanded, gridRef])

  

  const returnViewClass = () => {
    if(view_type === 2) {
      return 'grid-view';
    }
    else if(view_type === 3) {
      return 'story-view';
    }
    else 
      return 'gallery-view';
  }

  return (
    <>
      <div
        className={`draggable-feed__wrapper ${returnViewClass()}${scrapSize === 'Large' ? ' draggable-feed__wrapper--large-scraps' : ''}`}
        ref={gridRef}
      >
        {children}
      </div>
      {
        loading &&
        <div className='draggable-feed__loading'>
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="3x" color="#eb6f19" />
        </div>
      }
    </>
  );
}
