export default function returnTimeDifference(timeStr1, timeStr2) {
  try {
    // Convert time strings to Date objects
    const time1 = new Date(timeStr1);
    const time2 = new Date(timeStr2);

    // Calculate the time difference in milliseconds
    //   @ts-ignore
    const timeDifference = time2 - time1;

    // Convert the time difference to minutes
    const minutesDifference = timeDifference / (1000 * 60);

    return minutesDifference;
  } catch (error) {
    return null;
  }
}

// Example usage:
// const timeStr1 = '2023-10-27 10:43:01';
// const timeStr2 = '2023-10-30 11:24:18';
// const result = timeDifferenceInMinutes(timeStr1, timeStr2);

// console.log(`The difference in minutes is: ${result} minutes`);
