import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useScrollDirection from '../../helpers/customHooks/useScrollDirection';
import { setIsMainPageLoading } from '../../redux/action/utils';
import ScrollDrag from '../ScrollDragDiv';
import './styles.css';
import { parseTagFromSlug } from '../../helpers';

interface Proptypes {
  processing?: boolean;
  overriddenSearch?: string;
  isOneScrap?: boolean;
}

interface QueryType {
  type: 'types' | 'tags' | 'untagged';
}

const FilterSection = ({
  processing,
  overriddenSearch,
  isOneScrap,
}: Proptypes) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const [tags, setTags] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [untagged, setUntagged] = useState(false);
  const searchToUse =
    typeof overriddenSearch === 'string' ? overriddenSearch : search;
  const [searchParams, setSearchParams] = useState(
    qs.parse(searchToUse.substring(1))
  );
  const scrollDirection = useScrollDirection();

  const updateQuery = (params = null) => {
    const queryParams = params || searchParams;
    if (queryParams?.tags) {
      const { tags: queryTags } = queryParams;
      if (queryTags && queryTags?.length) {
        if (Array.isArray(queryTags)) {
          // @ts-ignore
          setTags(queryTags);
        } else {
          // @ts-ignore
          setTags([queryTags]);
        }
      }
    } else {
      setTags([]);
    }
    if (queryParams?.types) {
      const { types: queryTypes } = queryParams;
      if (queryTypes?.length && queryTypes?.length) {
        if (Array.isArray(queryTypes)) {
          // @ts-ignore
          setTypes(queryTypes);
        } else {
          // @ts-ignore
          setTypes([queryTypes]);
        }
      }
    } else {
      setTypes([]);
    }

    if (queryParams?.untagged) {
      setUntagged(true);
    } else {
      setUntagged(false);
    }
  };

  useEffect(() => {
    setSearchParams(qs.parse(searchToUse.substring(1)));
    updateQuery(qs.parse(searchToUse.substring(1)));
  }, [processing, searchToUse]);

  const removeFilter = (type: QueryType['type'], value: string) => {
    // Else handle for filter by tags or types category
    const property = type;
    if (typeof searchParams[property] === 'string') {
      // If property value type is string, it means that there is
      // only one query parameter value set in url for selected property
      if (searchParams[property] === value) {
        // if query value already exists, then it means that user is unchecking that filter
        delete searchParams[property];
      } else if (
        searchParams[property] === 'true' &&
        value.toLowerCase() === 'untagged'
      ) {
        delete searchParams[property];
      }
    } else if (Array.isArray(searchParams[property])) {
      // There are multiple query parameter value sets in the url of same key
      // which is equal to property value defined above
      let result = searchParams[property] as Array<string>;
      const existingIndex = result.findIndex((item) => item === value);
      if (existingIndex >= 0) {
        // If value is already present in the url, then remove it from the url
        result.splice(existingIndex, 1);
      } else {
        // else add it to the url
        result.push(value);
      }
    }
    const query = qs.stringify(searchParams, {
      arrayFormat: 'repeat',
    });
    dispatch(setIsMainPageLoading(true));
    replace(`?${query}`);
  };

  const removeAllFilter = () => {
    setTags([]);
    setTypes([]);
    setUntagged(false);
    dispatch(setIsMainPageLoading(true));
    replace('');
  };

  const renderfilter = (value: string, type: QueryType['type']) => {
    return (
      <div
        className={`filters-section__item filters-section__item--${type}`}
        key={value}
      >
        <span>{isOneScrap ? parseTagFromSlug(value).label : value}</span>
        <span
          onClick={() => {
            removeFilter(type, value);
          }}
          onKeyDown={() => {
            removeFilter(type, value);
          }}
          className="filters-section__remove"
          role="button"
          tabIndex={0}
        >
          <img
            src={'/dashboard/svg-icons/remove-filter.svg'}
            alt={'removeFilter'}
          />
        </span>
      </div>
    );
  };

  if (!tags.length && !types.length && !untagged) return null;

  return (
    <div
      className={`filters-section ${
        scrollDirection === 'down' ? '' : 'filters-section-sticky'
      }`}
    >
      <ScrollDrag rootClass="filters-section__item-dragList">
        <div className="filters-section__item-list">
          {tags.map((val) => renderfilter(val, 'tags'))}
          {types.map((val) => renderfilter(val, 'types'))}
          {untagged && renderfilter('Untagged', 'untagged')}
        </div>
      </ScrollDrag>
      <div
        className="filters-section__remove-all"
        onClick={() => {
          removeAllFilter();
        }}
        onKeyDown={() => {
          removeAllFilter();
        }}
      >
        Clear All Filters
      </div>
    </div>
  );
};

export default FilterSection;
