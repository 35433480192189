import React, { useEffect } from 'react';
import useWindowOnClick from '../../helpers/customHooks/useWindowOnclick';
import './styles.css';

interface propTypes {
  onOutsideClick?: () => void;
  overLayClassName: string; // overlay class name must always end with my-overlay at the end example: panel-details__my-overlay
  children: React.ReactChild | React.ReactChildren;
  dontChangeOverFlow?: boolean;
}

const Overlay = ({
  onOutsideClick,
  overLayClassName,
  children,
  dontChangeOverFlow,
}: propTypes) => {
  // HANDLER FUNCTION TO CHECK IF USER HAS CLICKED OUTSIDE OF DIRECT CHILDREN ELEMENTS OF OVER LAY DIV ELEMENT
  // AND CALL ONOUTSIDECLICK IF USER HAS CLICKED OUTSIDE
  const handleOutSideClick = (el: HTMLElement) => {
    if (!onOutsideClick) return;
    const containerEl = document.querySelector(`.${overLayClassName}`);

    if (!containerEl) return;
    const childrenEl = containerEl.children;

    if (!childrenEl || childrenEl.length === 0) return;
    const childrenClasses: string[] = [];

    for (let i = 0; i < childrenEl.length; i++) {
      childrenClasses.push(childrenEl[i].className);
    }

    let closestElement;

    for (const child in childrenClasses) {
      closestElement = el.closest(
        `.${childrenClasses[child].trim().replace(' ', '.')}`
      );
      if (closestElement) break;
    }

    if (!closestElement) onOutsideClick();
  };
  useWindowOnClick({
    cb: handleOutSideClick,
    removeListener: false,
  });
  useEffect(() => {
    if (!dontChangeOverFlow) {
      
      let windowDom = document.body;
      windowDom.style.overflowY = 'hidden';
      return () => {
        windowDom.style.overflowY = 'auto';
      };
    }
  }, [dontChangeOverFlow]);
  return <div className={overLayClassName}>{children}</div>;
};

export default Overlay;
