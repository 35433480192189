import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['group_id', 'Int!'],
  ['title', 'String!'],
  ['nickname', 'String'],
  ['cover_image', 'String'],
  ['desc', 'String'],
  ['status', 'Int = 1'],
  ['state', 'Int'],
  ['sort_param', 'String'],
  ['sort_by', 'String'],
  ['view_type', 'Int'],
  ['show_author', 'Int'],
]);
export default function CONVERT_GROUP(responseString = '') {
  return gql`
    mutation ConvertGroup(${parametersTypeString}) {
      convertGroup(${parametersAssignString}) {
        ${defaultResponse}
        data {
          collection_id
        }
      }
    }
  `;
}
