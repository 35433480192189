import React, { useEffect, useRef } from 'react';
import HighlightsItem from './uiComponents/HighlightsItem';
import ViewAllHighlights from './uiComponents/ViewAllHighlights';
import './styles.css';
import { onClickType } from '../../ScrapCard';
import { scrapType } from '../../../types/scrapType';

export interface Type {
  highlighted_text: string;
}
interface Proptypes {
  highlightText: Array<Type>;
  scrap: scrapType;
  setShowMore: (val) => void;
  onMoreClick: (onClickType: onClickType, currentlyOpened?: boolean) => boolean;
  setSelectedAnnotationIndex: (val) => void;
  showAll?: boolean;
  selectedAnnotationIndex?: number;
  clickedOn?: string;
}

const Highlights = ({
  scrap,
  setShowMore,
  onMoreClick,
  highlightText,
  setSelectedAnnotationIndex,
  showAll,
  selectedAnnotationIndex,
  clickedOn,
}: Proptypes) => {
  const highlightsScroll = useRef([]);
  useEffect(() => {
    if (clickedOn === 'anotations') {
      highlightsScroll?.current[selectedAnnotationIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [clickedOn]);
  if (!highlightText || highlightText?.length === 0) return null;

  const returnHighlight = () => {
    if (highlightText?.length > 1) {
      let highlightArray = showAll ? highlightText : highlightText.slice(0, 2);
      return highlightArray.map((item, key) => {
        return (
          <div key={key} ref={(ref) => (highlightsScroll.current[key] = ref)}>
            <HighlightsItem
              showAll={showAll}
              annotation={item}
              key={key}
              onMoreClick={onMoreClick}
              setSelectedAnnotationIndex={setSelectedAnnotationIndex}
              index={key}
            />
          </div>
        );
      });
    } else {
      return (
        <div>
          <HighlightsItem
            showAll={showAll}
            annotation={highlightText[0]}
            onMoreClick={onMoreClick}
            setSelectedAnnotationIndex={setSelectedAnnotationIndex}
            index={0}
          />
        </div>
      );
    }
  };
  return (
    <div className="highlights">
      {returnHighlight()}
      {highlightText.length > 2 && !showAll && (
        <ViewAllHighlights
          scrap={scrap}
          text={highlightText}
          highlightsCount={scrap.annotations_count ?? highlightText?.length}
          onMoreClick={onMoreClick}
          label="View all highlights"
          showMore="all-annotations"
        />
      )}
    </div>
  );
};

export default Highlights;
