import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { renderSocialEmbed } from '../../Cards/CardSocial';
import { CloseIcon, LeftArrowIcon } from '../icons';
import NewPopup from '../NewPopup';
import { Stream } from '@cloudflare/stream-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { returnCloudflareId } from '../../../helpers/config';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import { setImageDisplayPopup, setScrapEmbedPopup } from '../../../redux/action/utils';

const EmbedDisplayModal = () => {
  const dispatch = useDispatch();
  const scrapEmbedPopup = useSelector(
    (state: ReduxStateType) => state.utils.scrapEmbedPopup
  );
  const { data, modalClassName } = scrapEmbedPopup || {};
  const { meta, service, thumbnail, video } = data || {};

  const [show, setShow] = [
    !!scrapEmbedPopup,
    (val: boolean) => {
      dispatch(setScrapEmbedPopup(null));
      document.body.style.overflow = "auto";
    },
  ];

  const faSpinnerIcon = faSpinner as IconProp;
  const lightBoxRef = useRef<HTMLDivElement>();
  const [loadingVideo, setLoadingVideo] = useState(true);
  const [videoUrl, setVideoUrl] = useState({ video: video, waitTime: 0 });
  useEffect(() => {
    const element = lightBoxRef?.current;
    if (data && element) {
      if (service === 'youtube') {
        element.style.height = (element.clientWidth * 360) / 640 + 'px';
      }
    }
    if(data && ['facebook', 'tiktok'].includes(service)) {
      dispatch(setScrapEmbedPopup(null));
      dispatch(setImageDisplayPopup({ imageUrl: thumbnail, modalClassName }))
    }
  }, [lightBoxRef, data]);
  if(!data) return null;
  let className = 'display-modal display-modal--image';
  if (modalClassName) className += ' ' + modalClassName;
  if (!service && !video) return null;
  if (service) className += ' display-modal--' + service;
  if (['facebook', 'tiktok'].includes(service)) {
    return null;
  }
  if (video)
    return (
      <NewPopup
        className={className}
        size="Extra large"
        fullMobile
        header={{
          heading: 'Image Details',
          customRender: (close) => (
            <header className="new-popup__header-inner-container">
              <h3 className="new-popup__header-heading">
                <button
                  onClick={() => {
                    close();
                  }}
                  className="new-popup__header-button"
                >
                  <LeftArrowIcon className="new-popup__header-button__icon" />
                </button>
              </h3>
              <button
                onClick={() => {
                  close();
                }}
                className="new-popup__header-button"
              >
                <CloseIcon className="new-popup__header-button__icon" />
              </button>
            </header>
          ),
        }}
        controlled={{
          show,
          setShow,
        }}
      >
        {(close) => (
          <div
            className={`scrap-details__light-box scrap-details__light-box--video`}
          >
            <>
              {loadingVideo && (
                <div className="card-video__loading-overlay">
                  <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
                </div>
              )}
              <Stream
                controls
                onError={() => {
                  setVideoUrl((old) => ({ video: undefined, waitTime: 30000 }));
                  setTimeout(() => {
                    setVideoUrl((old) => ({
                      video: video,
                      waitTime: 30000,
                    }));
                  }, videoUrl.waitTime || 30000);
                }}
                onLoadStart={() => {
                  setLoadingVideo(false);
                }}
                height="250px"
                src={returnCloudflareId(videoUrl.video)}
              />
            </>
          </div>
        )}
      </NewPopup>
    );

  
  return (
    <NewPopup
      className={className}
      size={service === 'youtube' ? 'Extra large' : 'Small'}
      fullMobile
      header={{
        heading: 'Image Details',
        customRender: (close) => (
          <header className="new-popup__header-inner-container">
            <h3 className="new-popup__header-heading">
              <button
                onClick={() => {
                  close();
                }}
                className="new-popup__header-button"
              >
                <LeftArrowIcon className="new-popup__header-button__icon" />
              </button>
            </h3>
            <button
              onClick={() => {
                close();
              }}
              className="new-popup__header-button"
            >
              <CloseIcon className="new-popup__header-button__icon" />
            </button>
          </header>
        ),
      }}
      controlled={{
        show,
        setShow,
      }}
    >
      {(close) => (
        <div
          ref={lightBoxRef}
          className={`scrap-details__light-box scrap-details__light-box--${service}`}
        >
          {renderSocialEmbed({ meta, service, thumbnail })}
        </div>
      )}
    </NewPopup>
  );
};

export default EmbedDisplayModal;