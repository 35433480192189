import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const GoogleIcon = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <rect x="0.82373" width="55" height="56" rx="27.5" fill="#FDF1E8" />
      <path
        d="M17.3964 13.8059C17.0538 14.1561 16.8555 14.7013 16.8555 15.4074V40.594C16.8555 41.3001 17.0538 41.8454 17.3964 42.1956L17.481 42.2725L31.9367 28.1644V27.8313L17.481 13.7233L17.3964 13.8059Z"
        fill="url(#paint0_linear_12623_219765)"
      />
      <path
        d="M36.7497 32.8691L31.9365 28.1641V27.831L36.7556 23.1259L36.8635 23.1871L42.5705 26.3575C44.1992 27.2573 44.1992 28.7378 42.5705 29.6432L36.8635 32.8079L36.7497 32.8691Z"
        fill="url(#paint1_linear_12623_219765)"
      />
      <path
        d="M36.8637 32.8078L31.9368 27.9974L17.3965 42.1951C17.9374 42.7503 18.8196 42.8172 19.8228 42.262L36.8637 32.8078Z"
        fill="url(#paint2_linear_12623_219765)"
      />
      <path
        d="M36.8637 23.1867L19.8228 13.7325C18.8196 13.1829 17.9374 13.2498 17.3965 13.8051L31.9368 27.9971L36.8637 23.1867Z"
        fill="url(#paint3_linear_12623_219765)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12623_219765"
          x1="30.6532"
          y1="40.8563"
          x2="11.5453"
          y2="21.2854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00A0FF" />
          <stop offset="0.0066" stop-color="#00A1FF" />
          <stop offset="0.2601" stop-color="#00BEFF" />
          <stop offset="0.5122" stop-color="#00D2FF" />
          <stop offset="0.7604" stop-color="#00DFFF" />
          <stop offset="1" stop-color="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12623_219765"
          x1="44.6914"
          y1="27.9959"
          x2="16.466"
          y2="27.9959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE000" />
          <stop offset="0.4087" stop-color="#FFBD00" />
          <stop offset="0.7754" stop-color="#FFA500" />
          <stop offset="1" stop-color="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12623_219765"
          x1="34.1846"
          y1="25.3824"
          x2="8.27278"
          y2="-1.15729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF3A44" />
          <stop offset="1" stop-color="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12623_219765"
          x1="13.7364"
          y1="50.5742"
          x2="25.3072"
          y2="38.7232"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#32A071" />
          <stop offset="0.0685" stop-color="#2DA771" />
          <stop offset="0.4762" stop-color="#15CF74" />
          <stop offset="0.8009" stop-color="#06E775" />
          <stop offset="1" stop-color="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GoogleIcon;
