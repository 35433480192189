import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int'],
  ['image_id', 'Int']
  
]);

export default function DELETE_SCRAP_IMAGE() {
  return gql`
      mutation DeleteScrapImage(${parametersTypeString}) {
        deleteScrapImage(${parametersAssignString}) {
          ${defaultResponse}
        }
      }
    `;
}
