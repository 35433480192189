import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const ExtensionIcon = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className||''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6755 17.7913C21.6755 15.0529 23.8954 12.833 26.6338 12.833C29.3722 12.833 31.5921 15.0529 31.5921 17.7913V18.4997H35.8421C37.4069 18.4997 38.6755 19.7682 38.6755 21.333V24.1663C41.8051 24.1663 44.3421 26.7034 44.3421 29.833C44.3421 32.9626 41.8051 35.4997 38.6755 35.4997V38.333C38.6755 39.8978 37.4069 41.1663 35.8421 41.1663H18.8421C17.2773 41.1663 16.0088 39.8978 16.0088 38.333V34.1422C16.0088 33.3271 16.6696 32.6663 17.4847 32.6663H19.8653C21.0389 32.6663 21.9903 31.7149 21.9903 30.5413C21.9903 29.3677 21.0389 28.4163 19.8653 28.4163H17.4847C16.6696 28.4163 16.0088 27.7556 16.0088 26.9405V21.333C16.0088 19.7682 17.2773 18.4997 18.8421 18.4997H21.6755V17.7913ZM26.6338 15.6663C25.4602 15.6663 24.5088 16.6177 24.5088 17.7913V19.8602C24.5088 20.6736 23.8494 21.333 23.036 21.333H18.8421V25.583H19.8653C22.6037 25.583 24.8236 27.8029 24.8236 30.5413C24.8236 33.2798 22.6037 35.4997 19.8653 35.4997H18.8421V38.333H35.8421L35.8421 34.1391C35.8421 33.3257 36.5015 32.6663 37.3149 32.6663H38.6755C40.2403 32.6663 41.5088 31.3978 41.5088 29.833C41.5088 28.2682 40.2403 26.9997 38.6755 26.9997H37.3149C36.5015 26.9997 35.8421 26.3403 35.8421 25.5269V21.333H30.2316C29.4182 21.333 28.7588 20.6736 28.7588 19.8602V17.7913C28.7588 16.6177 27.8074 15.6663 26.6338 15.6663Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default ExtensionIcon;
