export interface GlobalSearchType {
  isSearchBarActive: boolean;
  search: string;
  filter: string;
}

interface ActionType {
  type: string;
  payload: Partial<GlobalSearchType>;
}
const DEFAULT: GlobalSearchType = {
  isSearchBarActive: false,
  search: '',
  filter: '',
};

const globalSearch = (state = DEFAULT, action: ActionType) => {
  const { type, payload } = action;
  const { isSearchBarActive, search, filter } = payload || {};
  switch (type) {
    case 'TOGGLE_GLOBAL_SEARCH_BAR':
      return { ...state, isSearchBarActive };
    case 'SET_GLOBAL_SEARCH_TEXT':
      return { ...state, search };
    case 'SET_GLOBAL_SEARCH_FILTER':
      return { ...state, filter };
    default:
      return state;
  }
};

export default globalSearch;
