// interface InstaProps {
//   instaUrl?: string;
//   ttUrl?: string;
// }

export const parseSocialMeta = (meta: string) => {
  let parsedMeta;

  try {
    parsedMeta = JSON.parse(meta);
    try {
      parsedMeta = JSON.parse(JSON.parse(meta));
    } catch (e) {
      parsedMeta = JSON.parse(meta);
    }
  } catch (error) {
    parsedMeta = null;
  }

  return parsedMeta;
}