import { CommentType } from '../../components/Comments';

type collectionDataType = {
  likesCount: number;
  commentsData?: {
    count: number;
    comments?: CommentType[]
  };
  isLiked: boolean;
};
export type ReduxCollectionsLikesAndCommentsType = Map<
  number,
  collectionDataType
>;

export const UPDATE_COLLECTIONS_LIKES_AND_COMMENTS =
  'UPDATE_COLLECTIONS_LIKES_AND_COMMENTS';
export const LIKE_COLLECTION = 'LIKE_COLLECTION';
export const DISLIKE_COLLECTION = 'DISLIKE_COLLECTION';
export const UPDATE_COLLECTION_COMMENT_COUNT = 'UPDATE_COLLECTION_COMMENT_COUNT';
export const RESET_COLLECTIONS_LIKES_AND_COMMENTS = 'RESET_COLLECTIONS_LIKES_AND_COMMENTS';

const defaultState: ReduxCollectionsLikesAndCommentsType = new Map();
type actionType =
  | typeof UPDATE_COLLECTIONS_LIKES_AND_COMMENTS
  | typeof LIKE_COLLECTION
  | typeof DISLIKE_COLLECTION
  | typeof UPDATE_COLLECTION_COMMENT_COUNT
  | typeof RESET_COLLECTIONS_LIKES_AND_COMMENTS;
  
export type collectionsLikesAndCommentsPayloadType = {
  collectionId: number;
  data?: Partial<collectionDataType>;
};


/**
 * collectionsLikesandComments reducer
 * It keeps the list of all the likes that are made by the user on collection.
 * The list gets updated as more collections are loaded across the app.
 * It will also save the comments collections
 */
export default function collectionsLikesAndComments(
  state = defaultState,
  action: {
    type: actionType;
    payload:
      | Array<collectionsLikesAndCommentsPayloadType>
      | collectionsLikesAndCommentsPayloadType;
  }
) {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_COLLECTIONS_LIKES_AND_COMMENTS:
      if (Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => [+item.collectionId, item.data]) as Iterable<
            readonly [number, collectionDataType]
          >),
        ]);
      } else {
        return new Map([...state, [+payload.collectionId, payload.data]]);
      }
    case DISLIKE_COLLECTION:
    case LIKE_COLLECTION:
      if (Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => {
            let data = state.get(+item.collectionId);
            if (!data) {
              data = {
                isLiked: type === LIKE_COLLECTION ? true : false,
                likesCount: type === LIKE_COLLECTION ? 1 : 0,
              };
            } else {
              data = {
                ...data,
                isLiked: type === LIKE_COLLECTION ? true : false,
                likesCount:
                  type === LIKE_COLLECTION
                    ? (data.likesCount || 0) + 1
                    : (data.likesCount || 1) - 1,
              };
            }
            return [+item.collectionId, data];
          }) as Iterable<readonly [number, collectionDataType]>),
        ]);
      } else {
        return new Map([
          ...state,
          (function () {
            let data = state.get(+payload.collectionId);
            if (!data) {
              data = {
                isLiked: true,
                likesCount: LIKE_COLLECTION ? 1 : 0,
              };
            } else {
              data = {
                ...data,
                isLiked: type === LIKE_COLLECTION ? true : false,
                likesCount: type === LIKE_COLLECTION
                  ? (data.likesCount || 0) + 1
                  : (data.likesCount || 1) - 1,
              };
            }
            return [+payload.collectionId, data];
          })(),
        ]);
      }
    case UPDATE_COLLECTION_COMMENT_COUNT:
      if(Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => {
            let data = state.get(+item.collectionId);
            if (!data) {
              data = {
                isLiked: false,
                likesCount: 0,
                commentsData: {
                  count: item.data?.commentsData?.count
                }
              };
            } else {
              data = {
                ...data,
                commentsData: {
                  ...(data?.commentsData || {}),
                  count: item.data?.commentsData?.count
                }
              };
            }
            return [+item.collectionId, data];
          }) as Iterable<readonly [number, collectionDataType]>)
        ])
      } else {
        return new Map([
          ...state,
          function(){
            let data = state.get(+payload.collectionId);
            if (!data) {
              data = {
                isLiked: false,
                likesCount: 0,
                commentsData: {
                  count: payload?.data?.commentsData?.count
                }
              };
            } else {
              data = {
                ...data,
                commentsData: {
                  ...(data.commentsData || {}),
                  count: payload?.data?.commentsData.count
                }
              };
            }
            return [+payload.collectionId, data];
          }()
        ])
      }
    case RESET_COLLECTIONS_LIKES_AND_COMMENTS:
      return defaultState;
    default:
      return state;
  }
}
