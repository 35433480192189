import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import Scrap from "../../../containers/data/scrap";
import { getScrapSummary, getScrapTags } from "../helpers/getScrapTags";
import { getScrapImageTags } from "../helpers/getScrapImageTags";
import scrap from "../../../containers/data/scrap";

const AIScrappTagging = ({ scrapId, username, privateKey }) => {
  const [scrapData, setScrapData] = useState(null);
  const [imageData, setImageData] = useState(null)
  const [tagData, setTagData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const { data, loading } = useQuery(Scrap, {
    variables: {
      scrap_id: scrapId,
      username,
      private_key: privateKey,
    },
  });

  function getSummaryData(contentForSummary: String) {
    setLoadingTags(true);
    getScrapSummary(contentForSummary)
      .then((summary) => {
        setTagData(summary.data);
      })
      .catch((err) => {
        console.log('There was an error loading summary for this Scrap');
      })
      .finally(() => {
        setLoadingTags(false);
      });
  }

  useEffect(() => {
    if (data) {
      setScrapData(null);
      setTagData(null);
      setLoadingData(true);
      getScrapTags(data.scrap.data).then((tags) => {
        setScrapData(tags.data);
        // @ts-ignore
        const contentForSummary = tags.data?.result?.windowValue?.textContent;

        // load tags
        getSummaryData(contentForSummary);
      }).catch((err) => {
        console.log('There was an error loading tags for this Scrap');
        const hasDesc = data.scrap.data.desc;
        if(hasDesc) {
          getSummaryData(data.scrap.data.desc);
        }
      }).finally(() => {
        setLoadingData(false);
      })

      const hasImage = data.scrap.data.images[0]?.file_path;
      if(hasImage) {
        getScrapImageTags(hasImage).then((tags) => {
          setImageData(tags.data);
        }).catch((err) => {
          console.log('There was an error loading tags for this Scrap');
        })
      }
    }
  }, [data]);

  if (loading) return <div>Loading Scrap data...</div>

  return (
    <div>
      <h3>HTML content of the page</h3>
      {loadingData && <p className="ai__loading">Loading Scrap data...</p>}
      {!loadingData && scrapData && (
        <>
          <div className="ai__summary">
            <p>{scrapData.url}</p>
            <h2>{scrapData.result?.windowValue.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: scrapData.result?.windowValue.content,
              }}
            />

            <h2>Data sent for Summary</h2>
            <p className="ai__summary--sent">
              {scrapData?.result?.windowValue.textContent ||
                data.scrap?.data?.desc}
            </p>
          </div>
        </>
      )}

      {(!scrapData && data.scrap?.data?.desc) && (
        <div>
          <h3>Data sent for Summary</h3>
          <p>
            {data.scrap?.data?.desc}
          </p>
        </div>
      )}

      <h2>Summary & Tags / Categories / Topics</h2>
      {loadingTags && <p className="ai__loading">Loading summary & tags...</p>}

      {tagData && !loadingTags && (
        <>
          <h3>Summary</h3>
          <div
            dangerouslySetInnerHTML={{ __html: tagData.summary?.response }}
          ></div>

          <h3>Tags</h3>
          <div
            dangerouslySetInnerHTML={{ __html: tagData.tags?.response }}
          ></div>
        </>
      )}

      <h2>Image tagging</h2>
      <p>{JSON.stringify(imageData)}</p>
    </div>
  );
};

export default AIScrappTagging;