import { ReduxFiltersType, SET_GROUP_FILTERS } from '../store/filters';

export function setGroupFilters(
  groupFilters: ReduxFiltersType['groupFilters']
) {
  return {
    type: SET_GROUP_FILTERS,
    payload: { groupFilters },
  };
}
