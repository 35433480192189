import { ReduxPopupsType, SET_GROUP_DETAILS_POPUP } from '../store/popups';

export function setGroupDetailsPopup(
  groupDetailsPopup: ReduxPopupsType['groupDetailsPopup']
) {
  return {
    type: SET_GROUP_DETAILS_POPUP,
    payload: { groupDetailsPopup },
  };
}
