import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { ReduxStateType } from '../../../redux/store';
import Register from '../Register';
import {
  LOGIN_METHOD,
  UPSERT_LOGIN_METHOD,
} from '../../../containers/data/addLoginMethod';
import { getProfile } from '../../../containers/data/getProfile';
import { LOGIN_VIA, STORAGE_KEYS } from '../../../helpers/config';
import { updateUser } from '../../../redux/action/userActions';
import {
  AddIcon,
  Apple,
  ExclamationSquare,
  Google,
  Pencil,
  PhoneIcon,
  TrashOutlineIcon,
} from '../../Global/icons';
import Email from '../../Global/icons/Email';
import Facebook from '../../Global/icons/Facebook';
import NewPopup from '../../Global/NewPopup';
import { setWarningPopup } from '../../../redux/action/utils';
import { ProfileType } from '../types';
import { storageListener } from '../../../helpers';

const LoginMethod = () => {
  const user = useSelector((state: ReduxStateType) => state.user);
  const [LoginMethod] = useMutation(LOGIN_METHOD);
  const [UpsetLoginMethod] = useMutation(UPSERT_LOGIN_METHOD);
  const [showLoginMethodPopup, setShowLoginMethodPopup] = useState(false);
  const [editLoginMethod, setEditLoginMethod] = useState(false);
  const [oldMethod, setOldMethod] = useState('');
  const { refetch } = useQuery(getProfile);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');

  // For native error listener
  useEffect(() => {
    if (typeof document === 'object') {
      storageListener(STORAGE_KEYS.LOGIN_SUCCESS, async (message: string) => {
        if (message.length) {
          setShowLoginMethodPopup(false);
          setError('');
          var profileData = await refetch();
          dispatch(updateUser(profileData.data?.profile?.data));
        }
      });
    }
  }, []);

  const submitHandler = async (
    data: ProfileType,
    onError: (message: string) => void,
    onSuccess: Function
  ) => {
    if (editLoginMethod) {
      UpsetLoginMethod({
        variables: {
          old_email: oldMethod,
          email: data.email,
          otp: data.otp,
          login_via: data.login_via,
          phone: data.phone,
          country_code: data.country_code,
          update: true,
        },
      })
        .then(async (res) => {
          if (res.data?.upsertLoginMethod?.http_code === 200) {
            onSuccess();
            setShowLoginMethodPopup(false);
            setOldMethod('');
            var profileData = await refetch();
            setError('');
            setEditLoginMethod(false);
            dispatch(updateUser(profileData.data?.profile?.data));
          } else {
            throw new Error(res.data.upsertLoginMethod?.messages?.error[0]);
          }
        })
        .catch((err) => {
          onError(err.message);
        });
    } else {
      LoginMethod({
        variables: {
          email: data.email,
          phone: data.phone,
          country_code: data.country_code,
          otp: data.otp,
          login_via: data.login_via,
          social_token: data.social_token,
          social_auth_code: data.social_auth_code,
          social_id: data.social_id,
        },
      })
        .then(async (res) => {
          if (res.data?.addLoginMethod?.http_code === 200) {
            onSuccess();
            setShowLoginMethodPopup(false);
            setError('');
            var profileData = await refetch();
            dispatch(updateUser(profileData.data?.profile?.data));
          } else {          
            throw new Error(res.data.addLoginMethod?.messages?.error[0]);
          }
        })
        .catch((err) => {
          onError(err.message);
        });
    }
  };

  const findLoginVia = (type) => {
    return type === 'phone_number' ? LOGIN_VIA.phone_number : LOGIN_VIA[type];
  };

  const removeLoginMethod = (item) => {
    UpsetLoginMethod({
      variables: {
        email: item.email,
        phone: item.phone_number,
        login_via: findLoginVia(item.type),
        remove: true,
      },
    })
      .then(async (res) => {
        if (res.data?.upsertLoginMethod?.http_code === 200) {
          setError('');
          var profileData = await refetch();
          dispatch(updateUser(profileData.data?.profile?.data));
        }
      })
      .catch((error) => {
        setLoader(false);
        setError('Something went wrong try again after some time');
      });
  };

  const markPrimary = (item) => {
    UpsetLoginMethod({
      variables: {
        email: item.email,
        phone: item.phone_number,
        login_via: findLoginVia(item.type),
        mark_primary: true,
      },
    })
      .then(async (res) => {
        if (res.data?.upsertLoginMethod?.http_code === 200) {
          var profileData = await refetch();
          dispatch(updateUser(profileData.data?.profile?.data));
          setLoader(false);
          setError('');
        }
      })
      .catch((error) => {
        setLoader(false);
        setError('Something went wrong try again after some time');
      });
  };

  const returnLogo = (type) => {
    if (type === 'google') {
      return <Google />;
    } else if (type === 'facebook') {
      return <Facebook className="login-method__img__facebook" />;
    } else if (type === 'apple') {
      return <Apple className="login-method__img__apple" />;
    } else if (type === 'email') {
      return <Email className="login-method__img__email" />;
    } else if (type === 'phone_number') {
      return <PhoneIcon className="login-method__img__phone" />;
    }
  };

  const onClose = (close) => {
    close();
    setEditLoginMethod(false);
    setOldMethod('');
  };

  const returnServicesList = useMemo(() => {
    try {
      return [
        ...(user.phone
          ? [{ type: 'phone_number', phone_number: user.phone }]
          : []),
        ...(JSON.parse(user.services) ?? []),
      ];
    } catch (err) {
      return [];
    }
  }, [user.services, user.phone]);

  const checkPrimary = (item) => {
    if (
      item.email === JSON.parse(user.main_service)?.email &&
      item.type === JSON.parse(user.main_service)?.type
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="profile-login-method">
      {returnServicesList.map((item, index) => {
        return (
          <div
            className={`login-method ${loader ? 'login-method__loader' : ''}`}
            key={index}
          >
            <div className="login-method__body">
              <div className="login-method__upper-body">
                <span className="login-method__img">
                  {returnLogo(item.type)}
                </span>
                <span className="login-method__name">
                  {/* <span className="login-method__first-name">
                    {user.userName}
                  </span> */}
                  <span
                    className={`login-method__last-name ${
                      checkPrimary(item)
                        ? 'login-method__last-name-primary'
                        : ''
                    }`}
                    title={item.email ? item.email : item.phone_number}
                  >
                    {item.email ? item.email : item.phone_number}
                  </span>
                </span>
              </div>
              <div className="login-method__lower-body">
                <span className="login-method__log-methd"></span>
                <span className="login-method__log-add"></span>
              </div>
            </div>
            <div className="login-method__edit-prof">
              {checkPrimary(item) ? (
                <span className="login-method__edit-btn login-method__login-btn">
                  <span className="login-method__edit-prof-text__primary">
                    Primary
                  </span>
                </span>
              ) : (
                <>
                  <span className="login-method__edit-btn login-method__login-btn">
                    <span
                      className="login-method__edit-prof-text"
                      onClick={() => {
                        setLoader(true);
                        markPrimary(item);
                      }}
                    >
                      Mark Primary
                    </span>
                  </span>

                  {item.type === 'email' && (
                    <span
                      className="login-method__edit-btn login-method__login-btn"
                      onClick={() => {
                        setShowLoginMethodPopup(true);
                        setEditLoginMethod(true);
                        setOldMethod(item.email);
                      }}
                    >
                      <span>
                        <span className="login-method__edit-prof-pencil">
                          <Pencil />
                        </span>
                        <span className="login-method__edit-prof-text">
                          Edit
                        </span>
                      </span>
                    </span>
                  )}

                  <span className="login-method__remove-btn login-method__login-btn">
                    <span className="login-method__edit-prof-icon">
                      <TrashOutlineIcon />
                    </span>
                    <span
                      className="login-method__edit-prof-text"
                      onClick={() => {
                        dispatch(
                          setWarningPopup({
                            warningMessage:
                              'Are you sure? Click OK to remove this login method.',
                            header: 'Warning',
                            leftLoader:true,
                            cancel: {
                              label: 'Cancel',
                            },
                            submit: {
                              label: 'OK',
                              cb: () => {
                                removeLoginMethod(item);
                              },
                            },
                          })
                        );
                      }}
                    >
                      Remove
                    </span>
                  </span>
                </>
              )}
            </div>
          </div>
        );
      })}

      <div className="add-login-cred">
        <span className="add-login-cred__login-wrp">
          <span className="add-login-cred__login-text">Add Login Method</span>
          <span className="add-login-cred__login-icon">
            <AddIcon onClick={() => setShowLoginMethodPopup(true)} />
            <NewPopup
              controlled={{
                show: showLoginMethodPopup,
                setShow: setShowLoginMethodPopup,
              }}
              className="login-method__popup "
            >
              {(close) => (
                <div className="login-popup__container">
                  <Register
                    onSubmit={submitHandler}
                    editLoginMethod={editLoginMethod}
                    overrideSubmit
                    prefillData={oldMethod}
                    onClose={() => onClose(close)}
                  />
                </div>
              )}
            </NewPopup>
          </span>
        </span>
      </div>
      {error && (
        <div className="login-method__error">
          <span className="otp-error__icon">
            <ExclamationSquare />
          </span>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default LoginMethod;
