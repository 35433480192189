import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const Sort = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H17C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447715 2 0 1.55228 0 1ZM3 6C3 5.44772 3.44772 5 4 5H14C14.5523 5 15 5.44772 15 6C15 6.55228 14.5523 7 14 7H4C3.44772 7 3 6.55228 3 6ZM7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H11C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10H7Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default Sort;
