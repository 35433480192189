import { entryTypes } from '../../types/feed';

const DEFAULT: entryTypes[] = [];

const feed = (state = DEFAULT, action) => {
  switch (action.type) {
    case 'LOAD_FEED':
      return [...state, ...action.feedData];

    case 'RESET_STATE':
      return action.feedData;
    case 'NEW_SCRAP':
      return [action.scrap, ...state];
    case 'UPDATE_SCRAP':
      return state.map((scrap) =>
        +scrap.id === +action.scrap.id ? { ...scrap, ...action.scrap } : scrap
      );
    case 'UPDATE_NOTE':
      return state.map((en) => {
        if (en.uid === action.uid) {
          /* eslint no-param-reassign: "error" */
          en.notes = action.note;
        }

        return en;
      });

    case 'UPDATE_COMMENT':
      return state.map((en) => {
        if (en.uid === action.uid) {
          /* eslint no-param-reassign: "error" */
          en.comment = action.comment;
        }

        return en;
      });
    case 'DELETE_FEED_ITEM':
      return state.map((en) => en).filter((entry) => entry.uid !== action.uid);
    case 'REMOVE_STORY_FROM_FEED':
      return state.map((en) => {
        if (en.pid === action.pid) {
          en.stories = en.stories.filter((story) => story.id !== action.id);
        }

        return en;
      });
    // add new stories to feed of a selected uid
    // action sends uid and list of stories
    case 'ADD_STORIES_TO_FEED':
      return state.map((en) => {
        if (en.uid === action.uid) {
          en.stories = [...en.stories, ...action.stories];
        }
        return en;
      });
    case 'UPDATE_TAG':
      return state.map((en) => {
        if (en.uid === action.uid) {
          /* eslint no-param-reassign: "error" */
          en.tags = action.tag;
        }
        return en;
      });
    case 'UPDATE_COLLECTION':
      return state.map((en) => {
        if (en.uid === action.uid) {
          /* eslint no-param-reassign: "error" */
          en.collections = action.collections;
        }
        return en;
      });
    default:
      return state;
  }
};

export default feed;
