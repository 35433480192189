import { GroupType } from '../../containers/GroupShareContainer/types';
import { collectionType } from '../../types/collections';

export type ReduxPopupsType = {
  groupDetailsPopup?: {
    type: 'groupId' | 'groupData' | 'collectionId' | 'collectionData';
    data: string | GroupType | collectionType;
    isReadOnly?: boolean;
    dontRedirectOnMerge?: boolean;
  };
};

export const SET_GROUP_DETAILS_POPUP = 'SET_GROUP_DETAILS_POPUP';
export const SET_SCRAP_POPUP = 'SET_SCRAP_POPUP';

type popupsActionType = typeof SET_GROUP_DETAILS_POPUP | typeof SET_SCRAP_POPUP;

const defaultState: ReduxPopupsType = {
  groupDetailsPopup: null,
};

export default function popups(
  state = defaultState,
  action: {
    type: popupsActionType;
    payload: ReduxPopupsType;
  }
) {
  const { type, payload } = action;
  switch (type) {
    case SET_GROUP_DETAILS_POPUP:
      return { ...state, groupDetailsPopup: payload.groupDetailsPopup };

    default:
      return state;
  }
}
