import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const PencilEdit = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      onClick={onClick}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.22 0.585108C14.9996 -0.195036 16.2637 -0.195036 17.0433 0.585108L19.4153 2.95868C20.1949 3.73882 20.1949 5.00369 19.4153 5.78383L9.57818 15.6275C9.30068 15.9051 8.94754 16.0948 8.5629 16.1728L5.58834 16.7758C4.93242 16.9087 4.25356 16.704 3.78034 16.2304C3.30712 15.7569 3.10249 15.0776 3.23536 14.4212L3.83793 11.4447C3.91584 11.0598 4.1054 10.7064 4.38291 10.4287L14.22 0.585108ZM5.62226 12.6924L5.72939 12.1631L7.84492 14.2801L7.31605 14.3873L5.19198 14.8178L5.62226 12.6924ZM9.3848 12.9958L7.0128 10.6223L14.22 3.41026L15.6316 1.99768L16.592 2.95868L17.0433 3.41026L18.0036 4.37125L16.592 5.78383L9.3848 12.9958ZM0.998179 18.0023C0.4469 18.0023 0 18.4495 0 19.0012C0 19.5528 0.4469 20 0.998179 20L18.9654 20C19.5167 20 19.9636 19.5528 19.9636 19.0012C19.9636 18.4495 19.5167 18.0023 18.9654 18.0023L0.998179 18.0023Z"
        fill="#838383"
      />
    </svg>
  );
};

export default PencilEdit;
