import React, { useState } from 'react';
import { parseMetaData } from '../../../helpers';
import { returnImageURL } from '../../Gallery/helpers/returnImageURL';
import GlobalImageStatus from '../../GlobalSearch/components/GlobalImageStatus';
import './styles.css';

interface propTypes {
  containerClassName?: string;
  sizes?: string[];
  image: string;
  imageClass?: string;
  alt?: string;
  config?: string;
  background?: boolean;
  gridWidth?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?(event): any;
  onLoad?: () => void;
}

//RETURNS AND SET MAIN IMAGE WITH SETTING THE IMAGE FOR BACKGROUND
const MainImage = ({
  srcSet,
  image,
  imageClass,
  setCurrentSrc,
  alt,
  onClick,
  style,
  onLoad: onImageLoaded,
}) => {
  const [loadingError, setLoadingError] = useState(false);
  //SET CURRENT SRC FOR BACKGROUND IMAGE
  const onLoad = (event) => {
    setCurrentSrc(event.target.currentSrc);
    onImageLoaded && onImageLoaded();
  };
  //HANDLE BROKEN IMAGE AND LOADS ORIGINAL IMAGE INSTEAD
  const onError = (event) => {
    setLoadingError(true);
    setCurrentSrc(image);
  };

  return loadingError || !srcSet.length || image.startsWith('data:image/') ? (
    <img
      src={image}
      className={imageClass}
      alt={alt}
      onLoad={(e) => {
        if (!srcSet.length) onLoad(e);
        else if (onImageLoaded) onImageLoaded();
      }}
      onClick={onClick}
      style={style}
    />
  ) : (
    <img
      alt={alt}
      srcSet={srcSet}
      onError={onError}
      onLoad={onLoad}
      className={imageClass}
      onClick={onClick}
      style={style}
    />
  );
};

const ResponsiveImage = (props: propTypes) => {
  const {
    containerClassName,
    sizes,
    image: mainImage,
    config,
    gridWidth,
    imageClass,
    background,
    alt,
    onClick,
    onLoad,
  } = props;
  var styles = {};
  if (config) {
    const metaData = parseMetaData(config);
    if (metaData?.original && gridWidth) {
      const widthRatio = ((gridWidth - 25) * 100) / metaData?.original?.width;
      const imgHeight = (metaData?.original?.height * widthRatio) / 100;
      styles = {
        minHeight: (imgHeight > 500 ? 500 : imgHeight) + 'px',
      };
    }
  }

  const image = mainImage !== null ? returnImageURL(mainImage):null;
  const [currentSrc, setCurrentSrc] = useState('');

  //GET CURRENT SIZE URL
  const getURL = (size) => {
    var imgArr = image.split('/');
    imgArr[imgArr.length - 1] = size + '/' + imgArr[imgArr.length - 1];
    var imgURL = imgArr.join('/');
    return imgURL;
  };
  //RETURN SRCSET STRING BASED ON ALL SIZES PROVIDED
  const setSrcSet = () => {
    let response = '';
    if (sizes && image) {
      sizes.map((s) => {
        response += `${getURL(s)} ${s}w, `;
        return true;
      });
      return response.split(', ').slice(0, -1).join(', ');
    }
    return '';
  };

  const srcSet = setSrcSet();
  let baseClass = image ? 'responsive__img' : 'responsive__main';
  if(containerClassName) baseClass += ' ' + containerClassName;

  return background ? (
    <div className={baseClass}>
      <div className={`${imageClass}-div`}>
        {image && image !== '' ? (
          <MainImage
            srcSet={srcSet}
            image={image}
            imageClass={imageClass}
            setCurrentSrc={setCurrentSrc}
            alt={alt}
            onClick={onClick}
            style={styles}
            onLoad={onLoad}
          />
        ) : (
          <GlobalImageStatus config={config} />
        )}
      </div>
      <div
        className={`${imageClass}-background`}
        style={{ background: `url('${currentSrc}')` }}
      ></div>
    </div>
  ) : (
    <MainImage
      srcSet={srcSet}
      image={image}
      imageClass={imageClass}
      setCurrentSrc={setCurrentSrc}
      alt={alt}
      onClick={onClick}
      style={styles}
      onLoad={onLoad}
    />
  );
};

export default ResponsiveImage;
