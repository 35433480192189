export const scrapsSettingsMapper = {
  description: 'desc',
  updatedAt: 'updated_at',
  tags: 'tags',
  title: 'title',
} as const;

export const collectionsSettingsMapper = {
  coverImage: 'cover_image',
  updatedAt: 'updated_at',
  description: 'description',
  panelCount: 'panel_count',
  state: 'state',
} as const;
export const storiesSettingsMapper = {
  coverImage: 'cover_image',
  updatedAt: 'updated_at',
  description: 'desc',
  panelCount: 'panel_count',
  state: 'is_published',
  tags: 'tags'
} as const;

