import { useEffect } from 'react';

function useOutsideAlerter(ref, cb, removeListener, onScroll = false) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        cb();
      }
    }
    if (!removeListener) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      if (onScroll) {
        document.addEventListener('scroll', handleClickOutside);
      }
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
        if (onScroll) {
          document.removeEventListener('scroll', handleClickOutside);
        }
      };
    }
  }, [ref, removeListener]);
}
export default useOutsideAlerter;
