import { gql } from '@apollo/client';

export const REMOVE_CONTACT = gql`
  mutation removeContact($userName: String!) {
    removeContact(userName: $userName) {
      status
      messages {
        error
        success
      }
    }
  }
`;
