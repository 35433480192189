import { CommentType } from '../../components/Comments';

type groupDataType = {
  likesCount: number;
  commentsData?: {
    count: number;
    comments?: CommentType[]
  };
  isLiked: boolean;
};
export type ReduxGroupsLikesAndCommentsType = Map<
  number,
  groupDataType
>;

export const UPDATE_GROUPS_LIKES_AND_COMMENTS =
  'UPDATE_GROUPS_LIKES_AND_COMMENTS';
export const LIKE_GROUP = 'LIKE_GROUP';
export const DISLIKE_GROUP = 'DISLIKE_GROUP';
export const UPDATE_GROUP_COMMENT_COUNT = 'UPDATE_GROUP_COMMENT_COUNT';
export const RESET_GROUPS_LIKES_AND_COMMENTS = 'RESET_GROUPS_LIKES_AND_COMMENTS';

const defaultState: ReduxGroupsLikesAndCommentsType = new Map();
type actionType =
  | typeof UPDATE_GROUPS_LIKES_AND_COMMENTS
  | typeof LIKE_GROUP
  | typeof DISLIKE_GROUP
  | typeof UPDATE_GROUP_COMMENT_COUNT
  | typeof RESET_GROUPS_LIKES_AND_COMMENTS;
  
export type groupsLikesAndCommentsPayloadType = {
  groupId: number;
  data?: Partial<groupDataType>;
};

/**
 * groupsLikesandComments reducer
 * It keeps the list of all the likes that are made by the user on groups.
 * The list gets updated as more groups are loaded across the app.
 * It will also save the comments of groups
 */
export default function groupsLikesAndComments(
  state = defaultState,
  action: {
    type: actionType;
    payload:
      | Array<groupsLikesAndCommentsPayloadType>
      | groupsLikesAndCommentsPayloadType;
  }
) {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_GROUPS_LIKES_AND_COMMENTS:
      if (Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => [+item.groupId, item.data]) as Iterable<
            readonly [number, groupDataType]
          >),
        ]);
      } else {
        return new Map([...state, [+payload.groupId, payload.data]]);
      }
    case DISLIKE_GROUP:
    case LIKE_GROUP:
      if (Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => {
            let data = state.get(+item.groupId);
            if (!data) {
              data = {
                isLiked: type === LIKE_GROUP ? true : false,
                likesCount: type === LIKE_GROUP ? 1 : 0,
              };
            } else {
              data = {
                ...data,
                isLiked: type === LIKE_GROUP ? true : false,
                likesCount:
                  type === LIKE_GROUP
                    ? (data.likesCount || 0) + 1
                    : (data.likesCount || 1) - 1,
              };
            }
            return [+item.groupId, data];
          }) as Iterable<readonly [number, groupDataType]>),
        ]);
      } else {
        return new Map([
          ...state,
          (function () {
            let data = state.get(+payload.groupId);
            if (!data) {
              data = {
                isLiked: true,
                likesCount: LIKE_GROUP ? 1 : 0,
              };
            } else {
              data = {
                ...data,
                isLiked: type === LIKE_GROUP ? true : false,
                likesCount: type === LIKE_GROUP
                  ? (data.likesCount || 0) + 1
                  : (data.likesCount || 1) - 1,
              };
            }
            return [+payload.groupId, data];
          })(),
        ]);
      }
    case UPDATE_GROUP_COMMENT_COUNT:
      if(Array.isArray(payload)) {
        return new Map([
          ...state,
          ...(payload.map((item) => {
            let data = state.get(+item.groupId);
            if (!data) {
              data = {
                isLiked: false,
                likesCount: 0,
                commentsData: {
                  count: item.data?.commentsData?.count
                }
              };
            } else {
              data = {
                ...data,
                commentsData: {
                  ...(data?.commentsData || {}),
                  count: item.data?.commentsData?.count
                }
              };
            }
            return [+item.groupId, data];
          }) as Iterable<readonly [number, groupDataType]>)
        ])
      } else {
        return new Map([
          ...state,
          function(){
            let data = state.get(+payload.groupId);
            if (!data) {
              data = {
                isLiked: false,
                likesCount: 0,
                commentsData: {
                  count: payload?.data?.commentsData?.count
                }
              };
            } else {
              data = {
                ...data,
                commentsData: {
                  ...(data.commentsData || {}),
                  count: payload?.data?.commentsData.count
                }
              };
            }
            return [+payload.groupId, data];
          }()
        ])
      }  
    case RESET_GROUPS_LIKES_AND_COMMENTS:
      return defaultState;
    default:
      return state;
  }
}
