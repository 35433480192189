import React, { useEffect, useState } from 'react';
import './styles.css';
import Squirrle from '../icons/Squirrle';
import { Link, Redirect } from "react-router-dom";
import { isMobileDevice } from '../../../helpers/handleShare';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import EmptyMessageForMobile from './EmptyMessageForMobile';
import * as temp from '../../../settings.json';

interface Proptypes {
  header?: {
    heading?: string;
    className?: string;
    // eslint-disable-next-line no-undef
    customRender?: () => JSX.Element;
  };
  body?: {
    className?: string; //Optional className which will be added to body
  };
  titleHeading?: string;
  type?: 'new' | 'empty';
  title?: string;
  register_from?:number;
  isHome?: boolean;

  // eslint-disable-next-line no-undef
  children?: React.ReactChild | React.ReactChildren; //to render custom body
}

function EmptyPageMessage({ type, header, titleHeading, children, register_from, isHome }: Proptypes) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { default: config }: any = { ...temp };
  const [checkAndroidWebView, setCheckAndroidWebView] = useState(false);
  const [checkIosWebView, setCheckIosWebView] = useState(false);
  function renderHeader() {
    const { className, customRender, heading } = header || {};
    if (customRender) return customRender();
    let containerClass = 'new-popup__header-inner-container';
    if (className) containerClass += ' ' + className;
    return (
      <div className="empty-page__logo">
        <Squirrle />
      </div>
    );
  }


  let webView = null;
  if (typeof window !== 'undefined') {
    // the app webview adds a variable to the window object
    // if it exists, then it is the mobile app
    // @ts-ignore
    webView = !!window.ReactNativeWebView;
  }

  function getMobileOperatingSystem() {
    if (typeof window !== 'undefined') {
      if (webView) {
        return 'app';
      }

      const userAgent = navigator.userAgent || navigator.vendor;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }

      // iOS detection from
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.Stream) {
        return 'iOS';
      }
    }

    return 'unknown';
  }

  const EmptyMessage = () => {
    if (checkAndroidWebView) {
      return (
        <EmptyMessageForMobile
          image1="/dashboard/img/playStoreScrapCreate.png"
          image2="/dashboard/img/playStoreSave.png"
          viewType="app"
        />
      );
    } else if (checkIosWebView) {
      return (
        <EmptyMessageForMobile
          image1="/dashboard/img/appStoreScrapCreate.png"
          image2="/dashboard/img/appStoreScrapSave.png"
          viewType="app"
        />
      );
    } else {
      if (getMobileOperatingSystem() === 'iOS' && isMobileDevice()) {
        return (
          <EmptyMessageForMobile
            image1="/dashboard/img/appStore.svg"
            viewType="browser"
            deviceName="iOS"
            link={config?.appStore ? config?.appStore : '#'}
          />
        );
      } else if (getMobileOperatingSystem() === 'Android' && isMobileDevice()) {
        return (
          <EmptyMessageForMobile
            image1="/dashboard/img/playStore.svg"
            viewType="browser"
            deviceName="Android"
            link={config?.playStore ? config?.playStore : '#'}
          />
        );
      } else {
        return (
          <div className="empty-page__detailholder">
            <div className="empty-page__detailholder__detail">
              <div className="empty-page__detailholder__detail__li">
                Install the{' '}
                <a
                  href={
                    config?.chromeExtensionStoreUrl
                      ? config?.chromeExtensionStoreUrl
                      : '/'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="empty-page__detailholder__link"
                >
                  Chrome Extension
                </a>{' '}
                to Scrap while browsing the web - web pages, content on pages,
                and screenshots. You can also Scrap video, audio or screen
                recordings for more ways to capture your ideas and notes.
              </div>
              {register_from !== 2 && (
                <div className="empty-page__detailholder__detail__li">
                  Download Scrappi for{' '}
                  <a
                    href={config?.appStore ? config?.appStore : '/'}
                    className="empty-page__detailholder__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    iOS
                  </a>{' '}
                  or{' '}
                  <a
                    href={config?.playStore ? config?.playStore : '/'}
                    className="empty-page__detailholder__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Android
                  </a>{' '}
                  to quickly Scrap on your phone. Share anything to Scrappi to
                  save it.
                </div>
              )}
              <div>
                Collect Scraps from the top of this dashboard. Here you can
                Scrap web pages from URLs, or upload files (pdf, xls, doc, txt, and
                ppt) to create Scraps. Or, quickly Scrap a standalone Note.
              </div>
            </div>
          </div>
        );
      }
    }
  };

  function Title() {
    switch (type) {
      case 'new':
        return (
          <h1
            className={`empty-page__title ${
              isMobileDevice() ? 'empty-page__title-mobile' : ''
            }`}
          >
            {titleHeading}
          </h1>
        );
    }
  }

  function Detail() {
    switch (type) {
      case 'new':
        return EmptyMessage();
      case 'empty':
        return (
          <div className="empty-page__empty-message">
            Scraps that are not assigned to Collections yet will be shown here.
            Right now, there are none.
          </div>
        );
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (!navigator.userAgent.match('Safari') && !navigator.userAgent.indexOf("Firefox") ) && setCheckIosWebView(true);
      (navigator.userAgent.indexOf('wv') !== -1 ||
        navigator.userAgent.indexOf('WebView') !== -1) &&
        setCheckAndroidWebView(true);
    }
  }, []);

  /**
   * TODO: if it is mobile app webview, redirect the user to a collection
   *  that is about using the mobile app.
   *  move this path to env
   */
  if(isHome && getMobileOperatingSystem() === 'app') {
    return <Redirect to="/c/scrappi-on-mobile/439" />
  }


  /**
   * Hide the empty page message if the device is not mobile
   * the desktop version has a different onboarding experience
   */
  if(getMobileOperatingSystem() === 'unknown' && isHome) {
    return null;
  }

  return (
    <div className="empty-page">
      {renderHeader()}

      {children ? (
        <div className="empty-page__dynamic-content">{children}</div>
      ) : (
        <>
          {Title()}
          {Detail()}
        </>
      )}
    </div>
  );
}

export default EmptyPageMessage;
