import React, { Fragment, useRef, useState } from 'react';
import { ScrapImageData } from '../../../containers/ScrapImagesPopupContainer';
import Tiptap from '../../Global/Tiptap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DragHandle } from '../../Gallery/components/AddedImages/helpers/draggables';
import {
  AllDirectionsDragIcon,
  DownloadIcon,
  PencilEditIcon
} from '../../Global/icons';
import TrashOutline from '../../Global/icons/TrashOutline';
import useWindowOnClick from '../../../helpers/customHooks/useWindowOnclick';
import Icon from '../../Global/Icon';
import { isEmpty } from '../../../helpers';
import { imageSize } from '../../../helpers/imageSize';

const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  hideDelete?: boolean;
  isEdit?: boolean;
  imageData: ScrapImageData;
  onCropClick: () => void;
  onDescriptionChange: (text: string) => void;
  onDeleteClick: () => void;
  onImageClick?: () => void;

}

const ScrapImagesGridItem = ({
  hideDelete,
  isEdit,
  imageData,
  onCropClick,
  onDescriptionChange,
  onDeleteClick,
  onImageClick,

}: Proptypes) => {
  const [load, setLoad] = useState(true);
  const inputRef = useRef<HTMLInputElement>()
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonId = `image-${imageData.key}`;
  const handleOutSideClick = (el: HTMLElement) => {
    const closestElement = el.closest('#' + buttonId);
    if (!closestElement) setShowDropdown(false);
  };
  useWindowOnClick({ cb: handleOutSideClick, removeListener: !showDropdown });

  return (
    <Fragment>
      <div className="scrap-images-grid__image-container">
        {/* {load && (
          <div
            className={`${
              load
                ? 'scrap-images-grid__loader'
                : 'scrap-images-grid__loader-hide'
            }`}
          >
            <LoaderImage />
          </div>
        )} */}
        {isEdit && (
          <DragHandle>
            <button className="scrap-images-grid__drag-button" tabIndex={-1}>
              <AllDirectionsDragIcon />
            </button>
          </DragHandle>
        )}
        <img
          src={imageSize(imageData.imageViewUrl,800)}
          onLoad={() => {
            setLoad(false);
          }}
          alt="loader"
          className="scrap-images-grid__image"
          onClick={(e) => onImageClick && onImageClick()}
        />
        {load && (
          <div className="scrap-images-grid__loader">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
          </div>
        )}
        <button
          id={buttonId}
          onClick={() => inputRef.current.focus()}
          className="scrap-images-grid__image-control-button"
        >
          <Icon iconClass="three-dots" size="big" />
        </button>
        <input
          readOnly
          className='scrap-images-grid__image-focus-input'
          onFocus={(e) => {
            setShowDropdown(true);
          }}
          ref={inputRef}
          onBlur={(e) => {
            setShowDropdown(false);
          }}
        />
        {showDropdown && (
          <div className="scrap-images-grid__image-dropdown">
            <button
              onMouseDown={e => {
                const a = document.createElement('a')
                a.href = imageData.imageViewUrl;
                a.download = imageData.filename;
                a.rel = 'noopener noreferrer';
                a.target = '_blank';
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
              }}
              className="scrap-images-grid__image-dropdown-item"
            >
              <DownloadIcon /> Download
            </button>
            {onCropClick && (
              <button
                onMouseDown={(e) => {
                  onCropClick();
                }}
                className="scrap-images-grid__image-dropdown-item"
              >
                <PencilEditIcon /> Edit
              </button>
            )}
            {isEdit && !hideDelete && (
              <button
                onMouseDown={onDeleteClick}
                className="scrap-images-grid__image-dropdown-item scrap-images-grid__image-dropdown-item--delete"
              >
                <TrashOutline /> Delete
              </button>
            )}
          </div>
        )}
      </div>
      {(isEdit || !isEmpty(imageData.description, true)) && (
        <div className="scrap-images-grid__description">
          <Tiptap
            onChange={onDescriptionChange}
            placeholder="Write a caption"
            hideAllTools
            edit={isEdit}
            labelledEditor={isEdit}
            label="Caption"
            text={imageData.description}
          />
        </div>
      )}
    </Fragment>
  );
};

export default ScrapImagesGridItem;
