import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import { formatTimestampDate, showFullDate } from '../../../helpers';
import Avatar from '../../Global/Avatar';
import userInitials from '../../../helpers/userInitial';
import MyDropDown from '../../Global/MyDropDown';
import {
  AIIcon,
  BookmarkIcon,
  CollectionIcon,
  MinusIcon, NotesIcon,
  PencilEditIcon,
  ShareIcon,
  TagIcon,
  TrashOutlineIcon,
  TripleDotIcon
} from "../../Global/icons";
import { dropdownType } from '../../NewScrapPopup/NewScrapPopupHeader';
import { scrapType } from '../../../types/scrapType';
import { scrapCardViewType } from '../index';
import ScrapEditTags from '../../ScrapDetails/components/ScrapEditTags';
import { PropHelpers } from '../../Global/CheckboxList';
import UPSERT_SCRAP_COLLECTIONS from '../../../containers/data/upsertScrapCollections';
import UPSERT_SCRAP_TAGS from '../../../containers/data/upsertScrapTags';
import { saveCollectionsInScrapHandler } from '../../../containers/NewScrapPopupContainer/helpers/collectionsChangeHandler';
import { useApolloClient } from '@apollo/client';
import updateEntryCache from '../../../helpers/cacheHelpers/updateEntry';
import NewPopup from '../../Global/NewPopup';
import UPSERT_SCRAP from '../../../containers/data/upsertScrap';
import ScrapAddNotesGroup from '../../Global/InputsGroup/ScrapAddNotesGroup';
import { canPaste } from "../../../redux/action/pasteDataAction";
import ScrapCardAISummary from "./ScrapCardAISummary";
import Trust from '../../Trust';
import TrustIcon from '../../Trust/TrustIcon';

type dropdownTypeMenu = {
  valueField:
    | 'delete'
    | 'edit'
    | 'remove'
    | 'share'
    | 'bookmark'
    | 'note'
    | 'collection'
    | 'tags'
    | 'ai'
    | 'trust'
    | '';
  textField: string | React.JSX.Element;
  className?: string;
  icon: React.ReactElement;
  isIconStroked?: boolean;
};

interface Proptypes {
  fromString?: string;
  userName: string;
  headline?: string;
  avatar: string;
  userDisplayName: string;
  created_at: string;
  updated_at: string;
  handleCopy?: () => void;
  onDropdownClosed?: () => void;
  onDropdownOpened?: () => void;
  onDeleteClick?: () => void;
  onEditClick?: (scrap: scrapType, val?: boolean) => void;
  onRemoveClick?: () => void;
  onBookmarkClick?: () => void;
  scrap?: scrapType;
  view?: scrapCardViewType;
  isBookmarked?: boolean;
  showModal?: {
    showModal: 'collections' | 'tags' | 'notes',
    resetModal: () => void,
  };
  trustCb?: (val: boolean) => void;
}

const ScrapCardHeader = ({
  onDeleteClick,
  onEditClick,
  onRemoveClick,
  handleCopy,
  onBookmarkClick,
  fromString,
  userName,
  headline,
  avatar,
  scrap,
  userDisplayName,
  created_at,
  updated_at,
  onDropdownClosed,
  onDropdownOpened,
  view,
  isBookmarked,
  showModal,
  trustCb,
}: Proptypes) => {
  const [showCollectionsPopup, setShowCollectionsPopup] = useState(false);
  const [showTagsPopup, setShowTagsPopup] = useState(false);
  const [collectionData, setCollectionData] = useState<PropHelpers['dataType'][]>([]);
  const [tagData, setTagData] = useState<PropHelpers['dataType'][]>([]);
  const [showNotesPopup, setShowNotesPopup] = useState(false);
  const [showAISummary, setShowAISummary] = useState(false);
  const [data, setData] = useState<PropHelpers['dataType'][]>([]);
  const [notesContent, setNotesContent] = useState<string | null>(scrap.desc || null);
  const [titleContent, setTitleContent] = useState<string | null>(scrap.title || null);
  const [saving, setSaving] = useState(false);

  const history = useHistory();
  const user = useSelector((state: ReduxStateType) => state.user);
  const dispatch = useDispatch();

  const client = useApolloClient();

  useEffect(() => {
    setCollectionData(scrap?.collections || []);
    setTagData(scrap?.tags || []);

    if(showModal.showModal === 'collections') setShowCollectionsPopup(true);
    if(showModal.showModal === 'tags') setShowTagsPopup(true);
    if(showModal.showModal === 'notes') setShowNotesPopup(true);

    // disable pasting if the popup is open
    if(showCollectionsPopup || showTagsPopup || showNotesPopup) {
      dispatch(canPaste(false));
    }
  }, [scrap, showModal, showCollectionsPopup, showTagsPopup, showNotesPopup]);

  function dropdownOptionClickHandler(option: Partial<dropdownTypeMenu>) {
    switch (option.valueField) {
      case 'delete':
        onDeleteClick();
        break;
      case 'edit':
        onEditClick(scrap, false);
        break;
      case 'remove':
        onRemoveClick();
        break;
      case 'share':
        handleCopy();
        break;
      case 'bookmark':
        onBookmarkClick();
        break;
      case 'note':
        setShowNotesPopup(true);
        break;
      case 'collection':
        // setData(scrap?.collections || []);
        setShowCollectionsPopup(true);
        break;
      case 'tags':
        // setData(scrap?.tags || []);
        setShowTagsPopup(true);
        break;
      case 'ai':
        setShowAISummary(true);
        break;

      default:
        break;
    }
  }

  const dropdownList: dropdownTypeMenu[] = [];
  // if (handleCopy)
  //   dropdownList.push({
  //     valueField: 'share',
  //     textField: 'Share',
  //     icon: <ShareIcon />,
  //   });

  // add trust option if the user is not the active user
  if(user.user_id !== scrap.user_id) {
    dropdownList.push({
      valueField: 'trust',
      textField: <Trust isTrusted={scrap.is_trusted} userId={scrap.user_id} cb={(status) => trustCb(status)} />,
      icon: <TrustIcon type={scrap.is_trusted ? 'fill' : 'outline'} />
    })
  }

  // AI option needs to be on all Scrap, only check if the URL property is present
  // and service is available
  if (scrap.url && !scrap?.service) {
    dropdownList.push({
      valueField: 'ai',
      textField: 'AI Summary',
      icon: <AIIcon />,
    });
  }

  if (onEditClick)
    dropdownList.push(
      {
        valueField: 'edit',
        textField: 'Edit Scrap',
        icon: <PencilEditIcon />,
      },
      {
        valueField: 'note',
        textField: scrap.desc ? 'Edit Notes' : 'Add Notes',
        icon: <NotesIcon />
      },
      {
        valueField: 'collection',
        textField: 'Add to Collection',
        icon: <CollectionIcon />,
      },
      {
        valueField: 'tags',
        textField: 'Add Tags',
        icon: <TagIcon />,
      },
    );

  // if (onBookmarkClick)
  //   dropdownList.push({
  //     valueField: 'bookmark',
  //     textField: isBookmarked ? 'Saved' : 'Save',
  //     icon: <BookmarkIcon isFilled={isBookmarked} />,
  //     className: isBookmarked ? 'scrap-card__footer-dropdown-item--active' : ''
  //   });

  if (onDeleteClick)
    dropdownList.push({
      valueField: 'delete',
      textField: 'Delete',
      icon: <TrashOutlineIcon />,
      isIconStroked: true,
    });

  if (onRemoveClick)
    dropdownList.push({
      valueField: 'remove',
      textField: `Remove from ${
        view === 'group-page' ? 'Share' : 'Collection'
      }`,
      icon: <MinusIcon />,
    });

  async function savedTags(type: 'collectionType' | 'tagType') {
    const updatedScrap = { ...scrap };
    if (type === 'collectionType') {
      const collectionData = data.map(({ id }) => ({ id }));
      await saveCollectionsInScrapHandler(scrap?.id, collectionData, client);
      updatedScrap.collections = collectionData;
      // TODO: handle errors
    }

    if (type === 'tagType') {
      const tagData = data.map(({ id }) => ({ id }));
      const tagDataWithSlug = data.map(({ slug }) => ({ slug }));
      await client.mutate({
        mutation: UPSERT_SCRAP_TAGS(),
        variables: {
          scrap_id: scrap?.id,
          tags: tagData,
        },
      });

      updatedScrap.tags = tagDataWithSlug;
      // TODO: handle errors
    }

    updateEntryCache(client.cache, updatedScrap, true, 'Scrap');
    updateEntryCache(client.cache, updatedScrap, true, 'CollectionScrap');
  }

  /**
   * Save the note and title
   * set a saving state, that disables the save button & show loading
   */
  async function saveNote() {
    setSaving(true);
    const trimmedNotes = notesContent.trim();
    const notesValue = trimmedNotes === '<div></div>' ? null : trimmedNotes;
    await client.mutate({
      mutation: UPSERT_SCRAP(),
      variables: {
        id: scrap.id,
        desc: notesValue,
        title: titleContent,
      }
    });

    let updatedScrap = { ...scrap };
    updatedScrap.desc = notesValue;
    updatedScrap.title = titleContent;
    updateEntryCache(client.cache, updatedScrap, true, 'Scrap');
    updateEntryCache(client.cache, updatedScrap, true, 'CollectionScrap');
    setSaving(false);
  }

  return (
    <div className="scrap-card__header">
      <div
        className="scrap-card__user-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/${userName}${fromString ?? ''}`);
        }}
      >
        <Avatar
          avatar={avatar}
          creator_initials={userInitials(userDisplayName)}
          userName={userName}
          className={'scrap-card__user-avatar'}
        />
        <div className="scrap-card__user-details">
          <div className="scrap-card__user-names-wrapper">
            {userDisplayName && (
              <span className="scrap-card__user-full-name">
                {userDisplayName} <Trust isTrusted={scrap.is_trusted} userId={scrap.user_id} view="icon" />
              </span>
            )}

            <div className="scrap-card__time">
              {created_at && (
                <span
                  className="scrap-card__created-at"
                  title={showFullDate(created_at, user.timezone)}
                >
                  <span className="scrap-card__dot">• </span>{formatTimestampDate(created_at, user.timezone)}
                </span>
              )}
            </div>
          </div>

          {headline && <span className="scrap-card__headline">{headline}</span>}

          <span className="scrap-card__user-name">{userName}</span>
        </div>
      </div>

      {/* @ts-ignore */}
      <MyDropDown<dropdownTypeMenu>
        dropDownList={dropdownList}
        // onOptionClick={dropdownOptionClickHandler}
        onDropDownClosed={onDropdownClosed}
        customButton={{
          customRender: (showDropDownListHandler) => {
            if (dropdownList.length === 0) return null;

            return (
              <button
                onMouseDown={(e) => e.stopPropagation()}
                onClick={(e) => {
                  e.stopPropagation();
                  showDropDownListHandler();
                  onDropdownOpened && onDropdownOpened();
                }}
                className="scrap-card__footer-button scrap-card__footer-triple-dot-button"
              >
                <TripleDotIcon />
              </button>
            );
          },
        }}
      >
        {(item, onClicked) => {
          return (
            <div
              key={item.valueField}
              className={`scrap-card__footer-dropdown-item${
                item.className ? ' ' + item.className : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                // item.valueField === 'share' && handleCopy();
                dropdownOptionClickHandler(item);
                onClicked && onClicked();
              }}
            >
              <span
                className={`scrap-card__footer-dropdown-item-icon${
                  item.isIconStroked
                    ? ' scrap-card__footer-dropdown-item-icon--stroked'
                    : ''
                }`}
              >
                {item?.icon}
              </span>
              <span className="scrap-card__footer-dropdown-item-label">
                {item?.textField}
              </span>
            </div>
          );
        }}
      </MyDropDown>

      {/* Ability to add quick note */}
      <NewPopup
        size="Small"
        header={{
          heading: 'Add Notes',
        }}
        footer={{
          submitLabel: 'Save Notes',
          onSubmit: async () => {
            await saveNote();
            setShowNotesPopup(false);
            showModal.resetModal();
          },
          spinnerConfig: {
            show: saving,
            label: 'Saving Notes',
          },
        }}
        controlled={{
          show: showNotesPopup,
          setShow: (val) => {
            setShowNotesPopup(val);
            if (!val) showModal.resetModal();
          },
        }}
      >
        <ScrapAddNotesGroup
          title={titleContent}
          onTitleChange={(title) => setTitleContent(title)}
          description={scrap.desc}
          onDescriptionChange={(note) => setNotesContent(note)}
          isDefaultOpened
        />
      </NewPopup>

      {showAISummary && (
        <ScrapCardAISummary
          url={scrap.url}
          userId={scrap.user_id}
          showAISummary={showAISummary}
          setShowAISummary={setShowAISummary}
        />
      )}

      {/* Quick add Collections */}
      {showCollectionsPopup && (
        <ScrapEditTags
          controlled={{
            show: showCollectionsPopup,
            setShow: (val) => {
              if (!val) showModal.resetModal();
              setShowCollectionsPopup(val);
            },
          }}
          dataType="collectionType"
          data={collectionData}
          setData={(newData) => setData(newData)}
          onSave={() => savedTags('collectionType')}
          showEdit
        />
      )}

      {/* Quick add Tags */}
      {showTagsPopup && (
        <ScrapEditTags
          controlled={{
            show: showTagsPopup,
            setShow: (val) => {
              if (!val) showModal.resetModal();
              setShowTagsPopup(val);
            },
          }}
          dataType="tagType"
          data={tagData}
          setData={(newData) => setData(newData)}
          onSave={() => savedTags('tagType')}
          showEdit
        />
      )}
    </div>
  );
};

export default ScrapCardHeader;
