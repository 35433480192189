import React from 'react';

import './styles.css';

const RenderConvertMessage = () => {
  return (
    <>
      <p className="convert-message__text">
        Would you like to convert this Share to a Collaboration?
      </p>
      <p className="convert-message__text">
        Doing so will allow you to: add an image, add a title, add a
        description, publish your collection, and manage collaborators.
      </p>
    </>
  );
};

export default RenderConvertMessage;
