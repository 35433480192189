import React, { ReactChild, useContext, useEffect, useState } from 'react';
// import { format } from 'date-fns';
import { connect, useDispatch, useSelector } from 'react-redux';
import './ItemMeta.css';
import { userTypes } from '../../types/user';
import { entryTypes } from '../../types/feed';
import { storyView } from '../../types/stories';
import { CardViewType } from '../Cards/types/cardView';
import { returnScrapSlug } from '../../helpers';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';
import CardSettings, {
  cardSettingsConfigType,
} from '../Cards/CardComponents/CardSettings';
import { shareSettingsOption } from '../../redux/action/shareSettingsAction';
import { ReduxStateType } from '../../redux/store';
import { returnShareLink } from '../../helpers/returnShareLinks';
import { useMutation } from '@apollo/client';
import { GENERATE_NEW_LINK, EDIT_PANEL } from '../data/updateDetails';
import ShareSettings from '../Details/Components/ShareSettings';
import { generateUrl } from '../Details/Components/ShareSettings/generateLink';
import EntryActionContext from '../../helpers/contexts/entryActionContext';
import ScrapShare from '../ScrapDetails/components/ScrapShare';
import { Minus, NewGlobeIcon, PencilEditIcon, PrivateIcon, TrashOutlineIcon } from '../Global/icons';
import { REMOVE_COLLECTION_TO_PANEL } from '../../containers/data/collection';
import { setWarningPopup } from '../../redux/action/utils';

interface propTypes {
  onEditClick?: (val?:boolean) => void;
  onDeleteClick?: () => void;
  someNumber?: string;
  children?: ReactChild;
  user: userTypes;
  entry: entryTypes;
  activeStoryView?: storyView;
  source?: string;
  addPanelDirectly?: (pid: string) => Promise<void>;
  view?: CardViewType;
  hideModifiedDate?: boolean;
  settingsConfig?: cardSettingsConfigType;
  collectionId?:string;
  reloadCollection?: () => void;
  iAmCollaborator?:boolean;
  collectionOwner?:string;
}

const ItemMeta = ({
  onEditClick,
  onDeleteClick,
  settingsConfig,
  children,
  user,
  entry,
  source,
  addPanelDirectly,
  view,
  hideModifiedDate,
  collectionId,
  reloadCollection,
  iAmCollaborator,
  collectionOwner
}: propTypes) => {  
  const [editVisibility, { loading: editing }] = useMutation(
    EDIT_PANEL({ type: entry.type })
  );

  const [generateNewLink, { loading: generating }] = useMutation(
    GENERATE_NEW_LINK()
  );
  const [saveScrap, { loading: savingScrap }] = useMutation(
    EDIT_PANEL({ type: entry.type })
  );

  const scrapOwner = useSelector(
    (state: ReduxStateType) => state.shareSettingsOption
  );
  const userData = useSelector(
    (state: { user: userTypes }) => state.user as userTypes
  );
  const [updatedScrap, setUpdatedScrap] = useState<Partial<entryTypes>>(null);
  const { setEntryAction } = useContext(EntryActionContext);
  const [showVisibility, setShowVisibility] = useState(false);
  const [scrapData, setScrapData] = useState(entry);
  const [shareUrl, setShareUrl] = useState('');
  const [removeCollectionToPanel] = useMutation(REMOVE_COLLECTION_TO_PANEL);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      shareSettingsOption({
        settings: null,
        scrap: null,
        pointer: null,
        show: false,
      })
    );
  }, []);

  useEffect(() => {
    if (entry) {
      setShareUrl(
        returnShareLink(
          'scrap',
          returnScrapSlug(entry),
          entry.userName,
          false,
          entry.state,
          entry.private_key
        )
      );
    }
  }, []);

  // Use effect for updating state of scrap in global containers like MyScrapsContainers, CollectionItem, GlobalSearchWrappers
  // This will be fired when this component unmounts and scrap share state is updated with api
  useEffect(() => {
    return () => {
      if (updatedScrap) {
        setEntryAction({
          type: 'edit',
          entry: updatedScrap,
        });
      }
    };
  }, [updatedScrap]);
  
 
  const handleVisibilitySave = async (state: number) => {
    if (!scrapData.id) return;
    try {
      const { data } = await saveScrap({
        variables: { state, id: `${scrapData.id}` },
      });
      let resScrap: entryTypes = data[Object.keys(data)[0]];

      setScrapData((scrap) => ({
        ...scrap,
        state: state,
        private_key: state === 2 ? resScrap?.private_key : null,
      }));
      setUpdatedScrap({
        id: scrapData.id,
        pid: scrapData.pid,
        uid: scrapData.uid,
        type: scrapData.type,
        state: state,
        private_key: state === 2 ? resScrap?.private_key : null,
      });
      resScrap = { ...entry, ...resScrap, state: 2 };
      const link = returnShareLink(
        'scrap',
        returnScrapSlug(resScrap),
        resScrap.userName,
        false,
        resScrap.state,
        resScrap.private_key
      );
      setShareUrl(link)
      return link;
    } catch (error) {
      alert(parseTryCatchError(error));
    }
  };

  const visibilityChangeHandler = async ({ state }: { state?: number }) => {
    return handleVisibilitySave(state);
  };

  function getFooterLabel() {
    if (entry.state === 3) {
      return 'Public';
    } else if (entry.state === 1) {
      return 'Private';
    } else return 'Shareable';
  }

  // if (view === 'detailShare') return null;

  if (view === 'detailSlideOut') return null;

  const visibilityHandler = async (data) => {
    try {
      const res = await editVisibility({
        variables: {
          id: scrapData.id,
          state: data.state,
        },
      });
      setScrapData((old) => ({
        ...old,
        state: data.state,
        private_key: res?.data[Object.keys(res?.data)[0]]?.private_key,
      }));
    } catch (error) {
      alert('Some went wrong!');
    }
  };

  const removeScrapWarning=async(collectionId,cardId)=>{
    const response = await removeCollectionToPanel({
      variables: {
        collection_id: +collectionId,
        pid:cardId
      },
    });
    response.data?.removeCollectionToPanel?.http_code ===200 ? reloadCollection() :  alert(response.data.removeCollectionToPanel.messages.error);
  }
  
  const removeScrap=(collectionId,cardId)=>{
    dispatch(
      setWarningPopup({
        warningMessage:"Are you sure you want to remove this Scrap from this Collection?",
        header:"Warning",
        leftLoader:true,
        cancel:{
          label:"Cancel",
        },
        submit:{
          label:"OK",
          cb:()=>{
            removeScrapWarning(collectionId,cardId);
          }
        }
      })
    ) 
    
   
  }
 
  return (
    <>
      <div className="meta">
        <div className={`meta__wrapper${hideModifiedDate ? ' no-border' : ''}`}>
          
          <div className="meta__left">
            {onDeleteClick && (
              <button
                className="meta__edit-button meta__edit-button--trash"
                title="Delete Scrap"
                onClick={onDeleteClick}
              >
                <TrashOutlineIcon />
              </button>
            )}

            {(view==='collectionEdit' && (collectionOwner === user.userName ||( iAmCollaborator && entry.owner.username === user.userName))) &&  <div className="collection-card__footer-visibility" title='Remove Scrap'> <Minus onClick={()=>removeScrap(collectionId,entry.pid)}/> </div>}

            {view === 'detailShare' &&  <div className="collection-card__footer-visibility">
              <span className={`collection-card__footer-visibility-icon`}>
                {entry.state === 3 ? (
                  <NewGlobeIcon width="20" height="20" />
                ) : (
                  <PrivateIcon  onClick={()=>{entry.userName===userData.userName&&onEditClick(true)}}/>
                )}
              </span>
              <span className="collection-card__footer-visibility-text">
                {getFooterLabel()}
              </span>
            </div>}
          </div>
          

          <div className="meta__right">
            {/* Do not remove below code. It migh be used later for comments */}
            {/* <CommentIcon className="meta__comment-icon" /> */}
            {onEditClick && (entry.userName===userData.userName) &&(
              <button
                className="meta__edit-button meta__edit-button--pencil"
                title="Edit Scrap"
                onClick={()=>onEditClick()}
              >
                <PencilEditIcon/>
              </button>
            )}
           
            {(scrapData.state!==1 && scrapData.state!==4) ?  <ScrapShare
              handleEdit={visibilityChangeHandler}
              shareUrl={shareUrl}
              status={scrapData.state}
              type="Scrap"
              noUrlMessage="Share link will be generated after saving the Scrap."
              loading={savingScrap || generating}
              view={view}
              cardOwner={entry.userName}
            /> : <div onClick={()=>{entry.userName===userData.userName&&onEditClick(true)}} className='share-settings'>
              <span className='action-item false'><PrivateIcon  className={`${entry.userName !== userData.userName ? "meta__right--disable-hover":''}`}/></span>
            </div> }
            
            {/* REMOVED REDUNDANT SHARESETTING COMPONENT FOR NOW. MIGHT BE USED IN FUTURE */}
            {/* <ShareSettings
              shareUrl={returnShareLink(
                'scrap',
                returnScrapSlug(scrapData),
                scrapData.userName,
                false,
                scrapData.state,
                scrapData.private_key
              )}
              scrap={scrapData}
              type="Scrap"
              loading={editing || generating}
              title={scrapData.title}
              handleEdit={async (data, cb) => {
                visibilityHandler(data);
              }}
              status={scrapData.state || 1}
              modalConfig={{
                show: showVisibility,
                setShow: setShowVisibility,
                onPopupClose: () => {
                  setEntryAction({ type: 'edit', entry: scrapData });
                },
              }}
              onGenerateLink={async () =>
                generateUrl(
                  generateNewLink,
                  scrapData,
                  scrapData.state,
                  setScrapData
                )
              }
            /> */}
          </div>
        </div>
      </div>
      <CardSettings scrapOwner={scrapOwner} />
    </>
  );
};

const mapStateToProps = (state: { user: userTypes }) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ItemMeta);
