import React from 'react';

import './styles.css';
interface IconRenderConfig {
  type: 'icon';
  // eslint-disable-next-line no-undef
  render: (elementProps: Object) => JSX.Element;
}
interface TextRenderConfig {
  type: 'text';
  data: string;
}
interface Proptypes {
  hideIfZeroCount?: boolean;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (item?: any) => void;
  isMultiselect?: boolean;
  active?: boolean;
  renderConfig?: IconRenderConfig | TextRenderConfig;
  type?: string;
  untag?: boolean;
  id?: string;
  count?:number;
  avatar?: string;
}

const SideNavDropdownListItem = ({
  hideIfZeroCount,
  label,
  onClick,
  isMultiselect,
  active,
  renderConfig,
  type,
  untag,
  id,
  count,
  avatar,
}: Proptypes) => {
  let isUntagActive = untag && id === 'null';
  if (
    hideIfZeroCount &&
    renderConfig.type === 'text' &&
    Number(renderConfig?.data) === 0
  )
    return null;
  return (
    <li
      onClick={onClick}
      className={`side-nav-dropdown__list-item${
        active || isUntagActive ? ' side-nav-dropdown__list-item--active' : ''
      }${!isMultiselect ? ' single-list-item' : ''} ${
        !isMultiselect ? ' single-list-item-collection' : ''
      } `}
    >
      <div className={'side-nav-dropdown__list-item-label-container'}>
        {isMultiselect && (
          <span
            className={`side-nav-dropdown__checkbox${
              active || isUntagActive
                ? ' side-nav-dropdown__checkbox--active'
                : ''
            }`}
          >
            {(active || isUntagActive) && (
              <img
                src="/dashboard/svg-icons/vector-stroke.svg"
                alt="checkbox"
              />
            )}
          </span>
        )}
        <p
          className={`side-nav-dropdown__list-item-label${
            active || isUntagActive
              ? ' side-nav-dropdown__list-item-label--active'
              : ''
          }`}
        >
          {(type === 'Tag' || type === 'tagType') && '#'}
          {label}
        </p>

        {avatar && (<img className="side-nav-dropdown__avatar" src={avatar} />)}
        {count && <span className={`side-nav-dropdown__list-item-label--count`}>{count}</span>}
      </div>
      {renderConfig && (
        <span
          className={`side-nav-dropdown__meta ${
            !isMultiselect ? ' single-list__icon' : ''
          }`}
        >
          {renderConfig.type === 'icon' ? (
            renderConfig.render({ className: 'side-nav-dropdown__meta-icon' })
          ) : (
            <span
              className={`side-nav-dropdown__meta-text${
                active || isUntagActive
                  ? ' side-nav-dropdown__meta-text--active'
                  : ''
              }`}
            >
              {renderConfig.data}
            </span>
          )}
        </span>
      )}
    </li>
  );
};

export default SideNavDropdownListItem;
