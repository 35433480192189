import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['child_collection_ids', '[ID]'],
  ['scrap_ids', '[ID]'],
]);
export default function MATCHED_BOOKMARKS(responseString = '') {
  return gql`
    query MatchedBookmarks(${parametersTypeString}) {
      matchedBookmarks(${parametersAssignString}) {
        ${defaultResponse}
        data {
            child_collection_id
            scrap_id
        }
      }
    }
  `;
}
