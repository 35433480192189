import React, { useState, createContext } from 'react';
import { entryActionType } from '../../types/feed';

interface EntryActionContextType {
  entryAction: entryActionType;
  setEntryAction: (val: entryActionType) => void;
}

const EntryActionContext = createContext<EntryActionContextType>({
  entryAction: null,
  setEntryAction: function (val) {},
});

export function EntryActionContextProvider(props) {
  const [entryAction, setEntryAction] = useState<entryActionType>(null);
  const context = { entryAction, setEntryAction };
  return (
    <EntryActionContext.Provider value={context}>
      {props.children}
    </EntryActionContext.Provider>
  );
}

export default EntryActionContext;
