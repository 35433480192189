import React, { useContext, useRef, useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { parseNote } from '../../../helpers';
import { scrapTitle } from '../cardHelpers';
import { entryTypes } from '../../../types/feed';
import Tiptap from '../../Global/Tiptap';
import EntryActionContext from '../../../helpers/contexts/entryActionContext';
import { scrapType } from '../../../types/scrapType';
interface Proptypes {
  hideLess?: boolean;
  type: 'title' | 'notes' | 'comment';
  fullContent?: boolean;
  preview?: boolean;
  showFull?: boolean;
  entry: entryTypes | scrapType;
  notesProperty?: string;
  onMoreClick?: () => boolean;
}

const RenderTitleAndNotes = (props: Proptypes) => {
  const {
    onMoreClick,
    type,
    preview,
    showFull,
    entry,
    notesProperty,
    fullContent,
    hideLess,
  } = props;
  const { setEntryAction } = useContext(EntryActionContext);
  const [readMoreStatus, setReadMoreStatus] = useState<
    'truncated' | 'expanded' | 'unavailable'
  >(entry?.isUgcExpanded && hideLess ? 'expanded' : 'unavailable');
  const notesRef = useRef<HTMLDivElement>();

  const handleText = () => {
    const notes = parseNote(entry[notesProperty]).replace(
      /<[div]+>\s*<\/[div]+>/gim,
      '<br>'
    );
    return notes;
  };
  return !preview ? null : (
    <>
      {type === 'title' ? (
        <h3 className="card__page-title">{scrapTitle(entry, showFull)}</h3>
      ) : (
        <>
          {entry[notesProperty] && (
            <div className="card__content">
              {showFull ? (
                <Tiptap text={parseNote(entry[notesProperty])} edit={false} />
              ) : (
                <div className="card__description">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      // @ts-ignore
                      if (!notesRef?.current?.clamped) return;
                      if (onMoreClick) {
                        const canContinue = onMoreClick();
                        if (!canContinue) return;
                      } else {
                        setEntryAction({
                          type: 'edit',
                          entry: {
                            // pid: entry.pid,
                            // uid: entry.uid,
                            id: String(entry.id),
                            // type: entry.type,
                            isUgcExpanded: readMoreStatus !== 'expanded',
                          },
                        });
                        setReadMoreStatus((old) => {
                          if (old === 'truncated') return 'expanded';
                          else if (old === 'expanded') return 'truncated';
                          return 'unavailable';
                        });
                      }
                    }}
                    className={`card__description-content card__description-content--${
                      fullContent ? 'full-content' : readMoreStatus
                    }`}
                  >
                    <HTMLEllipsis
                      ref={notesRef}
                      unsafeHTML={handleText()}
                      maxLine={
                        fullContent || readMoreStatus === 'expanded'
                          ? Infinity
                          : 4
                      }
                      basedOn="words"
                      ellipsisHTML={`<button>... more</button>`}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(RenderTitleAndNotes);
