import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const Collaborator = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M14 13V15H0V13C0 13 0 9.00002 7 9.00002C14 9.00002 14 13 14 13ZM10.5 3.50004C10.5 2.80781 10.2947 2.13112 9.91014 1.55555C9.52556 0.979981 8.97893 0.531379 8.33939 0.266473C7.69985 0.00156766 6.99612 -0.0677438 6.31718 0.067304C5.63825 0.202352 5.01461 0.535693 4.52513 1.02518C4.03564 1.51466 3.7023 2.1383 3.56725 2.81723C3.4322 3.49616 3.50152 4.19989 3.76642 4.83943C4.03133 5.47897 4.47993 6.02559 5.0555 6.41017C5.63108 6.79476 6.30777 7.00003 7 7.00003C7.92826 7.00003 8.8185 6.63128 9.47487 5.97491C10.1313 5.31853 10.5 4.42829 10.5 3.50004ZM13.94 9.00002C14.5547 9.47577 15.0578 10.0805 15.4137 10.7715C15.7696 11.4626 15.9697 12.2233 16 13V15H20V13C20 13 20 9.37002 13.94 9.00002ZM13 5.25788e-05C12.3117 -0.00378012 11.6385 0.202008 11.07 0.590051C11.6774 1.43878 12.0041 2.45633 12.0041 3.50004C12.0041 4.54375 11.6774 5.5613 11.07 6.41003C11.6385 6.79807 12.3117 7.00386 13 7.00003C13.9283 7.00003 14.8185 6.63128 15.4749 5.97491C16.1313 5.31853 16.5 4.42829 16.5 3.50004C16.5 2.57179 16.1313 1.68155 15.4749 1.02518C14.8185 0.3688 13.9283 5.25788e-05 13 5.25788e-05Z"
        fill="#969696"
      />
    </svg>
  );
};

export default Collaborator;
