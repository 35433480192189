export function matchPassword(p1: string, p2: string, errorMessage?: string) {
  if (p1 !== p2) return errorMessage || 'Not matching';
}

export function minimumCharacters(
  val: string,
  minLimit: number,
  errorMessage?: string,
  trim?: boolean
) {
  let value = trim ? val.trim() : val;
  if (value.length < minLimit)
    return errorMessage || `Must atleast have ${minLimit} characters`;
}
export function maximumCharacters(
  val: string,
  maxLimit: number,
  errorMessage?: string,
  trim?: boolean
) {
  let value = trim ? val.trim() : val;
  if (value.length > maxLimit)
    return errorMessage || `Max limit is ${maxLimit} characters`;
}

export function checkEmpty(val: string, errorMessage?: string, trim?: boolean) {
  const value = trim ? val.trim() : val;
  if (!value) return errorMessage || 'Must not be empty';
}

export function validate(...functions: Array<() => string | null>) {
  let errorMessage = null;
  for (let i = 0; i < functions.length; i++) {
    errorMessage = functions[i]();

    if (errorMessage) break;
  }
  return errorMessage;
}

//VALIDATE PASSWORD WITH REGEX
export function validatePassword(pass: string, regexType: string = null) {
  /*
      Contain minimum 8 characters
      Contain at least one mixes-case letter
      Contain at least one number
   */
  var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  switch (regexType) {
    case 'atleast_one_number':
      passw = /(?=.*?[0-9])/;
      break;
    case 'minimum_8_character_length':
      passw = /.{8,}/;
      break;
    case 'atleast_one_mix_letter':
      passw = /(?=.*[a-z])(?=.*[A-Z]).*/;
      break;

    default:
      passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      break;
  }

  if (pass.match(passw)) {
    return true;
  }
  return false;
}
