import React from 'react';
import { Helmet } from 'react-helmet';
import parseStringFromHtml from '../../helpers/parseStringFromHtml';
import { parseNote } from "../../helpers";

interface PropsMeta {
  title: string;
  description?: string;
  image?: string;
}

const SEOMeta = ({
  title,
  description,
  image
}: PropsMeta) => {
  let parsedDescription = parseNote(description);
  parsedDescription = parsedDescription.replace(/(<([^>]+)>)/gi, '');

  let scrapTitle = title;
  if (!title || title.trim() === '') {
    scrapTitle = 'Scrappi';
  }

  return (
    <>
      <Helmet>
        <title>{scrapTitle}</title>
        <meta name="title" content={scrapTitle} />
        <meta name="description" content={parsedDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={scrapTitle} />
        <meta property="og:description" content={parsedDescription} />
        <meta property="og:image" content={image} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={scrapTitle} />
        <meta property="twitter:description" content={parsedDescription} />
        <meta property="twitter:image" content={image} />
      </Helmet>
    </>
  )
}

export default SEOMeta;
