import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../Icon';
import EmojiPicker, { EmojiStyle, Theme } from 'emoji-picker-react';
import { ReduxStateType } from '../../../redux/store';
import {
  OrderList,
  UnOrderList,
  FormatClear,
  StrikeThrough,
  HighlightIcon,
  LinkIcon,
  NewLinkIcon,
} from '../icons/index';

const TiptapMenuBar = (props) => {
  const theme = useSelector((state: ReduxStateType) => state.theme);
  const platform = useSelector((state: ReduxStateType) => state.utils.platform);
  const { editor, toolsToHide, focusEditor, visible } = props;
  const themeConfig = {
    0: Theme.DARK,
    1: Theme.LIGHT,
  };

  const [forcedVisible, setForcedVisible] = useState(false);

  const [emoji, setEmoji] = useState(false);

  const isValidUrl = (value: string) => {
    try {
      const url = new URL(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    let isUrlValid = isValidUrl(previousUrl);
    let url = '';
    do {
      url = window.prompt('URL', previousUrl);
      // cancelled
      if (url === null) {
        break;
      }
      isUrlValid = isValidUrl(url);
    } while (!isUrlValid);

    if (url === null) {
      return;
    }
    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  const itemAction = (menuItem) => {
    if (editor != null) {
      switch (menuItem) {
        case 'type-bold':
          editor.chain().focus().toggleBold().focus().run();
          break;
        case 'type-italic':
          editor.chain().focus().toggleItalic().focus().run();
          break;
        case 'underline':
          editor.chain().focus().toggleUnderline().focus().run();
          break;
        case 'list-ordered':
          editor.chain().focus().toggleOrderedList().focus().run();
          break;
        case 'list-bullet':
          editor.chain().focus().toggleBulletList().focus().run();
          break;
        case 'format-clear':
          editor.chain().focus().clearNodes().unsetAllMarks().focus().run();
          break;
        case 'strike':
          editor.commands.toggleStrike().focus().run();
          break;
        case 'highlight-text':
          editor.chain().focus().toggleHighlight().focus().run();
          break;
        case 'Link':
          setLink();
          break;
        case 'Emoji Picker':
          setForcedVisible(true);
          setEmoji(!emoji);
          break;
        default:
          break;
      }
      if (menuItem !== 'Emoji Picker') setEmoji(false);
    }
  };

  const isItemActive = (menuItem) => {
    if (editor != null) {
      switch (menuItem) {
        case 'type-bold':
          return editor.isActive('bold');
        case 'type-italic':
          return editor.isActive('italic');
        case 'underline':
          return editor.isActive('underline');
        case 'list-ordered':
          return editor.isActive('orderedList');
        case 'list-bullet':
          return editor.isActive('bulletList');
        case 'strike':
          return editor.isActive('strike');
        case 'highlight-text':
          return editor.isActive('highlight');
        case 'Link':
          return editor.isActive('link');
        case 'format-clear':
          return false;
        case 'Emoji Picker':
          return false;
        default:
          if (!focusEditor) {
            return true;
          }
          editor.chain().focus();
          return false;
      }
    }
    return false;
  };
  const tools = {
    bold: [
      {
        icon: 'type-bold',
        title: 'Bold',
        keyword: 'type-bold',
      },
    ],
    italic: [
      {
        icon: 'type-italic',
        title: 'Italic',
        keyword: 'type-italic',
      },
    ],
    underline: [
      {
        icon: 'type-underline',
        title: 'Underline',
        keyword: 'underline',
      },
    ],
    strike: [
      {
        icon: 'strike-through',
        title: 'Strike Through',
        keyword: 'strike',
      },
    ],
    list: [
      {
        icon: 'list-ol',
        title: 'Ordered List',
        keyword: 'list-ordered',
      },
      {
        icon: 'list-ul',
        title: 'Bullet List',
        keyword: 'list-bullet',
      },
    ],
    'clear-format': [
      {
        icon: 'format-clear',
        title: 'Clear Format',
        keyword: 'format-clear',
      },
    ],
    'highlight-text': [
      {
        icon: 'highlight-text',
        title: 'Highlight Text',
        keyword: 'highlight-text',
      },
    ],
    link: [
      {
        icon: 'Link',
        title: 'Link',
        keyword: 'Link',
      },
    ],
    'emoji-picker': [
      {
        icon: 'emoji-picker',
        title: 'Emoji Picker',
        keyword: 'Emoji Picker',
      },
    ],
  };
  let toolKeys = Object.keys(tools);
  if (Array.isArray(toolsToHide) && toolsToHide.length > 0) {
    toolKeys = toolKeys.filter((tool) => !toolsToHide.includes(tool));
  }
  const items = [];
  toolKeys.forEach((toolKey) => {
    items.push(...tools[toolKey]);
  });

  const onEmojiClick = (emojiData, event) => {
    editor
      .chain()
      .focus()
      .command(({ tr }) => {
        // manipulate the transaction
        tr.insertText(emojiData.emoji);

        return true;
      })
      .run();
    setEmoji(false);
    setForcedVisible(false);
  };

  useEffect(() => {
    if(!visible && !forcedVisible) setEmoji(false);
  }, [visible, forcedVisible]);

  return (
    <div
      className={`tiptap-menu-bar${visible ? ' tiptap-menu-bar--visible' : ''}${
        forcedVisible ? ' tiptap-menu-bar--forced' : ''
      }`}
    >
      {items.map((menuItem) => (
        <button
          className={`menu-item ${
            isItemActive(menuItem.keyword) ? 'active' : ''

          } menu-item--${platform}`}
          key={menuItem.keyword}
          tabIndex={-1}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setForcedVisible(true);
          }}
          onMouseUp={(e) => {  
            e.preventDefault();
            e.stopPropagation();
            forcedVisible && setForcedVisible(false);
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            itemAction(menuItem.keyword);
          }}
          title={menuItem.title}
        >
          {menuItem.icon === 'format-clear' ? (
            <FormatClear className="menu-item--svg-icon" />
          ) : menuItem.icon === 'emoji-picker' ? (
            <svg
              className="svg-icon"
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 128"
              style={{
                width: '1em',
                // height: '.9em',
                // verticalAlign: 'middle',
                overflow: 'hidden',
              }}
            >
              <title>EMOJI</title>
              <path d="M35.56,49.964a1.75,1.75,0,0,0,1.75-1.75,6.477,6.477,0,1,1,12.954,0,1.75,1.75,0,0,0,3.5,0,9.977,9.977,0,1,0-19.954,0A1.75,1.75,0,0,0,35.56,49.964Z" />
              <path d="M75.986,49.964a1.75,1.75,0,0,0,1.75-1.75,6.477,6.477,0,1,1,12.954,0,1.75,1.75,0,0,0,3.5,0,9.977,9.977,0,1,0-19.954,0A1.75,1.75,0,0,0,75.986,49.964Z" />
              <path d="M64,4.75a59.863,59.863,0,1,0,59.863,59.863A59.931,59.931,0,0,0,64,4.75Zm0,116.227a56.363,56.363,0,1,1,56.363-56.363A56.427,56.427,0,0,1,64,120.977Z" />
              <path d="M113.044,74.3a11.817,11.817,0,0,0-5.283-3.131c-.015,0-.027-.011-.042-.015l-.026,0a14.489,14.489,0,0,0-6.057-.516,1.75,1.75,0,1,0,.573,3.453,10.032,10.032,0,0,1,2.908.063,42.827,42.827,0,0,1-41.107,30.6h-.14a42.827,42.827,0,0,1-41.1-30.582,10.161,10.161,0,0,1,3.023-.081,1.75,1.75,0,1,0,.573-3.453,14.443,14.443,0,0,0-5.92.477,1.531,1.531,0,0,0-.717.225A11.576,11.576,0,0,0,14.956,74.3a1.75,1.75,0,1,0,2.558,2.389,7.271,7.271,0,0,1,1.924-1.441,46.324,46.324,0,0,0,44.432,33h.14a46.325,46.325,0,0,0,44.45-33.059,7.338,7.338,0,0,1,2.026,1.5,1.75,1.75,0,0,0,2.558-2.389Z" />
            </svg>
          ) : menuItem.icon === 'list-ol' ? (
            <OrderList className="menu-item--svg-icon" />
          ) : menuItem.icon === 'list-ul' ? (
            <UnOrderList className="menu-item--svg-icon" />
          ) : menuItem.icon === 'highlight-text' ? (
            <HighlightIcon className="menu-item--svg-icon" />
          ) : menuItem.icon === 'strike-through' ? (
            <StrikeThrough className="menu-item--svg-icon" />
          ) : menuItem.icon === 'Link' ? (
            <NewLinkIcon className="menu-item--svg-icon" />
          ) : (
            <Icon iconClass={menuItem.icon} size="normal" />
          )}
        </button>
      ))}
      {emoji && (
        <div className="tiptap__emoji-picker">
          <EmojiPicker
            theme={themeConfig[theme?.theme] || Theme.AUTO}
            previewConfig={{
              showPreview: false,
            }}
            
            onEmojiClick={onEmojiClick}
            height={300}
            lazyLoadEmojis
            skinTonesDisabled
            emojiStyle={EmojiStyle.NATIVE}
          />
        </div>
      )}
    </div>
  );
};

export default TiptapMenuBar;
