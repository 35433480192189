import { GroupType } from '../containers/GroupShareContainer/types';

export const makeGroupMembersMapper = (group: Partial<GroupType>) => {
  try {
    const mapper = {
      [group.owner_user_name]: true,
    };
    if (group.members?.length) {
      group.members.forEach((member) => {
        mapper[member.user_name] = true;
      });
    }
    return mapper;
  } catch (error) {
    return {};
  }
};

// Checks if one share contains all other contacts and the members of other shares
export const oneGroupContainsAllMembers = (data: Partial<GroupType>[]) => {
  try {
    if (data.length <= 1) return true;
    if (data.some((group) => group.type === 'collection')) {
      return false;
    }
    const sortedGroups = [...data].sort(
      (a, b) => (b?.members || []).length - (a?.members || []).length
    );
    const largestGroup = sortedGroups.splice(0, 1)[0];
    if (!largestGroup?.members?.length) {
      return false;
    }

    const largestGroupsMembersMapper = makeGroupMembersMapper(largestGroup);
    return sortedGroups.every((group) => {
      if (!largestGroupsMembersMapper[group.owner_user_name]) {
        return false;
      }
      if (!group?.members?.length) {
        return true;
      }
      return group.members.every(
        (member) => largestGroupsMembersMapper[member.user_name]
      );
    });
  } catch (error) {
    return false;
  }
};
