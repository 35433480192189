import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';
const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int!'],
  ['username', 'String'],
  ['private_key', 'String'],
]);
const SCRAP = gql`
    query Scrap(${parametersTypeString}) {
        scrap(${parametersAssignString}) {
            ${defaultResponse}
            data {
                id
                user_id
                user_name
                display_name
                avatar
                title
                desc
                status
                state
                private_key
                service
                meta
                meta_title
                meta_desc
                price
                created_at
                updated_at
                url
                domain
                favicon
                original_favicon
                annotations_count
                annotations {
                    comment
                    highlighted_text
                    id
                    scrap_id
                    hash
                }
                documents_count
                documents {
                    file_name
                    file_type
                    file_path
                    file_status
                }
                images_count
                images {
                    file_name
                    file_path
                    order
                    file_status
                    file_config
                    desc    
                }
                tags {
                    slug
                }
                collections {
                    id
                }
            }
        }
    }
`;

export default SCRAP;
