import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setScrapImagesPopup } from '../redux/action/utils';

import { useApolloClient, useQuery } from '@apollo/client';

import {
  SortableList,
  DragItem,
} from '../components/Gallery/components/AddedImages/helpers/draggables';
import NewPopup from '../components/Global/NewPopup';

import {
  ScrapImagesCropper,
  ScrapImagesList,
  ScrapImagesListItem,
} from '../components/ScrapImagesPopup';

import { arrayMove } from 'react-sortable-hoc';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import { RequestStatusObjectType } from '../types/requestStatusType';
import { convertHEICImage, parseNote, parseStringifiedJson } from '../helpers';

import { returnImageURL } from '../components/Gallery/helpers/returnImageURL';
import config from '../helpers/config';

import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../components/Global/InvisibleRecaptcha';
import ScrapImagesGrid, {
  ScrapImagesGridItem,
} from '../components/ScrapImagesPopup/ScrapImagesGrid';
import {
  LeftArrowIcon,
  PencilEditIcon,
  PlusIcon,
} from '../components/Global/icons';
import { ReduxStateType } from '../redux/store';
import { setOneScrapAction } from '../redux/action/onScrapActions';
import GetScrapImages from './data/getScrapImages';
import saveImagesHandler from './NewScrapPopupContainer/helpers/scrapSaveSubmitHandler';
import { ErrorMessage } from '../components/Global/Error/Index';
import ScrapImagesView from '../components/ScrapImagesPopup/ScrapImagesView';
export interface ScrapImageData {
  file?: File;
  description: string;
  imageViewUrl: string;
  key?: string;
  order?: number;
  filename: string;
  id?: string;
  mimeType: string;
  is_heic?: boolean;
}

const ScrapImagesPopupContainer = () => {
  
  const dispatch = useDispatch();
  const client = useApolloClient();
  const scrapImagesPopup = useSelector(
    (state: ReduxStateType) => state.utils.scrapImagesPopup
  );
  const {
    carouselData,
    defaultEdit,
    isGridMode,
    isReadOnly,
    onDone,
    scrap,
  } = scrapImagesPopup || {};

  const [show, setShow] = [
    !!scrapImagesPopup,
    (val: boolean) => dispatch(setScrapImagesPopup(null)),
  ];

  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const [isEdit, setIsEdit] = useState(defaultEdit);

  const [status, setStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [selectedImages, setSelectedImages] = useState<Array<ScrapImageData>>(
    []
  );
  const [imageCarousel, setImageCarousel] = useState<number>(-1);
  const [currentCroppingImage, setCurrentCroppingImage] = useState<
    ScrapImageData
  >(null);

  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  
  //Fetch all the images
  const { data, loading: imagesLoading } = useQuery(GetScrapImages(), {
    variables: {
      scrap_id: scrap?.id,
      first: scrap?.images_count,
      page: 1,
      private_key: scrap?.private_key,
      username: scrap?.user_name,
      skip_first: 0,
    },
    skip: !scrap?.id,
    fetchPolicy: 'network-only',
  });

  const toggleErrorMessage = (message: string) => {
    setStatus({
      status: 'error',
      message
    })
    setTimeout(() => {
      setStatus(old => {
        if(old.status !== 'processing') {
          return {
            status: 'not-started'
          }
        }
        return old;
      })
    },3000)
  }

  // Function handler to store the image files selected by user from file system
  async function uploadFileChangeHandler(files: FileList) {
    try {
      setStatus({
        status: 'processing',
        processingLabel: 'Processing'
      })
      const imagesArray: Partial<ScrapImageData>[] = Array.from(files).map(
        (file, i) => ({
          file,
          description: '',
          imageViewUrl: URL.createObjectURL(file),
          key: 'image-' + Math.ceil(Math.random() * 10000),
          mimeType: file.type,
          filename: file.name,
          order:
             (selectedImages.length ? +selectedImages[selectedImages.length - 1]?.order : 0) + 100 * (i + 1),
        })
      );
      const isAnyFileBig = imagesArray.some(
        (imageData) => imageData.file.size > config.image_size_limit
      );
      if (isAnyFileBig)
        throw new Error(
          `Size of each image should be less than ${
            config.image_size_limit / 1000000
          } MB!`
        );
      for(let i = 0; i <imagesArray.length; i++) {
        const imageData = imagesArray[i];
        if(imageData.file.type === 'image/heic') {
          const newFile = await convertHEICImage(imageData.file);
          if(!newFile) throw new Error('Failed to convert');
          imageData.file = newFile;
          imageData.imageViewUrl = URL.createObjectURL(newFile);
          imageData.mimeType = newFile.type;
          imageData.filename = newFile.name;
          imageData.is_heic = true;
        }
      }  
      let trimmedFiles = [...selectedImages, ...imagesArray];
      if (trimmedFiles?.length > config.gallery_images_count_limit) {
        throw new Error(`Images limit exceeded, only ${config.gallery_images_count_limit} images allowed.`)
      }
        
      setSelectedImages(trimmedFiles as ScrapImageData[]);
      setStatus({
        status: 'not-started',
      })
    } catch (error) {
      setStatus({
        status: 'not-started',
      })
      throw new Error(parseTryCatchError(error));
    }
    
  }

  // Function handler to manage store the updated list when user drags and drops images vertically
  function sortEndHandler({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) {
    const newList = arrayMove(selectedImages, oldIndex, newIndex);
    const targetItem = newList[newIndex];
    const prevItem = newList[newIndex - 1];
    const nextItem = newList[newIndex + 1];
    const prevItemOrder = +prevItem?.order;
    const nextItemOrder = +nextItem?.order;
    if(!prevItem) { // Dragged to first position 
      targetItem.order = (0 + nextItemOrder) / 2
    } else if(!nextItem) { // Dragged to last position
      targetItem.order = prevItemOrder + 100
    } else { // Dragged in between
      targetItem.order = (prevItemOrder + nextItemOrder) / 2
    }
    setSelectedImages(newList);
  }

  // Function handler to store the description entered by user for each image
  function imageDescriptionChangeHandler(key: string, text: string) {
    setSelectedImages((old) => {
      const temp = [...old];
      const index = temp.findIndex((item) => item.key === key);
      temp[index].description = text;
      return temp;
    });
  }

  // Function handler which will be called to store the newly cropped image
  async function imageCropHandler(key: string, blob: Blob, isImageCropped: boolean) {
    const file = new File([blob], 'image-file', { type: blob.type });
    // const newImages = [...selectedImages]
    const newImages = [...selectedImages];
    const index = newImages.findIndex((item) => item.key === key);
    if(isImageCropped) {
      newImages[index].file = file;
      newImages[index].imageViewUrl = URL.createObjectURL(file);
    }    
    newImages[index].description = currentCroppingImage.description;
    if(carouselData?.index > -1 && scrap) {
      await saveImagesHandler({
        setStatus,
        imagesList: {
          value: newImages.map((item, i) => ({
            description: item.description,
            filename: item.filename,
            fileUrl: item.imageViewUrl,
            key: item.key,
            id: item.id,
            fileSize: item.file?.size,
            order: item.order, 
            mimeType: item.mimeType,
            is_heic: item.is_heic,
          })),
          gotChanged: true,
        },
        scrap:{
          ...scrap,
          images: data?.getScrapImages?.data,
        },
        client,
        captchaRef: reCaptchaRef.current,
        setReload: () => {
  
        },
        closeHandler: () => { 
          // setShow(false);
          setImageCarousel(carouselData.index)
          setIsEdit(false);
        },
        onSaved(scrap) {
          dispatch(
            setOneScrapAction({
              type: 'edit',
              scrap: {
                ...scrap,
                images: (scrap?.images || []).map(item => ({ ...item, desc: null })).sort((a, b) => a.order - b.order)
              },
            })
          );
        },  
      })
    } else {
      setSelectedImages(newImages);
      
    }
    setCurrentCroppingImage(null);
    
  }

  // Function handler to save the gallery scrap
  async function submitHandler(close: () => void) {
    
    

    /**
     * Steps followed here:
     ** Upload the images selected by user
     ** Hit api to create gallery after images get uploaded
     ** Redirect user to gallery details page if gallery is successfully created
     */
    try {
      if (showCloseWarning) {
        return setShow(false);
      }
      if (scrapImagesPopup.dontCreate && onDone) {
        onDone(
          JSON.stringify(
            selectedImages.map((item) => ({
              description: item.description,
              filename: item.filename,
              image: item.imageViewUrl,
              key: item.key,
              order: item.order,
              id: item.id,
              mimeType: item.mimeType,
              is_heic: item.is_heic,
            }))
          )
        );
        return setShow(false);
      }
      
      await saveImagesHandler({
        setStatus,
        imagesList: {
          value: selectedImages.map((item, i) => ({
            description: item.description,
            filename: item.filename,
            fileUrl: item.imageViewUrl,
            key: item.key,
            id: item.id,
            fileSize: item.file?.size,
            order: item.order, 
            mimeType: item.mimeType,
            is_heic: item.is_heic,
          })),
          gotChanged: true,
        },
        scrap:{
          ...scrap,
          images: data?.getScrapImages?.data,
        },
        client,
        captchaRef: reCaptchaRef.current,
        setReload: () => {
  
        },
        closeHandler: () => { 
          setShow(false);
        },
        onSaved(scrap) {
          dispatch(
            setOneScrapAction({
              type: 'edit',
              scrap: {
                ...scrap,
                images: (scrap?.images || []).map(item => ({ ...item, desc: null })).sort((a, b) => a.order - b.order)
              },
            })
          );
        },  
      })
    } catch (error) {
      alert('Please choose a supported file type and try again.');
      setStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  }
  // Function handler which will be called when user clicks cancel from the popup
  function cancelClickHandler(close: () => void) {
    if (currentCroppingImage) {
      // It means that user is on image edit mode to crop, rotate etc
      // So pressing cancel should give the user ability to go back to images list view popup
      // which will be achieved by setting currentCroppingImage to null
      setCurrentCroppingImage(null);
    } else if (showCloseWarning) {
      setShowCloseWarning(false);
    } else if (scrap && isEdit) {
      resetImagesHandler(true);
      setIsEdit(false);
    } else {
      // else close the popup
      setShow(false);
    }
  }
  // Function handler to delete image from the list
  function deleteImageHandler(key: string) {
    if(selectedImages.length === 1 && scrapImagesPopup.dontCreate) {
      onDone(JSON.stringify([]));
      return setShow(false);
    } else {
      setSelectedImages((old) => {
        const temp = [...old];
        const index = temp.findIndex((item) => item.key === key);
        temp.splice(index, 1);
        return temp;
      });
      setCurrentCroppingImage(null);
    }
    
  }

  const inputRef = useRef<HTMLInputElement>(null);
  async function fileChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      let allFiles = (e.target as HTMLInputElement).files;
      e.persist();
      if (allFiles.length) {
        await uploadFileChangeHandler(allFiles);
      } else {
        throw new Error('No files selected');
      }
    } catch (error) {
      toggleErrorMessage(parseTryCatchError(error));
    }

    e.target.value = null;
  }
  function render(close: () => void) {
    if (imageCarousel > -1) {
      let onImageEdit = (image: ScrapImageData) => {
        setCurrentCroppingImage(image);
        setImageCarousel(-1);
        setIsEdit(true);
      };
      if (isReadOnly || imagesLoading) onImageEdit = null;
      
      // return <ScrapImagesList list={selectedImages}>{() => <h3>Hello</h3>}</ScrapImagesList>
      return (
        <ScrapImagesView
          onImageEdit={onImageEdit}
          images={selectedImages}
          defaultImageIndex={imageCarousel}
        />
      );
    }
    if (showCloseWarning) {
      return (
        <p className="scrap-images-popup__close-warning">
          Closing this popup will not save your changes. Do you wish to proceed?
        </p>
      );
    }
    if (currentCroppingImage) {
      // Show crop popup ui
      return (
        <ScrapImagesCropper
          submitButtonLabel="Save"
          imageDescription={currentCroppingImage.description}
          imageType={currentCroppingImage?.mimeType || currentCroppingImage.file?.type}
          onCancel={() => {
            if(carouselData?.index > -1) {
              setIsEdit(false);
              setImageCarousel(carouselData.index)
            }
            setCurrentCroppingImage(null);

          }}
          imageUrl={currentCroppingImage?.imageViewUrl}
          onCrop={imageCropHandler.bind(null, currentCroppingImage.key)}
          onDescriptionChange={(text) => {
            setCurrentCroppingImage((old) => ({ ...old, description: text }));
          }}
        />
      );
    }
    // Else show images list popup
    const imagesCountLimitReached =
      selectedImages?.length === config.gallery_images_count_limit;
    if (isGridMode) {
      return (
        <>
          <SortableList
            axis="xy"
            onSortEnd={sortEndHandler}
            distance={0}
            transitionDuration={300}
            pressDelay={200}
            key={`${isEdit}`}
          >
            <ScrapImagesGrid
              disable={status.status === 'processing'}
              isEdit={isEdit}
              onFilesChange={uploadFileChangeHandler}
              list={selectedImages}
              isLimitReached={imagesCountLimitReached}
              disabled={status?.status === 'processing'}
            >
              {(imageData, i) => (
                <DragItem
                  
                  disabled={!isEdit}
                  className={`scrap-images-grid__item${
                    isEdit ? ' scrap-images-grid__item--editable' : ''
                  }`}
                  key={imageData.key}
                  index={i}
                >
                  <ScrapImagesGridItem
                    onImageClick={() => {
                      if (isEdit) setCurrentCroppingImage(imageData);
                      else setImageCarousel(i);
                    }}
                    isEdit={isEdit}
                    key={imageData.key}
                    imageData={imageData}
                    onDescriptionChange={imageDescriptionChangeHandler.bind(
                      null,
                      imageData.key
                    )}
                    onDeleteClick={deleteImageHandler.bind(null, imageData.key)}
                    onCropClick={
                      !isReadOnly
                        ? () => {
                          setCurrentCroppingImage(imageData);
                          setIsEdit(true);
                        }
                        : null
                    }
                  />
                </DragItem>
              )}
            </ScrapImagesGrid>
          </SortableList>
          {status?.status === 'error' && (
            <ErrorMessage type='filled'>{status.message}</ErrorMessage>
          )}
        </>
      );
    }

    return (
      <SortableList
        onSortEnd={sortEndHandler}
        distance={0}
        transitionDuration={300}
        useDragHandle
      >
        <ScrapImagesList
          onFilesChange={uploadFileChangeHandler}
          list={selectedImages}
          isLimitReached={imagesCountLimitReached}
          disabled={status?.status === 'processing'}
        >
          {(imageData, i) => (
            <DragItem
              className={'scrap-images-list__item__point'}
              key={imageData.key}
              index={i}
            >
              <ScrapImagesListItem
                key={imageData.key}
                imageData={imageData}
                onDescriptionChange={imageDescriptionChangeHandler.bind(
                  null,
                  imageData.key
                )}
                onCropClick={setCurrentCroppingImage.bind(null, imageData)}
              />
            </DragItem>
          )}
        </ScrapImagesList>
        <InvisibleRecaptcha inputRef={reCaptchaRef} />
      </SortableList>
    );
  }
  function renderLeftFooter() {
    if (isReadOnly) return null;
    if (showCloseWarning || currentCroppingImage || !isGridMode) return null;
    return (
      <div className="scrap-images-grid__footer-left">
        {isEdit ? (
          <button
            onClick={() => inputRef.current.click()}
            className="scrap-images-grid__footer-left-add-button button button__outline"
          >
            <PlusIcon />
          </button>
        ) : (
          <button
            onClick={() => setIsEdit(true)}
            className="scrap-images-grid__footer-left-edit-button button button__outline"
          >
            <PencilEditIcon />
          </button>
        )}
        <input
          multiple
          accept={config.supported_images_accept_list}
          onChange={fileChangeHandler}
          type="file"
          ref={inputRef}
        />
      </div>
    );
  }
  function setShowHandler(val: boolean) {
    if (status?.status === 'processing') return;
    if (currentCroppingImage) {
      // It means that user is on image edit mode to crop, rotate etc
      // So pressing cancel should give the user ability to go back to images list view popup
      // which will be achieved by setting currentCroppingImage to null
      setCurrentCroppingImage(null);
      if(carouselData?.index > -1) {
        setIsEdit(false);
        setImageCarousel(carouselData.index)
      }
    } else if (!selectedImages?.length) setShow(val);
    else {
      if (isEdit) {
        setShowCloseWarning(!showCloseWarning);
      }
      else {
        setShow(false);
      }
    }
  }
  function resetImagesHandler(clickedCancel?: boolean) {
    const images = data?.getScrapImages?.data;
    let carouselIndex = carouselData?.index > -1 ? carouselData.index : -1;
    let editMode = clickedCancel ? false : defaultEdit;
    let temp: ScrapImageData[] = [];
    
    if (Array.isArray(images) && !imagesLoading) {
      temp = images.map((image) => ({
        description: image.desc ? parseNote(image.desc) : '',
        imageViewUrl: returnImageURL(image.file_path),
        order: image.order,
        filename: image.file_name,
        id: image.id,
        key: image.id ?? image.file_path,
        mimeType: image.type,
        is_heic: image.is_heic,
      }));
    } else {
      const imageData = scrap.images;
      temp = imageData?.map((image) => {
        const parsedConfig = parseStringifiedJson(parseStringifiedJson(image.file_config));
        return {
          description: image.desc ? parseNote(image.desc) : '',
          imageViewUrl: returnImageURL(image.file_path),
          key: image.id ?? image.file_path,
          order: image.order,
          filename: image.file_name,
          id: image.id,
          mimeType: image.mimeType,
          is_heic: parsedConfig?.file_meta?.is_heic
        }
      });
    }
    
    if (temp.length === 1 && !editMode) carouselIndex = 0;
    setImageCarousel(carouselIndex);
    temp.sort((a, b) => a.order - b.order);
    setSelectedImages(temp);
    
    
  }
  // Using UseEffect to clear all data from state when ever user opens and closes the popup
  useEffect(() => {
    setCurrentCroppingImage(null);
    setShowCloseWarning(false);
    setIsEdit(defaultEdit);
    if (!scrap) {
      setSelectedImages([]);
    } else {
      resetImagesHandler();
    }
  }, [scrapImagesPopup]);

  let className = 'scrap-images-popup';
  if (currentCroppingImage) className += ' scrap-images-cropper-popup';
  else if (imageCarousel > -1) className = 'scrap-images-view-popup';
  else
    className += isGridMode
      ? ' scrap-images-grid-popup'
      : ' scrap-images-list-popup';
  if (status.status === 'processing')
    className += ' scrap-images-popup--disabled';
  if(isReadOnly || isReadOnly) className += ' scrap-images-popup--readonly';  
  let submitLabel = 'Save';
  if (status?.status === 'processing') submitLabel = 'Saving';
  if (showCloseWarning) submitLabel = 'Yes';
  let popupHeader = 'Scrap Image(s)';
  if (scrap) {
    popupHeader = isEdit ? 'Gallery Edit' : 'Image Gallery';
  }
  if (currentCroppingImage) popupHeader = 'Edit Image';
  let hideFooter =
    !!currentCroppingImage ||
    imageCarousel > -1 ||
    isReadOnly ||
    isReadOnly;
  
  useEffect(() => {
    const images = data?.getScrapImages?.data;
    if (images?.length && !imagesLoading) {
      const temp: ScrapImageData[] = images.map((image) => {
        const parsedConfig = parseStringifiedJson(image.file_config);
        return {
          description: image.desc ? parseNote(image.desc) : '',
          imageViewUrl: returnImageURL(image.file_path),
          order: image.order,
          filename: image.file_name,
          id: image.id,
          key: image.id ?? image.file_path,
          is_heic: parsedConfig?.file_meta?.is_heic,
        }
      });
      temp.sort((a, b) => a.order - b.order);
      setSelectedImages(temp);
      let imageData = images.map(function (item) {
        const data = { ...item };
        delete data.file_origin;
        delete data.title;
        delete data.scrap_id;
        data.desc = null;
        return data;
      });

      dispatch(
        setOneScrapAction({
          type: 'edit',
          scrap: {
            id: +scrap.id,
            images: [...imageData].sort((a, b) => a.order - b.order),
          },
        })
      );
    }
  }, [data, imagesLoading]);

  return (
    <NewPopup
      className={className}
      header={{
        heading: popupHeader,
        renderIcon:
          currentCroppingImage
            ? (close) => (
              <button
                onClick={() => {
                  if (currentCroppingImage) close();
                  else if (imageCarousel > -1) setImageCarousel(-1);
                }}
                className="new-popup__header-icon"
              >
                <LeftArrowIcon className="new-popup__header-button__icon" />
              </button>
            )
            : null,
      }}
      controlled={{ show, setShow: setShowHandler }}
      footer={{
        onSubmit: submitHandler,
        hide: hideFooter,
        onCancelClick: cancelClickHandler,
        submitLabel,
        cancelLabel: showCloseWarning ? 'No' : 'Cancel',
        disableSubmit: status?.status === 'processing' || !selectedImages.length,
        disableCancel: status?.status === 'processing',
        hideCancel: !isEdit,
        hideSubmit: !isEdit,
        leftRender: renderLeftFooter,
      }}
    >
      {render}
    </NewPopup>
  );
};

export default ScrapImagesPopupContainer;
