import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const ChainIcon = ({ className, width, height, style }: Proptypes) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75821 2.75764C1.87953 3.63632 1.87953 5.06094 2.75821 5.93962L3.81887 7.00028C4.11176 7.29318 4.11176 7.76805 3.81887 8.06094C3.52598 8.35384 3.0511 8.35384 2.75821 8.06094L1.69755 7.00028C0.233083 5.53582 0.233083 3.16145 1.69755 1.69698C3.16201 0.232516 5.53638 0.232516 7.00085 1.69698L8.06151 2.75764C8.3544 3.05054 8.3544 3.52541 8.06151 3.8183C7.76862 4.1112 7.29374 4.1112 7.00085 3.8183L5.94019 2.75764C5.06151 1.87896 3.63689 1.87896 2.75821 2.75764ZM11.2435 11.2429C10.3648 12.1216 8.94019 12.1216 8.06151 11.2429L7.00085 10.1823C6.70796 9.88937 6.23308 9.88937 5.94019 10.1823C5.6473 10.4752 5.6473 10.95 5.94019 11.2429L7.00085 12.3036C8.46532 13.7681 10.8397 13.7681 12.3042 12.3036C13.7686 10.8391 13.7686 8.46475 12.3042 7.00028L11.2435 5.93962C10.9506 5.64673 10.4757 5.64673 10.1828 5.93962C9.88994 6.23252 9.88994 6.70739 10.1828 7.00028L11.2435 8.06094C12.1222 8.93962 12.1222 10.3642 11.2435 11.2429ZM5.40986 4.34863C5.11697 4.05574 4.64209 4.05574 4.3492 4.34863C4.05631 4.64153 4.05631 5.1164 4.3492 5.40929L8.59184 9.65193C8.88473 9.94483 9.35961 9.94483 9.6525 9.65193C9.94539 9.35904 9.94539 8.88417 9.6525 8.59127L5.40986 4.34863Z"
        fill="#84929A"
      />
    </svg>
  );
};

export default ChainIcon;
