import React from 'react';
import { LinkIcon } from '../../icons';

interface Proptypes {
  url: string;
}
export default function WebContentUrl({ url }: Proptypes) {
  if (!url) return null;
  let urlData: URL;
  try {
    urlData = new URL(url);
  } catch (error) {
    //
  }
  if (!urlData) return null;

  return (
    <div className="web-content__url">
      <a
        className="web-content__url-link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {urlData.hostname}
        <LinkIcon className="web-content__url-icon" />
      </a>
    </div>
  );
}
