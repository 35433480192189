import React from 'react';

interface Proptypes {
  className?: string;
}

const FormatClear = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.20117 11.6748L5.10449 12H0.200195L0.323242 11.6748C0.81543 11.6631 1.14062 11.6221 1.29883 11.5518C1.55664 11.4521 1.74707 11.3145 1.87012 11.1387C2.06348 10.8633 2.2627 10.3711 2.46777 9.66211L4.54199 2.47266C4.71777 1.875 4.80566 1.42383 4.80566 1.11914C4.80566 0.966797 4.76758 0.837891 4.69141 0.732422C4.61523 0.626953 4.49805 0.547852 4.33984 0.495117C4.1875 0.436523 3.88574 0.407227 3.43457 0.407227L3.54004 0.0820312H8.14551L8.04883 0.407227C7.67383 0.401367 7.39551 0.442383 7.21387 0.530273C6.9502 0.647461 6.74805 0.814453 6.60742 1.03125C6.47266 1.24805 6.29688 1.72852 6.08008 2.47266L4.01465 9.66211C3.82715 10.3242 3.7334 10.7461 3.7334 10.9277C3.7334 11.0742 3.76855 11.2002 3.83887 11.3057C3.91504 11.4053 4.03223 11.4844 4.19043 11.543C4.35449 11.5957 4.69141 11.6396 5.20117 11.6748ZM8.42729 5.45455L9.99547 8.13068L11.5637 5.45455H12.7227L10.6091 8.72727L12.7227 12H11.5637L9.99547 9.46023L8.42729 12H7.2682L9.34775 8.72727L7.2682 5.45455H8.42729Z"
        fill="black"
      />
    </svg>
  );
};

export default FormatClear;
