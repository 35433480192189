import React from 'react';
import { ScrapImageData } from '../../../containers/ScrapImagesPopupContainer';
import ScrapImagesGridItem from './ScrapImagesGridItem';
import './styles.css';
interface Proptypes {
  disable?: boolean;
  isEdit?: boolean;
  list: ScrapImageData[];
  children: (imageData: ScrapImageData, index: number) => React.ReactElement;
  onFilesChange: (files: FileList) => void;
  isLimitReached?: boolean;
  disabled?: boolean;
}
const ScrapImagesGrid = ({
  isEdit,
  list,
  children,
  onFilesChange,
  isLimitReached,
  disabled,
}: Proptypes) => {
  return (
    <div
      className={`scrap-images-grid__list${
        isEdit ? ' scrap-images-grid__list--editable' : ''
      }`}
    >
      {list.map(children)}
    </div>
  );
};
export { ScrapImagesGrid as default, ScrapImagesGridItem };
