// This functtion fetches blob from file url passed in parameter
export default async function fetchBlob(url: string) {
  try {
    const res = await fetch(url);
    const blob = await res.blob();
    return blob;
  } catch (error) {
    return null;
  }
}
