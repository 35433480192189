import { BatchEditOperationType } from '../types/batchEdit';
export const operations: BatchEditOperationType[] = [
  {
    action: 'delete',
    show: [],
    label: 'Delete Scraps',
    selected: [],
    message: 'Delete the selected Scraps?',
  },
  {
    action: 'bulk_add_tags',
    show: ['tags'],
    label: 'Add Tags',
    selected: [],
  },
  {
    action: 'bulk_remove_tags',
    show: ['tags'],
    label: 'Remove Tags',
    selected: [],
  },
  {
    action: 'bulk_add_collections',
    show: ['collections'],
    label: 'Add to Collections',
    selected: [],
  },
  {
    action: 'bulk_remove_collections',
    show: ['collections'],
    label: 'Remove from Collections',
    selected: [],
  },
];
