import {
  chatType,
  MessageContentType,
  MessageResponseType,
  ThreadsResponseType,
  ThreadType,
} from './threadsType';
import * as config from '../../../settings.json';
import parseJustification from './parseJustification';

const BASE_URL = config.userContentURL;

export async function getThreads(
  userId: string
): Promise<Array<ThreadsResponseType>> {
  return fetch(`${BASE_URL}/threads?userId=${userId}`)
    .then((res) => res.json())
    .then((data) => data.threads);
}

export async function getThreadsChat(
  id: string
): Promise<Array<MessageResponseType>> {
  return fetch(`${BASE_URL}/threads/${id}`)
    .then((res) => res.json())
    .then((data) => data.chats);
}

export function removeJustification(message: string): string {
  // if justification tag is present, remove everything after <justification> tag
  return message.split('<justification>')[0];
}

/**
 * find the start and end of the topic-title tag
 * and remove the topic-title tag and everything after it
 * @param message
 */
export function removeTopicTitle(message: string): string {
  const topicTitleStart = message.indexOf('<topic-title>');
  const topicTitleEnd = message.indexOf('</topic-title>');
  if (topicTitleStart > -1 && topicTitleEnd > -1) {
    return (
      message.slice(0, topicTitleStart) + message.slice(topicTitleEnd + 13)
    );
  }

  // when only the topic-title opening tag is present, remove everything after it
  if (topicTitleStart > -1) {
    return message.slice(0, topicTitleStart);
  }

  return message;
}

/**
 * Make a request to save the chat and thread
 */
export async function saveChat(
  selectedThread: ThreadType,
  chat: chatType,
  userId: string,
  meta: {
    collectionId: string;
    groupId: string;
    path: string;
    label: string;
  }
): Promise<{ status: string; threadId: string }> {
  const ifJustification = parseJustification(chat.content);
  let justification = null;
  let scrapIds = null;

  if (ifJustification) {
    justification = ifJustification.justification;
    scrapIds = ifJustification.scrapIds;

    // remove justification from the message
    chat.content = removeJustification(chat.content);
  }

  return fetch(`${BASE_URL}/chat/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      chat,
      thread: {
        threadId: selectedThread?.threadId,
        title: selectedThread.title,
      },
      userId,
      justification,
      scrapIds,
      collectionId: meta?.collectionId,
      groupId: meta?.groupId,
      path: meta?.path,
      label: meta?.label,
    }),
  }).then((res) => res.json());
}

// format the MessageResponseType to MessageContentType
export function formatMessages(
  messages: MessageResponseType[]
): MessageContentType[] {
  return messages.map((message) => ({
    props: {
      model: {
        message: message.content,
        sentTime: message.createdAt,
        sender: message.role,
        direction: message.role === 'user' ? 'outgoing' : 'incoming',
        position: 'single',
        justification: message.justification,
        scrapIds: message.scrapIds,
      },
    },
  }));
}

// Take the threadResponseType and add a blank array to format it as ThreadType
export function formatThread(thread: ThreadsResponseType[]): ThreadType[] {
  return thread.map((t) => ({
    ...t,
    messages: [],
  }));
}
