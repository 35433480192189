import React, { useEffect, useRef, useState } from 'react';
import NewPopup from '../components/Global/NewPopup';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../redux/store';

import {
  setInvitationPopup,
} from '../redux/action/utils';
import InvitationPopup from '../components/InvitationPopup';
import { useApolloClient, useMutation } from '@apollo/client';
import useDebounce from '../helpers/customHooks/useDebounce';
import { GlobalSearchUserType } from '../types/globalSearchDataType';
import TabSwitch from '../components/TabSwitch';
import { TabSwitchDefaultTabs } from '../components/TabSwitch/types';
import {
  CountryIso2,
  usePhoneInput,
  usePhoneValidation
} from 'react-international-phone';
import useOutsideAlerter from '../helpers/customHooks/useOutsideAlerter';
import InvitationFields from '../components/InvitationPopup/UiComponents/InvitationFields';
import ContactSuggestions from '../components/InvitationPopup/UiComponents/ContactSuggestions';
import { SEND_WITHDRAW_CONTACT_REQUEST } from './data/sendWidrawInvitations';
import { waitBeforeExecute } from '../helpers';
import { LOGIN_VIA } from '../helpers/config';
import phone from 'phone';
import { validateEmail } from '../helpers/validators';
// import GLOBAL_SEARCH_USERS_FOR_INVITATION from './data/globalSearchUsersForInvitations';
import GLOBAL_SEARCH_USERS from './data/globalSearchUsers';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
const InvitationPopupContainer = () => {
  const dispatch = useDispatch();
  const invitationPopup = useSelector(
    (state: ReduxStateType) => state.utils.showInvitationPopup
  );

  const { onComplete, prefilledData } = invitationPopup || {};

  const [activeTab, setActiveTab] = useState<TabSwitchDefaultTabs['tabs']>(
    'Email'
  );
  // const [status, setStatus] = useState<RequestStatusObjectType>({
  //   status: 'not-started',
  // });
  const client = useApolloClient();
  const [username, setUsername] = useState('');
  // Note: if email is undefined, then it means the selected user from list does not have email
  // Do not set email to undefined for other causes.
  // To see its implementation, go to Section: undefined-email-implementation.
  const [email, setEmail] = useState(''); 
  const [loadingEmailSuggestion, setLoadingEmailSuggestion] = useState(false);
  const [hideEmailSuggestion, setHideEmailSuggestion] = useState(true);
  const [sendingInvitation, setSendingInvitaion] = useState(false);
  const [invitationMessage, setInvitationMessage] = useState('');
  const [fetchedUsers, setFetchedUsers] = useState<GlobalSearchUserType[]>(
    null
  );
  const [countryCode, setCountryCode] = useState({
    dialCode: '1',
    country: 'us' as CountryIso2,
  });

  const [phoneNumber, setPhoneNumber] = useState('');
  const { phone: formattedPhone } = usePhoneInput({
    defaultCountry: 'us',
    value: `${countryCode.dialCode}${phoneNumber}`,
  });
  const phoneValidation = usePhoneValidation(formattedPhone, {
    prefix: '+',
    charAfterDialCode: ' ',
  });
  const [sendInvitation] = useMutation(SEND_WITHDRAW_CONTACT_REQUEST);
  const debounce = useDebounce(500);
  const emailSuggestion = useRef();
  useOutsideAlerter(
    emailSuggestion,
    () => setHideEmailSuggestion(true),
    hideEmailSuggestion,
    true
  );

  //check if invitation should be disabled
  const isInviteDisabled = () => {
    // Section: undefined-email-implementation
    return (
      ((email?.length < 0 || !(email === undefined || validateEmail(email))) && activeTab === 'Email') ||
      (!phone(phoneNumber, {
        country: countryCode.country,
      }).isValid &&
        activeTab === 'SMS')
    );
  };
  
  //for handling invitation logic
  async function invitationHandler() {
    let variables = {
      isSending: true,
      isWithdrawing: false,
      login_via:
        activeTab === 'Email' ? LOGIN_VIA.email : LOGIN_VIA.phone_number,
    };
    if (activeTab === 'Email') {
      if(email) variables['email'] = email;
      if(username && username.startsWith('@')) variables['name'] = username.slice(1);
    } else if (activeTab === 'SMS') {
      variables['phone'] = phoneNumber;
      variables['country_code'] = '+' + countryCode.dialCode;
      variables['country_iso']= countryCode.country
    }
    setSendingInvitaion(true);
    sendInvitation({
      variables,
    })
      .then((res) => {
        waitBeforeExecute(() => {
          onComplete && onComplete();
        }, 1500);
        let message, isError;
        if (res?.data?.sendOrWithdrawContactRequest?.messages?.success?.length > 0) {
          message = res.data.sendOrWithdrawContactRequest.messages.success[0]
        } else {
          message = 'Something went wrong';
          const errors = res?.data?.sendOrWithdrawContactRequest?.messages?.error || []
          if(errors.length) message = errors[0]
          isError = true;
        }
        setInvitationMessage(message);
        setTimeout(() => {
          setInvitationMessage('');
        }, 3000);
        if(!isError) {
          setEmail('');
          setUsername('');
          setPhoneNumber('');
          setFetchedUsers(null);
        }
        
      })
      .catch((error) => {
        setInvitationMessage(parseTryCatchError(error));
        setTimeout(() => {
          setInvitationMessage('');
        }, 3000);
      })
      .finally(() => {
        setSendingInvitaion(false);
      });
    setLoadingEmailSuggestion(false);
    setHideEmailSuggestion(false);
    
  }

  function inputChangeHandler(value: string) {
    setUsername(value);
    setEmail(value);
    if (!value) {
      setFetchedUsers(null);
    }
    setLoadingEmailSuggestion(true);
    if(value.length < 3)
    {
      searchContacts(50, 1, value)
    }
    else{
      debounce(()=>searchContacts(50, 1, value));
    }
  }

  //for handing contact search logic
  async function searchContacts(first: number, page: number, text: string) {
    try {
      if (!text) {
        throw new Error('Nothing to search at the moment');
      }
      const variables = {
        first,
        text,
        isUsersForInvitation: true,
        tie_breaker_param: 'id'
      };

      const data = await client.query({
        query: GLOBAL_SEARCH_USERS(),
        variables,
      });
      if (data?.data.globalSearchUsers.data.knot?.length > 0) {
        setFetchedUsers(data.data.globalSearchUsers.data.knot);
      }
      else{
        setFetchedUsers(null)
      }
    } catch (error) {
      console.warn(error);
    }
    setLoadingEmailSuggestion(false);
    setHideEmailSuggestion(false);
  }

  //for rendering invitation controls
  const renderInvitationControls = () => {
    return (
      <TabSwitch
        onChange={(tab: string) => {
          setPhoneNumber(null);
          inputChangeHandler('')
          setActiveTab(tab as TabSwitchDefaultTabs['tabs']);
        }}
        activeTab={activeTab}
      />
    );
  };

  //render suggestion div
  const renderSuggestion = () => {
    return (
      <ContactSuggestions
        onUserClick={(user) => {
          setUsername('@' + user?.user_name);
          setEmail(user.email || undefined);  // Section: undefined-email-implementation
          setFetchedUsers(null);
          setHideEmailSuggestion(true);
        }}
        hideSuggestionHandler={(val) => {
          setHideEmailSuggestion(val);
        }}
        hideEmailSuggestion={hideEmailSuggestion}
        loadingEmailSuggestion={loadingEmailSuggestion}
        fetchedUsers={fetchedUsers}
        email={email}
      />
    );
  };

  //for dynamically rendering input field based on label selected
  const returnFormFields = () => {
    return (
      <InvitationFields
        setCountryCode={setCountryCode}
        phoneNumber={phoneNumber}
        inputChangeHandler={(val) => inputChangeHandler(val)}
        setPhoneNumber={(val) => setPhoneNumber(val)}
        activeTab={activeTab}
        countryCode={countryCode}
        val={username}
        showSuggestionHandler={() => setHideEmailSuggestion(false)}
      />
    );
  };

  // Reset form when states
  useEffect(() => {
    if(invitationPopup && prefilledData) {
      if(isFinite(+prefilledData)) {
        setActiveTab('SMS')
        setPhoneNumber(prefilledData.toString())
      } else {
        setActiveTab('Email')
        inputChangeHandler(prefilledData.toString());
      }
    } else {
      setPhoneNumber(null);
      inputChangeHandler('');
      setActiveTab('Email')
    }
  }, [invitationPopup])

  return (
    <NewPopup
      size='Small'
      className="invitation-popup__modal"
      header={{
        heading: 'Add a Contact',
      }}
      controlled={{
        show: !!invitationPopup,
        setShow: (val) => {
          setUsername(null);
          setFetchedUsers(null);
          dispatch(setInvitationPopup(null));
        },
      }}
    >
      {(close) => (
        <InvitationPopup
          renderInvitationControls={() => renderInvitationControls()}
          returnFormFields={() => returnFormFields()}
          renderSuggestion={() => renderSuggestion()}
          invitationHandler={() => invitationHandler()}
          hideEmailSuggestion={() => setHideEmailSuggestion(true)}
          isInviteDisabled={isInviteDisabled}
          sendingInvitation={sendingInvitation}
          message={invitationMessage}
        />
      )}
    </NewPopup>
  );
};

export default InvitationPopupContainer;
