import React, { useContext, useEffect, useState, useRef } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import { ENTRY_DATA } from './data/getSingleEntryData';
import { useDispatch, useSelector } from 'react-redux';
import ScrapSaved, {
  ScrapSavedFooter,
  ScrapSavedHeader,
  ScrapSavedLoader,
  ScrapSavedFiles,
  ScrapSavedGallery,
  ScrapSavedWeb,
} from '../components/ScrapSaved';
import NewPopup from '../components/Global/NewPopup';
import { ReduxStateType } from '../redux/store';
import { setReloadSideNavFilters, setSavedScrap } from '../redux/action/utils';

import { entryTypes } from '../types/feed';
import { RequestStatusObjectType } from '../types/requestStatusType';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import { returnShareLink } from '../helpers/returnShareLinks';
import { parseTagFromSlug, returnScrapSlug, waitBeforeExecute } from '../helpers';
import { tagType } from '../types/tags';
import { collectionType } from '../types/collections';
import { EDIT_PANEL } from '../components/data/updateDetails';
import { ADD_COLLECTIONS_TO_PANEL } from './data/collection';
import { DELETE_PANEL } from '../components/data/deletePanel';
import ReloadContext from '../helpers/contexts/reloadContext';
import EntryActionContext from '../helpers/contexts/entryActionContext';
import { CreateScrapImageDataType } from '../helpers/createScrapHelpers';
import DeleteScrap from '../components/Details/Components/DeleteScrap';
import { UPLOAD_FILE } from './data/uploadFile';
import { parseImagesMeta } from '../helpers/parseImagesMeta';
import { parseSearchQueryFromUrl } from '../components/ScrapDetailsPopup';

import ReCAPTCHA from 'react-google-recaptcha';
import InvisibleRecaptcha from '../components/Global/InvisibleRecaptcha';
import ValidateHuman from '../helpers/validators/isHuman';

export interface ScrapSavedImageType {
  type: 'default-image' | 'removed' | 'new';
  imageViewUrl: string;
  file?: File;
}

const ScrapSavedContainer = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const user = useSelector((state: ReduxStateType) => state.user);
  const { savedScrap } = useSelector((state: ReduxStateType) => state.utils);

  const [scrapData, setScrapData] = useState<entryTypes>(null);
  const [fetchStatus, setFetchStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [saveStatus, setSaveStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState<number>(null);
  const [url, setUrl] = useState('');
  const [imageData, setImageData] = useState<CreateScrapImageDataType>({
    image: null,
    type: 'remove',
  });
  const [scrapImage, setScrapImage] = useState<string>(null);
  const [isDeleting, setDeleting] = useState(false);
  const [deletePanel] = useMutation(
    DELETE_PANEL(scrapData?.type ? scrapData?.type : 'screenshot')
  );

  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const [gotEdited, setGotEdited] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { setReload } = useContext(ReloadContext);
  const [unsavedTags, setUnsavedTags] = useState<tagType[]>([]);
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [addCollections] = useMutation(ADD_COLLECTIONS_TO_PANEL);
  const [saveDocumentScrap] = useMutation(EDIT_PANEL({ type: 'document' }));
  const [saveVideoScrap] = useMutation(EDIT_PANEL({ type: 'video' }));
  const [saveGalleryScrap] = useMutation(EDIT_PANEL({ type: 'gallery' }));
  const [saveWebPageScrap] = useMutation(EDIT_PANEL({ type: 'page' }));
  const [saveProductScrap] = useMutation(EDIT_PANEL({ type: 'product' }));
  const [saveSocialScrap] = useMutation(EDIT_PANEL({ type: 'social' }));

  const reCaptchaRef = useRef<ReCAPTCHA>(null);

  const [unsavedCollections, setUnsavedCollections] = useState<
    collectionType[]
  >([]);
  const saveMapper = {
    video: saveVideoScrap,
    gallery: saveGalleryScrap,
    document: saveDocumentScrap,
    page: saveWebPageScrap,
    social: saveSocialScrap,
    product: saveProductScrap,
  };
  const saveScrap = saveMapper[scrapData?.type];
  const { entryAction } = useContext(EntryActionContext);
  const submitHandler = async (close) => {
    const isHuman = await ValidateHuman(reCaptchaRef.current);
    if (!isHuman) return;
    try {
      if (showDelete) {
        setSaveStatus({ status: 'processing' });
        await handleDelete();
        close();
      } else {
        setSaveStatus({ status: 'processing' });
        const descriptionProperty = ['document', 'product', 'gallery'].includes(
          scrapData.type
        )
          ? 'desc'
          : 'notes';

        const variables = {
          id: scrapData.id,
          title,
        };
        if (description)
          variables[descriptionProperty] = JSON.stringify(
            JSON.stringify(description)
          );
        if (scrapData.type === 'product') variables['price'] = price;
        if (['product', 'page', 'social'].includes(scrapData.type) && url) {
          let urlObject = new URL(scrapData.updated_url || scrapData.url);
          let newUrl = urlObject.href.replace(urlObject.search, '');
          let urlSearch = url;
          if (urlSearch && !urlSearch.startsWith('?'))
            urlSearch = '?' + urlSearch;

          newUrl = newUrl + urlSearch;
          if (newUrl !== scrapData.url) {
            variables['updated_url'] = newUrl;
          }
        }

        if (scrapData.type !== 'social' && scrapImage !== scrapData.image) {
          if (!scrapImage && imageData?.type === 'remove')
            variables['image'] = null;
          else {
            if (imageData.type === 'file') {
              const uploadResponse = await uploadFile({
                variables: { file: imageData.image },
              });
              if (uploadResponse.data.upload)
                variables['image'] = uploadResponse.data.upload;
            }
          }
        }
        if (!saveScrap) return;
        if (unsavedTags.length) {
          variables['tags'] = unsavedTags
            ?.map((el) => parseTagFromSlug(el.slug).id)
            .join(',');
        }
        await saveScrap({ variables });
        if (unsavedCollections.length) {
          await addCollections({
            variables: {
              pid: scrapData.pid,
              collections: unsavedCollections
                ?.map((el: { title?; id }) => el.id)
                .join(','),
            },
          });
        }
      }
    } catch (error) {
      //
    }
    setSaveStatus({ status: 'success' });
    setReload(true);
    dispatch(setSavedScrap(null));
    dispatch(setReloadSideNavFilters(true));
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await deletePanel({
        variables: {
          id: scrapData.id,
        },
      });
      setReload(true);
      setDeleting(false);
      setShowDelete(false);
      dispatch(setReloadSideNavFilters(true));
    } catch (error) {
      alert('Some went wrong!');
      setDeleting(false);
    }
  };

  const imageChangeHandler = (data: CreateScrapImageDataType | string) => {
    setGotEdited(true);
    if (typeof data === 'string') {
      setScrapImage(data);
    } else if (data) {
      setImageData(data);
      if (data.type === 'remove') setScrapImage(null);
    }
  };

  const render = (close: () => void) => {
    if (showDelete)
      return (
        <p className="scrap-images-popup__close-warning">
          Are you sure you want to delete this Scrap?
        </p>
      );
    if (showCloseWarning)
      return (
        <p className="scrap-images-popup__close-warning">
          Do you want to save your updates first?
        </p>
      );
    if (
      !fetchStatus ||
      ['not-started', 'processing'].includes(fetchStatus?.status) ||
      !scrapData
    ) {
      return <ScrapSavedLoader />;
    }
    let el = null;
    switch (scrapData.type) {
      case 'gallery':
        el = <ScrapSavedGallery imageList={parseImagesMeta(scrapData.images_meta)} />;
        break;
      case 'video':
      case 'document':
        el = (
          <ScrapSavedFiles
            type={scrapData.type}
            fileName={scrapData.name || 'Untitled.mp4'}
            link={
              scrapData.type === 'document' ? scrapData.file : scrapData.video
            }
            fileType={scrapData.file_type}
          />
        );
        break;
      case 'page':
      case 'product':
      case 'social':
        el = (
          <ScrapSavedWeb
            scrap={scrapData}
            url={url}
            onUrlChange={(val) => {
              setGotEdited(true);
              setUrl(val);
            }}
            price={price}
            onPriceChange={(val) => {
              setGotEdited(true);
              setPrice(val);
            }}
            image={scrapImage}
            onImageChange={imageChangeHandler}
            imageData={imageData}
          />
        );
        break;
      default:
        break;
    }
    let isNotesDefaultOpened = false;
    if (scrapData.type === 'gallery') {
      let images = parseImagesMeta(scrapData.images_meta);
      if (images.length === 1) isNotesDefaultOpened = true;
    }

    return (
      <ScrapSaved
        scrapType={scrapData?.type}
        isNotesDefaultOpened={isNotesDefaultOpened}
        title={title}
        onTitleChange={(val) => {
          setGotEdited(true);
          setTitle(val);
        }}
        onDescriptionChange={(val) => {
          setGotEdited(true);
          setDescription(val);
        }}
        description={description}
        disabled={saveStatus?.status === 'processing'}
        unsavedTags={unsavedTags}
        setUnsavedTags={(val) => {
          setGotEdited(true);
          setUnsavedTags(val);
        }}
        unsavedCollections={unsavedCollections}
        setUnsavedCollections={(val) => {
          setGotEdited(true);
          setUnsavedCollections(val);
        }}
      >
        <>
          {el}
          <InvisibleRecaptcha inputRef={reCaptchaRef} />
          {showDelete && (
            <DeleteScrap
              className="create-scrap__delete-popup"
              scrapTitle={scrapData.title}
              deleting={isDeleting}
              controlled={{ show: showDelete, setShow: setShowDelete }}
              handleDelete={async () => {
                await handleDelete();
                close();
              }}
            />
          )}
        </>
      </ScrapSaved>
    );
  };

  async function fetchImageData(uid: string, extraVariables: Object = {}) {
    const imageStatus = JSON.parse(scrapData.config || "{}")?.img_status || 1;
    if (+imageStatus !== 1) return;
    let isImageFound = false;
    do {
      await waitBeforeExecute(() => {}, 10000);
      try {
        const variables = {
          uid,
          ...extraVariables,
        };
        const response = await client.query({ query: ENTRY_DATA, variables });
        const scrap: entryTypes = response.data.entry.data;
        if (!scrap.id) throw new Error('Failed to fetch scrap data');
        if (['page', 'product'].includes(scrap.type)) {
          if (scrap.image) {
            setScrapImage(scrap.image);
            setScrapData((old) => ({
              ...old,
              config: scrap.config,
              image: scrap.image,
            }));
            isImageFound = true;
          }
        }
        if (scrap.type === 'social') {
          if (['facebook', 'tiktok'].includes(scrap.service)) {
            if (scrap.thumbnail) {
              setScrapImage(scrap.thumbnail);
              setScrapData((old) => ({
                ...old,
                config: scrap.config,
                thumbnail: scrap.thumbnail,
              }));
              isImageFound = true;
            }
          }
        }
      } catch (error) {
        //
      }
    } while (!isImageFound);
  }

  async function fetchScrapHandler(
    uid: string,
    extraVariables: Object = {},
    scrapData?: entryTypes
  ) {
    try {
      setFetchStatus({ status: 'processing' });
      const variables = {
        uid,
        ...extraVariables,
      };
      let scrap: entryTypes;
      if (scrapData) scrap = scrapData;
      else {
        const response = await client.query({ query: ENTRY_DATA, variables });
        scrap = response.data.entry.data;
        if (!scrap.id) throw new Error('Failed to fetch scrap data');
      }

      if (!scrap.state) scrap.state = 1;
      setScrapData(scrap);
      setShowCloseWarning(false);
      setFetchStatus({ status: 'success' });
      const url = scrap.updated_url || scrap.url;
      let parsedSearchUrl = parseSearchQueryFromUrl(url);
      if (parsedSearchUrl) setUrl(parsedSearchUrl);
      if (scrap.type === 'product') {
        setPrice(scrap.price);
      }
      if (['page', 'product'].includes(scrap.type)) {
        setScrapImage(scrap.image);
      }
      if (scrap.type === 'social') {
        if (['facebook', 'tiktok'].includes(scrap.service)) {
          setScrapImage(scrap.thumbnail);
        }
      }
    } catch (error) {
      setFetchStatus({ status: 'error', message: parseTryCatchError(error) });
      alert(parseTryCatchError(error));
      dispatch(setSavedScrap(null));
    }
  }

  function setShowHandler(val: boolean) {
    if (showDelete) {
      setShowDelete(false);
      return;
    }
    if (gotEdited) {
      setShowCloseWarning(!showCloseWarning);
    } else dispatch(setSavedScrap(null));
  }
  async function shareHandler() {
    if (!scrapData.id || !saveScrap) return;
    try {
      setSaveStatus({ status: 'processing' });

      const { data } = await saveScrap({
        variables: { state: 2, id: `${scrapData.id}` },
      });
      let resScrap: entryTypes = data[Object.keys(data)[0]];
      setScrapData((scrap) => ({
        ...scrap,
        state: 2,
        private_key: resScrap?.private_key,
      }));
      setSaveStatus({ status: 'success' });
      resScrap = { ...scrapData, ...resScrap, state: 2 };
      const link = returnShareLink(
        'scrap',
        returnScrapSlug(resScrap),
        resScrap.userName,
        false,
        resScrap.state,
        resScrap.private_key
      );
      return link;
    } catch (error) {
      setSaveStatus({ status: 'error', message: parseTryCatchError(error) });
      alert(parseTryCatchError(error));
    }
  }

  useEffect(() => {
    if (scrapData && ['product', 'page', 'social'].includes(scrapData.type)) {
      if (!scrapData[scrapData.type === 'social' ? 'thumbnail' : 'image']) {
        fetchImageData(scrapData.uid, {
          username: user.userName,
        });
      }
    }
  }, [scrapData]);

  useEffect(() => {
    if (savedScrap) {
      fetchScrapHandler(
        typeof savedScrap === 'string' ? savedScrap : savedScrap.uid,
        {
          username: user.userName,
        }
      );
    }
  }, [entryAction]);

  useEffect(() => {
    if (client && user?.userName) {
      if (savedScrap) {
        fetchScrapHandler(
          typeof savedScrap === 'string' ? savedScrap : savedScrap.uid,
          {
            username: user.userName,
          },
          typeof savedScrap !== 'string' ? savedScrap : null
        );
      } else {
        setShowCloseWarning(false);
        setUnsavedCollections([]);
        setUnsavedTags([]);
        setScrapData(null);
        setFetchStatus({ status: 'not-started' });
        setTitle(null);
        setUrl(null);
        setDescription(null);
        setShowDelete(false);
        setPrice(null);
        setImageData({
          image: null,
          type: 'remove',
        });
        setScrapImage(null);
        setGotEdited(false);
      }
    }
  }, [client, savedScrap, user]);

  return (
    <NewPopup
      fullMobile
      footer={{
        disableSubmit: saveStatus?.status === 'processing',
        cancelLabel: showCloseWarning ? 'Discard & Close' : 'Cancel',
        hideSubmit: !gotEdited && !showDelete,
        hideCancel: !gotEdited && !showDelete,
        onCancelClick: () => {
          if (showDelete) {
            setShowDelete(false);
          } else {
            dispatch(setSavedScrap(null));
          }
        },
        leftRender: showCloseWarning
          ? null
          : (close) => (
            <ScrapSavedFooter
              showDelete={showDelete}
              onDeleteClick={setShowDelete.bind(null, true)}
            />
          ),

        onSubmit: submitHandler,
        submitLabel: showCloseWarning
          ? 'Save'
          : showDelete
            ? 'Delete'
            : 'Save Changes',
      }}
      className={`scrap-saved-popup${
        gotEdited ? ' scrap-saved-popup--edited' : ''
      }`}
      controlled={{
        show: !!savedScrap,
        setShow: setShowHandler,
      }}
      header={{
        heading: 'Scrap Saved',
        customRender: scrapData
          ? (close) => (
            <ScrapSavedHeader
              onShare={shareHandler}
              showDelete={showDelete}
              close={close}
              scrapState={scrapData.state}
              onDeleteIconClick={setShowDelete.bind(null, true)}
              shareUrl={returnShareLink(
                'scrap',
                returnScrapSlug(scrapData),
                scrapData.userName,
                false,
                scrapData.state,
                scrapData.private_key
              )}
              isChangingVisibility={false}
            />
          )
          : null,
      }}
    >
      {render}
    </NewPopup>
  );
};

export default ScrapSavedContainer;
