import React, { useEffect } from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BrowserRouter as Router } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
import graphQlWrapper from './apollo/client';
import * as config from './settings.json';

import AppStore from './redux/store';

import { ReloadContextProvider } from './helpers/contexts/reloadContext';
import { Toast } from './helpers/notification';
import { CollectionFiltersProvider } from './helpers/contexts/collectionFiltersContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ErrorBoundary from './components/Global/ErrorBoundary';
import ScrapImagesPopupContainer from './containers/ScrapImagesPopupContainer';
import { EmbedDisplayModal, ImageDisplayModal } from './components/Global/DisplayModal';
import ImageCarouselPopupContainer from './containers/ImageCarouselPopupContainer';

// global styles
import './App.css';
import AppRoutes from './components/AppRoutes';
import { ShowMemberContainer } from './containers/ShowMemberContainer';
import GropShareContainer from './containers/GroupShareContainer';
import InvitationPopupContainer from './containers/InvitationPopupContainer';
import GroupDetailsPopupContainer from './containers/GroupDetailsPopupContainer';
import Drawer from './components/Global/Drawer';
import NewScrapPopupContainer from './containers/NewScrapPopupContainer';
import logging from './helpers/Logging';
function App(props) {
  function resizeHandler() {
    const height = window.innerHeight;
    document.documentElement.style.setProperty(
      '--screen-height',
      height ? height + 'px' : '100vh'
    );
  }

  const { serverPath, token, client, server } = props;

  // Set the variable --screen-height globally
  useEffect(() => {
    if (typeof window === 'object') {
      const initialHeight = window.innerHeight;
      document.documentElement.style.setProperty(
        '--screen-height',
        initialHeight ? initialHeight + 'px' : '100vh'
      );
      window.addEventListener('resize', resizeHandler);
      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }
  }, []);
  
  return (
    <ErrorBoundary>
      <ApolloProvider client={client ? client : graphQlWrapper(token)}>
        <Provider store={AppStore}>
          <GoogleOAuthProvider clientId={config.clientID}>
            <CollectionFiltersProvider>
              <ReloadContextProvider>
                <div className="App">
                  {server ? (
                    <StaticRouter location={serverPath} context={{}}>
                      <AppRoutes client={client} token={token} />
                      <NewScrapPopupContainer />     
                      <GroupDetailsPopupContainer />
                      <Drawer />
                      <ShowMemberContainer />
                    </StaticRouter>
                  ) : (
                    <Router>
                      <AppRoutes client={client} token={token} />
                      <NewScrapPopupContainer />     
                      <GroupDetailsPopupContainer />
                      <Drawer />
                      <ShowMemberContainer />
                    </Router>
                  )}
                  <Toast position="top-center" />
                   
                  <ScrapImagesPopupContainer />
                  <EmbedDisplayModal />
                  <ImageDisplayModal />
                  <ImageCarouselPopupContainer/>
                  <InvitationPopupContainer/>
                  <GropShareContainer/>
                  
                </div>
              </ReloadContextProvider>
            </CollectionFiltersProvider>
          </GoogleOAuthProvider>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
