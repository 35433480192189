import { useMutation } from '@apollo/client';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CollectionSettingsPopup from '../components/CollectionSettingsPopup';
import { returnImageURL } from '../components/Gallery/helpers/returnImageURL';
import Loader from '../components/Global/Loader/Loader';
import NewPopup from '../components/Global/NewPopup';
import { sendToast } from '../helpers/notification';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import { removeCollectionFromStore } from '../redux/action/collectionAction';
import {
  setCollectionAction,
  setCollectionSettingsPopup,
  setReloadSideNavCollections,
  setReloadSideNavShares,
} from '../redux/action/utils';
import { ReduxStateType } from '../redux/store';
import { RequestStatusObjectType } from '../types/requestStatusType';
import { COLLECTION_DELETE, UPSERT_COLLECTION } from './data/collection';
import CONVERT_GROUP from './data/convertGroup';
import { useHistory } from 'react-router-dom';
import { getResponseMessages, slugifyString } from '../helpers';
import returnActiveCollectionSort from '../helpers/returnCollectionActiveSort';

const CollectionSettingsPopupContainer = () => {

  const history = useHistory();

  const dispatch = useDispatch();
  const collectionSettings = useSelector(
    (state: ReduxStateType) => state.utils.collectionSettingsPopup
  );
  const [editStatus, setEditStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [deleteStatus, setDeleteStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  const [editCollection] = useMutation(UPSERT_COLLECTION());
  const [convertGroupMutation] = useMutation(CONVERT_GROUP());
  const [deleteCollection] = useMutation(COLLECTION_DELETE);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const { collection, type } = collectionSettings || {};
  const {
    members,
    owner_avatar,
    owner_display_name,
    user_id,
    owner_user_name,
    show_author,
    slug,
    view_type,
    title,
    id,
    cover_image,
    desc,
    state,
    sort_by,
    sort_param,
  } = collection || {};
  
  
  const selectedSort = returnActiveCollectionSort({ sort_param:sort_param, sort_by: sort_by });
  const isDeleting = deleteStatus.status === 'processing';
  async function deleteCollectionHandler() {
    try {
      setDeleteStatus({ status: 'processing' });
      const res = await deleteCollection({
        variables: {
          id: id,
        },
      });
      const { success, error } = res?.data?.deleteCollection?.messages || {};
      if (success?.length > 0) {
        dispatch(removeCollectionFromStore(id as string));
        setDeleteStatus({ status: 'success' });
        dispatch(
          setCollectionAction({
            collection: collectionSettings.collection,
            type: 'delete',
          })
        );
        dispatch(setCollectionSettingsPopup(null));
      } else {
        let errorMessage = 'Something went wrong';
        if (Array.isArray(error) && error.length > 0) errorMessage = error[0];
        throw new Error(errorMessage);
      }
    } catch (error) {
      setDeleteStatus({ status: 'error' });
      sendToast(parseTryCatchError(error), 'error', 2000);
      throw new Error(parseTryCatchError(error));
    }
  }
  async function editHandler(
    data: {
      title?: string;
      desc?: string;
      cover_image?: string;
      view_type?: number;
      show_author?: number;
    },
    cb?: () => void
  ) {
    try {
      setEditStatus({ status: 'processing' });
      if(type === 'group-convert') {
        const variables = {
          group_id: id,
          ...data
        }
        const res = await convertGroupMutation({
          variables
        });
        const {error, isSuccess, success} = getResponseMessages(res.data.convertGroup)
        if(isSuccess) {
          const collection_id = res.data.convertGroup.data.collection_id;
          history.replace(`/c/${slugifyString(data.title, false)}/${collection_id}`);
          dispatch(setReloadSideNavShares(true));
        } else throw new Error(error[0])              
      } else {
        const res = await editCollection({
          variables: {
            id: id,
            ...data,
          },
        });
        dispatch(
          setCollectionAction({
            type: 'edit',
            collection: {
              ...collectionSettings.collection,
              ...data,
              cover_image: res?.data?.upsertCollection?.data.cover_image,
              private_key: res?.data?.upsertCollection?.data.private_key,
            },
          })
        );
      }
      
      setEditStatus({ status: 'success' });
      dispatch(setCollectionSettingsPopup(null,false));
      dispatch(setReloadSideNavCollections(true))
    } catch (error) {
      setEditStatus({ status: 'error' });
      throw new Error(parseTryCatchError(error));
    }
  }
  useEffect(() => {
    setShowDeletePopup(false);
    setEditStatus({ status: 'not-started' });
    setDeleteStatus({ status: 'not-started' });
  }, [collectionSettings]);
  let collaborators = members ?? [];
  if (type === 'group-convert')
    collaborators = [{
      avatar: owner_avatar,
      display_name: owner_display_name,
      user_id,
      user_name: owner_user_name,
      id: user_id.toString(),
    }, ...members];
    
  return (
    <Fragment>
      <CollectionSettingsPopup
        show_author={show_author}
        createCollection={type === 'group-convert' || !id}
        state={state}
        key={id}
        collaborators={collaborators}
        openOtherFieldsByDefault
        view_type={view_type}
        collectionSlug={slug}
        title={title}
        id={id}
        currentImage={cover_image ? returnImageURL(cover_image) : null}
        desc={desc}
        onCollectionEdit={editHandler}
        isEditing={editStatus.status === 'processing'}
        openDeletePopup={setShowDeletePopup.bind(null, true)}
        controlled={{
          show: !!collectionSettings,
          setShow: (val) => {
            dispatch(setCollectionSettingsPopup(null));
          },
        }}
        selectedSort={selectedSort}
      />
      <NewPopup
        size='Small'
        header={{ heading: 'Delete Collection' }}
        controlled={{ show: showDeletePopup, setShow: setShowDeletePopup }}
        footer={{
          onSubmit: () => deleteCollectionHandler(),
          submitLabel: isDeleting ? 'Deleting' : 'Delete Collection',
          disableSubmit: isDeleting,
        }}
      >
        {(close) => (
          <div className="collection-header__delete-popup">
            <Loader loadingStatus={isDeleting}/>
            <h3 className="collection-header__delete-popup-heading">{title}</h3>
            <p className="collection-header__delete-popup-message">
              Deleting your Collection will not delete the Scraps within it.
            </p>
          </div>
        )}
      </NewPopup>
    </Fragment>
  );
};

export default CollectionSettingsPopupContainer;
