import React, { useCallback, useEffect, useState } from 'react';
import NewPopup from '../Global/NewPopup';
import ButtonModal from '../Global/ButtonModal';
import { AddIcon, CopyLink } from '../Global/icons';
import { handleNativeShare, handleShare, returnAllGroupMembersExceptMe } from '../../helpers';
import SearchModal from '../Global/SearchModal';
import Icon from '../Global/Icon';

import { GroupType } from '../../containers/GroupShareContainer/types';
import ShareList from '../Global/ShareList';
import userInitials from '../../helpers/userInitial';

import './styles.css';
import { useLodashDebounce } from '../../helpers/customHooks/useDebounce';
import { RequestStatusObjectType } from '../../types/requestStatusType';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';

const MemoizedShareList = React.memo(ShareList);
interface Proptypes {
  show: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (selected: Array<Partial<GroupType>>) => void;
  shareUrl: string;
  onInviteNew: (prefilledData?: string | number) => void;
  onLoadMore: () => void;
  groups: GroupType[];
  groupSearchTerm:string;
  setGroupSearchTerm:(val:string) => void;
  loading: boolean;
}

export default function GroupShare({
  show,
  onClose,
  onSubmit,
  shareUrl,
  onInviteNew,
  onLoadMore,
  groups,
  groupSearchTerm,
  setGroupSearchTerm,
  loading
}: Proptypes) {
  const [isTyping, setIsTyping] = useState(false);
  const [status, setStatus] = useState<RequestStatusObjectType>({
    // STATE FOR SHOWING PROCESSING UI AND ERROR MESSAGE
    status: 'not-started',
  });
  const [selected, setSelected] = useState<Array<Partial<GroupType>>>([]);
  const [copied, setCopied] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const performShare = (url: string, generate: boolean = false) => {
    handleShare(url, 'show', () => {
      navigator.clipboard.writeText(encodeURI(url || ''));
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    });
  };
  const loadMoreHandler = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      (event.target as HTMLInputElement).scrollHeight -
        (event.target as HTMLInputElement).scrollTop ===
      (event.target as HTMLInputElement).clientHeight
    ) {
      onLoadMore();
    }
  };
  const handleCopy = (e?: React.FormEvent<EventTarget>) => {
    // e.stopPropagation();
    if (typeof window !== 'undefined') {
      const isShared = handleNativeShare(shareUrl, () =>
        performShare(shareUrl)
      );
      if (isShared) return;
    }
    performShare(shareUrl);
  };

  const debouncedSearch = useLodashDebounce(function() {
    setGroupSearchTerm(searchTerm);
    setIsTyping(false);
  }, 500)
  
  const searchChangeHandler = value => {
    setIsTyping(true)
    setSearchTerm(value);
    debouncedSearch();
  }
  const onGroupSelect = useCallback((data: GroupType, id: string, type: string) => {
    setSelected(old => {
      if (!old?.find((item) => item.id === id)) {
        return [...old, { ...data, id: id, type: type }]
      } else return old.filter(function (item) {
        return item.id !== id;
      })
    })
  }, []);
  

  const render = useCallback(() => {
    
    if (groups.length > 0) {
      let showInvite = !groups.some((group) => group.type === 'contact');
      if(loading || !searchTerm || isTyping) showInvite = false;
      return (
        <>
          {showInvite && (
            <button
              key="invite-contact-button"
              className="group-share__invite"
              onClick={onInviteNew.bind(null, searchTerm)}
            >
              <AddIcon />
              <span>Invite new contact</span>
            </button>
          )}
          {groups.map((group, i) => {
            return (
              <MemoizedShareList
                state={group.state}
                name={group?.owner_display_name}
                id={group.id}
                type={group.type}
                avatar={group?.owner_avatar}
                user_name={group?.owner_user_name}
                creator_initials={userInitials(group?.owner_display_name)}
                key={group.id + group.owner_display_name + group.type + i}
                className="group-share__member-list"
                onClick={onGroupSelect.bind(null, group)}
                isActive={selected?.some((item) => item.id == group.id)}
                members={
                  group?.members?.length === 1
                    ? group.members
                    : returnAllGroupMembersExceptMe(group, null, 'user_name')
                }
                owner_display_name={group.owner_display_name}
                owner_name={group.owner_user_name}
                nickname={group.title ?? group.nickname}
              />
            );
          })}
        </>
      );
    } else if (!loading) {
      return (
        <button
          className="group-share__invite"
          onClick={onInviteNew.bind(null, searchTerm)}
          key="invite-contact-button"
        >
          <AddIcon />
          <span>Invite new contact</span>
        </button>
      );
    }
    return null;
  }, [groups,selected,searchTerm,groupSearchTerm, isTyping]);

  const submitHandler = useCallback(async () => {
    try {
      setStatus({ status: 'processing' });
      await onSubmit(selected);
      setStatus({ status: 'success' });
    } catch (error) {
      setStatus({ status: 'error', message: parseTryCatchError(error) });
    }
  }, [selected]);


  // Effect to clean states
  useEffect(() => {
    setSelected([]);
    setSearchTerm('');
    setStatus({
      status: 'not-started'
    })
  }, [show])

  return (
    <NewPopup
      className="group-share-popup"
      header={{
        heading: 'Share to',
      }}
      controlled={{
        show,
        setShow: (val) => {
          onClose();
        },
      }}
      footer={{
        onSubmit: submitHandler,
        submitLabel: 'Share',
        disableSubmit: selected.length === 0,
        spinnerConfig: {
          label: 'Processing',
          show: status.status === 'processing'
        },
        leftRender: status.status === 'processing' || !shareUrl ? null : () => (
          <>
            <div className="group-share__button">
              <ButtonModal
                className="hoverState__with-white-text"
                label="Copy Link"
                icon={<CopyLink />}
                onClick={() => handleCopy()}
              />
              {copied && (
                <div className="group-share__copied">
                  <Icon iconClass="check-square" size="big" />
                  Link copied to clipboard
                </div>
              )}
            </div>
          </>
        ),
      }}
    >
      {() => (
        <>
          <SearchModal
            label="Search"
            placeholder="Search Contacts and Share Groups"
            onChange={searchChangeHandler}
            value={searchTerm}
          />
          <div className="group-share__list" onScroll={loadMoreHandler}>
            {render()}
          </div>
        </>
      )}
    </NewPopup>
  );
}
