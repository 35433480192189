import { gql } from '@apollo/client';

export const GET_USER_STORAGE = gql`
  query userStorage{
    userStorage{ 
      status
      data
      {
        total{
          value
          type
        }
        used
          {
          value
          type
        }
        type
      }
    
    }
      
  }
`;
