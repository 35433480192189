import axios from "axios";
import * as config from '../../../../settings.json';

export const getScrapSummaryContent = async (
  url: String,
  userId: Number,
) => {
  return await axios.post(
    config.userContentURL,
    {
      url,
      userId
    },
  );
}
