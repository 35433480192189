const DEFAULT = '';

const urlQuery = (state =  DEFAULT, action) => {

  switch(action.type) {
    case 'LOAD_URL_DATA' :
      
      return action.urlQueryData;

    default :
      return state;
  }
};

export default urlQuery;

  
      
  