import config from '../config';

export default function isFileBig(
  fileType: 'video' | 'image' | 'document',
  file: File
) {
  const sizeMappers = {
    image: config.image_size_limit,
    video: config.pdf_size_limit,
    document: config.video_size_limit,
  };
  let allowedSize = sizeMappers[fileType];
  if (file.size > allowedSize) {
    allowedSize = allowedSize / (1000 * 1000);
    if(fileType === 'image') return `Please choose an image under ${allowedSize} mb.`
    return `Selected file is greater than allowed size (${allowedSize} MB).`;
  }
}
