interface argsType {
  page?: number;
  first?: number;
}
/* eslint-disable @typescript-eslint/no-explicit-any */
export function queryMerge(
  existing: any[],
  incoming: any[],
  args: argsType,
  limit?: number // THIS IS TO REMOVE EXTRA CACHED ELEMENT FROM ARRAY WHEN ITS SIZE REDUCES DUE TO DELETION
) {
  if (!existing) existing = [];
  if (!incoming) incoming = [];
  const merged = existing.slice(0);
  if (args) {
    const page = args.page || 1;
    const first = args.first || 0;
    const offset = (page - 1) * first;
    for (let i = 0; i < incoming.length; ++i) {
      merged[offset + i] = incoming[i];
    }
    if (Number.isInteger(limit) && limit > 0 && merged.length > limit) {
      merged.splice(limit, merged.length - limit);
    }
  } else {
    // It's unusual (probably a mistake) for a paginated field not
    // to receive any arguments, so you might prefer to throw an
    // exception here, instead of recovering by appending incoming
    // onto the existing array.
    merged?.push?.apply(merged, incoming);
  }
  return merged;
}

export function queryRead(existing: any[], args: argsType) {
  if (!existing) existing = [];
  const page = args?.page || 1;
  const first = args?.first || 0;
  return existing?.slice(0, page * first);
}

export const scrapLocalFields = {
  isUgcExpanded: {
    read(existing = false) {
      return existing;
    },
  },
  areFilesExpanded: {
    read(existing = false) {
      return existing;
    },
  },
  areAnnotationsExpanded: {
    read(existing = false) {
      return existing;
    },
  },
  isMetaExpanded: {
    read(existing = false) {
      return existing;
    },
  },
  isAnnotationExpanded: {
    read(existing = false) {
      return existing;
    },
  },
  isTagsExpanded: {
    read(existing = false) {
      return existing;
    },
  },
  isCollectionsExpanded: {
    read(existing = false) {
      return existing;
    },
  },
};
