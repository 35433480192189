import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const NewPencil = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        d="M9.90979 0.400269C9.78478 0.275288 9.61524 0.205078 9.43846 0.205078C9.26168 0.205078 9.09215 0.275288 8.96713 0.400269L8.02446 1.3436L10.8525 4.1716L11.7951 3.22894C11.9201 3.10392 11.9903 2.93438 11.9903 2.7576C11.9903 2.58083 11.9201 2.41129 11.7951 2.28627L9.90979 0.400269ZM9.90979 5.11494L7.08113 2.28627L1.14779 8.2196C1.02317 8.34454 0.953162 8.5138 0.953125 8.69027V10.5763C0.953125 10.7531 1.02336 10.9226 1.14839 11.0477C1.27341 11.1727 1.44298 11.2429 1.61979 11.2429H3.50513C3.68192 11.2429 3.85146 11.1726 3.97646 11.0476L9.90979 5.11427V5.11494Z"
        fill="#84929A"
      />
    </svg>
  );
};

export default NewPencil;
