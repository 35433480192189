const ctype_alpha = require('locutus/php/ctype/ctype_alpha');
const ord = (str: string) => {
  return str.charCodeAt(0);
};
const chr = (charCode: number) => {
  return String.fromCharCode(charCode);
};

export const uid_scrappi = (originalString: string) => {
  try {
    var string = originalString.split('');
    var responseString = [];

    var tempFixedNum = [2, 7, 1, 6, 3, 5, 9, 4];
    var fixedNum = [...tempFixedNum];
    var fixedNumCount = fixedNum.length;
    for (var i = 0; i < string.length; i++) {
      let newChar = string[i];
      let char = newChar;
      if (fixedNumCount) {
        if (isNaN(parseInt(char))) {
          if (tempFixedNum.length === 0) {
            tempFixedNum = fixedNum;
          }

          let pos = ord(string[i]) % tempFixedNum.length;

          let change = tempFixedNum[pos];
          if (tempFixedNum[pos]) {
            tempFixedNum.splice(pos, 1);
          }

          let asciiToCharPlus = chr(ord(char) + change);
          let asciiToCharMinus = chr(ord(char) - change);

          newChar = ctype_alpha(asciiToCharPlus)
            ? asciiToCharPlus
            : asciiToCharMinus;
        }
      }

      responseString[i] = newChar;
    }

    return responseString.join('');
  } catch (error) {
    return null;
  }
};
