import React from 'react';
import { TabSwitchDefaultProps, TabSwitchProps } from './types';
import './styles.css';

export const defaultTabSwitchProps: TabSwitchDefaultProps<['Email', 'SMS']> = {
  tabs: ['Email', 'SMS'],
  activeTab: 'Email',
};

const TabSwitch = <T extends string[]>(props: TabSwitchProps<T>) => {
  const { tabs, onChange, activeTab } = props;

  const isActive = (tabName: string) => {
    return activeTab === tabName;
  };

  return (
    <div className="tab-switch__wrapper">
      {tabs.map((tab, i) => (
        <button
          className={`tab-switch__selector${
            isActive(tab) ? ' tab-switch__active_selector' : ''
          }`}
          onClick={() => onChange(tab)}
          key={`tab${i}`}
          type="button"
        >
          <span
            className={`tab-switch__selector_text${
              isActive(tab) ? ' tab-switch__active_selector_text' : ''
            }`}
          >
            {tab}
          </span>
        </button>
      ))}
    </div>
  );
};

TabSwitch.defaultProps = defaultTabSwitchProps;

export default TabSwitch;
