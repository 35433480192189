import React from 'react';

interface Proptypes {
  className?: string;
}

const ThemeDark = ({ className }: Proptypes) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3195 15.3069C15.9305 15.3987 16.2153 16.1175 15.8337 16.6046C15.1547 17.4714 14.3029 18.2247 13.295 18.8081C9.05618 21.2617 3.63607 19.8056 1.18881 15.556C-1.25846 11.3063 0.193858 5.87223 4.43264 3.41868C5.20585 2.97111 6.01971 2.65317 6.84851 2.45888C7.45052 2.31776 7.98018 2.88095 7.80432 3.47518C7.10579 5.8354 7.33842 8.46458 8.66239 10.7636C10.1308 13.3136 12.6221 14.9016 15.3195 15.3069ZM5.43457 5.14961C2.15234 7.04948 1.02385 11.2618 2.92197 14.5579C4.81835 17.851 9.01383 18.9753 12.293 17.0772C12.4813 16.9682 12.6623 16.8518 12.836 16.7285C10.4311 15.8532 8.30798 14.1559 6.92923 11.7617C5.72766 9.67519 5.26652 7.36301 5.46335 5.13304C5.45375 5.13854 5.44416 5.14406 5.43457 5.14961ZM15.9858 2.42321C16.6533 2.42321 17.1943 1.88076 17.1943 1.21161C17.1943 0.542454 16.6533 0 15.9858 0C15.3184 0 14.7773 0.542454 14.7773 1.21161C14.7773 1.88076 15.3184 2.42321 15.9858 2.42321ZM13.166 5.25029C13.166 5.91944 12.6249 6.4619 11.9575 6.4619C11.2901 6.4619 10.749 5.91944 10.749 5.25029C10.749 4.58114 11.2901 4.03869 11.9575 4.03869C12.6249 4.03869 13.166 4.58114 13.166 5.25029ZM18 7.6735C18 8.34266 17.4589 8.88511 16.7915 8.88511C16.1241 8.88511 15.583 8.34266 15.583 7.6735C15.583 7.00435 16.1241 6.4619 16.7915 6.4619C17.4589 6.4619 18 7.00435 18 7.6735Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default ThemeDark;
