import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleGlobalSearch } from '../redux/action/globalSearchAction';
import { updateFilter } from '../redux/action/filterParamsAction';

import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_SEARCH_HISTORY } from './data/searchHistory';
import { GlobalSearchType } from '../redux/store/globalSearch';
import { filterParamTypes } from '../types/filter';
import useWindowOnClick from '../helpers/customHooks/useWindowOnclick';
import GlobalSearchBar from '../components/GlobalSearch/components/GlobalSearchBar';
import GlobalSearchInputWrapper from './GlobalSearchInputWrapper';
import { getToken } from '../apollo/getToken';
export interface SearchFilterType {
  label: string;
  id: number;
}

const searchFilterList: SearchFilterType[] = [
  { label: 'All of Scrappi', id: 1 },
  { label: 'My Scrappi', id: 2 },
];

interface Proptypes {
  isPublic?: boolean;
  placeholder?: string;
  hideSuggestion?:boolean
}

const GlobalSearchBarWrapper = ({ isPublic, placeholder}: Proptypes) => {
  const globalSearch: GlobalSearchType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.globalSearch
  );
  const filterParams: filterParamTypes = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.filterParams
  );

  const dispatch = useDispatch();
  const { isSearchBarActive } = globalSearch;
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilter, setActiveFilter] = useState<SearchFilterType>(
    searchFilterList[0]
  );

  const [hideHistory, setHideHistory] = useState(true);
  const [showSearchIcon, setShowSearchIcon] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const [createHistory] = useMutation(CREATE_SEARCH_HISTORY);
  const parsedSearch = search ? qs.parse(search.substring(1)) : {};
  
  // changed default search from My Scrappi to global Search as per new UI
  // Reason: Global Search must be unified
  let searchString = `/search`;

  useWindowOnClick({
    cb: handleOutSideClick,
    removeListener: !showFilter,
  });

  function handleOutSideClick(el: HTMLElement) {
    const closestElement = el.closest('.global-search-bar__filter-wrapper');
    if (!closestElement) setShowFilter(false);
  }

  function onSearch(val: string) {
    const parsedText = parsedSearch?.text || '';
    const parsedMode = parsedSearch?.mode || 'personal';
    const parsedType = parsedSearch?.type || 'scraps';
    const trimmedVal = val?.trim() || '';
    
    if (parsedText.toString().trim() === trimmedVal) {
      if (pathname === '/' && activeFilter.label === 'My Scrappi') return;
      if (pathname === '/search' && activeFilter.label === 'All of Scrappi')
        return;
    }

    if (isPublic) {
      if (parsedMode === 'personal' || parsedType === 'scraps') {
        push(`${searchString}?mode=global&type=collections&text=${trimmedVal}`);
        inputRef.current.blur();
        return;
      }
    }
    push(
      `${searchString}?mode=${parsedMode}&type=${parsedType}&text=${trimmedVal}`
    );
    if (trimmedVal !== '' && getToken()) {
      createHistory({ variables: { search_term: trimmedVal } });
    }
    inputRef.current.blur();
  }

  function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const searchValue = inputRef.current.value.trim();
    if(searchValue) {
      onSearch(searchValue);
      setHideHistory(true);
    }
    
  }

  // FUNCTION HANDLER TO SHOW OR HIDE CLEAR AND SEARCH ICON IN SEARCH INPUT ELEMENT
  function handleIconStyles(showSearch: boolean, showClear: boolean) {
    setShowSearchIcon(showSearch);
    setShowClearIcon(showClear);
  }

  function toggleSearch() {
    dispatch(toggleGlobalSearch(!isSearchBarActive));
  }

  function clearFiltersIfAny() {
    const paramText = filterParams.text || '';
    if (
      pathname === '/' &&
      activeFilter.label === 'My Scrappi' &&
      paramText.trim() !== ''
    ) {
      const newFilterValue: filterParamTypes = {};

      newFilterValue['text'] = '';
      dispatch(updateFilter(newFilterValue));
    }
  }
  /**
   * USEEFFECT TO PARSE THE TEXT AND SHOW OR HIDE INPUT ELEMENT ICONS
   */
  useEffect(() => {
    const { text } = parsedSearch;
    
    handleIconStyles(!text, !!text);
    inputRef.current.value = text ? text.toString() : '';
    setActiveFilter(searchFilterList[pathname === '/search' ? 0 : 1]);
  }, [pathname, search]);

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset > 100 && pathname !== '/search') {
        dispatch(toggleGlobalSearch(false));
      }
    };
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [pathname]);

  return (
    <GlobalSearchBar
      isSearchBarActive={isSearchBarActive}
      submitHandler={submitHandler}
      toggleSearch={toggleSearch}
      renderInputElement={() => (
        <GlobalSearchInputWrapper
          setHideHistory={setHideHistory}
          hideHistory={hideHistory}
          placeholder={placeholder ?? 'Search'}
          showSearchIcon={showSearchIcon}
          inputRef={inputRef}
          showClearIcon={showClearIcon}
          handleIconStyles={handleIconStyles}
          onSearchHistoryItemClick={onSearch}
          clearFiltersIfAny={clearFiltersIfAny}
          isPublic={isPublic}
        />
      )}
    />
  );
};
export default GlobalSearchBarWrapper;
