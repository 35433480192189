import React from 'react';
import PublicPageWrapper from '../components/Global/PublicPageWrapper';
import ProfilePreviewWrapper from './ProfilePreview';

const GlobalSearchPublicProfileWrapper = (props) => {
  return (
    <PublicPageWrapper>
      <ProfilePreviewWrapper {...props} isPublic={true} />
    </PublicPageWrapper>
  );
};

export default GlobalSearchPublicProfileWrapper;
