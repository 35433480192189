// Example usage
// const timestamp = '2022-09-13 09:56:00'; // Example timestamp from the previous year
// const timezone = 'America/New_York'; // Change this to the desired timezone
// const fullDate = showFullDate(timestamp, timezone);
// console.log(fullDate);

// Note: This function assumes that the timezone of
// the timestamp, it is recieving as parameter is UTC

import { formatToTimeZone, parseFromTimeZone } from 'date-fns-timezone';
import { getBrowserTimezone } from './getBrowserTimezone';

export function showFullDate(date: string, timezone: string) {
  try {
    const zonedDate = parseFromTimeZone(date, {
      timeZone: 'UTC',
    });
    const dateObject = new Date(zonedDate);
    
    const formattedTime = formatToTimeZone(
      dateObject,
      'MMM DD, YYYY - h:mm A',
      {
        timeZone: timezone || getBrowserTimezone(),
      }
    );
    return formattedTime;
  } catch (error) {
    return null;
  }
}
