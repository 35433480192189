import React, { useState } from 'react';
import './styles.css';
import LabelTextInput from '../../../Global/LabelTextInput';
import { useLodashDebounce } from '../../../../helpers/customHooks/useDebounce';

interface Proptypes {
  handleSearch: (val: string) => void;
}
const SearchContacts = (prop: Proptypes) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { handleSearch} = prop;
  // const searchRef= useRef(null);

  const debouncedSearch = useLodashDebounce(function () {
    handleSearch(searchTerm);
  }, 500);
  const searchChangeHandler = (value: string) => {
    setSearchTerm(value);
    debouncedSearch();
  };
  return (
    <div className="search-contacts">
      <LabelTextInput
        placeholder="Search Contacts"
        showLabelOnFocus={true}
        value={searchTerm}
        label="Search"
        onChange={searchChangeHandler}
      />
    </div>
  );
};

export default SearchContacts;
