import React, { useEffect, useMemo, useRef, useState } from 'react';

import './styles.css';
import { commentsDrawerType } from '../../../../redux/store/drawer';
import { useQuery } from '@apollo/client';
import { ArrowUpSortIcon, NewClose } from '../../icons';
import GET_COMMENTS from '../../../../containers/data/getComments';
import Comments, { CommentType, ReactionType } from '../../../Comments';
import CommentInput from '../../../Comments/CommentInput';
import CommentCard from '../../../Comments/CommentCard';
import UPSERT_COMMENT from '../../../../containers/data/upsertComment';
import { getResponseMessages } from '../../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';
import GET_REACTIONS from '../../../../containers/data/getReactions';
import { setCollectionMessenger, setGroupMessenger } from '../../../../redux/action/messengersAction';
import { updateCollectionCommentCount } from '../../../../redux/action/collectionsLikesAndCommentsActions';
import { updateGroupCommentCount } from '../../../../redux/action/groupsLikesAndCommentsActions';
interface Proptypes {
  data: commentsDrawerType['data'];
  onClose: () => void;
}

let isUpdatingQuery = false;

export default function CommentsDrawer({
  data: { variables, commentsFor: { id, type } },
  onClose,
}: Proptypes) {
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateType) => state.user);
  const ref = useRef<HTMLDivElement>();
  const [mention, setMention] = useState<{
    userName: string;
    key: string;
  }>(null);

  const [currentSort, setCurrentSort] = useState<{
    sort_by: 'desc' | 'asc'
    sort_param: 'created_at' | 'updated_at'
  }>({
    sort_by: 'desc',
    sort_param: 'created_at'
  })

  
  const activeSortLabel = useMemo(() => {
    const {sort_by, sort_param} = currentSort;
    if(sort_by === 'asc') {
      return 'ascending'
    } else if(sort_by === 'desc') {
      return 'descending'
    }
  }, [currentSort]);
  
  const [commentsReactions, setCommentsReactions] = useState<
    Array<ReactionType>
  >([]);
  
  const [showMoreComments, setShowMoreComments] = useState(false);

  const [isCommenting, setIsCommenting] = useState(false);

  const { data, loading, updateQuery, client, fetchMore } = useQuery(
    GET_COMMENTS(),
    {
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        first: 10,
        ...currentSort,
      },
    }
  );

  const commentingUsers: CommentType['user'][] =
    data?.getComments?.data?.commenting_users ?? [];

  const commentsList: CommentType[] = useMemo(() => {
    const comments: CommentType[] = data?.getComments?.data?.comments ?? [];
    return comments.map((comment) => ({
      ...comment,
      user:
        commentingUsers.find((user) => user.user_id === comment.user_id) ??
        comment.user,
    }));
  }, [data]);
  
  const totalComments: number = data?.getComments?.data?.paginatorInfo?.total || 0;

  const closeHandler = () => {
    let dispatchFunction: typeof setGroupMessenger | typeof setCollectionMessenger;
    // const dispatchFunction = type === 'group' ? setGroupMessenger : setCollectionMessenger
    switch (type) {
      case 'collection':
        dispatchFunction = setCollectionMessenger
        break;
      case 'group':
        dispatchFunction = setGroupMessenger
        break;
      case 'scrap':
        break;
    
      default:
        break;
    }
    if(dispatchFunction) {
      dispatch(dispatchFunction({
        type: 'likes-and-comments',
        commentsCount: totalComments,
        data: {
          id: id.toString(),
        }
      }))
    }
    onClose();

  }

  const loadReactionsHandler = async (
    comment_ids: Array<CommentType['id']>
  ) => {
    const response = await client.query({
      query: GET_REACTIONS(),
      variables: {
        comment_ids,
        reactable_type: 3,
      },
    });
    const reactions: Array<ReactionType> =
      response?.data?.getReactions?.data || [];
    const orderedReactions: Array<ReactionType> = new Array(comment_ids.length);
    comment_ids.forEach((id, i) => {
      orderedReactions[i] = reactions.find((data) => data.id == id) ?? null;
    });
    return orderedReactions;
  };

  const loadMoreHandler = async (scrollToBottom = false, forceLoadMore = false) => {
    if(totalComments === commentsList.length && !forceLoadMore) return;
    if (!loading && !isUpdatingQuery) {
      isUpdatingQuery = true;
      const lastComment = commentsList[commentsList.length - 1];
      await fetchMore({
        variables: {
          search_after: lastComment?.created_at,
          tie_breaker_id: lastComment?.id,
          tie_breaker_param: 'id',
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          isUpdatingQuery = false;
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            getComments: {
              ...prev.getComments,
              data: {
                ...prev.getComments.data,
                comments: [
                  ...prev.getComments.data.comments,
                  ...fetchMoreResult.getComments.data.comments,
                ],
                commenting_users: [
                  ...prev.getComments.data.commenting_users,
                  ...fetchMoreResult.getComments.data.commenting_users,
                ],
              },
            },
          };
        },
      });
      if (scrollToBottom) {
        setTimeout(() => {
          ref?.current?.scroll({
            top: -ref?.current?.scrollHeight,
            behavior: 'smooth',
          });
        }, 0);
      }
    }
  };

  const reactionToggledHandler = (commentIndex: number, is_reacted: boolean) => {
    if (loading) return;
    const comment = commentsList[commentIndex]
    setCommentsReactions((old) =>
      old.map((reaction, i) => {
        if(i === commentIndex) {
          if(reaction) {
            return  {
              ...reaction,
              is_reacted
            }
          } 
          return {
            id: comment.id,
            is_reacted,
            reactable_type: 3,
          }
        }
        return reaction;
      })
    );
    updateQuery((prevData) => {
      return {
        ...prevData,
        getComments: {
          ...prevData?.getComments,
          data: {
            ...prevData?.getComments?.data,
            comments: prevData.getComments.data.comments.map((comment,i) => {
              if(i === commentIndex) {
                const currentReactionCount = comment.reaction_count || 0
                return {
                  ...comment,
                  reaction_count: is_reacted? currentReactionCount + 1 : currentReactionCount - 1
                }
              }
              return comment;
            }),
            
          },
        },
      };
    });
  };

  const scrapCommentHandler = async (text: string) => {
    setIsCommenting(true);
    try {
      setMention(null);
      updateQuery((prevData) => {
        return {
          ...(prevData || {}),
          getComments: {
            ...(prevData?.getComments || {}),
            data: {
              ...(prevData?.getComments?.data || {}),
              paginatorInfo: {
                ...(prevData?.getComments?.data?.paginatorInfo || {}),
                total: totalComments + 1,
              },
            },
          },
        };
      });

      const res = await client.mutate({
        mutation: UPSERT_COMMENT(),
        variables: {
          text,
          ...variables,
        },
      });
      const { isSuccess, error } = getResponseMessages(res.data.upsertComment);

      if (!isSuccess) throw new Error(error[0]);
      const comment = res.data.upsertComment.data
      if(activeSortLabel === 'ascending') {
        if (commentsList.length === totalComments) await loadMoreHandler(true, true);
      } else {
        setCommentsReactions((old) => {
          const currentList = old || [];
          if(activeSortLabel === 'descending') {
            return [null, ...currentList]
          } else {
            return [...currentList, null]
          }
        })
        updateQuery((prevData) => {
          return {
            ...(prevData || {}),
            getComments: {
              ...(prevData?.getComments || {}),
              data: {
                ...(prevData?.getComments?.data || {}),
                comments: [
                  comment,
                  ...(prevData?.getComments?.data?.comments || []),
                ],
                commenting_users: (function () {
                  const prevUsers: CommentType['user'][] =
                    prevData?.getComments?.data?.commenting_users || [];
                  const commentingCurrentUser = {
                    avatar: user.avatar,
                    display_name: user.display_name,
                    user_id: user.user_id,
                    user_name: user.userName,
                  };
                  if (
                    prevUsers.findIndex(
                      (data) => data.user_id == commentingCurrentUser.user_id
                    ) >= 0
                  ) {
                    return prevUsers;
                  } else return [...prevUsers, commentingCurrentUser]
                })(),
              },
            },
          };
        });
        setTimeout(() => {
          ref?.current?.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }, 0);
      }
      
    } catch (error) {
      console.error(error);
    }
    setIsCommenting(false);
  };

  const toggleSortHandler = () => {
    setCurrentSort(activeSortLabel === 'descending' ? {
      sort_by: 'asc',
      sort_param: 'created_at'
    } : {
      sort_by: 'desc',
      sort_param: 'created_at'
    } )
  }

  useEffect(() => {
    if (loading || !commentsList?.length) return;
    const comment_ids = commentsReactions.length
      ? commentsList
        .slice(commentsReactions.length)
        .map((comment) => comment.id)
      : commentsList.map((scrap) => scrap.id);
    loadReactionsHandler(comment_ids).then((data) => {
      setCommentsReactions(
        commentsReactions?.length ? [...commentsReactions, ...data] : data
      );
    });
  }, [commentsList, loading]);
  useEffect(() => {
    isUpdatingQuery = false;
  }, []);
  useEffect(() => {
    if(!loading && data && totalComments <= 7) setShowMoreComments(true);
    if(!loading && data) {
      const dispatchAction = type === 'collection' ? updateCollectionCommentCount : updateGroupCommentCount
      dispatch(dispatchAction({
        count: totalComments,
        id: +id
      }))
    }
  }, [loading, data])
  useEffect(() => {
    setCommentsReactions([])
  }, [currentSort])
  return (
    <div
      className={`comments-drawer${
        showMoreComments ? '' : ' comments-drawer--limited-comments'
      }`}
    >
      <header className="comments-drawer__header">
        <div className="comments-drawer__heading-wrapper">
          <h3 className="comments-drawer__heading">Comments</h3>
          <p className="comments-drawer__count">{totalComments}</p>
          
        </div>

        <div className="comments-drawer__header-actions">
          <button
            className={`comments-drawer__sort-button comments-drawer__sort-button--${activeSortLabel}`}
            onClick={toggleSortHandler}
          >
            <ArrowUpSortIcon />
          </button>
          <button onClick={closeHandler} className="comments-drawer__close-button">
            <NewClose />
          </button>
        </div>
      </header>
      <div className={`comments-drawer__body${user.user_id ? '' : ' comments-drawer__body--read-only'}`}>
        <Comments
          containerRef={ref}
          moreConfig={
            !showMoreComments && {
              maxCount: 7,
              onMoreClick: () => {
                setShowMoreComments(true);
              },
              totalCount: totalComments,
            }
          }
          onLoadMore={showMoreComments && loadMoreHandler}
          comments={commentsList}
        >
          {(comment, commentIndex) => {
            const commentReaction = commentsReactions[commentIndex];
            return (
              <CommentCard
                onReactionToggled={reactionToggledHandler.bind(
                  null,
                  commentIndex
                )}
                onReplyClick={() => {
                  setMention({
                    userName: comment.user.user_name,
                    key: (Math.random() * 1000).toString(),
                  });
                }}
                comment={{
                  ...comment,
                  reaction: commentReaction,
                }}
              />
            )
          }}
        </Comments>
        {(showMoreComments && user.user_id) && (
          <CommentInput
            disabled={isCommenting}
            mention={mention}
            key={totalComments}
            submitLabel={totalComments > 0 ? "Reply" : "Save"}
            onCancel={closeHandler}
            onSubmit={scrapCommentHandler}
          />
        )}
      </div>
    </div>
  );
}
