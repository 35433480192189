import { ShareSettingsProptypes } from "../../components/Cards/CardComponents/CardSettings";

const initialState: ShareSettingsProptypes = {
  scrap: null,
  settings: null,
  pointer: null
}


export const shareSettingsOption = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_SCRAP_SETTINGS":
      return action.payload
    default:
      return state
  }
}