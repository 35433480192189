import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import {
  OnboardingPopStyles,
  OnboardingPopBaseStepsStyles,
} from '../OnboardingHelpers';

interface OnboardingProps {
  run?: boolean;
}

const OnboardingNewScrap = ({ run }: OnboardingProps) => {
  const [runState] = useState(run || false);
  const [activeStep, setActiveStep] = useState(1);

  useEffect(() => {
    // rerun the component
  }, [run]);

  // check if tourEnd is set in local storage
  const tourEnd = localStorage.getItem('tourEnd');

  let inMobile = null;
  if (typeof window !== 'undefined') {
    const width = window.innerWidth;
    inMobile = width < 768;
  }

  if (tourEnd || inMobile) {
    return null;
  }

  function renderContentWithStep(content: Array<string>, step: number) {
    return (
      <div>
        {content.map((block, index) => {
          return <p key={`content-${index}`}>{block}</p>;
        })}
        <span className="onboard__step-count">{step} of 7</span>
      </div>
    );
  }

  const stepsValue = {
    run: runState,
    steps: [
      {
        target: '.onboard-scrap',
        title: 'Congrats, you created your first Scrap.',
        content: renderContentWithStep(
          ['Now here’s a quick tour of your dashboard.'],
          activeStep
        ),
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
        locale: {
          close: 'Next',
        },
      },
      {
        target: '.onboard-scrap .scrap-card__footer-triple-dot-button',
        content: renderContentWithStep(
          ['Use the 3-dot menu to edit Scraps you created.'],
          activeStep
        ),
        disableBeacon: true,
        placement: 'bottom',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
        locale: {
          close: 'Next',
        },
      },
      {
        target: '.onboard-scrap .scrap-card__footer',
        content: renderContentWithStep(
          ['In the footer you can easily share, like, and save Scraps.'],
          activeStep
        ),
        disableBeacon: true,
        placement: 'top',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
        locale: {
          close: 'Next',
        },
        spotlightClicks: false,
      },
      {
        target: '.onboard__group--1',
        content: renderContentWithStep(
          ['Keep yourself organized using Collections, Saved Scraps and Tags.'],
          activeStep
        ),
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        styles: {
          ...OnboardingPopBaseStepsStyles,
        },
        locale: {
          close: 'Next',
        },
        spotlightClicks: false,
      },
      {
        target: '.onboard__group--2',
        content: renderContentWithStep(
          ['Add Contacts to share Scraps privately with others.','Your Share history will appear here.'],
          activeStep
        ),
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        locale: {
          close: 'Next',
        },
        styles: OnboardingPopBaseStepsStyles,
        spotlightClicks: false,
      },
      {
        target: '.side-nav-dropdown__list__uncategorized',
        content: renderContentWithStep(
          ['View all Scraps you created in My Scraps.'],
          activeStep
        ),
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        locale: {
          close: 'Next',
        },
        styles: OnboardingPopBaseStepsStyles,
        spotlightClicks: false,
      },
      {
        target: '.examples',
        content: renderContentWithStep(
          ['Click here to see sample Scraps and Collections.'],
          activeStep
        ),
        disableBeacon: true,
        placement: 'bottom',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
        locale: {
          close: 'Finish',
        },
        spotlightClicks: false,
      },
    ],
  };

  return (
    <div>
      {/* there is some issue in the libraries types with certain properties,
          so we need to ignore the typescript error */}
      <Joyride
        // @ts-ignore
        steps={stepsValue.steps}
        run={runState}
        disableCloseOnEsck
        disableOverlayClose
        showProgress
        hideCloseButton
        hideBackButton
        locale={{
          close: 'Close',
          last: 'End tour',
        }}
        styles={OnboardingPopStyles}
        callback={(step) => {
          const stateStep = step.index + 1;
          setActiveStep(stateStep);
          if (step.status === 'finished') {
            localStorage.setItem('tourEnd', 'true');
            return;
          }
        }}
      ></Joyride>
    </div>
  );
};

export default OnboardingNewScrap;
