import React, { Dispatch, SetStateAction } from 'react';
import ScrapSavedLoader from './ScrapSavedLoader';
import ScrapSavedFooter from './ScrapSavedFooter';
import ScrapSavedHeader from './ScrapSavedHeader';
import ScrapSavedGallery from './ScrapSavedGallery';
import ScrapSavedPage from './ScrapSavedPage';
import ScrapSavedFiles from './ScrapSavedFiles';
import ScrapSavedWeb from './ScrapSavedWeb';
import './styles.css';
import AddCollectionsHelper from '../Global/AddCollections';
import AddTagsHelper from '../Global/AddTags';
import { tagType } from '../../types/tags';
import { collectionType } from '../../types/collections';
import { entryTypes } from '../../types/feed';
import ScrapAddNotesGroup from '../Global/InputsGroup/ScrapAddNotesGroup';
import { useDispatch } from 'react-redux';
import { setReloadSideNavFilters } from '../../redux/action/utils';
import { parseTagFromSlug } from '../../helpers';
interface Proptypes {
  scrapType?: string;
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  unsavedTags: tagType[];
  setUnsavedTags: Dispatch<SetStateAction<tagType[]>>;
  unsavedCollections: collectionType[];
  setUnsavedCollections: Dispatch<SetStateAction<collectionType[]>>;
  disabled?: boolean;
  title: string;
  onTitleChange: (val: string) => void;
  description: string;
  onDescriptionChange: (val: string) => void;
  isNotesDefaultOpened?: boolean;
}
const ScrapSaved = ({
  scrapType,
  children,
  unsavedTags,
  setUnsavedCollections,
  setUnsavedTags,
  unsavedCollections,
  disabled,
  title,
  onTitleChange,
  description,
  onDescriptionChange,
  isNotesDefaultOpened,
}: Proptypes) => {
  const hideTitle = ['product', 'page', 'social'].includes(scrapType);
  const dispatch = useDispatch();
  const saveHandler = (data?: Partial<entryTypes>) => {
    if (data.tags) {
      const { tags } = data;
      const tagsId = tags.map((el) => parseTagFromSlug(el.slug).id);
      const filtered = tags.filter(
        ({ slug }, index) => !tagsId.includes(parseTagFromSlug(slug).id, index + 1)
      );
      dispatch(setReloadSideNavFilters(true));
      setUnsavedTags(filtered);
    }

    if (data.collections) {
      const { collections } = data;
      const collectionsId = collections.map((el) => el.id);
      const filtered = collections.filter(
        ({ id }, index) => !collectionsId.includes(id, index + 1)
      );
      setUnsavedCollections(filtered);
    }
  };

  return (
    <div
      className={`scrap-saved${
        disabled ? ' scrap-saved--disabled' : ''
      } scrap-saved--${scrapType}`}
    >
      <div className="scrap-saved__distinct-content">{children}</div>

      <div className="scrap-saved__wrapper">
        <ScrapAddNotesGroup
          hideTitle={hideTitle}
          isDefaultOpened={isNotesDefaultOpened}
          className="scrap-saved__add-notes"
          title={title}
          description={description}
          onDescriptionChange={onDescriptionChange}
          onTitleChange={onTitleChange}
        />
        <div className="scrap-saved__collection-tags-holder">
          <div className="scrap-saved__add-collections create-scrap__tags-wrapper">
            <AddCollectionsHelper
              selectedCollections={unsavedCollections}
              onSave={saveHandler}
            />
          </div>
          <div className="scrap-saved__add-tags create-scrap__tags-wrapper">
            <AddTagsHelper
              onSave={saveHandler}
              selectedTags={unsavedTags}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  ScrapSaved as default,
  ScrapSavedLoader,
  ScrapSavedFooter,
  ScrapSavedHeader,
  ScrapSavedFiles,
  ScrapSavedGallery,
  ScrapSavedPage,
  ScrapSavedWeb,
};
