import gql from 'graphql-tag';

const GET_TAGS = gql`
  query GetTags {
    tags {
      data {
        id
        title
      }
    }
  }
`;

const GET_COLLECTIONS = gql`
  {
    collections {
      data {
        collections {
          id
          title
          slug
          state
          collaborators{
            username
          }
        }
      }
    }
  }
`;

export { GET_TAGS, GET_COLLECTIONS };
