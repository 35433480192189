import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { useMutation, gql } from '@apollo/client';

import { AccountRecovery } from '../components/Account';
import { useLocation, useHistory } from 'react-router-dom';
import PublicPageWrapper from '../components/Global/PublicPageWrapper';

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $token: String
    $password: String
    $password_confirmation: String!
  ) {
    resetPassword(
      token: $token
      password: $password
      password_confirmation: $password_confirmation
    ) {
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

const AccountRecoveryWrapper = (props) => {
  const { search } = useLocation();
  const history = useHistory();
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION);
  const [token, setToken] = useState(null);
  async function submitHandler(
    password: string,
    onError: (errorMessage: string) => void,
    onSuccess: Function
  ) {
    const variables = {
      token,
      password: password,
      password_confirmation: password,
    };
    const generalErrorMessage = 'Something went wrong, please try again';
    try {
      const response = await resetPassword({ variables });
      if (response?.data?.resetPassword) {
        const data = response.data.resetPassword;
        if (data.status === 2 || data.messages.error.length > 0) {
          onError(data.messages.error[0] || generalErrorMessage);
        } else onSuccess();
      } else onError(generalErrorMessage);
    } catch (error) {
      //
      onError(generalErrorMessage);
    }
  }
  useEffect(() => {
    const urlParamValues = qs.parse(search.substring(1));
    if (urlParamValues && urlParamValues.token) {
      setToken(urlParamValues.token);
    } else history.replace('/login');
  }, [search]);
  return (
    <PublicPageWrapper>
      <AccountRecovery onSubmit={submitHandler} />
    </PublicPageWrapper>
  );
};

export default AccountRecoveryWrapper;
