import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';

export const INVITE_USER = gql`
  mutation InviteUser($email: String, $message: String) {
    inviteUser(email: $email, message: $message) {
      ${defaultResponse}
    }
  }
`;
