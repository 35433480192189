import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['group_id', 'Int!'],
]);

export default function LEAVE_GROUP() {
  return gql`
    mutation LeaveGroup(${parametersTypeString}) {
      leaveGroup(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}