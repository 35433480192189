import React, { useEffect, useState, useContext, useRef } from 'react';

import ReloadContext from '../../helpers/contexts/reloadContext';
import { useSelector, useDispatch } from 'react-redux';
import Scraps from '../data/scraps';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MyScraps from '../../components/MyScraps';
import ScrapCard from '../../components/ScrapCard';
import { ReduxStateType } from '../../redux/store';
import ScrapControls from '../../components/Global/ScrapControls';
import ScrapControlsSelect from '../../components/Global/ScrapControls/ScrapControlsSelect';
import ScrapControlsSort from '../../components/Global/ScrapControls/ScrapControlsSort';
import { updateMyScrapsPageSortSettings } from '../../redux/action/viewSettingsActions';
import { UPDATE_VIEW_SETTINGS } from '../data/updateViewSettings';
import { sortSettings } from '../../types/viewSettings';
import parseUrlParams from '../../helpers/parseUrlParams';
import {
  changeBatchEditMode,
  clearBatchEditScraps,
} from '../../redux/action/batchEditAction';
import { BatchEditType } from '../../types/batchEdit';
import {
  setBackUrl,
  setDeleteScrapPopup,
  setScrapPopup,
  setIsMainPageLoading, setNewScrapPopup, setReloadSideNavCollections
} from "../../redux/action/utils";
import updateEntryCache from '../../helpers/cacheHelpers/updateEntry';
import { scrapType } from '../../types/scrapType';
import { setOneScrapAction } from '../../redux/action/onScrapActions';
import { entryTypes } from '../../types/feed';
import { getResponseMessages, returnScrapSlug, slugifyString } from "../../helpers";
import SEOMeta from '../../components/Meta/SEOMeta';
import { ReactionType } from '../../components/Comments';
import GET_REACTIONS from '../data/getReactions';
import { OneScrapActionType } from '../../redux/store/oneScrap';
import MATCHED_BOOKMARKS from '../data/matchedBookmarks';
import { updateScrapsLikesAndComments } from '../../redux/action/scrapsLikesAndCommentsActions';
import OnboardingNewScrap from '../../components/Onboarding/components/OnboardingNewScrap';
import OnboardingInstruction from '../../components/Onboarding/components/OnboardingInstruction';
import Onboarding from "../../components/Onboarding";
import { AIIcon, ArchiveIcon, FileIcon, GalleryLogo, NotesIcon, WebPageIcon } from "../../components/Global/icons";
import CreateCollectionModal from "../../components/Collections/components/CreateCollectionModal";
import { parseTryCatchError } from "../../helpers/parseTryCatchError";
import { UPSERT_COLLECTION } from "../data/collection";
import Chat from "../../components/Chat";

const numberOfRecords = 30;

interface Proptypes {
  uncategorized?: boolean;
  hideSkeletion?: boolean;
}

interface SortOptionType {
  sort_param: string;
  sort_by: string;
  label: string;
  value: sortSettings['sort_param'];
}

const sortOptions: Array<SortOptionType> = [
  {
    sort_param: '',
    sort_by: '',
    label: 'Last edited',
    value: 'Last Edited',
  },
  {
    sort_param: 'created_at',
    sort_by: 'desc',
    label: 'Newest',
    value: 'Last Created',
  },
];
function parseSortingParams({ sort_by, sort_param }: SortOptionType) {
  return {
    sort_by,
    sort_param,
  };
}
const MyScrapsContainer = (props: Proptypes) => {
  const { uncategorized, hideSkeletion } = props
  const { push } = useHistory();

  const oneScrapAction = useSelector(
    (state: ReduxStateType) => state.oneScrapAction
  );
  const { reload, setReload } = useContext(ReloadContext);
  const dispatch = useDispatch();
  const { mode: batchEditMode }: BatchEditType = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.batchEdit
  );
  const [upsertViewSetting] = useMutation(UPDATE_VIEW_SETTINGS);
  const expanded = useSelector((state: ReduxStateType) => state.utils.expanded);
  const {
    loading: loadingViewSettings,
    saving: savingViewSettings,
    myScrapsPageViewSettings,
  } = useSelector((state: ReduxStateType) => state.viewSettings);
  const backUrl = useSelector((state: ReduxStateType) => state.utils.backUrl);
  const compactState = useSelector(
    (state: ReduxStateType) => state.user?.compact
  );

  const { search, state } = useLocation<{ dontReload?: boolean }>();
  const [lastScrap, setLastScrap] = useState<scrapType>(null);
  // const [scrapsReactions, setScrapReactions] = useState<ReactionType[]>([])
  const scrapsLikesRef = useRef<ReactionType[]>([]);
  const [bookmarkedIds, setBookmarkedIds] = useState<Array<scrapType['id']>>([])
  const [selectedSortOption, setSelectedSortOption] = useState<SortOptionType>(
    sortOptions[0]
  );
  const [masonryKey, setMasonryKey] = useState(
    (Math.random() * 1000).toString()
  );

  const [event, setEvent] = useState<{
    type: OneScrapActionType['type'];
    scrap: scrapType | entryTypes;
  }>();
  const [disablePaste, setDisablePaste] = useState(false);
  const [hideOnboarding, setHideOnboarding] = useState(false);
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [creatingCollection, setCreatingCollection ] = useState(false);

  const [upsertCollection] = useMutation(UPSERT_COLLECTION());

  const parsedURLParams = parseUrlParams(
    typeof backUrl === 'string' ? backUrl : search
  );

  if (parsedURLParams?.untagged) {
    // @ts-ignore
    parsedURLParams.untagged =
      parsedURLParams.untagged === 'true';
  }
  const extraVariables = {};

  // Parse tag IDs from url params and make new array of tags in variables object
  if (parsedURLParams.tags) {
    parsedURLParams.tags = (parsedURLParams.tags as string).split(',').map(item => ({
      slug: item
    }))
  }
  const variables = {
    first: numberOfRecords,
    ...parseSortingParams(selectedSortOption),
    tags: null,
    untagged: null,
    uncategorized: !!uncategorized,
    search_after: lastScrap ? lastScrap[selectedSortOption.label === 'Last edited' ? 'updated_at' : 'created_at'] : null,
    tie_breaker_id: lastScrap && lastScrap?.id,
    ...parsedURLParams,
    ...extraVariables,
  };

  const { data: newScrapsData, client, refetch, fetchMore, loading } = useQuery(
    Scraps(),
    {
      variables,
      fetchPolicy: 'cache-and-network'
    },
  );

  let newScraps: scrapType[] = newScrapsData?.scraps?.data || [];

  const cache = client.cache;
  const totalScrapsCount = newScrapsData?.scraps?.paginatorInfo?.total;

  function loadMoreHandler() {
    if (newScraps.length) setLastScrap(newScraps[newScraps.length - 1])
  }

  const loadBookmarksHandler = async (scrap_ids: Array<scrapType['id']>) => {
    try {
      const response = await client.query({
        query: MATCHED_BOOKMARKS(),
        variables: { scrap_ids }
      })
      const { error, isSuccess } = getResponseMessages(response?.data?.matchedBookmarks)
      if (!isSuccess) throw new Error(error[0])
      const scrapIdsFromResponse: Array<scrapType['id']> = (response?.data?.matchedBookmarks?.data || []).map(item => item?.scrap_id);
      const orderedScrapIds: Array<scrapType['id']> = new Array(scrap_ids.length)
      scrap_ids.forEach((id, i) => {
        orderedScrapIds[i] = scrapIdsFromResponse.find(data => data == id) ?? null;
      })
      return orderedScrapIds;
    } catch (error) {

      return []
    }

  }

  const loadReactionsHandler = async (scrap_ids: Array<scrapType['id']>) => {
    const response = await client.query({
      query: GET_REACTIONS(), variables: {
        scrap_ids,
        reactable_type: 1
      }
    })
    const scrapsReactions: Array<ReactionType> = response.data.getReactions.data || [];

    // dispatch(
    //   updateScrapLikes(scrapsReactions.map(reaction => ({
    //     id: reaction.id,
    //     is_reacted: reaction.is_reacted,
    //   })))
    // )

    const orderedScrapsReactions: Array<ReactionType> = new Array(scrap_ids.length)
    scrap_ids.forEach((id, i) => {
      orderedScrapsReactions[i] = scrapsReactions.find(data => data.id === id) ?? null
    })

    return orderedScrapsReactions;
  }

  async function saveSortSettingsHandler(value: sortSettings['sort_param']) {
    try {
      const mySettings = { ...myScrapsPageViewSettings };
      mySettings.sort_param = value;
      dispatch(updateMyScrapsPageSortSettings(value));
      await upsertViewSetting({
        variables: {
          type: 'myScrapsPageViewSettings',
          meta: JSON.stringify(JSON.stringify(mySettings)),
        },
      });
    } catch (error) {
      //
    }
  }

  async function handleSort(val: string) {
    const sortOption = sortOptions.find((item) => item.label === val);
    saveSortSettingsHandler(sortOption.value);
    setSelectedSortOption(sortOption);
    setLastScrap(null)
  }

  async function reactionToggledHandler(scrap_id: number, scrapIndex: number, isReacted: boolean) {
    // const scrap = newScraps[scrapIndex];
    // const currentReactionCount = scrap.reaction_count || 0;
    const scrapLikeData = scrapsLikesRef.current[scrapIndex];
    if (scrapLikeData) {
      scrapLikeData.is_reacted = isReacted
    } else {
      scrapsLikesRef.current[scrapIndex] = {
        is_reacted: isReacted,
        id: scrap_id,
        reactable_type: 1
      }
    }
  }

  const bookmarkToggledHandler = (scrapIndex: number, action: 'save' | 'unsave') => {
    const scrap = newScraps[scrapIndex]
    setBookmarkedIds(old => old.map((id, i) => {
      if (i === scrapIndex) return action === 'save' ? scrap.id : null;
      return id
    }))
  }

  function renderMyScrapControls() {
    return (
      <ScrapControls scrapCount={totalScrapsCount}>
        {/* uncomment it to add back bulk edit functionality */}
        {/* <ScrapControlsSelect
          onClick={() => {
            dispatch(changeBatchEditMode(!batchEditMode));
            dispatch(clearBatchEditScraps());
          }}
        /> */}
        <ScrapControlsSort
          activeOption={selectedSortOption.label || 'Newest'}
          onOptionClick={(val) => {
            handleSort(val);
          }}
          onClick={() => {
          }}
          options={sortOptions.map((option) => option.label)}
        />
        {/* Removed scrap controls for view */}
        {/* <ScrapControlsView
          key="scrap-controls-view"
          layout={{
            active: myScrapsPageViewSettings.viewLayout,
            onClick: layoutViewOptionClickHandler,
            options: ['Gallery', 'Story'],
          }}
          size={{
            active: scrapLayoutSetnewtings.size,
            onClick: sizeViewOptionClickHandler,
            options: ['Default', 'Large'],
            disabledOptions:
              myScrapsPageViewSettings.viewLayout === 'Story'
                ? ['Large']
                : null,
          }}
        /> */}
      </ScrapControls>
    );
  }

  const msKey = JSON.stringify({
    ...variables,
    batchEditMode,
    expanded,
    totalScrapsCount,
    search_after: null,
    tie_breaker_id: null,
  });

  //  USEEFFECT FOR PREVENTING FETCHING SCRAPS WHEN USER HAS APPLIED FILTER AND IN SCRAP EDIT POPUP
  useEffect(() => {
    if (typeof backUrl !== 'string' && !state?.dontReload) {
      setMasonryKey((Math.random() * 1000).toString());
      setLastScrap(null)
    }
    state?.dontReload && window.history.replaceState({}, document.title);
    scrapsLikesRef.current = [];
  }, [search]);

  // This useEffect takes care of fetching scraps reactions
  useEffect(() => {
    if (loading || !newScraps?.length) return;
    const reactionScrapIdsToFetch = scrapsLikesRef?.current?.length
      ? newScraps.slice(scrapsLikesRef.current.length).map((scrap) => scrap.id)
      : newScraps.map((scrap) => scrap.id);
    const bookmarkIdsToFetch = bookmarkedIds.length
      ? newScraps.slice(bookmarkedIds.length).map((scrap) => scrap.id)
      : newScraps.map((scrap) => scrap.id);
    if (reactionScrapIdsToFetch.length) {
      loadReactionsHandler(reactionScrapIdsToFetch).then((data) => {
        scrapsLikesRef.current.push(...data)
        dispatch(updateScrapsLikesAndComments(data.filter(item => !!item).map(reaction => {
          const scrap = newScraps.find(data => data.id == reaction.id)
          return {
            scrapId: reaction.id,
            data: {
              isLiked: reaction.is_reacted,
              likesCount: scrap.reaction_count
            }
          }
        })))
      });

    }
    if (bookmarkIdsToFetch.length) {
      loadBookmarksHandler(bookmarkIdsToFetch).then((data) => {
        setBookmarkedIds(old => old.length ? [...old, ...data] : data)
      })
    }

  }, [loading, newScraps]);

  useEffect(() => {
    if (reload) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'scraps' });
      refetch({ search_after: null });
      scrapsLikesRef.current = []
      setBookmarkedIds([]);
      setLastScrap(null)
      setReload(false);
    }
  }, [reload]);
  useEffect(() => {

    if (event) {
      const { scrap, type } = event;
      const scrapIndex = newScraps.findIndex(scrapData => scrapData.id == scrap.id);
      switch (type) {
        case 'delete':
          scrapsLikesRef.current.splice(scrapIndex, 1);
          setBookmarkedIds(old => old.filter((id, i) => i !== scrapIndex))
          cache.modify({
            id: 'ROOT_QUERY',
            fields: {
              scraps(prevCache, { DELETE }) {
                return {
                  ...prevCache,
                  paginatorInfo: {
                    ...prevCache.paginatorInfo,
                    total: prevCache.paginatorInfo.total - 1,
                  },
                  data: prevCache.data.filter((item) => {
                    // @ts-ignore
                    return item.__ref !== cache.identify({ ...scrap, __typename: "Scrap" });
                  }),
                  total: prevCache.total - 1,
                };
              },
            },
          });
          break;
        case 'edit':
          updateEntryCache(cache, scrap, true);
          break;

        default:
          break;
      }
      setEvent(null);
    }
  }, [event]);
  useEffect(() => {
    if (oneScrapAction.scrap && oneScrapAction.type) {
      const { scrap: actionScrap, type: actionType } = oneScrapAction;
      let type = actionType;
      // todo: implement for collections delete
      if (actionScrap?.collections?.length && uncategorized) type = 'delete';
      setEvent({ type, scrap: actionScrap as scrapType });
      dispatch(setOneScrapAction(null));
    }
  }, [oneScrapAction]);
  useEffect(() => {
    dispatch(setIsMainPageLoading(loading && !newScraps.length));
  }, [newScrapsData]);

  // check if localstorage has the key to show the onboarding
  useEffect(() => {
    if (localStorage.getItem('hideOnboarding')) {
      setHideOnboarding(true);
    }
  }, [reload]);

  if (newScraps.length > 0 && !hideOnboarding) {
    const blankScrap = {
      "id": 0,
      "user_id": 0,
      "user_name": null,
      "display_name": null,
      "avatar": null,
      "title": null,
      "desc": null,
      "status": 1,
      "state": 2,
      "private_key": "Eed3bMBZQdu0hYR0sOJ0",
      "service": null,
      "meta": null,
      "meta_title": null,
      "meta_desc": null,
      "price": null,
      "created_at": "2024-02-19 10:47:18",
      "updated_at": "2024-02-19 10:47:22",
      "url": null,
      "domain": null,
      "favicon": null,
      "original_favicon": null,
      "annotations": null,
      "annotations_count": null,
      "documents_count": 0,
      "documents": null,
      "audios_count": 0,
      "audios": null,
      "videos_count": 0,
      "videos": null,
      "images_count": 0,
      "images": [],
      "tags": null,
      "collections": null,
      "reaction_count": 0,
      "areFilesExpanded": false,
      "isUgcExpanded": false,
      "isMetaExpanded": false,
      "areAnnotationsExpanded": false,
      "isAnnotationExpanded": false,
      "isTagsExpanded": false,
      "isCollectionsExpanded": false
    };
    newScraps = [blankScrap, ...newScraps]
  }


  // Function handler for creating new collection
  const createCollectionHandler = async (variables: {
    title: string;
    desc?: string;
    cover_image?: string;
    view_type?: number;
  }) => {
    setCreatingCollection(true);
    try {
      const collectionData = await upsertCollection({
        variables,
      });
      setCreatingCollection(false);
      setShowCreateCollection(false);

      push(
        `/c/${slugifyString(
          collectionData.data.upsertCollection.data.title,
          false
        )}/${collectionData.data.upsertCollection.data.id}`
      );
      setCreatingCollection(false);
      dispatch(setReloadSideNavCollections(true));
    } catch (error) {
      setCreatingCollection(false);
      throw new Error(parseTryCatchError(error));
    }

  };

  // Reload the component so that the new scrap is added to the list
  const reloadComponent = () => {
    setReload(true);
  };

  const createScrapAction = (type) => {
    setDisablePaste(true);
    dispatch(setNewScrapPopup(type))
  }

  const renderScrapActions = () => {
    const scrapActions = [
      {
        label: 'Scrap Note',
        action: { type: 'Note' },
        icon: <NotesIcon />,
      },
      {
        label: 'Scrap Image(s)',
        action: { type: 'Images' },
        icon: <GalleryLogo />,
      },
      {
        label: 'Scrap File(s)',
        action: { type: 'File' },
        icon: <FileIcon />,
      },
      {
        label: 'Scrap Web Page',
        action: { type: 'Web' },
        icon: <WebPageIcon />,
      },
      {
        label: 'Create Collection',
        action: { type: 'collection' },
        icon: <ArchiveIcon />
      },
      {
        label: 'See Example Scraps',
        action: { type: 'example' },
      },
    ]

    return scrapActions.map((action, i) => (
      <div key={i} className="my-scraps__action">
        <button
          onClick={() => {
            if (action.action.type === 'collection') {
              setShowCreateCollection(true);
              return;
            }
            if (action.action.type === 'example') {
              push('/scrappi');
              return;
            }
            createScrapAction(action.action)
          }}
          className={`my-scraps__action-btn ${action.action.type === 'example' ? 'examples' : ''}`}
        >
          {
            action.icon && <span className="my-scraps__action-btn__icon">{action.icon}</span>
          }
          <span className="my-scraps__action-btn__label">{action.label}</span>
        </button>
      </div>
    ));
  }

  const header = (<header className="my-scraps__actions">
    {renderScrapActions()}
    {/*<Link to={{ pathname: '/smart-organize', state: { scrapList: newScraps } }} className="my-scraps__action-btn">*/}
    {/*  <span className="my-scraps__action-organize">*/}
    {/*    <AIIcon /> Organize Scraps*/}
    {/*  </span>*/}
    {/*</Link>*/}

    <CreateCollectionModal
      isCreating={creatingCollection}
      onCreate={createCollectionHandler}
      controlled={{
        open: showCreateCollection,
        setOpen: setShowCreateCollection,
      }}
    />
  </header>);

  const isServer = typeof window === 'undefined';

  return (
    <>
      {uncategorized && <SEOMeta title='Scrappi | Uncategorized Scraps' />}

      {!isServer && (<Chat />)}

      <MyScraps
        backUrl={backUrl}
        viewSettingsLoading={loadingViewSettings || savingViewSettings}
        scraps={newScraps}
        onLoadMore={loadMoreHandler}
        processing={loading || typeof backUrl === 'string'}
        masonryKey={msKey + masonryKey}
        isCompact={compactState}
        uncategorized={uncategorized}
        renderMyScrapControls={newScraps.length ? renderMyScrapControls : null}
        viewSettings={myScrapsPageViewSettings}
        scrapSize={'Large'}
        hideSkeletion={hideSkeletion}
        reloadScraps={reloadComponent}
        disablePaste={disablePaste}
        header={header}
      >
        {(scrap, i, width) => {
          if (!scrap.id) {
            return (
              <Onboarding
                type="instruction"
                hasScraps
                reloadScraps={() => {
                  reloadComponent();
                }}
              />
            )
          }

          return (
            <ScrapCard
              onBookmarkToggled={bookmarkToggledHandler.bind(null, i)}
              isBookmarked={!!bookmarkedIds[i]}
              onLikeToggled={reactionToggledHandler.bind(null, +scrap.id, i)}
              scrap={scrap as scrapType}
              onDeleteClick={() => {
                dispatch(
                  setDeleteScrapPopup({
                    scrap: scrap as scrapType,
                    onComplete: (scrap) => {
                      setEvent({ type: 'delete', scrap });
                      dispatch(setDeleteScrapPopup(null));
                    },
                  })
                );
              }}
              onboardingClass={i === 1}
              onEditClick={(scrap, scrollToBottom?: boolean) => {

                if (uncategorized) {
                  dispatch(
                    setScrapPopup({
                      type: 'id',
                      defaultEdit: true,
                      data: scrap.id,
                      scrollToBottom,
                    })
                  );
                } else {
                  dispatch(setBackUrl(search, scrollToBottom));
                  push(`/scrap/${returnScrapSlug(scrap)}/edit`, {
                    dontReload: true,
                  });
                }
              }}
              width={width}
            />
          )
        }}
      </MyScraps>

      {/*
        only show the onboarding when there is a Scrap
        and hide don't show it when the user has a few Scraps
      */}
      {(!loading && (newScraps.length >= 2 && newScraps.length <= 4)) && (
        <Onboarding type={'newScrap'} runOnboarding={true} />
      )}
    </>
  );
};

export default MyScrapsContainer;