import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const NewTag = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5833 8.83317L9.33331 1.4165C8.74997 0.833171 7.99997 0.583171 7.24997 0.666504L3.5833 0.999837C2.41664 1.1665 1.49997 1.99984 1.24997 3.1665L0.666638 6.58317C0.499972 7.4165 0.749972 8.24984 1.41664 8.9165L8.99997 16.5832C9.99997 17.5832 11.6666 17.5832 12.6666 16.5832L16.5833 12.5832C17.5833 11.4998 17.5833 9.83317 16.5833 8.83317ZM5.66664 7.58317C4.49997 7.58317 3.5833 6.6665 3.5833 5.4165C3.5833 4.1665 4.49997 3.33317 5.66664 3.33317C6.8333 3.33317 7.83331 4.24984 7.83331 5.4165C7.83331 6.58317 6.8333 7.58317 5.66664 7.58317Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default NewTag;
