import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int'],
  ['collection_id', 'Int'],
  ['comment_id', 'Int'],
  ['reactable_type', 'Int'],
  ['reaction_type', 'Int = 1'],
]);
export default function ADD_REACTION() {
  return gql`
    mutation AddReaction(${parametersTypeString}) {
      addReaction(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}
