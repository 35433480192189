import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['first', 'Int'],
  ['page', 'Int'],
  ['text', 'String'],
]);

export default function GET_RELEVANT_CONTACT_AND_GROUP_LIST(responseString = '') {
  return gql`
  query GetRelevantContactAndGroupList(${parametersTypeString}){
    getRelevantContactAndGroupList(${parametersAssignString}){
      ${defaultResponse}
      paginatorInfo {
        total
      }
      data {
        id
        title
        user_id
        nickname
        updated_at
        type
        owner_avatar
        owner_display_name
        owner_user_name
        state
        members {
          display_name
          user_name
          avatar
        }
        ${responseString}
      }
    }
  } 
  `;
}
