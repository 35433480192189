import React, { Fragment, useEffect, useState } from "react";
import { filterItemType } from '../../../containers/SideNavContainer';
import * as config from '../../../settings.json';

import './styles.css';

interface Proptypes {
  children: (filterItem: filterItemType) => React.ReactChild;
  list: filterItemType[];
  disabled?: boolean;
  showApplyFilters?: boolean;
  showCancelFilters?: boolean;
  onApplyFiltersClick: (type: 'cancel' | 'submit') => void;
}

const SideNavWrapper = ({
  children,
  list,
  disabled,
  showApplyFilters,
  onApplyFiltersClick,
  showCancelFilters,
}: Proptypes) => {
  const [ showDownload, setShowDownload ] = useState(false);
  const currentDate = new Date();
  const showApplyContainer = showApplyFilters || showCancelFilters;

  useEffect(() => {
    // if the local storage has the key hideOnboarding, then show the download extension
    if (localStorage.getItem('hideOnboarding')) {
      setShowDownload(true);
    }
  }, []);

  return (
    <div
      className={`side-nav-wrapper custom-scroll__body${
        disabled ? ' side-nav-wrapper--disabled' : ''
      }${showApplyFilters ? ' side-nav-wrapper--apply' : ''}`}
    >
      <ul className="side-nav-list">
        {showApplyContainer && (
          <li className="side-nav-wrapper__apply-filters">
            <button
              onClick={(e) => {
                onApplyFiltersClick('cancel');
              }}
              className="side-nav-wrapper__apply-filters-cancel button button__outline btn-action"
            >
              Cancel
            </button>
            <button
              onClick={(e) => {
                onApplyFiltersClick('submit');
              }}
              className="side-nav-wrapper__apply-filters-submit button button__primary btn-action"
            >
              Apply
            </button>
          </li>
        )}
        {list.map((item, i) => (
          <Fragment key={i}>{children(item)}</Fragment>
        ))}

        {/* TODO: there are just for onboarding can be removed, conditionally. */}
        <div className="onboard__group onboard__group--1"></div>
        <div className="onboard__group onboard__group--2"></div>
      </ul>
      <div className="side-nav-wrapper__policy">
        <img
          className="side-nav-wrapper__logo"
          src="/dashboard/img/Group.svg"
          alt="scrappi"
        />
        <div className="side-nav-wrapper__policy-content">
          {showDownload && (
            <div className="side-nav__download-extension">
              <a
                className="create-scrap-nav-icon__add hoverState__with-white-text"
                href={config.chromeExtensionStoreUrl || '#'}
                target="_blank"
              >
                <img
                  className="side-nav__download-extension--logo"
                  src="/dashboard/img/logo-without-text.svg"
                  alt="Scrappi logo"
                />
                <span className="side-nav__download-extension--text">
                  Add chrome extension
                </span>
              </a>
            </div>
          )}

          <span className="side-nav-wrapper__policy-text">
            Copyright {currentDate.getFullYear()}
          </span>
          <a
            className="side-nav-wrapper__privacy-policy-link hoverState-text-color"
            href="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </a>
          <a
            className="side-nav-wrapper__privacy-policy-link hoverState-text-color"
            href="/terms-and-conditions"
            target="_blank"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideNavWrapper;
