import React from 'react';

interface Proptypes extends React.SVGAttributes<SVGSVGElement> {}

const LeaveIcon = (props: Proptypes) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.62285 13.8814C5.92592 14.2278 6.45239 14.2629 6.79875 13.9598C7.14512 13.6568 7.18022 13.1303 6.87715 12.7839L5.16981 10.8327L13.3333 10.8327C13.7936 10.8327 14.1667 10.4596 14.1667 9.99935C14.1667 9.53911 13.7936 9.16602 13.3333 9.16602L5.16981 9.16602L6.87715 7.21477C7.18022 6.86841 7.14512 6.34194 6.79876 6.03887C6.45239 5.7358 5.92592 5.7709 5.62285 6.11726L2.70619 9.45059C2.43127 9.76478 2.43127 10.2339 2.70619 10.5481L5.62285 13.8814ZM13.3333 3.33268C12.8731 3.33268 12.5 2.95959 12.5 2.49935C12.5 2.03911 12.8731 1.66602 13.3333 1.66602L15.8333 1.66602C16.7538 1.66602 17.5 2.41221 17.5 3.33268L17.5 16.666C17.5 17.5865 16.7538 18.3327 15.8333 18.3327L13.3333 18.3327C12.8731 18.3327 12.5 17.9596 12.5 17.4993C12.5 17.0391 12.8731 16.666 13.3333 16.666L15.8333 16.666L15.8333 3.33268L13.3333 3.33268Z"
        fill="#969696"
      />
    </svg>
  );
};

export default LeaveIcon;
