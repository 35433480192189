import { entryTypes, entryStories } from '../../types/feed';
import { tagType } from '../../types/tags';
import { collectionType } from '../../types/collections';



export const loadFeed = (feedData: entryTypes[], flag: Boolean) => {
  if (flag) {
    return {
      type: 'RESET_STATE',
      feedData,
    };
  }

  
  return {
    type: 'LOAD_FEED',
    feedData,
  };
};

export const deleteEntry = (uid: string) => {
  return {
    type: 'DELETE_FEED_ITEM',
    uid,
  };
};

export const addOrRemoveTagsFromScrap = (type: 'add' | 'remove',tagsList: tagType[], pidList: number[]) => {
  
  let actionType; 
  switch (type) {
    case 'add':
      actionType = 'ADD_TAGS_TO_SCRAPS'
      break;
    case 'remove':
      actionType = 'REMOVE_TAGS_FROM_SCRAPS'
      break;
  
    default:
      break;  
  }
  if(actionType)
    return {
      type: actionType,
      tagsList,
      pidList
    }
}

export const updateNote = (uid: string, note: string) => {
  return {
    type: 'UPDATE_NOTE',
    uid,
    note,
  };
};

export const updateTag = (uid: string, tag: tagType[]) => {
  return {
    type: 'UPDATE_TAG',
    uid,
    tag,
  };
};
export const updateCollection = (uid: string, collections: collectionType[]) => {
  return {
    type: 'UPDATE_COLLECTION',
    uid,
    collections,
  };
};

export const updateComment = (uid: string, comment: string) => {
  return {
    type: 'UPDATE_COMMENT',
    uid,
    comment,
  };
};
export const createNewScrap = (scrap) => {
  return {
    type: 'NEW_SCRAP',
    scrap
  }
};
export const updateScrap = (scrap) => {
  return {
    type: 'UPDATE_SCRAP',
    scrap
  }
};
/**
 * When new stories are added to a feed item,
 * update the feed item with the new list of stories
 */

export const addStoriesToFeed = (uid: string, stories: entryStories[]) => {
  return {
    type: 'ADD_STORIES_TO_FEED',
    uid,
    stories: stories.map((story) => ({
      id: story.id.toString(),
      __typename: story.title,
    })),
  };
};
export const removeStoryFromFeed = (pid: number, id: bigint) => {
  return {
    type: 'REMOVE_STORY_FROM_FEED',
    pid, id
  };
};
