import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const CloseSideBar = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick && onClick}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 1C0 0.447754 0.559616 0 1.25 0H18.75C19.4404 0 20 0.447754 20 1C20 1.55225 19.4404 2 18.75 2H1.25C0.559616 2 0 1.55225 0 1ZM0 7C0 6.44775 0.559616 6 1.25 6H6.75C7.44038 6 8 6.44775 8 7C8 7.55225 7.44038 8 6.75 8H1.25C0.559616 8 0 7.55225 0 7ZM1.25 12C0.559616 12 0 12.4478 0 13C0 13.5522 0.559616 14 1.25 14H6.75C7.44038 14 8 13.5522 8 13C8 12.4478 7.44038 12 6.75 12H1.25ZM1.25 18C0.559616 18 0 18.4478 0 19C0 19.5522 0.559616 20 1.25 20H18.75C19.4404 20 20 19.5522 20 19C20 18.4478 19.4404 18 18.75 18H1.25ZM14.1585 14.7526C13.7429 15.1163 13.1111 15.0742 12.7474 14.6585L9.24742 10.6585C8.91753 10.2815 8.91753 9.71853 9.24742 9.34151L12.7474 5.34151C13.1111 4.92587 13.7429 4.88375 14.1585 5.24744C14.5741 5.61112 14.6163 6.24288 14.2526 6.65852L12.2038 9.00001H19C19.5523 9.00001 20 9.44773 20 10C20 10.5523 19.5523 11 19 11H12.2038L14.2526 13.3415C14.6163 13.7571 14.5741 14.3889 14.1585 14.7526Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export default CloseSideBar;
