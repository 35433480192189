import React from 'react';


interface Proptypes extends React.DOMAttributes<SVGSVGElement> {
  className?: string;
}

const Minus = (props: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99962 17.4617C4.32645 17.4617 0.538086 13.6733 0.538086 9.00011C0.538086 4.32693 4.32645 0.538574 8.99962 0.538574C13.6728 0.538574 17.4612 4.32693 17.4612 9.00011C17.4612 13.6733 13.6728 17.4617 8.99962 17.4617ZM8.99962 2.23088C5.26108 2.23088 2.23039 5.26157 2.23039 9.00011C2.23039 12.7387 5.26108 15.7693 8.99962 15.7693C12.7382 15.7693 15.7689 12.7387 15.7689 9.00011C15.7689 5.26157 12.7382 2.23088 8.99962 2.23088ZM8.99962 8.15396C9.46694 8.15396 9.84616 8.15396 9.84616 8.15396H12.5896C13.0569 8.15396 13.4357 8.5328 13.4357 9.00011C13.4357 9.46743 13.0569 9.84627 12.5896 9.84627H9.84616C9.84616 9.84627 9.38424 9.84627 9.00001 9.84627C8.53269 9.84627 8.15385 9.84627 8.15385 9.84627H5.4097C4.94238 9.84627 4.56354 9.46743 4.56354 9.00011C4.56354 8.53279 4.94238 8.15396 5.4097 8.15396H8.15385C8.15385 8.15396 8.53231 8.15396 8.99962 8.15396Z"
        fill="#969696"
      />
    </svg>
  );
};

export default Minus;
