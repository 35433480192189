import React, { useMemo } from 'react';
import { NewGlobeIcon, PrivateIcon, ShareIcon } from '../icons';
import './styles.css';
import Share from "../icons/Share";

interface Proptypes{
  state:number
  onClick?: (e) => void;
}

const StateLabel = ({ state, onClick }: Proptypes) => {
  const iconRenderer = useMemo(() => {
    switch (state) {
      case 3:
        return <>
          <span className="state-label__icon label-left">
            <ShareIcon />
          </span>&nbsp;&nbsp;
          <span className="state-label__icon">
            <NewGlobeIcon />
          </span>
        </>
      case 2:
        return <span className="state-label__icon"><ShareIcon /></span>
      default:
        return <span className="state-label__icon"><PrivateIcon /></span>;
    }
  }, [state])
  return (
    <div className={`state-label${onClick ? ' state-label--clickable' : ''}`} onClick={onClick}>
      {iconRenderer}
    </div>
  );
};

export default StateLabel;
