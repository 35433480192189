import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { releaseNotes } from '../../helpers/config';
import Icon from '../Global/Icon';
import { Back } from '../Global/icons';

interface List {
  title: string;
  description?: string;
  stateName: string;
  label?: string;
  subDescription?: string;
  image1?: string;
  image2?: string;
  appLogo?: string;
  appLink?:string;
}
interface Proptypes {
  selectedState: string;
  setSelectedState: (
    val:
      | 'extension'
      | 'faq'
      | 'appStore'
      | 'playStore'
      | 'release-notes'
      | 'ticket'
      | ''
  ) => void;
  supportList: Array<List>;
}


const SelectedSupport = (props: Proptypes) => {
  const { selectedState, setSelectedState, supportList } = props;
  const selectItem = supportList.find((item) => {
    return item.stateName === selectedState;
  });
  const [currentIndex,setCurrentIndex]=useState([0]);
  const supportListQuestion = [
    {
      question: 'How do I delete my Scrappi account?',
      answer: [
        'Click on your username or avatar in the upper right.',
        'Navigate to My Account and then to Delete Account on the next menu.',
        "Confirm you want to delete the account by typing 'delete' in the input box.",
        'You will receive an email with a one-time verification key.',
        'Copy the verification key and submit it in the form.',
        'You will be logged out and your account will be deleted.',
      ],
      bulletPoint:true
    },
    {
      question: 'What is a Scurry?',
      answer: [
        `It's a group of squirrels ... obviously.`
      ],
      bulletPoint:false,
      domain:'https://www.quora.com/',
      link:'https://www.quora.com/What-is-a-group-of-squirrels-called/answer/Sandhya-Lamsal-4?ch=10&oid=68088934&share=3b1b6869&srid=Bg2O&target_type=answer'

    },
  ];
  const mainContent = () => {
    if (
      selectedState === 'extension' ||
      selectedState === 'appStore' ||
      selectedState === 'playStore'
    ) {
      return (
        <div className="selected-support__extension">
          <div className="selected-support__header-section">
            <p className='selected-support__header-section--title'>{selectItem.label}</p>
            <p className='selected-support__header-section--desc'>
              {selectItem.description}{' '}
              {selectItem.stateName === 'extension' && (
                <a href="https://chrome.google.com/webstore/detail/scrappi/gegnhdpjmbkeedckopbcncknjogdiiof?hl=en" className='selected-support__extension--link' target="_blank" rel="noopener noreferrer"> Scrappi Chrome Extension</a>
              )}
            </p>
            {selectItem.appLogo && (
              <a className='selected-support__header-section--app-logo' href={`${selectItem?.appLink} `} >
                <img src={selectItem.appLogo} alt="App-logo"></img>
              </a>
            )}
            <div className="selected-support__image-wrapper">
              <div>
                <img className='selected-support__image-wrapper--image' src={selectItem.image1} alt="App-create-scrap"></img>
              </div>
              <div>
                <img className='selected-support__image-wrapper--image' src={selectItem.image2} alt="App-save-scrap"></img>
              </div>
            </div>
          </div>
          <p className='selected-support__header-section--desc'>{selectItem.subDescription}</p>
        </div>
      );
    }
    if (selectedState === 'faq') {
      return (
        <div className="selected-support__faq">
          {supportListQuestion.map((item,index)=>{
            return (
              <div key={index} className={`selected-support__dropdown`}>
                <div
                  key={index}
                  className={`selected-support__dropdown__selector ${
                    currentIndex.includes(index)
                      ? 'selected-support__dropdown-highlight'
                      : ''
                  }`}
                  onClick={() =>
                    currentIndex.includes(index)
                      ? setCurrentIndex((prev) => [
                        ...prev.filter((i) =>i!==index)
                      ])
                      : setCurrentIndex((prev) => [
                        ...prev, index
                      ])
                  }
                >
                  <p
                    className={`selected-support__dropdown-question ${
                      currentIndex.includes(index)
                        ? 'selected-support__dropdown-question-highlight'
                        : ''
                    }`}
                  >
                    {item.question}
                  </p>
                  <Icon
                    iconClass={`chevron-down ${
                      currentIndex.includes(index)
                        ? 'selected-support__dropdown__arrow-highlight'
                        : ''
                    }`}
                    size="normal"
                  />
                </div>
                {currentIndex.includes(index) && (
                  <ul className="selected-support__dropdown-question--list">
                    {item.answer.map((answer, index) => {
                      return (
                        <li
                          key={index}
                          className={`${
                            !item.bulletPoint
                              ? 'selected-support__dropdown-question--list-bullet-point'
                              : ''
                          }`}
                        >
                          {answer}{' '}
                          {item.link && (
                            <a
                              href={item.link}
                              className="selected-support__dropdown-question--list-link-color"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.domain}
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}

        </div>
      );
    }

    if (selectedState === 'ticket') {
      return (
        <div className="selected-support__submit-message">
          <p>
            We built Scrappi to make the internet a little less noisy ... a
            place where you can collect, collaborate and share. If we're not
            meeting your expectations, tell us why so we can make it better.
          </p>
          <p className="selected-support__submit-message__reddit">
            Join us on the{' '}
            <a
              className="selected-support__submit-message__link"
              href='https://www.reddit.com/r/Scrappi/'
              target="_blank" rel="noopener noreferrer"
            >
              r/Scrappi
            </a>{' '}
            subreddit.
          </p>
        </div>
      );
    }
    if (selectedState === 'release-notes') {
      return (
        <div className="main-list">
          {releaseNotes.map((release, i) => (
            <>
              <h2 className="selected-support__release-notes__date">
                {release.date}
              </h2>

              {['extension', 'scrappi'].map(
                (type) =>
                  release[type] && (
                    <>
                      <h2 className="selected-support__release-notes__title">
                        {release[type].header}
                      </h2>
                      <div>
                        <ul className="selected-support__release-notes__information">
                          {release[type].list.map((subRelease) => (
                            <>
                              <h4 className="selected-support__release-notes__title">
                                {subRelease.subHeader}
                              </h4>
                              <ul className="selected-support__term-list">
                                {subRelease.subList.map((data, i) => (
                                  <li className="selected-support__term-list__sub-item" key={i}>
                                    {data.header && (
                                      <strong>{data.header}:</strong>
                                    )}{' '}
                                    <span className="selected-support__release-notes__desc">
                                      {data.text}
                                    </span>
                                    {data.gifUrl && (
                                      <div className="help-page__release-gif">
                                        <Popup
                                          modal
                                          className="instructions"
                                          trigger={
                                            <img
                                              src={data.gifUrl}
                                              alt={data.text}
                                            />
                                          }
                                          position="right center"
                                        >
                                          <img
                                            src={data.gifUrl}
                                            alt={data.text}
                                          />
                                        </Popup>
                                      </div>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </>
                          ))}
                        </ul>
                      </div>
                    </>
                  )
              )}
            </>
          ))}
        </div>
      );
    }
  };
  return (
    <div className="selected-support">
      <div className="selected-support__header">
        <div
          onClick={() => setSelectedState('')}
          className="selected-support__back-button"
        >
          <Back />{' '}
          <span className="selected-support__back-button--label">Back</span>
        </div>
        <div className="selected-support__title">
          <p>{selectItem.title}</p>
        </div>
      </div>

      <div className="selected-support__main">{mainContent()}</div>
    </div>
  );
};

export default SelectedSupport;
