import React, { useEffect, useRef, useState } from 'react';
import { CreateScrapImageDataType } from '../../../helpers/createScrapHelpers';
import { entryTypes } from '../../../types/feed';
import { renderSocialEmbed } from '../../Cards/CardSocial';
import CreateScrapAddImage from '../../CreateScrap/components/CreateScrapAddImage';
import GlobalImageStatus from '../../GlobalSearch/components/GlobalImageStatus';
import ScrapEditSourceUrl from '../../ScrapDetails/components/ScrapEditSourceUrl';

import './styles.css';

interface Proptypes {
  scrap: entryTypes;
  url: string;
  onUrlChange: (val: string) => void;
  price: number;
  onPriceChange: (val: number) => void;
  image: string;
  onImageChange: (data: string | CreateScrapImageDataType) => void;
  imageData: CreateScrapImageDataType;
}
const maxMetaDescLength = 246; // MAX LENGTH FOR META DATA DESCRIPTION
const maxMetaTitleLength = 90; // MAX LENGTH FOR META DATA TITLE

const returnTrimmedData = (desc: string, maxLen: number) => {
  var metaDescription = desc;
  if (metaDescription) {
    if (desc.length > maxLen) {
      metaDescription = desc.substring(0, maxLen) + '...';
    } else {
      metaDescription = desc;
    }
  }
  return metaDescription;
};

const ScrapSavedWeb = ({
  scrap,
  url,
  onUrlChange,
  price,
  onPriceChange,
  image,
  imageData,
  onImageChange,
}: Proptypes) => {
  // FOR EDIT URL
  const [editURL, setEditURL] = useState(false);
  const [editPrice, setEditPrice] = useState(false);

  const [isProductPriceFocused, setIsProductPriceFocused] = useState(false);

  const favicon = scrap.favicon || scrap.original_favicon;


  const embedRef = useRef<HTMLDivElement>();
  let imageStatus;
  if (scrap.config) {
    try {
      imageStatus = JSON.parse(scrap.config).img_status;
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    const element = embedRef?.current;
    if(element && scrap?.service === 'youtube') {
      element.style.height = (element.clientWidth * 360 / 640) + 'px';
    }
  }, [embedRef, scrap])

  return (
    <div className="scrap-saved-web__outer-container">
      <div className={`scrap-saved-web scrap-saved-web--${scrap.service}`}>
        <div className={`scrap-saved-web__image-wrapper scrap-saved-web__image-wrapper--${scrap.service}`}>
          {scrap.type === 'social' &&
          !['facebook', 'tiktok'].includes(scrap.service) ? (
              <div
                ref={embedRef}
                className={`scrap-saved-web__embed scrap-saved-web__embed--${scrap.service}`}
              >
                {renderSocialEmbed(scrap)}
              </div>
            ) : !scrap[scrap.type === 'social' ? 'thumbnail' : 'image'] ? (
              <GlobalImageStatus config={scrap.config} image={image} />
            ) : (
              <CreateScrapAddImage
                key="cover-image"
                imageData={imageData}
                uneditable={scrap.type === 'social'}
                image={image}
                onImageChange={onImageChange}
                changeImageInScrap={onImageChange}
                initialImage={
                  scrap[
                    ['page', 'product'].includes(scrap.type)
                      ? 'image'
                      : 'thumbnail'
                  ]
                }
              />
            )}
        </div>
        <div className="scrap-saved-web__general-content">
          {scrap.url && (
            <div className="scrap-saved-web__url-wrapper">
              
              <div className="scrap-saved-web__url-favicon-wrapper">
                {favicon && (
                  <span className="scrap-saved-web__url-favicon">
                    <img
                      className="scrap-saved-web__url-favicon-image"
                      src={favicon}
                      alt="FavIcon"
                    ></img>
                  </span>
                )}
                <ScrapEditSourceUrl
                  disabled
                  displayFullUrl
                  url={scrap.updated_url || scrap.url}
                  onChange={(val) => {}}
                />
              </div>
              {/* HIDING EDIT URL FEATURE AS PER CLIENT REQUEST */}
              {/* {editURL ? (
                <div className="scrap-saved-web__url-input-container">
                  <input
                    value={url}
                    onChange={(e) => onUrlChange(e.target.value)}
                    className="scrap-saved-web__url-input"
                  />
                </div>
              ) : (
                <span
                  className="scrap-saved-web__url-edit-button"
                  onClick={() => setEditURL(true)}
                >
                  Edit URL
                </span>
              )} */}
              <h2 className="scrap-saved-web__url-heading">
                {scrap?.meta_title}
              </h2>

              {scrap.meta_desc?.length > 0 && (
                <p className="scrap-saved-web__description-wrapper">
                  {returnTrimmedData(scrap.meta_desc, maxMetaDescLength)}
                </p>
              )}
            </div>
          )}
          {scrap.type === 'product' && (
            <div className="scrap-saved-web__price-wrapper">
              {editPrice ? (
                <>
                  <div className="scrap-saved-web__price-input-wrapper">
                    <input
                      onFocus={setIsProductPriceFocused.bind(null, true)}
                      onChange={(e) => {
                        let price: string | number = e.target.value;
                        if (price.trim() === '') price = null;
                        onPriceChange(price as number);
                      }}
                      onBlur={setIsProductPriceFocused.bind(null, false)}
                      type="text"
                      placeholder="Add a Price"
                      value={
                        !isProductPriceFocused && scrap.price
                          ? new Intl.NumberFormat('en-US').format(price)
                          : price
                      }
                      className={`scrap-saved-web__price-input`}
                      onKeyPress={(event) => {
                        if (!/^\d+(?:\.\d+)?$/.test(event.key)) {
                          const keyCode = event.which
                            ? event.which
                            : event.keyCode;
                          if (
                            keyCode === 190 ||
                            keyCode === 110 ||
                            keyCode === 46
                          ) {
                            //FOR . (DOT)
                            if (scrap.price?.toString().includes('.')) {
                              event.preventDefault();
                            }
                          } else {
                            event.preventDefault();
                          }
                        }
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  {!scrap.price && (
                    <button
                      onClick={setEditPrice.bind(null, true)}
                      className="scrap-saved-web__price-add-button"
                    >
                      Add Price +
                    </button>
                  )}

                  {scrap.price && (
                    <>
                      <span>
                        ${scrap.price}
                        {'  '}
                      </span>
                      <button
                        onClick={setEditPrice.bind(null, true)}
                        className="scrap-saved-web__price-add-button"
                      >
                        Edit Price
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          )}

          {/* {!scrap.url?.length && scrap.type !== 'product' && (
          <p className="create-scrap__edit--meta-preview-description">
            No Meta Data Available.
          </p>
        )} */}
        </div>
      </div>
    </div>
  );
};

export default ScrapSavedWeb;
