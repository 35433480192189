import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const GalleryLogo = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className||''}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 20V16.7L18.3 12.6L11.5 19.2C11.1 19.6 10.4 19.7 9.9 19.3L5.1 15.8L2 18.8V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20Z"
        fill="#969696"
      />
      <path
        d="M5.7 13.1L10.5 16.6L17.5 9.8C17.7 9.6 18.1 9.4 18.4 9.4C18.7 9.4 19.1 9.6 19.3 9.8L22 12.8V4C22 2.9 21.1 2 20 2H4C2.9 2 2 2.9 2 4V15.3L4.1 13.3C4.5 12.8 5.2 12.8 5.7 13.1ZM10.1 5.8C11.1 5.8 12 6.6 12 7.7C12 8.8 11.2 9.6 10.1 9.6C9 9.6 8.2 8.7 8.2 7.6C8.2 6.5 9.1 5.8 10.1 5.8Z"
        fill="#969696"
      />
    </svg>
  );
};

export default GalleryLogo;
