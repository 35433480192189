import { ScrapCommentsType } from '../../components/Comments';
import { scrapType } from '../../types/scrapType';

export interface OneScrapActionType {
  type: 'edit' | 'delete' | 'comments-and-likes';
  scrap?: Partial<scrapType>;
  comments?: ScrapCommentsType;
}
interface actionType {
  type: string;
  payload: OneScrapActionType;
}
const DEFAULT: OneScrapActionType = {
  type: null,
  scrap: null,
};

export default function oneScrapAction(state = DEFAULT, action: actionType) {
  switch (action.type) {
    case 'SET_ONE_SCRAP_ACTION':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}
