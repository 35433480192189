import React from 'react';
import { filterItemType } from '../../../containers/SideNavContainer';
import Icon from '../../Global/Icon';
// import { Svg } from '../../../../public/svg-icons/collections-side-nav.svg';

import './styles.css';
import NotificationCount from '../../Global/NotificationCount';

interface Proptypes {
  item: filterItemType;
  children?: React.ReactChild;
  isActive: boolean;
  onClick: () => void;
  isOpen: boolean;
  // onToggle: (label: filterItemType['label']) => void;
  count?: number;
}

const SideNavItem = ({
  item: { label, icon, isToggle, reactElementIcon },
  children,
  isActive,
  onClick,
  isOpen,
  count,
}: Proptypes) => {
  return (
    <li
      className={`side-nav-item ${
        label === 'Shares' ? 'side-nav-item--shares' : ''
      } ${label === 'Saved' ? 'onboard-save' : ''}`}
    >
      <div
        onClick={onClick}
        className={`side-nav-item__content${
          isActive ? ' side-nav-item__content--active' : ''
        }`}
      >
        <div className="side-nav-item__label-container">
          {reactElementIcon ? (
            <span
              className={`side-nav-item__icon--svg-wrapper${
                isActive ? ' side-nav-item__icon--active' : ''
              }`}
            >
              {reactElementIcon}
            </span>
          ) : (
            <img
              className={`side-nav-item__icon${
                isActive ? ' side-nav-item__icon--active' : ''
              }`}
              src={icon}
              alt={label}
            />
          )}

          <span
            className={`side-nav-item__label${
              isActive ? ' side-nav-item__label--active' : ''
            }`}
          >
            {label}
            {label === 'Scraps' ? (
              <span>
                <sup className="side-nav-item__label__service-mark">SM</sup>
              </span>
            ) : null}
            {/*uncomment for  count */}
            {label === 'Contacts' && count ? (
              <NotificationCount count={count} />
            ) : null}
          </span>
        </div>
        {isToggle && (
          <span
            className={`side-nav-item__toggle-icon${
              isActive ? ' side-nav-item__toggle-icon--active' : ''
            }${isOpen ? ' side-nav-item__toggle-icon--open' : ''}`}
          >
            <Icon iconClass="chevron-down" size="normal" />
          </span>
        )}
      </div>

      {children && isOpen && (
        <div className="side-nav-item__children">{children}</div>
      )}
    </li>
  );
};

export default SideNavItem;
