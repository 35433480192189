import React from 'react';
import { groupDetailsWarningType } from '../../containers/GroupDetailsPopupContainer/types';
import { Collaborator, NewDownArrow } from '../Global/icons';
import { GroupType } from '../../containers/GroupShareContainer/types';
import { collectionType } from '../../types/collections';

interface PropTypes {
  dataType: 'group' | 'collection'
  warning: groupDetailsWarningType;
  group: GroupType | collectionType;
}

type messagesType = {
  [key in groupDetailsWarningType['type']]: (memberName: string, type: PropTypes['dataType']) => string;
};

const messages: Partial<messagesType> = {
  'remove-member': (memberName, type) =>
    `Are you sure you want to remove ${memberName} from this ${
      type === 'collection' ? 'Collection' : 'Share'
    }? All Scraps from this contact will be removed.`,
  'set-owner': (memberName, type) =>
    `Are you sure you want to transfer ownership of this ${
      type === 'collection' ? 'Collection' : 'Share'
    } to ${memberName}?`,
};

export function GroupDetailsWarning({ warning, group, dataType }: PropTypes) {
  const { type } = warning;
  const returnGroupName = (group: Partial<GroupType> | Partial<collectionType>) => {
    if (group.title) return group.title;
    if (group.nickname) return group.nickname;
    return group.members
      ?.map((member) => member['display_name'] ?? member['user_name'])
      .join(', ');
  };

  /**
   * Renders Group details on warning shown in group details popup
   */
  const renderGroup = (groupData: Partial<GroupType>, label: string) => {
    if(type === 'remove-member' && dataType === 'group') return null;
    return (
      <div className="group-details__warning-group">
        <p className="group-details__warning-merge-label">{label}</p>
        <p className="group-details__warning-group-name">
          <Collaborator />
          {returnGroupName(groupData)}
        </p>
      </div>
    );
  };
  let warningMessage = '';
  switch (type) {
    case 'merge-groups':
      warningMessage = warning.responseMessage;

      break;
    case 'remove-member':
      warningMessage = messages[type](
        warning.member.display_name ?? warning.member.user_name, dataType
      );
      break;
    case 'set-owner':
      warningMessage = messages[type](
        warning.newOwner.display_name ?? warning.newOwner.user_name, dataType
      );

      break;

    default:
      break;
  }

  return (
    <>
      <p
        className={`group-details__warning-message${
          type === 'merge-groups'
            ? ' group-details__warning-message--large'
            : ''
        }`}
      >
        {warningMessage}
      </p>

      {type === 'merge-groups' ? (
        <div className="group-details__warning-merge-wrapper">
          {renderGroup(group, 'Original')}
          <NewDownArrow className="group-details__warning-merge-icon" />
          {renderGroup(warning.existingGroup, 'Existing Share')}
        </div>
      ) : (
        renderGroup(group, dataType === 'group' ? 'Share' : 'Collection')
      )}
    </>
  );
}
