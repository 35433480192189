import { userTypes } from '../../types/user';

export const loadUser = (user: userTypes) => {
  if(!user.main_service){
    user.main_service = "{}"
  }
  if(!user.services){
    user.services = "[]"
  }
  return {
    type: 'LOAD_USER',
    user,
  };
};

export const toggleView = (viewState: boolean) => {
  return {
    type: 'UPDATE_GRID',
    compact: viewState,
  };
};
export const updateUser = (data: Partial<userTypes>) => {
  return {
    type: 'UPDATE_USER_DETAILS',
    payload: data,
  };
};
