import {
  DISLIKE_COLLECTION,
  LIKE_COLLECTION,
  UPDATE_COLLECTIONS_LIKES_AND_COMMENTS,
  UPDATE_COLLECTION_COMMENT_COUNT,
  collectionsLikesAndCommentsPayloadType,
} from '../store/collectionsLikesAndComments';

export function updateCollectionsLikesAndComments(
  payload:
    | collectionsLikesAndCommentsPayloadType
    | Array<collectionsLikesAndCommentsPayloadType>
) {
  return {
    type: UPDATE_COLLECTIONS_LIKES_AND_COMMENTS,
    payload,
  };
}

export function likeCollection(collectionIdOrIds: Array<number> | number) {
  return {
    type: LIKE_COLLECTION,
    payload: Array.isArray(collectionIdOrIds)
      ? collectionIdOrIds.map((collectionId) => ({
        collectionId,
      }))
      : {
        collectionId: collectionIdOrIds,
      },
  };
}
export function dislikeCollection(collectionIdOrIds: Array<number> | number) {
  return {
    type: DISLIKE_COLLECTION,
    payload: Array.isArray(collectionIdOrIds)
      ? collectionIdOrIds.map((collectionId) => ({
        collectionId,
      }))
      : {
        collectionId: collectionIdOrIds,
      },
  };
}

export function updateCollectionCommentCount(
  data:
    | Array<{
        id: number;
        count: number;
      }>
    | {
        id: number;
        count: number;
      }
) {
  return {
    type: UPDATE_COLLECTION_COMMENT_COUNT,
    payload: Array.isArray(data)
      ? data.map(({ id, count }) => {
        return {
          collectionId: id,
          data: {
            commentsData: {
              count,
            },
          },
        };
      })
      : {
        collectionId: data.id,
        data: {
          commentsData: {
            count: data.count,
          },
        },
      },
  };
}
