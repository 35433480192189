import React, { useRef } from 'react';
import './styles.css';
import CollectionHeaderAvatar from '../../../CollectionCreationWrapper/components/CollectionCreationHeader/CollectionHeaderAvatar';
import { GlobalSearchUserType } from '../../../../types/globalSearchDataType';
import useOutsideAlerter from '../../../../helpers/customHooks/useOutsideAlerter';
import userInitials from '../../../../helpers/userInitial';

interface Proptypes {
  loadingEmailSuggestion: boolean;
  hideEmailSuggestion: boolean;
  fetchedUsers: GlobalSearchUserType[];
  hideSuggestionHandler: (val: boolean) => void;
  onUserClick: (data: GlobalSearchUserType) => void;
  email?:string
}

const ContactSuggestions = (prop: Proptypes) => {
  const {
    loadingEmailSuggestion,
    hideEmailSuggestion,
    fetchedUsers,
    hideSuggestionHandler,
    onUserClick,
    email
  } = prop;
  const emailSuggestion = useRef();

  useOutsideAlerter(
    emailSuggestion,
    () => hideSuggestionHandler(true),
    hideEmailSuggestion,
    true
  );
 
  return (
    ((loadingEmailSuggestion && hideEmailSuggestion) ||
    (fetchedUsers && !hideEmailSuggestion && email) )&& 
      <div className="contact-suggestions" ref={emailSuggestion}>
        <div className="contact-suggestions__scrollable">
          {fetchedUsers?.map((data, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  onUserClick(data);
                  hideSuggestionHandler(true);
                }}
                className="contact-suggestions__scrollable-details"
              >
                <span className="contact-suggestions__user-icon">
                  <CollectionHeaderAvatar
                    image={data?.avatar?data?.avatar:'/dashboard/img/default-avatar.jpg'}
                    text={userInitials(data?.display_name)}
                  />
                </span>
                <span className="contact-suggestions__initials">
                  <span className="contact-suggestions__username">
                    {data?.display_name}
                  </span>
                  <span className="contact-suggestions__email">
                    @{data?.user_name}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    
  );
};

export default ContactSuggestions;
