import { ApolloClient } from '@apollo/client';
import { FileItemStateType } from '../types';
import { returnArrayInBatch } from '../../../helpers/arrayHelpers';
import DELETE_SCRAP_IMAGE from '../../data/deleteScrapImage';

export default async function deleteScrapImagesHandler(
  scrapId: number,
  client: ApolloClient<object>,
  filesList: FileItemStateType[]
) {
  try {
    //
    const batchListOfVariables = returnArrayInBatch(
      filesList.map((data, i) => {
        return {
          scrap_id: scrapId,
          image_id: data.id,
        };
      }),
      10
    );
    for (let batchListItem of batchListOfVariables) {
      // Start the process of saving uploaded file in scrap
      const responses = await Promise.all(
        batchListItem.map((variables) => {
          return client.mutate({
            mutation: DELETE_SCRAP_IMAGE(),
            variables,
          });
        })
      );
    }
  } catch (error) {
    //
  }
}
