import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const DragItem = SortableElement(
  ({ className, children, onClick = () => {} }) => (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  )
);
const DragHandle = SortableHandle(({ children, className = '' }) => (
  <div className={className} tabIndex={0}>{children}</div>
));
const SortableList = SortableContainer(({ children }) => children);

export { DragItem, SortableList, DragHandle };
