import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from 'react';
import useWindowOnClick from '../../../helpers/customHooks/useWindowOnclick';
import Icon from '../Icon';
import './styles.css';
import useOutsideAlerter from '../../../helpers/customHooks/useOutsideAlerter';
import { DownArrowIcon } from '../icons';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';

interface listItemType {
  
  label: string;
  id: string;
  disabled?: boolean;
  popup?: {
    heading: string;
    isProcessing: boolean;
    proceedLabel?: string;
    message: string;
  };

}
interface Proptypes {
  setMessageBody?: (val: boolean) => void;
  showMessageBody?: boolean;
  list: listItemType[];
  onSelect: (item: listItemType) => void;
  placeholder: string;
  isFixed?: boolean;
  extraHeight?: boolean;
  selectedItem?: string;
  parentClassName?: string;
  additonalClassName?:string;
  dropdownicon?:string;
}

const Dropdown = forwardRef(
  (
    {

      list,
      onSelect,
      extraHeight,
      placeholder,
      isFixed,
      setMessageBody,
      showMessageBody,
      selectedItem,
      parentClassName,
      additonalClassName,
      dropdownicon
    }: Proptypes,
    ref
  ) => {
    const [show, setShow] = useState(false);
    const [topPosition, setTopPosition] = useState(0);
    const { width } = useSelector((state: ReduxStateType) => state.utils.dimensions);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setShow.bind(null, false), !show);
    const [bottomPosition, setBottomPosition] = useState(null);
    const [id] = useState(Math.ceil(Math.random() * 10000).toString());
    function handleOutSideClick(el: HTMLElement) {
      const closestElement = el.closest(`#dropdown-select-${id}`);
      const popupElement = el.closest('.popup-overlay');
      if (!closestElement && !popupElement) setShow(false);
    }
    useWindowOnClick({
      cb: handleOutSideClick,
      removeListener: !show,
    });

    useImperativeHandle(ref, () => ({
      hideDropdown() {
        setShow(false);
      },
    }));

    useEffect(() => {
      if (show) {
        const el = document.querySelector('.dropdown-select__list');
        const res = isInViewport(el);
        if (!res) el.classList.add('outside-vport');
        else el.classList.remove('outside-vport');
      }
    }, [show]);

    /**
     *
     * @param element
     * @returns boolean
     *
     * Checking if dropdown is in viewport with respect to the portal(i.e: modal)
     */
    function isInViewport(element: Element) {
      const parentRect = document
        .querySelector(
          parentClassName
            ? parentClassName
            : `.collection-collaboration__members-wrapper`
        )
        .getClientRects()[0];
      const rect = element.getClientRects()[0];
      return !(rect.bottom >= parentRect.bottom);
    }

    function handleDropdDownState(dropdown) {
      setShow(!show);
      //getting dropDownElement
      const dropDownEl = document.querySelector(`#${dropdown}`);
      //get the top position of the dropdown
      let dropdownTop = dropDownEl.getBoundingClientRect().top;
      //get the height of the dropdown grandparent
      const dropdownParentHeight = dropDownEl.parentElement?.parentElement?.getBoundingClientRect()
        .height;
      //get the height of the dropdown
      const dropdownHeight = dropDownEl?.getBoundingClientRect().height;

      //if parentHeight is less than dropdownTop then translate the dropdown to the top
      if (dropdownTop - dropdownParentHeight > 100 && !extraHeight) {
        setBottomPosition(dropdownHeight);
        return;
      }

      if (isFixed && !parentClassName) {
        var rects = dropDownEl?.getClientRects() || [];
        if (rects.length) {
          if (width < 800) {
            setTopPosition(rects[0]['top'] - 250);
          } else if (width < 1050) {
            setTopPosition(rects[0]['top'] - 320);
          } else {
            setTopPosition(rects[0]['top'] - 220);
          }
        }
      }
    }

    function renderDropdownItem(item: listItemType) {
      // if (item.popup) {
      //   const { popup } = item;
      //   return (
      //     <Popup
      //       key={item.id}
      //       closeOnDocumentClick={false}
      //       modal
      //       trigger={
      //         <li
      //           className={`dropdown-select__list__item${
      //             item.disabled ? '--message' : ''
      //           }`}
      //         >
      //           {item.label}
      //         </li>
      //       }
      //     >
      //       {(close) => (
      //         <Modal
      //           close={close}
      //           heading={popup.heading}
      //           body={
      //             <Fragment>
      //               <p>{popup.message}</p>
      //             </Fragment>
      //           }
      //           proceed={() => {
      //             onSelect(item);
      //             setShow(false);
      //           }}
      //           proceedLabel={popup.proceedLabel}
      //           isProcessing={false}
      //         />
      //       )}
      //     </Popup>
      //   );
      // }
      return (
        <li
          key={item.id}
          className={`dropdown-select__list__item${
            item.disabled ? '--message' : ''
          }`}
          onClick={(e) => {
            e.stopPropagation();
            // setMessageBody(!showMessageBody)
            if (item.disabled) return;
            onSelect(item);
            setShow(false);
          }}
        >
          <span>{item.label}</span>
        </li>
      );
    }

    function getDropdownAdditionalStyles() {
      let styleObj={}
       if(isFixed){
         styleObj['top']=`${topPosition}px` 
       }
      if(bottomPosition){
        styleObj={...styleObj,...{ bottom: `${bottomPosition}px`, top: '' }}
      }
      if(parentClassName){
        styleObj['transform']='translateY(-10px)';
      }

      return styleObj
    }

    return (
      <div
        ref={wrapperRef}
        className={`dropdown-select${additonalClassName?additonalClassName:''} ${show ? 'dropdown-select-active' : ''}`}
        id={`dropdown-select-${id}`}
        onClick={() => {
          handleDropdDownState(`dropdown-select-${id}`);
        }}
      >
        <span className="dropdown-select__current-type">
          {selectedItem ? (
            <span className="dropdown-select__current-type__text">
              {selectedItem}
            </span>
          ) : (
            <span className="dropdown-select__placeholder">
              {placeholder || 'Select'}
            </span>
          )}

          <span className={`${dropdownicon?dropdownicon:"dropdown-select__current-type__icon"}`}>
            <DownArrowIcon />
          </span>
        </span>
        {show && (
          <ul
            //if the dropdown overflows the container then we show it in upward direction by giving bottom position
            className={`dropdown-select__list ${
              bottomPosition ? 'list__bottom' : 'list__top'
            }`}
            style={getDropdownAdditionalStyles()}
          >
            {list.map((item) => renderDropdownItem(item))}
          </ul>
        )}
      </div>
    );
  }
);

export default Dropdown;
