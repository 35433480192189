import React, { useEffect, useRef } from 'react';

interface Proptypes {
  children?: string | React.ReactElement;
  type?: 'default' | 'filled';
}

export default function ErrorMessageComponent({ children, type }: Proptypes) {
  const ref = useRef<HTMLParagraphElement>();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);
  return (
    <p
      ref={ref}
      className={`error-message${
        type !== 'default' ? ` error-message--${type}` : ''
      }`}
    >
      {children}
    </p>
  );
}
