import React from 'react';
import './styles.css';
import TermPagesWrapper from './TermPagesWrapper';
import { Helmet } from 'react-helmet';

const Copyright = () => {
  return (
    <TermPagesWrapper>
      <>
        <Helmet>
          <title>Scrappi | Copyright and Trademark</title>
        </Helmet>
        <iframe
          src="https://docs.google.com/document/d/e/2PACX-1vSFEJKPou9v0tkfSEzAwgafyRh-oACpaih1G0r_BbIGTETA_Z_2_z2zlpCkrrhGAbN3fj81S2CAMoc-/pub?embedded=true"
          width="800"
          height="1100"
          frameBorder="0"
          scrolling='no'
          title="Copyright and Trademark"
        ></iframe>
      </>
    </TermPagesWrapper>
  );
};

export default Copyright;
