import React from 'react';

import { parseSocialMeta } from '../../../helpers/parseSocialMeta';

interface PropType {
  meta: string;
}

interface FacebookProps {
  instaUrl?: string;
}

export const Facebook = ({ meta }: PropType) => {
  // parse the meta data
  let parsedMeta: FacebookProps = parseSocialMeta(meta);
  const href = parsedMeta.instaUrl;
  const file_name = (href.includes("/watch") ? 'video' : 'post')

  return (
    <>
      <iframe src={"https://www.facebook.com/plugins/" + file_name + ".php?href="+encodeURIComponent(href)+"&show_text=1&height=450&locale=en_US&width=400"}
        height="650" frameBorder="0" scrolling="no"></iframe>
    </>
  );
};
