import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['id', 'ID'],
  ['scrap_id', 'Int!'],
  ['file_name', 'String'],
  ['file_path', 'String!'],
  ['file_type', 'Int!'],
]);
export default function UPSERT_SCRAP_DOCUMENT(responseString = '') {
  return gql`
    mutation UpsertScrapDocument(${parametersTypeString}) {
      upsertScrapDocument(${parametersAssignString}) {
        ${defaultResponse}
        ${responseString ? `data { ${responseString} }` : ''}
      }
    }
  `;
}
