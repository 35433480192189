import { gql } from '@apollo/client';

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $notes: String!
    $status: Int
    $tags: String
    $title: String
    $state: Int
    $collections: String
  ) {
    createNote(
      notes: $notes
      status: $status
      tags: $tags
      title: $title
      state: $state
      collections: $collections
    ) {
      id
      pid
      uid
      type
      notes
      title
      state
      tags {
        id
        title
      }
      created_at
      updated_at
    }
  }
`;
