import React, { Fragment } from 'react';
import BaseLayout from '../BaseLayout';

interface Proptypes {
  children: React.ReactChild | React.ReactChildren;
  showSideNav?: boolean;
}

const PublicFeedNavigation = ({ children, showSideNav }: Proptypes) => {
  return (
    <Fragment>
      <BaseLayout shouldRenderChildren>
        {children}
      </BaseLayout>
    </Fragment>
  );
};

export default PublicFeedNavigation;
