/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef } from 'react';
import { ReduxStateType } from '../../redux/store';
import { useSelector } from 'react-redux';
import { calculateCardWidth } from '../../helpers';

interface Proptypes<T> {
  items: T[];
  onLoadMore: () => void;
  children: (item: T, index: number) => React.ReactElement;
  loading: boolean;
  keyField: keyof T;
}
export default function ItemsMasonryGrid<
  itemType extends { [key: string]: any }
>({ items, onLoadMore, children, loading, keyField }: Proptypes<itemType>) {
  const { width } = useSelector(
    (state: ReduxStateType) => state.utils.dimensions
  );
  const { expanded } = useSelector((state: ReduxStateType) => state.utils);
  const gridRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const gridContainer = gridRef?.current;
    if (gridContainer) {
      const { columns } = calculateCardWidth(
        320,
        gridContainer.offsetWidth,
        10
      );
      let className = gridContainer.className;
      let list = className.split(' ');
      list = list.filter((val) => !val.includes('items-masonry-grid--col'));
      list.push(`items-masonry-grid--col-${columns}`);
      gridContainer.className = list.join(' ');
    }
  }, [width, gridRef, expanded]);
  const gridLoadMore = useCallback(() => {
    let documentHeight = document.body.scrollHeight;
    let currentScroll = window.scrollY + window.innerHeight;
    let modifier = 200;
    if (currentScroll + modifier > documentHeight) {
      //   if (gridLoadedIndexes === feed.length) return;
      //   else gridLoadedIndexes = feed.length;
      onLoadMore();
    }
  }, [items, onLoadMore]);

  // useEffect to handle infinite scrolling in grid view
  useEffect(() => {
    if (items.length > 0) {
      document.addEventListener('scroll', gridLoadMore);
      return () => {
        document.removeEventListener('scroll', gridLoadMore);
      };
    }
  }, [items, onLoadMore]);

  return (
    <div ref={gridRef} className={`items-masonry-grid`}>
      {items.map((item, i) => (
        <div className='items-masonry-grid__item' key={item[keyField]}>{children(item, i)}</div>
      ))}
      {children}
    </div>
  );
}
