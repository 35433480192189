import React from 'react';
import { AntiClockwiseIcon } from '../icons';

import './styles.css';

interface Proptypes {
  onUndoClick: () => void;
  children?: React.ReactChild | React.ReactChildren;
  label?: string;
}

const UndoBox = ({ children, onUndoClick, label }: Proptypes) => {
  return (
    <div className="undo-box">
      {children && <span className=".undo-box__message">{children}</span>}
      <button
        className="undo-box__button"
        onClick={onUndoClick}
      >
        <AntiClockwiseIcon /> {label || 'Undo'}
      </button>
    </div>
  );
};

export default UndoBox;
