import {
  DISLIKE_GROUP,
  LIKE_GROUP,
  UPDATE_GROUPS_LIKES_AND_COMMENTS,
  UPDATE_GROUP_COMMENT_COUNT,
  groupsLikesAndCommentsPayloadType,
} from '../store/groupsLikesAndComments';

export function updateGroupsLikesAndComments(
  payload:
    | groupsLikesAndCommentsPayloadType
    | Array<groupsLikesAndCommentsPayloadType>
) {
  return {
    type: UPDATE_GROUPS_LIKES_AND_COMMENTS,
    payload,
  };
}

export function likeGroup(
  groupIdOrIds: Array<number> | number
) {
  return {
    type: LIKE_GROUP,
    payload: Array.isArray(groupIdOrIds)
      ? groupIdOrIds.map((groupId) => ({
        groupId,
      }))
      : {
        groupId: groupIdOrIds,
      },
  };
}
export function dislikeGroup(
  groupIdOrIds: number | number
) {
  return {
    type: DISLIKE_GROUP,
    payload: Array.isArray(groupIdOrIds)
      ? groupIdOrIds.map((groupId) => ({
        groupId,
      }))
      : {
        groupId: groupIdOrIds,
      },
  };
}

export function updateGroupCommentCount(
  data:
    | Array<{
        id: number;
        count: number;
      }>
    | {
        id: number;
        count: number;
      }
) {
  return {
    type: UPDATE_GROUP_COMMENT_COUNT,
    payload: Array.isArray(data)
      ? data.map(({ id, count }) => {
        return {
          groupId: id,
          data: {
            commentsData: {
              count,
            },
          },
        };
      })
      : {
        groupId: data.id,
        data: {
          commentsData: {
            count: data.count,
          },
        },
      },
  };
}
