import { getDomainWithoutSubdomain } from '../helpers';
import * as config from '../settings.json';
import logging from './Logging';

export const setToken = async (access_token: string, userId?: number) => {
  try {
    // set the token with 365 days expiry
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

    if (config.sharedToken) {
      if (typeof window !== 'undefined' && access_token.length) {
        const domainName = getDomainWithoutSubdomain(window.location.href);
        // For localhost environment
        const prefix = domainName.split('.').length > 1 ? '.' : '';
        document.cookie = 's_token=' + access_token + `; domain=${prefix}${domainName}; expires=${date.toUTCString()}; path=/`;
      }
    }

    if (access_token.length) {
      document.cookie = '_token_=' + access_token + `; expires=${date.toUTCString()}; path=/`;
      return logging(`Successfully set token: ${access_token} for ${userId} expiry: ${date.toUTCString()}`);
    }
  } catch (e) {
    // nothing to do here
  }
};
