import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const Apple = ({ className,width,height }: Proptypes) => {
  return (
    <svg
      width={width ||  "20"}
      height={ height || "24"}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.2807 18.424C18.9328 19.2275 18.5211 19.9672 18.0441 20.6472C17.3939 21.5743 16.8615 22.216 16.4512 22.5724C15.8152 23.1573 15.1337 23.4568 14.404 23.4739C13.8801 23.4739 13.2483 23.3248 12.5129 23.0224C11.7751 22.7214 11.097 22.5724 10.4771 22.5724C9.82683 22.5724 9.12947 22.7214 8.38355 23.0224C7.6365 23.3248 7.03469 23.4824 6.57456 23.498C5.87478 23.5278 5.17728 23.2197 4.48105 22.5724C4.03669 22.1848 3.48087 21.5204 2.81503 20.5791C2.10063 19.5739 1.51329 18.4084 1.05317 17.0795C0.560384 15.6442 0.313354 14.2543 0.313354 12.9087C0.313354 11.3673 0.646418 10.0379 1.31354 8.92385C1.83784 8.029 2.53534 7.32312 3.40832 6.80493C4.2813 6.28674 5.22456 6.02267 6.24036 6.00578C6.79618 6.00578 7.52506 6.1777 8.43083 6.51559C9.33405 6.85462 9.914 7.02655 10.1683 7.02655C10.3584 7.02655 11.0026 6.82552 12.0948 6.42473C13.1277 6.05305 13.9994 5.89916 14.7135 5.95978C16.6485 6.11595 18.1023 6.87876 19.0691 8.25303C17.3385 9.30163 16.4824 10.7703 16.4995 12.6544C16.5151 14.122 17.0475 15.3432 18.0938 16.3129C18.568 16.7629 19.0975 17.1107 19.6867 17.3578C19.5589 17.7283 19.4241 18.0832 19.2807 18.424ZM14.8427 0.960131C14.8427 2.11039 14.4224 3.18439 13.5848 4.17847C12.574 5.36023 11.3513 6.04311 10.0254 5.93536C10.0086 5.79736 9.99876 5.65213 9.99876 5.49951C9.99876 4.39526 10.4795 3.21349 11.3331 2.24724C11.7593 1.75801 12.3014 1.35122 12.9587 1.02671C13.6146 0.707053 14.235 0.530273 14.8185 0.5C14.8356 0.653772 14.8427 0.807554 14.8427 0.960116V0.960131Z"
        fill="white"
      />
    </svg>
  );
};

export default Apple;
