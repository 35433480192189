import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['id', 'ID'],
  ['scrap_id', 'Int!'],
  ['file_name', 'String'],
  ['file_path', 'String!'],
  ['order', 'String'],
  ['title', 'String'],
  ['desc', 'String'],
  ['is_heic', 'Boolean = false']
]);
export default function UPSERT_SCRAP_IMAGE(responseString = '') {
  return gql`
    mutation UpsertScrapImage(${parametersTypeString}) {
      upsertScrapImage(${parametersAssignString}) {
        ${defaultResponse}
        ${responseString ? `data { ${responseString} }` : ''}
      }
    }
  `;
}
