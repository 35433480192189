import React from 'react';
import LabelTextInput from '../../../Global/LabelTextInput';
import { CountrySelector } from 'react-international-phone';
import { onlyNumbers } from '../../../../helpers';
import {
  defaultCountries,
  parseCountry,
} from 'react-international-phone';
import {allowedCountries} from '../../../../settings.json'
interface Proptypes {
  activeTab: string;
  countryCode: { dialCode; country };
  val: string;
  inputChangeHandler: (val: string) => void;
  phoneNumber: string;
  setPhoneNumber: (val: string) => void;
  setCountryCode: ({ dialCode, country }) => void;
  showSuggestionHandler: (val: boolean) => void;
}

const InvitationFields = (prop: Proptypes) => {
  const {
    activeTab,
    countryCode,
    setCountryCode,
    setPhoneNumber,
    val,
    inputChangeHandler,
    phoneNumber,
    showSuggestionHandler,
  } = prop;
  if (activeTab === 'Email') {
    return (
      <LabelTextInput
        label='Search'
        placeholder={`${
          activeTab === 'Email' 
            ? 'Search by name or email'
            : 'Search by phone'
        }`}
        additionalClassName="profile-input"
        value={val}
        onChange={(val) => {
          inputChangeHandler(val);
        }}
        showSuggestion={true}
        showSuggestionHandler={() => showSuggestionHandler(true)}
      />
    );
  }
  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return allowedCountries.includes(iso2);
  });
  
  return (
    <div className="form-group auth__phone">
      <CountrySelector
        selectedCountry={countryCode.country}
        className="auth__phone-country"
        onSelect={({ dialCode, iso2: country}) => {
          setCountryCode({ dialCode, country });
        }}
        countries={countries}
      />
      <div className="auth__phone-country__dialcode">
        +{countryCode.dialCode}
      </div>
      <LabelTextInput
        placeholder="(201) 555-0123"
        inputType={'text'}
        additionalClassName="profile-input"
        inputName={'phoneNumber'}
        value={phoneNumber}
        className={`auth__phone-input`}
        onChange={(val) => {
          setPhoneNumber(
            onlyNumbers({
              value: val,
              oldValue: phoneNumber,
              max: 13,
              compareLength: true,
            })
          );
        }}
        required
      />
    </div>
  );
};

export default InvitationFields;
