import React, { useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { BrokenLink } from '../../Global/Error/Index';
import {
  parseNote,
  returnScrapSlug,
  returnScrapTypeName,
  uid_scrappi,
} from '../../../helpers';
import { ENTRY_DATA } from '../../../containers/data/getSingleEntryData';
import { RequestStatusObjectType } from '../../../types/requestStatusType';
import { entryTypes } from '../../../types/feed';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import './styles.css';
import { returnShareLink } from '../../../helpers/returnShareLinks';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import SEOMeta from '../../Meta/SEOMeta';
import CardWrapper from '../../Cards/CardWrapper';
import CardLinkPreview from '../../Cards/CardComponents/CardLinkPreview';
import RenderThumbnail from '../../Global/RenderThumbnail';
import { GalleryImagesMetaType } from '../../Gallery/types/imagesTypes';
import ReadMoreText from '../../Cards/ReadMoreText';
import {
  AllScrapsViewSettingsType,
  CollectionsViewSettingsType,
} from '../../../types/viewSettings';
import RenderTitleAndNotes from '../../Cards/CardComponents/RenderTitleAndNotes';
import { setScrapImagesPopup } from '../../../redux/action/utils';
import { returnImageURL } from '../../Gallery/helpers/returnImageURL';

/**
 * TODO: Refactor to manage prop types
 */
const ScrapDetailsPublic = (props) => {
  const dispatch = useDispatch()
  const { match } = props;
  const client = useApolloClient();
  const scrapId = match.params.id;
  const username = match.params.username || null;
  const private_key = match.params.private_key;
  const [fetchStatus, setFetchStatus] = useState<RequestStatusObjectType>({
    status: 'not-started',
  });
  // const [scrapData, setScrapData] = useState<entryTypes>(null);
  //CREATE UUID FROM TYPE AND SCRAP ID
  let typeID = returnScrapTypeName(parseInt(scrapId.split('-')[0]));
  let scrapID = scrapId.split('-')[1];
  let uID = uid_scrappi(scrapID + typeID);
  const user = useSelector((state: ReduxStateType) => state.user);
  let settings: CollectionsViewSettingsType | AllScrapsViewSettingsType;
  const { error, loading, data } = useQuery(ENTRY_DATA, {
    variables: {
      private_key: private_key,
      uid: uID,
      username,
    },
  });

  
  let shareUrl = null;

  if (error || data?.entry?.data?.state === null) {
    return (
      <div className="details-full share">
        <BrokenLink message="This Scrap is either inaccessible or maybe the link is broken." />
      </div>
    );
  }

  if (loading) {
    return null;
  }

  const scrapData: entryTypes = data?.entry?.data;

  const clickConfigData=()=>{
    if(scrapData.type==='page'||scrapData.type==='product')
    {
      return null;
    }
    else if(scrapData.type==='social' || scrapData.type==='video')
    {
      return scrapData
    }
    else {
      if(scrapData.image)
      {
        return scrapData.image
      }
      else
        return scrapData.thumbnail
    }

  }
  shareUrl = returnShareLink(
    'scrap',
    returnScrapSlug(scrapData),
    scrapData?.userName,
    false,
    scrapData?.state,
    scrapData?.private_key
  );

  const parsedImagesMeta: GalleryImagesMetaType[] = scrapData.images_meta
    ? JSON.parse(scrapData.images_meta)
    : [];
  parsedImagesMeta.sort((a,b) => a.order - b.order);  
  const images: string[] = [];
  if (Array.isArray(parsedImagesMeta)) {
    images.push(...parsedImagesMeta.map((img) => img.image));
  }
  let descriptionMapper = ['document', 'product', 'gallery'].includes(
    scrapData.type
  )
    ? 'desc'
    : 'notes';
  if (scrapData.type === 'annotation') descriptionMapper = 'comment';
  const title = scrapData.meta_title || scrapData.title || scrapData.page_title;
  const description = scrapData.meta_desc || parseNote(scrapData[descriptionMapper])
  let image = scrapData.image ||scrapData.thumbnail;
  if(scrapData.type === 'gallery' && parsedImagesMeta.length) image = returnImageURL(parsedImagesMeta[0].image) 
  return (
    <>
      <SEOMeta
        title={title}
        description={description}
        image={image}
      />

      <div className="scrap-details-public">
        <section className="scrap-details-public__scroll">
          <CardWrapper
            entry={scrapData}
            view="detailShare"
            containerClass="card-base card__page"
            cta={scrapData.updated_url || scrapData.url}
          >
            {() =>
              scrapData.type === 'gallery' ? (
                <>
                  <div
                    className="card-gallery__images"
                  >
                    {/*  
                     Deleted:  renderThumbnail Component
                     reusing  RenderThumbnail Global Component
                 */}
                    <RenderThumbnail images={images} needWrapper={false} />
                  </div>

                  {scrapData.auto_generate && (
                    <span className="auto-generate__label">
                      Auto Generated Image
                    </span>
                  )}
                </>
              ) : scrapData.type === 'annotation' ? (
                <CardLinkPreview
                  linkData={{
                    link: scrapData.updated_url || scrapData.url,
                    domain: scrapData.domain,
                    title: scrapData.meta_title,
                    description: scrapData.meta_desc,
                    favicon: scrapData.favicon || scrapData.original_favicon,
                  }}
                  highlight
                >
                  <div className="card__highlight--wrapper">
                    <div className="card__highlight">
                      <ReadMoreText
                        content={parseNote(scrapData.source_text)}
                        maxHeight={150}
                      />
                    </div>
                  </div>
                </CardLinkPreview>
              ) : scrapData.type === 'note' ? (
                <div className="card__notes-display">
                  {(!settings || scrapData.type === 'note') && (
                    <RenderTitleAndNotes entry={scrapData} type="notes" />
                  )}
                </div>
              ) : (
                <>
                  <CardLinkPreview
                    linkData={{
                      image: scrapData.image || scrapData.thumbnail,
                      link: scrapData.updated_url || scrapData.url,
                      domain: scrapData.domain,
                      title: scrapData.meta_title,
                      description: scrapData.meta_desc,
                      favicon: scrapData.favicon || scrapData.original_favicon,
                    }}
                    config={scrapData.config}
                    entry={scrapData}
                    imageClickConfigData={clickConfigData()}
                    playIcon={scrapData?.type==='video'?true:false}
                  />
                </>
              )
            }
          </CardWrapper>
        </section>
      </div>
    </>
  );
};

export default ScrapDetailsPublic;
