import { gql } from '@apollo/client';
import { defaultResponse } from './defaultResponse';

export const GET_SEARCH_HISTORIES = gql`
  query GetSearchHistories(
    $id: ID
    $search_term: String
    $first: Int
    $page: Int
  ) {
    searchHistories(
      id: $id
      search_term: $search_term
      first: $first
      page: $page
    ) {
      data {
        id
        search_term
      }
    }
  }
`;

export const CREATE_SEARCH_HISTORY = gql`
  mutation UpsertSearchHistory($search_term: String!) {
    upsertSearchHistory(search_term: $search_term) {
      ${defaultResponse}
    }
  }
`;

export const DELETE_SEARCH_HISTORY = gql`
  mutation DeleteSearchHistory($id: ID, $all: Boolean) {
    deleteSearchHistory(id: $id, all: $all) {
      ${defaultResponse}
    }
  }
`;
