import React from 'react';
import { TrashIcon, TrashOutlineIcon } from '../Global/icons';

interface Proptypes {
  showDelete?: boolean;
  onDeleteClick: () => void;
}

const ScrapSavedFooter = ({ onDeleteClick,showDelete }: Proptypes) => {
  return (
    <div className="scrap-saved__footer">
      {!showDelete && (
        <span
          onClick={onDeleteClick}
          className="scrap-saved__footer-delete new-popup__header-button"
        >
          <TrashOutlineIcon className="new-popup__header-button__icon" />
        </span>
      )}
    </div>
  );
};

export default ScrapSavedFooter;
