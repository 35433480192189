import React from 'react';

interface Proptypes {
  className?: string;
}

const DownArrow = ({ className }: Proptypes) => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.96967 0.21967C0.676777 0.512563 0.676777 0.987437 0.96967 1.28033L5.46967 5.78033C5.76256 6.07322 6.23744 6.07322 6.53033 5.78033L11.0303 1.28033C11.3232 0.987437 11.3232 0.512564 11.0303 0.21967C10.7374 -0.0732229 10.2626 -0.0732229 9.96967 0.21967L6 4.18934L2.03033 0.21967C1.73744 -0.0732233 1.26256 -0.0732233 0.96967 0.21967Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default DownArrow;
