import React, { useRef } from 'react';
import { ScrapImageData } from '../../../containers/ScrapImagesPopupContainer';
import { ExclamationIcon, PlusIcon } from '../../Global/icons';
import config from '../../../helpers/config';

import './styles.css';

interface Proptypes {
  list: ScrapImageData[];
  children: (imageData: ScrapImageData, index: number) => React.ReactElement;
  onFilesChange?: (files: FileList) => void;
  isLimitReached?: boolean;
  disabled?: boolean;
}

const ScrapImagesList = ({
  list,
  children,
  onFilesChange,
  isLimitReached,
  disabled
}: Proptypes) => {
  const inputRef = useRef<HTMLInputElement>(null);
  async function fileChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    if(!onFilesChange) return
    try {
      let allFiles = (e.target as HTMLInputElement).files;
      e.persist();
      if (allFiles.length) {
        await onFilesChange(allFiles);
      } else {
        throw new Error('No files selected');
      }
    } catch (error) {
      //
    }
    
    e.target.value = null;
  }
  return (
    <div className={`scrap-images-list__outer-container${disabled ? ' scrap-images-list__outer-container--disabled' : ''}`}>
      <ul className="scrap-images-list">
        {list.map((item, i) => children(item, i))}
      </ul>
      <input
        multiple
        accept={config.supported_images_accept_list}
        onChange={fileChangeHandler}
        type="file"
        ref={inputRef}
      />{' '}
      {isLimitReached ? (
        <span className='scrap-images-list__limit-reached-message'>
          <ExclamationIcon />
          Max Scrap size reached - uploading the first images selected
        </span>
      ) : (
        <span
          className={'scrap-images-list__add-more'}
          onClick={() => inputRef.current.click()}
        >
          <span className="scrap-images-list__add-more-text">
            Add More Images
          </span>
          <PlusIcon className={'scrap-images-list__add-more-icon'} />
        </span>
      )}
    </div>
  );
};

export default ScrapImagesList;
