import { ImagesItemsType } from '../components/Gallery/types/imagesTypes';
export const parseImagesMeta = (meta: string) => {
  try {
    if (typeof meta !== 'string') throw new Error('wrong meta type');
    const parsedImagesMeta: ImagesItemsType[] = JSON.parse(meta);
    if (!Array.isArray(parsedImagesMeta))
      throw new Error('parsed meta is not array');
    return parsedImagesMeta;
  } catch (error) {
    return [];
  }
};

