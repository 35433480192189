/* eslint-disable @typescript-eslint/no-explicit-any */
import { SupabaseClient, RealtimePostgresChangesPayload } from '@supabase/supabase-js';
export interface supabaseStoreType {
  client: SupabaseClient<any, 'public', any>;
  message: RealtimePostgresChangesPayload<{
    [key: string]: any;
  }>;
  invitationsCountSetter?: {
    value: number
  }
}
interface actionType {
  type: 'SET_SUPABASE_CLIENT' | 'SET_SUPABASE_MESSAGE' | 'SET_SUPABASE_INVITATIONS_COUNT_SETTER';
  payload: supabaseStoreType;
  invitationsCountSetter?: {
    value: number
  }
}

const initialState: supabaseStoreType = {
  client: null,
  message: null,
  invitationsCountSetter: null,
};

export default function supabase(
  state = initialState,
  { type, payload }: actionType
) {
  switch (type) {
    case 'SET_SUPABASE_CLIENT':
      return { ...state, client: payload.client };
    case 'SET_SUPABASE_MESSAGE':
      return { ...state, message: payload.message };
    case 'SET_SUPABASE_INVITATIONS_COUNT_SETTER':  
      return { ...state, invitationsCountSetter: payload.invitationsCountSetter }; 
    default:
      return state;
  }
}
