import React, { useState, useRef, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { CreateScrapImageDataType } from '../../../../helpers/createScrapHelpers';
import { entryTypes } from '../../../../types/feed';
import AddImage from '../../../Global/AddImage';
import Icon from '../../../Global/Icon';
import ImageCropper from '../../../Global/ImageCropper';
import UndoBox from '../../../Global/UndoBox';

import './styles.css';

interface Proptypes {
  config?: string;
  image: string;
  imageData: CreateScrapImageDataType;
  uneditable: boolean;
  onImageChange: (data: CreateScrapImageDataType) => void;
  placeholder?: string;
  changeImageInScrap?: (url?: string) => void;
  initialImage?: string;
  setEditImage?: (val: boolean) => void;
  scrapType?: string;
  uid?:string;
  thumbnail?:string;
  setScrap?: (val: entryTypes) => void;
  scrap?:entryTypes;
}
const CreateScrapAddImage = (props: Proptypes) => {
  const {
    image: currentImage,
    uneditable,
    onImageChange,
    placeholder,
    imageData: currentImageData,
    changeImageInScrap,
    config,
    initialImage,
    setEditImage,
    scrapType,
    uid,
    thumbnail,
    setScrap,
    scrap
  } = props;
  const cropperRef = useRef(null);
  const [disableCrop, setDisableCrop] = useState(false);
  const [imageFileType, setImageFileType] = useState<string>(null);
  const [undo, setUndo] = useState<{ url: string; image?: File | string }>(
    null
  );
  const [cropUrl, setCropUrl] = useState<string>(null);
  function undoClickHandler() {
    if (undo.image) {
      if (typeof undo.image === 'object') {
        onImageChange({ type: 'file', image: undo.image });
        changeImageInScrap(URL.createObjectURL(undo.image as File));
      } else if (undo.image.startsWith('http')) {
        onImageChange({ type: 'cdn-url', image: undo.image });
        changeImageInScrap(undo.image);
      } else {
        onImageChange({ type: 'b64Url', image: undo.image });
        changeImageInScrap(undo.image);
      }
    } else if (undo.url) {
      changeImageInScrap(undo.url);
    }

    setUndo(null);
  }
  function cropHandler(blob: Blob) {
    const file = new File([blob], 'Scrap-Image', { type: blob.type });
    changeImageInScrap(URL.createObjectURL(file));
    setCropUrl(null);
    onImageChange({ type: 'file', image: file });
  }

  function imageChangeHandler(
    image: string | File,
    type: 'file' | 'cdn-url' | 'b64Url' | 'remove'
  ) {
    setUndo(
      type === 'remove'
        ? {
          url: initialImage || currentImage,
          image: initialImage ? null : (currentImageData?.image as File),
        }
        : null
    );
    if (type === 'remove') {
      onImageChange({ image, type });
      changeImageInScrap();
    } else if (type === 'file') {
      // onImageChange({ image, type });
      setCropUrl(URL.createObjectURL(image as File));
      setImageFileType((image as File).type);
    } else if (type === 'cdn-url') {
      setCropUrl(image as string);
      // onImageChange({ image, type });
    } else if (type === 'b64Url') {
      setCropUrl(image as string);
      // onImageChange({ image, type });
    }
  }

  useEffect(() => {
    if (uneditable && undo) setUndo(null);
  }, [uneditable, undo]);
  useEffect(() => {
    if (cropUrl) {
      setDisableCrop(false);
    }
  }, [cropUrl]);
  return (
    <div
      className={`create-scrap-add-image${
        !currentImage ? ' create-scrap-add-image--no-image' : ''
      }`}
    >
      <div className="create-scrap-add-image__selector">
        <AddImage
          placeholder={placeholder}
          unEditable={uneditable}
          image={currentImage}
          convertCdnToBase64
          onChange={imageChangeHandler}
          config={config}
          removeButtonLabel="Replace"
          defaultAll={true}
          setEditImage={setEditImage}
          scrapType={scrapType}
          uid={uid}
          thumbnail={thumbnail}
          setScrap={setScrap}
          scrap={scrap}
        />
      </div>
      {undo && (
        <div className="create-scrap-add-image__undo">
          <UndoBox
            label="Revert Original Image"
            onUndoClick={undoClickHandler}
          />
        </div>
      )}
      <Popup
        className="create-scrap-add-image__cropper"
        modal
        closeOnEscape={false}
        open={!!cropUrl}
        onClose={setCropUrl.bind(null, null)}
      >
        {(close) => (
          <>
            <div className="modal-window__header">
              <h2 className="modal-window__heading">Crop Image</h2>

              <span
                className="modal-window__close"
                onClick={() => {
                  close();
                  setUndo({
                    url: initialImage || currentImage,
                    image: initialImage
                      ? null
                      : (currentImageData?.image as File),
                  });
                  setDisableCrop(true);
                  
                }}
              >
                <Icon iconClass="x-circle" size="big" />
              </span>
            </div>
            <div className="upload-file__cropper">
              <ImageCropper
                imageUrl={cropUrl}
                onCrop={cropHandler}
                ref={cropperRef}
                imageType={imageFileType}
              />

              <div className="upload__action-wrapper">
                {/* <button className="button button__link" {...getRootProps()}>
                  Upload Another
                </button> */}

                <button
                  disabled={disableCrop}
                  className="button button__primary"
                  onClick={(e) => {
                    setDisableCrop(true);
                    cropperRef?.current?.onCropClick();
                  }}
                >
                  Save crop
                </button>
              </div>
            </div>
          </>
        )}
      </Popup>
    </div>
  );
};

export default CreateScrapAddImage;
