import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['first', 'Int = 500'],
  ['page', 'Int'],
  ['username', 'String'],
  ['state', 'Int = 3'],
]);
export default function COLLECTIONS_VIA_STATE(
  responseString = '',
) {
  return gql`
    query CollectionsViaState(${parametersTypeString}) {
        collectionsViaState(${parametersAssignString}) {
        ${defaultResponse}
        ${responseString}
      }
    }
  `;
}

export function COLLECTIONS_FOR_PROFILE() {
  const responseString = `
    data {
        id
        title
        slug
        used_at
        updated_at
        created_at
        state
        user_id
        cover_image
        display_name
        avatar
        user_name
        show_author
        members {
            user_id
            display_name
            avatar
            user_name
            is_trusted
        }
        reaction_count
        comment_count
        is_trusted
    }
    paginatorInfo {
        total
        count
        firstItem
    }
    scrapsCount {
        id
        scraps_count
    }
  `;
  return COLLECTIONS_VIA_STATE(responseString)
}
