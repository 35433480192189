import { useState, useEffect, useCallback } from 'react';

// CUSTOM HOOK FOR RETURNING SCREEN DIMENSTIONS ON SCREEN RESIZE
const useWindowResize = () => {
  const [width, setWidth] = useState(
    typeof window === 'undefined' ? 0 : window.innerWidth
  );
  const [height, setHeight] = useState(
    typeof window === 'undefined' ? 0 : window.innerHeight
  );
  const windowResizeHandler = useCallback(() => {
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('resize', (e) => {
      setTimeout(() => {
        windowResizeHandler();
      }, 200);
    });
    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, []);
  return { width, height };
};

export default useWindowResize;
