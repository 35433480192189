import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int'],
  ['tags', '[InputTagId]'],
]);
export default function REMOVE_SCRAP_TAGS(responseString = '') {
  return gql`
    mutation RemoveScrapTags(${parametersTypeString}) {
      removeScrapTags(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}
