import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['scrap_id', 'Int'],
  ['collections', '[InputCollectionId]'],
]);
export default function UPSERT_SCRAP_COLLECTIONS(responseString = '') {
  return gql`
    mutation UpsertScrapCollections(${parametersTypeString}) {
      upsertScrapCollections(${parametersAssignString}) {
        ${defaultResponse}
        ${responseString ? `data { ${responseString} }` : ''}
      }
    }
  `;
}
