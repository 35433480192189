import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useQuery, gql } from '@apollo/client';

import Navigation from '../components/Navigations';
import { loadVisibilityStates } from '../redux/action/visibilityStatesAction';
import { addTopic } from '../redux/action/topicActions';
import '../components/Navigations/SecondaryNavigation.css';
import { loadUser } from '../redux/action/userActions';
import { setDimensions, toggleFilterState } from '../redux/action/utils';
import { GET_USER_SETTINGS } from '../containers/data/getUserSettings';
import {
  setAllViewSettings,
  setLoadingSettings,
} from '../redux/action/viewSettingsActions';
import { parseSettingsFromString } from '../helpers/parseViewSettings';
import { getToken } from '../apollo/getToken';
import { getBrowserTimezone } from '../helpers';
import useWindowResize from '../helpers/customHooks/useWindowResize';
const GET_BASE_DATA = gql`
  {
    profile {
      data {
        user_id  
        can_send_invite
        userName
        avatar
        userEmail
        phone
        country
        timezone
        bio
        headline
        address
        login_via
        onboard_status
        register_from
        cover_image
        social_media_accounts
        url
        location
        display_name
        services
        main_service
      }
    }
    states {
      data {
        id
        title
        type
      }
    }
  }
`;

const generateSettingsName = (type: string) => {
  switch (type) {
    case 'collections':
      return 'collectionsViewSettings';
    case 'collectionScraps':
      return 'collectionScrapsViewSettings';
    case 'allScraps':
      return 'allScrapsViewSettings';
    case 'stories':
      return 'storiesViewSettings';
    case 'myScrapsPageViewSettings':
      return 'myScrapsPageViewSettings';
    case 'scrapLayoutSettings':
      return 'scrapLayoutSettings';
    default:
      return 'undefined';
  }
};

const NavigationWrapper = ({
  topics,
  activeTopic,
  user,
  utils,
  hideFilter = false,
  showFullScreen = false,
}) => {
  const { width, height } = useWindowResize();
  const [load, setLoad] = useState(true);
  const { pathname } = useLocation();
  const [currentHighlight, setCurrentHighlight] = useState(
    pathname?.split('/')[1] || 'dashboard'
  );

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { loading, error, data } = useQuery(GET_BASE_DATA, {
    skip: !getToken(),
  });

  const { data: settingsData, loading: settingsLoading } = useQuery(
    GET_USER_SETTINGS,
    {
      variables: {
        type: '',
      },
      skip: !getToken(),
    }
  );
  const dispatch = useDispatch();

  const returnActivePathName = (path) => {
    switch (path) {
      case 'collections':
      case 'c':
        return 'collections';

      case '':
        return 'dashboard';
      default:
        return null;
    }
  };
  //Used to set dimensions of browser in redux
  useEffect(() => {
    dispatch(setDimensions({ width, height }));
  }, [width, height]);
  
  useEffect(() => {
    if (data && data.states) {
      dispatch(loadVisibilityStates(data.states.data));
      dispatch(
        loadUser({ ...data.profile.data, timezone: getBrowserTimezone() })
      );
      dispatch(toggleFilterState(hideFilter));
      setLoad(false);
    }
    if (!loading) {
      setLoad(false);
    }
  }, [data]);
  useEffect(() => {
    if (!settingsLoading && settingsData) {
      const obj = {};
      Array.isArray(settingsData.userSettings) &&
        settingsData.userSettings.forEach((item) => {
          if (item.type && item.meta) {
            obj[generateSettingsName(item.type)] = parseSettingsFromString(
              item.meta
            );
          }
        });
      if (Object.keys(obj).length > 0) {
        dispatch(setAllViewSettings(obj));
      } else dispatch(setLoadingSettings(false));
    }
  }, [settingsData, settingsLoading]);

  useEffect(() => {
    if (pathname.startsWith('/scrap/')) {
      setCurrentHighlight('dashboard');
    } else setCurrentHighlight(returnActivePathName(pathname?.split('/')[1]));
  }, [pathname]);

  // if the user is editing the story, hide the navigation
  const activeRouteHasEdit =
    (pathname.indexOf('/edit') >= 0 && pathname.indexOf('/story') >= 0) ||
    (pathname.indexOf('/preview') >= 0 && pathname.indexOf('/story') >= 0) ||
    pathname.indexOf('/published') >= 0;
  if (activeRouteHasEdit) {
    return null;
  }

  if (showFullScreen) return null;

  return (
    <Navigation
      topics={topics}
      load={load}
      activeTopic={activeTopic}
      user={user}
      addNewTopic={(topic) => dispatch(addTopic(topic))}
      utils={utils}
      currentHighlight={currentHighlight}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => {
  return {
    topics: state.topics,
    activeTopic: state.activeTopic,
    user: state.user,
    utils: state.utils,
  };
};

export default connect(mapStateToProps)(NavigationWrapper);
