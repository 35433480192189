import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const CopyIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-5.24537e-07 8L-1.74846e-07 16C-7.8281e-08 18.2091 1.79086 20 4 20L12 20C14.2091 20 16 18.2091 16 16C18.2091 16 20 14.2091 20 12L20 8.5L20 4C20 1.79086 18.2091 -7.95947e-07 16 -6.99382e-07L8 -3.49691e-07C5.79086 -2.53127e-07 4 1.79086 4 4C1.79086 4 -6.21101e-07 5.79086 -5.24537e-07 8ZM6 4L12 4C14.2091 4 16 5.79086 16 8L16 14C17.1046 14 18 13.1046 18 12L18 8.5L18 4C18 2.89543 17.1046 2 16 2L8 2C6.89543 2 6 2.89543 6 4ZM4 18C2.89543 18 2 17.1046 2 16L2 8C2 6.89543 2.89543 6 4 6L12 6C13.1046 6 14 6.89543 14 8L14 16C14 17.1046 13.1046 18 12 18L4 18Z"
        fill="#969696"
      />
    </svg>
  );
};

export default CopyIcon;
