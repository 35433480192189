import { entryTypes } from "../../types/feed";
import { utilsTypes } from "../../types/utils";

export const toggleSidebarAction = (status: boolean) => {
  return {
    type: 'TOGGLE_NAVIGATION',
    expanded: status,
  };
};

export const toggleFilterState = (hide: boolean) => {
  return {
    type: 'UPDATE_FILTER_STATE',
    hide,
  };
};
export const toggleOrganizeCollection = (organizeCollection: boolean) => {
  return {
    type: 'TOGGLE_ORGANIZE_COLLECTION',
    organizeCollection,
  };
};

export const setReloadSideNavFilters = (reloadSideNavFilters: boolean) => {
  return {
    type: 'SET_RELOAD_SIDE_NAV_FILTERS',
    reloadSideNavFilters
  }
}
export const setReloadSideNavCollections = (reloadSideNavCollections: boolean) => {
  return {
    type: 'SET_RELOAD_SIDE_NAV_COLLECTIONS',
    reloadSideNavCollections
  }
}


export const setReloadSideNavShares = (reloadSideNavShares: boolean)=>{
  return {
    type: 'SET_RELOAD_SIDE_NAV_SHARES',
    reloadSideNavShares
  }
}
export const setSavedScrap = (savedScrap: string | entryTypes) => {
  return {
    type: 'SET_SAVED_SCRAP',
    savedScrap
  }
}

export const setBackUrl = (backUrl?: string|undefined,scrollToBottom?:boolean) => {
  return {
    type: 'SET_BACK_URL',
    backUrl,
    scrollToBottom
  }

}
export const setScrapPopup = (scrapPopup: utilsTypes['scrapPopup']) => {
  return {
    type: 'SET_SCRAP_POPUP',
    scrapPopup,
  }
}

export const setScrapImagesPopup = (scrapImagesPopup: utilsTypes['scrapImagesPopup']) => {
  return {
    type: 'SET_SCRAP_IMAGES_POPUP',
    scrapImagesPopup,
  }
}

export const setWarningPopup = (warningPopup: utilsTypes['warningPopup']) => {
  return {
    type: 'SET_WARNING_POPUP',
    warningPopup,
  }
}


export const setDeleteScrapPopup = (deleteScrapPopup: utilsTypes['deleteScrapPopup']) => {
  return {
    type: 'SET_DELETE_SCRAP_POPUP',
    deleteScrapPopup,
  }
}

export const setInvitationPopup = (showInvitationPopup: utilsTypes['showInvitationPopup']) => {
  return {
    type: 'SET_INVITATION_POPUP',
    showInvitationPopup,
  }
}


export const setImageCarouselPopup =(imageCarouselPopup:utilsTypes['imageCarouselPopup'])=>{
  return{
    type:'SET_IMAGE_CAROUSEL_POPUP',
    imageCarouselPopup
  }
}

export const setGroupSharePopup = (groupSharePopup:utilsTypes['groupSharePopup'])=>{
  return{
    type:'SET_GROUP_SHARE_POPUP',
    groupSharePopup
  }
}

export const setShowMember =(showMemberPopup:utilsTypes['showMemberPopup'])=>{
  return{
    type: "SET_SHOW_MEMBER_POPUP",
    showMemberPopup
  }
}

export const setHideHistory = (status: boolean) => {
  return {
    type: 'SET_HIDE_HISTORY',
    hideHistory: status,
  };
};
export const setIsMainPageLoading = (isMainPageLoading: boolean) => {
  return {
    type: 'SET_SCRAPS_LOADING',
    isMainPageLoading,
  };
};
export const setCollectionAction = (collectionAction: utilsTypes['collectionAction']) => {
  return {
    type: 'SET_COLLECTION_ACTION',
    collectionAction
  }
}

export const setCollectionSettingsPopup = (collectionSettingsPopup: utilsTypes['collectionSettingsPopup'],scrollToBottom?:boolean,onSaved?:()=>void) => {
  return {
    type: 'SET_COLLECTION_SETTINGS_POPUP',
    collectionSettingsPopup,
    scrollToBottom,
    onSaved
  }
}
export const setPlatform = (platform: utilsTypes['platform']) => {
  return {
    type: 'SET_PLATFORM',
    platform,
  }
}

export const setScrapEmbedPopup = (scrapEmbedPopup: utilsTypes['scrapEmbedPopup']) => {
  return {
    type: 'SET_SCRAP_EMBED_POPUP',
    scrapEmbedPopup,
  }
}
export const setImageDisplayPopup = (imageDisplayPopup: utilsTypes['imageDisplayPopup']) => {
  return {
    type: 'SET_IMAGE_DISPLAY_POPUP',
    imageDisplayPopup,
  }

}
export const setDimensions = (dimensions: utilsTypes['dimensions']) => {
  return {
    type: 'SET_DIMENSIONS',
    dimensions
  }
}

export const setNewScrapPopup = (newScrapPopup: utilsTypes['newScrapPopup']) => {
  return {
    type: 'SET_NEW_SCRAP_POPUP',
    newScrapPopup,
  }
}

export const setCollaborationPopup = (collaborationPopup: utilsTypes['collaborationPopup']) => {
  return {
    type: 'SET_COLLABORATION_POPUP',
    collaborationPopup,
  }
}