import { entryTypes } from '../types/feed';

export function returnShowUrl(entry: entryTypes, url: string) {
  if (entry?.type === 'document') return entry?.name || 'Untitled.pdf';
  try {
    const temp = new URL(url);
    return temp.origin;
  } catch (error) {
    return url;
  }
}
