import React, { useState, useContext, useRef } from 'react';
import './CardLinkPreview.css';
import GlobalImageStatus from '../../..//GlobalSearch/components/GlobalImageStatus';

import { entryTypes } from '../../../../types/feed';
import { parseMetaData } from '../../../../helpers';
import RenderEmbed from '../../CardSocial/RenderEmbed';
import { CardViewType } from '../../types/cardView';
import VideoPlayButton from '../../../Global/icons/VideoPlayButton';
import { Doc, LinkIcon, PdfIcon, Ppt, Xls } from '../../../Global/icons';
import ReactAudioPlayer from 'react-audio-player';
import EntryActionContext from '../../../../helpers/contexts/entryActionContext';
import { useDispatch, useSelector } from 'react-redux';
import { setImageDisplayPopup, setScrapEmbedPopup } from '../../../../redux/action/utils';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { ReduxStateType } from '../../../../redux/store';

interface PropTypes {
  onMoreClick?: () => void;
  imageClickConfigData?: string | entryTypes;
  linkData: {
    image?: string;
    link: string;
    domain: string;
    title?: string;
    description?: string;
    price?: number | null;
    favicon?: string | null;
  };
  highlight?: boolean;
  children?: React.ReactNode;
  gridWidth?: number;
  config?: string;
  imageOnly?: boolean;
  hideImage?: boolean;
  fullMeta?: boolean;
  entry?: entryTypes;
  view?: CardViewType;
  playIcon?: boolean;
}

const CardLinkPreview = (props: PropTypes) => {
  const dispatch = useDispatch()
  const notesRef = useRef()
  const {
    onMoreClick,
    highlight,
    children,
    gridWidth,
    config,
    entry,
    view,
    playIcon,
    imageClickConfigData,
    hideImage,
    fullMeta,
  } = props;
  const { width } = useSelector((state: ReduxStateType) => state.utils.dimensions);
  const hideLess = width < 640;
  const { setEntryAction } = useContext(EntryActionContext);
  const [readMoreStatus, setReadMoreStatus] = useState<'truncated' | 'expanded' | 'unavailable'>(hideLess && entry?.isMetaExpanded ? 'expanded' : 'unavailable');
  const { linkData, imageOnly } = props;
  
  const { image, link, domain, title, description, price, favicon } = linkData;
  
  const [loader, setLoader] = useState(true);

  let checkType = ['page', 'screenshot', 'social', 'product'].includes(
    entry?.type
  );
  // used to store image dimentions to show to the user
  let imageStyle = {};

  const fileIcon=(fileType)=>{
    switch(fileType){
      case "1":
        return  <PdfIcon className='card__document-icon' />
      case "2":
        return <Ppt/>
      case "3":
        return <Xls/>
      case "4":
        return <Doc/>
    }
   
  }  

  const descriptionClickHandler = (canCollapse: boolean) => {
    if(readMoreStatus !== 'expanded' || canCollapse) {

      entry && setEntryAction(({
        type: 'edit',
        entry: {
          pid: entry.pid,
          uid: entry.uid,
          id: entry.id,
          type: entry.type,
          isMetaExpanded: readMoreStatus !== 'expanded',
        },
      }));
      setReadMoreStatus(old => {
        if(old === 'truncated') return 'expanded';
        else if(old === 'expanded') return 'truncated';
        return 'unavailable';
      })

    }
  }

  const _renderImage = () => {
    if (image) {
      // if there is a config, use it to determine the image style
      if (config) {
        const metaData = parseMetaData(config);

        // calculate the image height based on the grid width
        // so that the image is fully visible to the user without crop
        if (metaData?.original && gridWidth) {
          const widthRatio = ((gridWidth - 25) * 100) / metaData.original.width;
          const imgHeight = (metaData.original.height * widthRatio) / 100;

          imageStyle = {
            minHeight: (imgHeight > 500 ? 500 : imgHeight) + 'px',
          };
        }
      }

      return (
        <div
          className={`card-link-preview__image  ${
            imageOnly ? 'no-margin-preview' : ''
          }`}
          onClick={
            imageClickConfigData
              ? (e) => {
                e.stopPropagation();
                dispatch(
                  typeof imageClickConfigData === 'string'
                    ? setImageDisplayPopup({ imageUrl: imageClickConfigData })
                    : setScrapEmbedPopup({ data: imageClickConfigData })
                );
                
              }
              : clickHandler
          }
        >
          {view === 'detailShare' && entry?.type === 'social' ? (
            <RenderEmbed href={entry?.url} />
          ) : (
            <>
              {(playIcon || ['youtube'].includes(entry.service)) && !loader && (
                <VideoPlayButton className="card-link-preview__video-btn" />
              )}
              <img
                onLoad={() => setLoader(false)}
                src={image}
                alt={title}
                style={imageStyle}
              />
            </>
          )}
        </div>
      );
    } else if (imageOnly || checkType) {
      return (
        <div
          className={`card-link-preview__image ${
            imageOnly ? 'no-margin-preview' : ''
          }`}
          onClick={
            imageClickConfigData
              ? (e) => {
                e.stopPropagation();
                dispatch(
                  typeof imageClickConfigData === 'string'
                    ? setImageDisplayPopup({ imageUrl: imageClickConfigData })
                    : setScrapEmbedPopup({ data: imageClickConfigData })
                );
              }
              : clickHandler
          }
        >
          <GlobalImageStatus image={image} config={config} />
        </div>
      );
    }
  };

  const _renderHighlight = () => {
    if (highlight) {
      return <div>{children}</div>;
    }

    return null;
  };

  const _renderLink = () => {
    if (link && entry?.image_type !== 2) {
      return (
        <div className="card-link-preview__link" title={entry?.url}>
          {favicon && (
            <span className="card-link-preview__link-favicon">
              <img src={favicon} alt="FavIcon"></img>
            </span>
          )}
          <a href={link} target="_blank" rel="noopener noreferrer">
            {domain}
          </a>
          <span className='card-link-preview__link-icon'>
            <LinkIcon className='card-link-preview__link-icon' />
          </span>
        </div>
      );
    }

    return null;
  };

  const _renderTitle = () => {
    if (title) {
      return <h3 className="card-link-preview__title">{title}</h3>;
    }

    return null;
  };

  const _renderDescription = () => {
    if (description) {
      let classModifier: string = readMoreStatus;
      if(fullMeta) classModifier = 'full-meta';
      return (
        <div 
          className='card-link-preview__description-wrapper' 
          onClick={e => {
            
            e.stopPropagation();
            // @ts-ignore
            if(!notesRef?.current?.clamped) return;
            if(onMoreClick) onMoreClick();
            else descriptionClickHandler(false);
          }}
        >
          <p
            className={`card-link-preview__description card-link-preview__description--${classModifier}`}
          >
            <HTMLEllipsis
              ref={notesRef}
              unsafeHTML={description}
              maxLine={readMoreStatus === 'expanded' || fullMeta ? Infinity : 4}
              basedOn='letters'
              ellipsisHTML={`<button>... more</button>`}
            />
          </p>
        </div>
        
      );
    }

    return null;
  };

  const _renderPrice = () => {
    if (price) {
      return (
        <h4 className="card-link-preview__price">&#x24;{price.toFixed(2)}</h4>
      );
    }

    return null;
  };
  function clickHandler(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    e.stopPropagation();
    const el = e.target as HTMLElement;
    if (el.closest('.read-more__button')) return;
    if(entry.type==='document')
    {
      window && window.open(entry.file,'_blank')
    }
    else{
      window && window.open(link, '_blank');
    }
  
  }

  if (imageOnly) {
    return _renderImage();
  }

  return (
    <div className="card-link-preview__container">
      <div  className="card-link-preview__wrapper">
        {!hideImage && _renderImage()}
        <span onClick={clickHandler} className="card-link__target">
          {entry?.type==='document' && 
          <div className='card-link__pdf'>
            {fileIcon(entry.file_type)}
            <span className='card-link__pdf__name'>{entry.name}</span>
          </div>
          }
          {entry?.type==='audio' && <ReactAudioPlayer
            className="audio-player__inner-wrapper"
            src={entry.audio}
            autoPlay={false}
            controls
          />}
          {_renderHighlight()}
          {_renderLink()}
          {_renderTitle()}
          {_renderDescription()}
          {_renderPrice()}
        </span>
      </div>
    </div>
  );
};

export default CardLinkPreview;
