import React from 'react';
import { Link } from 'react-router-dom';
// import useOnResize from '../../../../helpers/customHooks/useOnResize';
import { PublicProfileDataType } from '../../../../containers/ProfilePreview';
import { returnImageURL } from '../../../Gallery/helpers/returnImageURL';
import { PencilEditIcon } from '../../../Global/icons';
import './styles.css';

interface Proptypes {
  userData: PublicProfileDataType;
  isViewingOwnProfile: boolean;
}
const ProfileSideBar = (props: Proptypes) => {
  const { userData, isViewingOwnProfile } = props;
  const { avatar, first_name, last_name, userName, bio } = userData || {};
  const avatarImage = avatar
    ? returnImageURL(avatar)
    : '/dashboard/img/default-avatar.jpg';

  const editButton = () => {
    return (
      <Link to="/profile" className="profile-sidebar__edit-link hoverState-button">
        <span className="profile-sidebar__edit-link__icon">
          <PencilEditIcon className="profile__wrapper-icon" />
        </span>
        <span className="profile-sidebar__edit-link__text">Edit Profile</span>
      </Link>
    );
  };

  return (
    <div className="">
      <div className="profile-sidebar">
        <div className="profile-sidebar__avatar">
          <img src={avatarImage} alt={userName} />
        </div>
        <div className="profile-sidebar__name-edit-box">
          <div className="profile-sidebar__name">
            <h2 className="profile-sidebar__name__full-name">
              {((first_name || '') + ' ' + (last_name || '')).trim()}
            </h2>
            <h3 className="profile-sidebar__name__username">{userName}</h3>
          </div>
        </div>

        {!!bio && bio.trim() !== '' && (
          <div className="profile-sidebar__bio">
            <p>{bio}</p>
          </div>
        )}
        {isViewingOwnProfile && (
          <div className="profile-sidebar__edit-inner-button">
            {editButton()}
          </div>
        )}
      </div>
      {isViewingOwnProfile && (
        <div className="profile-sidebar__edit-outer-button">{editButton()}</div>
      )}
    </div>
  );
};

export default ProfileSideBar;
