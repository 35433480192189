import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import GLOBAL_USER from './data/globalUser';

import ProfilePreview from '../components/ProfilePreview';
import { userTypes } from '../types/user';
import ProfileSideBar from '../components/ProfilePreview/components/ProfileSideBar';
import { tabsUnionType } from '../components/ProfilePreview';
import ProfileFeeds from '../components/ProfilePreview/components/ProfileFeeds';
import { generalResultType } from '../types/globalSearchDataType';
import { useHistory } from 'react-router-dom';
import Page404 from '../components/page404';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LeftArrowIcon } from '../components/Global/icons';
import { getResponseMessages, parseUrlParams } from '../helpers';
import './styles.css';
import ProfileCard from '../components/CollectionCreationWrapper/components/ProfileCard';
import { ReduxStateType } from '../redux/store';
import { setCollectionAction } from '../redux/action/utils';
import { COLLECTIONS_FOR_PROFILE } from './data/collectionsViaState';
import SEOMeta from '../components/Meta/SEOMeta';
import { collectionType } from '../types/collections';
import GET_REACTIONS from './data/getReactions';
import { ReactionType } from '../components/Comments';
import REMOVE_REACTION from './data/removeReaction';
import ADD_REACTION from './data/addReaction';
import { setCollectionMessenger } from '../redux/action/messengersAction';
import MATCHED_BOOKMARKS from './data/matchedBookmarks';
import { updateCollectionsLikesAndComments } from '../redux/action/collectionsLikesAndCommentsActions';
import trust from '../components/Trust';

const faSpinnerIcon = faSpinner as IconProp;
const typeQuery = {
  All: 'collections',
  Scraps: 'scraps',
  Collections: 'collections',
  Stories: 'stories',
};

export interface PublicProfileDataType {
  user_id: number;
  avatar: string;
  bio: string;
  first_name: string;
  last_name: string;
  userName: string;
  scraps_count: number;
  stories_count: number;
  collections_count: number;
  display_name: string;
  url?: string;
  location?: string;
  social_media_accounts?: string;
  cover_image?: string;
  is_contact?: boolean;
  is_trusted?: boolean;
  trustCb?: (val: boolean) => void;
  isPublic: boolean;
}

const ProfilePreviewWrapper = (props: PublicProfileDataType) => {
  const history = useHistory();
  
  const { isPublic, trustCb } = props;
  const { username } = useParams<{ username: string }>();
  const numberOfRecords = 15;
  
  const [addLike] = useMutation(ADD_REACTION());
  const [removeLike] = useMutation(REMOVE_REACTION());

  const [publicUser, setPublicUser] = useState<PublicProfileDataType | null>(
    null
  );
  const [bookmarkedIds, setBookmarkedIds] = useState<Array<collectionType['id']>>([])
  // const [collectionsReactions, setCollectionsReactions] = useState<ReactionType[]>([])
  const reactionsRef = useRef<ReactionType[]>([]);
  const [isEdit,setIsEdit]=useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [is404, setIs404] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [pageN, setPageN] = useState(1);
  const [comingFrom, setComingFrom] = useState('');
  const [data, setData] = useState<Array<generalResultType>>([]);
  const [scrapsCount,setScrapsCount] = useState([]);
  const [activeTab, setActiveTab] = useState<tabsUnionType>('All');
  const [isTrusted, setIsTrusted] = useState(false);

  const location = useLocation();
  const { search } = location;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const user: userTypes = useSelector((state: any) => state.user);
  const collectionMessenger = useSelector((state: ReduxStateType) => state.messengers.collectionMessenger);
  const dispatch = useDispatch();
  const [
    fetchPublicUserData,
    { data: publicUserData, loading: userLoading, refetch: refetchPublicProfile, client  },
  ] = useLazyQuery(GLOBAL_USER(), {
    variables: {
      username,
    },
  });
  const [count,setCount]=useState(0)
  const [
    fetchQueriedData,
    { loading, data: queriedData, refetch: refetchQueriedData },
  ] = useLazyQuery(COLLECTIONS_FOR_PROFILE(), {
    variables: {
      first: numberOfRecords,
      page: pageN,
      types: typeQuery[activeTab],
      username,
    },
  });

  const loadBookmarksHandler = async (
    ids: Array<number | string>,
    idKey: 'scrap_ids' | 'child_collection_ids'
  ) => {
    if (!ids.length) return [];
    try {
      const response = await client.query({
        query: MATCHED_BOOKMARKS(),
        variables: { [idKey]: ids },
      });
      const { error, isSuccess } = getResponseMessages(
        response?.data?.matchedBookmarks
      );
      if (!isSuccess) throw new Error(error[0]);
      const idsFromResponse: Array<collectionType['id']> = (
        response?.data?.matchedBookmarks?.data || []
      ).map(
        (item) =>
          item[idKey === 'scrap_ids' ? 'scrap_id' : 'child_collection_id']
      );
      const orderedIds: Array<collectionType['id']> = new Array(ids.length);
      ids.forEach((id, i) => {
        orderedIds[i] = idsFromResponse.find((data) => data == id) ?? null;
      });
      return orderedIds;
    } catch (error) {
      return [];
    }
  };

  const likeClickedHandler = async (collectionId: collectionType['id'], collectionIndex: number, isLiked: boolean) => {
    try {
      const reactionData = reactionsRef.current[collectionIndex]
      if(reactionData) {
        reactionData.is_reacted = isLiked
      } else {
        reactionsRef.current[collectionIndex] = {
          id: +collectionId,
          is_reacted: isLiked,
          reactable_type: 2,
        }
      }
    } catch (error) {
      alert('Something went wrong');
    }
    
  }

  async function loadLikesHandler(collection_ids: Array<collectionType['id']>) {
    if(!collection_ids.length) return [];
    const response = await client.query({
      query: GET_REACTIONS(),
      variables: {
        collection_ids,
        reactable_type: 2
      }
    })
    const fetchedReactions: Array<ReactionType> = response?.data?.getReactions?.data || []
    const orderedReactions: Array<ReactionType> = new Array(collection_ids.length)
    collection_ids.forEach((id, i) =>  {
      orderedReactions[i] = fetchedReactions.find(data => +data.id === +id) ?? null
    })
    return orderedReactions;
  }


  /**
   * UseEffect to fetch public user data and queried data
   */
  useEffect(() => {
    const fetchData = async () => {
      await fetchPublicUserData();
      await fetchQueriedData();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (publicUserData) {
      setCount(publicUserData.globalUser?.data?.collections_count);
      setIsTrusted(publicUserData.globalUser?.data?.is_trusted);
    }
  }, [publicUserData]);

  useEffect(() => {
    if (!userLoading && !loading && !isLoading) {
      if (!publicUserData?.globalUser?.data) {
        if (isPublic) {
          setIs404(true);
        } else {
          history.push('/not-found');
        }
      } else {
        setPublicUser(publicUserData?.globalUser?.data);
      }
    }
  }, [userLoading, loading, isLoading]);

  const handleBackClick = () => {
    history.push(comingFrom);
  };
  // HANDLE SEARCH QUERY CHANGE TO UPDATE SEARCH PROPS
  useEffect(() => {
    let parsedSearch = parseUrlParams(search);
    if (parsedSearch.from) {
      setComingFrom(parsedSearch.from as string);
    }
  }, [search]);
  useEffect(() => {
    if (queriedData?.collectionsViaState?.data && !loading && !isEdit) {
      const temp: generalResultType[] = (queriedData?.collectionsViaState?.data || []).map(
        (entry) => ({
          data: entry,
          type: "collection",
        })
      );
      if (queriedData.collectionsViaState.data.length === 0) setCanLoadMore(false);  
      setData((old) => [...old, ...temp]);
      setScrapsCount(old => [...old , ...(queriedData.collectionsViaState.scrapsCount || [])])
      setIsLoading(false);
      const collection_ids = temp.map(item => (item.data as collectionType).id);
      loadLikesHandler(collection_ids).then(reactions => {
        if(!reactions.length) return;
        reactionsRef.current.push(...reactions);
        dispatch(updateCollectionsLikesAndComments(reactions.filter(reaction => !!reaction).map(reaction => {
          const collection = temp.find(item => +item.data.id === +reaction.id)?.data as collectionType;
          return {
            collectionId: reaction.id,
            data: {
              isLiked: reaction.is_reacted,
              likesCount: collection.reaction_count,
              commentsData: {
                count: collection.comment_count,
              }
            }
          }
        })))
      })
      loadBookmarksHandler(collection_ids, 'child_collection_ids').then(ids => {
        setBookmarkedIds(old => [...old, ...ids])
      })
    }
  }, [queriedData]);
  useEffect(() => {
    if (queriedData?.collectionsViaState?.data?.length !== 0) setCanLoadMore(true);
    setData([]);
    setIsLoading(true);
    setPageN(1);
    reactionsRef.current = [];
    setBookmarkedIds([]);
  }, [username]);

  useEffect(() => {
    if(!collectionMessenger) return;
    const {type, commentsCount: comment_count, data: {
      id
    }} = collectionMessenger
    if(type === 'likes-and-comments') {
      setData(old => old.map(item => {
        const collection = item.data as collectionType;
        if(id == collection.id) {
          return {
            type: 'collection',
            data: {
              ...collection,
              comment_count
            }
          }
        }
        return item;
      }))
    }
    dispatch(setCollectionMessenger(null));
  }, [collectionMessenger]);
  
  function bookmarkToggledHandler(index: number, action: "save" | "unsave") {
    const collection = data[index].data as collectionType;
    setBookmarkedIds(old => old.map((id, i) => {
      if(i === index) {
        return action === 'save' ? collection.id : null;
      }
      return id;
    }))
  }

  // update the shared trust value of the user
  function updateTrustState(state: boolean) {
    setIsTrusted(state);
  }

  function loadMoreHandler() {
    if (canLoadMore && !isLoading  ) {
      setIsLoading(true);
      const currentPage = pageN;
      setPageN(currentPage + 1);
      refetchQueriedData({
        page: currentPage + 1,
      });
    }
  }

  if (publicUser) {
    const {
      collections_count,
      scraps_count,
      stories_count,
      userName,
    } = publicUser;

    // isPreview boolean variable to check if the user is viewing its own public profile
    let isViewingOwnProfile = false;
    if (user?.userName === userName) isViewingOwnProfile = true;
    let userFullName = publicUser.display_name;

    // if user's full name is empty string then show public username
    if (userFullName === '') userFullName = publicUser.userName;
    const backLable = comingFrom.startsWith('/search')
      ? 'Back To Search Result'
      : comingFrom.startsWith('/profile')
        ? 'Back To Edit Profile'
        : 'Back To Profile';

    console.log('value of is trusted: ', isTrusted);
    return (
      <div className='profile-preview__wrapper'>
        <SEOMeta title={userFullName ? `${userFullName} on Scrappi` : "Scrappi User"} />

        {comingFrom?.length > 0 && (
          <section className="back-button">
            <button
              className="button button-back-to-results hoverState__with-white-text"
              onClick={handleBackClick}
            >
              <LeftArrowIcon /> {backLable}
            </button>
          </section>
        )}

        <ProfileCard
          userData={publicUser}
          isTrusted={isTrusted}
          trustCb={(state) => {updateTrustState(state)}}
        />

        <ProfilePreview
          renderFeeds={() => (
            <ProfileFeeds
              onBookmarkToggled={bookmarkToggledHandler}
              bookmarkedIds={bookmarkedIds}
              isViewingOwnProfile={isViewingOwnProfile}
              userName={publicUser.userName}
              feeds={data}
              loadMore={loadMoreHandler}
              isLoading={isLoading}
              activeTab={activeTab}
              userData={publicUser}
              count={count}
              scrapsCount={scrapsCount}
              onLikeClicked={likeClickedHandler}
              isTrusted={isTrusted}
              trustCb={(state) => { updateTrustState(state) }}
            />
          )}
          userFullName={userFullName}
          filterCounts={{ collections_count, scraps_count, stories_count }}
          activeTab={activeTab}
          isPublic={isPublic}
          renderProfileSideBar={() => (
            <ProfileSideBar
              userData={publicUser}
              isViewingOwnProfile={isViewingOwnProfile}
            />
          )}
          isViewingOwnProfile={isViewingOwnProfile}
        />
      </div>
    );
  } else if (is404) {
    return <Page404 />;
  } else if (loading || userLoading) {
    return (
      <div className="public__loading-wrapper">
        <div className="public__loading">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="lg" />
        </div>
      </div>
    );
  }

  return null;
};

export default ProfilePreviewWrapper;
