/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';
import { RequestStatusObjectType } from '../../../types/requestStatusType';
import ValidateHuman from '../../../helpers/validators/isHuman';
import ReCAPTCHA from 'react-google-recaptcha';
import { scrapType } from '../../../types/scrapType';
import createScrapHandler from './createScrapHandler';
import { ApolloClient } from '@apollo/client';
import { FileDataType, FileItemStateType } from '../types';
import {
  returnRemovedAndNewCollections,
  returnRemovedAndNewDocumentFiles,
  returnRemovedAndNewTags,
  returnRemovedAndUpdatedImages,
} from './returnRemovedAndNew';
import deleteScrapDocumentsHandler from './deleteScrapDocumentsHandler';
import saveFileInScrapHandler from './saveFileInScrapHandler';
import deleteScrapImagesHandler from './deleteScrapImagesHandler';
import saveImagesInScrapHandler from './saveImagesInScrapHandler';
import { tagType } from '../../../types/tags';
import {
  removeScrapTagsHandler,
  saveTagsInScrapHandler,
} from './tagsChangeHandlers';
import { parseTryCatchError } from '../../../helpers/parseTryCatchError';
import { isEmpty } from '../../../helpers';
import { collectionType } from '../../../types/collections';
import parseIdsFromCollections from '../../../helpers/parseIdsFromCollections';
import { removeScrapCollectionsHandler, saveCollectionsInScrapHandler } from './collectionsChangeHandler';
import { InputFieldType } from '../../../types/inputFieldType';
import { setReloadSideNavFilters } from '../../../redux/action/utils';

interface ParametersType {
  meta?: any;
  setStatus: Dispatch<SetStateAction<RequestStatusObjectType>>;
  captchaRef: ReCAPTCHA;
  scrap: Partial<scrapType>;
  title?: InputFieldType<string>;
  notes?: InputFieldType<string>;
  state?: InputFieldType<number>;
  client: ApolloClient<object>;
  filesList?: InputFieldType<FileDataType[]>;
  imagesList?: InputFieldType<FileItemStateType[]>;  
  tags?: InputFieldType<tagType[]>;
  collections?: InputFieldType<collectionType[]>;
  setReload: (val: boolean) => void;
  closeHandler: () => void;
  onSaved?: (scrap: Partial<scrapType>) => void;
  editPrice?: InputFieldType<number>;
  dispatch?: Dispatch<any>;
}

type mutationListType = Array<{
  parameters: any[];
  cb: (...args) => Promise<any>;
  onDone?: (...args) => void;
}>;

// type saveMappersListType = Array<saveScrapMutationMapper | deleteFilesMutationMapper | saveFilesMutationMapper | deleteImagesMutationMapper | saveImagesMutationMapper | removeTagsMutationMapper | saveTagsMutationMapper>

function getUrlStructure(url: string) {
  const pathArray = url.split('/');
  const host = pathArray[2];
  // return JSON.stringify(
    
  // )
  return JSON.stringify({
    domain: host,
    url
  })
}


export default async function submitHandler({
  setStatus,
  captchaRef,
  scrap,
  title,
  notes,
  state,
  client,
  filesList,
  imagesList,
  tags,
  collections,
  setReload,
  closeHandler,
  onSaved,
  editPrice,
  dispatch,
  meta
}: ParametersType) {
  // List for storing all mutations which will be called in parallel in the end
  const mutationsList: mutationListType = [];
  try {
    // Set status to proceesing for showing the saving ui in frontend
    setStatus({ status: 'processing' });

    // Check for isHuman flag and throw error if it fails
    const isHuman = await ValidateHuman(captchaRef);
    if (!isHuman) throw new Error("Your're not a human!");
    
    let scrapId = scrap?.id;
    const metaData = {...(meta || {})}
    if(metaData.thumbnail) delete metaData['thumbnail']
    if(metaData.url) metaData.url = getUrlStructure(metaData.url);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const saveScrapVariables: any = {...metaData};
    const partialScrap: Partial<scrapType> = {};
    partialScrap.id = scrapId;

    if (scrapId) saveScrapVariables.id = scrapId;

    if (notes?.gotChanged) {
      saveScrapVariables.desc = isEmpty(notes.value, true) ? '' : notes.value;
      partialScrap.desc = isEmpty(notes.value, true) ? '' : notes.value;
    }

    if (editPrice?.gotChanged) {
      saveScrapVariables.price = editPrice.value || 0;
      partialScrap.price = editPrice.value || 0;
    }

    if (title?.gotChanged) {
      saveScrapVariables.title = title.value;
      partialScrap.title = title.value;
    }

    if (state?.gotChanged || (!state?.gotChanged && !scrap)) {
      saveScrapVariables.state = state.value;
      partialScrap.state = state.value;
    }
    if (!scrap || (scrap && Object.values(saveScrapVariables).length > 1)) {
      if (!scrapId) { 
        // Meaning scrap is not created, so we have to call the mutation right away 
        // since other mutation in the list will only be called if scrap is already created
        const scrapData = await createScrapHandler(saveScrapVariables, client);
        scrapId = scrapData.id;
        partialScrap.id = scrapId;
      } else {
        // Else scrap is already created, so store it in mutationsList
        mutationsList.push({
          parameters: [saveScrapVariables, client],
          cb: createScrapHandler,
          onDone(scrapData) {
            scrapId = scrapData.id;
          },
        });
      }
    }

    // Handle file uploading if file is selected by user from file system
    if (filesList?.gotChanged) {
      if (scrap) {
        partialScrap.documents = [...scrap.documents];
        partialScrap.documents_count = filesList?.value?.length;
        const {
          removedFilesList,
          newFilesList,
        } = returnRemovedAndNewDocumentFiles(scrap.documents, filesList.value);
        if (removedFilesList.length) {
          mutationsList.push({
            parameters: [scrapId, client, removedFilesList],
            cb: deleteScrapDocumentsHandler,
            onDone() {
              const removedFilesId = removedFilesList.map((item) => item.id);
              partialScrap.documents = partialScrap.documents.filter(
                (item) => !removedFilesId.includes(item.id)
              );
            },
          });
        }
        if (newFilesList.length) {
          mutationsList.push({
            parameters: [scrapId, client, newFilesList],
            cb: saveFileInScrapHandler,
            onDone(filesSavedData) {
              partialScrap.documents.push(...filesSavedData);
            },
          });
        }
      } else if (filesList.value.length) {
        mutationsList.push({
          parameters: [scrapId, client, filesList.value],
          cb: saveFileInScrapHandler,
        });
      }
    }
    
    // Handle images changes
    let imagesSavedData: scrapType['images'] = [];
    if (imagesList?.gotChanged) {
      if (scrap) {
        const {
          removedFilesList,
          updatedFilesList,
        } = returnRemovedAndUpdatedImages(scrap.images, imagesList.value);
        if (removedFilesList) {
          mutationsList.push({
            parameters: [scrapId, client, removedFilesList],
            cb: deleteScrapImagesHandler,
          });
        }
        if (updatedFilesList.length) {
          mutationsList.push({
            parameters: [scrapId, client, updatedFilesList],
            cb: saveImagesInScrapHandler,
            onDone(data) {
              imagesSavedData = [...data];
            },
          });
        }
      } else if (imagesList.value.length) {
        mutationsList.push({
          parameters: [scrapId, client, imagesList.value],
          cb: saveImagesInScrapHandler,
        });
      }
    }

    // Handle updating tags in scrap
    if (tags?.gotChanged) {
      if (scrap) {
        partialScrap.tags = tags.value;
        const { newTags, removedTags } = returnRemovedAndNewTags(
          scrap.tags,
          tags.value
        );
        if (newTags.length) {
          mutationsList.push({
            parameters: [scrapId, newTags, client],
            cb: saveTagsInScrapHandler,
          });
        }
        if (removedTags.length) {
          mutationsList.push({
            parameters: [scrapId, removedTags, client],
            cb: removeScrapTagsHandler,
          });
        }

      } else if (tags.value.length) {
        mutationsList.push({
          parameters: [scrapId, tags.value, client],
          cb: saveTagsInScrapHandler,
        });
      }
    }

    // Handle updating collections in scrap
    if (collections?.gotChanged) {
      if (scrap) {
        partialScrap.collections = parseIdsFromCollections(collections.value);
        const { newCollections, removedCollections } = returnRemovedAndNewCollections(
          scrap.collections,
          collections.value
        );
        if (newCollections.length) {
          mutationsList.push({
            parameters: [scrapId, newCollections, client],
            cb: saveCollectionsInScrapHandler
          })
        }
        if (removedCollections.length) {
          mutationsList.push({
            parameters: [scrapId, removedCollections, client],
            cb: removeScrapCollectionsHandler,
          })
        }

        // const currentTagsObject
      } else if (collections.value.length) {
        mutationsList.push({
          parameters: [scrapId, collections.value, client],
          cb: saveCollectionsInScrapHandler
        })
      }
    }

    // Call all mutations in parallel using Promise.all
    await Promise.all(
      mutationsList.map(({ cb, parameters, onDone }) => {
        return cb(...parameters).then(onDone);
      })
    );
    
    dispatch && dispatch(setReloadSideNavFilters(true)); 

    // Below code is required to update the scrap images properly in cache,
    if (imagesList.gotChanged && scrap) {
      partialScrap.images = imagesList.value.map((item) => {
        const newItem = imagesSavedData.find(
          (image) => +image.order === +item.order
        );
        if (newItem) return newItem;
        else return scrap.images.find((image) => image.id == item.id);
      });
      partialScrap.images_count = imagesList.value.length;
    }
    if (onSaved) await onSaved(partialScrap);
    setStatus({ status: 'success' });
    if (!scrap) setReload(true);
    closeHandler();
  } catch (error) {
    setStatus({ status: 'error', message: parseTryCatchError(error) });
  }
}
