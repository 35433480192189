import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const ReleaseIcon = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.8848 23.7497C39.6232 23.7497 41.8431 21.5298 41.8431 18.7913C41.8431 16.0529 39.6232 13.833 36.8848 13.833C34.1464 13.833 31.9264 16.0529 31.9264 18.7913C31.9264 21.5298 34.1464 23.7497 36.8848 23.7497ZM36.8848 20.9163C35.7112 20.9163 34.7598 19.9649 34.7598 18.7913C34.7598 17.6177 35.7112 16.6663 36.8848 16.6663C38.0584 16.6663 39.0098 17.6177 39.0098 18.7913C39.0098 19.9649 38.0584 20.9163 36.8848 20.9163ZM19.5914 41.7511C20.1446 42.3043 21.0416 42.3043 21.5948 41.7511C22.1481 41.1978 22.1481 40.3008 21.5948 39.7476L19.7632 37.916L21.5946 36.0846C22.1479 35.5314 22.1479 34.6344 21.5946 34.0811C21.0414 33.5279 20.1444 33.5279 19.5911 34.0811L17.7598 35.9125L15.9284 34.0811C15.3751 33.5279 14.4782 33.5279 13.9249 34.0811C13.3717 34.6344 13.3717 35.5314 13.9249 36.0846L15.7563 37.916L13.9247 39.7476C13.3715 40.3008 13.3715 41.1978 13.9247 41.7511C14.4779 42.3043 15.3749 42.3043 15.9282 41.7511L17.7598 39.9195L19.5914 41.7511ZM21.1132 14.3003C20.842 14 20.4553 13.8299 20.0506 13.8331C19.646 13.8362 19.262 14.0123 18.9955 14.3168L15.2769 18.5668C14.7617 19.1557 14.8214 20.0506 15.4102 20.5658C15.9991 21.081 16.8941 21.0214 17.4093 20.4325L18.645 19.0202V19.9423C18.645 25.9571 23.5209 30.833 29.5357 30.833C33.9857 30.833 37.5931 34.4404 37.5931 38.8904V40.7497C37.5931 41.5321 38.2274 42.1663 39.0098 42.1663C39.7922 42.1663 40.4264 41.5321 40.4264 40.7497V38.8904C40.4264 32.8756 35.5505 27.9997 29.5357 27.9997C25.0857 27.9997 21.4783 24.3923 21.4783 19.9423V18.9329L22.8471 20.449C23.3714 21.0298 24.2673 21.0755 24.848 20.5512C25.4287 20.0269 25.4745 19.1311 24.9502 18.5503L21.1132 14.3003Z"
        fill="#EB6F19"
      />
    </svg>
  );
};

export default ReleaseIcon;
