import React, { useState } from 'react';
import { BatchEditOperationType } from '../../../../types/batchEdit';
import { collectionType } from '../../../../types/collections';
import { tagType } from '../../../../types/tags';
import CheckboxList from '../../../Global/CheckboxList';
import { parseTagFromSlug } from '../../../../helpers';

interface Proptypes {
  activeOperation: BatchEditOperationType;
  setActiveOperation: Function;
  menuOpen: boolean;
  tags: tagType[];
  setTags: (tags: tagType[]) => void;
}
const BatchEditActionsRenderer = (props: Proptypes) => {
  const {
    activeOperation,
    tags,
    menuOpen,
    setTags,
    setActiveOperation,
  } = props;
  const [collections, setCollections] = useState<collectionType[]>([]);
  const render = () => {
    if (activeOperation.show.includes('tags')) {
      return (
        <CheckboxList
          allowCreation={activeOperation.action.includes('add')}
          placeholder={activeOperation.action.includes('add') ? "Search or Create Tags":"Search Tags"}
          itemDataType="tagType"
          selectedItems={tags}
          setSelectedItems={(newTags) => {
            setTags(newTags as tagType[]);
            setActiveOperation((old) => ({
              ...old,
              selected: newTags.map((tag) => parseTagFromSlug(tag.slug).id),
            }));
          }}
        />
      );
    }
    if (activeOperation.show.includes('collections'))
      return (
        <CheckboxList
          allowCreation={activeOperation.action.includes('add')}
          placeholder={activeOperation.action.includes('add') ? "Search or Create Collections":"Search Collections"}
          itemDataType="collectionType"
          selectedItems={collections}
          setSelectedItems={(newData) => {
            setCollections(newData as collectionType[]);
            setActiveOperation((old) => ({
              ...old,
              selected: (newData as collectionType[]).map(
                (collection) => +collection.id
              ),
            }));
          }}
        />
      );
    return <></>;
  };
  if (activeOperation.show.length) {
    return (
      <div
        className={`batch-container batch-container-action batch-mobile-div ${
          menuOpen ? 'dblock' : ''
        }`}
      >
        {render()}
      </div>
    );
  }
  return null;
};

export default BatchEditActionsRenderer;
