import React, { Fragment, useEffect, useRef } from 'react';
import { isEmpty, parseNote, parseTagFromSlug } from '../../../helpers';
import { entryTypes } from '../../../types/feed';
import { scrapType } from '../../../types/scrapType';
import { CloseIcon } from '../../Global/icons';
import ReadMoreText from '../ReadMoreText';
import { CardViewType } from '../types/cardView';
import { useQuery } from '@apollo/client';
import GetScrapDocuments from '../../../containers/data/getScrapDocuments';
import FileCard from '../../Global/FileCard';
import ImagesGroup from '../../Global/ImagesGroup';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import WebContent, { WebContentFavicon, WebContentUrl } from '../../Global/WebContent';
import CardTags from '../../Global/CardTags';
import parseCollectionsFromIds from '../../../helpers/parseCollectionsFromIds';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxStateType } from '../../../redux/store';
import GetScrapAnnotations from '../../../containers/data/getScrapAnnotations';
import HighlightsItem from '../../Global/Highlights/uiComponents/HighlightsItem';
import { GlobalImageStatusOneScrap } from '../../GlobalSearch/components/GlobalImageStatus';
import { setScrapEmbedPopup, setScrapImagesPopup } from '../../../redux/action/utils';
import VideoPlayButton from '../../Global/icons/VideoPlayButton';
interface Proptypes {
  show:
    | 'meta'
    | 'ugc-tags'
    | 'ugc-collections'
    | 'ugc-description'
    | 'all-annotations'
    | 'all-files'
    | 'anotations'
    | 'all-data';
  scrollTo?: 'tags' | 'collections' | 'description';
  entry: scrapType;
  onCloseClick: () => void;
  view?: CardViewType;
  selectedAnnotationIndex?: number;
  shareDetails?: boolean;
  clickedOn?: string;
  showMore?: string;
  fileData?: scrapType['documents'];
  scrapIsMine?: boolean;
}
const returnLinkData = (
  entry: entryTypes | scrapType,
  isOneScrap = false
): {
  image?: string;
  link: string;
  domain: string;
  title?: string;
  description?: string;
  price?: number | null;
  favicon?: string | null;
} => {
  if ('type' in entry) {
    const scrap = entry as entryTypes;
    switch (entry.type) {
      case 'annotation':
        return {
          link: scrap.updated_url || scrap.url,
          domain: scrap.domain,
          title: scrap.meta_title,
          description: scrap.meta_desc,
          favicon: scrap.favicon || scrap.original_favicon,
        };
      case 'page':
        return {
          image: scrap.image || scrap.thumbnail,
          link: scrap.updated_url || scrap.url,
          domain: scrap.domain,
          title: scrap.meta_title,
          description: scrap.meta_desc,
          favicon: scrap.favicon || scrap.original_favicon,
        };
      case 'product':
        return {
          image: scrap.image,
          link: scrap.updated_url || scrap.url,
          domain: scrap.domain,
          title: scrap.meta_title,
          description: scrap.meta_desc,
          price: scrap.price || 0,
          favicon: scrap.favicon || scrap.original_favicon,
        };
      case 'screenshot':
        return {
          image: scrap.image ?? scrap.thumbnail,
          link: scrap.updated_url ?? scrap.url,
          domain: scrap.domain,
          title: scrap.meta_title,
          description: scrap.meta_desc,
          favicon: scrap.favicon || scrap.original_favicon,
        };
      case 'video':
        return {
          image: scrap.thumbnail,
          link: scrap.updated_url || scrap.url,
          domain: scrap.domain,
          title: scrap.title,
          description: scrap.meta_desc,
          favicon: scrap.favicon || scrap.original_favicon,
        };
      case 'social':
        return {
          image: scrap.thumbnail,
          link: scrap.updated_url || scrap.url,
          domain: scrap.domain,
          title: scrap.meta_title,
          description: scrap.meta_desc,
          favicon: scrap.favicon || scrap.original_favicon,
        };

      default:
        return null;
    }
  }
};
const CardMore = ({
  show,
  entry,
  onCloseClick,
  view,
  selectedAnnotationIndex,
  shareDetails,
  clickedOn,
  showMore,
  scrapIsMine,
}: Proptypes) => {
  const dispatch = useDispatch()
  const allCollections = useSelector(
    (state: ReduxStateType) => state.collections
  );
  const highlightsScroll = useRef([]);
  const descriptionRef = useRef<HTMLDivElement>();
  const ugcRef = useRef<HTMLDivElement>();
  const tagsRef = useRef<HTMLDivElement>();
  const collectionsRef = useRef<HTMLDivElement>();
  const filesRef = useRef<HTMLDivElement>();
  const parentRef = useRef<HTMLDivElement>();
  let notesProperty = 'notes';
  if ('type' in entry) {
    if (entry.type === 'annotation') notesProperty = 'comment';
    else if (['gallery', 'document', 'product'].includes(entry.type)) {
      notesProperty = 'desc';
    }
  }
  useEffect(() => {
    if (clickedOn === 'ugc-description') {
      parentRef.current.scroll({ top: -ugcRef.current.offsetHeight });
    } else if (clickedOn === 'all-files') {
      filesRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'center',
      });
    } else if (clickedOn === 'ugc-collections') {
      collectionsRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'center',
      });
    } else if (clickedOn === 'ugc-tags') {
      tagsRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
        inline: 'center',
      });
    } else {
      highlightsScroll?.current[selectedAnnotationIndex?selectedAnnotationIndex:0]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [clickedOn]);

  const { data: annotationsData } = useQuery(GetScrapAnnotations(), {
    variables: {
      scrap_id: entry.id,
      first: (entry as scrapType).annotations_count ?? entry.annotations?.length,
      page: 1,
      username: entry.user_name,
      private_key: entry.private_key,
      skip_first: 0,
      sort_param: 'order',
      sort_by: 'asc'
    },
    skip: !entry.annotations?.length ?? !(entry as scrapType).annotations_count
  })
  const errorMessage = annotationsData?.getScrapAnnotations?.messages?.error
  
  const fetchedAnnotations: scrapType['annotations'] = errorMessage?.length ? null : annotationsData?.getScrapAnnotations?.data;
  let containsMetaData = false;
  if (entry) {
    if (entry.meta_desc || entry.meta_title) containsMetaData = true;
  }
  const { data: fileData } = useQuery(GetScrapDocuments(), {
    variables: {
      scrap_id: entry.id,
      first: entry.documents_count,
      page: 1,
      username: entry.user_name,
      private_key: entry.private_key,
      skip_first: 0,
    },
    skip:
      entry?.documents_count === entry?.documents?.length ||
      entry?.documents_count === 0 ||
      !entry?.documents_count,
  });
  const annotations = fetchedAnnotations ?? (entry as scrapType).annotations;
  let content = null;
  
  const returnImageURL = () => {
    if (entry?.images?.length > 0) {
      return Array.from(
        Object.values(entry?.images),
        (file) => file?.file_path
      );
    }
    return [];
  };
  const showImages = entry?.images?.length > 0;
  const showMetaData = entry?.url || entry?.meta_title || entry?.meta_desc;

  switch (show) {
    case 'all-data':
      content = (
        <div className="card__more-content-all-data">
          <div ref={parentRef}>
            {(entry?.title || !isEmpty(entry?.desc, true)) ? (
              <div className="card__more-content-ugc" ref={ugcRef}>
                {entry?.title ? (
                  <div className="card__more-title" ref={descriptionRef}>
                    {entry?.title}
                  </div>
                ) : null}
                {entry?.desc ? (
                  <div className="card__more-desc" ref={descriptionRef}>
                    <ReadMoreText content={parseNote(entry?.desc)} showFull />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          {(showImages || showMetaData) ? (
            <div className="card__more-media">
              {showImages ? (
                <div className="card__more-images-wrapper">
                  {(entry as scrapType)?.images?.length === 0 &&
                  !entry?.images[0]?.file_path ? (
                      <GlobalImageStatusOneScrap imageData={entry?.images[0]} />
                    ) : (
                      <ImagesGroup
                        renderImage={(i, imageElement) => {
                          const totalImages = returnImageURL().length;
                          const actualImagesCount = entry?.images_count;
                          const lastImage =
                            i === totalImages - 1 && actualImagesCount > totalImages;
                          return (
                            <div
                              onClick={() => {
                                if (entry.service && i === 0) {
                                  dispatch(
                                    setScrapEmbedPopup({
                                      data: {
                                        meta: entry.meta,
                                        service: entry.service,
                                        thumbnail: entry.images?.length
                                          ? entry.images[0]?.file_path
                                          : null,
                                        video: entry.videos?.length
                                          ? entry.videos[0]?.file_path
                                          : null
                                      }
                                    })
                                  );
                                } else {
                                  dispatch(
                                    setScrapImagesPopup({
                                      isGridMode: true,
                                      scrap: entry,
                                      carouselData: {
                                        index: i
                                      },
                                      isReadOnly: containsMetaData || !scrapIsMine
                                    })
                                  );
                                }
                              }}
                              className={`scrap-card__image-wrapper${
                                lastImage
                                  ? " scrap-card__image-wrapper--last-image"
                                  : ""
                              }`}
                            >
                              {imageElement}
                              {lastImage && (
                                <div className="scrap-card__image-overlay">
                                  +{actualImagesCount}
                                </div>
                              )}
                            </div>
                          );
                        }}
                        mode="carousel"
                        images={returnImageURL()}
                        imageCount={entry.images_count}
                      >
                        <>
                          {entry?.service === "youtube" && (
                            <VideoPlayButton className="scrap-card__video-btn" />
                          )}
                        </>
                      </ImagesGroup>
                    )}
                </div>
              ) : null}
              {showMetaData ? (
                <div className="card__more-content-meta">
                  {entry?.url ? (
                    <div className="web-content__link">
                      <WebContentFavicon
                        favicon={
                          entry?.favicon
                            ? entry?.favicon
                            : entry?.original_favicon
                        }
                      />
                      <WebContentUrl url={entry?.url} />
                    </div>
                  ) : null}
                  {entry?.meta_title ? (
                    <div className="card__more-title" ref={descriptionRef}>
                      {entry?.meta_title}
                    </div>
                  ) : null}
                  {entry.meta_desc ? (
                    <div className="card__more-desc" ref={descriptionRef}>
                      <HTMLEllipsis
                        ref={descriptionRef}
                        unsafeHTML={entry.meta_desc}
                        maxLine={Infinity}
                        basedOn="words"
                        ellipsisHTML={`<button>... more</button>`}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}

          {annotations?.length > 0 ?
            annotations?.map((item, index) => {
              return (
                <div
                  className="card__highlight--wrapper"
                  ref={(ref) => (highlightsScroll.current[index] = ref)}
                  key={item.highlighted_text.slice(0, 20)}
                >
                  <HighlightsItem annotation={item} showAll />
                </div>
              );
            }) : null}

          {fileData?.getScrapDocuments?.data || entry.documents?.length ? (
            <div className="card__more-content-all-files" ref={filesRef}>
              <FileCard
                file={fileData?.getScrapDocuments?.data ?? entry.documents}
                showAll={showMore ? true : false}
              />
            </div>
          ) : null}

          {scrapIsMine && (entry?.tags?.length || entry?.collections?.length) ? (
            <div className="card__collection-tag-wrapper">
              {entry?.collections?.length ? (
                <div ref={collectionsRef}>
                  <CardTags
                    clickable
                    mode="collections"
                    tags={parseCollectionsFromIds(
                      allCollections,
                      entry?.collections
                    )}
                  />
                </div>
              ) : null}
              {entry?.tags?.length ? (
                <div ref={tagsRef}>
                  <CardTags
                    clickable
                    mode="tags"
                    tags={entry?.tags.map((tag) => {
                      const { id, label: title, value } = parseTagFromSlug(
                        tag.slug
                      );
                      return { id, title, value };
                    })}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      );
      break;
    case "meta":
      content = (
        <div className="card__more-content-meta">
          {entry?.url && (
            <div className="web-content__link">
              <WebContentFavicon
                favicon={
                  entry?.favicon ? entry?.favicon : entry?.original_favicon
                }
              />
              <WebContentUrl url={entry?.url} />
            </div>
          )}
          {entry?.meta_title && (
            <div className="card__more-title" ref={descriptionRef}>
              {entry?.meta_title}
            </div>
          )}
          {entry.meta_desc && (
            <div className="card__more-desc" ref={descriptionRef}>
              <HTMLEllipsis
                ref={descriptionRef}
                unsafeHTML={entry.meta_desc}
                maxLine={Infinity}
                basedOn="words"
                ellipsisHTML={`<button>... more</button>`}
              />
            </div>
          )}
        </div>
      );
      break;
    case 'anotations':
      content = (
        <div className="card__highlight--wrapper">
          <div className="card__highlight">
            <ReadMoreText
              content={parseNote(
                entry?.annotations[selectedAnnotationIndex]?.highlighted_text
              )}
              showFull
            />
          </div>
        </div>
      );
      break;
    case 'ugc-tags':
    case 'ugc-collections':
    case 'ugc-description':
      content = (
        <div className="card__more-content-ugc">
          {entry?.title && (
            <div className="card__more-title" ref={descriptionRef}>
              {entry?.title}
            </div>
          )}
          {entry?.desc && (
            <div className="card__more-desc" ref={descriptionRef}>
              <ReadMoreText content={parseNote(entry?.desc)} showFull />
            </div>
          )}
          {entry?.tags?.length ||
            (entry?.collections?.length && (
              <div className="card__collection-tag-wrapper">
                {entry?.collections?.length && (
                  <CardTags
                    mode="collections"
                    tags={parseCollectionsFromIds(
                      allCollections,
                      entry?.collections
                    )}
                  />
                )}
                {entry?.tags?.length && (
                  <CardTags
                    mode="tags"
                    tags={entry?.tags.map((tag) => {
                      const { id, label: title } = parseTagFromSlug(tag.slug);
                      return { id, title };
                    })}
                  />
                )}
              </div>
            ))}
          <div className="card__collection-tag-wrapper">
            {entry?.tags && (
              <CardTags
                mode="tags"
                tags={entry?.tags.map((tag) => {
                  const { id, label: title } = parseTagFromSlug(tag.slug);
                  return { id, title };
                })}
              />
            )}
            
          </div>
        </div>
      );
      break;
    // case 'all-annotations':
    //   content = entry?.annotations?.map((item, key) => {
    //     return (
    //       <div className="card__highlight--wrapper" key={key}>
    //         <div className="card__highlight">
    //           <ReadMoreText
    //             content={parseNote(item?.highlighted_text)}
    //             showFull
    //           />
    //         </div>
    //       </div>
    //     );
    //   });
    //   break;
    case 'all-files':
      content = <FileCard file={fileData ?? entry.documents} showAll />;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <div
        className={`card__more-content-body card__more-content-body--${show}  ${
          show === 'all-annotations' ? 'card__more--all-anotations' : ''
        }`}
        ref={parentRef}
      >
        {content}
      </div>
      <div className="card__more-content-footer">
        <button
          onClick={(e) => {
            onCloseClick();
          }}
          className="card__more-content-close-button"
        >
          <CloseIcon />
        </button>
      </div>
    </Fragment>
  );
};

export default CardMore;
