import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './LoadingStyles.css';

interface propTypes {
  numberOfLine?: number;
  type?: 'fullwidth' | 'custom';
  height?: string;
  minWidth?: string;
  maxWidth?: string;
}

const LoadingContent = ({
  numberOfLine,
  type,
  height,
  minWidth,
  maxWidth,
}: propTypes) => {
  if (type === 'fullwidth') {
    return (
      <Skeleton
        style={{
          borderColor: '#C3CFDA',
          borderRadius: 0,
          backgroundColor: '#efefef',
          width: '100%',
          maxWidth: '650px',
          minWidth: '275px',
          height: '400px',
        }}
      />
    );
  } else if (type === 'custom') {
    return (
      <Skeleton
        style={{
          borderColor: '#C3CFDA',
          borderRadius: 0,
          backgroundColor: '#efefef',
          width: '100%',
          maxWidth: maxWidth,
          minWidth: minWidth,
          height: height,
        }}
      />
    );
  }
  return (
    <Skeleton
      className="card-base"
      count={numberOfLine || 6}
      style={{
        borderColor: '#C3CFDA',
        borderRadius: 0,
        backgroundColor: '#efefef',
        width: '100%',
        maxWidth: '650px',
        minWidth: '275px',
        height: '250px',
      }}
    />
  );
};

export default LoadingContent;
