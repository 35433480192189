import { gql } from '@apollo/client';

export const GET_FILTERED_IMAGES = (queryString) => {
  return gql`
    {
      getExistingImages(${queryString}) {
        data{
          image
        }    messages{
          error
          success
        }
        paginatorInfo{
          total
          currentPage
          count
          lastItem
          lastPage
        }
      }
      
    }
  `;
};
