import { GroupType } from '../containers/GroupShareContainer/types';
import { collectionType } from '../types/collections';

export default function returnAllGroupMembersExceptMe(
  data: Partial<GroupType & collectionType> | GroupType['members'],
  myUserId?: number | string,
  compareType?: 'user_id' | 'user_name'
): GroupType['members'] {
  const compareTypeKey = compareType ?? 'user_id'
  if(Array.isArray(data)) {
    return data.filter(member => member[compareTypeKey] !== myUserId)
  } else {
    let membersList = data.members ?? [];
    if (myUserId !== data.user_id) {
      membersList = membersList.filter((member) => member[compareTypeKey] !== myUserId);
      membersList.unshift({
        avatar: data.owner_avatar ?? data.avatar,
        display_name: data.owner_display_name ?? data.display_name,
        user_id: data.user_id,
        user_name: data.owner_user_name ?? data.user_name,
      });
    }
    return membersList;
  }
  
}
