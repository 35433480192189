
export const searchKeyword = (url: string) => {

  const urlParams = decodeURI(url).split('?')[1];
  const paramArray = (urlParams) ? urlParams.split('&') : [];
  const sKeyword = (paramArray) ? paramArray[0].split('=') : [];
  return sKeyword[1];
};

export const filterParams = (url: string) => {

  const filterArray = [];
  if (url) {

    const urlParams = (url) ? decodeURI(url).split('?')[1] : '';
    const paramArray = (urlParams) ? urlParams.split('&') : [];
    paramArray.pop();
    paramArray.splice(0, 4);

    // paramArray.forEach((function (value) {
    //   const filterParam = decodeURIComponent(value).split('=');
    //   filterArray.push(filterParam);
    // }));


    // const types   = (paramArray.length > 4) ?  decodeURIComponent(paramArray[4]).split('=') : '';
    // const tags    = (paramArray.length > 5) ? decodeURIComponent(paramArray[5]).split('=') : '';
    
    // filterArray.push(types);
    // filterArray.push(tags);

  }

  return filterArray;
};
export const decodeYoutubeUrl = (val) => {
  if (val.startsWith('https://www.youtube.com')) return val;
  let url = decodeURIComponent(val);
  url = url.slice(url.indexOf('https'));
  url = url.split('&')[0];
  url = url.replace('watch?v=', 'embed/');
  return url;
};

export const filterUrlValues = (url: string) => {

  let fParams = [];
  if (url) {
    const urlParams = decodeURI(url).split('?')[1];
    const paramArray = (urlParams) ? urlParams.split('&') : [];
    paramArray.pop();
    paramArray.splice(0, 4);

    const testString = '';
    // paramArray.forEach(function (value) {
    //   const testVal = value.split('=');
    //   testString = `${testString},${decodeURIComponent(testVal[1])}`;
    // });

    fParams = testString.split(',');
    // fParams.splice(0, 1);

  }


  return fParams;

};
export const validURL = (str) => {
  let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

