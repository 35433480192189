import React from 'react';

interface Proptypes {
  className?: string;
}

const ThemeLight = ({ className }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4C9.44772 4 9 3.55228 9 3V1C9 0.447715 9.44771 0 10 0C10.5523 0 11 0.447715 11 1V3C11 3.55228 10.5523 4 10 4ZM10 20C9.44772 20 9 19.5523 9 19V17C9 16.4477 9.44771 16 10 16C10.5523 16 11 16.4477 11 17V19C11 19.5523 10.5523 20 10 20ZM3 11C3.55228 11 4 10.5523 4 10C4 9.44771 3.55228 9 3 9H1C0.447715 9 0 9.44772 0 10C0 10.5523 0.447715 11 1 11H3ZM20 10C20 10.5523 19.5523 11 19 11H17C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9H19C19.5523 9 20 9.44771 20 10ZM14.2421 4.34313C13.8516 4.73365 13.8516 5.36681 14.2421 5.75734C14.6326 6.14786 15.2658 6.14786 15.6563 5.75734L17.0705 4.34312C17.4611 3.9526 17.4611 3.31944 17.0705 2.92891C16.68 2.53839 16.0469 2.53839 15.6563 2.92891L14.2421 4.34313ZM2.92961 17.0711C2.53909 16.6805 2.53909 16.0474 2.92961 15.6568L4.34383 14.2426C4.73435 13.8521 5.36751 13.8521 5.75804 14.2426C6.14856 14.6332 6.14856 15.2663 5.75804 15.6568L4.34383 17.0711C3.9533 17.4616 3.32014 17.4616 2.92961 17.0711ZM15.6563 14.2428C15.2658 13.8523 14.6326 13.8523 14.2421 14.2428C13.8516 14.6333 13.8516 15.2665 14.2421 15.657L15.6563 17.0712C16.0469 17.4617 16.68 17.4617 17.0705 17.0712C17.4611 16.6807 17.4611 16.0475 17.0705 15.657L15.6563 14.2428ZM2.92961 2.92906C3.32014 2.53854 3.9533 2.53854 4.34383 2.92906L5.75804 4.34328C6.14856 4.7338 6.14856 5.36697 5.75804 5.75749C5.36752 6.14801 4.73435 6.14801 4.34383 5.75749L2.92961 4.34328C2.53909 3.95275 2.53909 3.31959 2.92961 2.92906ZM6 10C6 12.2091 7.79086 14 10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6C7.79086 6 6 7.79086 6 10ZM8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default ThemeLight;
