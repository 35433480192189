import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { useMutation, gql } from '@apollo/client';

import { VerifyEmail } from '../components/Account';
import { useLocation, useHistory } from 'react-router-dom';

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String) {
    verifyEmail(token: $token) {
      messages {
        error
        success
      }
      status
      http_code
    }
  }
`;

const VerifyEmailWrapper = (props) => {
  const { search } = useLocation();
  const history = useHistory();
  const [verify] = useMutation(VERIFY_EMAIL);
  const [status, setStatus] = useState<'success' | 'pending' | 'error'>(null);
  const [errorMessage, setErrorMessage] = useState(null);
  async function verifyHandler(token) {
    const generalErrorMessage = 'Something went wrong! Please try again later';
    setStatus('pending');
    try {
      const response = await verify({ variables: { token } });
      if (response?.data?.verifyEmail?.messages?.success?.length > 0)
        setStatus('success');
      else {
        setStatus('error');
        setErrorMessage(
          response?.data?.verifyEmail?.messages?.error[0] || generalErrorMessage
        );
      }
    } catch (error) {
      setStatus('error');
      setErrorMessage(
        (error as { message: string }).message || generalErrorMessage
      );
    }
  }

  useEffect(() => {
    const urlParamValues = qs.parse(search.substring(1));
    if (urlParamValues && urlParamValues.token) {
      verifyHandler(urlParamValues.token);
    } else history.replace('/');
  }, [search]);
  return <VerifyEmail status={status} message={errorMessage} />;
};

export default VerifyEmailWrapper;
