import { gql } from '@apollo/client';

export const GET_USER_SETTINGS = gql`
  query UserSettings($type: String) {
    userSettings(type: $type) {
      type
      meta
    }
  }
`;
