import React from 'react';

interface Proptypes {
  className?: string;
}

const Video = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}  
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.5 3.39H1.58002C0.750017 3.39 0.0800171 4.06 0.0800171 4.89V37.06C0.0800171 37.89 0.750017 38.56 1.58002 38.56H40.5C41.33 38.56 42 37.89 42 37.06V4.89C42 4.06 41.33 3.39 40.5 3.39ZM7.02002 11.08L11.75 6.39H16.4L11.67 11.08H7.02002ZM20.66 6.39H25.62L20.89 11.08H15.93L20.66 6.39ZM29.88 6.39H34.88L30.15 11.08H25.15L29.88 6.39ZM38.99 6.53V11.08H34.4L38.99 6.53ZM7.49002 6.39L3.08002 10.77V6.39H7.49002ZM3.08002 35.56V14.08H39V35.56H3.08002Z"
        fill="#616161"
      />
      <path
        d="M26.27 23.79L17.22 18.56C16.76 18.29 16.18 18.29 15.72 18.56C15.26 18.83 14.97 19.32 14.97 19.86V30.31C14.97 30.85 15.26 31.34 15.72 31.61C15.95 31.74 16.21 31.81 16.47 31.81C16.73 31.81 16.99 31.74 17.22 31.61L26.27 26.39C26.73 26.12 27.02 25.63 27.02 25.09C27.02 24.55 26.73 24.06 26.27 23.79ZM17.97 27.71V22.46L22.52 25.09L17.97 27.72V27.71Z"
        fill="#616161"
      />
    </svg>
  );
};

export default Video;
