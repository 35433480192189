import React, { useState } from 'react';
import { collectionType } from '../../../types/collections';
import { entryTypes } from '../../../types/feed';

import { CollectionIcon } from '../icons';
import TagsView from '../TagsView';
import './styles.css';

interface Proptypes {
  selectedCollections: collectionType[];
  onSave?: (data?: Partial<entryTypes>) => void;
}

const AddCollectionsHelper = ({
  selectedCollections,
  onSave,
}: Proptypes) => {
  const [onSaveCollectionList,setOnSaveCollectionList] =useState(false)
 
  // Use this function to hide the dropdown checkbox for collection if user click on the collection it will hide the dropdown
  // const isEdit = () => {
  //   if (selectedCollections.length > 2) return true;
  //   if (!collectionData?.length) return false;
  //   var recentIDs = new Set(collectionData?.map((d: collectionType) => d.id));
  //   var filteredData = selectedCollections.filter(
  //     (d: collectionType) => !recentIDs.has(d.id)
  //   );
  //   if (filteredData.length) return true;
  //   return false;
  // };

  return (
    <div className="add-collections-helper">
      <TagsView
        list={selectedCollections}
        valueField="id"
        labelField="title"
        showEdit={true}
        showEditLabel='Collections'
        dataType="collectionType"
        onSave={onSave}
        // savingStatus={status}
        addIcon={true}
        label={onSaveCollectionList ? 'Edit' : 'Add to'}
        isNew={selectedCollections.length ? false : true}
        disableItemClick
        setOnSaveCollectionList={setOnSaveCollectionList}
        onSaveCollectionList={onSaveCollectionList}
      >
        <CollectionIcon />
      </TagsView>
      {/* Use isEdit() to hide dropdown here in place of collectionData.length*/}
      {/* {collectionData?.length > 0 && (
        <ul className="add-collections-helper__recent-list">
          {collectionData?.map((collection) => {
            let renderConfig;
            if (+collection.state === 3) {
              renderConfig = {
                type: 'icon',
                render: (elementProps) => <NewGlobeIcon {...elementProps} />,
              };
            }
            return (
              !onSaveCollectionList && <SideNavDropdownListItem
                onClick={() => onChange(collection.title, collection.id)}
                isMultiselect
                label={collection.title}
                key={collection.id}
                active={
                  selectedCollections?.filter(
                    (selectedCollection) =>
                      selectedCollection.id === collection.id
                  ).length > 0
                }
                renderConfig={renderConfig}
              />
            );
          })}
        </ul>
      )} */}
    </div>
  );
};

export default AddCollectionsHelper;
