import React, { useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { DELETE_PANEL } from '../../../data/deletePanel';

import { dependentOptionConfigType } from '.';
import { entryTypes } from '../../../../types/feed';
import DeleteScrap from '../../../Details/Components/DeleteScrap';
import { EDIT_PANEL, GENERATE_NEW_LINK } from '../../../data/updateDetails';
import { waitBeforeExecute } from '../../../../helpers';
import { OpenSquareIcon, TrashIcon } from '../../../Global/icons';
import { useDispatch, useSelector } from 'react-redux';
import { userTypes } from '../../../../types/user';
import { ReduxStateType } from '../../../../redux/store';
import { setReloadSideNavFilters } from '../../../../redux/action/utils';
interface Proptypes {
  config: dependentOptionConfigType;
  scrap: entryTypes;
  onClick: () => void;
  syncScrap?: (scrap: entryTypes) => void;
}
const DependentOption = ({ config, scrap, onClick, syncScrap }: Proptypes) => {

  const { action, onComplete } = config;
  const [scrapData, setScrapData] = useState(scrap);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletePanel] = useMutation(DELETE_PANEL(scrap.type));
  const dispatch = useDispatch()
  const userData = useSelector(
    (state: ReduxStateType) => state.user as userTypes
  );
  // const scrapOwner = useSelector(
  //   (state: ReduxStateType) => state.shareSettingsOption.scrap
  // );
  const [editVisibility, { loading: editing }] = useMutation(
    EDIT_PANEL({ type: scrap.type })
  );

  const [generateNewLink, { loading: generating }] = useMutation(
    GENERATE_NEW_LINK()
  );

  const deleteHandler = async () => {
    setDeleting(true);
    try {
      await deletePanel({
        variables: {
          id: scrap.id,
        },
      });
      waitBeforeExecute(() => {
        setDeleting(false);
        setShowDelete(false);
        onComplete && onComplete(scrap);
       
      }, 2000);
    } catch (error) {
      alert('Some went wrong!');
      setDeleting(false);
    }
    dispatch(setReloadSideNavFilters(true));
  };

  const visibilityHandler = async (data) => {
    try {
      const res = await editVisibility({
        variables: {
          id: scrap.id,
          state: data.state,
        },
      });
      setScrapData((old) => ({
        ...old,
        state: +data.state,
        private_key: res?.data[Object.keys(res?.data)[0]]?.private_key,
      }));
      onComplete && onComplete({
        ...scrap,
        state: data.state,
        private_key: res?.data[Object.keys(res?.data)[0]]?.private_key,
      });
    } catch (error) {
      alert('Some went wrong!');
    }
  };
  let actionConfig: {
    label: string;
    onClick: () => void;
    icon: () => React.ReactElement;
  };
  switch (action) {
    case 'delete':
      if (userData.userName === scrap?.userName)
        actionConfig = {
          label: 'Delete Scrap',
          onClick: () => {
            setShowDelete(true);
            onClick();
          },
          icon: () => <TrashIcon className="card-settings__list-item-icon" />,
        };
      break;

    case 'link-open':
      if (scrap.url)
   
        actionConfig = {
          label: 'Open Link',
          onClick: () => {
            if (window) window.open(scrap.url, '_blank');
          },
          icon: () => (
            <OpenSquareIcon className="card-settings__list-item-icon" />
          ),
        };
      break;
    default:
      break;
  }

  useEffect(() => {
    setScrapData(scrap);
  }, [scrap]);
  if(action === 'visibility') return null;
  if (actionConfig) {
    const { label, onClick, icon } = actionConfig;
    return (
      <div className="card-settings__list-item" onClick={onClick}>
        {icon()}
        <span className="card-settings__list-item-label">{label}</span>
        <DeleteScrap
          scrapTitle={scrap.title}
          deleting={deleting}
          controlled={{ show: showDelete, setShow: setShowDelete }}
          handleDelete={deleteHandler}
        />
        
      </div>
    );
  }
  return null;
};

export default DependentOption;
