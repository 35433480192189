import { gql } from '@apollo/client';

export const UPSERT_USER_TRUST = gql`
  mutation UpsertUserTrust($user_id_trusted: Int!) {
    upsertUserTrust(user_id_trusted: $user_id_trusted) {
      messages {
        error
        success
      }
      status
    }
  }
`;

export const DELETE_USER_TRUST = gql`
  mutation deleteUserTrust($user_id_trusted: Int!) {
    deleteUserTrust(user_id_trusted: $user_id_trusted) {
      messages {
        error
        success
      }
      status
    }
  }
`;
