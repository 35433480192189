import qs from 'qs';

const parseUrlParams = (search: string) => {
  const tempParams = qs.parse(search.substring(1));
  for (const property in tempParams) {
    if (Array.isArray(tempParams[property])) {
      (tempParams[property] as string) = (tempParams[
        property
      ] as string[]).join(',');
    }
  }
  return tempParams;
};

export default parseUrlParams;
