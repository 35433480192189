import React, { useEffect, useRef } from 'react';

import { parseSocialMeta } from '../../../helpers/parseSocialMeta';
interface PropType {
  meta: string;
}

interface InstaProps {
  instaUrl?: string;
}

export const Instagram = ({ meta }: PropType) => {
  // parse the meta data
  let parsedMeta: InstaProps = parseSocialMeta(meta);
  const href = parsedMeta.instaUrl;
  const frame = useRef<HTMLIFrameElement>();

  return (
    <iframe
      src={`${href}embed`}
      title={href}
      className="embedIframe"
      allowTransparency
      ref={frame}
      // change the border size on load to fit the iframe to the right height
      // when the post is loaded it does not have the correct height
      onLoad={() => {frame.current.style.border = '1px solid transparent';}}
    ></iframe>
  );
};
