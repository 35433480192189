import React from 'react';

interface Proptypes {
  className?: string;
}

const StoryView = ({ className }: Proptypes) => {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C11.1 20 12 19.1 12 18L12 17.3L12 15C12 13.9 11.1 13 10 13L2 13C0.9 13 5.33279e-07 13.9 4.37114e-07 15L1.74846e-07 18C7.86805e-08 19.1 0.9 20 2 20L10 20ZM10 18L2 18L2 15L10 15L10 17.3L10 18ZM10 11C11.1 11 12 10.1 12 9L12 7L12 2C12 0.9 11.1 -7.86805e-08 10 -1.74846e-07L2 -8.74228e-07C0.900001 -9.70393e-07 1.66978e-06 0.899999 1.57361e-06 2L9.61651e-07 9C8.65485e-07 10.1 0.9 11 2 11L10 11ZM10 9L2 9L2 2L10 2L10 7L10 9Z"
        fill="#4B4B4B"
      />
    </svg>
  );
};

export default StoryView;
