import { entryTypes, ScrapType } from './feed';
import { globalSearchOptionsMapper } from '../variableMappers/globalSearch';
import { storyType } from './stories';
import { collectionType } from './collections';


export enum KnotFetch {
  Scraps = 'Scraps',
  MyCollections = 'My Collections',
  PublicCollections = 'Public Collections',
  Scrappers = 'Scrappers',
  Shares = 'Shares'
}
export interface GlobalScrapDataType {
  type: 'scrap';
  entry: entryTypes;
}

export type GlobalSearchTabsType = Array<{
  value: KnotFetch.Scraps;
  label: string;
} | {
  value: KnotFetch.MyCollections;
  label: string;
} | {
  value: KnotFetch.PublicCollections;
  label: string;
} | {
  value: KnotFetch.Scrappers;
  label: string;
} | {
  value: KnotFetch.Shares;
  label: string;
}>
export interface GlobalKnotCountType {
  collections: number;
  publicCollections?: number;
  scraps: number;
  scrappers?: number;
}

export type GlobalSearchDataType = Array<entryTypes>;

type GlobalSearchOptionsType = typeof globalSearchOptionsMapper;
export type GlobalSearchOptionsLabelType = GlobalSearchOptionsType[keyof GlobalSearchOptionsType]['label'];
export type GlobalSearchOptionType = GlobalSearchOptionsType[keyof GlobalSearchOptionsType];
export type GlobalSearchOptionsValueType = GlobalSearchOptionsType[keyof GlobalSearchOptionsType]['value'];


export interface GlobalSearchUserType {
  id?: string;
  avatar?: string;
  bio?: string;
  display_name?:string;
  user_name?: string;
  userName?: string; // on of the api have this field
  collection_count?:number;
  is_sent?:boolean;
  email?:string;
  phone?:string;
  is_trusted?:boolean;
}
interface storyResultType {
  type: 'story';
  data: storyType;
}
interface scrapResultType {
  type: ScrapType;
  data: entryTypes;
}
interface collectionResultType {
  type: 'collection';
  data: collectionType;
}
interface scrapperResultType {
  type: 'scrapper';
  data: GlobalSearchUserType;
}

export type generalResultType =
  | storyResultType
  | scrapResultType
  | collectionResultType
  | scrapperResultType;

export interface SearchHistoryItemType {
  id: string;
  search_term: string;
  created_at: string;
  updated_at: string;
}
