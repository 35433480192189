import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const Search = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666645 6.91675C0.666645 10.3685 3.46487 13.1667 6.91665 13.1667C8.35968 13.1667 9.68849 12.6777 10.7464 11.8563C10.7862 11.9406 10.841 12.0196 10.9107 12.0893L15.9107 17.0893C16.2362 17.4148 16.7638 17.4148 17.0892 17.0893C17.4147 16.7639 17.4147 16.2363 17.0892 15.9108L12.0892 10.9108C12.0195 10.8411 11.9405 10.7863 11.8562 10.7465C12.6776 9.68859 13.1666 8.35978 13.1666 6.91675C13.1666 3.46497 10.3684 0.666748 6.91665 0.666748C3.46487 0.666748 0.666645 3.46497 0.666645 6.91675ZM2.33331 6.91675C2.33331 4.38544 4.38534 2.33341 6.91665 2.33341C9.44795 2.33341 11.5 4.38544 11.5 6.91675C11.5 9.44805 9.44795 11.5001 6.91665 11.5001C4.38534 11.5001 2.33331 9.44805 2.33331 6.91675Z"
        fill="#969696"
      />
    </svg>
  );
};

export default Search;
