import React from 'react';
import ResponsiveImage from '../../Global/ResponsiveImage';
interface PropType {
  thumbnail: string;
}

export const LinkedInProfile = ({ thumbnail }: PropType) => {
  return (
    <ResponsiveImage
      sizes={['800']}
      image={thumbnail}
      background={true}
      imageClass="img__main"
    />
  );
};
