import { ApolloClient } from '@apollo/client';
import { GET_IMAGE_DATA } from '../containers/data/getSingleEntryData';
import { entryActionType, entryTypes } from '../types/feed';

interface pendingImageProps {
  parsedConfig: number;
  uid: string;
  image: string;
  thumbnail: string;
  client: ApolloClient<object>;
  scrap?:entryTypes
  setScrap?: (val: entryTypes) => void;
  setEntryAction?: (val: entryActionType) => void;
}

export const pendingImage = async ({
  parsedConfig,
  uid,
  image,
  thumbnail,
  client,
  scrap,
  setScrap,
  setEntryAction,
}: pendingImageProps) => {
  
  if (parsedConfig === 0 || parsedConfig === 1) {
    const { data } = await client.query({
      query: GET_IMAGE_DATA,
      variables: {
        uid: uid,
      },
    });
    const newData={
      ...scrap,
      image: data?.entry?.data?.image,
      thumbnail: data?.entry?.data?.thumbnail,
    }
    var dataImgConfig = data
      ? JSON.parse(data?.entry?.data?.config)?.img_status
      : '';

    if ((dataImgConfig === 2 && (!image || !thumbnail)) || image !== null) {
      if (
        (data?.entry?.data?.image !== '' &&
          data?.entry?.data?.image !== null) ||
        (data?.entry?.data?.thumbnail !== '' &&
          data?.entry?.data?.thumbnail !== null)
      ) {
        setEntryAction &&
          setEntryAction({
            type: 'edit',
            entry: {
              ...data, //TODO: HOW THIS IS WORKING, IT SHOULD BE ...data.entry.data
              image: data?.entry?.data?.image,
              thumbnail: data?.entry?.data?.thumbnail,
              id: data?.entry?.data?.id,
              uid: uid,
              pid: data?.entry?.data?.pid,
              type: data?.entry?.data?.type,
              config: data?.entry?.data?.config
            },
          });

        setScrap && 
          setScrap({
            ...scrap,
            image: data?.entry?.data?.image,
            thumbnail: data?.entry?.data?.thumbnail,
            config:data?.entry?.data?.config
          })
      }
      return data?.entry?.data?.image;
    }
  }
};
