import React from 'react';
import ScrapCard from '../ScrapCard';
import './SmartOrganizeStyles.css';
import Scraps from '../../containers/data/scraps';
import { useMutation, useQuery } from '@apollo/client';
import { formatScrapDataForAi } from './formatScrapDataForAi';
import { UPSERT_COLLECTION } from '../../containers/data/collection';
import { scrapType } from '../../types/scrapType';
import { create } from 'node:domain';
import UPSERT_SCRAP_COLLECTIONS from '../../containers/data/upsertScrapCollections';
import { useSelector } from 'react-redux';
import { collectionType } from '../../types/collections';
import existingCollection from '../Details/Components/ExistingCollection';
import { Link } from 'react-router-dom';
import collection from '../Global/icons/Collection';
import { LinkIcon } from "../Global/icons";

interface CreateCollectionTypes {
  title: string;
  desc: string;
  scrapIds: number[];
  collectionId?: string;
  index?: number;
}

const SmartOrganize = () => {
  const [data, setData] = React.useState([]);
  const [waitingForAI, setWaitingForAI] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [createdCollections, setCreatedCollections] = React.useState<collectionType[]>([]);

  // get collections from redux store
  const collections = useSelector(
    (state: { collections: collectionType[] }) => state.collections
  );

  const { data: newScrapsData, client, refetch, fetchMore, loading } = useQuery(
    Scraps(),
    {
      variables: {
        first: 30,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  async function getAiData(scrapsForAi: scrapType[]) {
    setWaitingForAI(true);
    return fetch('https://collection-suggestion-dev.scrap-crawler.workers.dev', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        scraps: scrapsForAi,
        collections: collections.map((collection => {
          return collection.title;
        }))
      }),
    })
      .then((response) => response.json())
      .then((json) => json)
      .catch((error) => {
        setError(
          'There was an error processing a request, try creating a few more Scraps and try again.'
        );
        console.error('Error:', error);
      }).finally(
        () => {
          setWaitingForAI(false);
        }
      )
  }

  const [upsertCollection, { loading: addingCollection }] = useMutation(
    UPSERT_COLLECTION()
  );
  const [upsertScrapCollection, { loading: addingScraps }] = useMutation(
    UPSERT_SCRAP_COLLECTIONS()
  );

  // make an API call when the component mounts
  React.useEffect(() => {
    const scrapsForAi = newScrapsData?.scraps?.data;

    if (scrapsForAi && scrapsForAi.length) {
      const totalScraps = scrapsForAi?.length;
      const formattedScrapsSet1 = formatScrapDataForAi(scrapsForAi, 0, totalScraps < 15 ? totalScraps : 15);
      getAiData(formattedScrapsSet1).then((newVal) => {
        setData((oldData) => [ ...oldData, ...newVal ]);
      });

      if(totalScraps > 15) {
        const formattedScrapsSet2 = formatScrapDataForAi(scrapsForAi, 12, totalScraps < 27 ? totalScraps : 27);
        getAiData(formattedScrapsSet2).then((newVal) => {
          setData((oldData) => [ ...oldData, ...newVal ]);
        });

      }
    }
  }, [newScrapsData]);

  // take the values of title and description and create collection
  async function createCollection({
    title,
    desc,
    scrapIds,
    collectionId,
  }: CreateCollectionTypes) {
    let createdCollectionId = collectionId;
    if (!collectionId) {
      const createdCollection = await upsertCollection({
        variables: {
          title,
          desc,
          state: 2,
        },
      });

      const newCollection = createdCollection.data?.upsertCollection?.data;
      setCreatedCollections([...createdCollections, newCollection]);

      createdCollectionId = newCollection.id;
    }

    // if the collection id is created, add the scraps to the collection
    if (createdCollectionId) {
      for (const scrapId of scrapIds) {
        await upsertScrapCollection({
          variables: {
            scrap_id: scrapId,
            collections: [{ id: createdCollectionId }],
          },
        });
      }
    }
  }

  function isExistingCollection(title: string) {
    return collections.find(
      (collection: collectionType) => collection.title?.toLowerCase() === title?.toLowerCase()
    );
  }

  // if the API response fails to load
  if (error) {
    return <div className="my-scraps smart-organize__wrapper">{error}</div>;
  }

  // show while loading
  if (loading || waitingForAI)
    return (
      <div className="my-scraps smart-organize__wrapper">Waiting for AI to analyze Scraps…</div>
    );

  // check if data is Array
  if (!Array.isArray(data))
    return (
      <div className="my-scraps smart-organize__wrapper">
        Some issue with the data, please refresh the page!
      </div>
    );

  const scrapList = newScrapsData?.scraps.data;
  const addingData = addingScraps || addingCollection;

  return (
    <div className="my-scraps">
      {data &&
        data?.sort((a, b) => a.title?.localeCompare(b.title)).map(
          (
            item: { title: string; desc: string; ids: number[] },
            index: number
          ) => {
            const scrapsMatch = scrapList
              ?.filter((scrap: scrapType) =>
                item.ids?.includes(+scrap.id)
              );
            if(scrapsMatch?.length < 1) { return null }
            const isExisting = isExistingCollection(item.title);
            const createdNew = createdCollections.find(col => col.title === item.title);
            return (
              <div key={index} className="smart-organize">
                <div>
                  <div>
                    <h2 className="smart-organize__heading">{item.title}</h2>
                    {createdNew && (
                      <p className="smart-organize__description">
                        New collection created{' '}
                        <Link
                          to={`/c/${createdNew.title
                            .split(' ')
                            .join('-')
                            .toLowerCase()}/${createdNew.id}`}
                        >
                          {createdNew.title}
                        </Link>
                      </p>
                    )}

                    {isExisting && (
                      <p className="smart-organize__description">
                        This is an{' '}
                        <Link
                          to={`/c/${isExisting.title
                            .split(' ')
                            .join('-')
                            .toLowerCase()}/${isExisting.id}`}
                          target="_blank"
                        >
                          existing collection <LinkIcon className="web-content__url-icon" />
                        </Link>
                      </p>
                    )}

                    {!isExisting && (
                      <p className="smart-organize__description">{item.desc}</p>
                    )}
                  </div>
                  <div>
                    {addingData && (
                      <button className="button button__secondary" disabled>
                        Adding to collection
                      </button>
                    )}

                    {(!createdNew && !addingData) && (
                      <button
                        className="button button__secondary"
                        onClick={() =>
                          createCollection({
                            title: item.title,
                            desc: item.desc,
                            scrapIds: item.ids,
                            collectionId: isExisting?.id,
                            index,
                          })
                        }
                      >
                        {isExisting
                          ? 'Add to existing collection'
                          : 'Create collection'}
                      </button>

                    )}
                  </div>
                </div>

                <div className="smart-organize__scraps">
                  {scrapList &&
                    scrapList
                      ?.filter((scrap: scrapType) =>
                        item.ids.includes(+scrap.id)
                      )
                      .map((scrap: scrapType, index: number) => (
                        <ScrapCard key={index} scrap={scrap} />
                      ))}
                </div>
              </div>
            );
          }
        )}
    </div>
  );
};

export default SmartOrganize;
