import React from 'react';
import './styles.css';

interface Proptypes {
  children: React.ReactChild;
}

const TermPagesWrapper = (props: Proptypes) => {
  const { children } = props;
  return (
    <div className="pages-div">
       
      {/* 
       Uncomment this to use term page design without side nav
        <nav className="main-navigation">
        <div className="main-navigation__links">
          <a href="/" className="sl-logo">
            <img src="/dashboard/img/Group.svg" alt="Scrappi" />
          </a>
        </div>
      </nav> 
      */}
      {children}
    </div>
  );
};

export default TermPagesWrapper;
