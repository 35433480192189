import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const ExclamationIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 0H3C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H17C18.6569 20 20 18.6569 20 17V3C20 1.34315 18.6569 0 17 0ZM2 3C2 2.44772 2.44772 2 3 2H17C17.5523 2 18 2.44772 18 3V17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17V3ZM9.99988 3.75C9.31775 3.75 8.77258 4.31742 8.79984 4.999L9.02491 10.6257C9.04584 11.149 9.47616 11.5625 9.99988 11.5625C10.5236 11.5625 10.9539 11.1491 10.9748 10.6258L11.1999 4.999C11.2272 4.31742 10.682 3.75 9.99988 3.75ZM8.5625 14.5312C8.5625 13.7546 9.1921 13.125 9.96875 13.125C10.7454 13.125 11.375 13.7546 11.375 14.5312C11.375 15.3079 10.7454 15.9375 9.96875 15.9375C9.1921 15.9375 8.5625 15.3079 8.5625 14.5312Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default ExclamationIcon;
