import React, { useEffect, useMemo, useRef, useState } from 'react';
import ScrapCardHeader from './uiComponents/ScrapCardHeader';
import ScrapCardFooter from './uiComponents/ScrapCardFooter';
import ImagesGroup from '../Global/ImagesGroup';

import './styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxStateType } from '../../redux/store';
import { returnShareLink } from '../../helpers/returnShareLinks';
import { returnScrapSlug } from '../../helpers/slugify';
import {
  handleNativeShare,
  handleShare,
  isMobileDevice,
} from '../../helpers/handleShare';
import Highlights from '../Global/Highlights';

import WebContent from '../Global/WebContent/';
import FileCard from '../Global/FileCard';
import CardTags from '../Global/CardTags';
import CardMore from '../Cards/CardComponents/CardMore';
import { scrapType } from '../../types/scrapType';
import { setOneScrapAction } from '../../redux/action/onScrapActions';
import {
  setGroupSharePopup,
  setScrapEmbedPopup,
  setScrapImagesPopup,
} from '../../redux/action/utils';
import VideoPlayButton from '../Global/icons/VideoPlayButton';
import { GlobalImageStatusOneScrap } from '../GlobalSearch/components/GlobalImageStatus';
import { getResponseMessages, parseTagFromSlug, returnTimeDifference } from '../../helpers';
import { useApolloClient, useMutation } from '@apollo/client';
import GetScrapDocuments from '../../containers/data/getScrapDocuments';
import { returnFullName } from '../../helpers/returnFullName';
import parseCollectionsFromIds from '../../helpers/parseCollectionsFromIds';
import Comments, { CommentType, ScrapCommentsType } from '../Comments';
import CommentCard from '../Comments/CommentCard';
import ScrapCardCommentsOverlay from '../Comments/ScrapCardCommentsOverlay';
import CommentInput from '../Comments/CommentInput';
import ADD_REACTION from '../../containers/data/addReaction';
import REMOVE_REACTION from '../../containers/data/removeReaction';
import UPSERT_BOOKMARK from '../../containers/data/upsertBookmark';
import { sendToast } from '../../helpers/notification';
import { parseTryCatchError } from '../../helpers/parseTryCatchError';

const animationDurationWait = 320;
export type onClickType =
  | 'meta'
  | 'ugc-tags'
  | 'ugc-collections'
  | 'ugc-description'
  | 'all-annotations'
  | 'all-files'
  | 'anotations';

export type scrapCardViewType = 'home-page' | 'collection-page' | 'group-page' | 'search-page'; 

interface Proptypes {
  fromString?: string;
  scrap: scrapType;
  view?: scrapCardViewType;
  onDeleteClick?: () => void;
  onEditClick?: (scrap: scrapType, val?: boolean) => void;
  shareDetails?: boolean;
  disabled?: boolean;
  onRemoveClick?: () => void;
  onScrapComment?: (text: string, dontUpdateScrapState?: boolean) => Promise<ScrapCommentsType>;
  scrapAllCommentsExtraVariables?: {
    collection_id?: number;
    commentable_type: number;
  };
  comments?: CommentType[];
  onLikeToggled?: (isReacted: boolean) => void;
  commentsCount?: number;
  onCommentReactionToggled?: (commentId: CommentType['id'],isReacted: boolean) => void;
  isBookmarked?: boolean;
  onBookmarkToggled?: (action: 'save' | 'unsave') => void;
  onboardingClass?: boolean;
  width?: number;
  trustCb?: (val: boolean) => void;
}

function return800ImageUrl(url: string) {
  try {
    const splittedUrl = url.split('/');
    splittedUrl.splice(splittedUrl.length - 1, 0, '800')
    return splittedUrl.join('/');  
  } catch (error) {
    return url;  
  }
  
}
const ScrapCard = ({
  fromString,
  scrap,
  view,
  onDeleteClick,
  onEditClick,
  shareDetails,
  disabled,
  onRemoveClick,
  onScrapComment,
  scrapAllCommentsExtraVariables,
  comments,
  onLikeToggled,
  commentsCount,
  onCommentReactionToggled,
  isBookmarked,
  onBookmarkToggled,
  onboardingClass,
  width,
  trustCb,
}: Proptypes) => {
  const [addReaction] = useMutation(ADD_REACTION());
  const [removeReaction] = useMutation(REMOVE_REACTION());
  const [upsertBookmark] = useMutation(UPSERT_BOOKMARK());
  const dispatch = useDispatch();
  const allCollections = useSelector(
    (state: ReduxStateType) => state.collections
  );
  const user = useSelector((state: ReduxStateType) => state.user);
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [clickedOn, setClickedOn] = useState('');
  const [fileData, setFileData] = useState<scrapType['documents']>([]);
  const [selectedAnnotationIndex, setSelectedAnnotationIndex] = useState(0);
  const [showInMobile, setShowInMobile] = useState(false);
  const [showModal, setShowModal] = useState<'collections' | 'tags' | 'notes' | null >(null);

  const apolloClient = useApolloClient();
  const client = apolloClient;
  const parentRef = useRef<HTMLDivElement>();
  const [showMore, setShowMore] = useState<
    | 'meta'
    | 'ugc-tags'
    | 'ugc-collections'
    | 'ugc-description'
    | 'all-annotations'
    | 'all-files'
    | 'anotations'
    | 'all-data'
  >();
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showComments, setShowComments] = useState<{
    replyTo?: string;
  }>(null);
  const scrapIsMine = user.user_id === scrap.user_id;
  let avatar, userName, displayName, headline;

  if (scrapIsMine) {
    avatar = user?.avatar;
    userName = user?.userName;
    headline = user?.headline;
    displayName =
      user?.display_name || returnFullName(user.first_name, user.last_name);
  } else {
    avatar = scrap.avatar;
    userName = scrap.user_name;
    displayName = scrap.display_name;
    headline = scrap.headline;
  }
  const filesRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (clickedOn === 'all-files') {
      filesRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [fileData]);

  async function fetchFilesData() {
    try {
      const res = await client.query({
        query: GetScrapDocuments(),
        variables: {
          scrap_id: scrap.id,
          first: scrap.documents_count,
          page: 1,
          username: userName,
          private_key: scrap.private_key,
          skip_first: 0,
        },
      });

      setFileData(res?.data?.getScrapDocuments?.data);
    } catch (error) {
      console.warn(error);
    }
  }

  const performShare = (shareURL: string, generate: boolean = false) => {
    if (generate) navigator.clipboard.writeText(encodeURI(shareURL || ''));
    else {
      handleShare(shareURL, 'show', () => {
        navigator.clipboard.writeText(encodeURI(shareURL || ''));
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      });
    }
  };
  const handleCopy = (e?: React.FormEvent<EventTarget>) => {
    if (scrapIsMine)
      dispatch(
        setGroupSharePopup({
          scrap_id: scrap.id,
          shareUrl: shareUrl,
        })
      );
    else {
      if (typeof window !== 'undefined') {
        const isShared = handleNativeShare(shareUrl, () =>
          performShare(shareUrl)
        );
        if (isShared) return;
      }
      performShare(shareUrl);
    }
  };

  const onMoreClick = (
    onClickType: onClickType,
    index,
    currentlyOpened?: boolean
  ) => {
    if (isMobileDevice()) {
      setShowInMobile(true);
      setClickedOn(onClickType);
      fetchFilesData();
      return;
    }

    setClickedOn(onClickType);
    setShowMore('all-data');
    setSelectedAnnotationIndex(index);
    // if(width < 640 || view==='detailShare') {
    //   return true;
    // }
    return false;
  };
  const returnImageURL = () => {
    if (scrap?.images?.length > 0) {
      return Array.from(
        Object.values(scrap?.images.slice(0,10)),
        (file) => {
          let has800Image = false
          try {
            let config = JSON.parse(file.file_config);
            if(typeof config === 'string') config = JSON.parse(config);
            has800Image = !!config.file_meta['800']
          } catch (error) {
            // Intentionally left blank
          }
          return has800Image ? return800ImageUrl(file?.file_path) : file?.file_path
        }
      );
    }
    return [];
  };

  const bookmarkClickHandler = async () => {
    try {
      const action = isBookmarked ? 'unsave' : 'save';
      const response = await upsertBookmark({
        variables: {
          scrap_id: scrap.id,
        }
      })
      const { isSuccess, error } = getResponseMessages(response.data?.upsertBookmark);
      if(!isSuccess) throw new Error(error[0])
      onBookmarkToggled && onBookmarkToggled(action)
      sendToast(action === 'save'? 'Saved Successfully': 'Successfully removed from your saved items', 'success', 2000)  
    } catch (error) {
      onBookmarkToggled && onBookmarkToggled(isBookmarked ? 'save' : 'unsave');
      sendToast(parseTryCatchError(error), 'error', 2000);  
    }
    
    

  }

  const setZIndex = (val: string) => {
    const scrapElement = parentRef.current;
    const parentElement = scrapElement?.parentElement;
    if(parentElement) parentElement.style.zIndex = val;
  }

  const commentClickHandler = async () => {
    if(showCommentInput) return;
    setShowCommentInput(true);
    setZIndex('1')
  }
  const commentInputCloseHandler = async () => {
    if(!showCommentInput) return;
    setShowCommentInput(false)
    setZIndex('unset');
  }
  const commentSubmitHandler = async (text: string) => {
    onScrapComment(text);
    commentInputCloseHandler()
  }
  const timeDifference = returnTimeDifference(scrap.created_at, scrap.updated_at)
  useEffect(() => {
    if (scrap) {
      if(!scrap.state || scrap.state == 1) setShareUrl(null);
      else {
        setShareUrl(
          returnShareLink(
            'scrap',
            returnScrapSlug(scrap),
            userName,
            false,
            scrap.state,
            scrap.private_key
          )
        );
      }
    }
  }, []);
  const scrapAllCommentsVariables = useMemo(() => {
    const commonVariables = {
      scrap_id: scrap.id,
      commentable_type: 1,
    };
    if (scrapAllCommentsExtraVariables)
      return {
        ...commonVariables,
        ...scrapAllCommentsExtraVariables,
      };
    return commonVariables;
  }, [scrap, scrapAllCommentsExtraVariables]);

  return (
    <div
      ref={parentRef}
      className={`scrap-card ${copied ? 'share-active' : ''}${
        disabled ? ' scrap-card--disabled' : ''
      } ${onboardingClass ? 'onboard-scrap' : ''}`}
    >
      <ScrapCardHeader
        fromString={fromString}
        userName={userName}
        avatar={avatar}
        headline={headline}
        created_at={scrap?.created_at}
        updated_at={scrap?.updated_at}
        userDisplayName={displayName}
        handleCopy={handleCopy}
        onDropdownClosed={setZIndex.bind(null, 'unset')}
        onDropdownOpened={setZIndex.bind(null, '1')}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        onRemoveClick={onRemoveClick}
        onBookmarkClick={user.userName && bookmarkClickHandler}
        scrap={scrap}
        view={view}
        isBookmarked={isBookmarked}
        showModal={{
          showModal,
          resetModal: () => setShowModal(null),
        }}
        trustCb={(status) => trustCb(status)}
      />
      <div className="scrap-card__body">
        <WebContent
          url={scrap?.url}
          description={scrap?.desc}
          favicon={scrap?.favicon || scrap?.original_favicon}
          title={scrap?.title}
          secondary={true}
          onMoreClick={onMoreClick}
          moreShow="ugc-description"
          showFullContent={showInMobile}
          clickedOn={clickedOn}
          editClick={() => scrapIsMine ? setShowModal('notes') : null}
        />

        {(scrap?.images?.length > 0 || scrap?.url || scrap?.meta_desc) && (
          <div className="scrap-card__media">
            <div className="scrap-card__media-inner-container">
              {scrap?.images?.length === 0 && !scrap?.images[0]?.file_path ? (
                <GlobalImageStatusOneScrap imageData={scrap?.images[0]} />
              ) : (
                <ImagesGroup
                  renderImage={(i, imageElement) => {
                    const totalImages = returnImageURL().length;
                    const actualImagesCount = scrap?.images_count;
                    const lastImage =
                      i === totalImages - 1 && actualImagesCount > totalImages;
                    return (
                      <div
                        onClick={() => {
                          if (scrap.service && i === 0) {
                            dispatch(
                              setScrapEmbedPopup({
                                data: {
                                  meta: scrap.meta,
                                  service: scrap.service,
                                  thumbnail: scrap.images?.length
                                    ? scrap.images[0]?.file_path
                                    : null,
                                  video: scrap.videos?.length
                                    ? scrap.videos[0]?.file_path
                                    : null,
                                },
                              })
                            );
                          } else {
                            dispatch(
                              setScrapImagesPopup({
                                isGridMode: true,
                                scrap,
                                carouselData: {
                                  index: i,
                                },
                                isReadOnly:
                                  !scrapIsMine ||
                                  !onEditClick,
                              })
                            );
                          }
                        }}
                        className={`scrap-card__image-wrapper${
                          lastImage
                            ? ' scrap-card__image-wrapper--last-image'
                            : ''
                        }`}
                      >
                        {imageElement}
                        {lastImage && (
                          <div className="scrap-card__image-overlay">
                            +{actualImagesCount - 10}
                          </div>
                        )}
                      </div>
                    );
                  }}
                  mode="carousel"
                  images={returnImageURL()}
                  imageConfig={scrap?.images}
                  imageCount={scrap.images_count}
                  width={width}
                >
                  <>
                    {scrap?.service === 'youtube' && (
                      <div className="scrap-card__youtube">
                        <VideoPlayButton className="scrap-card__video-btn" />
                      </div>
                    )}
                  </>
                </ImagesGroup>
              )}

              <WebContent
                url={scrap?.url}
                description={scrap.meta_desc}
                favicon={scrap?.favicon || scrap?.original_favicon}
                price={scrap?.price}
                title={scrap?.meta_title}
                onMoreClick={onMoreClick}
                showFullContent={showInMobile}
                moreShow="meta"
              />
            </div>
          </div>
        )}

        <Highlights
          scrap={scrap}
          highlightText={scrap?.annotations?.length > 0 && scrap.annotations}
          setShowMore={setShowMore}
          onMoreClick={onMoreClick}
          setSelectedAnnotationIndex={setSelectedAnnotationIndex}
          showAll={showInMobile}
          selectedAnnotationIndex={selectedAnnotationIndex}
          clickedOn={clickedOn}
        />

        <div className="scrap-card__files" ref={filesRef}>
          <FileCard
            file={showInMobile ? fileData : scrap?.documents}
            file_count={scrap?.documents_count}
            onMoreClick={onMoreClick}
            showAll={showInMobile}
            showHighlightsButton={!showInMobile}
          />
        </div>

        {(scrap?.collections || scrap?.tags?.length) && scrapIsMine ? (
          <div className="scrap-card__tags">
            {scrap?.collections?.length ? (
              <CardTags
                trim={
                  !showInMobile
                    ? {
                      after: 5,
                      onMoreClick: onMoreClick.bind(
                        null,
                        'ugc-collections',
                        0
                      ),
                    }
                    : null
                }
                mode="collections"
                tags={parseCollectionsFromIds(
                  allCollections,
                  scrap?.collections
                )}
                clickable
                iconClick={() => setShowModal('collections')}
              />
            ) : null}
            {scrap?.tags?.length ? (
              <CardTags
                trim={
                  !showInMobile
                    ? {
                      after: 5,
                      onMoreClick: onMoreClick.bind(null, 'ugc-tags', 0),
                    }
                    : null
                }
                clickable
                mode="tags"
                tags={(scrap?.tags || []).map((item) =>
                  parseTagFromSlug(item.slug)
                )}
                shareDetails={shareDetails}
                iconClick={() => setShowModal('tags')}
              />
            ) : null}
          </div>
        ) : null}
        {(comments?.length) ? (
          <Comments
            moreConfig={{
              maxCount: 2,
              onMoreClick: () => {
                setShowComments({});
                setShowCommentInput(false);
              },
              totalCount: commentsCount || comments.length
            }}
            comments={comments}
          >
            {(comment) => (
              <CommentCard
                onReactionToggled={onCommentReactionToggled && onCommentReactionToggled.bind(null, comment.id)}
                onReplyClick={setShowComments.bind(null, {
                  replyTo: comment?.user?.user_name,
                })}
                comment={comment}
              />
            )}
          </Comments>
        ): null}
        {onScrapComment && (
          <div
            className={`scrap-card__comment-input-wrapper${
              !showCommentInput
                ? ' scrap-card__comment-input-wrapper--hidden'
                : ''
            }`}
          >
            <CommentInput
              key={showCommentInput ? 'shown' : 'hidden'}
              hide={!showCommentInput}
              onCancel={commentInputCloseHandler}
              onSubmit={commentSubmitHandler}
            />
            <ScrapCardFooter 
              commentsCount={scrap.comment_count ?? commentsCount}
              onLikeToggled={onLikeToggled}
              onCommentClick={() => {}}
              isCommentActive
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              state={scrap?.state}
              userName={userName}
              handleCopy={handleCopy}
              copied={copied}
              scrap={scrap}
              onRemoveClick={onRemoveClick}
              date={timeDifference > 15 ? scrap.updated_at : null}
              onDropdownClosed={setZIndex.bind(null, 'unset')}
              onDropdownOpened={setZIndex.bind(null, '1')}
              onBookmarkClick={bookmarkClickHandler}
              view={view}
              isBookmarked={isBookmarked}
            />
          </div>
        )}
      </div>
      <ScrapCardFooter
        commentsCount={scrap.comment_count ?? commentsCount}
        onCommentClick={onScrapComment && commentClickHandler}
        onLikeToggled={onLikeToggled}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
        state={scrap?.state}
        userName={userName}
        handleCopy={handleCopy}
        copied={!showCommentInput && copied}
        scrap={scrap}
        onRemoveClick={onRemoveClick}
        date={timeDifference > 15 ? scrap.updated_at : null}
        onDropdownClosed={setZIndex.bind(null, 'unset')}
        onDropdownOpened={setZIndex.bind(null, '1')}
        onBookmarkClick={user.userName && bookmarkClickHandler}
        view={view}
        isBookmarked={isBookmarked}
      />
      <ScrapCardCommentsOverlay
        show={!!showComments}
        onClose={setShowComments.bind(null, null)}
        defaultComments={comments}
        mentionedUserName={showComments?.replyTo}
        variables={scrapAllCommentsVariables}
      />
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`card__more-content-overlay${
          showMore ? ' card__more-content-overlay--visible' : ''
        }`}
      >
        <div
          className={`card__more-content${
            showMore
              ? ` card__more-content--visible card__more-content--${showMore}`
              : ''
          }`}
        >
          {showMore && (
            <CardMore
              scrapIsMine={scrapIsMine}
              show={showMore}
              entry={scrap}
              fileData={fileData}
              selectedAnnotationIndex={selectedAnnotationIndex}
              shareDetails={shareDetails}
              showMore={showMore}
              clickedOn={clickedOn}
              onCloseClick={() => {
                setShowMore(null);
                setTimeout(() => {
                  dispatch(
                    setOneScrapAction({
                      type: 'edit',
                      scrap: {
                        id: scrap.id,
                        isAnnotationExpanded: false,
                        areFilesExpanded: false,
                        areAnnotationsExpanded: false,
                      },
                    })
                  );
                }, animationDurationWait);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export { ScrapCard as default, ScrapCardFooter, ScrapCardHeader };
