import React, { useState, useEffect } from 'react';
import { GET_FILTERED_IMAGES } from './data//imagesData';
import { useQuery } from '@apollo/client';
import AddExistingImages from '../components/Gallery/components/AddExistingImages';
import { filterParamTypes } from '../types/filter';
import {
  ExistingImagesItemsType,
  FetchedExistingImageType,
} from '../components/Gallery/types/imagesTypes';
import getBase64FromUrl from '../helpers/getBase64FromUrl';
import { parseTryCatchError } from '../helpers/parseTryCatchError';
import NewPopup from '../components/Global/NewPopup';

import AddExistingImagesPopupHeader from '../components/Gallery/components/AddExistingImages/AddExistingImagesPopupHeader';
import { fetchBlob } from '../helpers';
interface Proptypes {
  selectedImages: ExistingImagesItemsType[];
  oldImages: ExistingImagesItemsType[];
  setSelectedImages: (imgs: ExistingImagesItemsType[]) => void;
  singleSelect?: (image: string, mimeType?: string) => void;
  convertToBase64?: boolean;
  show: boolean;
  setShow: (val: boolean) => void;
  defaultAll?: boolean;
  collectionId?: string;
}
const AddExistingImagesWrapper = (props: Proptypes) => {
  const {
    selectedImages,
    oldImages,
    setSelectedImages,
    singleSelect,
    convertToBase64,
    show,
    setShow,
    defaultAll,
    collectionId
  } = props;
  const [page, setPage] = useState(1);
  const [viewMode, setViewMode] = useState<'All Images' | 'Selected Images'>(
    'All Images'
  );
  const [selectedType, setSelectedType] = useState<{
    title: string;
    id: number;
  }>(defaultAll ? { title: 'All Collection', id: 2 } : { title: 'This Collection', id: 1 });
  const [load, setLoad] = useState(true);
  const [processingImage, setProcessingImage] = useState(false);
  const [images, setImages] = useState<FetchedExistingImageType[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [reloadFilters, setReloadFilters] = useState(false);
  const [filters, setFilters] = useState<filterParamTypes>({
    story: [],
    tags: [],
    types: [],
    untag:false,
    collections: [],
  });

  let initialState = {
    story: [],
    tags: [],
    types: [],
    collections: [],
  };
  
  const queryString = `
    first: 40,
    page:${page},
    tags:"${filters.tags.toString()}",
    untagged:${filters.untag},
    collections:"${filters.collections.toString()}"
  `;
  const { loading, error, data } = useQuery(GET_FILTERED_IMAGES(queryString));

  useEffect(() => {
    setReloadFilters(true);

    if (selectedType.title === 'This Collection') {
      reInitializeData();
      setLoad(true);
      setFilters({ ...filters, collections: [collectionId], tags: [] });
    } else {
      reInitializeData();
      setLoad(true);
      setFilters({ ...filters, collections: [], tags: [] });
    }
  }, [selectedType]);
  function handleChange() {
    if (canLoadMore && !loading) {
      setPage((currentPage) => currentPage + 1);
      setLoad(true);
    }
  }
  const reInitializeData = () => {
    setPage(1);
    // setIsFirstLoad(true);
    setImages([]);
  };
  const handleFiltersChange = (dt: filterParamTypes) => {
    reInitializeData();
    setLoad(true);
    setFilters((data) => ({ ...dt }));
    setReloadFilters(true);
  };

  const singleSelectHandler = async (src: string) => {
    if (convertToBase64) {
      try {
        setProcessingImage(true);
        const b64Url = await getBase64FromUrl(src);
        const blob = await fetchBlob(b64Url as string);
        singleSelect(b64Url as string, blob?.type);
      } catch (error) {
        alert(
          parseTryCatchError(
            error,
            'Unable to get base64 url from the image due to CORS policy! Please choose another image'
          )
        );
      } finally {
        setProcessingImage(false);
      }
    } else singleSelect(src);
  };
  useEffect(() => {
    if (!loading && data && data?.getExistingImages.data) {
      if (data?.getExistingImages.data.length > 0) {
        let scrapImages = data.getExistingImages.data;
        if (page === 1 && oldImages.length) {
          oldImages.map((s) => {
            let itemIndex = scrapImages.findIndex((i) => i.image === s.image);
            scrapImages.splice(itemIndex, 1);
            return true;
          });
          setSelectedImages([]);
        }
        scrapImages = scrapImages.filter(
          
          (image) =>  {
            return Boolean(image?.image) && !image.image.includes('/error')}
        );
        setImages((oldImages) => [...oldImages, ...scrapImages]);
        setLoad(false);
        if (!canLoadMore) setCanLoadMore(true);
      } else {
        setLoad(false);
        setCanLoadMore(false);
      }
    }
  }, [data, loading]);
  return (
    <NewPopup
      size="Extra large"
      className="add-existing-images__popup"
      controlled={{ show, setShow }}
      header={{
        heading: 'Select Image',
        customRender: (close) => (
          <AddExistingImagesPopupHeader
            hideCollectionFilter={!collectionId}
            close={close}
            onTypeChange={setSelectedType}
            selectedType={selectedType}
          />
        ),
      }}
    >
      {/* <SecondaryNavigationWrapper {...props} pFilters={filters} noSubNav /> */}
      {(close) => (
        <AddExistingImages
          images={images}
          reloadFilters={reloadFilters}
          setReloadFilters={setReloadFilters}
          filters={filters}
          setFilters={handleFiltersChange}
          loadMore={handleChange}
          loading={load}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          isAddExistingImages={{ viewMode, setViewMode }}
          singleSelect={singleSelect && singleSelectHandler}
          processingImage={processingImage}
          showFilteredCollection={selectedType.title}
          collectionId={collectionId}
        />
      )}
    </NewPopup>
  );
};

export default AddExistingImagesWrapper;
