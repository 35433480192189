import React from 'react';

interface Proptypes extends React.SVGAttributes<SVGSVGElement> {}

const CollectionOutlined = (props: Proptypes) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66675 3.33366C1.66675 2.41318 2.41294 1.66699 3.33341 1.66699H16.6667C17.5872 1.66699 18.3334 2.41318 18.3334 3.33366V5.83366C18.3334 6.45056 17.9982 6.98918 17.5001 7.27736V7.50033V15.8337C17.5001 17.2144 16.3808 18.3337 15.0001 18.3337H5.00008C3.61937 18.3337 2.50008 17.2144 2.50008 15.8337V7.50033V7.27736C2.00191 6.98918 1.66675 6.45056 1.66675 5.83366V3.33366ZM5.83342 7.50033H14.1667H15.8334V9.16699V15.8337C15.8334 16.2939 15.4603 16.667 15.0001 16.667H5.00008C4.53984 16.667 4.16675 16.2939 4.16675 15.8337V9.16699V7.50033H5.83342ZM16.6667 5.00033V5.83366H15.8334H15.0001H5.00008H4.16675H3.33341V5.00033V4.16699V3.33366H5.00008H15.0001H16.6667V4.16699V5.00033ZM7.50008 9.16699C7.03984 9.16699 6.66675 9.54009 6.66675 10.0003C6.66675 10.4606 7.03984 10.8337 7.50008 10.8337H12.5001C12.9603 10.8337 13.3334 10.4606 13.3334 10.0003C13.3334 9.54009 12.9603 9.16699 12.5001 9.16699H7.50008Z"
        fill="#969696"
      />
    </svg>
  );
};

export default CollectionOutlined;
