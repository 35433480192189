import React from 'react';

interface Proptypes {
  className?: string;
}

const TickSquare = ({ className }: Proptypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.2002 1.69995H15.9002C17.3002 1.69995 18.4002 2.79995 18.4002 4.19995V15.9C18.4002 17.3 17.3002 18.4 15.9002 18.4H4.2002C2.8002 18.4 1.7002 17.3 1.7002 15.9V4.19995C1.7002 2.79995 2.8002 1.69995 4.2002 1.69995ZM4.2002 3.29995C3.7002 3.29995 3.4002 3.69995 3.4002 4.09995V15.8C3.4002 16.3 3.80019 16.6 4.2002 16.6H15.9002C16.4002 16.6 16.7002 16.2 16.7002 15.8V4.19995C16.7002 3.69995 16.3002 3.39995 15.9002 3.39995H4.2002V3.29995Z" fill="#28A745"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.4 6.79995C14.7 7.09995 14.7 7.69995 14.4 7.99995L9.9 12.5L9.2 13.2C8.9 13.5 8.5 13.5 8.2 13.2L7.5 12.5L5.6 10.6C5.3 10.3 5.3 9.69995 5.6 9.39995C5.9 9.09995 6.5 9.09995 6.8 9.39995L8.7 11.3L13.2 6.79995C13.5 6.49995 14.1 6.49995 14.4 6.79995Z" fill="#28A745"/>
    </svg>
  );
};

export default TickSquare;
