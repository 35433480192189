import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TrashIcon, TrashOutlineIcon } from '../Global/icons';
import ReturnShareIcon from '../Global/ReturnShareIcon';

const faSpinnerIcon = faSpinner as IconProp;
const faCheckCircleIcon = faCheckCircle as IconProp;

interface Proptypes {
  processing?: boolean | string;
  disableSave?: boolean;
  editMode?: boolean;
  isReadOnly?: boolean;
  onDeleteClick?: () => void;
  onCancel?: () => void;
  onSaveClick?: () => void;
  createdAt: string;
  updatedAt: string;
  scrapState?: number;
  editImage?:boolean;
  alwaysShowPublic?:boolean;
}

const ScrapDetailsFooter = ({
  processing,
  editMode,
  isReadOnly,
  onDeleteClick,
  onCancel,
  onSaveClick,
  disableSave,
  createdAt,
  updatedAt,
  scrapState,
  editImage,
  alwaysShowPublic
}: Proptypes) => {
  if (editMode) {
    if (processing)
      return (
        <div className="create-scrap__footer-processing">
          <span className="create-scrap__footer-status">
            {typeof processing === 'string' ? processing : 'Saving'}
          </span>
          <span className="create-scrap__footer-spinner">
            <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
          </span>
        </div>
      );
    else
      return (
        <>
          {!isReadOnly && onDeleteClick && (
            <span
              onClick={onDeleteClick}
              className="create-scrap__delete new-popup__header-button"
            >
                <TrashOutlineIcon />
            </span>
          )}
          <div className="create-scrap__footer-buttons">
            <button
              onClick={onCancel}
              className="create-scrap__footer-cancel button button__outline btn-action"
              disabled={!!processing}
            >
              Cancel
            </button>
            {editMode && (
              <button
                onClick={onSaveClick}
                className="create-scrap__footer-save button button__primary btn-action"
                disabled={!!processing || disableSave || editImage}
              >
                <FontAwesomeIcon icon={faCheckCircleIcon} /> Save Changes
              </button>
            )}
          </div>
        </>
      );
  }
  return (
    <>
      <div className="create-scrap__footer-info--dates">
        <span className="create-scrap__footer-info--dates_created">
          Created:
          <span className="create-scrap__footer-info--dates_show">
            {createdAt}
          </span>
        </span>
        <span className="create-scrap__footer-info--dates_edited">
          Edited:
          <span className="create-scrap__footer-info--dates_show">
            {updatedAt}
          </span>
        </span>
      </div>
      <ReturnShareIcon
        scrapState={(alwaysShowPublic)?3:(!alwaysShowPublic&&(scrapState===2||scrapState===3))?2:1}
        className="create-scrap__footer-info--status"
      />
    </>
  );
};

export default ScrapDetailsFooter;
