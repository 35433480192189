import { gql } from '@apollo/client';

const GET_STRIPE_PRODUCT_ITEMS = gql`query GetStripeProducts {
    getStripeProducts {
    data{
      id
      name
      price
      buttonDetail
      customerActive
      isAnnual      
      included
      customerItemUnitPrice
      modifiedPriceId
      modifiedCustomerActivePlanPriceId
      modifiedCustomerActivePlanPriceFrequency      
    }
    messages {
      error
      success
      __typename
    }
    status
    http_code
    __typename
  }
}`;

export default GET_STRIPE_PRODUCT_ITEMS;