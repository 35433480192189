import React, { useEffect, useState, useRef } from 'react';
import { entryTypes } from '../../../../types/feed';
import CheckboxList, { PropHelpers, convertTagsIntoCollection } from '../../../Global/CheckboxList';
import { LeftArrowIcon, NewClose } from '../../../Global/icons';
import NewPopup from '../../../Global/NewPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './styles.css';
import { tagType } from '../../../../types/tags';
import { collectionType } from '../../../../types/collections';

import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../../redux/store';
import newTag from "../../../Global/icons/NewTag";


const faSpinnerIcon = faSpinner as IconProp;

interface Proptypes {
  showEdit?: boolean;
  isNew?: boolean;
  controlled: {
    show: boolean;
    setShow: (value: boolean) => void;
  };
  dataType: 'tagType' | 'collectionType';
  data: PropHelpers['dataType'][];
  setData: (data: PropHelpers['dataType'][]) => void;
  onSave: (data?: Partial<entryTypes>) => void;
  savingStatus?: string | null;
  setOnSaveCollectionList?: (val:boolean) => void;
}

const ScrapEditTags = (props: Proptypes) => {
  const {
    showEdit,
    isNew,
    controlled,
    data,
    setData,
    dataType,
    onSave,
    savingStatus,
    setOnSaveCollectionList
  } = props;
  const faCheckCircleIcon = faCheckCircle as IconProp;

  const [directEditData, setDirectEditData] = useState<PropHelpers['dataType'][]>(data || []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [preSelectedData, setPreSelectedData] = useState<
    PropHelpers['dataType'][]
  >(data);
  const [updating, setUpdating] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [disabledSave,setDisabeledSave]=useState(false)
  const { width } = useSelector((state: ReduxStateType) => state.utils.dimensions);
  
  useEffect(() => {
    if (width <= 745) {
      setIsMobile(true);
    } else if (isMobile) {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (!savingStatus && updating) {
      setUpdating(false);
      controlled.setShow(false);
    }
  }, [savingStatus, updating]);

  const returnType = () => {
    switch (dataType) {
      case 'tagType':
        return 'Tags';
      case 'collectionType':
        return 'Collections';
      default:
        return '';
    }
  };
  const returnLabel = () => {
    var heading = 'Add ';
    var type = returnType();
    if (showEdit && !isNew) {
      heading = 'Edit ';
    } else if (type === 'Collections') {
      heading += 'to ';
    }
    heading += returnType();

    return heading;
  };

  const inValidateTypename = (key: string, value: string | number | null) => {
    if (key === '__typename') return null;
    else return value;
  };
  const isChanged = () => {
    if ((showEdit && preSelectedData && directEditData)) {
      let copyPreSelectedData = [...preSelectedData];
      let copyData = [...directEditData];

      return (
        JSON.stringify(
          copyPreSelectedData.sort((a, b) => +convertTagsIntoCollection(a).id - +convertTagsIntoCollection(b).id),
          inValidateTypename
        ) !==
        JSON.stringify(
          copyData.sort((a, b) => +convertTagsIntoCollection(a).id - +convertTagsIntoCollection(b).id),
          inValidateTypename
        )
      );
    }
    return directEditData.length > 0;
  };

  const renderFooter = (close) => {
    return savingStatus ? (
      <div className="create-scrap__footer-processing">
        <span className="create-scrap__footer-status">{savingStatus}</span>
        <span className="create-scrap__footer-spinner">
          <FontAwesomeIcon icon={faSpinnerIcon} spin size="2x" />
        </span>
      </div>
    ) : (
      <>
        <div
          className={`scrap-edit-tags__footer-buttons${
            isMobile ? ' footer__mobile' : ''
          }`}
        >
          <button
            onClick={close}
            className="scrap-edit-tags__footer-cancel button button__outline btn-action"
          >
            Cancel
          </button>

          <button

            onClick={ async () => {   
              setDisabeledSave(true)
              setUpdating(true);
              data.forEach((item) => {
                if(convertTagsIntoCollection(item)?.checked){
                  delete convertTagsIntoCollection(item).checked;
                }
              })
              if (dataType === 'tagType') {
                onSave({ tags: data as tagType[] });
              } else if (dataType === 'collectionType') {
                onSave({ collections: data as collectionType[] });
              }
              setOnSaveCollectionList && ( data.length !== 0 ? setOnSaveCollectionList(true) : setOnSaveCollectionList(false));
              setDisabeledSave(false);
            }}
            className="scrap-edit-tags__footer-save button button__primary btn-action"
            disabled={!isChanged()||disabledSave}
          >
            <FontAwesomeIcon icon={faCheckCircleIcon} /> Save Changes
          </button>
        </div>
      </> 
    );
  };

  const renderHeader = (close) => {
    if (isMobile && isChanged()) {
      return renderFooter(close);
    }
    return (
      <header className="new-popup__header-inner-container">
        <h3 className="new-popup__header-heading">
          <button
            className="new-popup__header-button hoverState__with-white-text"
            onClick={() => {
              close();
            }}
          >
            <LeftArrowIcon className="new-popup__header-button__icon" />
          </button>
          {returnLabel()}
        </h3>
        <button
          className="new-popup__header-button hoverState__with-white-text"
          onClick={() => {
            close();
          }}
        >
          <NewClose className="new-popup__header-button__icon " />
        </button>
      </header>
    );
  };

  return (
    <div>
      <NewPopup
        controlled={controlled}
        size="Small"
        fullMobile={true}
        header={{
          customRender: (close) => renderHeader(close),
        }}
        className='scrap-edit-tags__popup'
        footer={
          isMobile
            ? undefined
            : {
              submitLabel: 'Save Changes',
              customRender: (close) => renderFooter(close),
              className: 'scrap-edit-tags__footer',
            }
        }
        onClosed={() => {
          if (preSelectedData) {
            setData(preSelectedData);
          }
        }}
      >
        {(close) => (
          <CheckboxList
            allowCreation={true}
            placeholder={`Type to search or create`}
            itemDataType={dataType}
            selectedItems={data}
            setSelectedItems={(newTags) => {
              // there is some issue with the reusability of the component when directly editing
              // added a component state to handle the edited data
              setDirectEditData(newTags);
              setData(newTags);
            }}
            preSelectedData={showEdit ? preSelectedData : []}
          />
        )}
      </NewPopup>
    </div>
  );
};

export default ScrapEditTags;
