import { GroupType } from '../../containers/GroupShareContainer/types';
import { collectionType } from '../../types/collections';

export type ReduxMessengersType = {
  groupMessenger?: {
    type: 'edit' | 'delete' | 'member-removed' | 'member-added' | 'groups-merge' | 'likes-and-comments';
    data?: Partial<GroupType>;
    reactionsCount?: number;
    commentsCount?: number;
  };
  collectionMessenger?: {
    type: 'edit' | 'delete' | 'member-removed' | 'member-added' | 'likes-and-comments';
    data?: Partial<collectionType>;
    reactionsCount?: number;
    commentsCount?: number;
  }
};

export const SET_GROUP_MESSENGER = 'SET_GROUP_MESSENGER';
export const SET_COLLECTION_MESSENGER = 'SET_COLLECTION_MESSENGER';

type popupsActionType = typeof SET_GROUP_MESSENGER | typeof SET_COLLECTION_MESSENGER;

const defaultState: ReduxMessengersType = {
  groupMessenger: null,
  collectionMessenger: null,
};

export default function messengers(
  state = defaultState,
  action: {
    type: popupsActionType;
    payload: ReduxMessengersType;
  }
) {
  const { type, payload } = action;
  switch (type) {
    case SET_GROUP_MESSENGER:
      return { ...state, groupMessenger: payload.groupMessenger };
    case SET_COLLECTION_MESSENGER:
      return { ...state, collectionMessenger: payload.collectionMessenger };

    default:
      return state;
  }
}
