import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { Back } from '../../Global/icons';

interface List {
  label: string;
  description?: string;
  stateName: string;
  icon: ReactElement;
  cb: () => void;
}

interface Proptypes {
  className?: string;
  list?: Array<List>;
  formState?: string;
}

const MyAccount = (props: Proptypes) => {
  const history = useHistory();
  
  const { className, list,formState } = props;
  return (
    <div className="my-account">
      <div className="my-account__header">
        <span onClick={()=>history.length > 2 ? history.goBack() :history.push('/')} className='my-account__back-icon'>
          <Back />
        </span>
        <h2 className="my-account__header__title">My Account</h2>
      </div>

      {list.map((item, index) => (
        <div className="my-account__list-wrapper" key={index}  onClick={() => {
          item.cb();
        }}>
          <span
            className={`my-account__list-item ${item.stateName==="delete-account"?"my-account__delete-icon":""}`}
           
            key={index}
          >
            {item.icon}
          </span>

          <div className='my-account__label-desc-wrapper'>
            <span className='my-account__label'>{item.label}</span>
            {item.description && <span className='my-account__description'>{item.description}</span>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyAccount;
