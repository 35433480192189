// This function returns query/mutations parameters definition and assignment string

export function createApolloQueriesString(parameters: Array<[variableName: string, variableType: string]>) {
  let variablesTypeString = '';
  let variablesAssignString = '';
  parameters.forEach(([name, type], i) => {
    variablesTypeString += `$${name}: ${type}${
      i === parameters.length - 1 ? '' : ' \n'
    }`;
    variablesAssignString += `${name}: $${name}${
      i === parameters.length - 1 ? '' : ' \n'
    }`;
  });
  //  This is how variablesTypeString will look like  ` 
  //   $id: ID
  //   $user_id: Int
  //   $title: String
  //   $desc: String
  //   $images_meta: String
  //   $tags: String
  //   $state: Int
  //   ` 
  return [variablesTypeString, variablesAssignString];
}
