import React, { useEffect, useState } from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import './styles.css';
import { isEmpty } from '../../../../helpers';

interface Proptypes {
  description: string;
  title: string;
  className?: string;
}

const NewCollectionDescription = ({ description, title, className }: Proptypes) => {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(false);
  }, [description]);
  const isDescriptionEmpty = isEmpty(description, true);
  return (
    <div className={`collection-card__description-wrapper${expanded ? ' collection-card__description-wrapper--expanded' : ''} ${className || ''}`}>
      <div className="collection-card__description-heading">{title}</div>
      {!isDescriptionEmpty ? (
        <div
          className="collection-card__description"
          onClick={(e) => {
            // e.stopPropagation();
            setExpanded(true);
          }}
        >
          <HTMLEllipsis
            unsafeHTML={description}
            maxLine={expanded ? Infinity : 6}
            key={Math.random() + title}
            basedOn="words"
            ellipsisHTML={`<button>...</button>`}
          />
        </div>
      ) : null}
    </div>
  );
};

export default NewCollectionDescription;
