import React, { useEffect, useState } from 'react';
import Joyride from 'react-joyride';
import {
  OnboardingPopStyles,
  OnboardingPopBaseStepsStyles,
} from '../OnboardingHelpers';

interface OnboardingProps {
  run?: boolean;
}

const OnboardingBackToScrap = ({ run }: OnboardingProps) => {
  const [runState] = useState(run || false);

  useEffect(() => {
    // rerun the component
    return () => {
      localStorage.setItem('tourEnd', 'true');
    };
  }, [run]);

  const stepsValue = {
    run: runState,
    steps: [
      {
        target: '.side-nav-dropdown__list__uncategorized',
        content:
          'Navigate back to My Scraps to see only the Scraps you\'ve created.',
        disableBeacon: true,
        placement: 'right',
        isFixed: true,
        styles: OnboardingPopBaseStepsStyles,
        spotlightClicks: true,
      },
    ],
  };

  // check if tourEnd is set in local storage
  const tourEnd = localStorage.getItem('tourEnd');
  if (tourEnd) {
    return null;
  }

  /**
   * Check if hideOnboarding is set in local storage
   * don't show onboarding component
   */
  const hideOnboarding = localStorage.getItem('hideOnboarding');
  if (hideOnboarding) {
    localStorage.removeItem('hideOnboarding');
  }

  return (
    <div>
      {/* there is some issue in the libraries types with certain properties,
          so we need to ignore the typescript error */}
      <Joyride
        // @ts-ignore
        steps={stepsValue.steps}
        run
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        hideCloseButton
        hideBackButton
        locale={{
          close: 'Close',
          last: 'End tour',
        }}
        styles={OnboardingPopStyles}
        callback={(step) => {
          if (step.status === 'finished') {
            // set tour flag in local storage
          }
        }}
      />
    </div>
  );
};

export default OnboardingBackToScrap;
