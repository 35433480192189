/*
 * THIS FUNCTION WILL HANDLE SHARE BASED ON NATIVE SHARE AVAILABILITY AND SHARE STATUS BEING PUBLIC OR NOT
 * IF THE SHARED DATA IS PUBLIC ONLY THEN THE NATIVE SHARE SCREEN WILL BE ALLOWED OR ELSE IF A STRING 'show' IS PASSED TO SHOW THE SAME FORCEFULLY
 * PROP DETAILS -
 * shareLink - THE LINK THAT NEEDS TO BE SHARED
 * shareStatus - POSSIBLE VALUES BEING STATUS VALUES (1 - PRIVATE, 2 - PRIVATE WITH LINK, 3 - PUBLIC) OR STRING VALUE 'show'
 * cb - THIS IS THE CALLBACK FUNCTION THAT DEFINES WHAT NEEDS TO BE DONE WHEN THERE IS NO NATIVE SHARE AVAILABILE OR IF THE CONDITIONS TO OPEN THAT DOES NOT MATCH
 * sendText - THIS IS AN OPTIONAL PARAMETER FOR AN ADDITIONAL MESSSAGE TO BE SENT ALONG WITH THE SHARE DATA
 * shareText - THIS IS AN OPTIONAL PARAMETER TO DEFINES WHETHER OR NOT TO SEND THE TITLE AS WELL IN THE SHARE DATA
 */
interface ShareData {
  url: string;
  title?: string;
  text?: string;
}
function share(title: string, url: string, shareText?: boolean) {
  try {
    var shareObj: ShareData = {
      url,
    };
    if (shareText) {
      shareObj = {
        title,
        url,
        text: title,
      };
    }
    navigator
      .share(shareObj)
      .then(() => {})  
  } catch (error) {
    // 
  }
  
}

export function isMobileDevice() {
  var isMobileDevice = false;
  try {
    if (navigator.userAgent) {
      let regexp = /android|iphone|kindle|ipad/i;
      isMobileDevice = regexp.test(navigator.userAgent);
    }
    
  } catch (error) {
    // 
  }
  return isMobileDevice;
  
}
/*
 * PARAMETERS
 * shareURL - Share URL is mandatory text to be shown in the native menu
 * cb - A void function that will be called only when the RaectNativeWebView is not found
 */
export function handleNativeShare(
  shareURL: string,
  cb: () => void,
  copyText = false
) {
  try {
    // @ts-ignore
    if (window.ReactNativeWebView && isMobileDevice()) {
      navigator.clipboard.writeText(encodeURI(shareURL || ''));
      if (copyText) {
        // @ts-ignore
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            requestType: 'copyText',
            data: shareURL,
          })
        );
      } else {
        // @ts-ignore
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            requestType: 'shareMenu',
            data: shareURL,
          })
        );
      }
      return true;
    }
    cb && cb();
    return false;
  } catch (error) {
    return false;
  }
  
}
export function handleShare(
  shareLink: string,
  shareStatus: number | 'show',
  cb: () => void,
  sendText?: string,
  shareText?: boolean
): void {
  try {
    if (shareStatus === 3 || shareStatus === 'show') {
      const title = sendText || document.title;
      if (navigator.share && isMobileDevice()) {
        share(title, shareLink, shareText);
      } else {
        cb && cb();
      }
    } else {
      cb && cb();
    }  
  } catch (error) {
    // 
  }
  
}

export function handleThemeChange(theme: string) {
  try {
    // @ts-ignore
    if (window.ReactNativeWebView && isMobileDevice() && theme.length > 0 ) {
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          requestType: 'changeTheme',
          data: theme,
        })
      );
      return true;
    }  
  } catch (error) {
    // 
  }
  
}

export function performNativeSocialLogin(
  loginType: 'google' | 'facebook' | 'apple',
  webCallback: () => void,
  onInit?: () => void
) {
  // @ts-ignore
  if (window.ReactNativeWebView && isMobileDevice() && loginType.length > 0) {
    onInit && onInit();
    // @ts-ignore
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        requestType: 'socialLogin',
        data: loginType,
      })
    );
    return true;
  } else {
    webCallback();
  }
}