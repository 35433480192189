import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { setImageDisplayPopup } from '../../../redux/action/utils';
import { ReduxStateType } from '../../../redux/store';
import { CloseIcon, DownloadIcon, LeftArrowIcon, NewClose } from '../icons';
import NewPopup from '../NewPopup';

const ImageDisplayModal = () => {
  const dispatch = useDispatch();
  const imageDisplayPopup = useSelector(
    (state: ReduxStateType) => state.utils.imageDisplayPopup
  );
  const { imageUrl, modalClassName } = imageDisplayPopup || {};
  const [show, setShow] = [
    !!imageDisplayPopup,
    (val: boolean) => {
      dispatch(setImageDisplayPopup(null));
    },
  ];
  let className = 'display-modal display-modal--image';
  if (modalClassName) className += ' ' + modalClassName;
  return (
    <NewPopup
      className={className}
      size="Extra large"
      fullMobile
      header={{
        heading: 'Image Details',
        customRender: (close) => (
          <header className="new-popup__header-inner-container">
            <h3 className="new-popup__header-heading">
              <button
                onClick={() => {
                  setShow(false);
                  close();
                }}
                className="new-popup__header-button"
              >
                <LeftArrowIcon className="new-popup__header-button__icon" />
              </button>
              Image Details
            </h3>
            <button
              onClick={() => {
                setShow(false);
                close();
              }}
              className="new-popup__header-button hoverState__with-white-text"
            >
              <NewClose className="new-popup__header-button__icon" />
            </button>
          </header>
        ),
      }}
      body={{
        className: 'display-modal__popup',
      }}
      controlled={{
        show,
        setShow,
      }}
    >
      {(close) => (
        <div className='display-modal__body'>
          
          <TransformWrapper>
            <TransformComponent>
              <img
                src={imageUrl}
                alt="add-image__view-full"
                className="add-image__view-full__image display-modal__image"
              />
            </TransformComponent>
          </TransformWrapper>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="display-modal__download-button hoverState__with-white-text"
            href={imageUrl}
            download='untitled'
          >
            <DownloadIcon />
          </a>
        </div>
      )}
    </NewPopup>
  );
};

export default ImageDisplayModal;
