import { filterParamTypes } from '../../types/filter';

interface actionTypes {
  type: string;
  filterData: filterParamTypes;
}

const stateDefault: filterParamTypes = {
  text: '',
  types: [],
  tags: [],
  story: [],
  collections: [],
  domain: '',
};

const filterParams = (
  state: filterParamTypes = stateDefault, action: actionTypes
) => {
  switch (action.type) {
    case 'SET_FILTER_PROPS':
      return { ...state, ...action.filterData };

    default:
      return state;
  }
};

export default filterParams;
