import React from 'react';

interface Proptypes {
  className?: string;
  width?: string;
  height?: string;
}

const CreatedAt = ({ className, width, height }: Proptypes) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`createAt__icon ${className || ''}`}
    >
      <path
        d="M9.4774 9.81641H4.66406"
        stroke="#84929A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.4774 7.02539H4.66406"
        stroke="#84929A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.50073 4.24023H4.66406"
        stroke="#84929A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.60533 0.833984C9.60533 0.833984 4.48733 0.836651 4.47933 0.836651C2.63933 0.847984 1.5 2.05865 1.5 3.90532V10.036C1.5 11.892 2.648 13.1073 4.504 13.1073C4.504 13.1073 9.62133 13.1053 9.63 13.1053C11.47 13.094 12.61 11.8827 12.61 10.036V3.90532C12.61 2.04932 11.4613 0.833984 9.60533 0.833984Z"
        stroke="#84929A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreatedAt;
