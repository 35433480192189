import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const Organize = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29776 0.133981C8.44978 -0.314182 9.66953 0.410934 9.94333 1.58059L11.3675 1.08809C12.6626 0.640261 14 1.64025 14 3.05642V3.54248L15.4253 3.0989C16.7085 2.69954 18 3.69637 18 5.08619V14.7464C18 16.1362 16.7085 17.133 15.4253 16.7337L14 16.2901V16.7761C14 18.1923 12.6626 19.1923 11.3675 18.7445L9.97383 18.2625C9.79024 19.434 8.6691 20.2364 7.51493 19.937L1.51494 18.3809C0.624603 18.15 7.62939e-06 17.3201 7.62939e-06 16.3681V4.41082C7.62939e-06 3.54595 0.517151 2.7718 1.29776 2.46812L7.29776 0.133981ZM12 14.9199C11.9997 14.9041 11.9996 14.8883 12 14.8725V4.96008C11.9996 4.94423 11.9997 4.92842 12 4.91266V3.05642L10.3162 3.63866C10.2114 3.67493 10.1048 3.69216 10 3.69218V13.1322V16.1404C10.1048 16.1404 10.2114 16.1576 10.3162 16.1939L12 16.7761V14.9199ZM14 5.70863V14.1239L16 14.7464V5.08619L14 5.70863ZM8.00001 2.07667L2.00001 4.41082V16.3681L8.00001 17.9242V13.1322V2.07667Z"
        fill="#3C3C3C"
      />
    </svg>
  );
};

export default Organize;
