/* eslint-disable @typescript-eslint/no-explicit-any */
import { collectionType } from '../types/collections';
import { scrapType } from '../types/scrapType';
// THIS FUNCTION FILTERS DOWN THE list BASED ON idsList AND RETURNS THOSE COLLECTIONS 
// WHOSE IDS ARE PASSED IN idslist parameter
export default function parseCollectionsFromIds(
  list: collectionType[],
  idsList: scrapType['collections']
) {
  if(!list?.length) return []
  const collections: collectionType[] = new Array(idsList?.length || 0);
  if(!Array.isArray(idsList) || !Array.isArray(list)) return [];
  try {
    const idsMapper: any = {};
    idsList.forEach((item, i) => {
      idsMapper[item.id] = `${i}`;
    });
    let count = 0;
    for (let item of list) {
      const position = idsMapper[item.id];
      if (position) {
        collections[position] = item;
        count += 1;
      }
      if(count === collections.length) break;
    }
  } catch (error) {
    console.warn(error);
  }

  return collections.filter(collection => !!collection);
}