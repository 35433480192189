import React from 'react';

interface Proptypes {
  className?: string;
}

const OrderList = ({ className }: Proptypes) => {
  return (
    <svg
      className={className || ''}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3 13.1484H6.3C5.7 13.1484 5.3 13.5484 5.3 14.1484C5.3 14.7484 5.7 15.1484 6.3 15.1484H18.3C18.9 15.1484 19.3 14.7484 19.3 14.1484C19.3 13.5484 18.9 13.1484 18.3 13.1484Z"
        fill="black"
      />
      <path
        d="M6.3 3.14844H18.3C18.9 3.14844 19.3 2.74844 19.3 2.14844C19.3 1.54844 18.9 1.14844 18.3 1.14844H6.3C5.7 1.14844 5.3 1.54844 5.3 2.14844C5.3 2.74844 5.7 3.14844 6.3 3.14844Z"
        fill="black"
      />
      <path
        d="M18.3 7.14844H6.3C5.7 7.14844 5.3 7.54844 5.3 8.14844C5.3 8.74844 5.7 9.14844 6.3 9.14844H18.3C18.9 9.14844 19.3 8.74844 19.3 8.14844C19.3 7.54844 18.9 7.14844 18.3 7.14844Z"
        fill="black"
      />
      <path
        d="M1.8 1.15V3.85H2.8V0.25H1.9L1 0.85V1.65L1.8 1.15Z"
        fill="black"
      />
      <path
        d="M1.7 7.15C1.7 7.05 1.8 7.05 1.9 6.95C2 6.95 2 6.85 2.1 6.85C2.2 6.85 2.3 6.85 2.3 6.95C2.3 7.05 2.5 7.15 2.5 7.15C2.5 7.25 2.6 7.25 2.6 7.35C2.6 7.45 2.6 7.55 2.5 7.55C2.5 7.65 2.4 7.65 2.3 7.75C2.3 7.95 2.3 7.95 2.2 8.05L0.799997 9.15V9.85H3.6V9.15H2.2L2.5 8.85C2.8 8.65 3 8.45 3.1 8.25C3.2 8.05 3.4 7.95 3.4 7.75C3.5 7.65 3.5 7.45 3.5 7.35C3.5 7.15 3.4 6.95 3.3 6.75C3.2 6.55 3 6.45 2.8 6.35C2.6 6.25 2.3 6.25 2 6.25C1.7 6.25 1.5 6.25 1.3 6.35C1.1 6.45 0.899997 6.55 0.799997 6.75C0.699997 6.95 0.699997 7.15 0.699997 7.45H1.6C1.7 7.35 1.7 7.25 1.7 7.15Z"
        fill="black"
      />
      <path
        d="M2.9 14.05C3.1 13.95 3.3 13.95 3.4 13.75C3.5 13.65 3.6 13.45 3.6 13.25C3.6 13.05 3.5 12.85 3.4 12.75C3.3 12.55 3.1 12.45 2.9 12.35C2.7 12.25 2.5 12.25 2.2 12.25C1.9 12.25 1.7 12.25 1.5 12.35C1.3 12.45 1.1 12.55 0.999997 12.75C0.899997 12.95 0.799997 13.15 0.799997 13.35H1.7C1.7 13.25 1.7 13.25 1.8 13.15C1.8 13.05 1.9 13.05 2 13.05C2.1 13.05 2.2 13.05 2.2 13.05C2.3 13.05 2.4 13.05 2.4 13.05C2.5 13.05 2.5 13.15 2.5 13.15C2.5 13.25 2.6 13.25 2.6 13.35C2.6 13.45 2.6 13.45 2.5 13.55C2.5 13.65 2.4 13.65 2.3 13.65C2.2 13.65 2.1 13.65 2 13.65H1.8V14.35H2.2C2.3 14.35 2.4 14.35 2.5 14.35C2.6 14.35 2.6 14.45 2.7 14.45C2.7 14.55 2.8 14.55 2.8 14.65C2.8 14.75 2.8 14.75 2.7 14.85C2.6 14.95 2.6 14.95 2.5 14.95C2.4 14.95 2.3 14.95 2.3 14.95C2.2 14.95 2.1 14.95 2 14.95C1.9 14.95 1.9 14.85 1.8 14.85C1.7 14.85 1.7 14.75 1.7 14.65H0.699997C0.699997 14.85 0.799997 15.05 0.899997 15.25C0.999997 15.45 1.2 15.55 1.4 15.65C1.6 15.75 1.9 15.75 2.2 15.75C2.5 15.75 2.8 15.75 3 15.65C3.2 15.55 3.4 15.45 3.5 15.25C3.6 15.05 3.7 14.85 3.7 14.65C3.7 14.45 3.6 14.25 3.5 14.05C3.4 14.15 3.2 14.05 2.9 14.05Z"
        fill="black"
      />
    </svg>
  );
};

export default OrderList;
