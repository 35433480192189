import { gql } from '@apollo/client';
import { createApolloQueriesString } from '../../helpers/apolloHelpers';
import { defaultResponse } from './defaultResponse';

const [
  parametersTypeString,
  parametersAssignString,
] = createApolloQueriesString([
  ['group_id', 'Int!'],
  ['nickname', 'String'],
  ['change_owner', 'Boolean = false'],
  ['owner_id', 'Int']
]);
export default function UPDATE_GROUP() {
  return gql`
    mutation UpdateGroup(${parametersTypeString}) {
      updateGroup(${parametersAssignString}) {
        ${defaultResponse}
      }
    }
  `;
}
