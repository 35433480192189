import React from 'react';
import { Link } from 'react-router-dom';
import Trust from '../../../../Trust';

interface PropTypes {
  screenName: string;
  profileImage: string;
  name: string;
  description: string;
  profileData: {
    user_id: number;
    userName: string;
    avatar: string;
    display_name: string;
    is_trusted: boolean;
  };
}

const XFollowsCard = (props: PropTypes) => {
  const { screenName, profileImage, name, description, profileData } = props;
  const user_id = profileData?.user_id;
  const userName = profileData?.userName;
  const avatar = profileData?.avatar;
  const display_name = profileData?.display_name;
  const is_trusted = profileData?.is_trusted;

  return (
    <>
      {user_id && (
        <Link to={`/${userName}`}>
          <div className="x-follows__user">
            <img
              src={avatar ? avatar : '/dashboard/img/default-avatar.jpg'}
              alt={userName}
            />
            <p>
              <h2>
                {display_name}{' '}
                <Trust isTrusted={is_trusted} userId={user_id} view="icon" />
              </h2>
              <h3>@{userName} </h3>
            </p>
          </div>
        </Link>
      )}

      <a
        className="x-follows__link"
        href={`https://twitter.com/${screenName}`}
        target="_blank"
        rel="noreferrer"
      >
        <div className={`x-follows__user${user_id ? ' small' : ''}`}>
          <img src={profileImage} alt={screenName} />
          <p>
            <h2>{name}</h2>
            <h3>@{screenName}</h3>
            <span>{description}</span>
          </p>
        </div>
      </a>
    </>
  );
};

export default XFollowsCard;
