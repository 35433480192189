import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';

interface propTypes {
  size: 'small' | 'normal' | 'big' | 'jumbo';
  iconClass: string; // bootstrap icon class
  color?: string;
}

const Icon = (props: propTypes) => {
  const { size, iconClass, color } = props;
  return (
    <i
      className={`icon icon-${size} bi bi-${iconClass}`}
      style={{ color: color ? color : '', lineHeight: '0' }}
    ></i>
  );
};

export default Icon;
