import React from 'react';
import './styles.css';

const LoadingScrapCard = () => {
  return (
    <div className="loading-scrap-card">
      <div className="loading-scrap-card-header__outer-container">
        <div className="loading-scrap-card-header__mid-container">
          <div className="loading-scrap-card__header">
            <div className="loading-scrap-card-header__image-container">
              <div className="loading-scrap-card-header__image-inner-container">
                <svg
                  className="loading-scrap-card-header__image"
                  width="92"
                  height="92"
                  viewBox="0 0 92 92"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 42L21 31L49 59L67 41L82.0021 57V10H10V42ZM5.11111 0H86.8889C88.2444 0 89.5445 0.53849 90.503 1.49701C91.4615 2.45553 92 3.75556 92 5.11111V86.8889C92 88.2444 91.4615 89.5445 90.503 90.503C89.5445 91.4615 88.2444 92 86.8889 92H5.11111C3.75556 92 2.45553 91.4615 1.49701 90.503C0.53849 89.5445 0 88.2444 0 86.8889V5.11111C0 3.75556 0.53849 2.45553 1.49701 1.49701C2.45553 0.53849 3.75556 0 5.11111 0ZM63.8889 35.7778C61.8556 35.7778 59.9055 34.97 58.4677 33.5323C57.03 32.0945 56.2222 30.1444 56.2222 28.1111C56.2222 26.0778 57.03 24.1277 58.4677 22.69C59.9055 21.2522 61.8556 20.4444 63.8889 20.4444C65.9222 20.4444 67.8723 21.2522 69.31 22.69C70.7478 24.1277 71.5556 26.0778 71.5556 28.1111C71.5556 30.1444 70.7478 32.0945 69.31 33.5323C67.8723 34.97 65.9222 35.7778 63.8889 35.7778Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
            </div>
            <div className="loading-scrap-card-header__content">
              <div className="loading-scrap-card-header__small-line loading-scrap-card__header-line animate" />
              <div className="loading-scrap-card-header__big-line loading-scrap-card__header-line animate" />
              <div className="loading-scrap-card-header__big-line loading-scrap-card__header-line animate" />
            </div>
          </div>
        </div>
      </div>

      <div className="loading-scrap-card__body">
        <div className="loading-scrap-card-body__line-1 loading-scrap-card__body-line animate" />
        <div className="loading-scrap-card-body__line-2 loading-scrap-card__body-line animate" />
        <div className="loading-scrap-card-body__line-3 loading-scrap-card__body-line animate" />
        <div className="loading-scrap-card-body__line-4 loading-scrap-card__body-line animate" />
        <div className="loading-scrap-card-body__line-5 loading-scrap-card__body-line animate" />
        <div className="loading-scrap-card-body__line-6">
          <div className="loading-scrap-card-body-line-6__line-1 loading-scrap-card__body-line animate" />
          <div className="loading-scrap-card-body-line-6__line-2 loading-scrap-card__body-line animate" />
          <div className="loading-scrap-card-body-line-6__line-3 loading-scrap-card__body-line animate" />
        </div>
      </div>

      <div className="loading-scrap-card__footer">
        <div className="loading-scrap-card-footer__inner-container">
          <div className="loading-scrap-card-footer__left-line loading-scrap-card__footer-line animate" />
          <div className="loading-scrap-card-footer__right-line">
            <div className="loading-scrap-card-footer-right-line__line-1 loading-scrap-card__footer-line animate" />
            <div className="loading-scrap-card-footer-right-line__line-2 loading-scrap-card__footer-line animate" />
            <div className="loading-scrap-card-footer-right-line__line-3 loading-scrap-card__footer-line animate" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScrapCard;
