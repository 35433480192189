import React from 'react';
import './styles.css';

interface Proptypes {
  children: React.ReactElement;
  videoUrl: string;
  onVideoLoaded: (duration: number) => void;
  isProcessing?: boolean;
  onVideoError?: () => void;
}

const ScrapFilePopup = ({
  children,
  videoUrl,
  onVideoLoaded,
  isProcessing,
  onVideoError,
}: Proptypes) => {

  return (
    <div
      className={`scrap-file-popup${
        isProcessing ? ' scrap-file-popup--disabled' : ''
      }`}
    >
      <div className="scrap-file-popup__content">{children}</div>
      {videoUrl && (
        <video
          onLoadedMetadata={(e) => {
            onVideoLoaded((e.target as HTMLVideoElement).duration);
          }}
          src={videoUrl}
          className="scrap-file-popup__video"
          controls
          onError={onVideoError && onVideoError}
        ></video>
      )}
    </div>
  );
};

export default ScrapFilePopup;
