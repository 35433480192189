import React from 'react';

interface Proptypes {
  className?: string;
  onClick?: () => void;
}

const NotesIcon = ({ className, onClick }: Proptypes) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 2V1C13 0.4 12.6 0 12 0C11.4 0 11 0.4 11 1V2H9V1C9 0.4 8.6 0 8 0C7.4 0 7 0.4 7 1V2H5V1C5 0.4 4.6 0 4 0C3.4 0 3 0.4 3 1V2C1.3 2 0 3.3 0 5V17C0 18.7 1.3 20 3 20H13C14.7 20 16 18.7 16 17V5C16 3.3 14.7 2 13 2ZM12 15.8H4C3.6 15.8 3.2 15.4 3.2 15C3.2 14.6 3.6 14.2 4 14.2H12C12.4 14.2 12.8 14.6 12.8 15C12.8 15.4 12.4 15.8 12 15.8ZM12 11.8H4C3.6 11.8 3.2 11.4 3.2 11C3.2 10.6 3.6 10.2 4 10.2H12C12.4 10.2 12.8 10.6 12.8 11C12.8 11.5 12.4 11.8 12 11.8ZM12 7.8H4C3.6 7.8 3.2 7.5 3.2 7C3.2 6.5 3.6 6.2 4 6.2H12C12.4 6.2 12.8 6.6 12.8 7C12.8 7.4 12.4 7.8 12 7.8Z"
        fill="#969696"
      />
    </svg>
  );
};

export default NotesIcon;
