import React, { useEffect } from 'react';

const useInterval = (cb: Function, duration: number, cancel = false) => {
  async function callbackHandler() {
    cb();
  }
  useEffect(() => {
    if (typeof window === 'object' && !cancel) {
      let intervalIdentifier = setInterval(callbackHandler, duration);
      return () => {
        window.clearInterval(intervalIdentifier);
      };
    }
  }, [cancel]);
};

export default useInterval;
