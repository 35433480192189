import { gql } from '@apollo/client';

export const GET_ENTRY_DATA = (queryString) => gql`{
    entry(${queryString})
      {
        data {
          config
          state
          id
          desc
          uid
          pid
          type
          notes
          url
          thumbnail
          images_meta
          updated_url
          started_at
          updated_at
          created_at
          color
          source_text
          comment
          playback_time
          image
          image_type
          page_title
          title
          userName
          position
          domain
          video
          auto_generate
          service
          meta
          audio
          audio_meta
          file
          name
          file_type
          private_key
          memos {
            id
            memo
          }
          tags {
            id
            title
          }
          collections {
            id
            title
            slug
            state
          }
          stories {
            id
            title
            slug
          }
          price
          meta_title
          meta_price
          meta_desc
          favicon
          original_favicon
        }
      }
  }`;

export const ENTRY_DATA = gql`
  query entry($uid: String!, $username: String, $private_key: String) {
    entry(uid: $uid, username: $username, private_key: $private_key) {
      data {
        config
        state
        id
        desc
        uid
        pid
        type
        notes
        url
        thumbnail
        images_meta
        updated_url
        started_at
        updated_at
        created_at
        creator_initials
        color
        source_text
        comment
        playback_time
        image
        image_type
        page_title
        title
        userName
        position
        domain
        video
        auto_generate
        service
        meta
        audio
        audio_meta
        file
        name
        file_type
        private_key
        memos {
          id
          memo
        }
        tags {
          id
          title
        }
        collections {
          id
          title
          slug
          state
        }
        owner {
          avatar
          bio
          username
          email
          first_name
          last_name
          display_name
          creator_initials
          role {
            role_id
            title
          }
        }
        price
        meta_title
        meta_price
        meta_desc
        favicon
        original_favicon
      }
    }
  }
`;

export const GET_IMAGE_DATA = gql`
  query entry($uid: String!) {
    entry(uid: $uid) {
      data {
        id
        pid
        uid
        type
        image
        config
        thumbnail
      }
    }
  }
`;
