import React from 'react';
import { Helmet } from 'react-helmet';

interface Proptypes {
  helmetTitle: string;
  title: string;
  children: React.ReactNode | void;
  subHeading?: string;
  footerMessage?: React.ReactChild | React.ReactChildren;
  className?: string;
}
const AuthHeaderLayout = (props: Proptypes) => {
  const { children, helmetTitle, title, className, subHeading } = props;
  return (
    <div className="main-wrapper">
      {helmetTitle && (
        <Helmet>
          <title>
            {helmetTitle} | {title}
          </title>
        </Helmet>
      )}

      <div className={`sl-auth${' ' + (className || '')}`}>
        <div className="sl-auth__wrapper">
          <div className="sl-auth__form">
            {subHeading && (
              <h3 className="sl-auth__sub-heading">{subHeading}</h3>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthHeaderLayout;
